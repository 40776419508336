import React, { useEffect, useRef, useState } from 'react';
import { Marker, NaverMap, Polygon } from 'react-naver-maps';
import DeliveryPriceDongRateRegisterGroupDialog from './DeliveryPriceDongRateRegisterGroupDialog';
import DeliveryPriceDongRateRegisterAreaDialog from './DeliveryPriceDongRateRegisterAreaGuideDialog';
import useStateCustomHook from '../../../hook/useStateCustomHook';
import cursor from '../../../img/cur/area.cur';
import { getAveragePosition } from '../../../utils/map.util';
import { Button, Image, Modal } from 'antd';
import { httpGet, httpPost, httpUrl, imageUrl } from '../../../api/httpClient';
// https://navermaps.github.io/maps.js.ncp/docs/img/area.cur

import copyPng from '../../../img/map/content_copy.png';
import editPng from '../../../img/map/edit.png';
import deletePng from '../../../img/map/delete.png';
import openMarkerPng from '../../../img/map/show_name_marker.png';
import closeMarkerPng from '../../../img/map/hide_name_marker.png';
import backPng from '../../../img/map/undo.png';
import refreshPng from '../../../img/map/refresh.png';
import searchPng from '../../../img/map/search.png';
import DeliveryPriceDongRateRegisterAreaGuideDialog from './DeliveryPriceDongRateRegisterAreaGuideDialog';
import DeliveryPriceDongRateRegisterAreaFormDialog from './DeliveryPriceDongRateRegisterAreaFormDialog';
import { comma } from '../../../lib/util/numberUtil';
import { customAlert, customError } from '../../../api/Modals';
import { useSelector } from 'react-redux';
import { getDist } from '../../../lib/util/mapUtil';
import { reactLocalStorage } from 'reactjs-localstorage';
import Const from '../../../const';
import moment from "moment";

const DeliveryPriceDongRateDialog = ({ close }) => {
  const navermap = window.naver.maps;
  const mapCenterLocation = useStateCustomHook({ lat: 37.37544345085402, lng: 127.11224555969238 });
  const [naverMapLoad, setNaverMapLoad] = useState(false);
  const [openModalBackground, setOpenModalBackground] = useState(false);
  const [mapModalStatus, setMapModalStatus] = useState(0); // 0-기본, 1-요금제선택, 2-지역선택, 3-요금제등록,수정, 4-지억등록,수정, 5
  const [openRegisterDongDialog, setOpenRegisterDongDialog] = useState(false);
  const [openRegisterAreaGuideDialog, setOpenRegisterAreaGuideDialog] = useState(false);
  const [openRegisterAreaFormDialog, setOpenRegisterAreaFormDialog] = useState(false);
  const [newPolygonPathList, setNewPolygonPathList] = useState([]);
  const [openNameMarker, setOpenNameMarker] = useState(true);
  const [isSelectArea, setIsSelectArea] = useState(false);
  const [overlayPolygonList, setOverlayPolygonList] = useState([]);
  const [isReadyOverlay, setIsReadyOverlay] = useState(true);

  const [dongRateList, setDongRateList] = useState([]);
  const [selectDongRate, setSelectDongRate] = useState(null);
  const [dongRateAreaList, setDongRateAreaList] = useState([]);
  const [originalDongRateAreaList, setOriginalDongRateAreaList] = useState([]);
  const [selectDongRateArea, setSelectDongRateArea] = useState(null);
  const [modifyDongRate, setModifyDongRate] = useState(null);
  const [isCopy, setIsCopy] = useState(false);
  const [modifyDongRateArea, setModifyDongRateArea] = useState(null);
  const [areaModifyMode, setAreaModifyMode] = useState(false);
  const [zoom, setZoom] = useState(14);
  const user = useSelector((state) => state.login.loginInfo);

  const mapAreaColorCode = {
    fixSelect: { line: 'rgba(32, 54, 250, 1)', area: 'rgba(32, 54, 250, 0.3)' },
    fixUnSelect: { line: 'rgba(32, 54, 250, 0.5)', area: 'rgba(32, 54, 250, 0.1)' },
    unFixSelect: { line: 'rgba(250, 32, 32, 1)', area: 'rgba(250, 32, 32, 0.3)' },
    unFixUnSelect: { line: 'rgba(250, 32, 32, 0.5)', area: 'rgba(250, 32, 32, 0.1)' },
  };

  let naverRef = useRef();
  useEffect(() => {
    if (!naverMapLoad) {
      console.log('naverMap: ' + naverMapLoad);
      mapCenterLocation.set({ lat: user.branchLatitude || 37.5215454, lng: user.branchLongitude || 127.0326957 });
      getDongRateList();
      mapGoToCenter();
      setNaverMapLoad(true);
    }
  }, []);

  const getDongRateList = async () => {
    try {
      const res = await httpGet(httpUrl.getDeliveryPriceDongRateList, [], {});
      if (res.result === 'SUCCESS') {
        setDongRateList(res.data.list);
      }
    } catch (error) {
      console.error('getDeliveryPriceDongRateList error: {}', error);
    }
  };

  const deleteDongRate = (idx) => {
    Modal.confirm({
      title: '요금제 그룹 삭제',
      content: '요금제 그룹을 삭제하시겠습니까?',
      onOk() {
        httpPost(httpUrl.deleteDeliveryPriceDongRate, [], {
          idx: idx,
        })
          .then((res) => {
            if (res.result === 'SUCCESS' && res.data.toUpperCase() === 'SUCCESS') {
              customAlert('요금제가 삭제되었습니다.');
            } else {
              Modal.info({
                title: '삭제실패',
                content: '요금제 삭제에 실패했습니다. 관리자에게 문의해주세요.',
              });
              return;
            }
            if (selectDongRate && selectDongRate.idx === idx) {
              setSelectDongRate(null);
              setOriginalDongRateAreaList([]);
              setDongRateAreaList([]);
            }
            getDongRateList();
          })
          .catch((error) => {
            customError('삭제 오류', '오류가 발생하였습니다. 다시 시도해 주십시오.');
          });
      },
    });
  };

  const dongRateListRender = (item) => {
    return (
      <div
        style={{ backgroundColor: selectDongRate && selectDongRate.idx === item.idx ? '#FFFFBF' : '#fff' }}
        className="flex justify-between nn-modal-border-bottom"
        key={item.idx}>
        <div
          className="flex flex-1 h-[50px] items-center cursor-pointer pl-[20px]"
          onClick={() => handleSelectDongRate(item)}>
          {item.name}
        </div>
        <div className="flex w-[80px] h-[50px] justify-between">
          <div className="flex h-[50px] items-center" onClick={() => handleOpenRegisterDongModal(item, true)}>
            <img src={copyPng} className="h-[20px] w-[20px] cursor-pointer"></img>
          </div>
          <div className="flex h-[50px] items-center" onClick={() => handleOpenRegisterDongModal(item, false)}>
            <img src={editPng} className="h-[20px] w-[20px] cursor-pointer"></img>
          </div>
          <div className="flex h-[50px] items-center" onClick={() => deleteDongRate(item.idx)}>
            <img src={deletePng} className="h-[20px] w-[20px] cursor-pointer"></img>
          </div>
        </div>
        {selectDongRate && selectDongRate.idx === item.idx ? (
          <div className="flex h-[50px] items-center w-[15px] ml-[5px] pr-[5px]">
            <div style={{ width: '8px', height: '8px' }} className="nn-right-arrow"></div>
          </div>
        ) : (
          <div className="flex h-[50px] items-center w-[15px] ml-[5px] pr-[5px]" />
        )}
      </div>
    );
  };

  const getDongRateAreaList = async (idx) => {
    // Api
    // this.setState({
    //   paths: [],
    //   viewPaths: [],
    //   inputName: '',
    //   inputPrice: '',
    // });
    const dongRateIdx = idx || selectDongRate.idx;
    try {
      const res = await httpGet(httpUrl.getDeliveryPriceDongRateAreaList, [dongRateIdx], {});
      if (res.result === 'SUCCESS') {
        // 처음 시작시 map에 보여지는 좌표
        let viewPaths = [];
        let parsedList = [];
        res.data.list.forEach((item) => {
          let parsedItem = { ...item };
          const JSONParsed = JSON.parse(parsedItem.polygon);
          let naverParsedList = [];
          JSONParsed.forEach((item) => {
            naverParsedList.push(new navermap.LatLng(item[0], item[1]));
          });
          // 최종 parsing 된 객체
          parsedItem.polygon = naverParsedList;
          parsedList.push(parsedItem);
          viewPaths.push(parsedItem);
        });

        setOriginalDongRateAreaList(parsedList);
        setDongRateAreaList(parsedList);

        // this.setState({
        //   deliveryZone: parsedList,
        //   prevDeliveryZone: parsedList,
        //   viewPaths,
        // });
      }
    } catch (error) {
      console.error('getList error: {}', error);
    }
  };

  const searchDongRateArea = (keyword) => {
    const searchResult = originalDongRateAreaList.filter((dongRateArea) => dongRateArea.dongName.includes(keyword));
    setDongRateAreaList(searchResult);
  };

  const deleteCustomzone = async (region) => {
    Modal.confirm({
      title: '요금제 그룹 삭제',
      content: '요금제 그룹을 삭제하시겠습니까?',
      async onOk() {
        const res = await httpPost(httpUrl.updateDeliveryPriceDongRateArea, [], {
          idx: region.idx,

          deleted: true,
        });
        console.log(res);
        if (!res.data) {
          Modal.info({
            title: '삭제 실패',
            content: '구역 삭제에 실패했습니다.',
          });
          return;
        }
        Modal.info({
          title: '삭제 성공',
          content: '구역 삭제에 성공했습니다.',
        });
        getDongRateAreaList();
      },
    });
  };

  const selectArea = (dongRateArea) => {
    const latList = [];
    const lngList = [];
    dongRateArea.polygon.map((point) => {
      latList.push(point.y);
      lngList.push(point.x);
    });
    const latAvg = getAveragePosition(latList);
    const lngAvg = getAveragePosition(lngList);
    setSelectDongRateArea(dongRateArea.idx);
    mapCenterLocation.set({ lat: latAvg, lng: lngAvg });
    mapGoToCenter();
  };

  const getDongRateAreaRender = (dongRateArea) => {
    return (
      <div
        style={{ backgroundColor: selectDongRateArea && selectDongRateArea === dongRateArea.idx ? '#FFFFBF' : '#fff' }}
        className="flex justify-between items-center nn-modal-border-bottom"
        key={dongRateArea.idx}
        onClick={() => selectArea(dongRateArea)}>
        <div className="w-[130px] h-[50px] flex items-center">
          <div className="p-[5px] text-[13px] cursor-pointer" onClick={() => handelSelectDongRateArea()}>
            {dongRateArea.dongName}
          </div>
        </div>
        <div className="flex-1 text-center">{dongRateArea.price}</div>
        <div className="flex-1 text-center" style={{ color: dongRateArea.isFixedPrice ? '#2036FA' : '#FA2020' }}>
          {dongRateArea.isFixedPrice ? '설정' : '미설정'}
        </div>
        <div className="flex-1 flex justify-center items-center ">
          <div className="flex mr-[10px] cursor-pointer" onClick={(e) => handleOpenRegisterAreaModal(e, dongRateArea)}>
            <img src={editPng} className="h-[20px] w-[20px]"></img>
          </div>
          <div>
            <img
              src={deletePng}
              className="h-[20px] w-[20px] cursor-pointer"
              onClick={() => deleteCustomzone(dongRateArea)}></img>
          </div>
        </div>
      </div>
    );
  };

  const areaPolygon = (dongRateArea) => {
    const colorCode = selectDongRateArea
      ? dongRateArea.idx === selectDongRateArea
        ? dongRateArea.isFixedPrice
          ? mapAreaColorCode.fixSelect
          : mapAreaColorCode.unFixSelect
        : dongRateArea.isFixedPrice
        ? mapAreaColorCode.fixUnSelect
        : mapAreaColorCode.unFixUnSelect
      : dongRateArea.isFixedPrice
      ? mapAreaColorCode.fixSelect
      : mapAreaColorCode.unFixSelect;

    const fillColor = colorCode.area;
    const strokeColor = colorCode.line;

    const latList = [];
    const lngList = [];
    dongRateArea.polygon.map((point) => {
      latList.push(point.y);
      lngList.push(point.x);
    });

    const latAvg = getAveragePosition(latList);
    const lngAvg = getAveragePosition(lngList);
    return (
      <div key={dongRateArea.idx}>
        <div>
          <Polygon
            paths={[[...dongRateArea.polygon]]}
            fillColor={fillColor}
            strokeColor={strokeColor}
            strokeWeight={3}
          />
          {openNameMarker && (
            // <div></div>
            <Marker
              onClick={() => {
                console.log(dongRateArea);
                setSelectDongRateArea(dongRateArea.idx);
                // setIsSelectArea(!isSelectArea);
              }}
              // position={new navermap.LatLng(37.37544345085402, 127.11224555969238)}
              position={new navermap.LatLng(latAvg, lngAvg)}
              icon={{
                content: `<div class="nn-map-name-marker-container"> 
                              <div>${dongRateArea.dongName} (${comma(dongRateArea.price)})</div>
                              <div class="nn-map-name-marker-point"></div> 
                            </div>
                              `,
              }}></Marker>
          )}
        </div>
      </div>
    );
  };

  const handleClickEvent = (e) => {
    if (selectDongRateArea) {
      setSelectDongRateArea(null);
    }
    if (!areaModifyMode) {
      return;
    }
    createPolygonPath(e);
  };

  const createPolygonPath = (e) => {
    console.log(e);
    let lat = e.coord._lat;
    let lng = e.coord._lng;
    let minDist = 1000;
    dongRateAreaList.forEach((path) => {
      path.polygon.forEach((p) => {
        const p1 = { lat: p._lat, lng: p._lng };
        const p2 = { lat: e.coord._lat, lng: e.coord._lng };
        const distance = getDist(p1, p2);
        if (minDist > distance) {
          minDist = distance;
          const temp = Math.pow(2, zoom - 14);
          if (distance < 100 / temp) {
            lat = p1.lat;
            lng = p1.lng;
          }
        }
      });
    });
    const newPolygon = new navermap.LatLng(lat, lng);
    setNewPolygonPathList([...newPolygonPathList, newPolygon]);
    // let newPath = this.state.paths;
    // newPath.push(new this.navermaps.LatLng(point.coord.y, point.coord.x));
    // this.setState({ paths: newPath }, () => console.log(this.state.paths));
  };

  const newAreaPolygon = () => {
    // console.log('newPolygon');
    // console.log('newPolygonPathList');
    // if (newPolygonPathList.length > 1) {
    return (
      <Polygon
        paths={newPolygonPathList}
        fillColor={'#ff0000'}
        fillOpacity={0.3}
        strokeColor={'#ff0000'}
        strokeOpacity={0.6}
        strokeWeight={3}></Polygon>
    );
    // } else {
    //   return <div></div>;
    // }
  };

  const overlayPolygon = () => {
    if (isSelectArea) {
      return (
        <Polygon
          paths={overlayPolygonList}
          fillColor={'#000'}
          fillOpacity={0.3}
          strokeColor={'#000'}
          strokeOpacity={0}
          strokeWeight={3}></Polygon>
      );
    }
  };
  const handleOpenRegisterDongModal = (data, isCopy) => {
    data && setModifyDongRate(data);
    isCopy && setIsCopy(true);
    setOpenModalBackground(true);
    setOpenRegisterDongDialog(true);
  };
  const handleCloseRegisterDongModal = () => {
    setOpenModalBackground(false);
    setIsCopy(false);
    setModifyDongRate(null);
    setOpenRegisterDongDialog(false);
    getDongRateList();
  };
  const handleOpenRegisterAreaModal = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (selectDongRateArea) {
      setSelectDongRateArea(null);
    }
    if (data) {
      setModifyDongRateArea(data);
      setNewPolygonPathList(data.polygon);
    }
    const dongRateOpenTime = reactLocalStorage.getObject(
      Const.appName + '#registDongRateOpen'
    );
    let temp = ""
    if(dongRateOpenTime) {
      temp = moment(dongRateOpenTime).add(1, 'd').format('YYYY-MM-DD HH:mm:ss');
    }
    if(!dongRateOpenTime || Object.keys(dongRateOpenTime).length === 0 || moment().isAfter(temp)){
      setOpenModalBackground(true);
      setOpenRegisterAreaGuideDialog(true);
    }

    setAreaModifyMode(true);
    naverRef.map.setCursor(`url('${cursor}'), default`);
  };
  const handleCloseRegisterAreaGuideModal = () => {
    setOpenModalBackground(false);
    setOpenRegisterAreaGuideDialog(false);
  };

  const handleCloseSideBarButton = () => {
    setSelectDongRate(null);
    setOriginalDongRateAreaList([]);
    setDongRateAreaList([]);
    setMapModalStatus(0);
  };

  const handleSelectDongRate = (item) => {
    // select
    setMapModalStatus(1);
    setSelectDongRate(item);
    getDongRateAreaList(item.idx);
    // 지도 작업 초기화 별도 handle로 뺴기
    setAreaModifyMode(false);
    naverRef.map.setCursor(`auto`);
    setNewPolygonPathList([]);
  };

  const handelSelectDongRateArea = () => {};

  const handleOpenAreaFormDialog = (data) => {
    data && setModifyDongRateArea(data);
    setOpenRegisterAreaFormDialog(true);
  };
  const handleCloseAreaFormDialog = () => {
    setModifyDongRateArea(null);
    setOpenRegisterAreaFormDialog(false);
    setNewPolygonPathList([]);
    getDongRateAreaList();
  };

  const mapGoToCenter = () => {
    console.log('center');
    const mapCenter = mapCenterLocation.get();
    naverRef.setCenter(new navermap.LatLng(mapCenter.lat, mapCenter.lng));
  };

  const handelBoundsChange = () => {
    // if (naverMapLoad && naverRef.getBounds()) {
    // if (naverMapLoad) {
    //   // if (isReadyOverlay) {
    //   console.log('-------------------------' + isReadyOverlay);
    //   // setIsReadyOverlay(false);
    //   setTimeout(() => {
    //     console.log(naverRef);
    //     // console.log(b._max);
    //     // console.log(b._max._lat);
    //     if (naverRef) {
    //       const b = naverRef.getBounds();
    //       const list = [
    //         [
    //           new navermap.LatLng(b._max._lat, b._max._lng),
    //           new navermap.LatLng(b._max._lat, b._min._lng),
    //           new navermap.LatLng(b._min._lat, b._min._lng),
    //           new navermap.LatLng(b._min._lat, b._max._lng),
    //         ],
    //         [
    //           new navermap.LatLng(37.37544345085402, 127.11224555969238),
    //           new navermap.LatLng(37.37230584065902, 127.10791110992432),
    //           new navermap.LatLng(37.35975408751081, 127.10795402526855),
    //           new navermap.LatLng(37.359924641705476, 127.11576461791992),
    //           new navermap.LatLng(37.35931064479073, 127.12211608886719),
    //           new navermap.LatLng(37.36043630196386, 127.12293148040771),
    //           new navermap.LatLng(37.36354029942161, 127.12310314178465),
    //           new navermap.LatLng(37.365211629488016, 127.12456226348876),
    //           new navermap.LatLng(37.37544345085402, 127.11224555969238),
    //         ],
    //       ];
    //       setOverlayPolygonList(list);
    //     }
    //     // console.log('-------------------------' + isReadyOverlay);
    //     // setIsReadyOverlay(true);
    //   }, 500);
    //   // }
    // }
  };

  const handleDragEnd = (e) => {
    naverRef.getCenter();
    // setMapCenterLocation(mapCenterTempLocation);
  };
  const handleOnIdle = () => {
    console.log('onIdle');
    // console.log(naverMapLoad)
    if (naverMapLoad) {
      const zoom = naverRef.getZoom();
      console.log(zoom);
      // console.log(naverRef.getBounds());
      // setMapCenterLocation(mapCenterTempLocation);
    }
  };

  const handleCenterChanged = (e) => {
    // console.log('handleCenterChanged');
    // console.log(e);
    // setMapCenterTempLocation({ lng: e._lng, lat: e._lat });
  };

  const handleAreaSave = () => {
    setAreaModifyMode(false);
    naverRef.map.setCursor(`auto`);
    handleOpenAreaFormDialog(null);
  };

  const handleToggleNameMarker = () => {
    setOpenNameMarker(!openNameMarker);
  };
  const handleToggleBackMarker = () => {
    setNewPolygonPathList(newPolygonPathList.slice(0, newPolygonPathList.length - 1));
  };
  const handleToggleReloadMarker = () => {
    setNewPolygonPathList([]);
    console.log(newPolygonPathList);
  };
  const handleClose = () => {
    close();
  };

  return (
    <div className="nn-modal-background">
      <div className="nn-modal-square-container w-full h-full">
        <div className="nn-modal-title-container">
          <span>동별 요금제</span>
          <div className="nn-modal-close" onClick={handleClose}></div>
        </div>
        <div className="nn-modal-title-divider"></div>
        <div className="relative flex w-full h-full">
          {/*<div className="relative flex w-full h-[900px]">*/}
          <div className={`absolute flex h-full left-0 top-0 z-[11] w-full`}>
            {/*<div className={`absolute flex h-full left-0 top-0 z-[11] ${openModalBackground && 'w-full'}`}>*/}
            <div className="w-[345px] h-full bg-white flex flex-column nn-modal-border-left">
              <div className="flex items-center justify-between h-[60px] p-[10px] nn-modal-border-bottom">
                <div className="text-[15px] nn-noto-font-bold">요금제 그룹 목록</div>
                <div
                  className="nn-modal-button bg-[#eee] px-[10px] py-[8px] text-[12px]"
                  onClick={() => handleOpenRegisterDongModal(null, false)}>
                  요금제 그룹등록
                </div>
              </div>
              <div className="nn-modal-sidebar-scroll-container">
                {dongRateList.map((item) => dongRateListRender(item))}
              </div>
            </div>
            {selectDongRate && (
              <div className="w-[345px] h-full bg-white flex flex-column nn-modal-border-left">
                <div className="flex items-center justify-between h-[60px] p-[10px] nn-modal-border-bottom">
                  <div className="text-[15px] nn-noto-font-bold">
                    {selectDongRate ? selectDongRate.name : ''}
                    {/* {'동별요금제'} */}
                  </div>
                  <div
                    className="nn-modal-button bg-[#eee] px-[10px] py-[8px] text-[12px]"
                    onClick={(e) => handleOpenRegisterAreaModal(e, null)}>
                    구역추가
                  </div>
                </div>
                <div className="relative p-[10px]">
                  <div
                    className="flex w-full pl-[10px] h-[40px] items-center justify-between"
                    style={{ borderRadius: '5px', border: '1px solid #c1c1c1' }}>
                    <input
                      style={{ boxShadow: 'none', border: 'none', width: '80%', outline: 'none' }}
                      placeholder={'구역명'}
                      onChange={(e) => searchDongRateArea(e.target.value)}
                    />
                    <img className="w-[14.25px] h-[14.25px] mr-[10px]" src={searchPng} />
                  </div>
                </div>
                <div className="flex flex-column flex-1 h-full">
                  <div className="flex bg-[#2E2E2E] text-white h-[50px] items-center">
                    <div className="w-[130px] text-center">구역명</div>
                    <div className="flex-1 text-center">금액</div>
                    <div className="flex-1 text-center">고정금액</div>
                    <div className="flex-1 text-center">수정/삭제</div>
                  </div>
                  <div style={{ height: 'calc(100vh - 280px' }} className="nn-modal-sidebar-scroll-container">
                    {dongRateAreaList.map((dongRateArea) => getDongRateAreaRender(dongRateArea))}
                  </div>
                </div>
              </div>
            )}
            <div className="relative h-full">
              {selectDongRate && (
                <div className="absolute left-0 top-[50%] z-[12]">
                  <div className="nn-map-side-button pl-[4px]" onClick={handleCloseSideBarButton}>
                    <div className="nn-left-arrow"></div>
                  </div>
                </div>
              )}
            </div>
            <div className="relative flex-1 h-full w-full z-[11]">
              {openRegisterDongDialog && (
                <DeliveryPriceDongRateRegisterGroupDialog
                  close={handleCloseRegisterDongModal}
                  data={modifyDongRate}
                  isCopy={isCopy}
                />
              )}
              {openRegisterAreaGuideDialog && (
                <DeliveryPriceDongRateRegisterAreaGuideDialog close={handleCloseRegisterAreaGuideModal} />
              )}
              {openRegisterAreaFormDialog && (
                <DeliveryPriceDongRateRegisterAreaFormDialog
                  close={handleCloseAreaFormDialog}
                  data={modifyDongRateArea}
                  paths={newPolygonPathList}
                  dongRateIdx={selectDongRate.idx}
                />
              )}
              <NaverMap
                naverRef={(ref) => {
                  naverRef = ref;
                }}
                defaultZoom={zoom}
                onClick={(e) => handleClickEvent(e)}
                // onRightclick={(e) => console.log(e)}
                // onKeydown={(e) => console.log(e)} // 27
                // onMousemove={(e) => console.log(e)}
                // onBoundsChanged={handelBoundsChange}
                // onDragend={(e) => handleDragEnd(e)}
                onIdle={(e) => handleOnIdle(e)}
                onZoomChanged={(e) => setZoom(e)}
                // center={navermap.LatLng(mapCenterLocation.lng, mapCenterLocation.lat)}
                // onCenterChanged={(e) => handleCenterChanged(e)}
                className="w-full h-full">
                {dongRateAreaList.map((dongRateArea) => {
                  if (modifyDongRateArea) {
                    if (modifyDongRateArea.idx === dongRateArea.idx) {
                      return null;
                    }
                    return areaPolygon(dongRateArea);
                  } else {
                    return areaPolygon(dongRateArea);
                  }
                  return areaPolygon(dongRateArea);
                })}
                {newPolygonPathList.length > 0 && newAreaPolygon()}
                {overlayPolygon()}
              </NaverMap>
              {areaModifyMode && (
                <div
                  style={{ transform: 'translateX(-50%)' }}
                  className="flex flex-column absolute left-[50%] bottom-20 z-[11]">
                  <div className="flex justify-center items-center">
                    <Button
                      className="nn-modal-button h-[50px] w-[200px]"
                      style={{backgroundColor: newPolygonPathList.length >= 3 ? "#fadc00" : "#c1c1c1", boxShadow: "0px 3px 6px rgba(0,0,0,0.1)", color: newPolygonPathList.length >= 3 ? "#000": "#fff"}}
                      disabled={newPolygonPathList.length < 3}
                      onClick={handleAreaSave}
                      htmlType="button">
                      구역저장
                    </Button>
                  </div>
                </div>
              )}
              {selectDongRateArea && (
                <div
                  style={{ transform: 'translateX(-50%)', backgroundColor: 'rgba(0, 0, 0, 0.7)', borderRadius: 34 }}
                  className="flex flex-column absolute left-[50%] bottom-20 z-[11] text-white px-[10px] py-[15px]">
                  빈곳을 클릭하시면 선택이 해제됩니다
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-column absolute right-0 top-0 z-[11] pt-[30px] pr-[50px]">
            {openNameMarker ? (
              <div className="w-[50px] h-[50px] bg-black flex items-center justify-center rounded-[10px] mb-[10px] ">
                <div className="cursor-pointer" onClick={handleToggleNameMarker}>
                  <img className="w-[17px]" src={openMarkerPng} />
                </div>
              </div>
            ) : (
              <div className="w-[50px] h-[50px] bg-white flex items-center justify-center rounded-[10px] mb-[10px] ">
                <div className="cursor-pointer" onClick={handleToggleNameMarker}>
                  <img className="w-[17px]" src={closeMarkerPng} />
                </div>
              </div>
            )}
            <div className="w-[50px] h-[50px] bg-white flex items-center justify-center rounded-[10px] mb-[10px] ">
              <div className="cursor-pointer" onClick={handleToggleBackMarker}>
                <img className="w-[17px]" src={backPng} />
              </div>
            </div>
            <div className="w-[50px] h-[50px] bg-white flex items-center justify-center rounded-[10px] mb-[10px] ">
              <div className="cursor-pointer" onClick={handleToggleReloadMarker}>
                <img className="w-[17px]" src={refreshPng} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPriceDongRateDialog;
