import {
  CarOutlined,
  CopyOutlined,
  CopyrightOutlined,
  LogoutOutlined,
  MinusCircleOutlined,
  PhoneOutlined,
  PieChartOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  SkinOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Button, Layout, Modal } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import { setBranchInfo } from '../actions/branchAction';
import { login, logout } from '../actions/loginAction';
import { addOrder, addOrderList, setOrder } from '../actions/orderAction';
import { setRider } from '../actions/riderAction';
import {
  websockConnected,
  websockDisconnected,
  websockDuplicated,
} from '../actions/websocketAction';
import {
  httpGet,
  httpGetWithNoLoading,
  httpPost,
  httpPostWithNoLoading,
  httpUrl,
} from '../api/httpClient';
import Const from '../const';
import '../css/index.css';
import '../css/modal.css';
import '../css/modal_m.css';
import {
  getOrderStatusValues,
  getPaymentMethodValues,
  mainMenus,
} from '../lib/util/codeUtil';
import { speak } from '../tts/tts';
import { cloneDeep } from 'lodash';
import { comma } from '../lib/util/numberUtil';
import {getOrderShareViewInfo} from '../lib/util/orderShareUtil';

const orderStatusValues = getOrderStatusValues();
const paymentMethodValues = getPaymentMethodValues();
const isInProgressOrder = (orderStatus) => {
  return orderStatus > 0 && orderStatus < 4;
};
const isCompletedOrder = (orderStatus) => {
  return orderStatus > 3 && orderStatus < 6;
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      frIdx: 0,
      companyIdNcash: 0,
    };
  }

  logout = () => {
    httpPost(httpUrl.logout, [], {})
      .then(() => {
        this.props.onLogout();
        global.location.href = '/';
      })
      .catch((e) => {
        global.location.href = '/';
      });
  };

  componentDidMount() {
    // 접수/배정/픽업 주문 폴링
    this.getOrderList();
    global.getOrderList = this.getOrderList;
    this.getBranch();
    this.getCompanyIdNcash();
    // this.pollingOrder = setInterval(
    //   this.getOrderList,
    //   Const.orderPollingInterval
    // );
    // // 완료/취소 주문 폴링
    // // this.getCompleteOrderList();
    // // this.pollingCompleteOrder = setInterval(
    // //   this.getCompleteOrderList,
    // //   Const.completeOrderPollingInterval
    // // );

    // // 기사정보 폴링
    // this.getRiderList();
    // this.pollingRider = setInterval(
    //   this.getRiderList,
    //   Const.riderPollingInterval
    // );
    // this.renewDeliveryData();
    // this.pollingDeliveryData = setInterval(this.renewDeliveryData, 5000);
  }

  componentWillUnmount() {
    // if (this.pollingOrder) clearInterval(this.pollingOrder);
    // if (this.pollingCompleteOrder) clearInterval(this.pollingCompleteOrder);
    // if (this.pollingRider) clearInterval(this.pollingRider);
    // if (this.pollingDeliveryData) clearInterval(this.pollingDeliveryData);
  }

  getOrderList = async () => {
    console.log('getOrderList start');
    const res = await getOrderShareViewInfo(this.props.loginInfo.branchIdx, true)

    const today = new moment();
    httpPostWithNoLoading(httpUrl.orderList, [], {
      orderStatuses: orderStatusValues,
      paymentMethods: paymentMethodValues,
      pageNum: 1,
      pageSize: 10000,
      startDate: moment(today).add(-1, 'd').format('YYYY-MM-DD HH:mm'),
      endDate: moment(today).add(1, 'd').format('YYYY-MM-DD HH:mm'),
      branchIdxes: res.view
    })
      .then((res) => {
        //console.log("Header.getOrderList", res);
        if (res.result === 'SUCCESS') {
          // console.log("getOrderList success");
          // console.log(res.data.orders);
          const today = moment();
          const orders = res.data.orders.filter(
            (x) =>
              isInProgressOrder(x.orderStatus) ||
              (isCompletedOrder(x.orderStatus) &&
                moment(x.orderDate).format('YYYY-MM-DD') ===
                  today.format('YYYY-MM-DD'))
          );
          this.props.onSetOrder('NEW', orders);
          // reactLocalStorage.setObject(con.appName + "#orders", res.data.orders);
        } else {
          console.log('getOrderList error. result: ', res.result);
          return;
        }
      })
      .catch((e) => {
        console.error('getOrderList. Raised Exception.', e);
        throw e;
      });
  };

  // getCompleteOrderList = () => {
  //   console.log("getCompleteOrderList start");
  //   const endDate = new moment();
  //   httpPostWithNoLoading(httpUrl.orderList, [], {
  //     endDate: formatDate(endDate.add("1", "d")).split(" ")[0],
  //     orderStatuses: [4, 5],
  //     paymentMethods: [1, 2, 3],
  //     pageNum: 1,
  //     pageSize: 100000,
  //     startDate: "1990-01-01",
  //   })
  //     .then((res) => {
  //       if (res.result === "SUCCESS") {
  //         // console.log("getCompleteOrderList success");
  //         this.props.onSetOrder("COMPLETE", res.data.orders);
  //       } else {
  //         // console.log("getCompleteOrderList error");
  //         return;
  //       }
  //     })
  //     .catch((e) => {
  //       // console.log("getOrderList error");
  //       throw e;
  //     });
  // };

  getRiderList = () => {
    console.log('getRiderList start');

    const pageNum = 1;
    const pageSize = 100000;
    const riderLevel = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const userStatus = '';
    const searchName = '';
    const riderStatus = '';

    httpGetWithNoLoading(
      httpUrl.riderList,
      [pageSize, pageNum, searchName, userStatus, riderLevel, riderStatus],
      {}
    )
      .then((res) => {
        if (res.result === 'SUCCESS') {
          // console.log("getRiderList success");
          this.props.onSetRider(res.data.riders);
          // reactLocalStorage.setObject(con.appName + "#riders", res.data.riders);
        } else {
          // console.log("getRiderList error");
          return;
        }
      })
      .catch((e) => {
        // console.log("getRiderList error");
        throw e;
      });
  };

  getDeliveryDataAsync = async (hideLoading) => {
    try {
      const res = await httpGetWithNoLoading(
        httpUrl.getBranch,
        [this.props.loginInfo.branchIdx],
        {},
        hideLoading
      );
      if (res.result !== 'SUCCESS') {
        console.log('getDeliveryDataAsync failed. result: ' + res.result);
        return res;
      }
      this.props.setBranchInfo(res.data);
      console.log('getDeliveryDataAsync success. result: ' + res.result);
      return res;
    } catch (e) {
      console.warn('getDeliveryDataAsync raised exception. e: ' + e);
      return { result: 'EXCEPTION' };
    }
  };

  // 지연시간 팝업 데이터
  renewDeliveryData = async () => {
    const prevDeliveryData = await reactLocalStorage.getObject(
      Const.appName + '#deliverydata'
    );
    // if (prevDeliveryData.pickupTimeMap) {
    //   const prevPickupTimeMapKeys = Object.keys(prevDeliveryData.pickupTimeMap);
    //   for (let i = 0; i < prevPickupTimeMapKeys.length; i++) {
    //     const value = prevDeliveryData.pickupTimeMap[prevPickupTimeMapKeys[i]];
    if (prevDeliveryData.delayPickupTime) {
      // console.log("pickupTimeMapKeys[i]");
      // console.log(prevPickupTimeMapKeys[i]);

      this.setState({ prevFastestDelayTime: prevDeliveryData.delayPickupTime });

      // break;
    }

    this.getDeliveryDataAsync(true).then((res) => {
      if (res.result === 'SUCCESS') {
        this.setState({
          deliveryEnabled: res.data.deliveryEnabled,
          branchTel: res.data.tel,
        });

        const deliveryData = {
          deliveryEnabled: res.data.deliveryEnabled,
          delayPickupTime: res.data.delayPickupTime,
          // pickupTimeMap: {
          //   0: res.data.pickupAvTime0,
          //   5: res.data.pickupAvTime5,
          //   10: res.data.pickupAvTime10,
          //   15: res.data.pickupAvTime15,
          //   20: res.data.pickupAvTime20,
          //   25: res.data.pickupAvTime25,
          //   30: res.data.pickupAvTime30,
          //   35: res.data.pickupAvTime35,
          //   40: res.data.pickupAvTime40,
          //   45: res.data.pickupAvTime45,
          //   50: res.data.pickupAvTime50,
          //   55: res.data.pickupAvTime55,
          //   60: res.data.pickupAvTime60,
          //   70: res.data.pickupAvTime70,
          //   1005: res.data.pickupAvTime5After,
          //   1010: res.data.pickupAvTime10After,
          //   1015: res.data.pickupAvTime15After,
          //   1020: res.data.pickupAvTime20After,
          //   1025: res.data.pickupAvTime25After,
          // },
        };
        // const pickupTimeMapKeys = Object.keys(deliveryData.pickupTimeMap);
        // for (let i = 0; i < pickupTimeMapKeys.length; i++) {
        //   const value = deliveryData.pickupTimeMap[pickupTimeMapKeys[i]];
        //   if (value) {
        //     console.log("pickupTimeMapKeys[i]");
        //     console.log(pickupTimeMapKeys[i]);
        //     this.setState({ fastestDelayTime: pickupTimeMapKeys[i] });
        //     break;
        //   }
        // }

        reactLocalStorage.setObject(
          Const.appName + '#deliverydata',
          deliveryData
        );
        reactLocalStorage.setObject(
          Const.appName + '#deliveryStatShow',
          res.data.deliveryStatShow
        );

        if (prevDeliveryData.delayPickupTime) {
          if (deliveryData.delayPickupTime > 0) {
            if (
              deliveryData.delayPickupTime !== this.state.prevFastestDelayTime
            ) {
              Modal.info({
                title: '지연시간 알림',
                content:
                  '지연시간 ' + deliveryData.delayPickupTime + '분 입니다.',
              });
              speak('지연시간 ' + deliveryData.delayPickupTime + '분 입니다');
            }
          } else {
            Modal.info({
              title: '지연시간 알림',
              content: `지연이 해제되었습니다.`,
            });
            speak('지연이 해제되었습니다');
          }
        } else {
          if (
            deliveryData.delayPickupTime !== prevDeliveryData.delayPickupTime
          ) {
            Modal.info({
              title: '지연시간 알림',
              content:
                '지연시간 ' + deliveryData.delayPickupTime + '분 입니다.',
            });
            speak('지연시간 ' + deliveryData.delayPickupTime + '분 입니다');
          }
        }
      }
    });
  };

  getBranch = async () => {
    const res = await httpGet(
      httpUrl.getBranch,
      [this.props.loginInfo.branchIdx],
      {}
    );
    if (
      res.result === 'SUCCESS' &&
      res.data !== null &&
      res.data !== undefined
    ) {
      this.props.setBranchInfo(res.data);
    }
  };

  getCompanyIdNcash = async () => {
    const res = await httpGet(
      httpUrl.getCompanyIdInfo,
      [this.props.loginInfo.branchIdx],
      {}
    );
    if (
      res.result === 'SUCCESS' &&
      res.data !== null &&
      res.data !== undefined
    ) {
      this.setState({
        companyIdNcash:
          res.data?.ncash -
          (res.data?.misu ?? 0) -
          (res.data?.misu2 ?? 0) -
          (res.data?.misu3 ?? 0),
      });
      this.props.onLogin({
        ...this.props.loginInfo,
        companyIdNcash:
          res.data?.ncash -
          (res.data?.misu ?? 0) -
          (res.data?.misu2 ?? 0) -
          (res.data?.misu3 ?? 0),
      });
    }
  };

  render() {
    console.log(this.props.history.location.pathname);
    console.log('frIdx : ' + this.state.frIdx);
    console.log(this.props.loginInfo);
    const agencyNameList = ['예치금관리', '일차감', '계정관리'];
    let newMenus = cloneDeep(mainMenus);
    newMenus =
      this.props.branchInfo.companyId === 'connect9'
        ? newMenus.filter((item) => !agencyNameList.includes(item.name))
        : newMenus;

    const url = Const.adminUrl;

    let currentPage = newMenus.find(
      (x) => x.url === this.props.history.location.pathname
    );
    return (
      <Layout.Header
        style={{
          background: '#fff',
          padding: 0,
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          zIndex: 3,
        }}>
        <div
          style={{
            backgroundColor: '#000',
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#fff',
            textAlign: 'left',
          }}
          className="header-menu-wrapper">
          <div className="menu-wrapper desk">
            {newMenus.map((row) => {
              if (
                this.props.loginInfo.menuAuths != null &&
                this.props.loginInfo.menuAuths.find((idx) => row.idx === idx)
              ) {
                return (
                  <div
                    key={row.idx}
                    // frIdx={this.state.frIdx}
                    onClick={() => this.props.history.push(row.url)}
                    className={
                      'top-menu ' +
                      (row.idx === currentPage.idx ? 'active' : '')
                    }>
                    {row.icon}&nbsp;
                    {row.name}
                    {/* <div className="header-delay-status">
                    {this.state.deliveryData !== this.state.deliveryStatShow &&
                    this.renewDeliveryData.deliveryData > 20
                      ? `지연시간` + this.renewDeliveryData.deliveryData
                      : null}
                  </div> */}
                  </div>
                );
              }
            })}
          </div>
          <div className="menu-wrapper mobile">
            {newMenus.map((row) => {
              if (
                this.props.loginInfo.menuAuths &&
                this.props.loginInfo.menuAuths.find((idx) => row.idx === idx)
              ) {
                return (
                  <div
                    key={row.idx}
                    // frIdx={this.state.frIdx}
                    onClick={() => this.props.history.push(row.url)}
                    className={
                      'top-menu ' +
                      (row.idx === currentPage.idx ? 'active' : '') +
                      (row.className && ' ' + row.className)
                    }>
                    {row.icon}&nbsp;
                    {row.name}
                  </div>
                );
              }
            })}
          </div>

          {/* <div className="header"> 모바일 햄버거
            <div className="header-top-menu mobile">
              <div className="ham-menu" onClick={() => { this.setState({ openSlideMenu: 1 }) }}>
                <div /><div /><div />
              </div>

              <div className="header-logo">
                <Link to='/receptionstatus'><img src={require('../img/header/header_logo-m-02.png').default} alt="logo" /></Link>
              </div>
            </div>

            {this.state.openSlideMenu === 1 &&
              <div className="slide-menu-overaly mobile" onClick={() => { this.setState({ openSlideMenu: 0 }) }}>
                <div className="slide-menu" >
                  <div>
                    <div> <img src={require('../img/header/reset.png').default} alt="close" /></div>
                    <div> <img src={require('../img/header/shop.png').default} alt="shop" />  </div>


                  </div>

                  <div>
                    <Link to='/receptionStatus'><div>  접수현황 </div></Link>
                    <Link to='/franchiseMain'><div> 가맹점관리 </div></Link>
                    <Link to='/riderMain'><div>  라이더관리 </div></Link>

                    <div onClick={() => { this.logout() }}> <img src={require('../img/header/logout.png').default} alt="logout" /> 로그아웃 </div>
                  </div>

                </div>
              </div>
            } </div> 모바일버전 끝 */}

          <div className="menu-right">
            {this.props.branchInfo.companyId === 'connect9' && (
              <div className="desk" style={{ display: 'inline-block' }}>
                <a
                  href="https://docs.google.com/spreadsheets/d/1VRviSqqZPnhAKaly86wW73DeOENV1BxxJBtuVzloMRU/edit#gid=655211654"
                  target="_blank"
                  rel="noreferrer">
                  <Button icon={<CarOutlined />} className="tabBtn noticeTab">
                    렌트 신청
                  </Button>
                </a>
                <a
                  href="https://docs.google.com/spreadsheets/d/1jJsau_XoVORrD7JDhKLXhk-x7TCfcN97VyzlJZaRjtQ/edit#gid=160056629"
                  target="_blank"
                  rel="noreferrer">
                  <Button
                    icon={<CopyrightOutlined />}
                    className="tabBtn noticeTab">
                    코인 신청
                  </Button>
                </a>
                <a href={url} target="_blank" rel="noreferrer">
                  <Button
                    icon={<PlusCircleOutlined />}
                    className="tabBtn noticeTab">
                    선충전
                  </Button>
                </a>
                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              </div>
            )}
            {this.props.branchInfo.companyId !== 'connect9' && (
              <div className="desk" style={{ display: 'inline-block' }}>
                {comma(this.state.companyIdNcash)}
                원&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              </div>
            )}
            <div className="desk" style={{ display: 'inline-block' }}>
              {this.props.loginInfo.branchName}&nbsp;&nbsp;&nbsp;|
              {/* {this.props.loginInfo.userId}&nbsp;&nbsp;&nbsp;| */}
            </div>
            <div className="desk" style={{ display: 'inline-block' }}>
              &nbsp;&nbsp;&nbsp;{this.props.loginInfo.id}{' '}
              (관리자)&nbsp;&nbsp;&nbsp;|
              {/* {this.props.loginInfo.userId}&nbsp;&nbsp;&nbsp;| */}
            </div>
            <div
              style={{ display: 'inline-block', cursor: 'pointer' }}
              className="desk"
              onClick={() => {
                // this.setState({ visible: true });
                this.logout();
              }}>
              &nbsp;&nbsp;&nbsp;로그아웃
            </div>
            <div
              style={{ display: 'inline-block', cursor: 'pointer' }}
              className="mobile"
              onClick={() => {
                // this.setState({ visible: true });
                this.logout();
              }}>
              &nbsp;&nbsp;&nbsp;
              <LogoutOutlined />
            </div>
          </div>
        </div>
        {/* <Modal
          visible={this.state.visible}
          title="로그아웃"
          okText="확인"
          cancelText="취소"
          onOk={this.logout}
          onCancel={() => {
            this.setState({ visible: false });
          }}
          destroyOnClose={true}
        >
          <div>로그아웃 하시겠습니까?</div>
        </Modal> */}
      </Layout.Header>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo,
    websockInfo: state.websock,
    orderReducer: state.orderReducer,
    riderReducer: state.riderReducer,
    branchInfo: state.branchReducer.branchInfo,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (userinfo) => dispatch(login(userinfo)),
    onLogout: () => dispatch(logout()),
    onWebsockConnect: () => dispatch(websockConnected()),
    onWebsockDisconnect: () => dispatch(websockDisconnected()),
    onWebsockDuplicate: () => dispatch(websockDuplicated()),
    onSetOrder: (category, orderList) =>
      dispatch(setOrder(category, orderList)),
    onAddOrder: (category, order) => dispatch(addOrder(category, order)),
    onAddOrderList: (category, orderList) =>
      dispatch(addOrderList(category, orderList)),
    onSetRider: (riderList) => dispatch(setRider(riderList)),
    setBranchInfo: (branchInfo) => dispatch(setBranchInfo(branchInfo)),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
