import { DatePicker, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../../api/httpClient';
import { customError } from '../../../api/Modals';
import '../../../css/modal.css';
import CloseIcon from '../../../img/login/close.png';

const { RangePicker } = DatePicker;
const today = new Date();

class RiderListDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      category: '',
      startDate: moment(today).add(-1, 'M').format('YYYY-MM-DD'),
      endDate: moment(today).format('YYYY-MM-DD'),
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onChangeStatus = (value) => {
    this.setState(
      {
        category: value === 'NONE' ? '' : value,
        pagination: {
          current: 1,
          pageSize: 5,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  getList = () => {
    const { status } = this.props;
    let searchName = '';
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let userStatus = 1;
    let riderLevels = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    let riderStatus = status;
    httpGet(
      httpUrl.riderList,
      [pageSize, pageNum, searchName, userStatus, riderLevels, riderStatus],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState(
          {
            list: res.data.riders,
            pagination,
          },
          () => console.log(this.state.list)
        );
      })
      .catch((e) => {
        customError('목록 에러', '에러가 발생하여 목록을 불러올수 없습니다.');
      });
  };

  onChange = (dateString) => {
    this.setState(
      {
        startDate: moment(dateString[0]).format('YYYY-MM-DD'),
        endDate: moment(dateString[1]).format('YYYY-MM-DD'),
        pagination: {
          current: 1,
          pageSize: 5,
        },
      },
      () => this.getList()
    );
  };

  render() {
    const columns = [
      {
        title: '이름',
        dataIndex: 'riderName',
        className: 'table-column-center',
      },
      {
        title: '번호',
        dataIndex: 'phone',
        className: 'table-column-center',
      },
    ];

    const { close, status } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="rider-Dialog">
          <div className="rider-content">
            <div className="rider-title">
              {status === 1
                ? '근무중 라이더'
                : status === 2
                ? '휴식중 라이더'
                : '퇴근중 라이더'}
            </div>
            <img
              onClick={close}
              src={CloseIcon}
              className="rider-close"
              alt="close"
            />
            <div className="rider-inner">
              <div className="listBlock">
                <Table
                  rowKey={(record) => record.idx}
                  dataSource={this.state.list}
                  columns={columns}
                  pagination={this.state.pagination}
                  onChange={this.handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RiderListDialog;
