import Pdf from '@mikecousins/react-pdf';
import { Button, Form, Image, Input, Modal, Popover } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import axios from 'axios';
import React, { Component } from 'react';
import { httpDelete, httpGet, httpUrl, makeUrl } from '../../../api/httpClient';
import '../../../css/modal.css';
import CloseIcon from '../../../img/login/close.png';
import {
  rentOwnedPaperCodeList,
  riderPaperNoCode,
  riderPaperNoCodeToText,
  riderPaperStatusToResultText,
  riderPaperUploadErrorCode,
} from '../../../lib/util/codeUtil';

class RiderUploadFileDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,

      paperList: null,
    };
    this.formRef = React.createRef();
    this.businessRegistrationRef = React.createRef();
  }

  async componentDidMount() {
    await this.getPaperList();
  }

  getPaperList = async () => {
    const res = await httpGet(
      httpUrl.getRiderPaperList,
      [this.props.data.idx],
      {}
    );

    if (res.data.result === 'SUCCESS') {
      this.setState({
        paperList: res.data.paperList,
      });
    }
  };

  getPaperIdx = (paperNo) => {
    if (this.state.paperList && typeof this.state.paperList === 'object') {
      const targetPaper = this.state.paperList.find(
        (paper) => paper.paperNo === paperNo
      );
      if (targetPaper == null) return null;
      else return targetPaper.paperIdx;
    } else {
      return null;
    }
  };

  getPaperStatus = (paperNo) => {
    if (this.state.paperList && typeof this.state.paperList === 'object') {
      const targetPaper = this.state.paperList.find(
        (paper) => paper.paperNo === paperNo
      );
      if (targetPaper == null) return 1;
      else return targetPaper.status;
    } else {
      return 1;
    }
  };

  uploadRiderPaper = async (file, paperNo) => {
    let formData = new FormData();
    formData.append('riderIdx', this.props.data.idx);
    formData.append('paperNo', paperNo);
    formData.append('file', file);

    const axiosConfig = {
      method: 'post',
      url: makeUrl(httpUrl.uploadRiderPaper, []),
      data: formData,
      withCredentials: true,
    };
    console.log(axiosConfig);
    try {
      const res = await axios(axiosConfig);

      console.log('===== uploadRiderPaper res =====', res);

      if (res.data.data.result === 'SUCCESS') this.getPaperList();
    } catch (e) {
      if (
        Object.keys(riderPaperUploadErrorCode).includes(e.response.data.message)
      ) {
        Modal.warn({
          title: '업로드 실패',
          content: riderPaperUploadErrorCode[e.response.data.message],
        });
      } else {
        Modal.warn({
          title: '업로드실패',
          content: '업로드 오류 발생',
        });
      }
    }
  };

  handleChange = (key, value) => {
    let state = { ...this.state.fileData };
    state[key] = value;
    this.setState({ fileData: state }, () => {
      console.log(this.state.fileData);
    });
  };

  renderPopoverContent = (paperNo) => {
    const src = makeUrl(
      httpUrl.riderPaperView,
      [this.props.data.idx, paperNo],
      {}
    );
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {this.state.showPdf && (
          <Pdf
            onDocumentLoadSuccess={() => this.setState({ showPdf: true })}
            onDocumentLoadFail={() => this.setState({ showPdf: false })}
            file={src}
            withCredentials={true}
          />
        )}
        {!this.state.showPdf && (
          <Image
            onError={() => this.setState({ showPdf: true })}
            style={{ width: 400, height: 300 }}
            src={src}
            alt="필수서류"
          />
        )}
      </div>
    );
  };

  confirmDeleteRiderPaper = async (paperIdx, paperNo) => {
    Modal.confirm({
      title: '파일삭제',
      content: '파일이 완전히 삭제되며, 복구할 수 없습니다. 삭제하시겠습니까?',
      onOk: async () => await this.deleteRiderUploadFile(paperIdx, paperNo),
      onCancel: () => {},
    });
  };

  deleteRiderUploadFile = async (paperIdx, paperNo) => {
    const res = await httpDelete(httpUrl.riderPaperDelete, [], {
      riderIdx: this.props.data.idx,
      paperIdx,
      paperNo,
    });
    if (res.data.result === 'SUCCESS') {
      Modal.info({
        title: '삭제성공',
        content: '파일 삭제에 성공했습니다.',
      });
      await this.getPaperList();
    } else
      Modal.warn({
        title: '삭제실패',
        content: '파일 삭제에 실패했습니다.',
      });
  };

  styles = {
    PAPER_STATUS_DIV: {
      marginRight: 10,
      width: 50,
    },
    PAPER_VIEW_DIV: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 14,
      fontWeight: 'bold',
      border: '1px solid #d9d9d9',
      width: 200,
      height: 35,
      padding: 10,
      cursor: 'pointer',
    },
  };

  renderFormItem = (paperNo) => {
    if (
      rentOwnedPaperCodeList[this.props.data.bikeRentOwnedCode].includes(
        paperNo
      )
    )
      return (
        <div className="fileUpload-content-block">
          <div>{riderPaperNoCodeToText[paperNo]}</div>
          <div
            style={{
              ...this.styles['PAPER_STATUS_DIV'],
              color: this.getPaperStatus(paperNo) === 2 ? 'blue' : 'red',
            }}>
            {riderPaperStatusToResultText[this.getPaperStatus(paperNo)]}
          </div>
          {(this.getPaperStatus(paperNo) === 2 ||
            this.getPaperStatus(paperNo) === 3) && (
            <Popover
              content={this.renderPopoverContent(paperNo)}
              title="서류보기"
              destroyTooltipOnHide>
              <div onClick={() => {}} style={this.styles.PAPER_VIEW_DIV}>
                <a
                  href={makeUrl(
                    httpUrl.riderPaperView,
                    [this.props.data.idx, paperNo],
                    {}
                  )}
                  style={{ color: 'black' }}
                  download>
                  파일 다운로드
                </a>
              </div>
            </Popover>
          )}

          <FormItem
            name={Object.keys(riderPaperNoCode).find(
              (key) => riderPaperNoCode[key] === paperNo
            )}
            rules={[
              {
                required: true,
                message: '파일을 등록해주세요.',
              },
            ]}>
            <Input
              onChange={(e) => {
                this.uploadRiderPaper(e.target.files[0], paperNo);
              }}
              type="file"
              accept="*/*"
              className="VAN-file-input"
            />
          </FormItem>
          {this.getPaperStatus(paperNo) > 1 && (
            <Button
              onClick={() =>
                this.confirmDeleteRiderPaper(this.getPaperIdx(paperNo), paperNo)
              }>
              파일삭제
            </Button>
          )}
        </div>
      );
  };

  render() {
    const { close } = this.props;
    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="zone-dialog">
          <div className="VAN-content">
            <div className="timeDelay-title">필수서류 제출</div>
            <img onClick={close} src={CloseIcon} alt="close" />
            <div className="filtering-inner">
              <div className="fileUpload-content-block-underline">
                <div className="fileUpload-riderName">
                  기사명 :<span>{this.props.data.riderName}</span>
                </div>
              </div>
              <Form ref={this.formRef} encType="multipart/form-data">
                {Object.values(riderPaperNoCode).map((paperNo) =>
                  this.renderFormItem(paperNo)
                )}
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RiderUploadFileDialog;
