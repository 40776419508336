import { ArrowLeftOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Form, Modal, List } from 'antd';
import React, { Component } from 'react';
import { Marker, NaverMap, Polygon } from 'react-naver-maps';
import { httpGet, httpPost, httpUrl } from '../../../api/httpClient';
import '../../../css/modal.css';
import CloseIcon from '../../../img/login/close.png';
import SelectBox from '../../input/SelectBox';
import { cloneDeep } from 'lodash';
import { deliveryZone } from '../../../lib/util/codeUtil';

const FormItem = Form.Item;

class DeliveryZoneDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navermaps: true,
      mapLat: null,
      mapLng: null,

      deliveryZone: [],
      deliveryZoneForException: [],
      customDeliveryZone: [
        {
          code: 10000,
          text: '풍무동326',
          toggle: false,
          coords: [],
        },
      ],
      selectMenu: 0,
      mapCenter: {
        lat: 37.643623625321474,
        lng: 126.66509442649551,
      },

      // 신규 금지 구역 parameter
      paths: [],
      // viewPaths: [],
      inputName: '',

      selectedExceptionAreaIdx: null,
    };
    this.navermaps = window.naver.maps;
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
    this.onReloadButtonClick = this.onReloadButtonClick.bind(this);
    this.resetExceptionArea = this.resetExceptionArea.bind(this);
    this.updateExceptionArea = this.updateExceptionArea.bind(this);
  }

  componentDidMount() {
    this.state.deliveryZone.forEach((deliveryZone) => {
      deliveryZone.toggle = true;
    });
    this.getList();
  }

  getList = () => {
    httpGet(httpUrl.branchAddrDeliveryList, [1, 1000], {})
      .then((res) => {
        if (res.result === 'SUCCESS') {
          const deliveryZones = res.data.addrDeliveries;
          console.log(deliveryZones);
          deliveryZones.map((deliveryZone) => {
            if (deliveryZone.exceptionArea == null) {
              deliveryZone.exceptionArea = [];
            } else {
              const parsed = JSON.parse(deliveryZone.exceptionArea);
              deliveryZone.exceptionArea = parsed.map((parsed) => this.navermaps.LatLng(parsed[0], parsed[1]));
            }
          });

          this.setState({
            deliveryZone: deliveryZones,
            deliveryZoneForException: cloneDeep(deliveryZones),
          });
        }
      })
      .catch((e) => {
        throw e;
      });
  };

  handleUpdate = async (type) => {
    let failed = [];
    for (let i = 0; i < this.state.deliveryZone.length; i++) {
      console.log(this.state.deliveryZone[i].deliveryStart);
      // if (
      //   this.state.deliveryZone[i].canDeliver !==
      //   this.state.prevDeliveryZone[i].canDeliver
      // ) {
      const res = await httpPost(httpUrl.updateAddrDelivery, [], {
        ...(type === 'start' && {
          deliveryStart: this.state.deliveryZone[i].deliveryStart,
        }),
        ...(type === 'end' && {
          deliveryEnd: this.state.deliveryZone[i].deliveryEnd,
        }),
        idx: this.state.deliveryZone[i].idx,
      });

      console.log('res');
      console.log(res);

      if (res.result !== 'SUCCESS') {
        failed.push(this.state.deliveryZone[i].eupMyeonDong);
      }
      // }
    }
    if (failed.length === 0) {
      Modal.info({
        title: '적용 성공',
        content: '배송가능지역 적용에 성공했습니다.',
      });
    } else {
      Modal.info({
        title: '적용 실패',
        content: `${failed} 지역의 적용에 실패했습니다.`,
      });
    }
    // this.getgetList();
  };

  /*addPath = (e) => {
    let newPath = this.state.paths;
    newPath.push(new this.navermaps.LatLng(e.coord.y, e.coord.x));
    this.setState({ paths: newPath }, () => console.log(this.state.paths));
  };*/

  addPath = (e) => {
    const deliveryZoneForException = cloneDeep(this.state.deliveryZoneForException);
    const target = deliveryZoneForException.find(
      (deliveryZoneItem) => deliveryZoneItem.idx === this.state.selectedExceptionAreaIdx
    );
    let newPath = target.exceptionArea;
    console.log(newPath);
    newPath.push(new this.navermaps.LatLng(e.coord.y, e.coord.x));
    this.setState({ deliveryZoneForException });
    // this.setState({ paths: newPath }, () => console.log(this.state.paths));
  };

  handleToggle = (type, index) => {
    let newState = [...this.state.deliveryZone];
    if (type === 'start') {
      newState[index].deliveryStart = !newState[index].deliveryStart;
    } else if (type === 'end') {
      newState[index].deliveryEnd = !newState[index].deliveryEnd;
    }
    this.setState({ deliveryZone: newState }, () => {
      console.log(this.state.deliveryZone);
    });
  };

  registCustomZone = () => {
    const formData = {
      code: this.state.customDeliveryZone[this.state.customDeliveryZone.length - 1].code + 1,
      text: this.state.inputName,
      toggle: false,
      coords: this.state.paths,
    };

    this.setState(
      {
        customDeliveryZone: this.state.customDeliveryZone.concat(formData),
      },
      () => this.setState({ inputName: '', paths: [] })
    );
  };

  /*handleToggleCustom = (index) => {
    let newState = this.state.customDeliveryZone;
    newState[index].toggle = !newState[index].toggle;

    // if (newState[index].toggle) {
    //   let newViewPath = this.state.viewPaths;
    //   newViewPath.push(newState[index].coords);
    //   this.setState({ customDeliveryZone: newState, viewPaths: newViewPath });
    // } else {
    //   this.setState({ customDeliveryZone: newState, viewPaths: [] });
    // }

    // if (this.state.deleteMode) {
    //   const self = this;
    //   Modal.confirm({
    //     onOk: () => {
    //       self.setState({
    //         customDeliveryZone: self.state.customDeliveryZone.filter(
    //           (item) =>
    //             !(item.code === self.state.customDeliveryZone[index].code)
    //         ),
    //         viewPaths: self.state.viewPaths.filter(
    //           (item) =>
    //             !(item.code === self.state.customDeliveryZone[index].code)
    //         ),
    //       });
    //       return;
    //     },
    //   });
    // } else {
    this.setState(
      {
        customDeliveryZone: newState,
        viewPaths: newState[index].toggle
          ? this.state.viewPaths.concat(this.state.customDeliveryZone[index])
          : this.state.viewPaths.filter(
              (item) =>
                !(item.code === this.state.customDeliveryZone[index].code)
            ),
      },
      () => {
        console.log('viewpath');
        console.log(this.state.viewPaths);
      }
    );
    // }
  };*/

  onBackButtonClick() {
    if(!this.checkSelectedExceptionAreaIdx()) {
      alert('먼저 읍명동을 선택해주세요.');
      return false;
    }
    const deliveryZoneForException = this.state.deliveryZoneForException;
    const target = deliveryZoneForException.find(
      (deliveryZoneItem) => deliveryZoneItem.idx === this.state.selectedExceptionAreaIdx
    );
    let newPath = target.exceptionArea;
    console.log(newPath);
    if (newPath.length === 1) {
      newPath = [];
    } else if (newPath.length > 1) {
      newPath = newPath.slice(0, newPath.length - 1);
    }
    target.exceptionArea = newPath;
    this.setState({ deliveryZoneForException }, () => console.log(this.state.deliveryZoneForException));

    /*this.setState(
      {
        paths: this.state.paths.slice(0, this.state.paths.length - 1),
      },
      () => {
        if (this.state.paths.length === 1) {
          this.setState({
            paths: [],
          });
        }
      }
    );*/
  }

  onReloadButtonClick() {
    if(!this.checkSelectedExceptionAreaIdx()) {
      alert('먼저 읍명동을 선택해주세요.');
      return false;
    }
    const deliveryZoneForException = this.state.deliveryZoneForException;
    const target = deliveryZoneForException.find(
      (deliveryZoneItem) => deliveryZoneItem.idx === this.state.selectedExceptionAreaIdx
    );
    target.exceptionArea = [];
    this.setState({ deliveryZoneForException }, () => console.log(this.state.deliveryZoneForException));

    /*this.setState(
      {
        paths: this.state.paths.slice(0, this.state.paths.length - 1),
      },
      () => {
        if (this.state.paths.length === 1) {
          this.setState({
            paths: [],
          });
        }
      }
    );*/
  }

  resetExceptionArea() {
    if(!this.checkSelectedExceptionAreaIdx()) {
      alert('먼저 읍명동을 선택해주세요.');
      return false;
    }
    this.setState({
      deliveryZoneForException: cloneDeep(this.state.deliveryZone),
    });
  }

  updateExceptionArea = async () => {
    if(!this.checkSelectedExceptionAreaIdx()) {
      alert('먼저 읍명동을 선택해주세요.');
      return false;
    }

    const targetDeliveryZone = cloneDeep(
      this.state.deliveryZoneForException.find(
        (deliveryZone) => deliveryZone.idx === this.state.selectedExceptionAreaIdx
      )
    );

    const exceptionAreaString = JSON.stringify(
      targetDeliveryZone.exceptionArea.map((path) => {
        return [path.y, path.x];
      })
    );

    const res = await httpPost(httpUrl.updateAddrDelivery, [], {
      exceptionArea: exceptionAreaString,
      idx: targetDeliveryZone.idx,
    });

    console.log(res);

    if (res.result === 'SUCCESS') {
      const targetIndex = this.state.deliveryZoneForException.findIndex(
        (deliveryZone) => deliveryZone.idx === this.state.selectedExceptionAreaIdx
      );

      const clone = cloneDeep(this.state.deliveryZone);
      clone[targetIndex].exceptionArea = targetDeliveryZone.exceptionArea;

      this.setState({
        deliveryZone: clone,
      });

      Modal.info({
        title: '적용 성공',
        content: '도착지 예외지역 설정에 성공했습니다.',
      });
    } else {
      Modal.info({
        title: '적용 실패',
        content: `${targetDeliveryZone.eupMyeonDong} 도착지 예외지역 설정에 실패했습니다.`,
      });
    }
  };

  checkSelectedExceptionAreaIdx = () => {
    if (this.state.selectedExceptionAreaIdx != null) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const lat = 37.643623625321474;
    const lng = 126.66509442649551;

    const navermaps = window.naver.maps;
    const { isOpen, close } = this.props;

    return (
      <React.Fragment>
        <div className="flex-modal-overlay" onClick={close} />

        <div className="flex-wrapper">
          <div className="flex-header">
            <div className="flex-title">배송가능지역</div>
            <img onClick={close} src={CloseIcon} className="dialog-close" alt="close" />
          </div>
          <div className="flex-inner zoneBox">
            <div className="zoneBox-inner-left">
              <div className="zone-text">
                배송가능지역(상점)
                <Button onClick={() => this.handleUpdate('start')} style={{ float: 'right' }}>
                  적용하기
                </Button>
              </div>
              <div className="zoneBox-wrap">
                {this.state.deliveryZone.map((obj, idx) => (
                  <div key={obj.idx} className="zone-box">
                    <div
                      className={obj.deliveryStart ? 'zone-b-active' : 'zone-b'}
                      onClick={() => this.handleToggle('start', idx)}>
                      {obj.eupMyeonDong}
                    </div>
                  </div>
                ))}
              </div>

              <div className="zone-text">
                배송가능지역(도착지)
                <Button onClick={() => this.handleUpdate('end')} style={{ float: 'right' }}>
                  적용하기
                </Button>
              </div>
              <div className="zoneBox-wrap">
                {this.state.deliveryZone.map((obj, idx) => (
                  <div key={obj.idx} className="zone-box">
                    <div
                      className={obj.deliveryEnd ? 'zone-b-active' : 'zone-b'}
                      onClick={() => this.handleToggle('end', idx)}>
                      {obj.eupMyeonDong}
                    </div>
                  </div>
                ))}
              </div>

              <div className="zone-text">도착지 예외지역 설정</div>
              <div className="flex flex-row">
                <div className="text-red-600 mr-2">*</div>
                <div className="text-gray-600 text-[10px]">
                  특정 동에 대해 배송가능한 지역을 설정해 놓으면 해당 동이 도착지에서 OFF 되더라도 주문접수가
                  가능합니다.
                </div>
              </div>

              <div className="flex flex-row justify-between">
                <SelectBox
                  className="w-[150px]"
                  width="placeholderToAdjustWidth"
                  options={this.state.deliveryZoneForException.map((deliveryZone) => {
                    return {
                      index: deliveryZone.idx,
                      label: deliveryZone.eupMyeonDong,
                    };
                  })}
                  value={this.state.selectedExceptionAreaIdx}
                  onChange={(value) => {
                    if (this.state.selectedExceptionAreaIdx != null) {
                      this.resetExceptionArea();
                    }
                    this.setState({
                      selectedExceptionAreaIdx: value,
                    });
                  }}
                />
                <div className="flex flex-row">
                  <Button className="ml-2 ant-btn-primary" onClick={this.updateExceptionArea}>
                    적용하기
                  </Button>
                </div>
              </div>
              <div className="w-full flex justify-end mt-[15px]">
                <div className="ml-[5px]">
                  <Button onClick={this.resetExceptionArea}>초기화</Button>
                </div>
                <div className="ml-[5px]">
                  <Button onClick={this.onReloadButtonClick}>영역삭제</Button>
                </div>
                <div className="ml-[5px]">
                  <Button onClick={this.onBackButtonClick}>되돌리기</Button>
                </div>
              </div>

              {/*<div className="zone-text">
                특정 구역
                <Button
                  className="radio-btn"
                  onClick={() => {
                    Modal.confirm({
                      title: '삭제',
                      content: `삭제하시겠습니까?`,
                      onOk: () => {
                        this.setState({
                          customDeliveryZone:
                            this.state.customDeliveryZone.filter(
                              (item) => !item.toggle
                            ),
                          viewPaths: [],
                        });
                      },
                    });
                  }}>
                  삭제하기
                </Button>
              </div>
              <div>
                {this.state.customDeliveryZone.map((obj, idx) => (
                  <div key={obj.code} className="zone-box">
                    <div
                      className={obj.toggle ? 'zone-b-active' : 'zone-b'}
                      onClick={() => this.handleToggleCustom(idx)}>
                      {obj.text}
                    </div>
                  </div>
                ))}
              </div>
              <div className="zone-text">새 구역 등록</div>
              <div>
                <Input
                  value={this.state.inputName}
                  onChange={(e) => this.setState({ inputName: e.target.value })}
                  placeholder="새 구역 이름을 입력해주세요."
                  className="zone-input"
                />
              </div>
              <Button
                type="primary"
                onClick={() => this.registCustomZone()}
                className="zone-btn">
                등록하기
              </Button>*/}
            </div>

            <div className="zoneBox-inner-right">
              {navermaps && (
                <NaverMap
                  defaultZoom={14}
                  center={this.state.mapCenter}
                  // style={{
                  //   width: "550px",
                  //   height: "600px",
                  //   display: "inline-block",
                  // }}
                  className="navermap-region"
                  onClick={(e) => {
                    if (this.state.selectedExceptionAreaIdx != null) {
                      this.addPath(e);
                    } else {
                      alert('먼저 읍면동을 선택해 주세요.');
                    }
                  }}>
                  {this.state.deliveryZoneForException.find((item) => item.idx === this.state.selectedExceptionAreaIdx)
                    ?.exceptionArea.length > 0 && (
                    <Polygon
                      paths={
                        this.state.deliveryZoneForException.find(
                          (item) => item.idx === this.state.selectedExceptionAreaIdx
                        ).exceptionArea
                      }
                      fillColor={'#ff0000'}
                      fillOpacity={0.3}
                      strokeColor={'#ff0000'}
                      strokeOpacity={0.6}
                      strokeWeight={3}
                    />
                  )}
                  {/*{this.state.viewPaths.length > 0 &&
                    this.state.viewPaths.map((elem) => {
                      let sumX = 0;
                      let sumY = 0;

                      elem.coords.map((el) => {
                        sumX += el.x;
                        sumY += el.y;
                      });
                      const meanX = sumX / elem.coords.length;
                      const meanY = sumY / elem.coords.length;

                      const position = navermaps.LatLng(meanY, meanX);

                      return (
                        <>
                          <Polygon
                            paths={elem.coords}
                            fillColor={'#ff0000'}
                            fillOpacity={0.3}
                            strokeColor={'#ff0000'}
                            strokeOpacity={0.6}
                            strokeWeight={3}
                          />
                          <Marker
                            position={position}
                            icon={{
                              content: [
                                '<div style="background-color: black; color: #fddc00; padding: 10px 20px; border-radius: 5px; font-size:16px; transform:translate(-50%,-50%);">' +
                                  elem.text +
                                  '</div>',
                              ].join(''),
                            }}
                            title={elem.text}
                          />
                        </>
                      );
                    })}*/}
                  {/*<Button*/}
                  {/*  type="primary"*/}
                  {/*  icon={<ArrowLeftOutlined />}*/}
                  {/*  style={{ zIndex: 1 }}*/}
                  {/*  onClick={this.onBackButtonClick}*/}
                  {/*/>*/}
                  {/*<Button*/}
                  {/*  type="primary"*/}
                  {/*  icon={<ReloadOutlined />}*/}
                  {/*  style={{ zIndex: 1 }}*/}
                  {/*  onClick={this.onReloadButtonClick}*/}
                  {/*/>*/}
                </NaverMap>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DeliveryZoneDialog;
