import { Button, Form, Input, Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPost, httpUrl } from '../../../api/httpClient';
import { customAlert, customError } from '../../../api/Modals';
import '../../../css/modal.css';
import '../../../css/modal_m.css';
import { apiResponseResult } from '../../../lib/util/codeUtil';
import SearchFranchiseDialog from '../common/SearchFranchiseDialog';
import CloseIcon from '../../../img/login/close.png';

const FormItem = Form.Item;
const Search = Input.Search;

class SnsSurchargeFrGroupDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },

      selectedFrs: new Set(),
      SearchFrModalOpen: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.data) {
      console.log(this.props.data);
      this.formRef.current.setFieldsValue({
        settingGroupName: this.props.data.name,
      });
      httpGet(httpUrl.getMessageGroupDetail, [this.props.data.idx], {})
        .then((res) => {
          if (res.result === apiResponseResult.SUCCESS) {
            this.setState({
              selectedFrs: res.data.frs,
            });
          }
        })
        .catch((e) =>
          Modal.info({ title: '조회실패', content: '조회에 실패했습니다.' })
        );
    }
  }

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  handleClear = () => {
    this.formRef.current.resetFields();
  };

  // 그룹추가
  handleSubmit = () => {
    const form = this.formRef.current;
    if (this.state.selectedFrs.length === 0) {
      Modal.info({
        title: '그룹 생성 오류',
        content: '그룹에 포함할 가맹점을 선택해주세요.',
      });
      return;
    }
    let frIndexes = [];
    this.state.selectedFrs.forEach((fr) => frIndexes.push(fr.idx));
    httpPost(httpUrl.createMessageGroup, [], {
      frIndexes,
      name: form.getFieldValue('settingGroupName'),
    }).then((res) => {
      if (res.result === 'SUCCESS') {
        customAlert(
          '새로운 그룹 추가',
          form.getFieldValue('settingGroupName') + '그룹을 추가 하였습니다.'
        );
        this.handleClear();
        this.props.refresh();
        this.props.close();
      } else {
        customError('목록 에러', '에러가 발생하여 삭제할수 없습니다.');
      }
    });
  };

  //   가맹점 검색
  openSearchFrModal = () => {
    this.setState({ SearchFrModalOpen: true });
  };
  closeSearchFrModal = () => {
    this.setState({ SearchFrModalOpen: false });
    // this.getList()
  };

  render() {
    const { isOpen, close } = this.props;

    return (
      <React.Fragment>
        <div className="surchargeGroup-Dialog-overlay" onClick={close} />
        <div className="surchargeGroup-Dialog">
          <div className="surchargeGroup-content">
            <div className="surchargeGroup-title">그룹 추가</div>
            <img
              onClick={close}
              src={CloseIcon}
              className="addRider-close"
              alt="close"
            />
            <Form ref={this.formRef} onFinish={this.handleSubmit}>
              <div className="snsGrouplayout">
                <div className="contentBlock">
                  <div className="mainTitle">그룹명</div>
                  <FormItem name="settingGroupName" className="selectItem">
                    <Input
                      placeholder="그룹명을 입력해 주세요."
                      className="snsGroup-input"
                      value={this.props.data && this.props.data.name}
                      disabled={this.props.data}
                    />
                  </FormItem>
                </div>
                {!this.props.data && (
                  <div className="contentBlock">
                    <div className="mainTitle">가맹점 검색</div>
                    {this.state.SearchFrModalOpen && (
                      <SearchFranchiseDialog
                        close={this.closeSearchFrModal}
                        multi={true}
                        callback={(selectedFrs) => {
                          let nextSelectedFrs = new Set(this.state.selectedFrs);
                          selectedFrs.forEach((fr) => {
                            if (
                              [...nextSelectedFrs].findIndex(
                                (item) => item.idx === fr.idx
                              ) === -1
                            )
                              nextSelectedFrs.add(fr);
                          });
                          this.setState(
                            {
                              selectedFrs: nextSelectedFrs,
                            },
                            () => console.log(this.state.selectedFrs)
                          );
                        }}
                      />
                    )}

                    <Button
                      className="snsBtn"
                      onClick={() => {
                        this.openSearchFrModal();
                      }}>
                      가맹점 검색
                    </Button>
                  </div>
                )}{' '}
                <div
                  className="contentBlock"
                  style={{ marginTop: 10, overflow: 'auto', height: '60vh' }}>
                  <div className="mainTitle">선택가맹점</div>
                  <div style={{ width: '100%', marginLeft: 10 }}>
                    {[...this.state.selectedFrs].map((item) => {
                      return (
                        <div
                          key={item.idx}
                          style={{
                            display: 'inline-block',
                            fontWeight: 'bold',
                            marginLeft: 10,
                          }}>
                          {item.frName}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="snsGrouplayout-btn">
                  {!this.props.data && (
                    <Button
                      type="primary"
                      onClick={() => this.handleSubmit()}
                      className="snsBtn">
                      등록하기
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnsSurchargeFrGroupDialog);
