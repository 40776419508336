import React, { useEffect, useRef, useState } from 'react';
import { Button, ConfigProvider, DatePicker, Input, Modal, Select, Table, Tooltip } from 'antd';
import { CloseOutlined, LeftOutlined, SearchOutlined } from '@ant-design/icons';
import { columns, detailDataOfFr, placeholderText, url } from '../../../lib/util/settlementUtil';
import locale from 'antd/lib/locale/ko_KR';
import moment from 'moment/moment';
import { comma } from '../../../lib/util/numberUtil';
import axios from 'axios';
import Const from '../../../const';
import { useSelector } from 'react-redux';
const today = moment().format('YYYY-MM-DD');
const TaxReturnDialog = (props) => {
  const title = props.title ? props.title : '';
  const CancelToken = axios.CancelToken;
  const source = useRef(null);
  const loginInfo = useSelector((state) => state.login.loginInfo);
  const [search, setSearch] = useState('');
  const [selectStartDate, setSelectStartDate] = useState(moment(props.startDate).startOf('month').format('YYYY-MM-DD'));
  const [selectEndDate, setSelectEndDate] = useState(moment(props.endDate).endOf('month').format('YYYY-MM-DD'));
  const [selectUserType, setSelectUserType] = useState('');
  const [selectUserTypeStr, setSelectUserTypeStr] = useState('구분');
  const [data, setData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceVat, setTotalPriceVat] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [fr, setFr] = useState({});
  const [isLoadingforDetail, setIsLoadingforDetail] = useState(false);
  const [deliveryVatDetailModalOpen, setDeliveryVatDetailModalOpen] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 80,
  });
  const [paginationForDetail, setPaginationForDetail] = useState({
    total: 0,
    current: 1,
    pageSize: 80,
  });
  useEffect(() => {
    getData();
    return () => {
      if (source.current !== null) {
        source.current.cancel();
      }
    };
  }, [pagination.current]);
  useEffect(() => {
    if (deliveryVatDetailModalOpen) getOrderListofFr();
    return () => {
      if (source.current !== null) {
        source.current.cancel();
      }
    };
  }, [paginationForDetail.current]);
  const getData = async (page) => {
    let current = pagination.current;
    if (page) current = 1;
    setIsLoading(true);
    let customParams = {
      startDate: selectStartDate,
      endDate: selectEndDate,
      companyId: loginInfo.companyId,
      page: current,
      size: pagination.pageSize,
    };
    if (search != null && search !== '') {
      customParams.keyword = search;
      customParams.search = search;
    }
    const datas = await getTaxReturnDate(customParams);
    if (datas) {
      setData(() => {
        return datas.data.contents;
      });
      setPagination({
        ...pagination,
        current: current,
        total: datas.data.totalCount,
      });
      setTotalPrice(datas.data.totalShareCallDeliveryPrice);
      setTotalPriceVat(datas.data.totalShareCallDeliveryPriceVat);
    }
    setIsLoading(false);
  };
  const getTaxReturnDate = async (customParams, paramsUrl) => {
    console.log(customParams);
    if (source.current !== null) {
      source.current.cancel();
    }
    source.current = CancelToken.source();
    try {
      if (!paramsUrl) setData([]); // delete data of list is existed.
      return await axios.get(paramsUrl ? paramsUrl : url[title], {
        baseURL: Const.settlementUrl,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        params: customParams,
        cancelToken: source.current.token,
        timeout: 60000,
      });
    } catch (e) {
      if (e.code === 'ECONNABORTED') {
        Modal.error({
          content: '데이터 연결시간이 초과되었습니다. 다시 시도하시거나 관리자에게 문의해주세요.',
        });
      }
      setIsLoading(false);
      return e.code;
      console.error('getting settlement-date is fail: ' + e);
    }
  };
  const onChangeDate = (_, date) => {
    setSelectStartDate(moment(date).startOf('month').format('YYYY-MM-DD'));
    setSelectEndDate(moment(date).endOf('month').format('YYYY-MM-DD'));
  };
  const disabledDate = (current) => {
    return (
      (current && current < moment(today, 'YYYY-MM').subtract(12, 'M')) ||
      current > moment(today, 'YYYY-MM').subtract(0, 'M')
    );
  };
  const getOrderListofFr = async (param) => {
    let frInfo = param ? param : fr;
    const params = {
      startDate: moment(frInfo.createDate).format('YYYY-MM-DD'),
      endDate: moment(frInfo.createDate).format('YYYY-MM-DD'),
      businessNumber: frInfo.businessNumber,
      companyId: loginInfo.companyId,
      page: paginationForDetail.current,
      size: paginationForDetail.pageSize,
    };
    const url = '/tax-details/delivery-price-vat/order-of-fr';
    setIsLoadingforDetail(true);
    const datas = await getTaxReturnDate(params, url);
    if (datas) {
      console.log(datas);
      setDetailData(() => {
        return datas.data.contents;
      });
      setPaginationForDetail({
        ...paginationForDetail,
        total: datas.data.totalCount,
        current: paginationForDetail.current,
      });
    }
    setIsLoadingforDetail(false);
  };
  return (
    <div id={'taxReturn'}>
      <div className={'Dialog-overlay'} onClick={props.close} />
      <div className={'DialogBackground'}>
        <div className={'settlementDialog'}>
          <div className={'settle-title-wrap'}>
            <div className={'settle-title-content'}>
              <div>{title} 세부내역</div>
              <div className={'settle-title-sub'}>
                <span style={{ marginRight: 16, color: 'blue' }}>
                  총 공급가: {comma(totalPrice ? totalPrice : props.amount)}
                </span>
                <span style={{ color: 'blue' }}>총 부가세: {comma(totalPriceVat ? totalPriceVat : props.vat)}</span>
              </div>
            </div>
            <Button
              onClick={props.close}
              icon={<CloseOutlined style={{ fontSize: 30 }} />}
              type="text"
              shape="circle"
            />
          </div>
          <div className={'settle-search-wrap'}>
            {/*{props.selectOp && (*/}
            {/*  <div className={'ncl-select-box'} style={{ marginRight: 10, flex: 0.5, marginLeft: 0 }}>*/}
            {/*    <Select*/}
            {/*      defaultValue={selectUserTypeStr}*/}
            {/*      value={selectUserTypeStr}*/}
            {/*      onChange={(value, item) => onChangeUserType(value, item)}*/}
            {/*      style={{ border: 'none', flex: 1 }}*/}
            {/*      options={[*/}
            {/*        { key: 0, value: '', label: '구분' },*/}
            {/*        { key: 1, value: 'RIDER', label: '라이더' },*/}
            {/*        { key: 2, value: 'FRANCHISE', label: '가맹점' },*/}
            {/*      ]}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}
            <div className={'elem-box'} style={{ marginRight: 10, flex: 1 }}>
              <Input
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                onPressEnter={() => {
                  getData(true);
                  setDeliveryVatDetailModalOpen(false);
                }}
                placeholder={'대리점명/가맹점명/라이더명'}
                style={{ flex: 1, border: 'none' }}
              />
              <SearchOutlined
                style={{ padding: '0 10px', cursor: 'pointer' }}
                onClick={() => {
                  getData(true);
                  setDeliveryVatDetailModalOpen(false);
                }}
              />
            </div>
            <ConfigProvider locale={locale}>
              <Tooltip title={'최대 12개월 이내에서 조회할 수 있습니다'}>
                <div className={'tr-flex-wrap'}>
                  <div className={'ncl-input-box'} style={{ width: 140 }}>
                    <DatePicker
                      picker="month"
                      style={{ border: 'none', flex: 1 }}
                      onChange={(_, date) => onChangeDate('start', _, date)}
                      value={moment(selectStartDate)}
                      allowClear={false}
                      disabledDate={disabledDate}
                    />
                  </div>
                  <span style={{ marginRight: 5 }}>-</span>
                  <div className={'ncl-input-box'} style={{ width: 140, marginLeft: 0 }}>
                    <DatePicker
                      picker="month"
                      style={{ border: 'none', flex: 1 }}
                      onChange={(_, date) => onChangeDate('end', _, date)}
                      value={moment(selectEndDate)}
                      allowClear={false}
                      disabledDate={disabledDate}
                    />
                  </div>
                </div>
              </Tooltip>
            </ConfigProvider>
            <Button
              onClick={() => {
                getData(true);
                setDeliveryVatDetailModalOpen(false);
              }}
              style={{
                height: 40,
                borderColor: '#c1c1c1',
                borderRadius: 5,
              }}>
              검색
            </Button>
          </div>
          <div
            className={data != null && data.length > 0 && 'settlementDataTable'}
            style={{
              flex: 1,
              margin: 20,
              marginBottom: 30,
              border: '1px solid #000',
            }}>
            <Table
              rowKey={(record) => record.idx}
              dataSource={data}
              rowClassName={(record, index) => index % 2 !== 0 && 'settlementDataTable-row'}
              scrollToFirstRowOnChange={true}
              loading={isLoading}
              columns={columns[title]}
              pagination={pagination}
              onChange={(pagination) => setPagination(pagination)}
              size="middle"
              onRow={(record) => {
                if (title === '배달비 부가세') {
                  return {
                    onClick: async () => {
                      setFr(record);
                      setDeliveryVatDetailModalOpen(true);
                      getOrderListofFr(record);
                      setPaginationForDetail({
                        total: 0,
                        current: 1,
                        pageSize: 80,
                      });
                    },
                  };
                } else return;
              }}
            />
          </div>
        </div>
        {deliveryVatDetailModalOpen && (
          <div className={'settlementDialog'}>
            <div className={'settle-title-wrap2'}>
              <Button
                onClick={() => {
                  setFr({});
                  setDeliveryVatDetailModalOpen(false);
                  setDetailData([]);
                  setPaginationForDetail({
                    total: 0,
                    current: 1,
                    pageSize: 80,
                  });
                }}
                icon={<LeftOutlined style={{ fontSize: 30 }} />}
                type="text"
                shape="circle"
              />
              <div className={'settle-title-content'}>
                <div style={{ marginLeft: 20 }}>{fr.frName} 세부내역</div>
              </div>
            </div>
            <div
              className={'settlementDataTable'}
              style={{
                flex: 1,
                margin: 20,
                marginBottom: 30,
                border: '1px solid #000',
              }}>
              <Table
                rowKey={(record) => record.idx}
                dataSource={detailData}
                rowClassName={(record, index) => index % 2 !== 0 && 'settlementDataTable-row'}
                scrollToFirstRowOnChange={true}
                loading={isLoadingforDetail}
                columns={detailDataOfFr}
                pagination={paginationForDetail}
                onChange={(pagination) => {
                  setPaginationForDetail(pagination);
                }}
                size="middle"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default TaxReturnDialog;
