
const getAveragePosition = (list) => {
  // let sum = 0;
  // const size = list.length;
  // for (const position of list) {
  //   sum += position;
  // }

  return (Math.max(...list) + Math.min(...list)) / 2;
};

export { getAveragePosition };
