export const SET_RIDER = 'SET_RIDER';
export const UPDATE_RIDER_LOCATION = 'UPDATE_RIDER_LOCATION';

export function setRider(riderList) {
  return {
    type: SET_RIDER,
    riderList,
  };
}

export function updateRiderLocation(updateRiderLocationRequest) {
  return {
    type: UPDATE_RIDER_LOCATION,
    updateRiderLocationRequest,
  };
}
