import { SET_SETTING } from '../actions/settingAction';

const INITIAL_STATE = {
  updateVoice: ['1'],
};

const settingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SETTING:
      console.log('settingReducer = action ? ' + JSON.stringify(action));
      console.log(
        Object.assign({}, state, {
          updateVoice: action.setting,
        })
      );
      global.voices = action.setting;
      console.log(
        'settingReducer = global.voices ? ' + JSON.stringify(global.voices)
      );
      return {
        ...state,
        updateVoice: action.setting,
      };
    default:
      return state;
  }
};

export default settingReducer;
