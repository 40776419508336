import { Button, DatePicker, Input, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import moment from 'moment';
import React, { Component } from 'react';
import xlsx from 'xlsx';
import { customError } from '../../../api/Modals';
import { httpGet, httpUrl } from '../../../api/httpClient';
import SelectBox from '../../../components/input/SelectBox';
import '../../../css/modal.css';
import CloseIcon from '../../../img/login/close.png';
import ExcelIcon from '../../../img/login/excel.png';
import { kindString } from '../../../lib/util/codeUtil';
import { comma } from '../../../lib/util/numberUtil';

const { RangePicker } = DatePicker;
const today = new Date();

const Search = Input.Search;

class BatchWorkListDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      kind: '',
      searchName: '',
      startDate: moment(today).add(-1, 'M').format('YYYY-MM-DD'),
      endDate: moment(today).format('YYYY-MM-DD'),
    };
    this.formRef = React.createRef();
  }

  pressSearch = () => {
    this.setState(
      {
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  componentDidMount() {
    this.getList();
  }

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  parseExcelJson = (data) => {
    let result = [
      {
        createDate: '일시',
        kind: '구분',
        userId: '라이더ID',
        userName: '라이더명',
        phone: '연락처',
        ncashDelta: '차감금액',
        afterNcash: '차감 후 잔액',
        misu: '미수금',
        ncashDeltaMisu: '미수금증감분',
      },
    ];
    data.forEach((item) => {
      result.push({
        createDate: item.createDate,
        kind: kindString[item.kind],
        userId: item.userId,
        userName: item.userName,
        phone: item.phone,
        ncashDelta: item.ncashDelta,
        afterNcash: item.afterNcash,
        misu: ((item.misu1 ?? 0) + (item.misu2 ?? 0) + (item.misu3 ?? 0)),
        ncashDeltaMisu: (item.ncashDeltaMisu ?? 0),
      });
    });

    return result;
  };

  onDownload = () => {
    httpGet(
      httpUrl.riderBatchWorkDailyList,
      [
        this.state.kind,
        1,
        10000,
        this.state.searchName,
        this.state.startDate,
        this.state.endDate,
      ],
      {}
    ).then((res) => {
      const excelJson = this.parseExcelJson(res.data.ncashDailies);
      const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
      const wb = xlsx.utils.book_new();
      ws['!cols'] = [];
      xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
      xlsx.writeFile(wb, '일차감내역.xlsx');
    });
  };

  onChange = (dateString) => {
    this.setState(
      {
        startDate: moment(dateString[0]).format('YYYY-MM-DD'),
        endDate: moment(dateString[1]).format('YYYY-MM-DD'),
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => this.getList()
    );
  };

  onChangeStatus = (value) => {
    this.setState(
      {
        kind: value === '0' ? '' : value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  //   getList = () => {
  //     var list = [
  //         {
  //             createDate: "2021-06-12",
  //             kind: 1,
  //             userId: "rider03",
  //             userName: "rider03",
  //             registrationNumber: "930507-1000000",
  //             phone: "010-1111-2222",
  //             ncashDelta: 50000
  //         },
  //         {
  //             createDate: "2021-06-23",
  //             kind: 2,
  //             userId: "rider04",
  //             userName: "rider04",
  //             registrationNumber: "950721-2000000",
  //             phone: "010-1212-3333",
  //             ncashDelta: 30000
  //         },
  //         {
  //             createDate: "2021-07-15",
  //             kind: 3,
  //             userId: "rider06",
  //             userName: "rider06",
  //             registrationNumber: "941108-1000000",
  //             phone: "010-2121-1111",
  //             ncashDelta: 20000
  //         }
  //     ]
  //     this.setState({
  //         list:list,
  //     })
  // }

  getList = () => {
    let kind = this.state.kind;
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let searchName = this.state.searchName;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    httpGet(
      httpUrl.riderBatchWorkDailyList,
      [kind, pageNum, pageSize, searchName, startDate, endDate],
      {}
    )
      .then((res) => {
        console.log('res.data :' + JSON.stringify(res.data, null, 4));
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState(
          {
            list: res.data.ncashDailies,
            pagination,
          },
          () => console.log(this.state.list)
        );
      })
      .catch((e) => {
        customError('목록 에러', '에러가 발생하여 목록을 불러올수 없습니다.');
      });
  };

  render() {
    const columns = [
      {
        title: '일시',
        dataIndex: 'createDate',
        className: 'table-column-center',
        width: '12%',
        // render: (data) => <div>{formatDateToDay(data)}</div>,
      },
      {
        title: '구분',
        dataIndex: 'kind',
        className: 'table-column-center',
        render: (data, row) => (
          <div>{`${kindString[data]} / ${
            row.batchTitle ? row.batchTitle : '-'
          }`}</div>
        ),
      },
      {
        title: '라이더ID',
        dataIndex: 'userId',
        className: 'table-column-center',
      },
      {
        title: '라이더명',
        dataIndex: 'userName',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '연락처',
        dataIndex: 'phone',
        className: 'table-column-center',
      },
      {
        title: '차감금액',
        dataIndex: 'ncashDelta',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}원</div>,
      },
      {
        title: '차감 후 잔액',
        dataIndex: 'afterNcash',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}원</div>,
      },
      {
        title: '미수금',
        className: 'table-column-center',
        render: (row) => <div>{comma((row.misu1 ?? 0) + (row.misu2 ?? 0) + (row.misu3 ?? 0))}원</div>,
      },
      {
        title: '미수금증감분',
        dataIndex: 'ncashDeltaMisu',
        className: 'table-column-center',
        render: (data) => <div>{comma(data ?? 0)}원</div>,
      },
    ];

    const { close } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="taskWorkList-Dialog">
          <div className="taskWorkList-content">
            <div className="taskWorkList-title">일/월차감 내역</div>
            <img
              onClick={close}
              src={CloseIcon}
              className="taskWorkList-close"
              alt="close"
            />
            <div className="taskWorkList-inner">
              <SelectBox
                // placeholder={'전체'}
                style={{ width: 175 }}
                value={
                  this.state.kind === ''
                    ? kindString['0']
                    : kindString[this.state.kind]
                }
                code={Object.keys(kindString)}
                codeString={kindString}
                onChange={(value) => {
                  if (parseInt(value) !== this.state.kind) {
                    this.onChangeStatus(value);
                  }
                }}
              />

              <Search
                placeholder="라이더명 검색"
                enterButton
                allowClear
                onChange={(e) => this.setState({ searchName: e.target.value })}
                onSearch={this.pressSearch}
                style={{
                  width: 220,
                  marginLeft: 20,
                }}
              />
              <Button
                className="tabBtn sectionTab exel"
                onClick={this.onDownload}>
                <img src={ExcelIcon} alt="" />
                엑셀 다운로드
              </Button>
              <RangePicker
                placeholder={['시작일', '종료일']}
                onChange={this.onChange}
                className="stats-date"
                locale={locale}
                format={'YYYY-MM-DD'}
                style={{ float: 'right' }}
                value={[
                  moment(this.state.startDate, 'YYYY-MM-DD'),
                  moment(this.state.endDate, 'YYYY-MM-DD'),
                ]}
              />
              <div className="listBlock">
                <Table
                  // scroll={{ y: '60vh' }}
                  rowKey={(record) => record.idx}
                  dataSource={this.state.list}
                  columns={columns}
                  pagination={this.state.pagination}
                  onChange={this.handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BatchWorkListDialog;
