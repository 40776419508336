import { Line } from "@ant-design/charts";
import { DatePicker, Radio, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";
import "moment/locale/ko";
import React, { Component } from "react";
import { httpGet, httpUrl } from "../../api/httpClient";
import "../../css/common.css";
import "../../css/staff.css";
import {joinExitStaticsType} from "../../lib/util/codeUtil";
import {comma} from "../../lib/util/numberUtil";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const today = new Date();
const { RangePicker } = DatePicker;


class joinExitStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finalList: [],
      frChartList: [],
      riderChartList: [],
      chartListRider: [],
      etcList: [],

      dateRange: 1,
      startDate: moment(today).add(-6, "d").format("YYYY-MM-DD"),
      endDate: moment(today).format("YYYY-MM-DD"),

    };
  }

  componentDidMount() {
    this.getList();
  }

  onChangeDateRange = (e) => {
    this.setState(
      {
        dateRange: e.target.value,
      },
      () => {
        this.state.dateRange === 1
          ? this.setState(
              {
                startDate: moment(today).add(-6, "d").format("YYYY-MM-DD"),
                endDate: moment(today).format("YYYY-MM-DD"),
              },
              () => {
                this.getList();
              }
            )
          : this.state.dateRange === 2
          ? this.setState(
              {
                startDate: moment(today).add(-10, "W").format("YYYY-MM-DD"),
                endDate: moment(today).format("YYYY-MM-DD"),
              },
              () => {
                this.getList();
              }
            )
          : this.setState(
              {
                startDate: moment(today)
                  .add(-1, "Y")
                  .format("YYYY-MM-DD"),
                  //.concat("-01"),
                endDate: moment(today).format("YYYY-MM-DD"),
              },
              () => {
                this.getList();
              }
            );

      }
    );
  };

  getList = () => {
    let dateRange = this.state.dateRange;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    httpGet(
      httpUrl.getJoinExitStatList,
      [dateRange, 0, startDate, endDate],
      {}
    ).then((result) => {
      console.log("## nnbox result=" + JSON.stringify(result, null, 4));
      let tempRiderChartList = [];
      let tempFrChartList = [];
      let tempFinalList = [];
      let tempObj = {
        statDate : "",
        joinCountF : 0,
        exitCountF : 0,
        stopCountF : 0,
        comebackCountF : 0,
        totalMemberCountF : 0,
        joinCountR : 0,
        exitCountR : 0,
        stopCountR : 0,
        accidentCountR : 0,
        totalMemberCountR : 0,
      }
      for (let i = 0; i < result.data.list.length; i++) {
        if (result.data.list[i] == null) continue;
        if (result.data.list[i].userType == 1) {
          if (tempObj.statDate != "" && result.data.list[i].statDate != tempObj.statDate) {
            tempFinalList.push(tempObj);
            tempObj = {
              statDate : "",
              joinCountF : 0,
              exitCountF : 0,
              stopCountF : 0,
              comebackCountF : 0,
              totalMemberCountF : 0,
              joinCountR : 0,
              exitCountR : 0,
              stopCountR : 0,
              accidentCountR : 0,
              totalMemberCountR : 0,
            };
          }
          tempObj.statDate = result.data.list[i].statDate;
          tempObj.joinCountR = result.data.list[i].joinCount;
          tempObj.exitCountR = result.data.list[i].exitCount;
          tempObj.stopCountR = result.data.list[i].stopCount;
          tempObj.accidentCountR = result.data.list[i].accidentCount;
          tempObj.totalMemberCountR = result.data.list[i].totalMemberCount;
          let totalobj = {
            statDate: result.data.list[i].statDate,
            count: result.data.list[i].totalMemberCount,
            searchType: joinExitStaticsType[1],
          };
          tempRiderChartList.push(totalobj);
          let joinobj = {
            statDate: result.data.list[i].statDate,
            count: result.data.list[i].joinCount,
            searchType: joinExitStaticsType[2],
          };
          tempRiderChartList.push(joinobj);
          let exitobj = {
            statDate: result.data.list[i].statDate,
            count: result.data.list[i].exitCount,
            searchType: joinExitStaticsType[3],
          }
          tempRiderChartList.push(exitobj);
          let stopobj = {
            statDate: result.data.list[i].statDate,
            count: result.data.list[i].stopCount,
            searchType: joinExitStaticsType[4],
          }
          tempRiderChartList.push(stopobj);
          let combackobj = {
            statDate: result.data.list[i].statDate,
            count: result.data.list[i].comebackCount,
            searchType: joinExitStaticsType[5],
          }
          tempRiderChartList.push(combackobj);
          let accidentobj = {
            statDate: result.data.list[i].statDate,
            count: result.data.list[i].accidentCount,
            searchType: joinExitStaticsType[6],
          }
          tempRiderChartList.push(accidentobj);
        } else {
          tempObj.joinCountF = result.data.list[i].joinCount;
          tempObj.exitCountF = result.data.list[i].exitCount;
          tempObj.stopCountF = result.data.list[i].stopCount;
          tempObj.comebackCountF = result.data.list[i].comebackCount;
          tempObj.totalMemberCountF = result.data.list[i].totalMemberCount;
          let totalobj = {
            statDate: result.data.list[i].statDate,
            count: result.data.list[i].totalMemberCount,
            searchType: joinExitStaticsType[1],
          };
          tempFrChartList.push(totalobj);
          let joinobj = {
            statDate: result.data.list[i].statDate,
            count: result.data.list[i].joinCount,
            searchType: joinExitStaticsType[2],
          };
          tempFrChartList.push(joinobj);
          let exitobj = {
            statDate: result.data.list[i].statDate,
            count: result.data.list[i].exitCount,
            searchType: joinExitStaticsType[3],
          }
          tempFrChartList.push(exitobj);
          let stopobj = {
            statDate: result.data.list[i].statDate,
            count: result.data.list[i].stopCount,
            searchType: joinExitStaticsType[4],
          }
          tempFrChartList.push(stopobj);
          let combackobj = {
            statDate: result.data.list[i].statDate,
            count: result.data.list[i].comebackCount,
            searchType: joinExitStaticsType[5],
          }
          tempFrChartList.push(combackobj);
          let accidentobj = {
            statDate: result.data.list[i].statDate,
            count: result.data.list[i].accidentCount,
            searchType: joinExitStaticsType[6],
          }
          tempFrChartList.push(accidentobj);
        }
      }
      tempFinalList.push(tempObj);
      tempRiderChartList.sort(function (a, b) {
        return a.statDate < b.statDate ? -1 : a.statDate > b.statDate ? 1: 0;
      });
      tempFrChartList.sort(function (a, b) {
        return a.statDate < b.statDate ? -1 : a.statDate > b.statDate ? 1: 0;
      });
      tempFinalList.sort(function (a, b) {
        return a.statDate < b.statDate ? 1 : a.statDate > b.statDate ? -1: 0;
      });
      let tempEtcObj = {
        branchName: result.data.branchName,
        currentTotalMemberCount: result.data.currentTotalMemberCount,
        accumulateJoinCount: result.data.accumulateJoinCount,
        accumulateExitCount: result.data.accumulateExitCount,
        currentStaffCount: result.data.currentStaffCount,
      }

      this.setState({
        riderChartList: tempRiderChartList,
        frChartList: tempFrChartList,
        finalList: tempFinalList,
        etcList: [tempEtcObj],
      });
    });
  };

  onChangeDate = (dateString) => {
    this.setState(
      {
        startDate:
          dateString != null ? moment(dateString[0]).format("YYYY-MM-DD") : "",
        endDate:
          dateString != null ? moment(dateString[1]).format("YYYY-MM-DD") : "",
      },
      () => {
        this.getList();
      }
    );
  };

  render() {
    const columns = [
      {
        title: "날짜",
        dataIndex: "statDate",
        className: "table-column-center",
      },
      {
        title: "가맹점",
        className: "table-column-center",
        children: [
          {
            title: "총원",
            dataIndex: "totalMemberCountF",
            className: "table-column-center",
            render: (data) => <div>{comma(data)}</div>,
          },
          {
            title: "신규",
            dataIndex: "joinCountF",
            className: "table-column-center",
            render: (data) => <div>{comma(data)}</div>,
          },
          {
            title: "중지",
            dataIndex: "stopCountF",
            className: "table-column-center",
            render: (data) => <div>{comma(data)}</div>,
          },
          {
            title: "탈퇴",
            dataIndex: "exitCountF",
            className: "table-column-center",
            render: (data) => <div>{comma(data)}</div>,
          },
          {
            title: "복귀",
            dataIndex: "comebackCountF",
            className: "table-column-center",
            render: (data) => <div>{comma(data)}</div>,
          },
        ]
      },
      {
        title: "라이더",
        className: "table-column-center",
        children: [
          {
            title: "총원",
            dataIndex: "totalMemberCountR",
            className: "table-column-center",
            render: (data) => <div>{comma(data)}</div>,
          },
          {
            title: "신규",
            dataIndex: "joinCountR",
            className: "table-column-center",
            render: (data) => <div>{comma(data)}</div>,
          },
          {
            title: "중지",
            dataIndex: "stopCountR",
            className: "table-column-center",
            render: (data) => <div>{comma(data)}</div>,
          },
          {
            title: "탈퇴",
            dataIndex: "exitCountR",
            className: "table-column-center",
            render: (data) => <div>{comma(data)}</div>,
          },
          {
            title: "사고",
            dataIndex: "accidentCountR",
            className: "table-column-center",
            render: (data) => <div>{comma(data)}</div>,
          },
        ]
      },
    ];

    const columnsEtc = [
      {
        title:
          this.props.branchInfo.companyId !== 'connect9' ? '지사명' : '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: "총원",
        dataIndex: "currentTotalMemberCount",
        className: "table-column-center",
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: "누적입사",
        dataIndex: "accumulateJoinCount",
        className: "table-column-center",
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: "누적퇴사",
        dataIndex: "accumulateExitCount",
        className: "table-column-center",
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: "직원",
        dataIndex: "currentStaffCount",
        className: "table-column-center",
        render: (data) => <div>{comma(data)}</div>,
      },
    ];


    const configFr = {
      data: this.state.frChartList,
      title: "가맹점",
      xField: "statDate",
      yField: "count",
      seriesField: "searchType",
    };

    const configRider = {
      data: this.state.riderChartList,
      title: "라이더",
      xField: "statDate",
      yField: "count",
      seriesField: "searchType",
    };

    return (
        <>
          <div className="joinExitStats">
            <Table
              className="joinexit-stats-tables"
              dataSource={this.state.etcList}
              columns={columnsEtc}
              pagination={{
                hideOnSinglePage: true
              }}
            />
          </div>
          <div>
            <Radio.Group
              defaultValue={1}
              onChange={this.onChangeDateRange}
              checked={this.state.dateRange}
              className="stats-radio"
            >
              <Radio value={1}>일별</Radio>
              <Radio value={2}>주별</Radio>
              <Radio value={3}>월별</Radio>
            </Radio.Group>

            <RangePicker
              placeholder={["시작일", "종료일"]}
              onChange={this.onChangeDate}
              className="stats-date"
              locale={locale}
              format={"YYYY-MM-DD"}
              value={[
                moment(this.state.startDate, "YYYY-MM-DD"),
                moment(this.state.endDate, "YYYY-MM-DD"),
              ]}
            />
          </div>
          <Table
            className="joinexit-stats-tables"
            dataSource={this.state.finalList}
            columns={columns}
          />

          <div className="joinexit-stats-graph">
            라이더
            <div className="stats-line">
              <Line {...configRider} />
            </div>
            가맹점
            <div className="stats-line">
              <Line {...configFr} />
            </div>
          </div>

        </>
    );
  }
}
let mapStateToProps = (state) => {
  return {
    branchInfo: state.branchReducer.branchInfo,
  };
};

export default withRouter(connect(mapStateToProps)(joinExitStats));
