import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { Marker, NaverMap, Polyline } from 'react-naver-maps';
import { useSelector } from 'react-redux';
import { setProperNavermapZoom } from '../../../lib/util/mapUtil';
import CloseIcon from '../../../img/login/close.png';
import ArriveYellowIcon from '../../../img/login/map/arrive_yellow.png';
import MarkerRiderRedIcon from '../../../img/login/map/marker_rider_red_2.png';
import FranchiseYellowIcon from '../../../img/login/map/franchise_yellow.png';

const OrderRouteInfo = ({ data, close }) => {
  const navermaps = window.naver.maps;
  const riders = useSelector((state) => state.riderReducer.riders);

  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [mapZoom, setMapZoom] = useState(16);
  const frLocation = { lat: data.frLatitude, lng: data.frLongitude };
  const destinationLocation = { lat: data.latitude, lng: data.longitude };
  const riderLocation = useSelector((state) => {
    const riders = state.riderReducer.riders;
    const orderRiderIdx = state.riderReducer.riders.findIndex(
      (rider) => rider.idx === data.userIdx
    );
    if (orderRiderIdx === -1) {
      Modal.warn({
        title: '경로 찾기 실패',
        content: '잘못된 기사 정보입니다. 주문정보를 확인해주세요.',
      });
      close();
      return;
    }
    const orderRider = riders[orderRiderIdx];
    return {
      lat: orderRider.riderLocation.latitude,
      lng: orderRider.riderLocation.longitude,
    };
  });

  useEffect(() => {
    if (riders.length === 0) {
      Modal.info({
        title: '잠시만 기다려주세요.',
        content: '기사정보를 로딩중입니다. 잠시 후에 시도해주세요.',
      });
      close();
      return;
    }
    setMapCenter({
      lat: (riderLocation.lat + frLocation.lat + destinationLocation.lat) / 3,
      lng: (riderLocation.lng + frLocation.lng + destinationLocation.lng) / 3,
    });
    setMapZoom(
      setProperNavermapZoom(riderLocation, frLocation, destinationLocation)
    );
    console.log(
      setProperNavermapZoom(riderLocation, frLocation, destinationLocation)
    );
  }, []);

  return (
    <>
      <div className="flex-modal-overlay" onClick={close}>
        <div
          onClick={(e) => e.stopPropagation()}
          className={'order-route-info-dialog-wrapper'}>
          <div className={'order-route-info-dialog-header'}>
            주문 경로{' '}
            <img
              onClick={close}
              src={CloseIcon}
              className="dialog-close"
              alt="닫기"
            />
          </div>
          <div className="order-route-info-dialog-navermap-wrapper">
            {navermaps && (
              <NaverMap
                className="order-route-info-dialog-navermap"
                zoom={mapZoom}
                center={mapCenter}>
                {/* 라이더 */}
                <Marker position={riderLocation} icon={MarkerRiderRedIcon} />
                {/* 라이더 - 가맹점 */}
                <Polyline
                  key={''}
                  path={[riderLocation, frLocation]}
                  strokeColor={'red'}
                  strokeWeight={4}
                />
                {/* 가맹점 */}
                <Marker position={frLocation} icon={FranchiseYellowIcon} />
                {/* 가맹점 - 도착지 */}
                <Polyline
                  key={''}
                  path={[frLocation, destinationLocation]}
                  strokeColor={'red'}
                  strokeWeight={4}
                />
                {/* 도착지 */}
                <Marker
                  position={destinationLocation}
                  icon={ArriveYellowIcon}
                />
              </NaverMap>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderRouteInfo;
