import { Button, Form, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { httpGet, httpPost, httpUrl } from '../../../api/httpClient';
import CloseIcon from '../../../img/login/close.png';
import FrOrderAgencyCard from './FrOrderAgencyCard';

const FrOrderAgencyDialog = ({ data, close }) => {
  // console.log(data);
  const newFrOrderAgency = {
    userIdx: data.idx,
    nncode: null,
    ftCode: null,
    deCode: null,
    dgCode: null,
    bpCode: null,
    psCode: null,
    posCode: null,
    posStoreCode: null,
  };

  const [frOrderAgency, setFrOrderAgency] = useState([]);

  useEffect(() => {
    getFrOrderAgency();
  }, []);

  useEffect(() => {
    console.log(frOrderAgency);
  }, [frOrderAgency]);

  const validateFrOrderAgency = (foa) => {
    if (
      (foa.posCode === null || foa.posCode === '') &&
      foa.ftCode === null &&
      foa.deCode === null &&
      foa.dgCode === null &&
      foa.bpCode === null &&
      foa.psCode === null
    )
      return false;
    return true;
  };

  const getFrOrderAgency = async () => {
    const res = await httpGet(httpUrl.getFrOrderAgencyList, [data.idx], {});
    // console.log(res);
    if (res.result === 'SUCCESS') setFrOrderAgency(res.data.frOrderAgency);
  };

  const appendFrOrderAgency = () => {
    setFrOrderAgency([...frOrderAgency, newFrOrderAgency]);
  };

  const handleSubmit = async () => {
    let formData = [...frOrderAgency];
    let flag = true;
    formData.forEach((foa) => (flag = validateFrOrderAgency(foa)));
    if (!flag) {
      Modal.warn({
        title: '설정 오류',
        content: '연동업체를 설정해주세요',
      });
      return;
    }
    for (let i = 0; i < formData.length; i++) {
      delete formData[i].createDate;
    }
    console.log(formData);
    try {
      const res = await httpPost(httpUrl.setFrOrderAgencyList, [data.idx], {
        frOrderAgencyList: formData,
      });
      // console.log(res);
      if (res.data) close();
      else
        Modal.warn({
          title: '설정오류',
          content: '연동설정에 실패했습니다.',
        });
    } catch (e) {
      console.error(e);
      Modal.warn({
        title: '설정오류',
        content: '연동설정에 실패했습니다.',
      });
    }
  };

  return (
    <React.Fragment>
      <div className="Dialog-overlay" onClick={close} />
      <div className="systemKey-Dialog">
        <div className="systemKey-content">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="systemKey-title">{`매장연동설정 (${data.frName})`}</div>
            <img
              onClick={close}
              src={CloseIcon}
              className="dialog-close"
              alt="img"
            />
          </div>

          <div className="systemKey-inner">
            <Form>
              {frOrderAgency.map((foa, idx) => (
                <FrOrderAgencyCard
                  key={idx + foa.posCode + foa.userIdx}
                  getFrOrderAgency={getFrOrderAgency}
                  setFrOrderAgency={(value) => {
                    let newFrOrderAgency = [...frOrderAgency];
                    newFrOrderAgency.splice(idx, 1, value);
                    setFrOrderAgency(newFrOrderAgency);
                  }}
                  deleteFrOrderAgency={() => {
                    let newFrOrderAgency = [...frOrderAgency];
                    newFrOrderAgency.splice(idx, 1);
                    setFrOrderAgency(newFrOrderAgency);
                  }}
                  frOrderAgency={foa}
                  data={data}
                />
              ))}
            </Form>
            <Button
              type="primary"
              style={{ margin: 10 }}
              onClick={handleSubmit}>
              확인
            </Button>
            <Button style={{ margin: 10 }} onClick={appendFrOrderAgency}>
              추가
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FrOrderAgencyDialog;
