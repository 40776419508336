import { Button, Checkbox } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../css/modal.css';
import '../../../css/modal_m.css';
import CloseIcon from '../../../img/login/close.png';
import ReceiveNoticeTable from '../../notice/ReceiveNoticeTable';
import SendNoticeTable from '../../notice/SendNoticeTable';
import RegistNoticeDialog from './RegistNoticeDialog';

class NoticeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      date: '',
      title: '',
      content: '',
      category: 0,
      sortOrder: 30,
      important: 0,
      branchIdx: 1,
      createdate: '',
      deleteDate: '',
      readDate: '',
      deleted: false,
      checkedDeletedCall: false,
      registNotice: false,
      updateNotice: false,
      showContent: 0,

      noticeDirection: 0,

      refreshTableTrigger: false,
    };
    this.formRef = React.createRef();
  }


  handleToggleDeletedCall = (e) => {
    this.setState(
      {
        checkedDeletedCall: e.target.checked,
        pagination: {
          current: 1,
          pageSize: 5,
        },
      },
      () => {
        // this.getList();
      }
    );
  };



  handleClear = () => {
    this.formRef.current.resetFields();
  };

  closeNoticeRegistrationModal = () => {
    this.setState(
      {
        registNotice: false,
        checkedDeletedCall: false,
        pagination: {
          current: 1,
          pageSize: 5,
        },
      },
    );
  };

  openNoticeRegistrationModal = () => {
    this.setState({ registNotice: true });
  };

  refreshTable = () => {
    this.setState({
      refreshTableTrigger: !this.state.refreshTableTrigger,
    })
  }

  render() {

    const { close } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="noticeDialog">
          <div className="container">
            <div className="notice-title">공지사항</div>
            <img
              onClick={close}
              src={CloseIcon}
              className="surcharge-close"
              alt="닫기"
            />
            <div className="noticeLayout">
              <div style={{display: 'flex', marginBottom: 10}}>
                <div onClick={() => this.setState({
                  noticeDirection: 0
                })} style={{borderWidth: 2, borderColor: this.state.noticeDirection === 0 ? 'black' : '#888', borderStyle: 'solid', borderRadius: 30, paddingLeft: 10, paddingRight: 10, cursor: 'pointer', fontWeight: 'bold', color: this.state.noticeDirection === 0 ? 'black' : '#888'}}>
                  전달받은 공지사항
                </div>
                <div onClick={() => this.setState({
                  noticeDirection: 1
                })} style={{borderWidth: 2, borderColor:  this.state.noticeDirection === 1 ? 'black' : '#888', borderStyle: 'solid', borderRadius: 30, paddingLeft: 10, paddingRight: 10, cursor: 'pointer', fontWeight: 'bold', marginLeft: 10, color: this.state.noticeDirection === 1 ? 'black' : '#888'}}>
                  등록된 공지사항
                </div>
              </div>
              <div className="noticelistBlock">
                {this.state.noticeDirection === 0 
                && <ReceiveNoticeTable
                      refreshTableTrigger={this.state.refreshTableTrigger} 
                      refreshTable={this.refreshTable}
                    />}
                {this.state.noticeDirection === 1 
                && <>
                  <div className="deleteBox">
                    <Checkbox
                      checked={this.state.checkedDeletedCall ? 'checked' : ''}
                      onChange={this.handleToggleDeletedCall}></Checkbox>
                    <span className="deleteBox-text">삭제목록</span>
                  </div>
                  <div className="registBtn">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="tabBtn insertTab noticeBtn"
                      onClick={this.openNoticeRegistrationModal}>
                      등록하기
                    </Button>
                  </div>
                  <SendNoticeTable 
                        checkedDeletedCall={this.state.checkedDeletedCall} 
                        refreshTableTrigger={this.state.refreshTableTrigger} 
                        refreshTable={this.refreshTable}
                      />
                
                </>}
              </div>
            </div>
          </div>
        </div>
        {this.state.registNotice && (
          <RegistNoticeDialog 
            close={this.closeNoticeRegistrationModal} 
            refreshTable={this.refreshTable}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.branch,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(NoticeDialog);
