import { ClockCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPost, httpUrl } from '../../../api/httpClient';
import { delayReqTime } from '../../../lib/util/codeUtil';
import CloseIcon from '../../../img/login/close.png';

// import "../../../css/modal.css";

class TimeDelayDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchIdx: null,
      branchInfo: null,
      deliveryNotAvailable: false,
      confirmLoading: false,
      btnInfos: [
        {
          value: 0,
          text: delayReqTime[0],
        },
        {
          value: 5,
          text: delayReqTime[5],
        },
        {
          value: 10,
          text: delayReqTime[10],
        },
        {
          value: 15,
          text: delayReqTime[15],
        },
        {
          value: 20,
          text: delayReqTime[20],
        },
        {
          value: 25,
          text: delayReqTime[25],
        },
        {
          value: 30,
          text: delayReqTime[30],
        },
        {
          value: 35,
          text: delayReqTime[35],
        },
        {
          value: 40,
          text: delayReqTime[40],
        },
        {
          value: 45,
          text: delayReqTime[45],
        },
        {
          value: 50,
          text: delayReqTime[50],
        },
        {
          value: 55,
          text: delayReqTime[55],
        },
        {
          value: 60,
          text: delayReqTime[60],
        },
        {
          value: 65,
          text: delayReqTime[65],
        },
        {
          value: 70,
          text: delayReqTime[70],
        },
        // {
        //   value: 1005,
        //   text: delayReqTime[1005],
        // },
        // {
        //   value: 1010,
        //   text: delayReqTime[1010],
        // },
        // {
        //   value: 1015,
        //   text: delayReqTime[1015],
        // },
        // {
        //   value: 1020,
        //   text: delayReqTime[1020],
        // },
        // {
        //   value: 1025,
        //   text: delayReqTime[1025],
        // },
      ],
    };
  }

  componentDidMount() {
    this.getBranch();
  }

  getBranch = () => {
    httpGet(httpUrl.getBranch, [this.props.branchIdx], {})
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data) {
          console.log(res);
          this.setState({ branchInfo: res.data }, () => {
            const branchInfo = this.state.branchInfo;
            this.setState({
              deliveryNotAvailable: !branchInfo.deliveryEnabled,
              delayPickupTime: res.data.delayPickupTime,
              addrUpdateEnabled: res.data.addrUpdateEnabled,
            });
          });
        } else {
          console.log('branchInfo error');
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleChange = (e) => {
    this.setState({
      deliveryNotAvailable: e.target.checked,
    });
  };

  handleClick = (value) => {
    this.setState({
      delayTime: value,
    });
  };

  handleToggle = (value) => {
    this.setState({
      delayPickupTime: value,
    });
    // if ( value === true /* toggle === true && this.state.btninfos.value*/) {
    //   const selectedBtnInfos = this.state.btnInfos.map((btnInfo) => {
    //     if (btnInfo.value < value /*this.state.btninfos.value === this.state.branchInfo.delayPickupTime*/) {
    //       return {
    //         value: btnInfo.value,
    //         text: btnInfo.text,
    //       };
    //     } else {
    //       return btnInfo;
    //     }
    //   });
    //   this.setState({ btnInfos: selectedBtnInfos });
    // } else {
    //   const selectedBtnInfos = this.state.btnInfos.map((btnInfo) => {
    //     if (btnInfo.value >= value) {
    //       return {
    //         value: btnInfo.value,
    //         text: btnInfo.text,
    //       };
    //     } else {
    //       return btnInfo;
    //     }
    //   });
    //   this.setState({ btnInfos: selectedBtnInfos });
    // }
  };

  handleSubmit = () => {
    console.log(this.props.branchIdx);
    Modal.confirm({
      title: '호출설정',
      content: '설정하시겠습니까?',
      onOk: () => {
        this.setState({ confirmLoading: true });
        // const btnInfos = this.state.btnInfos;
        httpPost(httpUrl.updateBranch, [], {
          idx: this.props.branchIdx,
          deliveryEnabled: !this.state.deliveryNotAvailable,
          delayPickupTime: this.state.delayPickupTime,
          addrUpdateEnabled: this.state.addrUpdateEnabled,
          // pickupAvTime0: btnInfos.find((e) => e.value === 0).toggle,
          // pickupAvTime5: btnInfos.find((e) => e.value === 5).toggle,
          // pickupAvTime10: btnInfos.find((e) => e.value === 10).toggle,
          // pickupAvTime15: btnInfos.find((e) => e.value === 15).toggle,
          // pickupAvTime20: btnInfos.find((e) => e.value === 20).toggle,
          // pickupAvTime25: btnInfos.find((e) => e.value === 25).toggle,
          // pickupAvTime30: btnInfos.find((e) => e.value === 30).toggle,
          // pickupAvTime35: btnInfos.find((e) => e.value === 35).toggle,
          // pickupAvTime40: btnInfos.find((e) => e.value === 40).toggle,
          // pickupAvTime45: btnInfos.find((e) => e.value === 45).toggle,
          // pickupAvTime50: btnInfos.find((e) => e.value === 50).toggle,
          // pickupAvTime55: btnInfos.find((e) => e.value === 55).toggle,
          // pickupAvTime60: btnInfos.find((e) => e.value === 60).toggle,
          // pickupAvTime70: btnInfos.find((e) => e.value === 70).toggle,
          // pickupAvTime5After: true,
          // pickupAvTime10After: true,
          // pickupAvTime15After: true,
          // pickupAvTime20After: true,
          // pickupAvTime25After: true,
        })
          .then((res) => {
            if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
              this.setState({ confirmLoading: false });
              // Modal.info({
              //   title: "적용 완료",
              //   content: "성공적으로 처리되었습니다.",
              // });
            } else {
              this.setState({ confirmLoading: false });
              Modal.info({
                title: '적용 오류',
                content: '처리가 실패했습니다.',
              });
              console.log(res);
            }
          })
          .catch((e) => {
            this.setState({ confirmLoading: false });
            console.log(e);
            Modal.info({
              title: '적용 오류',
              content: '처리가 실패했습니다.',
            });
          });
      },
      onCancel: () => {
        console.log('task cancelled');
      },
      confirmLoading: this.state.confirmLoading,
    });
  };

  render() {
    const btnInfos = this.state.btnInfos;

    const { close } = this.props;
    return (
      <>
        <div
          className="flex-modal-overlay"
          onClick={() =>
            close({
              idx: this.props.branchIdx,
              deliveryEnabled: !this.state.deliveryNotAvailable,
              // pickupAvTime0: btnInfos.find((e) => e.value === 0).toggle,
              // pickupAvTime5: btnInfos.find((e) => e.value === 5).toggle,
              // pickupAvTime10: btnInfos.find((e) => e.value === 10).toggle,
              // pickupAvTime15: btnInfos.find((e) => e.value === 15).toggle,
              // pickupAvTime20: btnInfos.find((e) => e.value === 20).toggle,
              // pickupAvTime25: btnInfos.find((e) => e.value === 25).toggle,
              // pickupAvTime30: btnInfos.find((e) => e.value === 30).toggle,
              // pickupAvTime35: btnInfos.find((e) => e.value === 35).toggle,
              // pickupAvTime40: btnInfos.find((e) => e.value === 40).toggle,
              // pickupAvTime45: btnInfos.find((e) => e.value === 45).toggle,
              // pickupAvTime50: btnInfos.find((e) => e.value === 50).toggle,
              // pickupAvTime55: btnInfos.find((e) => e.value === 55).toggle,
              // pickupAvTime60: btnInfos.find((e) => e.value === 60).toggle,
              // pickupAvTime70: btnInfos.find((e) => e.value === 70).toggle,
              // pickupAvTime5After: true,
              // pickupAvTime10After: true,
              // pickupAvTime15After: true,
              // pickupAvTime20After: true,
              // pickupAvTime25After: true,
            })
          }
        />
        <div className="flex-wrapper">
          <div className="flex-header">
            <div className="flex-title">호출설정</div>
            <img
              onClick={() =>
                close({
                  idx: this.props.branchIdx,
                  deliveryEnabled: !this.state.deliveryNotAvailable,
                  // pickupAvTime0: btnInfos.find((e) => e.value === 0).toggle,
                  // pickupAvTime5: btnInfos.find((e) => e.value === 5).toggle,
                  // pickupAvTime10: btnInfos.find((e) => e.value === 10).toggle,
                  // pickupAvTime15: btnInfos.find((e) => e.value === 15).toggle,
                  // pickupAvTime20: btnInfos.find((e) => e.value === 20).toggle,
                  // pickupAvTime25: btnInfos.find((e) => e.value === 25).toggle,
                  // pickupAvTime30: btnInfos.find((e) => e.value === 30).toggle,
                  // pickupAvTime35: btnInfos.find((e) => e.value === 35).toggle,
                  // pickupAvTime40: btnInfos.find((e) => e.value === 40).toggle,
                  // pickupAvTime45: btnInfos.find((e) => e.value === 45).toggle,
                  // pickupAvTime50: btnInfos.find((e) => e.value === 50).toggle,
                  // pickupAvTime55: btnInfos.find((e) => e.value === 55).toggle,
                  // pickupAvTime60: btnInfos.find((e) => e.value === 60).toggle,
                  // pickupAvTime70: btnInfos.find((e) => e.value === 70).toggle,
                  // pickupAvTime5After: true,
                  // pickupAvTime10After: true,
                  // pickupAvTime15After: true,
                  // pickupAvTime20After: true,
                  // pickupAvTime25After: true,
                })
              }
              src={CloseIcon}
              className="dialog-close"
              alt="닫기"
            />
          </div>
          <div className="flex-inner">
            <div className="dialog-inner timeDelay-inner">
              <div className="timeDelay-box desk">
                {btnInfos.map((btnInfo) => {
                  return (
                    <Button
                      key={btnInfo.value}
                      icon={
                        <ClockCircleOutlined
                          style={{ fontSize: 50, width: 70 }}
                        />
                      }
                      className={
                        !this.state.deliveryNotAvailable &&
                        this.state.delayPickupTime === btnInfo.value
                          ? 'timeDelay-box-off'
                          : 'timeDelay-box-on'
                      }
                      onClick={
                        btnInfo.value > 1000
                          ? () => {
                              Modal.info({
                                title: '수정 불가',
                                content:
                                  '후배차 옵션은 비활성화 할 수 없습니다.',
                              });
                            }
                          : () => this.handleToggle(btnInfo.value)
                      }>
                      <div
                        style={{
                          fontSize: '1rem',
                          fontWeight: 700,
                        }}>
                        {btnInfo.text}
                      </div>
                    </Button>
                  );
                })}
              </div>
              <div className="timeDelay-box mobile">
                {btnInfos.map((btnInfo) => {
                  return (
                    <Button
                      key={btnInfo.value}
                      icon={
                        <ClockCircleOutlined
                          style={{ fontSize: 20, width: 30 }}
                        />
                      }
                      className={
                        !this.state.deliveryNotAvailable &&
                        this.state.delayPickupTime === btnInfo.value
                          ? 'timeDelay-box-off'
                          : 'timeDelay-box-on'
                      }
                      onClick={
                        btnInfo.value > 1000
                          ? () => {
                              Modal.info({
                                title: '수정 불가',
                                content:
                                  '후배차 옵션은 비활성화 할 수 없습니다.',
                              });
                            }
                          : () => this.handleToggle(btnInfo.value)
                      }>
                      <div
                        style={{
                          fontSize: '.8rem',
                          fontWeight: 700,
                        }}>
                        {btnInfo.text}
                      </div>
                    </Button>
                  );
                })}
              </div>

              <div className="timeDelay-btn-box">
                <div className="timeDelay-btn">
                  <Checkbox
                    onChange={(e) => this.handleChange(e)}
                    checked={this.state.deliveryNotAvailable}></Checkbox>
                  <div className="timeDelay-btn-01">배달불가</div>
                </div>

                <div className="timeDelay-btn-02">
                  <Button
                    className=" timeDelay-btn"
                    onClick={() => {
                      this.handleSubmit();
                    }}>
                    적용
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  branchIdx: state.login.loginInfo.branchIdx,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeDelayDialog);
