import { Form, Input, Modal, Select, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { httpGet, httpUrl } from "../../api/httpClient";
import SelectBox from "../../components/input/SelectBox";
import {
    category2AllStatus,
    category2FrStatus,
    category2RiderStatus,
    categoryStatus,
    userTypeString
} from "../../lib/util/codeUtil";
import { comma } from "../../lib/util/numberUtil";

const columns = [
    {
        title: '발생일',
        dataIndex: 'createDate',
        className: 'table-column-center',
    },
    {
        title: '가맹점명/라이더명',
        dataIndex: 'name',
        className: 'table-column-center',
    },
    {
        title: '가맹점/라이더',
        dataIndex: 'userType',
        className: 'table-column-center',
        render: (data, row) => <div>{data != null ? userTypeString[data] : ''}</div>,
    },
    {
        title: '사업자번호/주민번호',
        dataIndex: 'number',
        className: 'table-column-center',
    },
    {
        title: '카테고리',
        dataIndex: 'category',
        className: 'table-column-center',
        render: (data, row) => <div>{data != null ? categoryStatus[data] : ''}</div>,
    },
    {
        title: '차감명',
        dataIndex: 'title',
        className: 'table-column-center',
    },
    {
        title: '미수금비용',
        dataIndex: 'misuAmount',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
    },
    {
        title: '회수금액',
        dataIndex: 'recoveredAmount',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
    },
    {
        title: '상태',
        dataIndex: 'misuAmount',
        className: 'table-column-center',
        render: (data, row) => <div>{data > row.recoveredAmount ? <p style={{color : 'red'}}>미수</p> : <p style={{color : 'blue'}}>완납</p>}</div>,
    },
    {
        title: '마지막 납부일',
        dataIndex: 'recoveredDate',
        className: 'table-column-center',
        render: (data) => <div>{data ? data : '-'}</div>
    },
];

const MisuList = () => {
    const formRef = useRef();
    const [list, setList] = useState([]);
    const [userType, setUserType] = useState(0);
    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 20,
    });

    const getList = async () => {
        try {
            let category2 = formRef.current.getFieldValue("category2");
            if (category2 == undefined || category2 == "ALL") {
                category2 = '';
            }
            const res = await httpGet(httpUrl.getFrMisuList, [pagination.current, pagination.pageSize, userType, category2, formRef.current.getFieldValue("name")], {});
            console.log(res);

            if (res.data.result === 'SUCCESS') {
                setList(res.data.list);
                setPagination({ ...pagination, total: res.data.totalCount })
            }
            else Modal.warn({
                title: "조회실패",
                content: res.data.resultMessage
            })
        } catch (e) {
            Modal.warn({
                title: "조회실패",
                content: "조회에 실패했습니다."
            })
        }
    };

    useEffect(async () => {
        await getList();
    }, [pagination.current, pagination.pageSize])

    return (
    <div>

        <Form ref={formRef}>
            <Form.Item style={{display: 'inline-block'}} name={"userType"}>
                <SelectBox
                    placeholder={userTypeString[0]}
                    style={{ width: 200, marginBottom: 10 }}
                    code={Object.keys(userTypeString)}
                    codeString={userTypeString}
                    onChange={(value) => {
                        setUserType(value);
                    }}
                />
            </Form.Item>
            <Form.Item style={{display: 'inline-block', paddingLeft: '10px'}} name={"category2"}>
                {userType == 0 ? <Select  disabled={true} style={{ width: 200, marginBottom: 10 }}></Select> : <SelectBox
                    placeholder={category2AllStatus.ALL}
                    style={{ width: 200, marginBottom: 10 }}
                    code={userType == 1 ? Object.keys(category2RiderStatus) : Object.keys(category2FrStatus)}
                    codeString={userType == 1 ? category2RiderStatus : category2FrStatus}
                />}

            </Form.Item>
            <Form.Item style={{display: 'inline-block', paddingLeft: '10px'}} name={"name"} initialValue={""}>
                <Input.Search
                    onSearch={async () => {
                        if (pagination.current === 1) await getList();
                        else setPagination({...pagination, current: 1});
                    }}
                    style={{width:300}}
                    placeholder={"가맹점명/라이더명 검색"}
                    enterButton
                    allowClear
                />
            </Form.Item>
        </Form>
        <Table
            rowKey={(record) => record.userIdx}
            dataSource={list}
            columns={columns}
            pagination={pagination}
            onChange={pagination => setPagination(pagination)}
        />
    </div>
    )
}

export default MisuList;
