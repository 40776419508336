import React, {useEffect, useRef, useState} from "react";
import {httpPost, httpUrl} from "../../api/httpClient";
import {Button, Form, Input, Table} from "antd";
import WithdrawDialog from "./WithdrawDialog";
import {comma} from "../../lib/util/numberUtil";

const WithdrawList = () => {
    const formRef = useRef();
    const [list, setList] = useState([]);
    const [withdrawDialogOpen, setWithdrawDialogOpen] = useState(false);
    const [selectedFr, setSelecteFr] = useState(null);
    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 20,
    });

    useEffect(() => {
        if (selectedFr === null) setWithdrawDialogOpen(false);
        else setWithdrawDialogOpen(true);
    }, [selectedFr])

    const getBankName = (rawData) => {
        if (null === rawData || undefined === rawData || '' === rawData) return '-';
        const splitted = rawData.split(',');
        if (splitted.length < 2) return rawData;
        else return splitted[0];
    };

    const columns = [
        {
            title: '가맹점명',
            dataIndex: 'frName',
            className: 'table-column-center',
        },
        {
            title: '사업자번호',
            dataIndex: 'businessNumber',
            className: 'table-column-center',
        },
        {
            title: '주소',
            dataIndex: 'addr1',
            className: 'table-column-left-only',
            render: (data, row) => <div>{`${data} ${row.addr2} (${row.addr3})`}</div>
        },
        {
            title: '대표자번호',
            dataIndex: 'phone',
            className: 'table-column-center',
        },
        {
            title: '예치금잔액',
            dataIndex: 'ncash',
            className: 'table-column-center',
            render: (data, row) => comma(data - (row?.misu ?? 0) - (row?.misu2 ?? 0) - (row?.misu3 ?? 0))
        },
        {
            title: '출금계좌정보',
            dataIndex: 'bankAccount',
            className: 'table-column-center',
            render: (data, row) => <div>{`${getBankName(row.bank)} / ${data ? data : '-'}`}</div>
        },
        {
            title: '',
            dataIndex: 'userIdx',
            className: 'table-column-center',
            render: (data, row) => <Button onClick={() => setSelecteFr(row)}>{"예치금출금"}</Button>
        },
    ];


    const getList = async () => {
        const result = await httpPost(httpUrl.franchiseList, [], {
            franIsConnected: 0,
            frName: formRef.current.getFieldValue("keyword"),
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            userGroup: 0,
            userStatus: 1,
        });

        setList(result.data.franchises);
        setPagination({...pagination, total: result.data.totalCount});
    };

    useEffect(async () => {
        await getList();
    }, [pagination.current, pagination.pageSize])


    return (
        <div>
            {
                withdrawDialogOpen && <WithdrawDialog getList={getList} close={() => setSelecteFr(null)} selectedFr={selectedFr} />
            }
            <Form ref={formRef}>
                <Form.Item name={"keyword"} initialValue={""}>
                    <Input.Search
                        onSearch={async () => {
                            if (pagination.current === 1) await getList();
                            else setPagination({...pagination, current: 1});
                        }}
                        style={{width: 300}}
                        placeholder={"가맹점명 검색"}
                        enterButton
                        allowClear
                    />
                </Form.Item>
            </Form>
            <Table
                rowKey={(record) => record.userIdx}
                dataSource={list}
                columns={columns}
                pagination={pagination}
                onChange={pagination => setPagination(pagination)}
            />
        </div>
    )
}

export default WithdrawList;
