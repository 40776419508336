import { Button, Form, Input, Modal, Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpPost, httpUrl, imageUrl } from '../../../api/httpClient';
import '../../../css/modal.css';
import CloseIcon from '../../../img/login/close.png';
import { bankCode } from '../../../lib/util/codeUtil';

const Option = Select.Option;
const FormItem = Form.Item;

class UpdateRiderBankInfoDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      bank: '',
      bankAccount: '',
      depositor: '',
      step: 1, //1-초기, 2-발송, 3-인증완료
      smsTimer: 180,
    };
    this.formRef = React.createRef();
    this.smsInterval = null;
  }

  componentWillUnmount() {
    if (this.smsInterval) clearInterval(this.smsInterval);
  }

  resetCheckPhoneCode() {
    this.setState({ step: 1 });
    if (this.smsInterval) clearInterval(this.smsInterval);
  }

  bankUpdate = () => {
    if (this.props.userIdx == null) {
      Modal.info({
        title: '수정실패',
        content: '유저 정보가 없습니다.',
      });
      return;
    }

    const form = this.formRef.current.getFieldsValue();
    httpPost(httpUrl.riderBankUpdate, [], {
      idx: this.props.userIdx,
      bank: form.bank,
      bankAccount: form.bankAccount,
      depositor: form.depositor,
      token: this.token,
    })
      .then((res) => {
        if (res.data === 'SUCCESS') {
          Modal.info({
            title: '완료',
            content: '수정 완료했습니다.',
          });
          this.resetCheckPhoneCode();
        } else if (res.data === 'NOT_AGENCY') {
          Modal.info({
            title: '수정실패',
            content: '대리점 관리자만 수정 가능합니다.',
          });
        } else if (res.data === 'USER_TYPE_NOT_MATCHED') {
          Modal.info({
            title: '수정실패',
            content: '관리자만 수정 가능합니다.',
          });
        } else if (res.data === 'SMS_VERIFICATION_INVALID') {
          Modal.info({
            title: '수정실패',
            content: 'SMS인증이 유효하지 않습니다.',
          });
        } else if (res.data === 'INVALID_DATA') {
          Modal.info({
            title: '수정실패',
            content: 'data가 유효하지 않습니다.',
          });
        } else {
          Modal.info({
            title: '수정실패',
            content: '수정 실패했습니다.',
          });
        }
      })
      .catch((e) => {
        Modal.info({
          title: '수정 실패',
          content: '수정 실패했습니다.',
        });
        throw e;
      });
  };

  sendCode = () => {
    const to = this.props.loginInfo?.phone;
    const userIdx = this.props.loginInfo?.idx;
    if (null === to || undefined === to || '' === to) {
      Modal.warn({
        title: '인증실패',
        content: '등록된 핸드폰 번호가 유효하지 않습니다. 번호 확인 후 다시 시도해주세요.',
      });
      return;
    }
    if (null === userIdx || undefined === userIdx || '' === userIdx) {
      Modal.warn({
        title: '인증실패',
        content: '로그인 정보가 유효하지 않습니다. 다시 로그인 후 시도해주세요.',
      });
      return;
    }
    const self = this;
    Modal.confirm({
      title: '인증번호 전송',
      content: `${this.props.loginInfo.phone} 으로 인증번호를 전송하시겠습니까?`,
      onOk: async () => {
        const res = await httpPost(httpUrl.sendCertiSms, [], {});
        self.token = res.data.token;

        if (!self.token || self.token.length == 0) {
          Modal.warn({
            title: '인증번호 전송실패',
            content: '인증번호 전송에 실패했습니다. 인증코드는 3분이내에 여러번 보낼 수 없습니다.',
          });
        } else {
          this.formRef.current.setFieldsValue({
            code: '',
          });
          self.setState({ step: 2, smsTimer: 180 });
          self.smsInterval = setInterval(() => {
            const timer = self.state.smsTimer - 1;
            self.setState({ smsTimer: timer });
            if (timer <= 0) {
              self.setState({ step: 1 });
              if (self.smsInterval) clearInterval(self.smsInterval);
            }
          }, 1000);
        }
      },
    });
  };
  checkCode = async () => {
    const form = this.formRef.current.getFieldsValue();

    const res = await httpPost(httpUrl.checkCertiCode, [], {
      token: this.token,
      code: form.code,
    });

    if (res.data) {
      this.setState({ step: 3 });
    } else {
      Modal.warn({
        title: '인증실패',
        content: '인증코드가 잘못되었습니다. 다시 확인해주세요.',
      });
    }
  };

  render() {
    const { close, data } = this.props;
    console.log(data);

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="registRider-Dialog">
          <div className="registRider-content">
            <div className="registRider-title">{'계좌 정보 수정'}</div>
            <img onClick={close} src={CloseIcon} className="registRider-close" alt="close" />

            <Form ref={this.formRef}>
              <div className="registRiderLayout">
                <div className="registRiderBox">
                  <div className="registRiderWrapper">
                    <div className="contentBlock">
                      <div className="mainTitle">연락처</div>
                      <FormItem name="bankAccount" className="selectItem">
                        <Input value={this.props.loginInfo.phone} disabled />
                        {this.state.step == 1 && (
                          <Button onClick={() => this.sendCode()} style={{ position: 'absolute' }}>
                            인증번호전송
                          </Button>
                        )}
                        {this.state.step == 2 && (
                          <span style={{ color: 'red', position: 'absolute', paddingLeft: '10px' }}>
                            {this.state.smsTimer}
                          </span>
                        )}
                        {this.state.step == 3 && (
                          <span style={{ width: '70px', color: 'blue', position: 'absolute', paddingLeft: '10px' }}>
                            인증완료
                          </span>
                        )}
                      </FormItem>
                    </div>
                    {this.state.step > 1 && (
                      <div className="contentBlock">
                        <div className="mainTitle">인증번호</div>
                        <FormItem
                          name="code"
                          className="selectItem"
                          rules={[
                            {
                              required: true,
                              message: '인증번호를 입력해주세요',
                            },
                          ]}>
                          <Input
                            type="number"
                            disabled={this.state.step == 3}
                            value={this.state.code}
                            placeholder="인증번호를 입력해주세요"
                            onChange={(e) =>
                              this.setState({
                                code: e.target.value,
                              })
                            }
                          />
                        </FormItem>
                        {this.state.step == 2 && (
                          <Button onClick={() => this.checkCode()} style={{ position: 'absolute' }}>
                            확인
                          </Button>
                        )}
                      </div>
                    )}
                    <div className="contentBlock">
                      <div className="mainTitle">은행</div>
                      <FormItem
                        name="bank"
                        className="selectItem"
                        rules={[{ required: true, message: '은행을 선택해주세요' }]}
                        initialValue={this.props.bank}>
                        <Select disabled={data ? true : false}>
                          {Object.keys(bankCode).map((key) => {
                            return <Option value={key + ',' + bankCode[key]}>{key}</Option>;
                          })}
                        </Select>
                      </FormItem>
                    </div>
                    <div className="contentBlock">
                      <div className="mainTitle">계좌번호</div>
                      <FormItem
                        name="bankAccount"
                        className="selectItem"
                        rules={[
                          {
                            required: true,
                            message: '계좌번호를 입력해주세요',
                          },
                        ]}
                        initialValue={this.props.bankAccount}>
                        <Input placeholder="계좌번호를 입력해 주세요." className="override-input" />
                      </FormItem>
                    </div>
                    <div className="contentBlock">
                      <div className="mainTitle">예금주</div>
                      <FormItem
                        name="depositor"
                        className="selectItem"
                        rules={[{ required: true, message: '예금주를 입력해주세요' }]}
                        initialValue={this.props.depositor}>
                        <Input placeholder="예금주를 입력해 주세요." className="override-input" />
                      </FormItem>
                    </div>

                    <div className="submitBlock">
                      <Button disabled={this.state.step < 3} onClick={this.bankUpdate}>
                        {'수정'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loginInfo: state.login.loginInfo,
  branchIdx: state.login.loginInfo.branchIdx,
});

const mapDispatchToProps = () => {};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateRiderBankInfoDialog);
