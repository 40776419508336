import { useSelector } from 'react-redux';
import axios from 'axios';
import FileSaver from 'file-saver';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Modal, Radio, Spin } from 'antd';
import { VerticalAlignBottomOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { comma } from '../../lib/util/numberUtil';
import Const from '../../const';
import moment from 'moment';

const AgencyTaxReturnRider = (props) => {
  const loginInfo = useSelector((state) => state.login.loginInfo);
  const CancelToken = axios.CancelToken;
  const source = useRef(null);
  const startDate = props.startDate;
  const endDate = props.endDate;
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [mainList, setMainList] = useState([]);
  const [detailList, setDetailList] = useState([]);
  const [detailOpen, setDetailOpen] = useState(false);
  const [onDownlaodModal, setOnDownlaodModal] = useState(false);
  const [downloadType, setDownloadType] = useState(0);
  const [riderIdx, setRiderIdx] = useState(0);
  const [main, setMain] = useState({
    totalDeliveryCount: 0,
    totalRiderDeliveryPrice: 0,
    totalRiderFee: 0,
    totalRiderRevenue: 0,
    totalWithholdingTax: 0,
  });
  const [detail, setDetail] = useState({
    totalRiderRevenue: 0,
    totalWithholdingTax: 0,
  });

  useEffect(() => {
    getData();
    setDetailList([]);
    setRiderIdx(0);
    setDetailOpen(false);
    return () => {
      if (source.current !== null) {
        source.current.cancel();
      }
    };
  }, [props.startDate, props.endDate]);

  const getDataFromMongodb = async (customParams, url) => {
    if (source.current !== null) {
      // already exists request
      source.current.cancel();
    }
    source.current = CancelToken.source();
    try {
      return await axios.get(url, {
        baseURL: Const.settlementUrl,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        params: customParams,
        cancelToken: source.current.token,
      });
    } catch (e) {
      console.error('getting ncash-deposit-list is fail: ' + e);
    }
  };

  const getData = async (params) => {
    if (isLoading) return;
    setIsLoading(true);
    let customParams = {
      startDate: startDate,
      endDate: endDate,
      companyId: loginInfo.companyId,
    };
    if (search !== '' || search != null) customParams.keyword = search;
    const url = '/tax-return-v2/rider';
    const datas = await getDataFromMongodb(customParams, url);
    if (datas.data) {
      setMainList(() => {
        return datas.data.taxRiders;
      });
      setMain({
        totalDeliveryCount: datas.data.totalDeliveryCount,
        totalRiderDeliveryPrice: datas.data.totalRiderDeliveryPrice,
        totalRiderFee: datas.data.totalRiderFee,
        totalRiderRevenue: datas.data.totalRiderRevenue,
        totalWithholdingTax: datas.data.totalWithholdingTax,
      });
    }
    setIsLoading(false);
  };

  const getDetailData = async (riderUserIdx) => {
    if (isLoading) return;
    setIsLoading(true);
    let customParams = {
      startDate: startDate,
      endDate: endDate,
      companyId: loginInfo.companyId,
      riderUserIdx: riderUserIdx,
    };
    const url = '/tax-return-v2/rider/detail';
    const datas = await getDataFromMongodb(customParams, url);
    if (datas) {
      setDetailList(() => {
        return datas.data.taxRiderDetails;
      });
      setDetail({
        totalRiderRevenue: datas.data.totalRiderRevenue,
        totalWithholdingTax: datas.data.totalWithholdingTax,
      });
    }
    setIsLoading(false);
  };
  const mainColumns = [
    '대리점명',
    '라이더ID',
    '라이더명',
    '주민번호',
    '배달건수',
    '라이더 배달비',
    '배달비 수수료',
    '라이더 수익',
    '원천세',
    '',
  ];
  const detailColumns = [
    '대리점명',
    '주문번호',
    '귀속년월',
    '지급년월일',
    '내국인(1)/외국인(2)',
    '소득자명',
    '주민등록번호',
    '지급총액',
    '세율(%)',
    '원천세',
    '',
  ];

  const getExcelDataFromMongodb = async (params, url) => {
    if (source.current !== null) {
      source.current.cancel();
    }
    source.current = CancelToken.source();
    try {
      return await axios.get(url, {
        baseURL: Const.settlementUrl,
        params: params,
        responseType: 'arraybuffer',
        cancelToken: source.current.token,
      });
    } catch (e) {
      console.error('getting tax-invoice-excel-data is failed: ' + e);
    }
  };

  const onDownloadContent = async () => {
    console.log(downloadType);
    if (isLoading) return;
    setIsLoading(true);
    let params = {};
    let url = '';
    let str = '';
    if (downloadType === 0) {
      params = {
        startDate: props.startDate,
        endDate: props.endDate,
        companyId: loginInfo.companyId,
      };
      url = '/tax-return-v2/rider/excel';
      str = '전체내역';
    } else {
      params = {
        startDate: props.startDate,
        endDate: props.endDate,
        companyId: loginInfo.companyId,
        riderUserIdx: riderIdx,
      };
      url = '/tax-return-v2/rider/detail/excel';
      str = '상세내역';
    }
    if (downloadType === 0 && (search !== null || search !== '')) params.keyword = search;
    const response = await getExcelDataFromMongodb(params, url);
    if (response) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(
        blob,
        `${str}_${moment(props.startDate).format('YYMMDD')}_${moment(props.endDate).format('YYMMDD')}.xlsx`
      );
      Modal.success({
        content: '엑셀파일 다운로드가 완료되었습니다.',
      });
      setIsLoading(false);
      setOnDownlaodModal(false);
    } else {
      Modal.error({
        content: '다운로드에 실패했습니다. 관리자에게 문의해주세요.',
      });
    }
    setIsLoading(false);
    setOnDownlaodModal(false);
  };
  return (
    <div id={'agencyTaxReturn'}>
      <div className={'ncl-menu-wrap'}>
        <div className={'ncl-input-box'} style={{ width: 240 }}>
          <Input
            onPressEnter={getData}
            onChange={(e) => {
              setSearch(e.target.value);
              setDetailOpen(false);
            }}
            placeholder={'라이더ID/라이더명'}
            style={{
              border: 'none',
              paddingLeft: 10,
              width: 200,
            }}
          />
          <SearchOutlined style={{ padding: '0 10px', cursor: 'pointer' }} onClick={getData} />
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <Button
            className={'agency-btn'}
            onClick={() => {
              setOnDownlaodModal(true);
              setDownloadType(0);
            }}>
            전체 내역 다운로드 <VerticalAlignBottomOutlined style={{ color: '#fff' }} />
          </Button>
        </div>
      </div>
      {isLoading && (
        <div className={'spin-overlay'}>
          <Spin className={'spin'} />
        </div>
      )}
      <div className={'agency-tr-tbl'} style={{ marginTop: 15 }}>
        <div className={'agency-tr-table-container'} style={{ minWidth: 1080, maxWidth: 1710, flex: 1 }}>
          <div className={'agency-tr-main-table-columns rider'}>
            {mainColumns.map((col, index) => {
              if (index === 9) return <p>{col}</p>;
              else if (index === 8) return <div style={{ borderRight: 0 }}>{col}</div>;
              else return <div>{col}</div>;
            })}
          </div>
          {mainList.length > 0 ? (
            <div className={'agency-tr-main-table-content'}>
              {mainList.map((main, index) => {
                return (
                  <div
                    className={
                      index % 2 === 0 ? 'agency-tr-main-table-row rider' : 'agency-tr-main-table-row rider odd'
                    }
                    onClick={() => {
                      setDetailOpen(true);
                      setRiderIdx(main.riderUserIdx);
                      setDetailList([]);
                      getDetailData(main.riderUserIdx);
                    }}
                    style={{ fontWeight: riderIdx === main.riderUserIdx ? 'bold' : 'normal' }}>
                    <div>{main.branchName}</div>
                    <div>{main.riderUserId}</div>
                    <div>{main.exactRiderName ? main.exactRiderName : main.riderName}</div>
                    <div>{main.registrationNumber}</div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.deliveryCount)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.riderDeliveryPrice)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.riderFee)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.riderRevenue)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.withholdingTax)}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={'agency-tr-main-table-content2'}>조회되는 데이터가 없습니다.</div>
          )}
          <div className={'agency-tr-main-table-footer'}>
            {mainColumns.map((footer, index) => {
              if (index === 4) return <div className={'totalBox'}>{comma(main.totalDeliveryCount)}</div>;
              else if (index === 5) return <div className={'totalBox'}>{comma(main.totalRiderDeliveryPrice)}</div>;
              else if (index === 6) return <div className={'totalBox'}>{comma(main.totalRiderFee)}</div>;
              else if (index === 7) return <div className={'totalBox'}>{comma(main.totalRiderRevenue)}</div>;
              else if (index === 8) return <div className={'totalBox'}>{comma(main.totalWithholdingTax)}</div>;
              else if (index === 9) return;
              else return <div />;
            })}
          </div>
        </div>
        {detailOpen && (
          <div
            className={'agency-tr-table-container'}
            style={{ minWidth: 800, maxWidth: 1260, flex: 0.8, marginLeft: 10 }}>
            <div className={'detail-tbl-columns'}>
              {detailColumns.map((col, index) => {
                if (index === 10) return <p>{col}</p>;
                else if (index === 9) return <div style={{ borderRight: 0 }}>{col}</div>;
                return <div>{col}</div>;
              })}
            </div>
            <div className={'detail-tbl'}>
              {detailList.length > 0 &&
                detailList.map((detail, index) => {
                  return (
                    <div key={detail.createDate}>
                      <div className={'detail-tbl-date'}>기간: {detail.createDate}</div>
                      {detail.items.map((item, index) => {
                        return (
                          <div
                            key={item.registrationNumber + index}
                            className={index % 2 !== 0 ? 'detail-tbl-item odd' : 'detail-tbl-item'}>
                            <div>{item.branchName}</div>
                            <div>{item.orderIdx}</div>
                            <div>{item.yearMonth}</div>
                            <div>{item.date}</div>
                            <div>{item.isForeigner ? 2 : 1}</div>
                            <div>{item.exactRiderName}</div>
                            <div>{item.registrationNumber}</div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <span style={{ marginRight: 10 }}>{comma(item.riderRevenue)}</span>
                            </div>
                            <div>3.3</div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <span style={{ marginRight: 10 }}>{comma(item.withholdingTax)}</span>
                            </div>
                          </div>
                        );
                      })}
                      <div className={'detail-tbl-total'}>
                        {detailColumns.map((item, index) => {
                          if (index === 0)
                            return (
                              <div style={{ paddingLeft: 10, fontWeight: 'bold', flex: 2 }}>
                                배달 건수: {comma(detail.totalDeliveryCount)} 건
                              </div>
                            );
                          else if (index === 1) return;
                          else if (index === 7)
                            return (
                              <div className={'detail-bold'}>
                                <span>{comma(detail.totalRiderRevenue)}</span>
                              </div>
                            );
                          else if (index === 9)
                            return (
                              <div className={'detail-bold'}>
                                <span>{comma(detail.totalWithholdingTax)}</span>
                              </div>
                            );
                          else if (index === 10) return;
                          else return <div />;
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className={'footer-container'}>
              <div className={'agency-tax-return-table-footer'}>
                {detailColumns.map((item, index) => {
                  if (index === 0) return <div className={'totalBox'}>{comma(detail.totalWithholdingTax)}</div>;
                  else if (index === 2) return <div className={'totalBox'}>{comma(detail.totalRiderRevenue)}</div>;
                  else if (index === 10) return;
                  else return <div />;
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      {onDownlaodModal && (
        <div>
          <div className="Dialog-overlay" onClick={() => setOnDownlaodModal(false)} />
          <div className="taxReturn-Dialog">
            <CloseOutlined
              style={{ marginLeft: 'auto', fontSize: 20, cursor: 'pointer' }}
              onClick={() => setOnDownlaodModal(false)}
            />
            <div>
              <p style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>
                전체 내역 중 엑셀 파일로 <br /> 저장할 내용을 선택하세요
              </p>
              <Radio.Group onChange={(e) => setDownloadType(e.target.value)} defaultValue={0}>
                <Radio value={0}>통계(좌측)</Radio>
                <Radio value={1} disabled={!detailOpen}>
                  상세(우측)
                </Radio>
              </Radio.Group>
            </div>
            <div>
              <Button className={'taxReturn-Dialog-btn'} onClick={() => setOnDownlaodModal(false)}>
                취소
              </Button>
              <Button
                onClick={onDownloadContent}
                className={'taxReturn-Dialog-btn'}
                style={{ marginLeft: 10, background: '#FADC00', fontWeight: 'bold' }}>
                다운로드
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default AgencyTaxReturnRider;
