import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, Select, Button } from 'antd';
import moment from 'moment';
import { comma } from '../../lib/util/numberUtil';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Const from '../../const';
import SettlementDialog from '../../components/dialog/settlement/SettlementDialog';
import { httpGet, httpUrl } from '../../api/httpClient';
import { RightOutlined } from '@ant-design/icons';

const Option = Select.Option;
const today = moment().format('YYYY-MM-DD');
const SettlementList = () => {
  const loginInfo = useSelector((state) => state.login.loginInfo);
  const CancelToken = axios.CancelToken;
  const source = useRef(null);
  const [data, setData] = useState({
    totalNetProfit: 0,
    totalSalesAmount: 0,
    totalCostAmount: 0,
    totalTaxAmount: 0,
    duesCount: 0,
    dues: 0,
    deliveryVatCount: 0,
    deliveryVatAmount: 0,
    withholdingTaxCount: 0,
    withholdingTax: 0,
    duesVatCount: 0,
    duesVatAmount: 0,
    agencyFeeCount: 0,
    agencyFeeAmount: 0,
    accidentInsuranceCount: 0,
    accidentInsuranceAmount: 0,
    employInsuranceCount: 0,
    employInsuranceAmount: 0,
    promotionCount: 0,
    promotionAmount: 0,
    compensationCount: 0,
    compensationAmount: 0,
    etcCostCount: 0,
    etcCostAmount: 0,
    depositTransaction: 0,
    companyFeesCount: 0,
    companyFees: 0,
    duesDeliveryPriceCount: 0,
    duesDeliveryPrice: 0,
    bikeRentSalesCount: 0,
    bikeRentSalesAmount: 0,
    bikeRentCostCount: 0,
    bikeRentCostAmount: 0,
    duesDeliveryPriceVatCount: 0,
    duesDeliveryPriceVat: 0,
    prechargeMisuAmount: 0,
    depositTransactionMisuAmount: 0,
    accountNcashBalance: 0,
    shareCallVatCount: 0,
    shareCallVatAmount: 0,
    duesMisu: 0,
    loanSalesCount: 0,
    loanSalesAmount: 0,
    prechargeSalesCount: 0,
    prechargeSalesAmount: 0,
    etcSalesCount: 0,
    etcSalesAmount: 0,
    loanCostCount: 0,
    loanCostAmount: 0,
    prechargeCostCount: 0,
    prechargeCostAmount: 0,
    ncashDepositAmount: 0,
    ncashDepositCount: 0,
    ncashWithdrawAmount: 0,
    ncashWithdrawCount: 0,
    shareCallVatCostAmount: 0,
    shareCallVatCostCount: 0,
    deposit: 0,
    withdraw: 0,
    callFeeVatCount: 0,
    callFeeVatAmount: 0,
    agencyFeeVatCount: 0,
    agencyFeeVatAmount: 0,
    subsidyCount: 0,
    subsidyAmount: 0,
  });
  const [batchWorkNcashMisuAmount, setBatchWorkNcashMisuAmount] = useState(0);
  const [currentYearValue, setCurrentYearValue] = useState(moment().format('YYYY'));
  const [currentMonthValue, setCurrentMonthValue] = useState(moment().format('MM'));
  const [selectStartDate, setSelectStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [selectEndDate, setSelectEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [dateType, setDateType] = useState('');
  const [yearSelectCode, setYearSelectCode] = useState([]);
  const [monthSelectCode, setMonthSelectCode] = useState([]);
  const [settleTotal, setSettleTotal] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [modalParams, setModalParams] = useState({
    title: '',
    tab: false,
    def: '',
    input: false,
    radio: false,
    select: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [tempData, setTempData] = useState({
    deliveryVat: 0,
    shareCallSales: 0,
    shareCallBuying: 0,
  });

  useEffect(() => {
    let monthcode = [];
    for (let m = 1; m <= 12; m++) {
      monthcode.push({
        month: m,
        price: 0,
      });
    }
    setMonthSelectCode(monthcode);
    return () => {
      if (source.current !== null) {
        source.current.cancel();
      }
    };
  }, []);

  useEffect(() => {
    makeYearCode();
    makeMonthData();
  }, [currentYearValue]);

  const makeYearCode = () => {
    let yearCodeTmp = [];
    for (let i = parseInt(moment().format('YYYY')); i >= 2023; i--) {
      yearCodeTmp.push(i);
    }
    setYearSelectCode(yearCodeTmp);
  };
  const getSettlementDataFromMongo = async (customParams) => {
    if (source.current !== null) {
      // already exists request
      source.current.cancel();
    }
    source.current = CancelToken.source();
    const url = 'dashboard/statistics';
    try {
      return await axios.get(url, {
        baseURL: Const.settlementUrl,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        params: customParams,
        cancelToken: source.current.token,
      });
    } catch (e) {
      console.error('getting settlement-month-date is fail: ' + e);
    }
  };
  const tempGetVatData = async (start, end) => {
    let customParams = {
      startDate: start,
      endDate: end,
      companyId: loginInfo.companyId,
    };
    if (source.current !== null) {
      source.current.cancel();
    }
    source.current = CancelToken.source();
    const url = '/tax-return-v2/franchise';
    try {
      return await axios.get(url, {
        baseURL: Const.settlementUrl,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        params: customParams,
        cancelToken: source.current.token,
      });
    } catch (e) {
      console.error('getting settlement-month-date is fail: ' + e);
    }
  };
  const makeMonthData = async () => {
    let month_temp = [];
    let sum = 0;
    if (isLoading) return;
    setIsLoading(true);
    for (let m = 1; m <= 12; m++) {
      const date = currentYearValue + '-' + m;
      const start = moment(date, 'YYYY-MM-DD').format('YYYY-MM-01');
      const end = moment(date, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD');
      const datas = await getSettleData(start, end);
      if (datas) {
        month_temp.push({
          month: m,
          price: datas.data.totalNetProfit,
        });
        if (Number(currentMonthValue) === m) {
          ////////////////// 임시
          if (moment(start).isSameOrAfter('2024-05-01')) {
            const vatData = await tempGetVatData(start, end);
            setTempData(() => {
              return {
                deliveryVat: vatData.data.totalDeliveryPriceVat,
                shareCallBuying: datas.data.shareCallVatCostAmount,
                shareCallSales: Number(
                  datas.data.deliveryVatAmount - vatData.data.totalDeliveryPriceVat - datas.data.shareCallVatCostAmount
                ),
              };
            });
          }
          /////////////////////////
          setData(() => {
            return datas.data;
          });
          const batchWorkData = await getBatchWorkData(start, end);
          if (batchWorkData.data != null) {
            setBatchWorkNcashMisuAmount(() => {
              return batchWorkData.data * -1;
            });
          }
          setSelectStartDate(start);
          setSelectEndDate(end);
        }
        sum += datas.data.totalNetProfit;
      }
    }
    if (month_temp.length > 0) {
      setMonthSelectCode(month_temp);
    }
    setSettleTotal(sum);
    setIsLoading(false);
  };
  const getSettleData = async (start, end) => {
    let customParams = {
      startDate: start,
      endDate: end,
      companyId: loginInfo.companyId,
    };
    return await getSettlementDataFromMongo(customParams);
  };
  const getBatchWorkData = async (start, end) => {
    try {
      return await httpGet(
        httpUrl.getDashboardBatchWorkData,
        [loginInfo.companyId, moment(end).add(1, 'day').format('YYYY-MM-DD'), start],
        {}
      );
    } catch (e) {
      console.error('getting batch-work-misu-Amount is fail: ' + e);
    }
  };

  const onChangeMonth = (month) => {
    const m = String(month).padStart(2, '0');
    setCurrentMonthValue(m);
    const tempdd = currentYearValue + '-' + m;
    setSelectStartDate(moment(tempdd).format('YYYY-MM-01'));
    const day =
      today < moment(tempdd).endOf('month')
        ? moment().format('YYYY-MM-DD')
        : moment(tempdd).endOf('month').format('YYYY-MM-DD');
    setSelectEndDate(day);
    setDateType('');

    getData(moment(tempdd).format('YYYY-MM-01'), day);
  };
  const onChangeDate = (type, _, date) => {
    if (type === 'start') {
      setSelectStartDate(moment(currentYearValue + currentMonthValue + date).format('YYYY-MM-DD'));
      setDateType('');
    } else {
      setSelectEndDate(moment(currentYearValue + currentMonthValue + date).format('YYYY-MM-DD'));
      setDateType('');
    }
  };

  const getData = async (start, end) => {
    setTempData(() => {
      return {
        deliveryVat: 0,
        shareCallBuying: 0,
        shareCallSales: 0,
      };
    });
    //   통계가져오기
    const datas = await getSettleData(start, end);
    if (datas.data != null) {
      ////////////////// 임시
      if (moment(start).isSameOrAfter('2024-05-01')) {
        const vatData = await tempGetVatData(start, end);
        setTempData(() => {
          return {
            deliveryVat: vatData.data.totalDeliveryPriceVat,
            shareCallBuying: datas.data.shareCallVatCostAmount,
            shareCallSales: Number(
              datas.data.deliveryVatAmount - vatData.data.totalDeliveryPriceVat - datas.data.shareCallVatCostAmount
            ),
          };
        });
      }
      /////////////////////////
      setData(() => {
        return datas.data;
      });
    }
    //   일차감 가져오기
    const batchWorkData = await getBatchWorkData(start, end);
    if (batchWorkData != null) {
      setBatchWorkNcashMisuAmount(() => {
        return batchWorkData.data * -1;
      });
    }
  };

  const onClickDateTypeButton = async (type) => {
    if (type === 'yesterday') {
      const start = moment().add(-1, 'day').format('YYYY-MM-DD');
      const end = moment().add(-1, 'day').format('YYYY-MM-DD');
      setSelectStartDate(start);
      setSelectEndDate(end);
      setDateType('yesterday');
      await getData(start, end);
    } else if (type === 'today') {
      setSelectStartDate(today);
      setSelectEndDate(today);
      setDateType('today');
      await getData(today, today);
    }
  };
  // title:모달 타이틀, tab:탭메뉴 노출 유무, def:탭메뉴 디폴트값, input:검색창 노출 유무, radio:라디오 노출 유
  const onClickOpenModal = (params) => {
    setOpenModal(true);
    setModalParams({
      title: params.title,
      tab: params.tab,
      def: params.def,
      input: params.input,
      radio: params.radio,
      select: params.select,
    });
  };
  const onClickCloseModal = () => {
    setOpenModal(false);
    setModalParams({
      title: '',
      tab: false,
      def: '',
      input: false,
      radio: false,
      select: false,
    });
  };

  return (
    <div id={'settlement'}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 20,
        }}>
        <div className={'selectMonthTable'}>
          <div
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              marginBottom: 6,
            }}>
            정산내역
          </div>
          <div
            style={{
              border: '1px solid #c1c1c1',
              height: '96%',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 3,
            }}>
            <div
              style={{
                backgroundColor: '#eee',
                borderBottom: '1px solid #c1c1c1',
                display: 'flex',
                alignItems: 'center',
              }}>
              <Select
                style={{
                  marginRight: 10,
                  marginLeft: 10,
                  width: '100%',
                  fontSize: 16,
                }}
                value={currentYearValue}
                onChange={(value) => setCurrentYearValue(value)}>
                {yearSelectCode.map((value) => {
                  return (
                    <Option key={value} value={value}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </div>
            {monthSelectCode.map((item) => {
              return (
                <div
                  key={item.month}
                  style={{
                    width: '100%',
                    backgroundColor: parseInt(currentMonthValue) === item.month ? '#C2E4FF' : '#F8F8F8',
                    color: parseInt(currentMonthValue) === item.month ? '#008FFF' : '#000',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    onChangeMonth(item.month);
                  }}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: item.price ? 'bold' : 'normal',
                      fontSize: 18,
                    }}>
                    <div
                      style={{
                        padding: '0 10px',
                        width: '20%',
                        textAlign: 'center',
                      }}>
                      {String(item.month).padStart(2, '0')} 월
                    </div>
                    <div
                      style={{
                        padding: '0 10px',
                        width: '70%',
                        textAlign: 'center',
                      }}>
                      {item.price ? comma(item.price) + '원' : '-'}
                    </div>
                    <div
                      style={{
                        padding: '0 10px',
                        width: '10%',
                        textAlign: 'center',
                      }}>
                      <RightOutlined />
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                backgroundColor: '#000',
              }}>
              <div
                style={{
                  color: '#fff',
                  fontSize: 18,
                  padding: '0 10px',
                  width: '20%',
                  textAlign: 'center',
                }}>
                총 합계
              </div>
              <div
                style={{
                  color: '#fff',
                  fontSize: 24,
                  fontWeight: 'bold',
                  padding: '0 10px',
                  width: '80%',
                  textAlign: 'center',
                }}>
                {comma(settleTotal)}
              </div>
            </div>
          </div>
        </div>
        <div style={{ flex: 1, paddingLeft: 20, height: '100%' }}>
          <div id={'settlementDetail'}>
            {/*{isLoading && (*/}
            {/*  <div className={'spin-overlay'}>*/}
            {/*    <Spin className={'spin'} />*/}
            {/*  </div>*/}
            {/*)}*/}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 10,
              }}>
              <div style={{ fontSize: 18, fontWeight: 'bold', marginRight: 10 }}>
                {currentYearValue + '년 ' + currentMonthValue + '월'}
              </div>
              <div>
                <DatePicker
                  style={{ width: 70 }}
                  onChange={(_, date) => onChangeDate('start', _, date)}
                  value={moment(selectStartDate, 'YYYY-MM-DD')}
                  format={'DD'}
                />
                <span> ~ </span>
                <DatePicker
                  style={{ width: 70 }}
                  onChange={(_, date) => onChangeDate('end', _, date)}
                  value={moment(selectEndDate, 'YYYY-MM-DD')}
                  format={'DD'}
                />
              </div>
              <Button style={{ marginLeft: 10 }} onClick={() => getData(selectStartDate, selectEndDate)}>
                검색
              </Button>
              <div style={{ marginLeft: 30 }}>
                <Button
                  onClick={() => onClickDateTypeButton('yesterday')}
                  disabled={moment(selectEndDate).isSameOrBefore(moment().format('YYYY-MM-01'))}
                  style={{
                    borderRadius: 20,
                    backgroundColor: dateType === 'yesterday' && '#4EA6EB',
                    color: dateType === 'yesterday' && '#fff',
                  }}>
                  전일
                </Button>
                <Button
                  onClick={() => onClickDateTypeButton('today')}
                  disabled={moment(selectEndDate).isBefore(moment().format('YYYY-MM-01'))}
                  style={{
                    borderRadius: 20,
                    marginLeft: 5,
                    backgroundColor: dateType === 'today' && '#4EA6EB',
                    color: dateType === 'today' && '#fff',
                  }}>
                  당일
                </Button>
                <span style={{ marginLeft: 10 }}>각 항목을 클릭 시 세부 내역을 확인하실 수 있습니다.</span>
              </div>
            </div>
            <div className="settleDetail">
              {/* 매출 */}
              <div className="settleDetailContent">
                <div className="settleDetailTitle">수입</div>
                <div className="settleDetailBox">
                  <div className="settleDetailCategoryBox" style={{ backgroundColor: '#C2E4FF', borderRadius: 3 }}>
                    <div className="settleDetailDiv">구분</div>
                    <div className="settleDetailCount" style={{ fontWeight: 'bold' }}>
                      건수 합계
                    </div>
                    <div className="settleDetailTotal" style={{ fontWeight: 'bold' }}>
                      금액 합계
                    </div>
                  </div>
                  {data.companyFees !== 0 && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '대리점 수수료', def: '', tab: true, input: true })}>
                      <div className="settleDetailDiv">대리점 수수료</div>
                      <div className="settleDetailCount">{comma(data.companyFeesCount)}건</div>
                      <div className="settleDetailTotal">{comma(data.companyFees)}원</div>
                    </div>
                  )}
                  {(data.dues !== 0 || data.duesMisu !== 0) && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '가맹비', input: true })}>
                      <div className="settleDetailDiv">가맹비</div>
                      <div className="settleDetailCount">{comma(data.duesCount)}건</div>
                      <div className="settleDetailTotal">
                        <p className="settleDetailPrice">{comma(data.dues)}원</p>
                        <p className="settleDetailMisu">({comma(data.duesMisu)})</p>
                      </div>
                    </div>
                  )}
                  {data.duesDeliveryPrice !== 0 && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '건별 가맹비', input: true })}>
                      <div className="settleDetailDiv">건별 가맹비</div>
                      <div className="settleDetailCount">{comma(data.duesDeliveryPriceCount)}건</div>
                      <div className="settleDetailTotal">{comma(data.duesDeliveryPrice)}원</div>
                    </div>
                  )}
                  {data.bikeRentSalesAmount !== 0 && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '바이크 렌트', def: '', tab: true, input: true })}>
                      <div className="settleDetailDiv">바이크 렌트</div>
                      <div className="settleDetailCount">{comma(data.bikeRentSalesCount)}건</div>
                      <div className="settleDetailTotal">{comma(data.bikeRentSalesAmount)}원</div>
                    </div>
                  )}
                  {data.loanSalesAmount !== 0 && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '대여금 회수', input: true })}>
                      <div className="settleDetailDiv">대여금 회수</div>
                      <div className="settleDetailCount">{comma(data.loanSalesCount)}건</div>
                      <div className="settleDetailTotal">{comma(data.loanSalesAmount)}원</div>
                    </div>
                  )}
                  {data.prechargeSalesAmount !== 0 && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '선충전 회수', input: true })}>
                      <div className="settleDetailDiv">선충전 회수</div>
                      <div className="settleDetailCount">{comma(data.prechargeSalesCount)}건</div>
                      <div className="settleDetailTotal">{comma(data.prechargeSalesAmount)}원</div>
                    </div>
                  )}
                  {data.etcSalesAmount !== 0 && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '기타', input: true })}>
                      <div className="settleDetailDiv">기타</div>
                      <div className="settleDetailCount">{comma(data.etcSalesCount)}건</div>
                      <div className="settleDetailTotal">{comma(data.etcSalesAmount)}원</div>
                    </div>
                  )}
                </div>
                <div className="settleDetailSumBox">
                  <div style={{ fontSize: 16 }}>수입 합계</div>
                  <div style={{ fontSize: 24 }}>{comma(data.totalSalesAmount)}</div>
                </div>
              </div>

              {/* 비용 */}
              <div className="settleDetailContent">
                <div className="settleDetailTitle">비용</div>
                <div className="settleDetailBox">
                  <div className="settleDetailCategoryBox" style={{ backgroundColor: '#C2E4FF', borderRadius: 3 }}>
                    <div className="settleDetailDiv">구분</div>
                    <div className="settleDetailCount" style={{ fontWeight: 'bold' }}>
                      건수 합계
                    </div>
                    <div className="settleDetailTotal" style={{ fontWeight: 'bold' }}>
                      금액 합계
                    </div>
                  </div>
                  {data.agencyFeeAmount !== 0 && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '프로그램 사용료', input: true })}>
                      <div className="settleDetailDiv">프로그램 사용료</div>
                      <div className="settleDetailCount">{comma(data.agencyFeeCount)}건</div>
                      <div className="settleDetailTotal">{comma(data.agencyFeeAmount)}원</div>
                    </div>
                  )}
                  {data.accidentInsuranceAmount !== 0 && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '산재보험료(대리점 부담분)', input: true })}>
                      <div className="settleDetailDiv">
                        <p>산재보험료</p>
                        <p className="settleDetailSub">(대리점 부담분)</p>
                      </div>
                      <div className="settleDetailCount">{comma(data.accidentInsuranceCount)}건</div>
                      <div className="settleDetailTotal">{comma(data.accidentInsuranceAmount)}원</div>
                    </div>
                  )}
                  {data.employInsuranceAmount !== 0 && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '고용보험료(대리점 부담분)', input: true })}>
                      <div className="settleDetailDiv">
                        <p>고용보험료</p>
                        <p className="settleDetailSub">(대리점 부담분)</p>
                      </div>
                      <div className="settleDetailCount">{comma(data.employInsuranceCount)}건</div>
                      <div className="settleDetailTotal">{comma(data.employInsuranceAmount)}원</div>
                    </div>
                  )}
                  {data.subsidyAmount !== 0 && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '배달 지원금', input: true, select: true })}>
                      <div className="settleDetailDiv">배달 지원금</div>
                      <div className="settleDetailCount">{comma(data.subsidyCount)}건</div>
                      <div className="settleDetailTotal">{comma(data.subsidyAmount)}원</div>
                    </div>
                  )}
                  {data.compensationAmount !== 0 && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '음식배상', input: true })}>
                      <div className="settleDetailDiv">음식배상</div>
                      <div className="settleDetailCount">{comma(data.compensationCount)}건</div>
                      <div className="settleDetailTotal">{comma(data.compensationAmount)}원</div>
                    </div>
                  )}
                  {data.bikeRentCostAmount !== 0 && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '바이크 렌트 납부', def: '', tab: true, input: true })}>
                      <div className="settleDetailDiv">바이크 렌트</div>
                      <div className="settleDetailCount">{comma(data.bikeRentCostCount)}건</div>
                      <div className="settleDetailTotal">{comma(data.bikeRentCostAmount)}원</div>
                    </div>
                  )}
                  {data.promotionAmount !== 0 && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '프로모션', input: true })}>
                      <div className="settleDetailDiv">프로모션</div>
                      <div className="settleDetailCount">{comma(data.promotionCount)}건</div>
                      <div className="settleDetailTotal">{comma(data.promotionAmount)}원</div>
                    </div>
                  )}
                  {data.loanCostAmount !== 0 && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '대여금 지급', input: true })}>
                      <div className="settleDetailDiv">대여금</div>
                      <div className="settleDetailCount">{comma(data.loanCostCount)}건</div>
                      <div className="settleDetailTotal">{comma(data.loanCostAmount)}원</div>
                    </div>
                  )}
                  {data.prechargeCostAmount !== 0 && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '선충전 지급', input: true })}>
                      <div className="settleDetailDiv">선충전</div>
                      <div className="settleDetailCount">{comma(data.prechargeCostCount)}건</div>
                      <div className="settleDetailTotal">{comma(data.prechargeCostAmount)}원</div>
                    </div>
                  )}
                  {data.etcCostAmount !== 0 && (
                    <div
                      className="settleDetailCategoryBox"
                      onClick={() => onClickOpenModal({ title: '기타 비용', input: true })}>
                      <div className="settleDetailDiv">기타 비용</div>
                      <div className="settleDetailCount">{comma(data.etcCostCount)}건</div>
                      <div className="settleDetailTotal">{comma(data.etcCostAmount)}원</div>
                    </div>
                  )}
                </div>
                <div className="settleDetailSumBox">
                  <div style={{ fontSize: 16 }}>비용 합계</div>
                  <div style={{ fontSize: 24 }}>{comma(data.totalCostAmount)}</div>
                </div>
              </div>

              {/* 부가세 */}
              <div className="settleDetailContent">
                <div className="settleDetailTitle">예상 세금</div>
                <div className="settleDetailBox">
                  <div className="settleDetailCategoryBox" style={{ backgroundColor: '#C2E4FF', borderRadius: 3 }}>
                    <div className="settleDetailDiv">구분</div>
                    <div className="settleDetailCount" style={{ fontWeight: 'bold' }}>
                      건수 합계
                    </div>
                    <div className="settleDetailTotal" style={{ fontWeight: 'bold' }}>
                      금액 합계
                    </div>
                  </div>
                  {/* 부가세 수정을 위한 임시 주석처리.0508 */}
                  <div
                    className="settleDetailCategoryBox"
                    onClick={() => onClickOpenModal({ title: '배달비 부가세', input: true })}>
                    <div className="settleDetailDiv">배달비 부가세</div>
                    <div className="settleDetailCount">{comma(data.deliveryVatCount)}건</div>
                    <div className="settleDetailTotal">{comma(data.deliveryVatAmount)}원</div>
                  </div>
                  {/* 임시 처리 */}
                  {moment(selectStartDate).isSameOrAfter('2024-05-01') && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: -14,
                          padding: '8px 26px',
                        }}>
                        <div
                          style={{
                            fontSize: 12,
                            wordBreak: 'keep-all',
                            color: '#444',
                          }}>
                          가맹점 배달비 부가세
                        </div>
                        <div
                          style={{
                            textAlign: 'right',
                            fontSize: 12,
                            color: '#444',
                          }}>
                          {comma(tempData.deliveryVat)}원
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: -14,
                          padding: '8px 26px',
                          cursor: 'pointer',
                        }}
                        onClick={() => onClickOpenModal({ title: '공유콜 매입 부가세', input: true })}>
                        <div
                          style={{
                            fontSize: 12,
                            wordBreak: 'keep-all',
                            color: '#444',
                          }}>
                          공유콜 매입 부가세
                        </div>
                        <div
                          style={{
                            textAlign: 'right',
                            fontSize: 12,
                            color: '#444',
                          }}>
                          {comma(tempData.shareCallBuying)}원
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: -14,
                          padding: '8px 26px',
                        }}>
                        <div
                          style={{
                            fontSize: 12,
                            wordBreak: 'keep-all',
                            color: '#444',
                          }}>
                          공유콜 매출 부가세
                        </div>
                        <div
                          style={{
                            textAlign: 'right',
                            fontSize: 12,
                            color: '#444',
                          }}>
                          {comma(tempData.shareCallSales)}원
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    className="settleDetailCategoryBox"
                    onClick={() => onClickOpenModal({ title: '가맹비 부가세', input: true })}>
                    <div className="settleDetailDiv">가맹비 부가세</div>
                    <div className="settleDetailCount">{comma(data.duesVatCount)}건</div>
                    <div className="settleDetailTotal">{comma(data.duesVatAmount)}원</div>
                  </div>
                  <div
                    className="settleDetailCategoryBox"
                    onClick={() => onClickOpenModal({ title: '콜수수료 부가세', input: true })}>
                    <div className="settleDetailDiv">콜수수료 부가세</div>
                    <div className="settleDetailCount">{comma(data.callFeeVatCount)}건</div>
                    <div className="settleDetailTotal">{comma(data.callFeeVatAmount)}원</div>
                  </div>
                  {/*<div*/}
                  {/*  className="settleDetailCategoryBox"*/}
                  {/*  onClick={() => onClickOpenModal({ title: '공유콜 매입 부가세', input: true })}>*/}
                  {/*  <div className="settleDetailDiv">공유콜 매입 부가세</div>*/}
                  {/*  <div className="settleDetailCount">{comma(data.shareCallVatCostCount)}건</div>*/}
                  {/*  <div className="settleDetailTotal">{comma(data.shareCallVatCostAmount)}원</div>*/}
                  {/*</div>*/}
                  <div
                    className="settleDetailCategoryBox"
                    onClick={() => onClickOpenModal({ title: '라이더 원천세', input: true })}>
                    <div className="settleDetailDiv">라이더 원천세</div>
                    <div className="settleDetailCount">{comma(data.withholdingTaxCount)}건</div>
                    <div className="settleDetailTotal">{comma(data.withholdingTax)}원</div>
                  </div>
                  {/* 02/01월부터 시행, 데이터 확인 후에 다시 집계할 예정*/}
                  <div className="settleDetailCategoryBox" style={{ cursor: 'default' }}>
                    <div className="settleDetailDiv">프로그램 사용료 매입 부가세</div>
                    <div className="settleDetailCount">{comma(data.agencyFeeVatCount)}건</div>
                    <div className="settleDetailTotal">{comma(data.agencyFeeVatAmount)}원</div>
                  </div>
                </div>
                <div className="settleDetailSumBox">
                  <div style={{ fontSize: 16 }}>예상 세금 합계</div>
                  <div style={{ fontSize: 24 }}>{comma(data.totalTaxAmount)}</div>
                </div>
              </div>
            </div>
            <div className="seperateBar" />
            <div className="settleDetail" style={{ marginTop: 10 }}>
              <div
                className="settleSubContent"
                onClick={() =>
                  onClickOpenModal({ title: '전체 미회수', tab: true, def: 999, input: true, radio: true })
                }>
                <div className="subTitle">전체 미회수 내역</div>
                <div className="subContent">
                  <p>미회수 합계</p>
                  <p className="subNcash">{comma(batchWorkNcashMisuAmount)}</p>
                </div>
              </div>
              <div className="settleSubContent" style={{ cursor: 'default' }}>
                {/*// onClick={() => onClickOpenModal('선충전 미회수', false, '', false, false)}*/}
                <div className="subTitle">선충전</div>
                <div className="subContent">
                  <p>합계</p>
                  <p className="subNcash">{comma(data.prechargeMisuAmount)}</p>
                </div>
              </div>
              {/* 아직 데이터 검증이 안됨! */}
              <div className="settleSubContent">
                <div className="subContent transaction" onClick={() => onClickOpenModal({ title: '예치금 입금' })}>
                  <p className="transaction-title">예치금 입금</p>
                  <p className="subNcash" style={{ color: '#4EA6EB' }}>
                    {comma(data.deposit)}
                  </p>
                </div>
                <div className="subContent" onClick={() => onClickOpenModal({ title: '예치금 출금' })}>
                  <p className="transaction-title">예치금 출금</p>
                  <p className="subNcash">{comma(data.withdraw)}</p>
                </div>
              </div>
            </div>
            <div className="settleDetail" style={{ marginTop: 10 }}>
              <div
                style={{
                  width: '66%',
                  backgroundColor: '#000',
                  borderRadius: 3,
                  color: '#fff',
                  padding: 8,
                  paddingLeft: 24,
                  paddingRight: 24,
                  display: 'flex',
                  alignItems: 'flex-start',
                }}>
                <div
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    marginRight: 24,
                    marginTop: 12,
                  }}>
                  총 예상 수익
                </div>
                <div className="settleBottomBox">
                  <p
                    style={{
                      fontSize: 40,
                      fontWeight: 'bold',
                    }}>
                    {comma(data.totalNetProfit)} <span style={{ fontSize: 24, fontWeight: 'normal' }}>원</span>
                  </p>
                  <p
                    style={{
                      paddingBottom: 12,
                      marginTop: 4,
                    }}>
                    (수입, {comma(data.totalSalesAmount)}원) - (비용, {comma(data.totalCostAmount)}원) - (납부할 세금,{' '}
                    {comma(data.totalTaxAmount)}원)
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: '32%',
                  backgroundColor: '#000',
                  borderRadius: 3,
                  color: '#fff',
                  padding: 8,
                  paddingLeft: 24,
                  paddingRight: 24,
                }}>
                <div
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    marginTop: 6,
                  }}>
                  예치금 잔액
                </div>
                <div style={{ fontSize: 40, fontWeight: 'bold', marginTop: -6 }}>
                  {comma(loginInfo.companyIdNcash)} <span style={{ fontSize: 24, fontWeight: 'normal' }}>원</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {openModal && (
          <SettlementDialog
            close={onClickCloseModal}
            params={modalParams}
            // title={modalParams.title}
            // def={modalParams.def}
            // input={modalParams.input}
            // radio={modalParams.radio}
            startDate={selectStartDate}
            endDate={selectEndDate}
          />
        )}
      </div>
    </div>
  );
};

export default SettlementList;
