import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Form, Input, Modal } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { httpPost, httpUrl } from '../../../api/httpClient';

const DeliveryPriceDongRateRegisterAreaFormDialog = ({ close, data, paths, dongRateIdx }) => {
  const formRef = useRef();
  const [isFixedPrice, setIsFixedPrice] = useState(data ? data.isFixedPrice : false)
  const handleClose = () => {
    close();
  };

  const handleSubmit = async() => {
    console.log("TTTTTTTT")
    console.log(formRef.current.getFieldsValue())

    if (!formRef.current.getFieldsValue().dongName) {
      Modal.warn({
        title: '등록실패',
        content: '구역명을 입력해주세요',
      });
      return;
    } else if (!formRef.current.getFieldsValue().price) {
      Modal.warn({
        title: '등록실패',
        content: '구역에 적용될 가격을 입력해주세요',
      });
      return;
    }

    if(data) {
      updateCustomZone()
    } else {
      registCustomZone()
    }

  };

  const updateCustomZone = async () => {
    let listParsedPaths = [];
    paths.forEach((path) => {
      const listParsedPath = [path.y, path.x];
      listParsedPaths.push(listParsedPath);
    });
    const res = await httpPost(httpUrl.updateDeliveryPriceDongRateArea, [], {
      ...formRef.current.getFieldsValue(),
      isFixedPrice: isFixedPrice,
      idx: data.idx,
      polygon: JSON.stringify(listParsedPaths),
    });
    if (!res.data) {
      Modal.info({
        title: '수정 실패',
        content: '구역 수정이 실패했습니다.',
      });
      return;
    }
    Modal.info({
      title: '수정 성공',
      content: '구역 수정에 성공했습니다.',
    });
    handleClose();
  }

  const registCustomZone = () => {
    let listParsedPaths = [];
    paths.forEach((path) => {
      const listParsedPath = [path.y, path.x];
      listParsedPaths.push(listParsedPath);
    });
    const formData = {
      ...formRef.current.getFieldsValue(),
      dongRateIdx: dongRateIdx,
      isFixedPrice: isFixedPrice,
      polygon: JSON.stringify(listParsedPaths),
    };

    httpPost(httpUrl.createDeliveryPriceDongRateArea, [], formData).then((res) => {
      if (res.result === 'SUCCESS' && res.data == 'SUCCESS') {
        Modal.info({
          title: '등록성공',
          content: '신규 구역이 등록되었습니다.',
        });
        handleClose()
      } else if (res.data === 'DUPLICATED') {
        Modal.info({
          title: '등록실패',
          content: '구역 등록 실패. 이미 동일한 구역이름이 있습니다. 다른 이름을 등록해 주세요.',
        });
        return;
      } else if (res.data === 'NOT_EXIST_DONG_RATE') {
        Modal.info({
          title: '등록실패',
          content: '구역 등록 실패. 요금제를 선택 중 인지 확인하세요.',
        });
        return;
      }
    });
  };

  return (
    <div style={{ backgroundColor: 'rgba(0,0,0,0.56)' }} className="absolute flex-1 h-full w-full z-[12]">
      <div className="realtive">
        <div style={{ position: 'absolute' }} className="nn-modal-container">
          <div className="nn-modal-title-container">
            <span>구역{data ? '수정': '등록'}</span>
            <div className="nn-modal-close" onClick={handleClose}></div>
          </div>
          <div className="nn-modal-title-divider"></div>
          <div className="w-[450px] p-[20px]">
            <div className="flex mb-[20px] items-center justify-between">
              <Form className="w-full" ref={formRef} onFinish={handleSubmit}>
                <div className="flex items-center">
                  <FormItem className="m-0" name="isFixedPrice">
                    <Checkbox
                      className="mr-[10px] mb-[10px]"
                      checked={isFixedPrice}
                      onChange={(e) => setIsFixedPrice(e.target.checked )}></Checkbox>
                  </FormItem>
                  <div className="font-['NotoSansBold'] nn-modal-input-label">배달비 고정금액 설정</div>
                </div>
                <div className="mb-[20px]">
                  <div className="text-[12px]">배달비 고정금액 설정이란? 거리별요금제와 지역 할증이 포함되지 않습니다.</div>
                </div>
                <div className="flex-1 mb-[20px]">
                  <div className="nn-modal-input-label">구역명 입력</div>
                  <FormItem
                    name="dongName"
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: '구역명을 입력해 주세요.' }]}
                    initialValue={data && data.dongName}
                  >
                    <Input className="nn-modal-input" placeholder="구역명을 입력해 주세요." />
                  </FormItem>
                </div>
                <div className="flex-1">
                  <div className="nn-modal-input-label">금액 (원)</div>
                  <FormItem
                    name="price"
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: '금액을 입력해 주세요.' }]}
                    initialValue={data && data.price}
                  >
                    <Input className="nn-modal-input" placeholder="금액을 입력해 주세요." />
                  </FormItem>
                </div>
              </Form>
            </div>
            <div className="flex justify-between">
              <Button className="flex-1 nn-modal-button h-[50px] mr-[10px]" onClick={handleClose} htmlType="button">
                취소
              </Button>
              <Button className="flex-1 nn-modal-button h-[50px] bg-[#fadc00]" htmlType="submit" onClick={handleSubmit}>
                {data ? '수정':'등록'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPriceDongRateRegisterAreaFormDialog;
