import { Button, Form, Input, Modal, Select, Table } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPost, httpUrl } from '../../../api/httpClient';
import { customAlert, customError } from '../../../api/Modals';
import '../../../css/modal.css';
import '../../../css/modal_m.css';
import {
  freeDuesApprovalStatus,
  freeDuesAutoPassive,
  freeDuesString,
} from '../../../lib/util/codeUtil';
import {
  formatDate,
  formatDateToDayPlusSelectedMonth,
  getFreeDuesMonth,
  monthFormat,
} from '../../../lib/util/dateUtil';
import CloseIcon from '../../../img/login/close.png';
import moment from 'moment';

const Option = Select.Option;
const FormItem = Form.Item;

class DuesExceptionDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      monthNum: 0,
      duesAutoPassive: 0,
      nyamnyamApprovalList: [],
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getApprovalList();
  }

  // 가맹비 예외설정
  handleSubmit = () => {
    let self = this;
    const data = this.props.data;
    if (
      parseInt(this.state.duesAutoPassive) === 1 &&
      isNaN(self.formRef.current.getFieldsValue().data)
    ) {
      Modal.warn({ content: '가맹비에는 숫자만 입력해주세요!' });
      return;
    }
    Modal.confirm({
      title: '가맹비 예외설정',
      content: <div>가맹비 예외 설정 하시겠습니까?</div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        console.log(
          JSON.stringify({
            category: 'DUES',
            userIdx: data.idx,
            rawData: JSON.stringify({
              data:
                self.state.duesAutoPassive == 0
                  ? '자동'
                  : self.formRef.current.getFieldValue('data'),
              date: formatDateToDayPlusSelectedMonth(
                data.registDate,
                self.state.monthNum
              ),
            }),
            status: 0,
            ...self.formRef.current.getFieldsValue(),
          })
        );
        httpPost(httpUrl.duesExceptionCreate, [], {
          category: 'DUES',
          userIdx: data.idx,
          rawData: JSON.stringify({
            data:
              self.state.duesAutoPassive == 0
                ? '자동'
                : self.formRef.current.getFieldValue('data'),
            // data: self.state.duesData === -1 ? "자동" : self.state.duesData + "",
            date: formatDateToDayPlusSelectedMonth(
              data.registDate,
              self.state.monthNum
            ),
          }),
          status: 0,
          ...self.formRef.current.getFieldsValue(),
        })
          .then((res) => {
            console.log(res.result);
            console.log(res.data);
            if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
              customAlert('가맹비 예외설정 신청이 완료되었습니다.');
            } else if (res.data === 'NO_DATA') {
              Modal.info({
                title: '등록실패',
                content: '수동가맹비가 제대로 입력됐는지 확인해주세요.',
              });
              return;
            } else {
              Modal.info({
                title: '설정실패',
                content:
                  '가맹점 예외설정에 실패했습니다. 관리자에게 문의해주세요.',
              });
              return;
            }
            self.props.close();
          })
          .catch((error) => {
            customError(
              '등록 오류',
              '오류가 발생하였습니다. 다시 시도해 주십시오.'
            );
          });
      },
    });
  };

  getApprovalList = () => {
    const data = this.props.data;
    let userIdx = data.idx;
    httpGet(httpUrl.duesExceptionList, [userIdx], {}).then((res) => {
      if (res.result === 'SUCCESS') {
        this.setState({
          nyamnyamApprovalList: res.data.nyamnyamApproval,
        });
      }
    });
  };

  render() {
    const { close, data } = this.props;

    const columns = [
      {
        title: '신청일시',
        dataIndex: 'createDate',
        className: 'table-column-center',
        render: (data) => (
          <div>
            {moment(data).subtract(9, 'hours').format('YYYY-MM-DD HH:mm')}
          </div>
        ),
      },
      {
        title: '신청내용( 신청금액 / 적용기간 )',
        dataIndex: 'rawData',
        className: 'table-column-center',
        render: (data, row) => {
          const content = JSON.parse(data).data;
          const freeDuesMonths = getFreeDuesMonth(
            JSON.parse(data).date,
            this.props.data.registDate
          );
          return (
            <div>
              {'신청금액 : ' + content} {' / ' + freeDuesMonths}
            </div>
          );
        },
      },
      {
        title: '메모',
        dataIndex: 'memo',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '상태',
        dataIndex: 'status',
        className: 'table-column-center',
        render: (data) => <div>{freeDuesApprovalStatus[data]}</div>,
      },
    ];

    return (
      <React.Fragment>
        <div className="duesException-Dialog-overlay" onClick={close} />
        <div className="duesException-Dialog">
          <div className="duesException-content">
            <div className="duesException-title">
              가맹비 예외 설정 승인 신청
            </div>
            <img
              onClick={close}
              src={CloseIcon}
              className="duesException-close"
              alt="close"
            />

            <Form ref={this.formRef} onFinish={this.handleSubmit}>
              <div className="duesExceptionlayout">
                <div className="duesExceptionWrapper">
                  <div className="contentBlock">
                    <div className="mainTitle">무료기간</div>
                    <FormItem
                      name="date"
                      className="selectItem"
                      rules={[
                        {
                          required: true,
                          message: '가맹비 예외 적용할 개월 수를 선택하세요',
                        },
                      ]}
                      initialValue="미적용">
                      <Select
                        className="override-select"
                        onChange={(value) =>
                          this.setState({ monthNum: value })
                        }>
                        {Object.keys(freeDuesString).map((value, index) => {
                          return (
                            <Option value={value} key={index}>
                              {freeDuesString[value]}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">가맹비</div>
                    <Select
                      className="override-select-sub"
                      onChange={(value) =>
                        this.setState({ duesAutoPassive: value })
                      }
                      defaultValue={freeDuesAutoPassive[0]}>
                      {Object.keys(freeDuesAutoPassive).map((value, index) => {
                        return (
                          <Option value={value} key={index}>
                            {freeDuesAutoPassive[value]}
                          </Option>
                        );
                      })}
                    </Select>
                    <FormItem name="data" className="selectItem">
                      <Input
                        placeholder="고정할 가맹비 금액을 입력(VAT포함)"
                        className="override-input-sub"
                        disabled={
                          this.state.duesAutoPassive == 0 ? true : false
                        }
                        onChange={(e) => {
                          if (
                            parseInt(this.state.duesAutoPassive) === 1 &&
                            isNaN(e.target.value)
                          ) {
                            Modal.warn({
                              content: '가맹비에는 숫자만 입력해주세요!',
                            });
                            return;
                          }
                        }}></Input>
                    </FormItem>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">사유</div>
                    <FormItem
                      name="memo"
                      className="selectItem"
                      initialValue="">
                      <Input
                        placeholder="예외설정 사유를 입력해주세요."
                        className="override-input"></Input>
                    </FormItem>
                  </div>
                  <div className="contentBlock-Table">
                    <div className="mainTitle">※ 승인 신청 내역 (최근3개)</div>
                  </div>
                  <div className="dataTableLayout-01">
                    <Table
                      style={{ marginTop: '10px' }}
                      dataSource={this.state.nyamnyamApprovalList}
                      columns={columns}
                    />
                  </div>
                  <div className="contentBlock-Table">
                    <div>
                      * 천콜상점은 별도로 예외처리하지 않아도 됩니다. (자동면제)
                    </div>
                    <div>
                      * 무료기간은 가맹점 등록일로부터 해당 개월만큼 적용됩니다.
                    </div>
                    <div>
                      * 무료기간과 가맹비는 각각 별도로 적용됩니다. (무료6개월,
                      수동44,000원 적용하는 경우 6개월동안 무료이고 무료가
                      끝나면 44,000원으로 고정가맹비가 차감됨)
                    </div>
                  </div>
                  <div className="submitBlock">
                    <Button type="primary" htmlType="submit">
                      승인신청
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DuesExceptionDialog);
