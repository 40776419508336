import React, { useState } from 'react';
import NcashCompanyList from './NcashCompanyList';
import MisuList from './MisuList';
import WithdrawList from './WithdrawList';
import WithdrawAgencyNcash from './WithdrawAgencyNcash';
import NcashList from './NcashList';
import NcashDailyList from './NcashDailyList';
import { Button } from 'antd';
import RegistBatchWorkDialog from '../../components/dialog/rider/RegistBatchWorkDialog';

const BatchworkListLayout = () => {
  const [pageContent, setPageContent] = useState(0);
  const [taskworkModalOpen, setTaskworkModalOpen] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  const pageContentCode = [
    {
      value: 0,
      menu: '일차감내역',
    },
    {
      value: 1,
      menu: '일차감목록',
    },
  ];

  return (
    <>
      {taskworkModalOpen && (
        <RegistBatchWorkDialog
          close={() => setTaskworkModalOpen(false)}
          callback={() => setRefreshTrigger(!refreshTrigger)}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          marginBottom: 20,
        }}>
        {pageContentCode.map((code) => {
          return (
            <>
              <div
                style={{
                  flex: 1,
                  borderBottomColor: 'grey',
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 1,
                }}
              />
              <div
                style={{
                  flex: 5,
                  padding: 10,
                  borderColor: 'grey',
                  borderStyle: 'solid',
                  borderWidth: 1,
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  borderBottom: pageContent === code.value ? 'none' : 'grey solid 1px',
                }}
                key={code.value}
                onClick={() => setPageContent(code.value)}>
                {code.menu}
              </div>
            </>
          );
        })}
        <div
          style={{
            flex: 1,
            borderBottomColor: 'grey',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
          }}
        />
        <div
          style={{
            borderBottomColor: 'grey',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
          }}>
          <Button onClick={() => setTaskworkModalOpen(true)}>일차감 등록</Button>
        </div>

        <div
          style={{
            flex: 100,
            borderBottomColor: 'grey',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
          }}
        />
      </div>
      {pageContent === 0 && <NcashDailyList refreshTrigger={refreshTrigger} />}
      {pageContent === 1 && <NcashList refreshTrigger={refreshTrigger} />}
    </>
  );
};

export default BatchworkListLayout;
