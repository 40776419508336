import { Button, Input, Modal, Table } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpPost, httpUrl } from '../../../api/httpClient';
import '../../../css/common.css';
import '../../../css/common_m.css';
import '../../../css/modal.css';
import '../../../css/modal_m.css';
import '../../../css/order.css';
import '../../../css/order_m.css';
import { accountFormat } from '../../../lib/util/numberUtil';
import CloseIcon from '../../../img/login/close.png';

class ModifyDeliveryPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      orderIdx: '',
      riderName: '',
      basicDeliveryPrice: this.props.data.basicDeliveryPrice,
      extraDeliveryPrice: this.props.data.extraDeliveryPrice,

    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    let orderIdx = this.props.data.idx;
    let riderName = this.props.data.riderName;
    httpGet(httpUrl.selectCompleteOrder, [orderIdx], {}).then((res) => {
      this.setState({
        list: res.data.order,
        riderName: riderName,
      });
    });
  };

  handleDeliveryPrice = () => {
    if (
      this.props.data.basicDeliveryPrice === this.state.basicDeliveryPrice &&
      this.props.data.extraDeliveryPrice === this.state.extraDeliveryPrice
    ) {
      Modal.warn({
        title: '요금 수정 실패',
        content: '금액을 입력해주세요.',
      });
      return false;
    }
    let self = this;
    Modal.confirm({
      title: <div>배송비등록</div>,
      content: <div>입력하신 요금으로 배송비변경하시겠습니까?</div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.changeDeliveryPrice, [], {
          orderIdx: self.props.data.idx,
          deliveryPriceBasic: self.state.basicDeliveryPrice,
          deliveryPriceExtra: self.state.extraDeliveryPrice,
        })
          .then((res) => {
            if (res.result === 'SUCCESS') {
              if (res.data === 'NOT_ENOUGH_NCASH') {
                Modal.info({
                  title: '배송비 변경 실패',
                  content: '가맹점 잔액이 부족하여 배송비 변경 실패했습니다.',
                });
              } else if (res.data === 'SUCCESS') {
                Modal.info({
                  title: '수동등록성공',
                  content: '수동 등록에 성공하였습니다.',
                });
                self.setState(() => self.props.close());
              } else if (res.data === 'NOT_CHANGE_STATUS_7_DAYS') {
                Modal.info({
                  title: '요금 수정 불가',
                  content: '40일 전 요금은 수정 불가합니다.',
                });
              }
            } else {
              Modal.info({
                title: '수정 실패',
                content: '요금 수정에 실패했습니다.',
              });
            }
          })
          .catch((e) => {
            Modal.info({
              title: '수정 실패',
              content: '요금 수정에 실패했습니다.',
            });
            throw e;
          });
      },
    });
  };

  render() {
    const { data, close } = this.props;

    const columns = [
      {
        title: '주문번호',
        dataIndex: 'idx',
        className: 'table-column-center',
      },
      {
        title: '라이더명',
        dataIndex: 'riderName',
        className: 'table-column-center',
        render: (data, row) => <div>{this.state.riderName}</div>,
      },
      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center',
      },
      {
        title: '기본배달요금',
        dataIndex: 'basicDeliveryPrice',
        className: 'table-column-center',
        render: (data, row) => <div>{accountFormat(data)}</div>,
      },
      {
        title: '할증배달요금',
        dataIndex: 'extraDeliveryPrice',
        className: 'table-column-center',
        render: (data, row) => <div>{accountFormat(data)}</div>,
      },
    ];

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="taskWork-Dialog2" style={{ textAlign: 'left', right: '600px' }}>
          <div className="taskWork-content" style={{ height: '300px' }}>
            {/* 타이틀 */}
            <div className="taskWork-title w-full" style={{ marginBottom: '10px' }}>
              <span>배송비변경</span>
              <img onClick={close} src={CloseIcon} className="taskWork-close" alt="close" />
            </div>

            <Table dataSource={this.state.list} columns={columns} rowKey={(record) => record.idx} pagination={false} />
            <div className="input-deliveryPrice">
              <span>
                <>기본배달요금: </>
                <Input
                  style={{
                    display: 'inline-block',
                    width: 100,
                    margin: '10px',
                  }}
                  onChange={(e) => this.setState({ basicDeliveryPrice: e.target.value })}
                />
              </span>
              <span>
                <>할증배달요금: </>
                <Input
                  style={{
                    display: 'inline-block',
                    width: 100,
                    margin: '10px',
                  }}
                  onChange={(e) => this.setState({ extraDeliveryPrice: e.target.value })}
                />
              </span>

              <Button onClick={this.handleDeliveryPrice} style={{ marginTop: '10px' }}>
                등록
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ModifyDeliveryPrice;
