import React, { Component } from 'react';
import { Table, Button, Tag, Modal } from 'antd';
import '../../../css/modal.css';
import '../../../css/modal_m.css';
import RiderTeamRegistDialog from './RiderTeamRegistDialog';
import SearchRiderDialog from '../../dialog/common/SearchRiderDialog';
import SearchRiderTalkTeamDialog from '../../dialog/common/SearchRiderTalkTeamDialog';
import { httpUrl, httpPost } from '../../../api/httpClient';
import { connect } from 'react-redux';
import { customAlert, customError } from '../../../api/Modals';
import CloseIcon from '../../../img/login/close.png';
import { riderLevelText } from '../../../lib/util/codeUtil';
import _ from 'lodash';

class RiderTeamDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      addRiderOpen: false,
      addAdminOpen: false,
      registTeamOpen: false,
      teamIdx: 0,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  // 지점에 해당된 팀 리스트
  getList = () => {
    const { pagination } = this.state;
    httpPost(httpUrl.getRiderTeamList, [], {
      branchIdx: this.props.branchIdx,
      pageNum: pagination.current,
      pageSize: pagination.pageSize,
    })
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data !== null) {
          this.setState({
            list: _.uniqBy(res.data.teamList, 'idx'),
          });
        } else {
          customAlert('목록 에러', '에러가 발생하여 목록을 불러올수 없습니다.');
        }
      })
      .catch((e) => {
        customAlert('목록 에러', '에러가 발생하여 목록을 불러올수 없습니다.');
      });
  };

  // 팀삭제
  deleteTeam = (row) => {
    let self = this;
    Modal.confirm({
      title: '팀 삭제',
      content: row.name + ' 팀을 삭제하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        let teamIdx = row.idx;
        httpPost(httpUrl.deleteRiderTeam, [], {
          teamIdx,
        })
          .then((res) => {
            console.log('삭제 결과!!!');
            console.log(res);
            if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
              customAlert('팀 삭제', row.name + ' 팀을 삭제하였습니다.');
              self.getList();
            } else if (res.result === 'SUCCESS' && res.data === 'TEAM_RIDER_EXISTS') {
              Modal.error({
                title: "삭제 실패",
                content: "팀에 팀원이 존재합니다."
              });
              return;
            } else {
              customError(
                '삭제 에러',
                '에러가 발생하여 그룹을 삭제할 수 없습니다.'
              );
            }
          })
          .catch((e) => {
            customError(
              '삭제 에러',
              '에러가 발생하여 그룹을 삭제할 수 없습니다.'
            );
          });
      },
    });
  };

  // 팀에서 라이더 삭제
  deleteRider = (row, record) => {
    let self = this;
    let riderIdx = row.userIdx;
    httpPost(httpUrl.deleteOneRiderFromTeam, [], {
      riderIdx,
      teamIdx: record.idx,
    })
      .then((res) => {
        console.log(res);
        if (res.result === 'SUCCESS') {
          customAlert('팀원 삭제', row.riderName + ' 팀원을 삭제하였습니다.');
          self.getList();
        } else {
          customError('삭제 에러', '에러가 발생하여 팀원을 삭제할수 없습니다.');
        }
      })
      .catch((e) => {
        customError('삭제 에러', '에러가 발생하여 팀원을 삭제할수 없습니다.');
      });
  };

  // 팀에 팀원 추가
  addRiderToTeam = (data) => {
    let self = this;
    let teamRiderIdx = data.idx;
    let teamRiderName = data.riderName;
    let teamIdx = self.state.teamIdx;
    Modal.confirm({
      title: '팀원 추가',
      content: `${teamRiderName}을/를 팀원으로 추가하시겠습니까?`,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.updateTeamRider, [], {
          teamRiderIdx,
          teamIdx,
        })
          .then((res) => {
            console.log(res);
            if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
              customAlert('팀원 추가', ' 팀에 팀원을 추가하였습니다.');
              self.getList();
            } else if (res.result === 'SUCCESS' && res.data === 'RIDER_LEVEL_NOT_MATCH') {
              Modal.info({
                title: "추가 실패",
                content: "팀원으로 등록하려는 기사의 직급을 확인해주세요."
              });
            } else {
              customError(
                '추가 에러',
                '에러가 발생하여 팀원을 추가할수 없습니다.'
              );
            }
          })
          .catch((e) => {
            customError(
              '추가 에러',
              '에러가 발생하여 팀원을 추가할수 없습니다.'
            );
          });
      },
    });
  };

  // 팀에 팀 관리자 추가
  addAdminToTeam = (data) => {
    let self = this;
    let teamHeadIdx = data.idx;
    let teamIdx = self.state.teamIdx;
    let teamHeadName = data.riderName;
    Modal.confirm({
      title: '팀 관리자 추가',
      content: `${teamHeadName}을/를 팀 관리자로 추가/변경하시겠습니까?`,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.updateTeamHead, [], {
          teamHeadIdx,
          teamIdx,
        })
          .then((res) => {
            console.log(res);
            if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
              customAlert(
                '팀 관리자 추가',
                ' 팀에 팀 관리자를 추가/변경하였습니다.'
              );
              self.getList();
            } else if (res.result === 'SUCCESS' && res.data === 'TEAM_HEAD_ALREADY_EXISTS') {
              Modal.info({
                title: "추가 실패",
                content: "동일한 팀관리자가 이미 존재합니다."
              });
            } else if (res.result === 'SUCCESS' && res.data === 'RIDER_LEVEL_NOT_MATCH') {
              Modal.info({
                title: "추가 실패",
                content: "팀 관리자로 등록하려는 기사의 직급을 확인해주세요."
              });
            } else {
              customError(
                '추가 에러',
                '에러가 발생하여 팀 관리자를 추가/변경할수 없습니다.'
              );
            }
          })
          .catch((e) => {
            customError(
              '추가 에러',
              '에러가 발생하여 팀 관리자를 추가/변경할수 없습니다.'
            );
          });
      },
    });
  };

  // 가맹점 그룹추가 dialog
  openAddRiderModal = (row) => {
    this.setState({ addRiderOpen: true, teamIdx: row.idx });
  };
  closeAddRiderModal = () => {
    this.setState({ addRiderOpen: false });
  };
  openAddAdminModal = (row) => {
    this.setState({ addAdminOpen: true, teamIdx: row.idx });
  };
  closeAddAdminModal = () => {
    this.setState({ addAdminOpen: false });
  };

  // 팀 등록 dialog
  openRiderTeamModal = () => {
    this.setState({ registTeamOpen: true });
  };
  closeRiderTeamModal = () => {
    this.setState({ registTeamOpen: false });
    this.getList();
  };

  render() {
    const columns = [
      {
        title: '팀명',
        width: '50px',
        dataIndex: 'name',
        className: 'table-column-center',
      },
      {
        title: '팀관리자 추가',
        className: 'table-column-center',
        width: '80px',
        render: (row) => (
          <div>
            <Button
              className="tabBtn"
              onClick={() => {
                this.openAddAdminModal(row);
              }}>
              추가
            </Button>
          </div>
        ),
      },
      {
        title: '팀관리자(직급)',
        dataIndex: 'teamHeads',
        className: 'table-column-center',
        width: '120px',
        render: (data) => (
          <div>
            {data !== null && Array.isArray(data) && data.length > 0 &&
              data.length === 1 ?
              data.map(item => item.riderName + '(' + riderLevelText[item.riderLevel] + ')')
              :
              data.map(item => item.riderName + '(' + riderLevelText[item.riderLevel] + '), ')}
          </div>
        ),
      },
      {
        title: '팀원 추가',
        className: 'table-column-center',
        width: '80px',
        render: (row) => (
          <div>
            <Button
              className="tabBtn"
              onClick={() => {
                this.openAddRiderModal(row);
              }}>
              추가
            </Button>
          </div>
        ),
      },
      {
        title: '팀 삭제',
        className: 'table-column-center',
        width: '80px',
        render: (row) => (
          <div>
            <Button className="tabBtn" onClick={() => this.deleteTeam(row)}>
              삭제
            </Button>
          </div>
        ),
      },
    ];

    const expandedRowRender = (record) => {
      console.log(record);
      const dropColumns = [
        {
          dataIndex: 'riderName',
          className: 'table-column-center',
          render: (data, row) => (
            <>
              <Tag
                closable
                style={{ fontSize: 15, padding: 5 }}
                onClose={() => this.deleteRider(row, record)}>
                <div className="elipsis-table-row">{data}</div>
              </Tag>
            </>
          ),
        },
      ];
      return (
        <Table
          className="subTable"
          rowKey={(record) => `record: ${record.idx}`}
          columns={dropColumns}
          dataSource={record.riders}
          pagination={false}
        />
      );
    };

    const { close } = this.props;

    return (
      <React.Fragment>
        <React.Fragment>
          <div className="Dialog-overlay" onClick={close} />
          <div className="surchargeGroupList-Dialog">
            <div className="surchargeGroupList-content">
              <div className="surchargeGroupList-title">팀 관리</div>
              <img
                onClick={close}
                src={CloseIcon}
                className="taskGroup-close"
                alt="close"
              />
              <div className="surchargeGroupList-inner">
                <div className="surchargeGroupList-btn">
                  <div className="surchargeGroupList-btn-01">
                    {this.state.registTeamOpen && (
                      <RiderTeamRegistDialog close={this.closeRiderTeamModal} />
                    )}
                    <Button
                      className="tabBtn surchargeGroupList-btn"
                      onClick={() => {
                        this.openRiderTeamModal();
                      }}>
                      팀 등록
                    </Button>
                  </div>
                </div>
                <div style={{ marginTop: '5px', fontWeight: 'bold' }}>
                  ※(주의) 라이더는 하나의 팀에만 속할 수 있습니다. A팀에 속한 라이더를
                  B팀에 추가하면 A팀에서는 삭제됩니다.
                </div>
                <div className="listBlock">
                  <Table
                    rowKey={(record) => record}
                    dataSource={this.state.list}
                    columns={columns}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                    expandedRowRender={expandedRowRender}
                  />
                </div>
              </div>
              {/* 라이더 추가 모달 */}
              {this.state.addRiderOpen && (
                <SearchRiderTalkTeamDialog
                  close={this.closeAddRiderModal}
                  callback={(data) => this.addRiderToTeam(data)}
                  onlyRider={true}
                  stillWorking={true}
                  isSelectBox={false}
                />
              )}
              {/* 관리자 추가 모달 */}
              {this.state.addAdminOpen && (
                <SearchRiderDialog
                  close={this.closeAddAdminModal}
                  callback={(data) => this.addAdminToTeam(data)}
                  teamManagerOnly={true}
                  stillWorking={true}
                  isSelectBox={false}
                />
              )}
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
  };
};

export default connect(mapStateToProps)(RiderTeamDialog);
