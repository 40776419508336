import React, { useEffect, useRef, useState } from 'react';
import { NaverMap, Polygon } from 'react-naver-maps';
import { getDist } from '../../../lib/util/mapUtil';
import { Button } from "antd";
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from 'moment';
import Const from '../../../const';
const DeliveryPriceDongRateRegisterAreaGuideDialog = ({ close }) => {
  const handleClose = () => {
    close();
  };
  const setLocalStorage = () => {
    reactLocalStorage.setObject(Const.appName + '#registDongRateOpen', moment().format("YYYY-MM-DD HH:mm:ss"));
  }

  return (
    <div style={{ backgroundColor: 'rgba(0,0,0,0.56)' }} className="absolute flex-1 h-full w-full z-[12]">
      <div className="realtive">
        <div style={{ position: 'absolute' }} className="nn-modal-container">
          <div className="nn-modal-title-container h-auto pt-[20px]">
            <div></div>
            <div className="nn-modal-close" onClick={handleClose}></div>
          </div>
          <div className="w-[615px] h-[200px] p-[20px]">
            <div className="text-center text-[18px] mb-[20px] nn-noto-font-bold">
              구역추가를 시작합니다.
            </div>
            <div className="mb-[3px]">1. 지도 위에서  <span className="nn-noto-font-bold">'시작점'을 '마우스 왼쪽 버튼'</span>을 클릭 후, 구역을 순차적으로 클릭하여 주세요.</div>
            <div className="mb-[15px]">2. 구역을 지정하였다면 <span className="nn-noto-font-bold">'지도의 하단 구역 등록'</span>을 클릭하여 등록해주세요.</div>
            <div className="w-full flex">
              <Button
                className="w-full nn-modal-button h-[50px] bg-[#F5F5F5] mr-[20px]"
                onClick={()=>{
                  setLocalStorage()
                  close()
                }}
              >
                24시간 보지 않기
              </Button>
              <Button
                className="w-full nn-modal-button h-[50px] bg-[#fadc00]"
                onClick={close}
              >
                확인
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPriceDongRateRegisterAreaGuideDialog;
