/*global kakao*/
import { Form, Input, Modal, Table } from 'antd';
import React, { Component } from 'react';
import { httpPost, httpUrl } from '../../../api/httpClient';
import '../../../css/rider.css';
import { tableStatusString } from '../../../lib/util/codeUtil';
import SelectBox from '../../input/SelectBox';
import CloseIcon from '../../../img/login/close.png';

const FormItem = Form.Item;
const Search = Input.Search;

class CopyRegionException extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      franStatus: 0,
      frName: '',
      exceptionFranList: [],
    };
    this.formRef = React.createRef();
  }

  async componentDidMount() {
    this.getList(true);
    if (this.props.getExceptionFranList) {
      const res = await this.props.getExceptionFranList();

      this.setState({
        exceptionFranList: res.data,
      });
    }
  }

  // 가맹점 검색
  onSearchFranchisee = (value) => {
    this.setState(
      {
        frName: value,
        pagination: {
          total: 0,
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  handleTableChange = (pagination) => {
    this.setState(
      {
        pagination,
      },
      () => this.getList()
    );
  };

  getList = () => {
    httpPost(httpUrl.franchiseList, [], {
      frName: this.state.frName,
      pageNum: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
    }).then((result) => {
      this.setState({
        list: result.data.franchises,
        pagination: { ...this.state.pagination, total: result.data.totalCount },
      });
    });
  };

  onFrSelected = (data) => {
    console.log('data');
    console.log(data);
    let self = this;
    Modal.confirm({
      title: '할증지역 복사',
      content: data.frName + ' (으)로 복사하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.copyRegionException, [], {
          frIdx: self.props.data.idx,
          targetFrIdx: data.idx,
          branchIdx: data.branchIdx,
          frName: self.props.data.frName,
          targetFrName: data.frName,
        })
          .then((res) => {
            console.log(res);
            if (res.result === 'SUCCESS') {
              self.getList();
              self.props.close();
            } else {
              Modal.error({
                title: '변경 실패',
                content: <div>변경에 실패했습니다. 관리자에게 문의하세요.</div>,
                onOk() {},
              });
            }
          })
          .catch((e) => {
            Modal.error({
              title: '변경 실패',
              content: <div>변경에 실패했습니다.</div>,
              onOk() {},
            });
          });
      },
    });
  };

  render() {
    const columns = [
      {
        title: '순번',
        dataIndex: 'idx',
        className: 'table-column-center',
      },
      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center',
        render: (data, row) => (
          <div
            className="frNameTag"
            onClick={() => {
              this.onFrSelected(row);
            }}>
            {data}
          </div>
        ),
      },
    ];

    const { data, close } = this.props;

    return (
      <React.Fragment>
        <div className="flex-modal-overlay" onClick={close} />
        <div className="flex-wrapper">
          <div className="flex-header">
            <div className="flex-title">예외할증지역 복사</div>
            <img
              onClick={close}
              src={CloseIcon}
              className="dialog-close"
              alt=""
            />
          </div>
          <div className="copyRegionException-noti">
            <span className="mainText-blue desk">{data.frName}</span>
            <span style={{ marginTop: '7px', fontSize: '15px' }}>
              의 예외할증지역을 복사합니다.
              <br />
            </span>
            <div style={{ fontSize: '18px' }}>
              복사받을 가맹점을 선택해주세요.
            </div>
          </div>
          <div className="copyRegionException-container">
            <div
              className="searchFranchiseWrapper"
              style={{ marginTop: '20px' }}>
              <div className="searchFranchise-list">
                <div className="inputBox inputBox-searchFranchise sub autocomplete">
                  <SelectBox
                    value={tableStatusString[this.state.franStatus]}
                    code={Object.keys(tableStatusString)}
                    codeString={tableStatusString}
                    onChange={(value) => {
                      if (parseInt(value) !== this.state.franStatus) {
                        this.setState(
                          {
                            franStatus: parseInt(value),
                            pagination: {
                              current: 1,
                              pageSize: 10,
                            },
                          },
                          () => this.getList()
                        );
                      }
                    }}
                  />
                  <Search
                    id="autoInput"
                    placeholder="가맹점검색"
                    className="searchFranchiseInput"
                    enterButton
                    allowClear
                    onSearch={this.onSearchFranchisee}
                  />
                </div>
              </div>
            </div>
            <div className="dataTableLayout-01">
              <Table
                style={{ marginTop: '10px' }}
                dataSource={this.state.list}
                columns={columns}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CopyRegionException;
