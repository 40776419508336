import { Button, Input, Modal, Table } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../../api/httpClient';
import '../../../css/modal.css';
import { userGroupString } from '../../../lib/util/codeUtil';
import CloseIcon from '../../../img/login/close.png';

const Search = Input.Search;
const today = new Date();

class SearchFtStoreDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      multiList: this.props.multiList,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {}

  handleSelect = (record) => {
    Modal.confirm({
      title: `${record.deliveryStoreCode} 매장으로 연동을 진행하시겠습니까?`,
      onOk: () => {
        this.props.onSelect(record);
        this.props.close();
      },
    });
  };

  onSubmit = async () => {
    const res = await httpGet(
      httpUrl.getFtStore,
      [this.props.businessNumber, this.state.keyword],
      {}
    );
    if(res.result === "SUCCESS") {
      if (res.data) {
        this.setState({
          multiList: res.data,
        });
      } else {
        this.setState({
          multiList: []
        })
      }
    }
  };

  render() {
    const { close, multiList } = this.props;

    const columns = [
      {
        title: '브랜드명',
        dataIndex: 'brandName',
        className: 'table-column-center desk',
      },
      {
        title: '사업자번호',
        dataIndex: 'businessNo',
        className: 'table-column-center',
      },
      {
        title: '푸드테크코드',
        dataIndex: 'deliveryStoreCode',
        className: 'table-column-center desk tableSub',
      },
      {
        title: '대표자명',
        dataIndex: 'representName',
        className: 'table-column-center mobile',
      },
      {
        title: '주소',
        dataIndex: 'storeAddress',
        className: 'table-column-center desk',
        render: (data, row) => <div>{`${data || ""} ${row.storeAddressDetail || ""}`}</div>,
      },
      {
        title: '가게명',
        dataIndex: 'storeName',
        className: 'table-column-center desk',
      },

      {
        title: '가게번호',
        dataIndex: 'storeTelNo',
        className: 'table-column-center desk',
      },
    ];

    return (
      <React.Fragment>
        <div className="searchFtStore-Dialog-overlay" onClick={close} />
        <div className="searchFtStore-Dialog">
          <div className="searchFranchise-content">
            <div className="searchFranchise-title" style={{ marginBottom: 20 }}>
              연동가맹점 조회
            </div>
            <img
              onClick={close}
              src={CloseIcon}
              className="surcharge-close"
              alt="닫기"
            />

            <div className="layout">
              <div style={{marginBottom: 10}}>
                <Input
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') this.onSubmit();
                  }}
                  value={this.state.keyword}
                  onChange={(e) => this.setState({ keyword: e.target.value })}
                  placeholder="키워드검색"
                  allowClear
                  style={{
                    width: 180,
                  }}
                />
                <Button type="primary" onClick={() => this.onSubmit()}>
                  조회
                </Button>
              </div>

              <div className="dataTableLayout-01">
                <Table
                  rowKey={(record) => record.deliveryStoreCode}
                  dataSource={this.state.multiList}
                  columns={columns}
                  rowClassName="searchFtStore-row"
                  onRow={(record) => {
                    return {
                      onClick: (e) => {
                        this.handleSelect(record);
                      },
                    };
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SearchFtStoreDialog;
