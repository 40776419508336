import { Button, Form, Input, Modal, Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reactLocalStorage } from 'reactjs-localstorage';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import Const from '../../const';
import {redShowSetting,addrUpdateEnabled,distShowSetting} from "../../lib/util/codeUtil";
import {login, logout} from "../../actions/loginAction";
import {
  websockConnected,
  websockDisconnected, websockDuplicated
} from "../../actions/websocketAction";
import {addOrder, addOrderList, setOrder} from "../../actions/orderAction";
import {setRider} from "../../actions/riderAction";
import {setBranchInfo} from "../../actions/branchAction";

const FormItem = Form.Item;

class SettingMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkNewPasswordError: false,
      checkCurrentPasswordError: false,
      branchTel: null,
      updateBranchTel: null,
      startTime: '',
      endTime: '',
      updateStartTime: '',
      updateEndTime: '',
      riderCancelPenalty: '',
      basicDeliveryPrice: '',
      distShowSetting: '',
      addrUpdateEnabled: '',
      redOrderShowSetting: '',
      updateRiderCancelPenalty: '',
      updateBasicDeliveryPrice: '',
      updateDistShowSetting: '',
      myBranchOrderShareFlag: '',
      defaultLevelUpPointFlag: '',
      deliveryStatShow: '',
      companyId: '',
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    let branchIdx = this.getBranchInfo().branchIdx;
    const res = httpGet(httpUrl.getBranch, [branchIdx], {}).then((res) => {
      if (res.result === 'SUCCESS') {
        console.log('branchInfo: ', res.data);
        this.setState({
          branchTel: res.data.tel,
          startTime: res.data.startTime,
          endTime: res.data.endTime,
          riderCancelPenalty: res.data.riderCancelPenalty,
          basicDeliveryPrice: res.data.basicDeliveryPrice,
          distShowSetting: res.data.distShowSetting,
          addrUpdateEnabled: res.data.addrUpdateEnabled,
          redOrderShowSetting: res.data.redOrderShowSetting,
          myBranchOrderShareFlag: res.data.myBranchOrderShareFlag,
          defaultLevelUpPointFlag: res.data.defaultLevelUpPointFlag,
          deliveryStatShow: res.data.deliveryStatShow,
          companyId: res.data.companyId,
        });
        this.props.setBranchInfo(res.data);
      }
    });
    return res;
  };

  getBranchInfo = () => {
    const data = reactLocalStorage.getObject(Const.appName + '#adminUser');
    return typeof data === 'object' && data;
  };

  handleSubmit = () => {
    const form = this.formRef.current.getFieldsValue();
    if (form.newPassword !== form.newPasswordCheck) {
      this.setState({
        checkNewPasswordError: true,
      });
      return Modal.info({
        title: '비밀번호 확인 오류',
        content: '비밀번호를 확인 해주세요',
      });
    }
    Modal.confirm({
      title: '비밀번호 변경',
      content: '비밀번호를 변경하시겠습니까?',
      onOk: () => {
        httpPost(httpUrl.changePassword, [], {
          // userIdx: this.props.userIdx,
          currentPassword: form.currentPassword,
          newPassword: form.newPassword,
        })
          .then((res) => {
            if (res.data === 'SUCCESS') {
              Modal.info({
                title: '변경 성공',
                content: '비밀번호 변경에 성공했습니다.',
              });
              this.setState({ checkNewPasswordError: false });
              // this.formRef.clearFields();
            } else {
              Modal.info({
                title: '변경 실패',
                content: '비밀번호 변경에 실패했습니다.',
              });
            }
          })
          .catch((e) => {
            Modal.info({
              title: '변경 실패',
              content: '비밀번호 변경에 실패했습니다.',
            });
            throw e;
          });
      },
      onCancel: () => {},
    });
  };

  handleBranchTel = () => {
    const { companyId } = this.state;
    Modal.confirm({
      title:
        companyId !== 'connect9' ? '지사번호 추가/변경' : '지점번호 추가/변경',
      content:
        companyId !== 'connect9'
          ? '지사번호를 추가/변경하시겠습니까?'
          : '지점번호를 추가/변경하시겠습니까?',
      onOk: () => {
        httpPost(httpUrl.changeBranchTel, [], {
          branchIdx: this.getBranchInfo().branchIdx,
          branchTel: this.state.updateBranchTel,
        })
          .then((res) => {
            if (res.data === 'SUCCESS') {
              this.setState({
                updateBranchTel: null,
              });
              Modal.info({
                title: '추가/변경 성공',
                content:
                  companyId !== 'connect9'
                    ? '지사번호 추가/변경에 성공했습니다.'
                    : '지점번호 추가/변경에 성공했습니다.',
              });
              this.getList();
            } else {
              Modal.info({
                title: '추가/변경 실패',
                content:
                  companyId !== 'connect9'
                    ? '지사번호 추가/변경에 실패했습니다.'
                    : '지점번호 추가/변경에 실패했습니다.',
              });
            }
          })
          .catch((e) => {
            Modal.info({
              title: '추가/변경 실패',
              content:
                companyId !== 'connect9'
                  ? '지사번호 추가/변경에 실패했습니다.'
                  : '지점번호 추가/변경에 실패했습니다.',
            });
            throw e;
          });
      },
      onCancel: () => {},
    });
  };

  handleBranchTime = () => {
    const { companyId } = this.state;
    Modal.confirm({
      title:
        companyId !== 'connect9' ? '지사 운영시간 변경' : '지점 운영시간 변경',
      content:
        companyId !== 'connect9'
          ? '지사 운영시간을 변경하시겠습니까?'
          : '지점 운영시간을 변경하시겠습니까?',
      onOk: () => {
        const branchIdx = this.getBranchInfo().branchIdx;
        httpPost(httpUrl.changeBranchTime, [], {
          branchIdx,
          startTime: this.state.updateStartTime,
          endTime: this.state.updateEndTime,
        })
          .then((res) => {
            console.log(res);
            if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
              this.setState({
                updateStartTime: null,
                updateEndTime: null,
              });
              Modal.info({
                title: '운영시간 변경 성공',
                content:
                  companyId !== 'connect9'
                    ? '지사 운영시간 변경에 성공했습니다.'
                    : '지점 운영시간 변경에 성공했습니다.',
              });
              this.getList();
            } else if (res.data === 'WRONG_START_TIME') {
              Modal.warn({
                content: "개점 시간 형식이 '00:00' 형식인지 확인하세요!",
              });
            } else if (res.data === 'WRONG_END_TIME') {
              Modal.warn({
                content: "폐점 시간 형식이 '00:00' 형식인지 확인하세요!",
              });
            } else if (res.data === 'WRONG_LENGTH') {
              Modal.warn({
                content: "':' 위치를 다시 확인해주세요!",
              });
            } else if (res.data === 'NO_MARK') {
              Modal.warn({
                content: "':' 가 있는지 확인해주세요!",
              });
            } else if (res.data === 'IS_NOT_NUMBER') {
              Modal.warn({
                content: '숫자를 제대로 입력했는지 확인해주세요!',
              });
            }
          })
          .catch((e) => console.error('handleBranchTime error: ' + e));
      },
      onCancel: () => {},
    });
  };

  handleBranchSetting = () => {
    const distShowSetting = this.state.distShowSetting;
    const addrUpdateEnabled = this.state.addrUpdateEnabled;
    const redOrderShowSetting = this.state.redOrderShowSetting;
    const { companyId } = this.state;

    if(distShowSetting >= 2) {
      return Modal.warn({
        content: "변경 기사거리필터 노출여부 값은 0과1만 입력할 수 있습니다.(0 : OFF / 1 : ON)",
      });
    }

    Modal.confirm({
      title:
        companyId !== 'connect9'
          ? '지사 각종 금액 및 설정 추가/변경'
          : '지점 각종 금액 및 설정 추가/변경',
      content:
        companyId !== 'connect9'
          ? '지사 각종 금액 및 설정을 추가/변경하시겠습니까?'
          : '지점 각종 금액 및 설정을 추가/변경하시겠습니까?',
      onOk: () => {
        httpPost(httpUrl.changeBranchSetting, [], {
          branchIdx: this.getBranchInfo().branchIdx,
          riderCancelPenalty: this.state.updateRiderCancelPenalty,
          basicDeliveryPrice: this.state.updateBasicDeliveryPrice,
          distShowSetting: this.state.distShowSetting,
          addrUpdateEnabled: this.state.addrUpdateEnabled,
          redOrderShowSetting: this.state.redOrderShowSetting,
          myBranchOrderShareFlag: this.state.myBranchOrderShareFlag,
          defaultLevelUpPointFlag: this.state.defaultLevelUpPointFlag,
          deliveryStatShow: this.state.deliveryStatShow,
        })
          .then((res) => {
            console.log('data : ' + res);
            if (res.data === 'SUCCESS') {
              this.setState({
                updateRiderCancelPenalty: null,
                updateBasicDeliveryPrice: null,
                updateDistShowSetting: null,
                updateAddrUpdateEnabled: null,
                // updateRedOrderShowSetting: null,
                redOrderShowSetting: null,
              });
              Modal.info({
                title: '추가/변경 성공',
                content:
                  companyId !== 'connect9'
                    ? '지사 각종 금액 및 설정을 추가/변경에 성공했습니다.'
                    : '지점 각종 금액 및 설정을 추가/변경에 성공했습니다.',
              });
              this.getList();
            } else {
              Modal.info({
                title: '추가/변경 실패',
                content:
                  companyId !== 'connect9'
                    ? '지사 각종 금액 및 설정을 추가/변경에 실패했습니다.'
                    : '지점 각종 금액 및 설정을 추가/변경에 실패했습니다.',
              });
            }
          })
          .catch((e) => {
            Modal.info({
              title: '추가/변경 실패',
              content:
                companyId !== 'connect9'
                  ? '지사 각종 금액 및 설정을 추가/변경에 실패했습니다.'
                  : '지점 각종 금액 및 설정을 추가/변경에 실패했습니다.',
            });
            throw e;
          });
      },
      onCancel: () => {},
    });
  };

  handleChangeRedShowSetting = async (e) => {
    this.setState({
      redOrderShowSetting: e.target.value,
    });
  };

  handleChangeAddrUpdateEnabled = async (e) => {
    this.setState({
      addrUpdateEnabled: e.target.value,
    });
  };

  handleChangeDistShowSetting = async (e) => {
    this.setState({
      distShowSetting: e.target.value,
    });
  };
  handleChangeMyBranchOrderShareFlag = async (e) => {
    this.setState({
      myBranchOrderShareFlag: e.target.value,
    });
  };
  handelChangeDeliveryStatShowFlag = async (e) => {
    this.setState({
      deliveryStatShow: e.target.value,
    });
  };
  handleChangeDefaultLevelUpPointFlag = async (e) => {
    this.setState({
      defaultLevelUpPointFlag: e.target.value,
    })
  }

  render() {
    const { companyId } = this.state;
    return (
      <div className="pwChange-root">
        <div className="pwChange-Layout">
          <div className="pwChange-title">비밀번호 변경</div>
          <br></br>
          <Form ref={this.formRef} onFinish={this.handleSubmit}>
            <div className="pwChange-box">
              <div className="twl pwChange-list">
                <td>현재 비밀번호</td>
                <div className="inputBox inputBox-pwChange sub">
                  <FormItem
                    name="currentPassword"
                    rules={[
                      {
                        required: true,
                        message: '현재 비밀번호를 입력해주세요',
                      },
                    ]}>
                    <Input
                      type="password"
                      placeholder="현재 비밀번호를 입력해주세요"
                    />
                  </FormItem>
                  {this.state.checkCurrentPasswordError && (
                    <div className="error-message">
                      비밀번호를 확인해주세요!
                    </div>
                  )}
                </div>
              </div>
              <div className="twl pwChange-list">
                <td>새 비밀번호</td>
                <div className="inputBox inputBox-pwChange sub">
                  <FormItem
                    name="newPassword"
                    rules={[
                      {
                        validator: (rule, value, cb) => {
                          const currentPassword =
                            this.formRef.current.getFieldValue(
                              'currentPassword'
                            );

                          let strongRegex = new RegExp(
                            '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,15}$'
                          );
                          if (!value || value == '')
                            cb('패스워드를 입력해주세요');
                          else if (!strongRegex.test(value))
                            cb('영문, 숫자, 특수문자 조합 8~15자로 입력');
                          else if (currentPassword == value)
                            cb('기존 비밀번호와 동일합니다');
                          else cb();
                        },
                      },
                    ]}>
                    <Input
                      type="password"
                      placeholder="새로운 비밀번호를 입력해주세요"
                    />
                  </FormItem>
                </div>
              </div>
              <div className="twl pwChange-list">
                <td>새 비밀번호 확인</td>
                <div className="inputBox inputBox-pwChange sub">
                  <FormItem
                    name="newPasswordCheck"
                    rules={[
                      {
                        validator: (rule, value, cb) => {
                          const newPassword =
                            this.formRef.current.getFieldValue('newPassword');

                          if (!value || value == '')
                            cb('패스워드를 입력해주세요');
                          else if (newPassword != value)
                            cb('비밀번호와 비밀번호 확인이 다릅니다.');
                          else cb();
                        },
                      },
                    ]}>
                    <Input
                      type="password"
                      placeholder="새로운 비밀번호를 다시 입력해주세요"
                    />
                  </FormItem>
                  {this.state.checkNewPasswordError && (
                    <div className="error-message">
                      비밀번호를 확인해주세요!
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="pwChange-btn">
              <Button htmlType="submit" className="tabBtn" onClick={() => {}}>
                변경하기
              </Button>
            </div>
          </Form>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
          }}>
          <div className="pwChange-Layout">
            {companyId !== 'connect9' ? (
              <div className="pwChange-title">
                {this.getBranchInfo().branchName + ' '}지사 관제센터 번호변경
              </div>
            ) : (
              <div className="pwChange-title">
                {this.getBranchInfo().branchName + ' '}지점 관제센터 번호변경
              </div>
            )}
            <br></br>
            <div className="pwChange-box">
              <div className="twl pwChange-list">
                {companyId !== 'connect9' ? (
                  <td>현재 지사번호</td>
                ) : (
                  <td>현재 지점번호</td>
                )}
                <div className="inputBox inputBox-pwChange sub">
                  <TextArea
                    type="password"
                    placeholder={this.state.branchTel}
                    style={{ width: '250px' }}
                    disabled
                  />
                </div>
              </div>
              <div className="twl pwChange-list">
                <td>변경할 번호</td>
                <div className="inputBox inputBox-pwChange sub">
                  <TextArea
                    type="password"
                    placeholder={this.state.branchTel}
                    value={this.state.updateBranchTel}
                    style={{ width: '250px' }}
                    onChange={(e) =>
                      this.setState({ updateBranchTel: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="pwChange-btn">
              <Button
                htmlType="submit"
                className="tabBtn"
                onClick={this.handleBranchTel}>
                변경하기
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
          }}>
          <div className="pwChange-Layout">
            {companyId !== 'connect9' ? (
              <div className="pwChange-title">
                {this.getBranchInfo().branchName + ' '}지사 운영시간 변경
              </div>
            ) : (
              <div className="pwChange-title">
                {this.getBranchInfo().branchName + ' '}지점 운영시간 변경
              </div>
            )}
            <br></br>
            <div className="pwChange-box">
              <div className="twl pwChange-list">
                <td>현재 운영시간</td>
                <span className="inputBox inputBox-pwChange sub">
                  <Input placeholder={this.state.startTime} disabled />
                </span>
                <span style={{ margin: '10px' }}>~</span>
                <span className="inputBox inputBox-pwChange sub">
                  <Input placeholder={this.state.endTime} disabled />
                </span>
              </div>
              <div className="twl pwChange-list">
                <td>변경할 시간</td>
                <span className="inputBox inputBox-pwChange sub">
                  <Input
                    placeholder={this.state.startTime}
                    value={this.state.updateStartTime}
                    onChange={(e) =>
                      this.setState({ updateStartTime: e.target.value })
                    }
                  />
                </span>
                <span style={{ margin: '10px' }}>~</span>
                <span className="inputBox inputBox-pwChange sub">
                  <Input
                    placeholder={this.state.endTime}
                    value={this.state.updateEndTime}
                    onChange={(e) =>
                      this.setState({ updateEndTime: e.target.value })
                    }
                  />
                </span>
              </div>
            </div>

            <div className="pwChange-btn">
              <Button
                htmlType="submit"
                className="tabBtn"
                onClick={this.handleBranchTime}>
                변경하기
              </Button>
            </div>
          </div>
        </div>

        {/*지점 기본요금 등 수정기능 추가*/}
        <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}>
          <div className="pwChange-Layout">
            {companyId !== 'connect9' ? (
              <div className="pwChange-title">
                {this.getBranchInfo().branchName + ' '}지사 각종 금액 및 설정
                변경
              </div>
            ) : (
              <div className="pwChange-title">
                {this.getBranchInfo().branchName + ' '}지점 각종 금액 및 설정
                변경
              </div>
            )}
            <br></br>
            <div className="pwChange-box">
              <div className="twl pwChange-list">
                <span style={{ margin: "10px" }}>현재 라이더취소페널티 금액</span>
                <span className="inputBox inputBox-pwChange sub">
                  <Input placeholder={this.state.riderCancelPenalty} disabled />
                </span>
                <span style={{ margin: "10px" }}>변경 라이더취소페널티 금액</span>
                <span className="inputBox inputBox-pwChange sub">
                  <Input
                    placeholder={this.state.riderCancelPenalty}
                    value={this.state.updateRiderCancelPenalty}
                    onChange={(e) =>
                      this.setState({ updateRiderCancelPenalty: e.target.value })
                    }
                  />
                </span>
              </div>
              <div className="twl pwChange-list">
                <span style={{ margin: "10px" }}>현재 기본배달료</span>
                <span className="inputBox inputBox-pwChange sub">
                  <Input placeholder={this.state.basicDeliveryPrice} disabled />
                </span>
                <span style={{ margin: "10px" }}>변경 기본배달료</span>
                <span className="inputBox inputBox-pwChange sub">
                  <Input
                    placeholder={this.state.basicDeliveryPrice}
                    value={this.state.updateBasicDeliveryPrice}
                    onChange={(e) =>
                      this.setState({ updateBasicDeliveryPrice: e.target.value })
                    }
                  />
                </span>
              </div>
              <div style={{ padding: "5px" }}>
                <span style={{ marginLeft: "62px" }}>기사거리필터 노출여부</span>
                <span style={{ marginLeft: "10px" }}>
                  <Radio.Group
                    value={this.state.distShowSetting}
                    onChange={this.handleChangeDistShowSetting}>
                  {distShowSetting.map((o) => {
                    return (
                      <Radio value={o.value}>{o.label}</Radio>
                    );
                  })}
                </Radio.Group>
                </span>
              </div>
              <div style={{ padding: "5px" }}>
                <span style={{ marginLeft: "-28px" }}>배차 후 상점에서 도착지 주소 변경여부</span>
                <span style={{ marginLeft: "10px" }}>
                  <Radio.Group
                    value={this.state.addrUpdateEnabled}
                    onChange={this.handleChangeAddrUpdateEnabled}>
                  {addrUpdateEnabled.map((o) => {
                    return (
                      <Radio value={o.value}>{o.label}</Radio>
                    );
                  })}
                </Radio.Group>
                </span>
              </div>
              <div style={{ padding: "5px" }}>
                <span style={{ marginLeft: "87px" }}>빨간오더 노출여부</span>
                <span style={{ marginLeft: "10px" }}>
                  <Radio.Group
                    value={this.state.redOrderShowSetting}
                    onChange={this.handleChangeRedShowSetting}>
                  {redShowSetting.map((o) => {
                    return (
                      <Radio value={o.value}>{o.label}</Radio>
                    );
                  })}
                </Radio.Group>
                </span>
              </div>
              <div style={{ padding: "5px" }}>
                <span>소속지점 주문 공유 ON/OFF 가능</span>
                <span style={{ marginLeft: "10px" }}>
                  <Radio.Group
                    value={this.state.myBranchOrderShareFlag}
                    onChange={this.handleChangeMyBranchOrderShareFlag}>
                    <Radio value={true}>{"가능"}</Radio>
                    <Radio value={false}>{"불가능"}</Radio>
                </Radio.Group>
                </span>
              </div>
              <div
                className="ml-[45px]"
                style={{ padding: "5px" }}>
                <span>상점에 배달현황 노출여부</span>
                <span style={{ marginLeft: "10px" }}>
                  <Radio.Group
                    value={this.state.deliveryStatShow}
                    onChange={this.handelChangeDeliveryStatShowFlag}>
                    <Radio value={true}>{"노출"}</Radio>
                    <Radio value={false}>{"비노출"}</Radio>
                </Radio.Group>
                </span>
              </div>
              {this.state.companyId == "connect9" &&
                (<div style={{ padding: "5px" }}>
                  <span style={{ marginLeft: "26px" }}>라이더 레벨업 활성화 기본 값</span>
                  <span style={{ marginLeft: "10px" }}>
                  <Radio.Group
                    value={this.state.defaultLevelUpPointFlag}
                    onChange={this.handleChangeDefaultLevelUpPointFlag}>
                    <Radio value={true}>{"활성"}</Radio>
                    <Radio value={false}>{"비활성"}</Radio>
                </Radio.Group>
                </span>
                </div>)
              }
            </div>


            <div className="pwChange-btn">
              <Button
                htmlType="submit"
                className="tabBtn"
                onClick={this.handleBranchSetting}>
                변경하기
              </Button>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  password: state.login.loginInfo.password,
  userIdx: state.login.loginInfo.idx
});

const mapDispatchToProps = (dispatch) => {
  return {
    setBranchInfo: (branchInfo) => dispatch(setBranchInfo(branchInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingMain);
