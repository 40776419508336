import { Button, Checkbox, Form, Input, Modal, Table } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  httpPost,
  httpPostWithNoLoading,
  httpUrl,
} from '../../../api/httpClient';
import { customAlert, customError } from '../../../api/Modals';
import SelectBox from '../../../components/input/SelectBox';
import '../../../css/modal.css';
import '../../../css/modal_m.css';
import {
  apiResponseResult,
  chattingCategoryType,
  chattingSubCategoryType,
  HttpResponseStatusCode,
} from '../../../lib/util/codeUtil';
import SnsSurchargeFrGroupDialog from '../order/SnsSurchargeFrGroupDialog';
import CloseIcon from '../../../img/login/close.png';

const { TextArea } = Input;
const FormItem = Form.Item;

class SendSnsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      messageGroupPagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      dataIdxs: [],
      selectedRowKeys: [],

      riderLevel: 0,
      searchName: '',
      isMulti: false,
      category: 1,
      subCategory: 1,

      background: '#fff',
      registGroupOpen: false,
      selectedMessageGroup: [],
      modifyData: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getMessageGroupList();
  }

  // 전체메세지 전송
  handleSubmit = () => {
    let self = this;
    if (this.state.category === 3 && this.state.subCategory === 2) {
      Modal.confirm({
        title: '그룹메세지 전송',
        content: <div>메세지를 전송하시겠습니까?</div>,
        okText: '확인',
        cancelText: '취소',
        onOk() {
          console.log({
            message: self.formRef.current.getFieldValue('message'),
            branchIdx: self.props.branchIdx,
            groups: self.state.selectedMessageGroup,
          });
          if (self.state.selectedMessageGroup.length === 0) {
            Modal.warn({
              title: '전송오류',
              content: '전송대상 그룹을 선택해주세요.',
            });
            return;
          }
          httpPostWithNoLoading(httpUrl.sendGroupMessage, [], {
            message: self.formRef.current.getFieldValue('message'),
            branchIdx: self.props.branchIdx,
            groups: self.state.selectedMessageGroup,
            sessionId:
              undefined !== global.ws && null !== global.ws
                ? global.ws.getSessionId()
                : null,
          })
            .then((res) => {
              if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
                Modal.info({
                  title: '완료',
                  content: <div>메세지가 전송 완료되었습니다.</div>,
                });
                self.props.close();
              } else {
                Modal.info({
                  title: ' 오류',
                  content: '오류가 발생하였습니다. 다시 시도해 주십시오.',
                });
              }
            })
            .catch((e) => {
              if (e.response.status === HttpResponseStatusCode.BAD_REQUEST)
                Modal.info({
                  title: '입력값 오류',
                  content: e.response.data.message,
                });
              else
                Modal.info({
                  title: ' 오류',
                  content: '오류가 발생하였습니다. 다시 시도해 주십시오.',
                });
            });
        },
      });
      return;
    }
    Modal.confirm({
      title: '전체메세지 전송',
      content: <div>메세지를 전송하시겠습니까?</div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        self.props.close();
        httpPostWithNoLoading(httpUrl.riderMessageAll, [], {
          ...self.formRef.current.getFieldsValue(),
          branchIdx: self.props.branchIdx,
          category: self.state.category,
          sessionId:
            undefined !== global.ws && null !== global.ws
              ? global.ws.getSessionId()
              : null,
        })
          .then((res) => {
            if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
              Modal.info({
                title: '완료',
                content: <div>메세지가 전송 완료되었습니다.</div>,
              });
            } else {
              Modal.info({
                title: ' 오류',
                content: '오류가 발생하였습니다. 다시 시도해 주십시오.',
              });
            }
          })
          .catch((e) => {
            if (e.response.status === HttpResponseStatusCode.BAD_REQUEST)
              Modal.info({
                title: '입력값 오류',
                content: e.response.data.message,
              });
            else
              Modal.info({
                title: ' 오류',
                content: '오류가 발생하였습니다. 다시 시도해 주십시오.',
              });
          });
      },
    });
  };

  handleClear = () => {
    this.formRef.current.resetFields();
  };

  onChangeStatus = (value) => {
    this.setState({
      category: value,
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.messageGroupPagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        messageGroupPagination: pager,
      },
      () => this.getMessageGroupList()
    );
  };

  getMessageGroupList = () => {
    httpPost(httpUrl.getMessageGroupList, [], {
      pageNum: this.state.messageGroupPagination.current,
      pageSize: this.state.messageGroupPagination.pageSize,
    }).then((res) => {
      console.log(res);
      if (res.result === apiResponseResult.SUCCESS) {
        this.setState({
          messageGroupList: res.data.groups,
          messageGroupPagination: {
            ...this.state.messageGroupPagination,
            total: res.data.totalCount,
          },
        });
      }
    });
  };

  // 그룹 등록 dialog
  openRegistGroupModal = (row) => {
    if (row) {
      this.setState(
        {
          modifyData: row,
        },
        () => this.setState({ registGroupOpen: true })
      );
    } else {
      this.setState(
        {
          modifyData: null,
        },
        () => this.setState({ registGroupOpen: true })
      );
    }
  };
  closeRegistGroupModal = () => {
    this.setState({ registGroupOpen: false });
    // this.getList();
  };

  // 그룹삭제
  deleteGroup = (row) => {
    let self = this;
    Modal.confirm({
      title: '그룹 삭제',
      content: row.name + ' 그룹을 삭제하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        let idx = row.idx;
        httpPost(httpUrl.deleteMessageGroup, [], row)
          .then((res) => {
            if (res.result === 'SUCCESS') {
              customAlert('그룹 삭제', row.name + ' 그룹을 삭제하였습니다.');
              self.getMessageGroupList();
            } else {
              customError(
                '삭제 에러',
                '에러가 발생하여 그룹을 삭제할수 없습니다.'
              );
            }
          })
          .catch((e) => {
            customError(
              '삭제 에러',
              '에러가 발생하여 그룹을 삭제할수 없습니다.'
            );
          });
      },
    });
  };

  render() {
    const { close } = this.props;

    const columns = [
      {
        title: '선택',
        dataIndex: 'idx',
        className: 'table-column-center',
        width: '15%',
        render: (idx, row) => {
          return (
            <Checkbox
              checked={this.state.selectedMessageGroup.includes(row)}
              onChange={(e) => {
                if (e.target.checked) {
                  this.setState({
                    selectedMessageGroup:
                      this.state.selectedMessageGroup.concat(row),
                  });
                } else {
                  this.setState({
                    selectedMessageGroup:
                      this.state.selectedMessageGroup.filter(
                        (item) => item.idx !== idx
                      ),
                  });
                }
              }}
            />
          );
        },
      },
      {
        title: '그룹명',
        dataIndex: 'name',
        className: 'table-column-center',
        width: '70%',
      },
      {
        title: '보기',
        className: 'table-column-center',
        width: '20%',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn"
              onClick={() => this.openRegistGroupModal(row)}>
              보기
            </Button>
          </div>
        ),
      },

      {
        title: '삭제',
        className: 'table-column-center',
        width: '20%',
        render: (data, row) => (
          <div>
            <Button className="tabBtn" onClick={() => this.deleteGroup(row)}>
              삭제
            </Button>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="snsDialog">
          <div className="container">
            <div className="sns-title">전체메세지 전송</div>
            <img
              onClick={close}
              src={CloseIcon}
              alt=""
              className="surcharge-close"
            />

            <Form ref={this.formRef} onFinish={this.handleSubmit}>
              <div className="snsLayout">
                <div>
                  <div className="contentBlock">
                    <div className="mainTitle">전송대상</div>
                    <FormItem name="category" className="selectItem">
                      <SelectBox
                        placeholder={'전체'}
                        value={chattingCategoryType[this.state.category]}
                        code={Object.keys(chattingCategoryType)}
                        codeString={chattingCategoryType}
                        onChange={(value) => {
                          if (parseInt(value) !== this.state.category) {
                            this.setState({ category: parseInt(value) });
                          }
                        }}
                      />
                    </FormItem>
                    {this.state.category === 3 && (
                      <>
                        <FormItem
                          style={{ marginLeft: 10 }}
                          name="subCategory"
                          className="selectItem">
                          <SelectBox
                            placeholder={'전체'}
                            value={
                              chattingSubCategoryType[this.state.subCategory]
                            }
                            code={Object.keys(chattingSubCategoryType)}
                            codeString={chattingSubCategoryType}
                            onChange={(value) => {
                              if (parseInt(value) !== this.state.subCategory) {
                                this.setState({ subCategory: parseInt(value) });
                              }
                            }}
                          />
                        </FormItem>
                      </>
                    )}
                  </div>
                  <div className="sns-inputBox">
                    <FormItem
                      name="message"
                      rules={[
                        { required: true, message: '메세지를 입력해주세요' },
                      ]}>
                      <TextArea
                        id="snsInputBox"
                        placeholder="메세지를 입력해주세요"
                      />
                    </FormItem>
                  </div>
                  <div className="snsbtn-box">
                    <Button type="primary" htmlType="submit" className="snsBtn">
                      전송
                    </Button>
                  </div>
                </div>
                {this.state.category === 3 && this.state.subCategory === 2 && (
                  <div className="groupLayout">
                    {this.state.registGroupOpen && (
                      <SnsSurchargeFrGroupDialog
                        close={this.closeRegistGroupModal}
                        refresh={this.getMessageGroupList}
                        data={this.state.modifyData}
                      />
                    )}
                    <Button
                      className="snsBtn"
                      onClick={() => {
                        this.openRegistGroupModal();
                      }}>
                      그룹추가
                    </Button>
                    <div className="frgroup-table">
                      <Table
                        columns={columns}
                        dataSource={this.state.messageGroupList}
                        rowKey={(record) => 'messageGroup:' + record.idx}
                        pagination={this.state.messageGroupPagination}
                        onChange={this.handleTableChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  branchIdx: state.login.loginInfo.branchIdx,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SendSnsDialog);
