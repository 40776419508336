import React, { Component } from 'react';
import {Form, Input, Button, Modal} from 'antd';
import '../../../css/modal.css';
import '../../../css/modal_m.css';
import { httpUrl, httpPost } from '../../../api/httpClient';
import CloseIcon from '../../../img/login/close.png';

const FormItem = Form.Item;

class SMSVerificationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      smsSent: false,
      isVerificationSuccess: false,
      phone: '',
      code: '',
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
  }

  SendMessage = () => {
    const { phone } = this.formRef.current.getFieldsValue();
    if(!phone) {
      Modal.warn({
        title: '실패',
        content: '전화번호를 입력해주세요.',
      });
      return;
    }
    const phoneStr = phone.replace(/[^0-9]/g, '')
    httpPost(httpUrl.sendNaverSms, [], {
      to: phoneStr
    })
    .then(result=> {
      if(result.data === "SUCCESS"){
        Modal.info({
          title: '문자 전송 완료',
          content: '문자가 전송되었습니다.',
        });
        this.setState({smsSent: true,});
      } else {
        Modal.warn({
          title: '실패',
          content: '문자전송에 실패하였습니다. 전화번호를 확인해 주세요.',
        });
      }
    })
    .catch(e=> Modal.warn({
      title: '실패',
      content: '문자전송에 실패하였습니다. 전화번호를 확인해 주세요.',
    }))
  }

  checkCode = () => {
    let { code } = this.formRef.current.getFieldsValue();

    if(!code) {
      Modal.warn({
        title: '실패',
        content: '인증 번호를 입력해주세요.',
      });
      return
    }
    httpPost(httpUrl.checkCode, [], {
      code,
    })
    .then(result=> {
      if(result.data){
        Modal.info({
              title: '완료',
              content: '인증이 완료되었습니다.',
        });
        this.props.success();
        this.props.close();
      } else{
        Modal.warn({
          title: '실패',
          content: '인증에 실패하였습니다. 인증번호를 확인해 주세요.',
        });
      }
    })
    .catch(e=> Modal.warn({
      title: '실패',
      content: '인증에 실패하였습니다. 인증번호를 확인해 주세요.',
    }))
  };


  render() {
    const { close } = this.props;

    return (
        <React.Fragment>
          <div className="Dialog-overlay" onClick={close} />
          <div className="systemKey-Dialog">
            <div className="systemKey-content">
              <div className="systemKey-title">
                본인 인증
              </div>
              <img
                  onClick={close}
                  src={CloseIcon}
                  className="dialog-close"
                  alt="img"
              />

              <div className="systemKey-inner">
                <Form
                    ref={this.formRef}
                    onFinish={this.handleSubmit}
                    className="create-control-form">

                  <div className="create-control-form-item-title">휴대폰 인증</div>
                  <FormItem
                      name="phone"
                      className="create-control-form-item"
                      rules={[
                        { required: true, message: '전화번호를 입력해주세요' },
                      ]}>
                    <Input
                        className="create-control-input"
                        placeholder="전화번호"
                    />
                  </FormItem>
                  <Button
                      type="primary"
                      onClick={this.SendMessage}
                      style={{ width: '100%', backgroundColor: 'darkgray', borderColor: 'black' }}>
                    {this.state.smsSent ? '인증번호 다시 받기' : '인증번호 받기'}
                  </Button>
                  <div style={{height: '40px'}}></div>
                  <div className="create-control-form-item-title">인증번호</div>
                  <FormItem
                      name="code"
                      className="create-control-form-item">
                    <Input
                        className="create-control-input"
                        placeholder="인증번호"
                    />
                  </FormItem>

                  <Button
                      type="primary"
                      onClick={this.checkCode}
                      className="create-control-submit-btn"
                      style={{ width: '100%', backgroundColor: '#fddc00', color: 'black', borderColor: 'gray' }}>
                    인증완료
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </React.Fragment>
    );
  }
}

export default SMSVerificationDialog;
