import { Button, Modal } from 'antd';
import React, { Component } from 'react';
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { login, logout } from '../actions/loginAction';
import { addOrder, deleteOrder, updateOrder } from '../actions/orderAction';
import { updateRiderLocation } from '../actions/riderAction';
import {
  websockConnected,
  websockDisconnected,
  websockDuplicated,
} from '../actions/websocketAction';
import Const from '../const';
import { formatDate, formatJSONDateToString } from '../lib/util/dateUtil';
import { speak } from '../tts/tts';
import ChattingDialog from './dialog/common/ChattingDialog';
import NoticeDetailDialog from './notice/NoticeDetailDialog';
import {getOrderShareViewInfo,setOrderShareViewInfo} from '../lib/util/orderShareUtil';

const wsReadyState = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};
const getWSReadyStateStr = (state) => {
  return state === 0
    ? 'CONNECTING'
    : state === 1
    ? 'OPEN'
    : state === 2
    ? 'CLOSING'
    : state === 3
    ? 'CLOSED'
    : '';
};

const sendWebSocketPing = () => {
  setInterval(() => {
    console.log('WS PING');
    try {
      const ws = global.ws;
      if (undefined === ws || null === ws) {
        return;
      }

      if ('function' === typeof ws.send) {
        ws.send('P');
      }
    } catch (e) {}
  }, Const.websocketPingIntervalMS);
};
sendWebSocketPing();

class NNWebSocket extends Component {
  constructor(props) {
    super(props);
    this.url = `${Const.wsProtocol}://${Const.wsHost}:${Const.wsPort}${Const.wsPath}`;
    this.state = {
      appState: '',
      visible: false,
      message: '',
      MessageOpen: false,
      openRoom: true,

      audioSource: '',

      noticeOpen: false,
      notice: null,
    };
  }

  componentDidMount() {
    this.initWS();
    this.wsTimeInterval = setInterval(() => {
      this.checkWS();
    }, 20000);
  }

  componentWillUnmount() {
    clearInterval(this.wsTimeInterval);
  }

  // 메세지 dialog
  openMessageModal = () => {
    this.setState({ MessageOpen: true });
  };
  closeMessageModal = () => {
    this.setState({ MessageOpen: false });
  };

  openNoticeModal = (data) => {
    this.setState({ noticeOpen: true, notice: data})
  }
  closeNoticeModal = () => {
    this.setState({ noticeOpen: false})
  }

  initWS = () => {
    console.log('=================initWS==================');
    if (global.ws != null) {
      console.log('ws is not null');
      global.ws.close();
      global.ws = null;
    }
    global.ws = new WebSocket(this.url);
    global.ws.onopen = () => {
      // connection opened
      console.log('ws open!!');
      global.ws.send('something'); // send a message
      this.props.websockConnected();
    };
    global.ws.onmessage = async (e) => {
      // a message was received
      console.log(`ws onmessage, data: ${e.data}, type: ${typeof e.data}`);

      /* websocket SESSION_ID 저장 */
      const dataVal = e.data;
      if (
        typeof dataVal === 'string' &&
        4 < dataVal.length &&
        dataVal.substring(0, 4) === 'SID:'
      ) {
        const arr = dataVal.split('SID:');
        if (2 === arr.length) {
          global.ws.sessionId = arr[1];
        }
        return;
      }

      if (e.data === 'logged in from another location') {
        // this.props.websockDuplicated();
        // this.props.websockDisconnected();
        // this.props.alert.show(
        //   "다른곳에서 접속하여 채팅서버 연결이 종료됩니다."
        // );
        return;
      }
      let message = {};
      try {
        // if (typeof e.data !== 'object') {
        //   return;
        // }
        console.log('parse message');
        message = JSON.parse(e.data);
        console.log(message);
      } catch (error) {
        console.log(
          `ws onmessage, raised exception. e: ${error}, data: ${e.data}`
        );
        return;
      }
      let data = {};
      if (message.data instanceof Object) data = message.data;
      else data = JSON.parse(message.data);
      console.log(data);

      console.log('branch filter flag');
      console.log(
        message.messageType !== 'CHAT' &&
          data.branchIdx != this.props.loginReducer.loginInfo.branchIdx &&
          data.riderBranchIdx != this.props.loginReducer.loginInfo.branchIdx
      );

      // 다른지점 콜은 공유걸려있어도 처리안함 (채팅제외)
      // 보기처리 된 콜은 처리함

      const res = await getOrderShareViewInfo(this.props.loginReducer.loginInfo.branchIdx);
      if (
        message.messageType !== 'CHAT' &&
        // data.branchIdx != this.props.loginReducer.loginInfo.branchIdx &&
        // data.riderBranchIdx != this.props.loginReducer.loginInfo.branchIdx
        !res.view.includes(data.branchIdx) &&
        !res.view.includes(data.riderBranchIdx)
      ) {
        if(message.messageType !== 'NOTICE' || data.branchIdx !== 0) {
          return;
        }
      }
      console.log('mybranch websocket!!!');

      if (
        message.messageType === 'NOTICE' &&
        (data.category === 4 || data.category === 5) &&
        (data.branchIdx === this.props.loginReducer.loginInfo.branchIdx || data.branchIdx == 0)
      ) {
        this.openNoticeModal(data)
      } else if (message.messageType === 'CHAT') {
        console.log('## chat message ##');
        console.log(message);

        if (!isNaN(message.sendUserIdx) && 0 < message.sendUserIdx) {
          data.sendUserIdx = message.sendUserIdx;
        }

        let oppName = '';
        let oppIdx = '';

        this.audioPlay('/tts/message_alarm.wav');

        if (this.props.loginReducer.loginInfo.idx == message.data.member1) {
          oppName = message.data.member2Name;
          oppIdx = message.data.member2;
        } else {
          oppName = message.data.member1Name;
          oppIdx = message.data.member1;
        }
        if (!global.chatAprear && !global.chatDetailAprear) {
          //채팅중이 아닐때만 띄우기
          if (data.sendUserIdx !== this.props.loginReducer.loginInfo.idx) {
            this.setState({
              visible: true,
              message: '[' + oppName + '] ' + message.data.lastMessage,
              messageIdx: oppIdx,
            });
          }
        } else {
          //채팅창 활성화 시 해당 컴포넌트로 전송
          if (global.chatListener) global.chatListener(message.data);
          if (global.chatDetailListener)
            global.chatDetailListener(message.data);
        }
      } else if (message.messageType === 'ORDER_NEW') {
        data.waitingStatus = 0;

        let payments = [...data.orderPayments];
        for (let i = 0; i < payments.length; i++) {
          const payment = payments[i];
          payment.updateDate = formatDate(
            formatJSONDateToString(payment.updateDate)
          );
        }

        data.orderPayments = payments;
        if (global.voices.includes('1')) speak('접수');
        if (
          this.props.orderReducer.orders.findIndex(
            (order) => order.idx === data.idx
          ) !== -1
        ) {
          // 배차 취소시 orderStatus가 바뀌기전 2인 상태로 옴
          // + 소켓으로 오는 데이터 Date 형식이 다른 api에서 쓰는 형식과 다른 형식으로 옴
          if (data.orderDate && data.orderDate.date)
            data.orderDate = formatJSONDateToString(data.orderDate);
          if (data.assignDate) data.assignDate = null;
          data.orderStatus = 1;
          this.props.updateOrder(data);
        } else {
          // if (global.voices.includes('1')) speak('접수');
          if (data.orderDate.date)
            data.orderDate = formatJSONDateToString(data.orderDate);
          this.props.addOrder('NEW', data);
        }
        if (data.deliveryPrice === 0) {
          speak('배달료 0원 주문이 접수되었습니다.');
        }
      } else if (message.messageType === 'ORDER_ASSIGN') {
        let payments = [...data.orderPayments];
        for (let i = 0; i < payments.length; i++) {
          const payment = payments[i];
          payment.updateDate = formatDate(
            formatJSONDateToString(payment.updateDate)
          );
        }

        if (global.voices && global.voices.includes('2')) speak('배차');
        if (data.orderDate && data.orderDate.date)
          data.orderDate = formatJSONDateToString(data.orderDate);
        if (data.assignDate && data.assignDate.date)
          data.assignDate = formatJSONDateToString(data.assignDate);
        data.orderStatus = 2;

        console.log('update flag');
        console.log(
          this.props.orderReducer.orders.findIndex(
            (order) => order.idx === data.idx
          ) > -1
        );
        console.log(
          this.props.orderReducer.orders.findIndex(
            (order) => order.idx === data.idx
          )
        );
        if (
          this.props.orderReducer.orders.findIndex(
            (order) => order.idx === data.idx
          ) > -1
        )
          this.props.updateOrder(data);
        else this.props.addOrder('NEW', data);
      } else if (message.messageType === 'ORDER_PICIUP') {
        let payments = [...data.orderPayments];
        for (let i = 0; i < payments.length; i++) {
          const payment = payments[i];
          payment.updateDate = formatDate(
            formatJSONDateToString(payment.updateDate)
          );
        }

        if (global.voices.includes('3')) speak('픽업');
        if (data.orderDate && data.orderDate.date)
          data.orderDate = formatJSONDateToString(data.orderDate);
        if (data.assignDate && data.assignDate.date)
          data.assignDate = formatJSONDateToString(data.assignDate);
        if (data.pickupDate && data.pickupDate.date)
          data.pickupDate = formatJSONDateToString(data.pickupDate);
        this.props.updateOrder(data);
      } else if (message.messageType === 'ORDER_PICIUP_CANCEL') {
        // if (global.voices.includes("3")) speak("픽업");

        let payments = [...data.orderPayments];
        for (let i = 0; i < payments.length; i++) {
          const payment = payments[i];
          payment.updateDate = formatDate(
            formatJSONDateToString(payment.updateDate)
          );
        }

        if (data.orderDate && data.orderDate.date)
          data.orderDate = formatJSONDateToString(data.orderDate);
        if (data.assignDate && data.assignDate.date)
          data.assignDate = formatJSONDateToString(data.assignDate);

        this.props.updateOrder(data);
      } else if (message.messageType === 'ORDER_COMPLETE') {
        let payments = [...data.orderPayments];
        for (let i = 0; i < payments.length; i++) {
          const payment = payments[i];
          payment.updateDate = formatDate(
              formatJSONDateToString(payment.updateDate)
          );
        }
        data.orderPayments = payments;
        if (data.orderDate && data.orderDate.date)
          data.orderDate = formatJSONDateToString(data.orderDate);
        if (data.assignDate && data.assignDate.date)
          data.assignDate = formatJSONDateToString(data.assignDate);
        if (data.pickupDate && data.pickupDate.date)
          data.pickupDate = formatJSONDateToString(data.pickupDate);
        if (data.completeDate && data.completeDate.date)
          data.completeDate = formatJSONDateToString(data.completeDate);
        // this.props.deleteOrder("NEW", data);
        // this.props.addOrder("COMPLETE", data);
        this.props.updateOrder(data);
      } else if (message.messageType === 'ORDER_CANCEL') {
        let payments = [...data.orderPayments];
        for (let i = 0; i < payments.length; i++) {
          const payment = payments[i];
          payment.updateDate = formatDate(
              formatJSONDateToString(payment.updateDate)
          );
        }
        data.orderPayments = payments;
        if (global.voices.includes('4')) speak('취소');
        if (data.orderDate && data.orderDate.date)
          data.orderDate = formatJSONDateToString(data.orderDate);
        if (data.assignDate && data.assignDate.date)
          data.assignDate = formatJSONDateToString(data.assignDate);
        if (data.pickupDate && data.pickupDate.date)
          data.pickupDate = formatJSONDateToString(data.pickupDate);
        if (data.cancelDate && data.cancelDate.date)
          data.cancelDate = formatJSONDateToString(data.cancelDate);
        if (data.completeDate && data.completeDate.date)
          data.completeDate = formatJSONDateToString(data.completeDate);
        this.props.updateOrder(data);
      } else if (message.messageType === 'ORDER_CANCEL_RESTORE') {
        data.waitingStatus = 0;

        let payments = [...data.orderPayments];
        for (let i = 0; i < payments.length; i++) {
          const payment = payments[i];
          payment.updateDate = formatDate(
              formatJSONDateToString(payment.updateDate)
          );
        }
        data.orderPayments = payments;
        if (global.voices.includes('1')) speak('접수');
        if (data.orderDate && data.orderDate.date)
          data.orderDate = formatJSONDateToString(data.orderDate);
        if (data.assignDate) data.assignDate = null;
        if (data.pickupDate) data.pickupDate = null;
        data.orderStatus = 1;
        this.props.updateOrder(data);
      } else if (message.messageType === 'ORDER_UPDATE') {
        let payments = [...data.orderPayments];
        for (let i = 0; i < payments.length; i++) {
          const payment = payments[i];
          payment.updateDate = formatDate(
            formatJSONDateToString(payment.updateDate)
          );
        }

        if (data.orderDate && data.orderDate.date)
          data.orderDate = formatJSONDateToString(data.orderDate);
        if (data.assignDate && data.assignDate.date)
          data.assignDate = formatJSONDateToString(data.assignDate);
        if (data.pickupDate && data.pickupDate.date)
          data.pickupDate = formatJSONDateToString(data.pickupDate);
        // if (data.orderPayments[0].updateDate)
        //   data.orderPayments[0].updateDate = formatDate(
        //     formatJSONDateToString(data.orderPayments[0].updateDate)
        //   );
        this.props.updateOrder(data);
      } else if (message.messageType === 'ORDER_RESTORE') {
        let payments = [...data.orderPayments];
        for (let i = 0; i < payments.length; i++) {
          const payment = payments[i];
          payment.updateDate = formatDate(
            formatJSONDateToString(payment.updateDate)
          );
        }

        if (data.orderDate && data.orderDate.date)
          data.orderDate = formatJSONDateToString(data.orderDate);
        if (data.assignDate && data.assignDate.date)
          data.assignDate = formatJSONDateToString(data.assignDate);
        if (data.pickupDate && data.pickupDate.date)
          data.pickupDate = formatJSONDateToString(data.pickupDate);
        data.completeDate = null;
        data.orderStatus = 3;
        this.props.addOrder('NEW', data);
      } else if (message.messageType === 'RIDER_LOCATION_UPDATE') {
        console.log('RIDER_LOCATION_UPDATE');
        this.props.updateRiderLocation(data);
      }
    };
    global.ws.onerror = (e) => {
      // an error occurred
      console.log(`ws onerror. message: ${e.message}`);
    };
    global.ws.onclose = (e) => {
      // connection closed
      console.log(`ws onclose. code: ${e.code}, reason: ${e.reason}`);
      this.props.websockDisconnected();
    };
    global.ws.getSessionId = () => {
      return global.ws.sessionId;
    };
  };
  checkWS = () => {
    // console.log('checkWS');
    if (!this.props.loginReducer.isLogin) {
      console.log('checkWs isloggedin');
      return;
    }
    if (global.ws === null || typeof global.ws === 'undefined') {
      console.log(`wsTimeInterval. ws invalid type. type: ${typeof global.ws}`);
      return;
    }
    if (this.props.websockInfo.isDuplicated) {
      console.log('connected from another PC');
    }
    if (global.ws.readyState !== wsReadyState.OPEN) {
      console.log(
        `wsTimeInterval. url: ${this.url}, readyState: ${getWSReadyStateStr(
          global.ws.readyState
        )}`
      );
      // this.getOrderInfo();
    }
    if (global.ws.readyState === wsReadyState.CLOSED) {
      console.log(`wsTimeInterval, ws reconnect. url: ${this.url}`);
      global.ws = null;
      this.initWS();
    }
  };

  audioPlay = (src) => {
    console.log('audio play');
    console.log(src.split('/')[src.split('/').length - 1]);

    // let audio = new Audio(src.split("/")[src.split("/").length - 1]);
    let audio = new Audio(src);

    audio.volume = 0.3;
    audio.crossOrigin = 'anonymous';
    // audio.muted = true;

    console.log(audio);

    audio
      .play()
      .then((res) => {})
      .catch((e) => console.log(e + '###############################'));
  };

  render() {
    return (
      <>
        {this.state.MessageOpen && (
          <ChattingDialog
            close={this.closeMessageModal}
            openRoom={true}
            messageIdx={this.state.messageIdx}
          />
        )}
        <Modal
          visible={this.state.visible}
          title="채팅알람"
          onOk={() => {
            this.setState({ visible: false });
          }}
          footer={[
            <Button
              key="answer"
              onClick={() => {
                this.openMessageModal();
                this.setState({ visible: false });
              }}>
              답장
            </Button>,
            <Button
              key="ok"
              onClick={() => {
                this.setState({ visible: false });
              }}>
              확인
            </Button>,
          ]}
          destroyOnClose={true}
          closable={false}>
          <div>{this.state.message}</div>
        </Modal>
        { this.state.noticeOpen && (
          <div className="Dialog-overlay" onClick={this.closeNoticeModal}>
            <NoticeDetailDialog
              close={this.closeNoticeModal}
              notice={this.state.notice}
              fromWebSocket = {true}
            />
          </div>
        )}
      </>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    loginReducer: state.login,
    websockInfo: state.websock,
    orderReducer: state.orderReducer,
  };
};

let mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login,
      logout,
      websockConnected,
      websockDisconnected,
      websockDuplicated,
      addOrder,
      updateOrder,
      deleteOrder,
      updateRiderLocation,
    },
    dispatch
  );
export default withAlert()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(NNWebSocket))
);
