import { Button, DatePicker, Input, Modal, Select, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import xlsx from 'xlsx';
import { httpGet, httpUrl } from '../../api/httpClient';
import { adminSendKindString, categoryStatus, kindString } from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';

const dateFormat = 'YYYY-MM-DD';

const userTypeCode = ['전체', '라이더', '가맹점', '냠냠박스'];

const riderCategory = {
  기사수수료: {
    category: ['DELIVERY_PRICE_RECEIVE', 'DELIVERY_PRICE_RECEIVE_RESTORE'],
    category2: ['RIDER_FEE', 'RIDER_FEE_RESTORE'],
    kind: '',
  },
  원천징수: {
    category: ['DELIVERY_PRICE_RECEIVE', 'DELIVERY_PRICE_RECEIVE_RESTORE'],
    category2: ['TAX', 'TAX_RESTORE'],
    kind: '',
  },
  '대출상환(대양)': {
    category: ['BATCH_WORK_NCASH'],
    category2: [],
    kind: 16,
  },
  // 상해보험쪽 기능이 아직 완성 안됨
  // '상해보험': ['', ''],

  // 1. 일차감 kind 1
  // 2. category : BATCH_WORK_NCASH_OF_RIDER_BIKE_RENT_FEE
  // 이렇게 2개가 있는데 어느쪽 쓰는건지 확인 필요
  // BATCH_WORK_NCASH_OF_RIDER_BIKE_RENT_FEE 이건 실서버에 테스트 로그밖에 없음

  바이크렌트비: {
    category: ['BATCH_WORK_NCASH'],
    category2: [],
    kind: 10,
  },
  바이크수리비: {
    category: ['BATCH_WORK_NCASH'],
    category2: [],
    kind: 11,
  },
  바이크과태료: {
    category: ['BATCH_WORK_NCASH'],
    category2: [],
    kind: 17,
  },
};

const franchiseCategory = {
  가맹비: {
    category: ['DUES_PAY', 'DUES_PAY_RESTORE'],
    category2: [],
    kind: '',
  },
};

const nnboxCategory = {
  고용보험: {
    category: ['DELIVERY_PRICE_RECEIVE', 'DELIVERY_PRICE_RECEIVE_RESTORE'],
    category2: ['EMPLOY_INSURANCE_AGENCY', 'EMPLOY_INSURANCE_AGENCY_RESTORE'],
    kind: '',
  },
  산재보험: {
    category: ['DELIVERY_PRICE_RECEIVE', 'DELIVERY_PRICE_RECEIVE_RESTORE'],
    category2: ['ACCIDENT_INSURANCE_AGENCY', 'ACCIDENT_INSURANCE_AGENCY_RESTORE'],
    kind: '',
  },
  '대출상환(대양)': {
    category: ['BATCH_WORK_NCASH'],
    category2: [],
    kind: 16,
  },
  대여금회수: {
    category: ['BATCH_WORK_NCASH'],
    category2: [],
    kind: 6,
  },
  // 상해보험쪽 기능이 아직 완성 안됨
  // '상해보험': ['', ''],

  // 1. 일차감 kind 1
  // 2. category : BATCH_WORK_NCASH_OF_RIDER_BIKE_RENT_FEE
  // 이렇게 2개가 있는데 어느쪽 쓰는건지 확인 필요
  // BATCH_WORK_NCASH_OF_RIDER_BIKE_RENT_FEE 이건 실서버에 테스트 로그밖에 없음

  바이크렌트비: {
    category: ['BATCH_WORK_NCASH'],
    category2: [],
    kind: 10,
  },
  바이크수리비: {
    category: ['BATCH_WORK_NCASH'],
    category2: [],
    kind: 11,
  },
  바이크과태료: {
    category: ['BATCH_WORK_NCASH'],
    category2: [],
    kind: 17,
  },
};

const today = new moment();

const NcashCompanyList = ({ ncashListTrigger }) => {
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 20,
  });
  const [selectKey, setSelectKey] = useState('전체');
  let [category, setCategory] = useState([]);
  const [category2, setCategory2] = useState([]);
  const [kind, setKind] = useState('');

  const [depositDialogOpen, setDepositDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [userType, setUserType] = useState(0);
  const [dwFilter, setDwFilter] = useState(0);

  const [subBranchList, setSubBranchList] = useState([]);
  const [selectedSubBranch, setSelectedSubBranch] = useState(0);

  const [searchString, setSearchString] = useState('');

  useEffect(async () => {
    const res = await httpGet(httpUrl.subBranchList, [], {});
    setSubBranchList(res.data);
  }, []);

  const getList = async (withPagination) => {
    const endDatePlus1Day = endDate.clone().add('1', 'day');

    const res = await httpGet(
      httpUrl.ncashCompanyList,
      [
        pagination.current,
        pagination.pageSize,
        category,
        category2,
        kind,
        startDate.format(dateFormat),
        endDatePlus1Day.format(dateFormat),
        withPagination,
        dwFilter,
        searchString,
        selectedSubBranch,
      ],
      {}
    );
    console.log(res);

    if (res.data.result === 'SUCCESS') {
      setList(res.data.logs);
      setPagination({
        ...pagination,
        total: res.data.totalCount,
      });
      return res.data.logs;
    } else {
      Modal.warn({
        title: '조회실패',
        content: res.data.resultMessage,
      });
      return null;
    }
  };

  const onDownload = async () => {
    if (!startDate || !endDate) {
      Modal.warn({
        title: '조회기간 미지정',
        content: '조회기간을 지정해주세요',
      });
      return null;
    }

    const data = await getList(false);
    if (!data || data.length === 0) {
      Modal.info({
        title: '조회실패',
        content: '조회 일자에 해당하는 정보가 없습니다.',
      });
      return;
    }
    console.log('엑셀 다운로드 조회 정보' + data);

    const maxItemCount = 50000;
    const splitCount = parseInt(data.length / maxItemCount) + 1;
    for (let sp = 1; sp <= splitCount; sp++) {
      const excelJson = parseExcelJson(data.splice(0, maxItemCount));

      const ws = xlsx.utils.json_to_sheet(excelJson, {
        skipHeader: true,
      });

      const wb = xlsx.utils.book_new();

      ws['!cols'] = [];
      ws['!cols'][1] = { width: 15 };
      ws['!cols'][3] = { width: 23 };
      ws['!cols'][4] = { width: 20 };

      xlsx.utils.book_append_sheet(wb, ws, `예치금내역_${sp}`);
      await xlsx.writeFile(wb, `예치금내역_${sp}.xlsx`);
    }
  };

  const parseExcelJson = (data) => {
    let result = [];
    let headerJson = {};
    columns.forEach((column) => {
      headerJson[column.dataIndex] = column.title;
    });
    result.push(headerJson);

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      let copiedItem = { ...item };
      const keys = Object.keys(item);
      for (let j = 0; j < Object.keys(item).length; j++) {
        const key = keys[j];
        if (columns.findIndex((column) => column.dataIndex === key) < 0) {
          delete copiedItem[key];
        } else if (columns[columns.findIndex((column) => column.dataIndex === key)].render) {
          const targetCol = columns[columns.findIndex((column) => column.dataIndex === key)];
          copiedItem[key] = targetCol.render(copiedItem[key], copiedItem);
          if (copiedItem[key] && copiedItem[key].type && copiedItem[key].type === 'div') {
            copiedItem[key] = copiedItem[key].props.children;
          }
        }
      }

      result.push({
        ...copiedItem,
      });
    }

    return result;
  };

  useEffect(async () => {
    await getList(true);
  }, [
    pagination.current,
    pagination.pageSize,
    category,
    category2,
    kind,
    startDate,
    endDate,
    ncashListTrigger,
    dwFilter,
  ]);

  const columns = [
    {
      title: '지점',
      dataIndex: 'branchName',
      className: subBranchList && subBranchList.length > 0 ? 'table-column-center' : 'table-column-hide',
    },
    {
      title: '카테고리',
      dataIndex: 'category',
      className: 'table-column-center',
      render: (data, row) =>
        categoryStatus[data] + (data == 'NCASH_SEND_BY_ADMIN' ? '-' + adminSendKindString[row.kind] : ''),
    },
    {
      title: '상세내용',
      dataIndex: 'memo',
      className: 'table-column-left-only',
      render: (data, row) =>
        row.category === 'BATCH_WORK_NCASH'
          ? row.batchTitle
            ? kindString[row.kind] + '[' + row.batchTitle + ']'
            : '-'
          : data,
    },
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '금액',
      dataIndex: 'ncashDelta',
      className: 'table-column-right-only',
      render: (data, row) => <div style={{ color: data >= 0 ? 'blue' : 'red' }}>{comma(data) + '원'}</div>,
    },
    {
      title: '잔액',
      dataIndex: 'afterNcash',
      className: 'table-column-right-only',
      render: (data, row) => comma(data) + '원',
    },
    {
      title: '대상',
      dataIndex: 'relationTarget',
      className: 'table-column-left-only',
    },
    {
      title: '비고',
      dataIndex: 'idx',
      className: 'table-column-left-only',
      render: (data, row) => {
        if (row.category === 'DELIVERY_PRICE_RECEIVE') {
          let deliveryPrice = parseInt(row.deliveryPrice);
          let deliveryPriceVat = parseInt(row.deliveryPrice * 0.1);
          let deliveryPriceFee = row.deliveryPriceFee ? parseInt(row.deliveryPriceFee) : 0;
          return (
            '주문번호:' +
            row.orderIdx +
            ',배달비:' +
            deliveryPrice +
            ',부가세:' +
            deliveryPriceVat +
            ',수수료:' +
            deliveryPriceFee +
            ',건별가맹비:' +
            row.duesDeliveryPrice
          );
        }
        if (row.category === 'DUES_PAY') {
          return row.relationBusinessNumber;
        }
      },
    },
    {
      title: '취소/복원여부',
      dataIndex: 'maxLogIdx',
      className: 'table-column-center',
      render: (data, item) =>
        (item.category === 'DELIVERY_PRICE_RECEIVE' ||
          item.category === 'DELIVERY_PRICE_SEND' ||
          item.category === 'AGENCY_FEE') &&
        item.maxLogIdx
          ? '복원'
          : item.orderStatus == 5
          ? '취소'
          : '',
    },
  ];

  return (
    <>
      <div style={{ padding: 20, display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {subBranchList && subBranchList.length > 0 && (
            <Select
              style={{ width: 200, marginRight: 20 }}
              value={selectedSubBranch}
              onChange={(value) => setSelectedSubBranch(value)}>
              <Select.Option value={0}>전체</Select.Option>
              {subBranchList.map((row) => (
                <Select.Option value={row.branchCode}>{row.branchName}</Select.Option>
              ))}
            </Select>
          )}
          <Select
            style={{ width: 200, marginRight: 20 }}
            value={userType}
            onChange={(value) => {
              setUserType(value);
              let key = null;
              let category = null;
              if (value === 0) {
                setSelectKey('');
                setCategory([]);
                setCategory2([]);
                setKind('');
              }
              if (value === 1) {
                key = Object.keys(riderCategory)[0];
                category = riderCategory[key];
                setSelectKey(key);
                setCategory(category.category);
                setCategory2(category.category2);
                setKind(category.kind);
              }
              if (value === 2) {
                key = Object.keys(franchiseCategory)[0];
                category = franchiseCategory[key];
                setSelectKey(key);
                setCategory(category.category);
                setCategory2(category.category2);
                setKind(category.kind);
              }
              if (value === 3) {
                key = Object.keys(nnboxCategory)[0];
                category = nnboxCategory[key];
                setSelectKey(key);
                setCategory(category.category);
                setCategory2(category.category2);
                setKind(category.kind);
              }
            }}>
            {userTypeCode.map((userType, index) => (
              <Select.Option value={index}>{userType}</Select.Option>
            ))}
          </Select>

          {userType === 0 && <Select style={{ width: 200 }} disabled={true}></Select>}
          {userType === 1 && (
            <Select
              style={{ width: 200 }}
              value={selectKey}
              onChange={(value) => {
                setSelectKey(value);
                setCategory(riderCategory[value].category);
                setCategory2(riderCategory[value].category2);
                setKind(riderCategory[value].kind);
              }}>
              {Object.keys(riderCategory).map((key) => {
                return (
                  <Select.Option key={key} value={key}>
                    {key}
                  </Select.Option>
                );
              })}
            </Select>
          )}
          {userType === 2 && (
            <Select
              style={{ width: 200 }}
              value={selectKey}
              onChange={(value) => {
                setSelectKey(value);
                setCategory(franchiseCategory[value].category);
                setCategory2(franchiseCategory[value].category2);
                setKind(franchiseCategory[value].kind);
              }}>
              {Object.keys(franchiseCategory).map((key) => {
                return (
                  <Select.Option key={key} value={key}>
                    {key}
                  </Select.Option>
                );
              })}
            </Select>
          )}

          {userType === 3 && (
            <Select
              style={{ width: 200 }}
              value={selectKey}
              onChange={(value) => {
                setSelectKey(value);
                setCategory(nnboxCategory[value].category);
                setCategory2(nnboxCategory[value].category2);
                setKind(nnboxCategory[value].kind);
              }}>
              {Object.keys(nnboxCategory).map((key) => {
                return (
                  <Select.Option key={key} value={key}>
                    {key}
                  </Select.Option>
                );
              })}
            </Select>
          )}

          <Select style={{ width: 200, marginLeft: 20 }} value={dwFilter} onChange={(value) => setDwFilter(value)}>
            <Select.Option value={0}>입출금전체</Select.Option>
            <Select.Option value={1}>입금</Select.Option>
            <Select.Option value={2}>출금</Select.Option>
          </Select>

          <Input.Search
            style={{ width: 400, marginLeft: 20 }}
            value={searchString}
            onChange={(e) => setSearchString(e?.target?.value)}
            onSearch={() => getList(false)}
            placeholder={'검색할 아이디/라이더명/가맹점명을 입력하세요'}
            enterButton
            allowClear
          />
        </div>
        <div>
          <DatePicker.RangePicker
            value={[startDate, endDate]}
            placeholder={['시작일', '종료일']}
            onChange={(value) => {
              const [startDate, endDate] = value;
              setStartDate(startDate);
              setEndDate(endDate);
            }}
          />
          <Button
            style={{ backgroundColor: 'green', border: 'none', color: 'white' }}
            onClick={async () => await onDownload()}>
            엑셀 다운로드
          </Button>
        </div>
      </div>
      <Table
        rowKey={(record) => record.idx}
        dataSource={list}
        columns={columns}
        pagination={pagination}
        onChange={(pagination) => setPagination(pagination)}
      />
    </>
  );
};

export default NcashCompanyList;
