import { Table, Button, Input, Modal } from 'antd';
import React, { Component } from 'react';
import { httpUrl, httpPost } from '../../api/httpClient';
import '../../css/staff.css';
import '../../css/common.css';
import SelectBox from '../../components/input/SelectBox';
import {
  statusString,
  statusCode,
} from '../../lib/util/codeUtil';
import {connect} from "react-redux";
import RegistAccountDialog
  from "../../components/dialog/account/RegistAccountDialog";
import {formatDate} from "../../lib/util/dateUtil";
const Search = Input.Search;

class AccountMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
      },
      registAccount: false,
      searchId: '',
      searchName: '',
      userStatus: 0,
      dialogData: undefined,
    };
  }

  componentDidMount() {
    this.getList();
  }


  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onSearchId = (searchId) => {
    this.setState(
        {
          searchId: searchId,
          pagination: {
            current: 1,
            pageSize: 20,
          },
        },
        () => {
          this.getList();
        }
    );
  };

  onSearchName = (searchName) => {
    this.setState(
        {
          searchName: searchName,
          pagination: {
            current: 1,
            pageSize: 20,
          },
        },
        () => {
          this.getList();
        }
    );
  };

  onCheckType = (value) => {
    this.setState({ userStatus: value }, () => this.getList());
  };

  getList = () => {
    let pageSize = this.state.pagination.pageSize;
    let pageNum = this.state.pagination.current;
    let branchIdx = this.props.branchIdx;
    let id = this.state.searchId;
    let name = this.state.searchName;
    let userStatus = this.state.userStatus;

    httpPost(
      httpUrl.controllerList,
      [],
      {pageSize, pageNum, id, name, userStatus, branchIdx}
    ).then((result) => {
      console.log('## nnbox result=' + JSON.stringify(result, null, 4));
      const pagination = { ...this.state.pagination };
      pagination.current = result.data.currentPage;
      pagination.total = result.data.totalCount;
      this.setState({
        results: result.data.controlUsers,
        pagination,
      });
    });
  };

  closeRegistAccountModal = () => {
    this.setState({ registAccount: false, dialogData: undefined }, () => this.getList());
  };

  render() {
    const columns = [
      {
        title: '생성일시',
        dataIndex: 'createDate',
        className: 'table-column-center',
        render: (data) => <div>{formatDate(data)}</div>,
      },
      {
        title: '상태',
        dataIndex: 'userStatus',
        className: 'table-column-center',
        render: (data, row) => <div>{statusString[data]}</div>,
      },
      {
        title: '아이디',
        dataIndex: 'id',
        className: 'table-column-sub',
      },
      {
        title: '성명',
        dataIndex: 'riderName',
        className: 'table-column-sub',
      },
      {
        title: '전화번호',
        dataIndex: 'phone',
        className: 'table-column-center',
      },
      {
        title: '비고',
        dataIndex: 'memo',
        className: 'table-column-center',
      },
      {
        title: '정보 수정',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            {this.state.updateAccount && (
              <RegistAccountDialog
                data={this.state.dialogData}
                close={this.closeRegistAccountModal}
              />
            )}
            <Button
              className="tabBtn surchargeTab"
              onClick={() => {
                this.setState({ registAccount: true, dialogData: row });
              }}
            >
              수정
            </Button>
          </div>
        ),
      },
    ];
    return (
      <div className="">
        <div className="selectLayout">
          <br></br>
          <SelectBox
              placeholder={'전체'}
              defaultValue={statusCode[0]}
              code={Object.keys(statusCode)}
              codeString={statusCode}
              value={statusCode[this.state.userStatus]}
              onChange={this.onCheckType}
          />

          <Search
              placeholder="아이디 검색"
              enterButton
              allowClear
              onSearch={this.onSearchId}
              style={{
                width: 220,
                marginLeft: 10,
                marginBottom: 20,
              }}
          />

          <Search
              placeholder="이름 검색"
              enterButton
              allowClear
              onSearch={this.onSearchName}
              style={{
                width: 220,
                marginLeft: 10,
                marginBottom: 20,
              }}
          />

          {this.state.registAccount && (
            <RegistAccountDialog data={this.state.dialogData} close={this.closeRegistAccountModal} />
          )}
          <Button
            className="registStaff"
            onClick={() => {
              this.setState({ registAccount: true });
            }}>
            직원 등록
          </Button>
        </div>

        <div className="dataTableLayout">
          <Table
            dataSource={this.state.results}
            columns={columns}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
            onRow={(record) => {
              return {
                onDoubleClick: () => {
                  this.setState({ registAccount: true, dialogData: record });
                },
              };
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  branchIdx: state.login.loginInfo.branchIdx,
});

export default connect(mapStateToProps)(AccountMain);

