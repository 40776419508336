import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { httpGet, httpUrl } from '../../../api/httpClient';
import '../../../css/modal.css';
import { formatDate } from '../../../lib/util/dateUtil';
import { comma } from '../../../lib/util/numberUtil';
import CloseIcon from '../../../img/login/close.png';
import { useSelector } from 'react-redux';

const OrderLogDialog = ({ data, close }) => {
  const [logList, setLogList] = useState([]);
  const user = useSelector((state) => state.login.loginInfo);
  const getLogList = () => {
    console.log(data);
    httpGet(httpUrl.getOrderLog, [data.idx, 1, 10000])
      .then((res) => {
        if (res.result === 'SUCCESS') {
          console.log(res.data.nyamnyamLogs.filter(x=>!(x.category === "AGENCY_FEE" && x.userId !== user.companyId && x.ncashTransactionTargetId !== user.companyId)));
          setLogList(res.data.nyamnyamLogs.filter(x=>!(x.category === "AGENCY_FEE" && x.userId !== user.companyId && x.ncashTransactionTargetId !== user.companyId)));
        }
      })
      .catch((e) => {
        throw e;
      });
  };
  const columns = [
    {
      title: '내용',
      dataIndex: 'memo',
      className: 'table-column-center desk',
    },
    {
      title: '금액',
      dataIndex: 'ncashDelta',
      className: 'table-column-center desk',
      render: (data) => <div>{data ? comma(data) : '-'}</div>,
    },
    {
      title: '시간',
      dataIndex: 'createDate',
      className: 'table-column-center desk',
      render: (data) => <div>{formatDate(data)}</div>,
    },
    {
      title: '작성자',
      dataIndex: 'userId',
      className: 'table-column-center desk',
    },
    {
      title: '수정상세내용',
      dataIndex: 'updateContent',
      className: 'table-column-center desk',
      render: (data) => <div>{data}</div>,
    },
  ];
  useEffect(() => {
    getLogList();
  }, []);
  return (
    <React.Fragment>
      <div className="Dialog-overlay" onClick={() => close()} />

      <div className="zone-dialog">
        <div className="zone-content" style={{ width: '1400px' }}>
          <div className="timeDelay-title">이력보기</div>
          <img
            onClick={() => close()}
            src={CloseIcon}
            className="timeDelay-close"
            alt="closeDialog"
          />

          <Table
            rowKey={(record) => record.idx}
            dataSource={logList}
            columns={columns}
            style={{
              marginTop: 20,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: '#f0f0f0',
              maxHeight: '500px',
              overflowY: 'scroll',
            }}
            pagination={false}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderLogDialog;
