import { Button, Checkbox, Form, Image, Input, Modal, Radio } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import axios from 'axios';
import React, { Component } from 'react';
import {httpDelete, httpPost, httpUrl, imageUrl, makeUrl} from '../../../api/httpClient';
import '../../../css/modal.css';
import { contarctType, phoneAgency, vanFileUploadErrorCode } from '../../../lib/util/codeUtil';
import SelectBox from '../../input/SelectBox';
import CloseIcon from '../../../img/login/close.png';

class RegistVANDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contarctType: 1,
      VANchecked: false,
      file: null,
      selectedFile: null,

      // 신규 사업자 등록 data
      type0Data: {
        businessRegistration: '',
        businessCertification: '',
        representativePhoneAgency: 0,
        representativeRegistrationNumber: '',
        accountCopy: '',
        outsidePhoto: '',
        insidePhoto: '',
        corporateRegistration: '',
        corporateCertificate: '',
        shareholderRegister: '',
        useSeal: '',
        phone: '',
        phoneAgency: 0,
        type: true,
      },
      // 기존 사업자 등록 data
      type1Data: {
        businessRegistration: this.props.data.businessRegistration,
        representativeRegistrationNumber: '',
        accountCopy: '',
        birthday: this.props.data.vcrBirthday,
      },
    };
    this.formRef = React.createRef();

    this.businessRegistrationRef = React.createRef();
  }

  componentDidMount() {
    console.log(this.props.data);
  }

  handleSubmit = () => {
    if(!this.props.data.vcrIdx) {
      if(!this.state.selectedFile) {
        Modal.warn({
          title: "파일 선택 에러",
          content: "선택된 파일이 없습니다."
        })
        return;
      }
      var formData = this.state.selectedFile;
      formData.append('type', this.state.contarctType);
      if (this.state.type1Data. birthday) formData.append('birthday', this.state.type1Data. birthday)
      formData.append('userIdx', this.props.data.idx);

      axios.post(makeUrl(httpUrl.createVANWithUpload, []), formData, {
        withCredentials: true,
      })
        .then((res) => {
          console.log(res);
          if (res.status === 200 && res.data.result === "SUCCESS" && res.data.data === "SUCCESS") {
            Modal.info({
              title: '등록요청 성공',
              content: 'VAN 등록요청에 성공했습니다.',
            });
            this.props.close();
          } else {
            Modal.warn({
              title: '등록요청 실패',
              content: 'VAN 등록요청에 실패했습니다.',
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (
            Object.keys(vanFileUploadErrorCode).includes(e.response.data.message)
          )  {
            Modal.warn({
              title: '업로드 실패',
              content: vanFileUploadErrorCode[e.response.data.message],
            });
          } else {
            Modal.warn({
              title: '등록요청 실패',
              content: 'VAN 등록요청에 실패했습니다.',
            });
          }
        });
    } else {
      let data = 
      this.state.contarctType === 0
        ? { ...this.state.type0Data }
        : { ...this.state.type1Data };

    data['type'] = this.state.contarctType;
    data['userIdx'] = this.props.data.idx;
    data['idx'] =this.props.data.vcrIdx

      httpPost(httpUrl.updateVAN, [], data)
      .then((res) => {
        console.log(res);
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          Modal.info({
            title: '등록요청 수정 성공',
            content: 'VAN 등록요청 수정에 성공했습니다.',
          });
          this.props.close();
        } else {
          Modal.warn({
            title: '등록요청 수정 실패',
            content: 'VAN 등록요청 수정에 실패했습니다.',
          });
        }
      })
      .catch((e) => {
        console.log(e);
        if (
          Object.keys(vanFileUploadErrorCode).includes(e.response.data.message)
        )  {
          Modal.warn({
            title: '업로드 실패',
            content: vanFileUploadErrorCode[e.response.data.message],
          });
        } else {
          Modal.warn({
            title: '등록요청 수정 실패',
            content: 'VAN 등록요청 수정에 실패했습니다.',
          });
        }
      })
    }
  };

  onCheckType = (e) => {
    if (e.target.value === 0) {
      this.setState({
        type1Data: {
          businessRegistration: '',
          representativeRegistrationNumber: '',
          accountCopy: '',
        },
      });
    } else {
      this.setState({
        type0Data: {
          businessRegistration: '',
          businessCertification: '',
          representativePhoneAgency: 0,
          representativeRegistrationNumber: '',
          accountCopy: '',
          outsidePhoto: '',
          insidePhoto: '',
          corporateRegistration: '',
          corporateCertificate: '',
          shareholderRegister: '',
          useSeal: '',
          phone: '',
          phoneAgency: 0,
          type: true,
        },
      });
    }
    this.formRef.current.resetFields();
    this.setState({
      contarctType: e.target.value,
    });
  };

  handleChange = (key, value) => {
    if (this.state.contarctType === 0) {
      let state = { ...this.state.type0Data };
      state[key] = value;
      this.setState({ type0Data: state }, () =>
        console.log(this.state.type0Data)
      );
    } else {
      let state = { ...this.state.type1Data };
      state[key] = value;
      this.setState({ type1Data: state }, () =>
        console.log(this.state.type1Data)
      );
    }
  };

  handleFileUpload = async (file, key) => {
    if (!file) return;
    var formData = new FormData();
    formData.append('file', file);
    console.log(file);

    this.setState({
      selectedFile: formData
    })

    // axios
    //   .post(makeUrl(httpUrl.uploadVAN, []), formData, {
    //     withCredentials: true,
    //   })
    //   .then((res) => {
    //     console.log(res);
    //     if (
    //       res.status === 200 &&
    //       res.data.result === 'SUCCESS' &&
    //       res.data.data.result
    //     ) {
    //       this.handleChange(key, res.data.data.idx);
    //     } else {
    //       Modal.warn({
    //         title: '파일 업로드 실패',
    //         content: '파일 업로드에 실패했습니다. 관리자에게 문의하세요.',
    //       });
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     if (
    //       Object.keys(vanFileUploadErrorCode).includes(e.response.data.message)
    //     )  {
    //       Modal.warn({
    //         title: '업로드 실패',
    //         content: vanFileUploadErrorCode[e.response.data.message],
    //       });
    //     } else {
    //       Modal.warn({
    //         title: '파일 업로드 실패',
    //         content: '파일 업로드에 실패했습니다. 관리자에게 문의하세요.',
    //       });
    //     }
    //   });
  };

  deleteVanFile = async () =>{
    Modal.confirm({
      title: '파일삭제',
      content: '파일이 완전히 삭제되며, 복구할 수 없습니다. 삭제하시겠습니까?',
      onOk: async () => {
        try {
          const res = await httpDelete(httpUrl.deleteVANFile, [], {
            userIdx: this.props.data.idx
          })
  
          if (res.data.result === 'SUCCESS') {
            Modal.info({
              title: '삭제성공',
              content: '파일 삭제에 성공했습니다.',
            });
            await this.props.getList()
          } else
            Modal.warn({
              title: '삭제실패',
              content: '파일 삭제에 실패했습니다.',
            });

        } catch(err) {
          console.log("error: ", err)
          Modal.warn({
            title: '삭제실패',
            content: '파일 삭제에 실패했습니다.',
          });
        }

      },
      onCancel: () => {},
    });
  }

  render() {
    const { close } = this.props;
    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="zone-dialog">
          <div className="VAN-content">
            <div className="timeDelay-title">VAN 등록요청</div>
            <img onClick={close} src={CloseIcon} alt="close" />
            <div className="filtering-inner">
              <Form
                ref={this.formRef}
                encType="multipart/form-data"
                onFinish={this.handleSubmit}>
                {/* <div className="VAN-content-first-box">
                  <div className="mainTitle">유형</div> */}
                  {/* <FormItem name="type"> */}
                  {/* <div>
                    <Radio.Group
                      onChange={this.onCheckType}
                      value={this.state.contarctType}>
                      {Object.keys(contarctType)
                        .reverse()
                        .map((key) => {
                          return (
                            <Radio value={parseInt(key)}>
                              {contarctType[key]}
                            </Radio>
                          );
                        })}
                    </Radio.Group>
                  </div> */}
                  {/* </FormItem> */}
                  {/* {this.state.contarctType === 0 ? (
                    <div className="VAN-txt-sub">
                      *신규 사업자는 VAN등록을 한 적이 없어 카드사에 등록되지{' '}
                      <br />
                      않은 사업장입니다.
                    </div>
                  ) : (
                    <></>
                  )}
                </div> */}
                {this.state.contarctType === 0 ? (
                  <div>
                    <div className="VAN-content-box right">
                      <div className="VAN-content-block">
                        <div>사업자 등록증</div>
                        <div>
                          <FormItem
                            name="businessRegistration"
                            rules={[
                              {
                                required: true,
                                message: '파일을 등록해주세요.',
                              },
                            ]}>
                            <Input
                              onChange={(e) => {
                                this.handleFileUpload(
                                  e.target.files[0],
                                  'businessRegistration'
                                );
                              }}
                              type="file"
                              accept="image/*"
                              className="VAN-file-input"
                            />
                          </FormItem>
                        </div>
                      </div>
                      <div className="VAN-content-block">
                        <div>영업신고증</div>
                        <div>
                          <FormItem
                            name="businessCertification"
                            rules={[
                              {
                                required: true,
                                message: '파일을 등록해주세요.',
                              },
                            ]}>
                            <Input
                              onChange={(e) => {
                                this.handleFileUpload(
                                  e.target.files[0],
                                  'businessCertification'
                                );
                              }}
                              type="file"
                              accept="image/*"
                              className="VAN-file-input"
                            />
                          </FormItem>
                        </div>
                      </div>
                      <div className="VAN-content-block">
                        <div>대표자 신분증</div>
                        <div>
                          <FormItem
                            name="representativeRegistrationNumber"
                            rules={[
                              {
                                required: true,
                                message: '파일을 등록해주세요.',
                              },
                            ]}>
                            <Input
                              onChange={(e) => {
                                this.handleFileUpload(
                                  e.target.files[0],
                                  'representativeRegistrationNumber'
                                );
                              }}
                              type="file"
                              accept="image/*"
                              className="VAN-file-input"
                            />
                          </FormItem>
                        </div>
                      </div>
                      <div className="VAN-content-block">
                        <div>대표자 핸드폰</div>
                        <div>
                          <FormItem name="representativePhoneAgency">
                            <>
                              <SelectBox
                                value={
                                  phoneAgency[
                                    this.state.type0Data
                                      .representativePhoneAgency
                                  ]
                                }
                                code={Object.keys(phoneAgency)}
                                codeString={phoneAgency}
                                onChange={(value) => {
                                  if (
                                    parseInt(value) !==
                                    this.state.type0Data
                                      .representativePhoneAgency
                                  ) {
                                    this.handleChange(
                                      'representativePhoneAgency',
                                      parseInt(value)
                                    );
                                  }
                                }}
                              />
                            </>
                          </FormItem>
                        </div>
                      </div>
                    </div>

                    <div className="VAN-content-box">
                      <div className="VAN-content-block">
                        <div>통장 사본</div>
                        <div>
                          <FormItem
                            name="accountCopy"
                            rules={[
                              {
                                required: true,
                                message: '파일을 등록해주세요.',
                              },
                            ]}>
                            <Input
                              onChange={(e) => {
                                this.handleFileUpload(
                                  e.target.files[0],
                                  'accountCopy'
                                );
                              }}
                              type="file"
                              accept="image/*"
                              className="VAN-file-input"
                            />
                          </FormItem>
                        </div>
                      </div>
                      <div className="VAN-content-block">
                        <div>매장 외부 사진</div>
                        <div>
                          <FormItem
                            name="outsidePhoto"
                            rules={[
                              {
                                required: true,
                                message: '파일을 등록해주세요.',
                              },
                            ]}>
                            <Input
                              onChange={(e) => {
                                this.handleFileUpload(
                                  e.target.files[0],
                                  'outsidePhoto'
                                );
                              }}
                              type="file"
                              accept="image/*"
                              className="VAN-file-input"
                            />
                          </FormItem>
                        </div>
                      </div>
                      <div className="VAN-content-block">
                        <div>매장 내부 사진</div>
                        <div>
                          <FormItem
                            name="insidePhoto"
                            rules={[
                              {
                                required: true,
                                message: '파일을 등록해주세요.',
                              },
                            ]}>
                            <Input
                              onChange={(e) => {
                                this.handleFileUpload(
                                  e.target.files[0],
                                  'insidePhoto'
                                );
                              }}
                              type="file"
                              accept="image/*"
                              className="VAN-file-input"
                            />
                          </FormItem>
                        </div>
                      </div>
                      <div className="VAN-content-block">
                        <div>법인 사업자</div>
                        <div>
                          <FormItem name="type">
                            <Checkbox
                              onChange={(e) =>
                                this.handleChange('type', e.target.checked)
                              }
                              checked={this.state.type0Data.type}
                            />
                          </FormItem>
                        </div>
                      </div>
                    </div>
                    {this.state.type0Data.type ? (
                      <>
                        <div className="VAN-content-first-box" />
                        <div className="VAN-content-box right">
                          <div className="VAN-content-block">
                            <div>법인등기부등본</div>
                            <div>
                              <FormItem
                                name="corporateRegistration"
                                rules={[
                                  {
                                    required: true,
                                    message: '파일을 등록해주세요.',
                                  },
                                ]}>
                                <Input
                                  onChange={(e) => {
                                    this.handleFileUpload(
                                      e.target.files[0],
                                      'corporateRegistration'
                                    );
                                  }}
                                  type="file"
                                  accept="image/*"
                                  className="VAN-file-input"
                                />
                              </FormItem>
                            </div>
                          </div>
                          <div className="VAN-content-block">
                            <div>법인인감증명서</div>
                            <div>
                              <FormItem
                                name="corporateCertificate"
                                rules={[
                                  {
                                    required: true,
                                    message: '파일을 등록해주세요.',
                                  },
                                ]}>
                                <Input
                                  onChange={(e) => {
                                    this.handleFileUpload(
                                      e.target.files[0],
                                      'corporateCertificate'
                                    );
                                  }}
                                  type="file"
                                  accept="image/*"
                                  className="VAN-file-input"
                                />
                              </FormItem>
                            </div>
                          </div>
                          <div className="VAN-content-block">
                            <div>주주명부</div>
                            <div>
                              <FormItem
                                name="shareholderRegister"
                                rules={[
                                  {
                                    required: true,
                                    message: '파일을 등록해주세요.',
                                  },
                                ]}>
                                <Input
                                  onChange={(e) => {
                                    this.handleFileUpload(
                                      e.target.files[0],
                                      'shareholderRegister'
                                    );
                                  }}
                                  type="file"
                                  accept="image/*"
                                  className="VAN-file-input"
                                />
                              </FormItem>
                            </div>
                          </div>
                        </div>
                        <div className="VAN-content-box">
                          <div className="VAN-content-block">
                            <div>사용인감계(선택)</div>
                            <div>
                              <FormItem name="useSeal">
                                <Input
                                  onChange={(e) => {
                                    this.handleFileUpload(
                                      e.target.files[0],
                                      'useSeal'
                                    );
                                  }}
                                  type="file"
                                  accept="image/*"
                                  className="VAN-file-input"
                                />
                              </FormItem>
                            </div>
                          </div>
                          <div className="VAN-content-block">
                            <div>본사 유선</div>
                            <div>
                              <FormItem
                                name="phone"
                                rules={[
                                  {
                                    required: true,
                                    message: '본사 번호를 입력해주세요.',
                                  },
                                ]}>
                                <Input
                                  onChange={(e) => {
                                    this.handleChange('phone', e.target.value);
                                  }}
                                  className="VAN-file-input"
                                  placeholder="전화번호를 - 빼고 입력해주세요."
                                />
                              </FormItem>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="VAN-btn">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="zone-btn">
                        요청하기
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="VAN-content-box">
                      <div className="VAN-content-block" style={{verticalAlign: "top"}}>
                        <div>사업자 등록증</div>
                        <div>
                            <FormItem
                              name="businessRegistration"
                              rules={ !this.props.data.vcrIdx ?[
                                {
                                  required: true,
                                  message: '파일을 등록해주세요.',
                                },
                              ]: null}>
                              { this.props.data.vcrIdx ?
                                <Button
                                  onClick={()=>this.deleteVanFile()}
                                >
                                  삭제
                                </Button>
                                :
                                <Input
                                  onChange={(e) => {
                                    this.handleFileUpload(
                                      e.target.files[0],
                                      'businessRegistration'
                                    );
                                  }}
                                  type="file"
                                  accept="image/*"
                                  className="VAN-file-input"
                                />
                              }
                            </FormItem>
                            { this.props.data.vcrIdx &&
                              <Image
                                src={makeUrl(
                                    httpUrl.vanFileView,
                                    [this.props.data.idx],
                                    {}
                                )}
                                style={{ width: 200, height: 300 }}
                                alt="면허증 사진"
                              />
                            }
                        </div>
                      </div>
                      <div className="VAN-content-block">
                        <div>사업자생년월일</div>
                        <div>
                          <FormItem
                            name="birthday"
                            initialValue={this.props.data.vcrBirthday}
                            rules={[
                              {required: false},
                              {max: 6, message: '6자리로 입력해주세요. ex) 920101'},
                              {pattern: /([0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1,2][0-9]|3[0,1]))/, message: '날짜형식으로 입력해주세요. ex) 920101'}
                            ]}
                          >
                            <Input
                              onChange={(e) => {
                                this.handleChange('birthday', e.target.value);
                              }}
                              placeholder='ex) 920101'
                              className="VAN-file-input"
                            />
                          </FormItem>
                        </div>
                      </div>
                      {/* <div className="VAN-content-block">
                        <div>대표자 신분증</div>
                        <div>
                          <FormItem
                            name="tempName2"
                            rules={[
                              {
                                required: true,
                                message: '파일을 등록해주세요.',
                              },
                            ]}>
                            <Input
                              onChange={(e) => {
                                this.handleFileUpload(
                                  e.target.files[0],
                                  'representativeRegistrationNumber'
                                );
                              }}
                              type="file"
                              accept="image/*"
                              className="VAN-file-input"
                            />
                          </FormItem>
                        </div>
                      </div>
                      <div className="VAN-content-block">
                        <div>통장 사본</div>
                        <div>
                          <FormItem
                            name="tempName3"
                            rules={[
                              {
                                required: true,
                                message: '파일을 등록해주세요.',
                              },
                            ]}>
                            <Input
                              onChange={(e) => {
                                this.handleFileUpload(
                                  e.target.files[0],
                                  'accountCopy'
                                );
                              }}
                              type="file"
                              accept="image/*"
                              className="VAN-file-input"
                            />
                          </FormItem>
                        </div>
                      </div> */}
                    </div>
                    <div className="VAN-btn">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="zone-btn">
                        {"요청하기"}
                      </Button>
                    </div>
                  </>
                )}
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegistVANDialog;
