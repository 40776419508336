export const SET_ORDER = 'SET_ORDER';
export const ADD_ORDER = 'ADD_ORDER';
export const ADD_ORDER_LIST = 'ADD_ORDER_LIST';
export const DELETE_ORDER = 'DELETE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';

export function setOrder(category, orderList) {
  return {
    type: SET_ORDER,
    category,
    orderList,
  };
}

export function addOrder(category, order) {
  return {
    type: ADD_ORDER,
    category,
    order,
  };
}

export function addOrderList(category, orderList) {
  return {
    type: ADD_ORDER_LIST,
    category,
    orderList,
  };
}

export function deleteOrder(category, order) {
  return {
    type: DELETE_ORDER,
    category,
    order,
  };
}

export function updateOrder(order) {
  return {
    type: UPDATE_ORDER,
    order,
  };
}
