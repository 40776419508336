import moment from 'moment';
import { SET_RIDER, UPDATE_RIDER_LOCATION } from '../actions/riderAction';

const riderInitialState = {
  riders: [],
};

const riderReducer = (state = riderInitialState, action) => {
  console.log('state.riders');
  console.log(state.riders);
  switch (action.type) {
    case SET_RIDER:
      return {
        ...state,
        riders: action.riderList,
      };

    case UPDATE_RIDER_LOCATION:
      console.log(UPDATE_RIDER_LOCATION);
      let riders = [...state.riders];
      let targetRiderIdx = riders.findIndex(
        (rider) => action.updateRiderLocationRequest.userIdx === rider.idx
      );

      if (targetRiderIdx > 0) {
        console.log({
          ...action.updateRiderLocationRequest,
          updateDateTime: new moment(),
        });
        riders[targetRiderIdx] = {
          ...riders[targetRiderIdx],
          riderLocation: {
            ...action.updateRiderLocationRequest,
            updateDateTime: new moment(),
          },
        };
        return {
          ...state,
          riders,
        };
      } else return state;

    default:
      return state;
  }
};

export default riderReducer;
