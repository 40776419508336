import { ArrowLeftOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpPost, httpUrl } from '../../../api/httpClient';
import '../../../css/modal.css';
import CloseIcon from '../../../img/login/close.png';

const FormItem = Form.Item;

class PasswordDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.formRef = React.createRef();
  }

  componentDidMount() {}

  handleSubmit = () => {
    const formData = this.formRef.current.getFieldsValue();

    httpPost(httpUrl.setPassword, [], {
      currentPassword: formData.passwordOld,
      newPassword: formData.password,
    })
      .then((res) => {
        console.log(res);
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          alert('비밀번호가 설정되었습니다. 다시 로그인하여 이용해주세요.');
          this.props.close();
        } else if (res.data === 'CURRENT_PASSWORD_NOT_MATCH') {
          alert('기존 비밀번호가 틀렸습니다.');
        } else {
          alert('비밀번호가 변경에 실패했습니다.');
        }
      })
      .catch((e) => {
        alert('비밀번호가 변경에 실패했습니다.');
        throw e;
      });
  };

  render() {
    const { isOpen, close } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />

        <div className="zone-dialog">
          <Form ref={this.formRef} onFinish={this.handleSubmit}>
            <div className="zone-content" style={{ width: 'auto' }}>
              <div> 비밀번호 설정</div>

              <img onClick={close} src={CloseIcon} alt="close" />

              <div className="zone-inner">
                <div className="inner-left">
                  {this.props.passwordValid == 2 && (
                    <div>
                      <FormItem
                        name="passwordOld"
                        rules={[
                          {
                            required: true,
                            message: '기존 패스워드를 입력해주세요',
                          },
                        ]}>
                        <Input
                          placeholder="기존패스워드"
                          type="password"></Input>
                      </FormItem>
                    </div>
                  )}
                  <div>
                    <FormItem
                      name="password"
                      rules={[
                        {
                          validator: (rule, value, cb) => {
                            const passwordOld =
                              this.formRef.current.getFieldValue('passwordOld');

                            let strongRegex = new RegExp(
                              '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,15}$'
                            );
                            if (!value || value == '')
                              cb('패스워드를 입력해주세요');
                            else if (!strongRegex.test(value))
                              cb('영문, 숫자, 특수문자 조합 8~15자로 입력');
                            else if (
                              this.props.passwordValid == 2 &&
                              passwordOld == value
                            )
                              cb('기존 비밀번호와 동일합니다');
                            else cb();
                          },
                        },
                      ]}>
                      <Input placeholder="패스워드" type="password"></Input>
                    </FormItem>
                  </div>
                  <div>
                    <FormItem
                      name="passwordConfirm"
                      rules={[
                        {
                          validator: (rule, value, cb) => {
                            const password =
                              this.formRef.current.getFieldValue('password');

                            if (!value || value == '')
                              cb('패스워드를 입력해주세요');
                            else if (password != value)
                              cb('비밀번호와 비밀번호 확인이 다릅니다.');
                            else cb();
                          },
                        },
                      ]}>
                      <Input placeholder="패스워드확인" type="password"></Input>
                    </FormItem>
                  </div>
                  <div className="zone-btn">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="zoneBtn">
                      등록하기
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </React.Fragment>
    );
  }
}

export default PasswordDialog;
