import { Button, Checkbox, Form, Input, Modal, Radio, Table } from 'antd';
import React, { Component } from 'react';
import { httpDelete, httpGet, httpUrl } from '../../../api/httpClient';
import '../../../css/modal.css';
import CloseIcon from '../../../img/login/close.png';
import { riderLevelText, tableStatusString, userGroupString } from '../../../lib/util/codeUtil';
import SelectBox from '../../input/SelectBox';
import { connect } from 'react-redux';
import { customAlert, customError } from '../../../api/Modals';

const Search = Input.Search;
const today = new Date();

class NcashBatchWorkTargetDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      isMulti: false,
      // data param
      riderLevel: [],
      batchWorkIdx: 0,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ list: this.props.list });
    this.setState({ batchWorkIdx: this.props.batchWorkIdx });
  }

  // 일차감 삭제
  onDeleteRider = (userIdx) => {
    var self = this;
    httpDelete(httpUrl.userBatchWorkDelete, [], {
      batchWorkNcashIdx: self.props.batchWorkIdx,
      userIdx,
    })
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          customAlert('일차감 대상 삭제', '일차감 대상이 삭제되었습니다.');
          this.props.close();
        } else customError('삭제 오류', '오류가 발생하였습니다. 다시 시도해 주십시오.');
      })
      .catch((error) => {
        customError('삭제 오류', '오류가 발생하였습니다. 다시 시도해 주십시오.');
      });
  };

  render() {
    const { close, multi } = this.props;
    const companyId = this.props.branchInfo.companyId;

    const columns = [
      {
        title: 'id',
        dataIndex: 'idx',
        className: 'table-column-center desk',
      },
      {
        title: '대상명',
        className: 'table-column-center',
        render: (row) => <div>{this.props.userType === 1 ? row.riderName:row.frName}</div>,
      },
      {
        title: '삭제',
        dataIndex: 'idx',
        className: 'table-column-center',
        render: (data) => (
          <div>
            <Button className="tabBtn surchargeTab" onClick={() => this.onDeleteRider(data)}>
              삭제
            </Button>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="searchFranchise-Dialog">
          <div className="searchFranchise-content">
            <div className="searchFranchise-title">회수 대상자 목록</div>
            <img onClick={close} src={CloseIcon} className="surcharge-close" alt="닫기" />
            {
              <div className="layout">
                <div style={{ maxHeight: '600px' }} className="dataTableLayout-01">
                  <Table
                    rowKey={(record) => record.idx}
                    dataSource={this.state.list}
                    columns={columns}
                    // pagination={false}
                    onChange={this.handleTableChange}
                  />
                </div>
              </div>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

let mapStateToProps = (state) => ({
  branchInfo: state.branchReducer.branchInfo,
});

export default connect(mapStateToProps)(NcashBatchWorkTargetDialog);
