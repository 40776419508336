import { Button, Input, Modal, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { httpDelete, httpGet, httpUrl } from '../../../api/httpClient';
import CloseIcon from '../../../img/login/close.png';
import { comma, sortDpbJson } from '../../../lib/util/numberUtil';
import DeliveryPriceBasicDialog from './DeliveryPriceBasicDialog';

const DeliveryPriceBasicListDialog = ({ close, companyId }) => {
  const searchStringRef = useRef();

  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });

  const [selectedDpb, setSelectedDpb] = useState(null);
  const [dpbDialogOpen, setDpbDialogOpen] = useState(false);

  useEffect(async () => {
    await getList();
  }, []);

  useEffect(async () => {
    if (selectedDpb == null) await getList();
  }, [selectedDpb]);

  useEffect(async () => {
    await getList();
  }, [pagination.current, pagination.pageSize]);

  const getList = async () => {
    const res = await httpGet(
      httpUrl.getDeliveryPriceBasicList,
      [pagination.pageSize, pagination.current, searchStringRef.current.input.value],
      {}
    );

    console.log(res);

    let sortedList = [];

    if (Array.isArray(res.data.deliveryPriceBasics)) {
      res.data.deliveryPriceBasics.forEach((dpb) => {
        dpb.json = sortDpbJson(dpb.json);
        dpb['jsono'] = dpb.json;
        const o = JSON.parse(dpb.json);
        let list = '';
        for (let a = 0; a < o.length; a++) {
          let distance = comma(o[a][0]);
          let price = comma(o[a][1]);
          list += `${distance}m 부터 100m 마다 ${price}원\n`;
        }
        dpb.json = list;
        sortedList.push(dpb);
      });
    } else return;

    setList(sortedList);
    setPagination({
      ...pagination,
      total: res.data.totalCount,
    });
  };

  const deleteDpb = async (row) => {
    const res = await httpDelete(httpUrl.deleteDeliveryPriceBasicGroup, [], row);

    console.log(res);

    if (res.data.result === 'SUCCESS') {
      Modal.info({
        title: '삭제 성공',
        content: '요금제 삭제에 성공했습니다.',
      });
      await getList();
    } else {
      Modal.warn({
        title: '삭제 실패',
        content: res.data.resultMsg,
      });
    }
  };

  const columns = [
    {
      title: companyId !== 'connect9' ? '지사번호' : '지점번호',
      dataIndex: 'branchIdx',
      className: 'table-column-center',
    },
    {
      title: companyId !== 'connect9' ? '지사명' : '지점명',
      dataIndex: 'branchName',
      className: 'table-column-center',
    },
    {
      title: '그룹번호',
      dataIndex: 'groupIdx',
      className: 'table-column-center',
    },
    {
      title: '그룹명',
      dataIndex: 'name',
      className: 'table-column-center',
    },
    {
      title: '건별수수료',
      dataIndex: 'deliveryPriceFeeByDpBasic',
      className: 'table-column-center',
      render: (data) => <div>{!data ? 0 : comma(data)}원</div>,
    },
    {
      title: '요금제값',
      dataIndex: 'json',
      className: 'table-column-center',
    },
    {
      title: '수정',
      dataIndex: '',
      className: 'table-column-center',
      render: (data, row) => <Button onClick={() => setSelectedDpb(row)}>수정</Button>,
    },
    {
      title: '삭제',
      dataIndex: '',
      className: 'table-column-center',
      render: (data, row) => <Button onClick={async () => await deleteDpb(row)}>삭제</Button>,
    },
  ];

  const setNewDpb = () => {
    setSelectedDpb({
      branchIdx: null,
      groupIdx: null,
      name: null,
      json: null,
      branchName: null,
    });
  };

  return (
    <>
      {selectedDpb != null && <DeliveryPriceBasicDialog dpb={selectedDpb} close={() => setSelectedDpb(null)} />}

      <div className="flex-modal-overlay" onClick={() => close()}>
        <div className="flex-wrapper" onClick={(e) => e.stopPropagation()}>
          <div className="flex-header">
            거리별요금제설정
            <img onClick={close} src={CloseIcon} className="dialog-close" alt="close" />
          </div>

          <div className="flex-inner" style={{ flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
              <Input.Search
                placeholder="그룹명/그룹번호 검색"
                enterButton
                allowClear
                onSearch={async () => await getList()}
                ref={searchStringRef}
                style={{ marginBottom: 10 }}
              />
              <Button type="primary" onClick={() => setNewDpb()}>
                등록
              </Button>
            </div>

            <Table
              style={{ whiteSpace: 'pre' }}
              rowKey={(record) => record.branchIdx + '-' + record.groupIdx}
              rowClassName={'padding10'}
              dataSource={list}
              columns={columns}
              pagination={pagination}
              onChange={(p) => setPagination(p)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryPriceBasicListDialog;
