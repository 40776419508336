import { Button, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import xlsx from 'xlsx';
import { httpGet, httpUrl } from '../../../api/httpClient';
import SelectBox from '../../input/SelectBox';
import { accidentInsuranceString } from '../../../lib/util/codeUtil';
import ExcelIcon from '../../../img/login/excel.png';
import '../../../css/modal.css';
import '../../../css/index.css';
import CloseIcon from '../../../img/login/close.png';

const today = new Date();

class RiderAccidentInsurance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },

      list: [],
      excelList: [],

      startDate: moment(today).format('YYYY-MM-DD'),
      lastDate: moment(today).subtract(1, 'M').format('YYYY-MM-DD'),
      beforeLastDate: moment(today).subtract(2, 'M').format('YYYY-MM-DD'),

      thisMonth: moment(today).format('MM'),
      lastMonth: moment(today).subtract(1, 'M').format('MM'),
      monthBeforeLast: moment(today).subtract(2, 'M').format('MM'),

      selRequestType: 0,
      clicked: '',
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    const {
      pagination,
      selRequestType,
      clicked,
      startDate,
      lastDate,
      beforeLastDate,
    } = this.state;

    httpGet(
      httpUrl.getAccidentInsuranceList,
      [
        pagination.current,
        pagination.pageSize,
        selRequestType,
        clicked === ''
          ? startDate
          : clicked.idx === 3
          ? startDate
          : clicked.idx === 2
          ? lastDate
          : beforeLastDate,
      ],
      {}
    ).then((res) => {
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.accidentInsuranceList,
          pagination: {
            ...pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
          },
        });
      }
    });
  };

  getExcelList = async () => {
    const {
      pagination,
      selRequestType,
      clicked,
      startDate,
      lastDate,
      beforeLastDate,
    } = this.state;

    const res = await httpGet(
      httpUrl.getAccidentInsuranceList,
      [
        1,
        10000,
        selRequestType,
        clicked === ''
          ? startDate
          : clicked.idx === 3
          ? startDate
          : clicked.idx === 2
          ? lastDate
          : beforeLastDate,
      ],
      {}
    );
    if (res.result === 'SUCCESS') {
      this.setState(
        {
          excelList: res.data.accidentInsuranceList,
          pagination: {
            ...pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
          },
        },
        () => this.onDownload()
      );
    }
  };

  parseExcelJson = (data) => {
    const companyId = this.props.branchInfo.companyId;
    let result = [
      {
        branchName: companyId !== 'connect9' ? '지사명' : '지점명',
        riderName: '라이더명',
        registrationNumber: '주민/사업자번호',
        requestType: '내용',
        createDate: '요청일',
        phone: '연락처',
        joinDate: '입직일',
      },
    ];

    data.forEach((item) => {
      result.push({
        branchName: item.branchName,
        riderName: item.riderName,
        registrationNumber: item.registrationNumber,
        requestType: accidentInsuranceString[item.requestType],
        createDate: item.createDate.substring(0, 10),
        phone: item.phone,
        joinDate: item.joinDate,
      });
    });

    return result;
  };

  onDownload = () => {
    const data = this.state.excelList;
    const excelJson = this.parseExcelJson(data);
    const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
    const wb = xlsx.utils.book_new();

    ws['!cols'] = [];
    ws['!cols'][0] = { width: 20 };
    ws['!cols'][2] = { width: 15 };
    ws['!cols'][3] = { width: 15 };
    ws['!cols'][4] = { width: 20 };
    ws['!cols'][5] = { width: 20 };
    xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
    xlsx.writeFile(
      wb,
      this.state.clicked !== ''
        ? this.state.clicked.name + '_라이더_상해보험_가입_해지.xlsx'
        : this.state.thisMonth + '월 요청자_라이더_상해보험_가입_해지.xlsx'
    );
    this.setState({
      excelDownload: false,
    });
  };

  onChangeStatus = (value) => {
    this.setState(
      {
        selRequestType: value === '0' ? 0 : value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  render() {
    const companyId = this.props.branchInfo.companyId;
    const columns = [
      {
        title: companyId !== 'connect9' ? '지사명' : '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '라이더명',
        dataIndex: 'riderName',
        className: 'table-column-center',
      },
      {
        title: '주민/사업자번호',
        dataIndex: 'registrationNumber',
        className: 'table-column-center',
      },
      {
        title: '내용',
        dataIndex: 'requestType',
        className: 'table-column-center',
        render: (data) => <div>{data === 1 ? '가입요청' : '해지요청'}</div>,
      },
      {
        title: '요청일',
        dataIndex: 'createDate',
        className: 'table-column-center',
        render: (data) => <div>{data.substring(0, 10)}</div>,
      },
      {
        title: '연락처',
        dataIndex: 'phone',
        className: 'table-column-center',
      },
      {
        title: '입직일',
        dataIndex: 'joinDate',
        className: 'table-column-center',
      },
    ];

    const menus = [
      {
        idx: 1,
        name: this.state.monthBeforeLast + '월 요청자',
      },
      {
        idx: 2,
        name: this.state.lastMonth + '월 요청자',
      },
      {
        idx: 3,
        name: this.state.thisMonth + '월 요청자',
      },
    ];

    const { close } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className={'rider-Dialog2'}>
          <div className="rider-content2" style={{ padding: '50px' }}>
            <img
              onClick={close}
              src={CloseIcon}
              className="rider-close2"
              alt="close"
            />
            <SelectBox
              style={{ marginBottom: 20 }}
              value={
                this.state.selRequestType === 0
                  ? accidentInsuranceString[0]
                  : accidentInsuranceString[this.state.selRequestType]
              }
              code={Object.keys(accidentInsuranceString)}
              codeString={accidentInsuranceString}
              onChange={(value) => {
                if (parseInt(value) !== this.state.selRequestType) {
                  this.onChangeStatus(value);
                }
              }}
            />
            <span className="menu-wrapper">
              {menus.map((row) => {
                return (
                  <div
                    key={row.idx}
                    onClick={() => {
                      this.setState(
                        {
                          clicked: row,
                          pagination: {
                            current: 1,
                            pageSize: 10,
                          },
                        },
                        () => this.getList()
                      );
                    }}
                    className={
                      'insurance-menu ' +
                      ((this.state.clicked === '' &&
                        row.name.includes(this.state.thisMonth)) ||
                      row.idx === this.state.clicked.idx
                        ? 'active'
                        : '')
                    }>
                    {row.name}
                  </div>
                );
              })}
            </span>
            <Button
              className="download-btn"
              style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
              onClick={() => this.getExcelList()}>
              <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
              엑셀 다운로드
            </Button>
            <Table
              rowKey={(record) => record.idx}
              dataSource={this.state.list}
              columns={columns}
              pagination={this.state.pagination}
              onChange={this.handleTableChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RiderAccidentInsurance;
