import {
  DollarCircleOutlined,
  EnvironmentFilled,
  FieldTimeOutlined,
  GatewayOutlined,
  MessageOutlined,
  NotificationFilled,
  PhoneOutlined,
  PushpinOutlined,
  ShopOutlined
} from '@ant-design/icons';
import {
  Affix,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Pagination,
  Popover,
  Radio,
  Select,
  Table
} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reactLocalStorage } from 'reactjs-localstorage';
import { setBranchInfo } from '../../actions/branchAction';
import { addOrder, deleteOrder, updateOrder } from '../../actions/orderAction';
import { setSetting } from '../../actions/settingAction';
import {
  httpGet,
  httpGetWithNoLoading,
  httpPost,
  httpUrl,
  invokeFunction
} from '../../api/httpClient';
import {
  customError,
  registComplete,
  registError,
  updateError
} from '../../api/Modals';
import ChattingCurrentRoom from '../../components/dialog/common/ChattingCurrentRoom';
import ChattingDialog from '../../components/dialog/common/ChattingDialog';
import SearchRiderDialog from '../../components/dialog/common/SearchRiderDialog';
import VirtualTable from '../../components/dialog/common/VirtualTable';
import BlindControlDialog from '../../components/dialog/franchise/BlindControlDialog';
import AssignShareDialog from '../../components/dialog/order/AssignShareDialog';
import DeliveryZoneDialog from '../../components/dialog/order/DeliveryZoneDialog';
import MapControlDialog from '../../components/dialog/order/MapControlDialog';
import ModifyDeliveryPrice from '../../components/dialog/order/ModifyDeliveryPrice';
import NoticeDialog from '../../components/dialog/order/NoticeDialog';
import OrderLogDialog from '../../components/dialog/order/OrderLogDialog';
import OrderRouteInfo from '../../components/dialog/order/OrderRouteInfo';
import OrderShareDialog from '../../components/dialog/order/OrderShareDialog';
import OrderShareViewDialog from '../../components/dialog/order/OrderShareViewDialog';
import PaymentDialog from '../../components/dialog/order/PaymentDialog';
import RegionSurchargeDialog from '../../components/dialog/order/RegionSurchargeDialog';
import RegistCallDialog from '../../components/dialog/order/RegistCallDialog';
import RiderList from '../../components/dialog/order/RiderListDialog';
import TimeDelayDialog from '../../components/dialog/order/TimeDelayDialog';
import SendSnsDialog from '../../components/dialog/rider/SendSnsDialog';
import ZeroCallFrListDialog from '../../components/dialog/franchise/ZeroCallFrListDialog';
import ZeroCallRiderDialog from '../../components/dialog/rider/ZeroCallRiderDialog';
import Const from '../../const';
import '../../css/common.css';
import '../../css/common_m.css';
import '../../css/modal.css';
import '../../css/modal_m.css';
import '../../css/order.css';
import '../../css/order_m.css';
import {
  arriveReqTime,
  deliveryStatusCode,
  dosiguCodeReplace,
  getOrderStatusValues,
  getPaymentMethodValues,
  HttpResponseStatusCode,
  modifyType,
  orderStatusDefs,
  paymentMethod,
  paymentMethodDefs,
  rowColorName,
  sortOrderCode,
  voiceUpdate
} from '../../lib/util/codeUtil';
import {
  checkCompleteDateExpired,
  formatDate,
  formatMDHMS
} from '../../lib/util/dateUtil';
import { comma, remainTime } from '../../lib/util/numberUtil';
import SurchargeDialog from './../../components/dialog/order/SurchargeDialog';
import {getOrderShareViewInfo,setOrderShareViewInfo} from '../../lib/util/orderShareUtil';
import {getBranchNameList} from '../../lib/util/branchUtil';
import PopupNotice from '../../components/dialog/common/PopupNotice';

const Option = Select.Option;
const Search = Input.Search;
const dateFormat = 'YYYY/MM/DD';
const FormItem = Form.Item;

const inProgressOrderStatuses = getOrderStatusValues(false);
const completedOrderStatuses = getOrderStatusValues(true);

let processPending = false;
let processQueue = [];

class ReceptionStatus extends Component {
  constructor(props) {
    super(props);
    this.franchiseRef = React.createRef();
    this.franchiseRefM = React.createRef();
    this.riderRef = React.createRef();
    this.riderRefM = React.createRef();
    this.addrRef = React.createRef();
    this.idxRef = React.createRef();
    this.showCountRef = React.createRef();
    this.showCountRefM = React.createRef();
    this.allowAssignCancelRef = React.createRef();
    this.assignCancelPenaltyUnitRef = React.createRef();
    this.assignCancelPenaltyLimitRef = React.createRef();
    this.redOrderShowSettingRef = React.createRef();
    this.state = {
      // sort
      sorter: {
        field: '',
        order: '',
        //order: "descend",
        //order: "ascend",
      },
      // paging
      pagination: {
        total: 0,
        current: 1,
        pageSize: 100,
      },
      data: null,

      // modal open / close
      timeDelayOpen: false,
      surchargeOpen: false,
      addCallOpen: false,
      filteringOpen: false,
      noticeOpen: false,
      forceOpen: false,
      MessageOpen: false,
      directMessageOpen: false,
      sendSnsOpen: false,
      activeIndex: -1,
      mapControlOpen: false,
      modifyOrder: false,
      paymentOpen: false,
      editable: false,
      orderData: null,
      paymentData: null,
      deliveryZone: false,
      orderLogOpen: false,
      modifyRegistOrder: false,
      orderRouteInfoOpen: false,
      riderWorkOpen: false,
      modifyDeliveryPrice: false,

      // data
      list: [],
      // orderStatusCounts: [],
      // api param
      frName: '',
      riderName: '',
      destAddr: '',
      idx: '',

      selectedDate: moment(),
      selectedOrderStatuses: inProgressOrderStatuses,
      selectedPaymentMethods: getPaymentMethodValues(),
      checkedCompleteCall: false,

      // 호출설정 branch 정보
      branchInfo: null,
      pullingInterval: 5000,

      messageTarget: null,
      messageTargetName: null,
      messageTargetLevel: null,

      // 추천배차 주문 지정
      forceAllocateOrder: {},

      // 라이더 / 가맹점 상태 표기 추가
      listStatus: {
        connected: 0,
        disconnected: 0,
      },
      riderStatus: [],

      forceList: [],

      showCountList: [],

      selectedVoiceUpdate: [],
      selectBranchSub:
        Object.keys(this.getBranchSubChecked()).length !== 0
          ? this.getBranchSubChecked()
          : [],
      branchSubList: [],
      getBranchSub: [],

      refreshOrderOn: true,
      status: '',
      checkedAddrUpdateCall: false,
      allowAssignCancel: true,
      assignCancelPenaltyUnit: 0,
      assignCancelPenaltyLimit: 0,

      openZeroFrList: false,
      openZeroRiderList: false,

      skipIfAssigned: false,
    };
  }

  async componentDidMount() {
    await this.getBranchSubList();
    this.getFrByBranchSub();
    this.getStatusList();
    this.setInitialSetting();
    this.getBranch();
    await this.getDeliveryDataAsync();
    this.handleAssignedToControlOrders();
    this.getZeroFrList();
    this.getZeroRiderList();

    global.setOrderSelectCallback = (callback) => {
      global.orderSelectCallback = callback;
    };

    global.setOrderUpdateCallback = (callback) => {
      global.orderUpdateCallback = callback;
    };

    this.riderStatusPolling = setInterval(this.getStatusList, 60000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.orderReducer !== this.props.orderReducer) {
      this.pollingFunction();
      global.orderUpdateCallback &&
        global.orderUpdateCallback();
    }
  }

  componentWillUnmount() {
    if (this.riderStatusPolling) clearInterval(this.riderStatusPolling);
  }

  pollingFunction = () => {
    if (!this.state.checkedCompleteCall) {
      this.getOrderList(
        {
          sorter: this.state.sorter,
          orderStatuses: this.state.selectedOrderStatuses,
          paymentMethods: this.state.selectedPaymentMethods,
          frName: this.state.frName,
          riderName: this.state.riderName,
          destAddr: this.state.destAddr,
          idx: this.state.idx,
          branchSub: this.state.selectBranchSub,
        },
        this.state.pagination,
        true
      );
    }

    // this.getRiderStatusList();
  };

  getList = (
    completeCall = this.state.checkedCompleteCall,
    selectedDate = this.state.selectedDate,
    pagination = this.state.pagination,
    selectedOrderStatuses = Object.keys(this.getOrderStatusChecked()).length !==
      0
      ? this.getOrderStatusChecked()
      : this.state.selectedOrderStatuses,
    selectedPaymentMethods = Object.keys(this.getPaymentMethodsChecked())
      .length !== 0
      ? this.getPaymentMethodsChecked()
      : this.state.selectedPaymentMethods,
    selectBranchSub = Object.keys(this.getBranchSubChecked()).length !== 0
      ? this.getBranchSubChecked()
      : this.state.selectBranchSub
  ) => {
    let searchData = {
      sorter: this.state.sorter,
      orderStatuses: selectedOrderStatuses,
      paymentMethods: selectedPaymentMethods,
      frName: this.state.frName,
      riderName: this.state.riderName,
      destAddr: this.state.destAddr,
      idx: this.state.idx,
      branchSub: selectBranchSub,
    };
    if (completeCall) {
      searchData = {
        ...searchData,
        startDate: selectedDate.format('YYYY-MM-DD HH:mm'),
        endDate: selectedDate.add(1, 'd').format('YYYY-MM-DD HH:mm'),
        pageNum: pagination.current,
        pageSize: pagination.pageSize,
      };

      this.getCompleteOrderListAsync(searchData, pagination);
    } else {
      this.getOrderList(searchData, pagination);
    }
  };

  getOrderStatusChecked = () => {
    const data = reactLocalStorage.getObject(
      Const.appName + '#orderStatusChecked'
    );
    return typeof data === 'object' && data;
  };
  setOrderStatusChecked = (data) => {
    reactLocalStorage.setObject(Const.appName + '#orderStatusChecked', data);
  };

  getPaymentMethodsChecked = () => {
    const data = reactLocalStorage.getObject(
      Const.appName + '#paymentMethodsChecked'
    );
    return typeof data === 'object' && data;
  };
  setPaymentMethodsChecked = (data) => {
    reactLocalStorage.setObject(Const.appName + '#paymentMethodsChecked', data);
  };

  getVoiceChecked = () => {
    const data = reactLocalStorage.getObject(Const.appName + '#voiceChecked');
    return typeof data === 'object' && data;
  };
  setVoiceChecked = (data) => {
    reactLocalStorage.setObject(Const.appName + '#voiceChecked', data);
  };

  getBranchSubChecked = () => {
    const data = reactLocalStorage.getObject(
      Const.appName + '#branchSubChecked'
    );
    return typeof data === 'object' && data;
  };
  setBranchSubChecked = (data) => {
    reactLocalStorage.setObject(Const.appName + '#branchSubChecked', data);
  };

  getBranchSubList = () => {
    let branchIdx = this.getAdminUserInfo().branchIdx;
    const res = httpGet(httpUrl.getBranchSubName, [branchIdx], {}).then(
      (res) => {
        if (res.result === 'SUCCESS') {
          this.setState(
            {
              getBranchSub: res.data.list,
            },
            console.log('88888888888888888888' + JSON.stringify(res))
          );
        }
      }
    );
    return res;
  };

  getAdminUserInfo = () => {
    const data = reactLocalStorage.getObject(Const.appName + '#adminUser');
    return typeof data === 'object' && data;
  };

  getRiderStatusList = async () => {
    try {
      const res = await httpGet(httpUrl.getRiderStatusList, [], {});
      console.log(res);
      this.setState({
        riderStatus: res.data,
      });
    } catch (e) {
      console.log('getStatusList error', e);
    }
  };

  executeProcessQueue = () => {
    const data = processQueue.pop();
    if (data) {
      console.warn('call queue getOrderListAsync.');
      this.getOrderListAsync(data.searchData, data.pagination, data.background);
    }
  };
  getOrderList = (searchData, pagination, background = false) => {
    this.executeProcessQueue();
    this.getOrderListAsync(searchData, pagination, background).then(
      (result) => {
        if (result) {
          this.executeProcessQueue();
        }
      }
    );
  };
  getOrderListAsync = async (searchData, pagination, background) => {
    if (processPending) {
      if (!background) {
        processQueue.unshift({
          searchData,
          pagination,
          background,
        });
      }
      console.warn(
        `getOrderListAsync. pending, background: ${background}, add to queue: ${processQueue.length}`
      );
      return false;
    }

    console.log(
      `getOrderListAsync. searchData: ${JSON.stringify(
        searchData
      )}, pagination: ${JSON.stringify(pagination)}, background: ${background}`
    );

    processPending = true;

    let result = 'ERROR';
    let list = [];
    // let orderStatusCounts = [];
    let showCountList = [];

    try {
      const res = await invokeFunction(() => {
        return this.searchOrderList(searchData);
      }, background);
      console.log('getOrderListAsync. res: ', res);
      if (res.result === 'SUCCESS') {
        result = res.result;
        list = res.orders;
        pagination.total = res.orders.length;

        const pageCount = parseInt(
          Math.ceil(res.orders.length / pagination.pageSize)
        );
        const startIdx = (pagination.current - 1) * pagination.pageSize;
        const endIdx =
          pagination.current === pageCount
            ? res.orders.length
            : pagination.current * pagination.pageSize;
        list = endIdx > 0 ? res.orders.slice(startIdx, endIdx) : [];
        // console.log(`getOrderList. list pageCount: ${pageCount}, idx: ${startIdx}, ${endIdx}`);

        // orderStatusCounts = this.getOrderStatusCounts();

        const newOrders = this.props.orderReducer.orders.filter(
          (x) => x.orderStatus === 1 && x.waitingStatus != 1
        );

        showCountList =
          newOrders.length <= this.props.showCount
            ? newOrders.map((x) => x.idx)
            : newOrders
              .slice(
                newOrders.length - this.props.showCount,
                newOrders.length
              )
              .map((x) => x.idx);
      }
    } catch (e) {
      console.error('getOrderListAsync. Raised exception. ', e);
    }

    processPending = false;

    if (result) {
      this.setState({
        checkedCompleteCall: false,
        list,
        pagination,
        selectedOrderStatuses: searchData.orderStatuses,
        selectedPaymentMethods: searchData.paymentMethods,
        // orderStatusCounts,
        showCountList,
      });
    } else {
      Modal.info({
        title: '적용 오류',
        content: '처리가 실패했습니다.',
      });
    }

    return true;
  };
  getCompleteOrderListAsync = async (searchData, pagination) => {
    console.log(
      `getCompleteOrderListAsync. searchData: ${JSON.stringify(
        searchData
      )}, pagination: ${JSON.stringify(pagination)}`
    );

    let result = 'ERROR';
    let list = [];
    // let orderStatusCounts = orderStatusDefs.map((x) => {
    //   return 0;
    // });

    try {
      const osv = await getOrderShareViewInfo(this.props.branchIdx, true)
      const res = await httpPost(httpUrl.orderList, [], {
        ...searchData,
        orderStatuses: searchData.orderStatuses.filter((x) =>
          completedOrderStatuses.includes(x)
        ),
        branchIdxes: osv.view
      });
      console.log('getCompleteOrderListAsync. res: ', res);
      if (res.result === 'SUCCESS') {
        result = res.result;
        list = res.data.orders;
        pagination.total = res.data.totalCount;

        // TODO 한번에 가져오게 수정하자
        // const resCompleted = await httpGet(
        //   httpUrl.completedCount,
        //   [searchData.startDate],
        //   {}
        // );
        // if (resCompleted.result == "SUCCESS") {
        //   orderStatusCounts[4 - 1] = resCompleted.data;
        // }

        // const resCanceled = await httpGet(
        //   httpUrl.canceledCount,
        //   [searchData.startDate],
        //   {}
        // );
        // if (resCanceled.result == "SUCCESS") {
        //   orderStatusCounts[5 - 1] = resCanceled.data;
        // }
      }
    } catch (e) {
      console.error('getCompleteOrderListAsync. Raised exception. ', e);
    }

    if (result) {
      this.setState({
        checkedCompleteCall: true,
        list,
        pagination,
        selectedOrderStatuses: searchData.orderStatuses,
        selectedPaymentMethods: searchData.paymentMethods,
        selectBranchSub: searchData.branchSub,
        // orderStatusCounts,

        selectedDate: moment(searchData.startDate),
      });
    } else {
      Modal.info({
        title: '적용 오류',
        content: '처리가 실패했습니다.',
      });
    }
  };

  searchOrderList = (searchData) => {
    let { orders } = this.props.orderReducer;

    // 가맹점 검색
    if (searchData.frName) {
      orders = orders.filter((order) => {
        if (
          order.frName != null &&
          order.frName.indexOf(searchData.frName) >= 0
        )
          return true;
        if (
          order.controlMemo != null &&
          order.controlMemo.indexOf(searchData.frName) >= 0
        )
          return true;
        return false;
      });
    }
    // 기사명 검색
    if (searchData.riderName) {
      orders = orders.filter((order) => {
        if (
          order.riderName != null &&
          order.riderName.indexOf(searchData.riderName) >= 0
        )
          return true;
        return false;
      });
    }
    // 도착지 검색
    if (searchData.destAddr) {
      orders = orders.filter((order) => {
        if (
          order.destAddr3 != null &&
          order.destAddr3.indexOf(searchData.destAddr) >= 0
        )
          return true;
        if (
          order.destAddr2 != null &&
          order.destAddr2.indexOf(searchData.destAddr) >= 0
        )
          return true;
        return false;
      });
    }
    // 주문번호 검색
    if (searchData.idx) {
      orders = orders.filter((order) => {
        if (order.idx != null && order.idx === parseInt(searchData.idx))
          return true;
        return false;
      });
    }
    // 주문상태 필터링
    orders = orders.filter((order) =>
      searchData.orderStatuses.includes(order.orderStatus)
    );

    // 결제방식 필터링
    orders = orders.filter((order) => {
      let flag = false;
      searchData.paymentMethods.forEach((method) => {
        order.orderPayments.forEach((payment) => {
          if (method === payment.paymentMethod) {
            flag = true;
            return;
          }
          if (flag) return;
        });
      });
      return flag;
    });

    //하위지점 필터링
    const array = this.state.branchSubList;
    orders = orders.filter((order) => !array.includes(order.frIdx));

    // 자동갱신 껐을 시 최대 idx 값 필터링
    if (!this.state.refreshOrderOn) {
      orders = orders.filter(
        (order) => order.idx <= this.refreshOrderOnFlagIdx
      );
    }

    console.log('list');
    console.log(!this.state.refreshOrderOn);
    console.log(this.refreshOrderOnFlagIdx);
    console.log(orders);

    // if (
    //   searchData.sorter &&
    //   searchData.sorter.field &&
    //   searchData.sorter.order
    // ) {
    const sorter = this.sortOrder(searchData.sorter);
    if (sorter) {
      console.log('searchOrderList. sorted sorter: ', searchData.sorter);

      orders.sort(sorter);
      // }
    }

    return {
      result: 'SUCCESS',
      orders,
    };
  };
  sortOrder = (sorter) => {
    switch (sorter.field) {
      case 'branchIdx':
        return (a, b) => {
          let dataA = a.branchIdx+'';
          let dataB = b.branchIdx+'';

          const obj1 = getBranchNameList().find(x=>x.IDX == dataA);
          if (obj1) dataA = obj1.BRANCH_NAME;
          const obj2 = getBranchNameList().find(x=>x.IDX == dataB);
          if (obj2) dataB = obj2.BRANCH_NAME;

          return sorter.order === 'descend'
            ? dataB.localeCompare(dataA)
            : dataA.localeCompare(dataB);
        };
      case 'arriveReqTime':
        return (a, b) =>
          sorter.order === 'descend'
            ? b.arriveReqTime - a.arriveReqTime
            : a.arriveReqTime - b.arriveReqTime;
      case 'remainTime':
        return (a, b) =>
          sorter.order === 'descend'
            ? remainTime(b.orderDate, b.arriveReqTime) -
            remainTime(a.orderDate, a.arriveReqTime)
            : remainTime(a.orderDate, a.arriveReqTime) -
            remainTime(b.orderDate, b.arriveReqTime);
      case 'itemPrepared':
        return (a, b) =>
          sorter.order === 'descend'
            ? b.itemPrepared - a.itemPrepared
            : a.itemPrepared - b.itemPrepared;
      case 'frName':
        return (a, b) =>
          sorter.order === 'descend'
            ? b.frName.localeCompare(a.frName)
            : a.frName.localeCompare(b.frName);
      case 'destAddr':
        return (a, b) =>
          sorter.order === 'descend'
            ? (b.destAddr3 + b.destAddr2).localeCompare(
              a.destAddr3 + a.destAddr2
            )
            : (a.destAddr3 + a.destAddr2).localeCompare(
              b.destAddr3 + b.destAddr2
            );
      case 'riderName':
        return (a, b) => {
          const dataA = (a.riderName || '').trim();
          const dataB = (b.riderName || '').trim();
          return sorter.order === 'descend'
            ? dataB.localeCompare(dataA)
            : dataA.localeCompare(dataB);
        };
      case 'orderPrice':
        return (a, b) =>
          sorter.order === 'descend'
            ? b.orderPrice - a.orderPrice
            : a.orderPrice - b.orderPrice;
      case 'deliveryPrice':
        return (a, b) =>
          sorter.order === 'descend'
            ? b.deliveryPrice - a.deliveryPrice
            : a.deliveryPrice - b.deliveryPrice;
      case 'orderDate':
        return (a, b) =>
          sorter.order === 'descend'
            ? moment(b.orderDate) - moment(a.orderDate)
            : moment(a.orderDate) - moment(b.orderDate);
      case 'assignDate':
        return (a, b) => {
          if(sorter.order === 'ascend') {
            if (a.assignDate == null) return -1;
            if (b.assignDate == null) return +1;
          } else {
            if (a.assignDate == null) return +1;
            if (b.assignDate == null) return -1;
          }
          return sorter.order === 'descend'
            ? moment(b.assignDate) - moment(a.assignDate)
            : moment(a.assignDate) - moment(b.assignDate);
        }
      case 'pickupDate':
        return (a, b) => {
          if (a.pickupDate == null) return +1;
          if (b.pickupDate == null) return -1;
          return sorter.order === 'descend'
            ? moment(b.pickupDate) - moment(a.pickupDate)
            : moment(a.pickupDate) - moment(b.pickupDate);
        }
      case 'completeDate':
        return (a, b) => {
          if (a.completeDate == null) return +1;
          if (b.completeDate == null) return -1;
          return sorter.order === 'descend'
            ? moment(b.completeDate) - moment(a.completeDate)
            : moment(a.completeDate) - moment(b.completeDate);
        }
      case 'idx':
        return (a, b) =>
          sorter.order === 'descend' ? b.idx - a.idx : a.idx - b.idx;
      default:
        return (a, b) => {
          if (b.waitingStatus - a.waitingStatus > 0) return 1;
          else if (b.waitingStatus - a.waitingStatus < 0) return -1;
          else return b.idx - a.idx;
        };
    }
  };

  getOrderStatusCount = (status) => {
    // const counts = this.state.orderStatusCounts[id - 1];
    // return counts;
    const array = this.state.branchSubList;
    if (this.state.checkedCompleteCall) {
      return this.state.list
        .filter(
          (order) =>
            order.orderStatus === status &&
            order.branchIdx === this.props.branchIdx
        )
        .filter((order) => !array.includes(order.frIdx)).length;
    } else {
      return this.props.orderReducer.orders
        .filter(
          (order) =>
            order.orderStatus === status &&
            order.branchIdx === this.props.branchIdx
        )
        .filter((order) => !array.includes(order.frIdx)).length;
    }
  };

  getStatusList = async () => {
    try {
      const res = await httpGet(httpUrl.getRiderStatusList, [], {});
      this.setState({
        riderStatus: res.data,
      });
    } catch (e) {
      console.log('getStatusList error', e);
    }
  };

  handleToggleCompleteCall = (e) => {
    const checked = e.target.checked;

    this.setState(
      {
        checkedCompleteCall: checked,
        selectedDate: moment().hours('00').minutes('00').seconds('00'),
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  handleChangeSelectedDate = (date) => {
    if (!date) return;
    console.log('handleChangeSelectedDate date:', date);

    this.setState(
      {
        selectedDate: moment(date).hours('00').minutes('00').seconds('00'),
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      },
      () => {
        this.getList();
      }
    );
  };
  handleChangeOrderStatus = (checkedValues) => {
    console.log('handleChangeOrderStatus. checkedValues: ', checkedValues);
    this.setOrderStatusChecked(checkedValues);

    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        selectedOrderStatuses: this.getOrderStatusChecked(),
        // selectedOrderStatuses: checkedValues,
      },
      () => {
        this.getList();
      }
    );
  };
  handleChangePaymentMethod = (checkedValues) => {
    console.log('handleChangePaymentMethod. checkedValues: ', checkedValues);
    this.setPaymentMethodsChecked(checkedValues);

    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        selectedPaymentMethods: this.getPaymentMethodsChecked(),
        // selectedPaymentMethods: checkedValues,
      },
      () => {
        this.getList();
      }
    );
  };

  handleChangeOrderSort = async (e) => {
    console.log(e.target.value);
    const res = await httpPost(httpUrl.setBranchOrderSort, [], e.target.value);
    console.log(res);
    if (res.data === 'SUCCESS')
      this.setState({
        selectedOrderSort: e.target.value,
      });
  };

  getControlValue = (control, defaultValue = '') => {
    return control ? control.state.value : defaultValue;
  };
  handleSearch = () => {
    let frName = this.getControlValue(this.franchiseRef.current);
    let riderName = this.getControlValue(this.riderRef.current);
    let destAddr = this.getControlValue(this.addrRef.current);
    let idx = this.getControlValue(this.idxRef.current);

    if (!frName) frName = this.getControlValue(this.franchiseRefM.current);
    if (!riderName) riderName = this.getControlValue(this.riderRefM.current);

    this.setState(
      {
        frName,
        riderName,
        destAddr,
        idx,

        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  assignRider = (data) => {
    console.log(data);

    if (!this.state.forceAllocateOrder) {
      this.assignRiderMulti(data);
      return;
    }
    var self = this;
    let updatedOrder = this.state.forceAllocateOrder;
    httpPost(httpUrl.assignRiderAdmin, [], {
      orderIdx: self.state.forceAllocateOrder.idx,
      userIdx: data.idx,
      skipIfAssigned: self.state.skipIfAssigned,
    }).then((res) => {
      console.log(res);
      if (res.result === 'SUCCESS') {
        switch (res.data) {
          case 'SUCCESS':
            updatedOrder.riderName = data.riderName;
            self.setState(
              {
                forceAllocateOrder: null,
                forceList: self.state.forceList.filter(
                  (flo) => flo.idx !== updatedOrder.idx
                ),
              },
              () => self.getList()
            );

            global.orderUpdateCallback &&
              global.orderUpdateCallback();
            break;
          case 'ALREADY_ASSIGNED':
            customError('배차 오류', '이미 배차된 주문입니다.');
            break;
          case 'ALREADY_ASSIGNED_SKIP_TRUE':
            customError('배차 오류', '이미 배차된 주문입니다.');
            break;

          case 'ORDER_NOT_EXISTS':
            customError('배차 오류', '존재하지 않은 주문입니다.');
            break;
          case 'NCASH_MINUS':
            customError('배차 오류', 'NCash 잔액이 부족합니다.');
            break;
          case 'ASSIGN_LIMIT_EXCEEDED':
            customError('배차 오류', '배차 목록이 가득 찼습니다.');
            break;
          case 'NOT_ADMIN':
            customError('배차 오류', '관리자만 추천배차할 수 있습니다.');
            break;
          case 'ASSIGNED_TO_ADMIN':
            customError(
              '배차 오류',
              '다른 관리자 장바구니에 추가된 주문입니다.'
            );
            break;
          case 'RIDER_NOT_APPROVED':
            customError('라이더 선택 불가', '서류 승인이 필요한 라이더 입니다.');
            break;
          case 'COMPLETED_ORDER':
            customError('라이더 배정 불가', '완료상태의 주문 입니다.');
            break;
          case 'CANCELED_ORDER':
            customError('라이더 배정 불가', '취소상태의 주문 입니다.');
            break;
          case 'SAME_RIDER':
            customError('라이더 선택 불가', '동일한 라이더가 배정되어 있습니다.');
            break;
          case 'WAITING_ORDER':
            customError('라이더 배정 불가', '대기상태의 주문 입니다.');
            break;
          case 'RIDER_NAME_NOT_VERIFIED':
            customError(
              '배차 오류',
              '실명인증이 되어있지 않은 라이더입니다.'
            );
            break;
          case 'AUTO_CANCEL_TIME_OVER':
            customError(
                '배차 실패',
                '주문 자동 취소 시간이 지나서 자동 취소 된 주문 입니다.'
            );
            break;
          default:
            customError(
              '배차 오류',
              '배차에 실패했습니다. 관리자에게 문의하세요.'
            );
            break;
        }
      } else
        customError('배차 오류', '배차에 실패했습니다. 관리자에게 문의하세요.');
    });
    //   },
    // });
  };

  assignRiderMulti = (data) => {
    let orderText = '';
    this.state.forceList.forEach((order) => {
      orderText += `${order.idx}번 `;
    });
    const self = this;
    Modal.confirm({
      title: '일괄배차',
      content: `${orderText} 주문을 일괄배차 하시겠습니까?`,
      onOk: async () => {
        let failedOrderIdxes = [];
        for (let i = 0; i < self.state.forceList.length; i++) {
          const order = self.state.forceList[i];
          console.log({
            orderIdx: order.idx,
            userIdx: data.idx,
            skipIfAssigned: self.state.skipIfAssigned,
          });
          const res = await httpPost(httpUrl.assignRiderAdmin, [], {
            orderIdx: order.idx,
            userIdx: data.idx,
            skipIfAssigned: self.state.skipIfAssigned,
          });
          console.log(`${i}번째 주문 res`);
          console.log(res);
          if (res.data !== 'SUCCESS') {
            switch (res.data) {
              case 'ALREADY_ASSIGNED':
                failedOrderIdxes.push({
                  orderIdx: order.idx,
                  errorMessage: '이미 배차된 주문입니다.',
                });
                break;
              case 'ALREADY_ASSIGNED_SKIP_TRUE':
                failedOrderIdxes.push({
                  orderIdx: order.idx,
                  errorMessage: '이미 배차된 주문입니다.',
                });
                break;
              case 'ORDER_NOT_EXISTS':
                failedOrderIdxes.push({
                  orderIdx: order.idx,
                  errorMessage: '존재하지 않은 주문입니다.',
                });
                break;
              case 'NCASH_MINUS':
                failedOrderIdxes.push({
                  orderIdx: order.idx,
                  errorMessage: 'NCash 잔액이 부족합니다.',
                });
                break;
              case 'ASSIGN_LIMIT_EXCEEDED':
                failedOrderIdxes.push({
                  orderIdx: order.idx,
                  errorMessage: '배차 목록이 가득 찼습니다.',
                });
                break;
              case 'NOT_ADMIN':
                failedOrderIdxes.push({
                  orderIdx: order.idx,
                  errorMessage: '관리자만 추천배차할 수 있습니다.',
                });
                break;
              case 'RIDER_NOT_APPROVED':
                failedOrderIdxes.push({
                  orderIdx: order.idx,
                  errorMessage: '서류 승인이 필요한 라이더 입니다.',
                });
                break;
              case 'COMPLETED_ORDER':
                failedOrderIdxes.push({
                  orderIdx: order.idx,
                  errorMessage: '완료상태의 주문 입니다.',
                });
                break;
              case 'CANCELED_ORDER':
                failedOrderIdxes.push({
                  orderIdx: order.idx,
                  errorMessage: '취소상태의 주문 입니다.',
                });
                break;
              case 'SAME_RIDER':
                failedOrderIdxes.push({
                  orderIdx: order.idx,
                  errorMessage: '동일한 라이더가 배정되어 있습니다.',
                });
                break;
              case 'WAITING_ORDER':
                failedOrderIdxes.push({
                  orderIdx: order.idx,
                  errorMessage: '대기상태의 주문 입니다.',
                });
                break;
              case 'RIDER_NAME_NOT_VERIFIED':
                failedOrderIdxes.push({
                  orderIdx: order.idx,
                  errorMessage: '실명인증이 되어있지 않은 라이더입니다.',
                });
                break;
              case 'AUTO_CANCEL_TIME_OVER':
                failedOrderIdxes.push({
                  orderIdx: order.idx,
                  errorMessage: '주문 자동 취소 시간이 지나서 자동 취소 된 주문 입니다.',
                });
                break;
              default:
                failedOrderIdxes.push({
                  orderIdx: order.idx,
                  errorMessage: '배차에 실패했습니다. 관리자에게 문의하세요.',
                });
                break;
            }
          }
        }
        if (failedOrderIdxes.length === 0) {
          Modal.info({
            title: '일괄배차 성공',
            content: '모든 주문 추천배차에 성공했습니다.',
          });
          this.setState({
            forceList: [],
          });
        } else {
          let errorText = '';
          failedOrderIdxes.forEach((failure) => {
            errorText += `${failure.orderIdx} : ${failure.errorMessage}\n`;
          });
          Modal.info({
            title: '일괄배차 실패',
            content: errorText,
          });
        }
      },
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    console.log('handleTableChange. pagination: ', pagination, filters, sorter);

    this.setState(
      {
        sorter: {
          field: sorter.order ? sorter.field : '',
          order: sorter.order,
        },
        pagination,
      },
      () => {
        this.getList();
      }
    );
  };

  changeWaiting = (row) => {
    let self = this;
    // Modal.confirm({
    //   title: "대기 변경",
    //   content: "주문 대기 상태를 변경하시겠습니까?",
    //   okText: "확인",
    //   cancelText: "취소",
    //   onOk() {
    console.log({
      idx: row.idx,
      waitingStatus: row.waitingStatus === 0 ? 1 : 0,
    });
    httpPost(httpUrl.orderUpdateWaitingStatus, [], {
      idx: row.idx,
      waitingStatus: row.waitingStatus === 0 ? 1 : 0,
    })
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          // customAlert("완료", "대기상태가 변경되었습니다.");
        } else updateError();
        this.removeForceList(row);
        self.getList();
        global.orderUpdateCallback &&
          global.orderUpdateCallback();
      })
      .catch((e) => {
        updateError();
      });
    //   },
    // });
  };

  setRowClassName = (record) => {
    return record.deliveryPrice === 0 || record.distance === 0 || record.destAddr2.charAt(0) === '-'
      ? 'table-redalert'
      : record.waitingStatus === 1
        ? 'table-purple'
        : this.state.showCountList.includes(record.idx)
          ? 'table-pink'
          : rowColorName[record.orderStatus];
  };

  // 호출지역 dialog
  openDeliveryZoneModal = () => {
    this.setState({ deliveryZone: true });
  };
  closeDeliveryZoneModal = () => {
    this.setState({ deliveryZone: false });
  };

  // 시간지연 dialog
  openTimeDelayModal = () => {
    this.setState({ timeDelayOpen: true });
  };
  closeTimeDelayModal = (value) => {
    this.setState({ timeDelayOpen: false, branchInfo: value });
    this.getBranch();
  };

  // 할증 dialog
  openSurchargeModal = () => {
    this.setState({ surchargeOpen: true });
  };
  closeSurchargeModal = () => {
    this.setState({ surchargeOpen: false });
  };

  // 콜등록 dialog
  openAddCallModal = () => {
    this.setState({ addCallOpen: true });
  };
  closeAddCallModal = (addedCall) => {
    if (addedCall) {
      const checked = false;

      this.setState(
        {
          addCallOpen: false,

          checkedCompleteCall: checked,
          selectedDate: moment(),
          pagination: {
            ...this.state.pagination,
            current: 1,
          },
        },
        () => {
          this.getList();
        }
      );
    } else {
      this.setState({
        addCallOpen: false,
      });
    }
    global.orderUpdateCallback &&
      global.orderUpdateCallback();
  };
  openOrderShareModal = () => {
    this.setState({
      orderShareModalOpen: true,
    });
  };
  closeOrderShareModal = () => {
    this.setState({
      orderShareModalOpen: false,
    });
  };

  openAssignShareModal = () => {
    this.setState({
      assignShareModalOpen: true,
    });
  };
  closeAssignShareModal = () => {
    this.setState({
      assignShareModalOpen: false,
    });
  };

  openOrderShareViewModal = () => {
    this.setState({
      orderShareViewModalOpen: true,
    });
  };
  closeOrderShareViewModal = () => {
    this.setState({
      orderShareViewModalOpen: false,
    });
  };
  // 지도관제
  openMapWindow = () => {
    this.mapControlDialogWindow = window.open(
      '../../components/dialog/order/MapControlDialog',
      'child',
      'location=yes,height=%100,width=%100,left=0,location=0,scrollbars=yes,status=yes'
    );
    console.log(this.mapControlDialogWindow);
  };
  openMapControlModal = () => {
    this.setState({ mapControlOpen: true });
  };
  closeMapControlModal = () => {
    this.mapControlDialogWindow = null;
    this.setState({ mapControlOpen: false });
    this.getList();
  };

  // 공지사항 dialog
  openNoticeModal = () => {
    this.setState({ noticeOpen: true });
  };
  closeNoticeModal = () => {
    this.setState({ noticeOpen: false });
  };

  // 블라인드관리 dialog
  openBlindControlModal = () => {
    this.setState({ blindControlOpen: true });
  };
  closeBlindControlModal = () => {
    this.setState({ blindControlOpen: false });
  };

  // 추천배차 dialog
  openForceModal = (forceAllocateOrder) => {
    if (forceAllocateOrder && forceAllocateOrder.waitingStatus === 1) {
      alert('주문 대기 상태에서는 사용 불가합니다.');
    } else {
      this.setState({ forceOpen: true, forceAllocateOrder }, () => { });
    }
  };
  closeForceingModal = () => {
    this.setState({ forceOpen: false, forceAllocateOrder: null });
  };

  // 메세지 dialog
  openMessageModal = () => {
    this.setState({ MessageOpen: true });
  };
  closeMessageModal = () => {
    this.setState({ MessageOpen: false });
  };

  // 개인 메세지 dialog
  openDirectMessageModal = (idx, name, riderLevel) => {
    this.setState(
      {
        messageTarget: idx,
        messageTargetName: name,
        messageTargetLevel: riderLevel,
      },
      () => this.setState({ directMessageOpen: true })
    );
  };
  closeDirectMessageModal = () => {
    this.setState({
      directMessageOpen: false,
      messageTarget: null,
      messageTargetLevel: null,
    });
  };

  // sns dialog
  openSendSnsModal = () => {
    this.setState({ sendSnsOpen: true });
  };
  closeSendSnsModal = () => {
    this.setState({ sendSnsOpen: false });
  };

  // 주문수정 dialog
  openModifyOrderModal = (order) => {
    this.setState({ data: order, modifyOrder: true });
  };
  closeModifyOrderModal = (modified) => {
    this.setState({ modifyOrder: false });
    modified && !this.state.checkedCompleteCall && this.getList();
    global.orderUpdateCallback &&
      global.orderUpdateCallback();
  };

  // 재접수 dialog
  openModifyRegistModal = (order) => {
    this.setState({
      data: { ...order, ftOrderCode: null, bmOrderCode: null, userIdx: null },
      modifyRegistOrder: true,
    });
  };
  closeModifyRegistModal = (addedCall) => {
    if (addedCall) {
      const checked = false;

      this.setState(
        {
          modifyRegistOrder: false,

          checkedCompleteCall: checked,
          selectedDate: moment(),
          pagination: {
            ...this.state.pagination,
            current: 1,
          },
        },
        () => {
          this.getList();
        }
      );
    } else {
      this.setState({
        modifyRegistOrder: false,
      });
    }
    global.orderUpdateCallback &&
      global.orderUpdateCallback();
  };

  // 주문수정 dialog
  openPaymentModal = (data, row) => {
    this.setState({
      paymentData: data,
      orderPrice: row.orderPrice,
      paymentOpen: true,
    });
  };
  closePaymentModal = () => {
    this.setState({ paymentOpen: false });
    this.getList();
  };

  getStatusVal = (idx) => {
    // console.log("idx : "+idx)
  };

  openOrderLogModal = (row) => {
    this.setState({ orderLog: row }, () =>
      this.setState({ orderLogOpen: true })
    );
  };

  closeOrderLogModal = () => {
    this.setState({ orderLog: null, orderLogOpen: false });
  };

  openRegionSurchargeModal = () => {
    this.setState({ RegionSurchargeOpen: true });
  };

  closeRegionSurchargeModal = () => {
    this.setState({ RegionSurchargeOpen: false });
  };

  openOrderRouteInfo = (data) => {
    if (!(data.orderStatus === 2 || data.orderStatus === 3)) return;

    this.setState({ data, orderRouteInfoOpen: true });
  };
  closeOrderRouteInfo = () => {
    this.setState({ data: null, orderRouteInfoOpen: false });
  };

  //근무중 라이더
  openRiderWorkListDialog = (status) => {
    this.setState({ riderWorkOpen: true, status });
  };

  closeRiderWorkListDialog = () => {
    this.setState({ riderWorkOpen: false });
  };

  //휴식중 라이더
  openRiderRestList = () => {
    this.setState({ riderRestOpen: true });
  };

  closeRiderRestList = () => {
    this.setState({ riderRestOpen: false });
  };

  openModifyDeliveryPriceModal = (order) => {
    this.setState({
      // data:order,
      data: { ...order, ftOrderCode: null, bmOrderCode: null },
      modifyDeliveryPrice: true,
    });
  };

  closeModifyDeliveryPriceModal = () => {
    this.setState(
      {
        modifyDeliveryPrice: false,
      },
      () => {
        // this.getList();
        global.getOrderList();
      }
    );
  };

  orderReregist = (row) => {
    let self = this;
    const newOrder = {
      ...row,
      orderDate: '',
      itemPrepared: false,
      ftOrderCode: null,
    };
    Modal.confirm({
      title: '주문 재등록',
      content: '주문을 재등록하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.orderCreate, [], newOrder)
          .then((res) => {
            if (res.result === 'SUCCESS') {
              if (res.data === 'SUCCESS') {
                registComplete();
                self.getList();
              } else if (res.data === 'NOT_ENOUGH_NCASH') {
                Modal.info({
                  title: '등록 오류',
                  content: '가맹점 예치금이 부족합니다.',
                });
              } else if (res.data === 'BRANCH_CLOSED') {
                Modal.info({
                  title: '등록 오류',
                  content: '가맹점 영업시간이 아닙니다.',
                });
              }
            } else {
              registError();
            }
          })
          .catch((e) => {
            registError();
          });
      },
    });
  };

  updateOrderStatus = (order, status) => {
    const prevStatus = order.orderStatus;
    switch (status) {
      case 1:
        if (prevStatus > 1) {
          order.assignDate = null;
          order.pickupDate = null;
          order.completeDate = null;
        }
        break;
      case 2:
        if (prevStatus > 2) {
          order.pickupDate = null;
          order.completeDate = null;
        }
        break;
      case 3:
        if (prevStatus > 4) {
          order.completeDate = null;
        }
        break;
    }

    order.orderStatus = status;
    this.props.updateOrder(order);

    console.log(`updateOrderStatus. state: ${prevStatus} -> ${status}`);
  };

  getDeliveryDataAsync = async (hideLoading) => {
    try {
      const res = await httpGetWithNoLoading(
        httpUrl.getBranch,
        [this.props.branchIdx],
        {},
        hideLoading
      );
      if (res.result !== 'SUCCESS') {
        console.log('getDeliveryDataAsync failed. result: ' + res.result);
        return res;
      }
      this.props.setBranchInfo(res.data);
      console.log('getDeliveryDataAsync success. result: ');
      console.log(res);
      return res;
    } catch (e) {
      console.warn('getDeliveryDataAsync raised exception. e: ' + e);
      return { result: 'EXCEPTION' };
    }
  };

  getFrByBranchSub = () => {
    try {
      let branchSubIdx =
        this.state.getBranchSub.length !== 0
          ? this.state.selectBranchSub.length === this.state.getBranchSub.length
            ? 0
            : this.state.getBranchSub
              .map((item) => item.idx)
              .filter((item) => !this.state.selectBranchSub.includes(item))
          : 0;
      const res = httpGet(httpUrl.searchFrByBranchSub, [branchSubIdx], {}).then(
        (res) =>
          this.setState(
            {
              branchSubList: res.data.list.map((item) => item.idx),
            },
            () => this.getList()
          )
      );
      return res;
    } catch (e) {
      return e;
    }
  };

  setShowCountAsync = async () => {
    let showCount = this.getControlValue(this.showCountRef.current);
    if (!showCount)
      showCount = this.getControlValue(this.showCountRefM.current);

    if (showCount > 100) {
      Modal.warn({
        title: '설정실패',
        content: '최대 100건까지 설정 가능합니다.',
      });
      return;
    }
    console.log({
      showCount: this.getControlValue(this.showCountRef.current),
    });
    const res = await httpPost(httpUrl.setShowCount, [], {
      showCount: this.getControlValue(this.showCountRef.current),
    });
    if (res.data === 'SUCCESS') {
      Modal.info({
        title: '설정성공',
        content: '기사노출건수 설정에 성공했습니다.',
      });
      this.getDeliveryDataAsync(true);
      this.showCountRef.current.state.value = null;
      this.showCountRefM.current.state.value = null;
      this.setState({ showListCount: showCount });
    } else {
      Modal.warn({
        title: '설정실패',
        content: '기사노출건수 설정에 실패했습니다.',
      });
    }
  };

  handleAssignedToControlOrders = () => {
    this.props.orderReducer.orders.forEach((order) => {
      console.log('handleAssignedToControlOrders');
      console.log(order.idx);
      console.log(this.props.idx);
      if (this.props.idx === order.userIdx && order.orderStatus === 2) {
        this.appendForceList(order);
      }
    });
  };

  assignOrderToControl = async (order) => {
    try {
      const assignOrderToControlRes = await httpPost(httpUrl.assignRider, [], {
        orderIdx: order.idx,
      });
      return assignOrderToControlRes;
    } catch (e) {
      return e;
    }
  };

  appendForceList = (row) => {
    if (this.state.forceList.findIndex((order) => order.idx === row.idx) > 0)
      return;
    if (row.orderStatus !== 1 && this.props.idx !== row.userIdx) {
      customError(
        '장바구니 담기 실패',
        '접수상태인 주문만 장바구니에 담을 수 있습니다.'
      );
      return;
    }
    this.assignOrderToControl(row).then((res) => {
      console.log(res);
      if (res.data === 'SUCCESS') {
        this.setState({
          forceList: this.state.forceList.concat(row),
        });
      } else {
        switch (res.data) {
          case 'ALREADY_ASSIGNED':
            customError('장바구니 담기 실패', '이미 배차된 주문입니다.');
            break;
          case 'ORDER_NOT_EXISTS':
            customError('장바구니 담기 실패', '존재하지 않은 주문입니다.');
            break;
          default:
            customError(
              '배차 오류',
              '배차에 실패했습니다. 관리자에게 문의하세요.'
            );
            break;
        }
      }
    });
  };

  assignCancelFromControl = async (order) => {
    try {
      const assignCancelFromControlRes = await httpPost(
        httpUrl.orderAssignCancel,
        [],
        { orderIdx: order.idx }
      );
      return assignCancelFromControlRes;
    } catch (e) {
      return e;
    }
  };

  removeForceList = (row) => {
    this.setState({
      forceList: this.state.forceList.filter(
        (order) => order.idx !== row.idx
      ),
    });
  };

  removeForceListByAssignCancel = (row) => {
    this.assignCancelFromControl(row).then((res) => {
      if (res.data === 'SUCCESS') {
        this.removeForceList(row);
      } else {
        Modal.warn({
          title: '빼기 실패',
          content: '장바구니 빼기에 실패했습니다.',
        });
      }
    });
  };
  removeForceListAlreadyCanceled = (row) => {
    this.removeForceList(row);
  }

  setInitialSetting = () => {
    console.log(
      'setInitialSetting = alarmVoice ? ' + this.state.selectedVoiceUpdate
    );

    this.props.setSetting(
      Object.keys(this.getVoiceChecked()).length !== 0
        ? this.getVoiceChecked()
        : this.state.selectedVoiceUpdate
    );
    this.setState({
      selectedVoiceUpdate: this.getVoiceChecked(),
    });
  };

  handleUpdateVoice = (checkedValues) => {
    console.log(
      'handleUpdateVoice = checkedValues ? ' + JSON.stringify(checkedValues)
    );
    this.setVoiceChecked(checkedValues);

    this.setState({ selectedVoiceUpdate: checkedValues });
    // reactLocalStorage.setObject(Const.appName + "#updateVoice", checkedValues);
    this.props.setSetting(checkedValues);
  };

  handleBranchSub = (checkedValues) => {
    console.log(
      'handleBranchSub = checkedValues ? ' + JSON.stringify(checkedValues)
    );
    this.setBranchSubChecked(checkedValues);

    this.setState({ selectBranchSub: checkedValues }, () => {
      this.getFrByBranchSub();
    });
    // , () => {this.getList()});
  };

  handleRefreshOrderOn = (checked) => {
    this.setState(
      {
        refreshOrderOn: checked,
      },
      () => {
        if (!checked) {
          this.refreshOrderOnFlagIdx =
            this.state.list.length > 0 ? this.state.list[0].idx : 0;
          console.log('refreshOrderOnFlagIdx');
          console.log(this.state.list.length > 0 ? this.state.list[0].idx : 0);
        } else {
          this.refreshOrderOnFlagIdx = null;
        }
      }
    );
  };

  getBranch = () => {
    httpGet(httpUrl.getBranch, [this.props.branchIdx], {})
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data) {
          console.log(res);
          this.setState({ branchInfo: res.data }, () => {
            const branchInfo = this.state.branchInfo;
            this.setState({
              checkedAddrUpdateCall: res.data.addrUpdateEnabled,
              delayPickupTime: res.data.delayPickupTime,
              deliveryEnabled: res.data.deliveryEnabled,
              selectedOrderSort: res.data.sortOrder,
              allowAssignCancel: res.data.allowAssignCancel,
              assignCancelPenaltyUnit: res.data.assignCancelPenaltyUnit,
              assignCancelPenaltyLimit: res.data.assignCancelPenaltyLimit,
              redOrderShowSetting: res.data.redOrderShowSetting,
            });
          });
        } else {
          console.log('branchInfo error');
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleToggledAddrUpdateCall = () => {
    httpPost(httpUrl.updateBranch, [], {
      idx: this.props.branchIdx,
      addrUpdateEnabled: this.state.checkedAddrUpdateCall ? 0 : 1,
      delayPickupTime: this.state.delayPickupTime,
      deliveryEnabled: this.state.deliveryEnabled,
    })
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          this.setState({
            checkedAddrUpdateCall: !this.state.checkedAddrUpdateCall,
          });
        } else {
          Modal.info({
            title: '적용 오류',
            content: '처리가 실패했습니다.',
          });
          console.log(res);
        }
      })
      .catch((e) => {
        Modal.info({
          title: '적용 오류',
          content: '처리가 실패했습니다.',
        });
      });
  };

  handlePagesChange = (current, pageSize) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          current: current,
          pageSize: pageSize,
        },
      },
      () => this.getList()
    );
  };

  setAssignCancelSettings = async () => {
    try {
      const assignCancelPenaltyUnitRefValue = this.assignCancelPenaltyUnitRef.current.state.value;
      const assignCancelPenaltyLimitRefValue = this.assignCancelPenaltyLimitRef.current.state.value;

      /**input 안에 숫자가 아닌값을 넣어도 undefined로 정의됨.*/
      if (assignCancelPenaltyUnitRefValue === undefined && assignCancelPenaltyLimitRefValue === undefined) {
        Modal.info({
          title: '입력 금액 오류',
          content: '페널티값을 확인해주세요.',
        });
        return;
      }
      if (parseInt(assignCancelPenaltyUnitRefValue) < 0) {
        Modal.info({
          title: '입력 금액 오류',
          content: '1분당 페널티는 마이너스값이나 숫자가 아닌 값이 될 수 없습니다.',
        });
        return;
      }
      if (parseInt(assignCancelPenaltyLimitRefValue) < 0) {
        Modal.info({
          title: '입력 금액 오류',
          content: '페널티 최대값은 마이너스값이나 숫자가 아닌 값이 될 수 없습니다.',
        });
        return;
      }
      const res = await httpPost(httpUrl.setAssignCancelSettings, [], {
        allowAssignCancel: this.allowAssignCancelRef.current.state.checked,
        assignCancelPenaltyUnit: assignCancelPenaltyUnitRefValue,
        assignCancelPenaltyLimit: assignCancelPenaltyLimitRefValue,
      });

      console.log('setAssignCancelSettings res');
      console.log(res);

      if (res.data === 'SUCCESS') {
        Modal.info({
          title: '변경완료',
          content: '배차취소 설정 변경에 성공했습니다.',
        });
        /**여기는 null을 대입하는 부분이기 때문에
         * 위에 상수 assignCancelPenaltyUnitRef, assignCancelPenaltyLimitRef로 대체하면 안됨!! */
        this.assignCancelPenaltyUnitRef.current.state.value = null;
        this.assignCancelPenaltyLimitRef.current.state.value = null;
        this.getBranch();
      } else {
        Modal.warn({
          title: '변경실패',
          content: '배차취소 설정 변경에 실패했습니다.',
        });
      }
    } catch (e) {
      Modal.warn({
        title: '변경실패',
        content: '배차취소 설정 변경에 실패했습니다.',
      });
    }
  };

  getZeroFrList = () => {
    const BEFORE_DATE = reactLocalStorage.getObject(Const.appName + '#zeroCallFrDate');
    const NOW_DATE = moment(new Date()).format("YYYY-MM-DD");
    const NOW_HOURS = Math.floor(new Date().getHours());

    if (
      (Object.keys(BEFORE_DATE).length === 0 &&
        NOW_HOURS >= 6) ||
      (BEFORE_DATE !== null &&
        Object.keys(BEFORE_DATE).length !== 0 &&
        !BEFORE_DATE.includes(NOW_DATE) &&
        NOW_HOURS >= 6)
    ) {
      reactLocalStorage.remove(Const.appName + '#zeroCallFrDate');

      const expiry = moment(new Date()).format('YYYY-MM-DD HH:mm');
      reactLocalStorage.setObject(Const.appName + '#zeroCallFrDate', expiry);

      this.setState({
        openZeroFrList: true
      });
    }
  };

  closeZeroFrList = () => {
    this.setState({
      openZeroFrList: false,
    }, () => this.getList());
  };

  getZeroRiderList = () => {
    const BEFORE_DATE = reactLocalStorage.getObject(Const.appName + '#zeroCallRiderDate');
    const NOW_DATE = moment(new Date()).format("YYYY-MM-DD");
    const NOW_HOURS = Math.floor(new Date().getHours());

    if (
      (Object.keys(BEFORE_DATE).length === 0 &&
        NOW_HOURS >= 6) ||
      (BEFORE_DATE !== null &&
        Object.keys(BEFORE_DATE).length !== 0 &&
        !BEFORE_DATE.includes(NOW_DATE) &&
        NOW_HOURS >= 6)
    ) {
      reactLocalStorage.remove(Const.appName + '#zeroCallRiderDate');

      const expiry = moment(new Date()).format('YYYY-MM-DD HH:mm');
      reactLocalStorage.setObject(Const.appName + '#zeroCallRiderDate', expiry);

      this.setState({
        openZeroRiderList: true
      });
    }
  };

  closeZeroRiderList = () => {
    this.setState({
      openZeroRiderList: false,
    }, () => this.getList());
  };

  render() {
    console.log('render');
    const companyId = this.props.branchInfo.companyId;
    const columns = [
      {
        title: (
          <Checkbox
            style={{ color: 'white' }}
            checked={this.state.refreshOrderOn}
            onChange={(e) => this.handleRefreshOrderOn(e.target.checked)}>
            갱신
          </Checkbox>
        ),
        dataIndex: 'reRegist',
        className: 'table-column-center desk',
        width: 150,
        key: (row) => `reRegist:${row.reRegist}`,
        render: (data, row) => (
          <span>
            <Button
              className="tabBtn-rt"
              onClick={() => {
                this.openModifyRegistModal(row);
              }}>
              재접수
            </Button>
            {row.orderStatus === 4 && (
              <Button
                onClick={() => {
                  this.openModifyDeliveryPriceModal(row);
                }}
                className="tabBtn-rt"
                style={{ letterSpacing: '-2px' }}>
                배송비변경
              </Button>
            )}
          </span>
        ),
      },
      {
        title: '번호',
        dataIndex: 'idx',
        className: 'table-column-center desk',
        width: 50,
        // key: (row) => `idx:${row.idx}`,
        //sorter: (a, b) => a.idx - b.idx,
        sorter: !this.state.checkedCompleteCall,
        // defaultSortOrder: 'descend',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '상태',
        dataIndex: 'orderStatus',
        className: 'table-column-center desk',
        width: 40,
        key: (row) => `orderStatus:${row.orderStatus}`,
        render: (data, row) => (
          <div className="table-column-sub">
            <Select
              defaultValue={data}
              value={row.orderStatus}
              onClick={(e) => e.stopPropagation()}
              onChange={(value) => {
                if (!modifyType[row.orderStatus].includes(value)) {
                  Modal.info({
                    content: <div>상태를 바꿀 수 없습니다.</div>,
                  });
                  return;
                }
                // 대기중 -> 픽업중 변경 시 추천배차 알림
                if (row.orderStatus === 1 && value === 2) {
                  Modal.info({
                    content: <div>추천배차를 사용하세요.</div>,
                  });
                  return;
                }
                // 픽업 -> 접수 변경 시 배차상태로 변경 알림
                if (row.orderStatus === 3 && value === 1) {
                  Modal.info({
                    content: (
                      <div>배차상태로 먼저 변경한 후 접수로 변경해주세요.</div>
                    ),
                  });
                  return;
                }

                //완료를 복원시키는 경우
                if (row.orderStatus === 4 && value === 3) {
                  const self = this;
                  Modal.confirm({
                    title: '주문복구',
                    content:
                      '주문을 복구하는 경우 라이더에게 지급된 가맹점 배달료도 복구됩니다. 정말 복구하시겠습니까?',
                    okText: '확인',
                    cancelText: '취소',
                    onOk() {
                      httpPost(httpUrl.orderCompleteRestore, [], {
                        orderIdx: row.idx,
                      }).then((res) => {
                        if (
                          res.result === 'SUCCESS' &&
                          res.data === 'SUCCESS'
                        ) {
                          self.getList();
                        } else if (res.data === 'NOT_CHANGE_STATUS_7_DAYS') {
                          Modal.info({
                            title: '변경 실패',
                            content: '40일 전 주문은 복원 불가합니다.',
                          });
                        } else {
                          Modal.info({
                            title: '변경 실패',
                            content: '주문상태 변경에 실패했습니다.',
                          });
                        }
                      });
                    },
                  });
                  global.orderUpdateCallback && global.orderUpdateCallback();
                  return;
                } else if (row.orderStatus === 5 && value === 1) {
                  const self = this;
                  Modal.confirm({
                    title: '주문복구',
                    content: '취소된 주문을 복구하시겠습니까?',
                    okText: '확인',
                    cancelText: '취소',
                    onOk() {
                      httpPost(httpUrl.orderCancelRestore, [], {
                        orderIdx: row.idx,
                      }).then((res) => {
                        if (
                          res.result === 'SUCCESS' &&
                          res.data === 'SUCCESS'
                        ) {
                          self.getList();
                        } else {
                          Modal.info({
                            title: '변경 실패',
                            content: '주문상태 변경에 실패했습니다.',
                          });
                        }
                      });
                    },
                  });
                  global.orderUpdateCallback && global.orderUpdateCallback();
                  return;
                }
                const self = this;
                const orderStatuseChangeApiCode = [
                  '',
                  httpUrl.orderAssignCancel,
                  httpUrl.orderPickupCancel,
                  httpUrl.orderPickup,
                  httpUrl.orderComplete,
                  httpUrl.orderCancel,
                ];

                httpPost(orderStatuseChangeApiCode[value], [], {
                  orderIdx: row.idx,
                })
                  .then((res) => {
                    if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
                      this.getList();
                      this.removeForceListAlreadyCanceled(row);
                      global.orderUpdateCallback &&
                        global.orderUpdateCallback();
                    } else {
                      Modal.info({
                        title: '변경 실패',
                        content: '주문상태 변경에 실패했습니다.',
                      });
                    }
                  })
                  .catch((e) => {
                    if (
                      e.response.data.status ===
                      HttpResponseStatusCode.SERVICE_UNAVAILABLE
                    ) {
                      // 상태변경 이중처리 시 에러코드 처리
                      Modal.warn({
                        title: '상태변경 실패',
                        content: '이미 처리된 주문입니다.',
                      });
                      // 목록 새로고침
                      global.getOrderList && global.getOrderList();
                      return;
                    }

                    Modal.info({
                      title: '변경 실패',
                      content: '주문상태 변경에 실패했습니다.',
                    });

                    throw e;
                  });
              }}>
              {deliveryStatusCode.map((value, index) => {
                if (index === 0) return <></>;
                else
                  return (
                    <Option key={index} value={index}>
                      {value}
                    </Option>
                  );
              })}
            </Select>
          </div>
        ),
      },
      {
        title: '대기',
        className: 'table-column-center desk',
        width: 40,
        render: (data, row) => (
          <div>
            {(row.orderStatus === 1 || row.orderStatus === 2) && (
              <button
                onClick={(e) => {
                  this.changeWaiting(row);
                  e.stopPropagation();
                }}>
                {row.waitingStatus === 0 ? 'OFF' : 'ON'}
              </button>
            )}
          </div>
        ),
      },
      {
        title: (
          <div>
            <div>
            <Button
              onClick={() => {
                this.state.forceList.length > 0
                  ? this.openForceModal(null)
                  : alert('선택된 주문이 없습니다.');
              }}>
              일괄배차({this.state.forceList.length})
            </Button>
              <div>배차</div>
            </div>
          </div>
        ),
        dataIndex: 'forceLocate',
        className: 'table-column-center desk',
        width: 90,
        key: (row) => `forceLocate:${row.idx}`,
        render: (data, row) => (
          <span>
            <Button
              className="tabBtn-rt"
              onClick={(e) => {
                this.openForceModal(row);
                e.stopPropagation();
              }}>
              추천배차
            </Button>
            {this.state.forceList.findIndex((order) => order.idx === row.idx) >
              -1 &&
              row.orderStatus === 2 &&
              row.userIdx === this.props.idx ? (
              <Button
                className="tabBtn-rt"
                onClick={(e) => {
                  this.removeForceListByAssignCancel(row);
                  e.stopPropagation();
                }}
                style={{ fontWeight: 'bold' }}
                type="danger">
                -
              </Button>
            ) : (row.orderStatus === 1 && !row.waitingStatus) ? (
              <Button
                className="tabBtn-rt"
                onClick={(e) => {
                  this.appendForceList(row);
                  e.stopPropagation();
                }}
                style={{ fontWeight: 'bold' }}>
                +
              </Button>
            ) : <span className="pl-[18px]"></span>}
          </span>
        ),
      },
      {
        title: '요청시간',
        dataIndex: 'arriveReqTime',
        className: 'table-column-center desk',
        width: 56,
        // key: (row) => `arriveReqTime:${row.arriveReqTime}`,
        //sorter: (a, b) => a.arriveReqTime - b.arriveReqTime,
        sorter: !this.state.checkedCompleteCall,
        render: (data) => (
          <div>{data > 1000 ? '후 ' + (data % 1000) + '분' : data + '분'}</div>
        ),
      },
      {
        title: '남은시간',
        dataIndex: 'remainTime',
        className: 'table-column-center desk',
        width: 56,
        key: (row) => `remainTime:${row.idx}`,
        // sorter: (a, b) =>
        //   remainTime(a.orderDate, a.arriveReqTime) -
        //   remainTime(b.orderDate, b.arriveReqTime),
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => {
          if (row.orderStatus == 5) return <div></div>;
          //취소는 남은시간 없음
          else if (row.orderStatus == 4) {
            //완료는 요청시간에서 완료시간까지 계산
            if (row.arriveReqTime > 1000) {
              //배차후 주문 처리
              const arriveReqDate = moment(row.assignDate).add(
                row.arriveReqTime % 1000,
                'minutes'
              );
              const time = arriveReqDate.diff(
                moment(row.completeDate),
                'minutes'
              );
              return <div>{time}분</div>;
            } else {
              const arriveReqDate = moment(row.orderDate).add(
                row.arriveReqTime,
                'minutes'
              );
              const time = arriveReqDate.diff(
                moment(row.completeDate),
                'minutes'
              );
              return <div>{time}분</div>;
            }
          } else {
            //진행중
            if (row.arriveReqTime > 1000) {
              //배차후 주문 처리
              if (row.orderStatus == 1) return <div></div>;
              else
                return (
                  <div>
                    {remainTime(row.assignDate, row.arriveReqTime % 1000)}분
                  </div>
                );
            } else
              return (
                <div>{remainTime(row.orderDate, row.arriveReqTime)}분</div>
              );
          }
        },
      },
      {
        title: '음식준비',
        dataIndex: 'itemPrepared',
        className: 'table-column-center desk',
        width: 56,
        // key: (row) => `itemPrepared:${row.itemPrepared}`,
        //sorter: (a, b) => a.itemPrepared - b.itemPrepared,
        sorter: !this.state.checkedCompleteCall,
        render: (data) => <div>{data ? '완료' : '준비중'}</div>,
      },

      {
        title: '지점',
        dataIndex: 'branchIdx',
        className: 'table-column-center desk',
        width: 70,
        // key: (row) => `frName:${row.frName}`,
        //sorter: (a, b) => a.frName.localeCompare(b.frName),
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => {
          let name = '-';
          const obj = getBranchNameList().find(x=>x.IDX == data);
          if (obj) name = obj.BRANCH_NAME;
          return (
              <div>
                {name}
              </div>
          );
        },
      },

      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center desk',
        width: 200,
        // key: (row) => `frName:${row.frName}`,
        //sorter: (a, b) => a.frName.localeCompare(b.frName),
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => {
          const content = (
            <div>
              <p>{row.frPhone}</p>
            </div>
          );
          return (
            <Popover content={content} title="가맹점연락처">
              <div>
                {data}{' '}
                {row.controlMemo &&
                  row.controlMemo !== '' &&
                  `[${row.controlMemo}]`}
              </div>
            </Popover>
          );
        },
      },
      {
        title: '도착지',
        dataIndex: 'destAddr',
        className: 'table-column-center desk',
        width: 330,
        // key: (row) => `destAddr3:${row.destAddr3}`,
        // sorter: (a, b) =>
        //   (a.destAddr3 + a.destAddr2).localeCompare(b.destAddr3 + b.destAddr2),
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => (
          <div
            // onClick={() => this.openOrderRouteInfo(row)}
            className="arriveArea">
            {this.state.checkedCompleteCall &&
              (checkCompleteDateExpired(row.completeDate) ||
                checkCompleteDateExpired(row.cancelDate))
              ? '***'
              : dosiguCodeReplace(row.destAddr3) +
              ' ' +
              dosiguCodeReplace(row.destAddr2)}
          </div>
        ),
      },
      {
        title: '기사',
        dataIndex: 'riderName',
        className: 'table-column-center desk',
        width: 80,
        // sorter: (a, b) => {
        //   const dataA = (a.riderName || "").trim();
        //   const dataB = (b.riderName || "").trim();
        //   return dataA.localeCompare(dataB);
        // },
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => {
          const content = (
            <div>
              <p>{row.riderPhone}</p>
            </div>
          );
          return (
            <Popover content={content} title="기사연락처">
              <div>{row.orderStatus >= 2 ? data : '-'} </div>
            </Popover>
          );
        },
      },

      // antd 찾아봐야 될 듯
      {
        title: '가격',
        dataIndex: 'orderPrice',
        className: 'table-column-center desk',
        width: 50,
        // key: (row) => `orderPrice:${row.orderPrice}`,
        //sorter: (a, b) => a.orderPrice - b.orderPrice,
        sorter: !this.state.checkedCompleteCall,
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '배달요금',
        dataIndex: 'deliveryPrice',
        className: 'table-column-center desk',
        width: 150,
        // key: (row) => `deliveryPrice:${row.deliveryPrice}`,
        //sorter: (a, b) => a.deliveryPrice - b.deliveryPrice,
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => {
          const content = (
            <div>
              <p>배달요금(기본배달료+할증배달료+건별가맹비), 건별수수료, 할증건별수수료</p>
            </div>
          );
          return (
            <Popover content={content} title="배달요금 세부항목">
              <div>
                {comma(data)} (
                {comma(row.basicDeliveryPrice - row.duesDeliveryPrice)} +{' '}
                {comma(row.extraDeliveryPrice)} +{' '}
                {comma(row.duesDeliveryPrice)}
                ),{' '}
                {row.deliveryPriceFeeByDpBasic
                  ? comma(row.deliveryPriceFeeByDpBasic)
                  : 0}
                ,{' '}
                {row.deliveryPriceFeeByDpRegion
                  ? comma(row.deliveryPriceFeeByDpRegion)
                  : 0}
              </div>
            </Popover>
          );
        },

      },
      {
        title: '배달지원금',
        dataIndex: 'deliverySubsidy',
        className: 'table-column-center desk',
        width: 70,
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '결제',
        dataIndex: 'orderPayments',
        className: 'table-column-center desk',
        width: 40,
        key: (row) => `orderPayments:${row.orderPayments}`,
        render: (data, row) => {
          const authTypeString = ['승인', '취소'];
          const content = row.cardPayments && row.cardPayments.length > 0 && (
            <div>
              {row.cardPayments.map((item) => (
                <div key={item.trxId}>
                  {item.createDate} [{authTypeString[item.authType]}]{' '}
                  {item.authNum}
                </div>
              ))}
            </div>
          );
          return row.cardPayments && row.cardPayments.length > 0 ? (
            <Popover content={content} title="승인번호">
              {data.length > 1 ? (
                <Button
                  onClick={() => this.openPaymentModal(data, row)}
                // close={this.closePaymentModal}
                >
                  보기
                </Button>
              ) : (
                <div>
                  {paymentMethod[data[0] ? data[0]['paymentMethod'] : 0]}
                </div>
              )}
            </Popover>
          ) : data.length > 1 ? (
            <Button
              onClick={() => this.openPaymentModal(data, row)}
            // close={this.closePaymentModal}
            >
              보기
            </Button>
          ) : (
            <div>{paymentMethod[data[0] ? data[0]['paymentMethod'] : 0]}</div>
          );
        },
      },

      {
        title: '거리(m)',
        dataIndex: 'distance',
        className: 'table-column-center desk',
        width: 46,

        key: (row) => `distance:${row.distance}`,
        render: (data, row) => <div>{comma(data)}</div>,
      },
      // {
      //   title: "결제방식",
      //   dataIndex: "orderPayments",
      //   className: "table-column-center desk",
      //   width: 100,

      //   key: (row) => `orderPayments:${row.idx}`,
      //   render: (data, row) =>
      //     data.length > 1 ? (
      //       <>
      //         <Button
      //           onClick={() => {
      //             this.openPaymentModal(data, row);
      //           }}
      //           close={this.clos}
      //         >
      //           보기
      //         </Button>
      //       </>
      //     ) : (
      //       <div>{paymentMethod[data[0] ? data[0]["paymentMethod"] : 0]}</div>
      //     ),
      // },

      {
        title: '이력보기',
        dataIndex: '',
        className: 'table-column-center desk',
        width: 50,

        key: (row) => `orderLog:${row.idx}`,
        render: (data, row) => (
          <span>
            <Button
              className="tabBtn-rt"
              onClick={(e) => {
                this.openOrderLogModal(row);
                e.stopPropagation();
              }}>
              이력보기
            </Button>
          </span>
        ),
      },
      {
        title: '메세지',
        dataIndex: 'franchisePhoneNum',
        className: 'table-column-center desk',
        width: 110,
        key: (row) => `franchisePhoneNum:${row.franchisePhoneNum}`,
        render: (data, row) => (
          <span>
            <Button
              disabled={row.orderStatus === 1 || row.userIdx === this.props.idx}
              className="tabBtn-rt"
              onClick={(e) => {
                e.stopPropagation();
                console.log(row);
                this.openDirectMessageModal(
                  row.userIdx,
                  row.riderName,
                  row.riderLevel
                );
              }}>
              라이더
            </Button>
            <Button
              className="tabBtn-rt"
              onClick={(e) => {
                e.stopPropagation();
                this.openDirectMessageModal(row.frIdx, row.frName);
              }}>
              가맹점
            </Button>
          </span>
        ),
      },
      {
        title: '접수시간',
        dataIndex: 'orderDate',
        className: 'table-column-center desk',
        width: 110,
        // key: (row) => `orderDate:${row.orderDate}`,
        //sorter: (a, b) => moment(a.orderDate) - moment(b.orderDate),
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => <div>{formatMDHMS(data)}</div>,
      },

      {
        title: '배차시간',
        dataIndex: 'assignDate',
        className: 'table-column-center desk',
        width: 110,
        // key: (row) => `assignDate:${row.assignDate}`,
        // sorter: (a, b) => moment(a.assignDate) - moment(b.assignDate),
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => <div>{row.orderStatus >= 2 ? formatMDHMS(data) : '-'}</div>,
      },
      {
        title: '픽업시간',
        dataIndex: 'pickupDate',
        className: 'table-column-center desk',
        width: 100,
        // key: (row) => `pickupDate:${row.pickupDate}`,
        //sorter: (a, b) => moment(a.pickupDate) - moment(b.pickupDate),
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => (
          <div>{row.orderStatus >= 3 ? formatMDHMS(data) : '-'}</div>
        ),
      },
      {
        title: '완료시간',
        dataIndex: 'completeDate',
        className: 'table-column-center desk',
        width: 100,
        // key: (row) => `completeDate:${row.completeDate}`,
        //sorter: (a, b) => moment(a.completeDate) - moment(b.completeDate),
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => (
          <div>{row.orderStatus >= 4 ? formatMDHMS(data) : '-'}</div>
        ),
      },
      {
        title: '외부연동코드',
        dataIndex: 'ftOrderCode',
        className: 'table-column-center desk',
        width: 100,
        render: (data, row) =>
          data && row.bmOrderCode ? (
            <Popover
              content={`파트너사 : ${data} / 배민 : ${row.bmOrderCode}`}
              title="외부연동코드">
              <div>{data}</div>
            </Popover>
          ) : (
            <div>{data ? data : row.bmOrderCode ? row.bmOrderCode : row.memo === 'BLICT' ? '영수증' : '-'}</div>
          ),
      },

      {
        title: '수정',
        dataIndex: 'updateOrder',
        className: 'table-column-center desk',
        width: 50,
        key: (row) => `updateOrder:${row.updateOrder}`,
        render: (data, row) => (
          <Button
            className="tabBtn-rt"
            onClick={(e) => {
              e.stopPropagation();
              this.openModifyOrderModal(row);
            }}>
            수정
          </Button>
        ),
      },
    ];

    const columnsByConnect9 = [
      {
        title: (
          <Checkbox
            style={{ color: 'white' }}
            checked={this.state.refreshOrderOn}
            onChange={(e) => this.handleRefreshOrderOn(e.target.checked)}>
            갱신
          </Checkbox>
        ),
        dataIndex: 'reRegist',
        className: 'table-column-center desk',
        width: 150,
        key: (row) => `reRegist:${row.reRegist}`,
        render: (data, row) => (
            <span>
            <Button
                className="tabBtn-rt"
                onClick={() => {
                  this.openModifyRegistModal(row);
                }}>
              재접수
            </Button>
              {row.orderStatus === 4 && (
                  <Button
                      onClick={() => {
                        this.openModifyDeliveryPriceModal(row);
                      }}
                      className="tabBtn-rt"
                      style={{ letterSpacing: '-2px' }}>
                    배송비변경
                  </Button>
              )}
          </span>
        ),
      },
      {
        title: '번호',
        dataIndex: 'idx',
        className: 'table-column-center desk',
        width: 50,
        // key: (row) => `idx:${row.idx}`,
        //sorter: (a, b) => a.idx - b.idx,
        sorter: !this.state.checkedCompleteCall,
        // defaultSortOrder: 'descend',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '상태',
        dataIndex: 'orderStatus',
        className: 'table-column-center desk',
        width: 40,
        key: (row) => `orderStatus:${row.orderStatus}`,
        render: (data, row) => (
            <div className="table-column-sub">
              <Select
                  defaultValue={data}
                  value={row.orderStatus}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(value) => {
                    if (!modifyType[row.orderStatus].includes(value)) {
                      Modal.info({
                        content: <div>상태를 바꿀 수 없습니다.</div>,
                      });
                      return;
                    }
                    // 대기중 -> 픽업중 변경 시 추천배차 알림
                    if (row.orderStatus === 1 && value === 2) {
                      Modal.info({
                        content: <div>추천배차를 사용하세요.</div>,
                      });
                      return;
                    }
                    // 픽업 -> 접수 변경 시 배차상태로 변경 알림
                    if (row.orderStatus === 3 && value === 1) {
                      Modal.info({
                        content: (
                            <div>배차상태로 먼저 변경한 후 접수로 변경해주세요.</div>
                        ),
                      });
                      return;
                    }

                    //완료를 복원시키는 경우
                    if (row.orderStatus === 4 && value === 3) {
                      const self = this;
                      Modal.confirm({
                        title: '주문복구',
                        content:
                            '주문을 복구하는 경우 라이더에게 지급된 가맹점 배달료도 복구됩니다. 정말 복구하시겠습니까?',
                        okText: '확인',
                        cancelText: '취소',
                        onOk() {
                          httpPost(httpUrl.orderCompleteRestore, [], {
                            orderIdx: row.idx,
                          }).then((res) => {
                            if (
                                res.result === 'SUCCESS' &&
                                res.data === 'SUCCESS'
                            ) {
                              self.getList();
                            } else if (res.data === 'NOT_CHANGE_STATUS_7_DAYS') {
                              Modal.info({
                                title: '변경 실패',
                                content: '40일 전 주문은 복원 불가합니다.',
                              });
                            } else {
                              Modal.info({
                                title: '변경 실패',
                                content: '주문상태 변경에 실패했습니다.',
                              });
                            }
                          });
                        },
                      });
                      global.orderUpdateCallback && global.orderUpdateCallback();
                      return;
                    } else if (row.orderStatus === 5 && value === 1) {
                      const self = this;
                      Modal.confirm({
                        title: '주문복구',
                        content: '취소된 주문을 복구하시겠습니까?',
                        okText: '확인',
                        cancelText: '취소',
                        onOk() {
                          httpPost(httpUrl.orderCancelRestore, [], {
                            orderIdx: row.idx,
                          }).then((res) => {
                            if (
                                res.result === 'SUCCESS' &&
                                res.data === 'SUCCESS'
                            ) {
                              self.getList();
                            } else {
                              Modal.info({
                                title: '변경 실패',
                                content: '주문상태 변경에 실패했습니다.',
                              });
                            }
                          });
                        },
                      });
                      global.orderUpdateCallback && global.orderUpdateCallback();
                      return;
                    }
                    const self = this;
                    const orderStatuseChangeApiCode = [
                      '',
                      httpUrl.orderAssignCancel,
                      httpUrl.orderPickupCancel,
                      httpUrl.orderPickup,
                      httpUrl.orderComplete,
                      httpUrl.orderCancel,
                    ];

                    httpPost(orderStatuseChangeApiCode[value], [], {
                      orderIdx: row.idx,
                    })
                    .then((res) => {
                      if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
                        this.getList();
                        global.orderUpdateCallback &&
                        global.orderUpdateCallback();
                      } else {
                        Modal.info({
                          title: '변경 실패',
                          content: '주문상태 변경에 실패했습니다.',
                        });
                      }
                    })
                    .catch((e) => {
                      if (
                          e.response.data.status ===
                          HttpResponseStatusCode.SERVICE_UNAVAILABLE
                      ) {
                        // 상태변경 이중처리 시 에러코드 처리
                        Modal.warn({
                          title: '상태변경 실패',
                          content: '이미 처리된 주문입니다.',
                        });
                        // 목록 새로고침
                        global.getOrderList && global.getOrderList();
                        return;
                      }

                      Modal.info({
                        title: '변경 실패',
                        content: '주문상태 변경에 실패했습니다.',
                      });

                      throw e;
                    });
                  }}>
                {deliveryStatusCode.map((value, index) => {
                  if (index === 0) return <></>;
                  else
                    return (
                        <Option key={index} value={index}>
                          {value}
                        </Option>
                    );
                })}
              </Select>
            </div>
        ),
      },
      {
        title: '대기',
        className: 'table-column-center desk',
        width: 40,
        render: (data, row) => (
            <div>
              {(row.orderStatus === 1 || row.orderStatus === 2) && (
                  <button
                      onClick={(e) => {
                        this.changeWaiting(row);
                        e.stopPropagation();
                      }}>
                    {row.waitingStatus === 0 ? 'OFF' : 'ON'}
                  </button>
              )}
            </div>
        ),
      },
      {
        title: (
          <div>
            <div>
            <Button
              onClick={() => {
                this.state.forceList.length > 0
                  ? this.openForceModal(null)
                  : alert('선택된 주문이 없습니다.');
              }}>
              일괄배차({this.state.forceList.length})
            </Button>
              <div>배차</div>
            </div>
          </div>
        ),
        dataIndex: 'forceLocate',
        className: 'table-column-center desk',
        width: 90,
        key: (row) => `forceLocate:${row.idx}`,
        render: (data, row) => (
            <span>
            <Button
                className="tabBtn-rt"
                onClick={(e) => {
                  this.openForceModal(row);
                  e.stopPropagation();
                }}>
              추천배차
            </Button>
              {this.state.forceList.findIndex((order) => order.idx === row.idx) >
              -1 &&
              row.orderStatus === 2 &&
              row.userIdx === this.props.idx ? (
                  <Button
                      className="tabBtn-rt"
                      onClick={(e) => {
                        this.removeForceListByAssignCancel(row);
                        e.stopPropagation();
                      }}
                      style={{ fontWeight: 'bold' }}
                      type="danger">
                    -
                  </Button>
              ) : (row.orderStatus === 1 && !row.waitingStatus) ? (
                <Button
                  className="tabBtn-rt"
                  onClick={(e) => {
                    this.appendForceList(row);
                    e.stopPropagation();
                  }}
                  style={{ fontWeight: 'bold' }}>
                  +
                </Button>
              ) : <span className="pl-[18px]"></span>}
          </span>
        ),
      },
      {
        title: '요청시간',
        dataIndex: 'arriveReqTime',
        className: 'table-column-center desk',
        width: 56,
        // key: (row) => `arriveReqTime:${row.arriveReqTime}`,
        //sorter: (a, b) => a.arriveReqTime - b.arriveReqTime,
        sorter: !this.state.checkedCompleteCall,
        render: (data) => (
            <div>{data > 1000 ? '후 ' + (data % 1000) + '분' : data + '분'}</div>
        ),
      },
      {
        title: '남은시간',
        dataIndex: 'remainTime',
        className: 'table-column-center desk',
        width: 56,
        key: (row) => `remainTime:${row.idx}`,
        // sorter: (a, b) =>
        //   remainTime(a.orderDate, a.arriveReqTime) -
        //   remainTime(b.orderDate, b.arriveReqTime),
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => {
          if (row.orderStatus == 5) return <div></div>;
          //취소는 남은시간 없음
          else if (row.orderStatus == 4) {
            //완료는 요청시간에서 완료시간까지 계산
            if (row.arriveReqTime > 1000) {
              //배차후 주문 처리
              const arriveReqDate = moment(row.assignDate).add(
                  row.arriveReqTime % 1000,
                  'minutes'
              );
              const time = arriveReqDate.diff(
                  moment(row.completeDate),
                  'minutes'
              );
              return <div>{time}분</div>;
            } else {
              const arriveReqDate = moment(row.orderDate).add(
                  row.arriveReqTime,
                  'minutes'
              );
              const time = arriveReqDate.diff(
                  moment(row.completeDate),
                  'minutes'
              );
              return <div>{time}분</div>;
            }
          } else {
            //진행중
            if (row.arriveReqTime > 1000) {
              //배차후 주문 처리
              if (row.orderStatus == 1) return <div></div>;
              else
                return (
                    <div>
                      {remainTime(row.assignDate, row.arriveReqTime % 1000)}분
                    </div>
                );
            } else
              return (
                  <div>{remainTime(row.orderDate, row.arriveReqTime)}분</div>
              );
          }
        },
      },
      {
        title: '음식준비',
        dataIndex: 'itemPrepared',
        className: 'table-column-center desk',
        width: 56,
        // key: (row) => `itemPrepared:${row.itemPrepared}`,
        //sorter: (a, b) => a.itemPrepared - b.itemPrepared,
        sorter: !this.state.checkedCompleteCall,
        render: (data) => <div>{data ? '완료' : '준비중'}</div>,
      },

      {
        title: '지점',
        dataIndex: 'branchIdx',
        className: 'table-column-center desk',
        width: 70,
        // key: (row) => `frName:${row.frName}`,
        //sorter: (a, b) => a.frName.localeCompare(b.frName),
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => {
          let name = '-';
          const obj = getBranchNameList().find(x=>x.IDX == data);
          if (obj) name = obj.BRANCH_NAME;
          return (
              <div>
                {name}
              </div>
          );
        },
      },

      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center desk',
        width: 200,
        // key: (row) => `frName:${row.frName}`,
        //sorter: (a, b) => a.frName.localeCompare(b.frName),
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => {
          const content = (
              <div>
                <p>{row.frPhone}</p>
              </div>
          );
          return (
              <Popover content={content} title="가맹점연락처">
                <div>
                  {data}{' '}
                  {row.controlMemo &&
                      row.controlMemo !== '' &&
                      `[${row.controlMemo}]`}
                </div>
              </Popover>
          );
        },
      },
      {
        title: '도착지',
        dataIndex: 'destAddr',
        className: 'table-column-center desk',
        width: 330,
        // key: (row) => `destAddr3:${row.destAddr3}`,
        // sorter: (a, b) =>
        //   (a.destAddr3 + a.destAddr2).localeCompare(b.destAddr3 + b.destAddr2),
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => (
            <div
                // onClick={() => this.openOrderRouteInfo(row)}
                className="arriveArea">
              {this.state.checkedCompleteCall &&
              (checkCompleteDateExpired(row.completeDate) ||
                  checkCompleteDateExpired(row.cancelDate))
                  ? '***'
                  : dosiguCodeReplace(row.destAddr3) +
                  ' ' +
                  dosiguCodeReplace(row.destAddr2)}
            </div>
        ),
      },
      {
        title: '기사',
        dataIndex: 'riderName',
        className: 'table-column-center desk',
        width: 80,
        // sorter: (a, b) => {
        //   const dataA = (a.riderName || "").trim();
        //   const dataB = (b.riderName || "").trim();
        //   return dataA.localeCompare(dataB);
        // },
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => {
          const content = (
              <div>
                <p>{row.riderPhone}</p>
              </div>
          );
          return (
              <Popover content={content} title="기사연락처">
                <div>{row.orderStatus >= 2 ? data : '-'} </div>
              </Popover>
          );
        },
      },

      // antd 찾아봐야 될 듯
      {
        title: '가격',
        dataIndex: 'orderPrice',
        className: 'table-column-center desk',
        width: 50,
        // key: (row) => `orderPrice:${row.orderPrice}`,
        //sorter: (a, b) => a.orderPrice - b.orderPrice,
        sorter: !this.state.checkedCompleteCall,
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '배달요금',
        dataIndex: 'deliveryPrice',
        className: 'table-column-center desk',
        width: 150,
        // key: (row) => `deliveryPrice:${row.deliveryPrice}`,
        //sorter: (a, b) => a.deliveryPrice - b.deliveryPrice,
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => {
          const content = (
              <div>
                <p>배달요금(기본배달료+할증배달료+건별가맹비), 건별수수료, 할증건별수수료</p>
              </div>
          );
          return (
              <Popover content={content} title="배달요금 세부항목">
                <div>
                  {comma(data)} (
                  {comma(row.basicDeliveryPrice - row.duesDeliveryPrice)} +{' '}
                  {comma(row.extraDeliveryPrice)} +{' '}
                  {comma(row.duesDeliveryPrice)}
                  ),{' '}
                  {row.deliveryPriceFeeByDpBasic
                      ? comma(row.deliveryPriceFeeByDpBasic)
                      : 0}
                  ,{' '}
                  {row.deliveryPriceFeeByDpRegion
                      ? comma(row.deliveryPriceFeeByDpRegion)
                      : 0}
                </div>
              </Popover>
          );
        },

      },
      {
        title: '결제',
        dataIndex: 'orderPayments',
        className: 'table-column-center desk',
        width: 40,
        key: (row) => `orderPayments:${row.orderPayments}`,
        render: (data, row) => {
          const authTypeString = ['승인', '취소'];
          const content = row.cardPayments && row.cardPayments.length > 0 && (
              <div>
                {row.cardPayments.map((item) => (
                    <div key={item.trxId}>
                      {item.createDate} [{authTypeString[item.authType]}]{' '}
                      {item.authNum}
                    </div>
                ))}
              </div>
          );
          return row.cardPayments && row.cardPayments.length > 0 ? (
              <Popover content={content} title="승인번호">
                {data.length > 1 ? (
                    <Button
                        onClick={() => this.openPaymentModal(data, row)}
                        // close={this.closePaymentModal}
                    >
                      보기
                    </Button>
                ) : (
                    <div>
                      {paymentMethod[data[0] ? data[0]['paymentMethod'] : 0]}
                    </div>
                )}
              </Popover>
          ) : data.length > 1 ? (
              <Button
                  onClick={() => this.openPaymentModal(data, row)}
                  // close={this.closePaymentModal}
              >
                보기
              </Button>
          ) : (
              <div>{paymentMethod[data[0] ? data[0]['paymentMethod'] : 0]}</div>
          );
        },
      },

      {
        title: '거리(m)',
        dataIndex: 'distance',
        className: 'table-column-center desk',
        width: 46,

        key: (row) => `distance:${row.distance}`,
        render: (data, row) => <div>{comma(data)}</div>,
      },
      // {
      //   title: "결제방식",
      //   dataIndex: "orderPayments",
      //   className: "table-column-center desk",
      //   width: 100,

      //   key: (row) => `orderPayments:${row.idx}`,
      //   render: (data, row) =>
      //     data.length > 1 ? (
      //       <>
      //         <Button
      //           onClick={() => {
      //             this.openPaymentModal(data, row);
      //           }}
      //           close={this.clos}
      //         >
      //           보기
      //         </Button>
      //       </>
      //     ) : (
      //       <div>{paymentMethod[data[0] ? data[0]["paymentMethod"] : 0]}</div>
      //     ),
      // },

      {
        title: '이력보기',
        dataIndex: '',
        className: 'table-column-center desk',
        width: 50,

        key: (row) => `orderLog:${row.idx}`,
        render: (data, row) => (
            <span>
            <Button
                className="tabBtn-rt"
                onClick={(e) => {
                  this.openOrderLogModal(row);
                  e.stopPropagation();
                }}>
              이력보기
            </Button>
          </span>
        ),
      },
      {
        title: '메세지',
        dataIndex: 'franchisePhoneNum',
        className: 'table-column-center desk',
        width: 110,
        key: (row) => `franchisePhoneNum:${row.franchisePhoneNum}`,
        render: (data, row) => (
            <span>
            <Button
                className="tabBtn-rt"
                onClick={(e) => {
                  e.stopPropagation();
                  console.log(row);
                  this.openDirectMessageModal(
                      row.userIdx,
                      row.riderName,
                      row.riderLevel
                  );
                }}>
              라이더
            </Button>
            <Button
                className="tabBtn-rt"
                onClick={(e) => {
                  e.stopPropagation();
                  this.openDirectMessageModal(row.frIdx, row.frName);
                }}>
              가맹점
            </Button>
          </span>
        ),
      },
      {
        title: '접수시간',
        dataIndex: 'orderDate',
        className: 'table-column-center desk',
        width: 110,
        // key: (row) => `orderDate:${row.orderDate}`,
        //sorter: (a, b) => moment(a.orderDate) - moment(b.orderDate),
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => <div>{formatMDHMS(data)}</div>,
      },

      {
        title: '배차시간',
        dataIndex: 'assignDate',
        className: 'table-column-center desk',
        width: 110,
        // key: (row) => `assignDate:${row.assignDate}`,
        // sorter: (a, b) => moment(a.assignDate) - moment(b.assignDate),
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => <div>{row.orderStatus >= 2 ? formatMDHMS(data) : '-'}</div>,
      },
      {
        title: '픽업시간',
        dataIndex: 'pickupDate',
        className: 'table-column-center desk',
        width: 100,
        // key: (row) => `pickupDate:${row.pickupDate}`,
        //sorter: (a, b) => moment(a.pickupDate) - moment(b.pickupDate),
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => (
            <div>{row.orderStatus >= 3 ? formatMDHMS(data) : '-'}</div>
        ),
      },
      {
        title: '완료시간',
        dataIndex: 'completeDate',
        className: 'table-column-center desk',
        width: 100,
        // key: (row) => `completeDate:${row.completeDate}`,
        //sorter: (a, b) => moment(a.completeDate) - moment(b.completeDate),
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => (
            <div>{row.orderStatus >= 4 ? formatMDHMS(data) : '-'}</div>
        ),
      },
      {
        title: '외부연동코드',
        dataIndex: 'ftOrderCode',
        className: 'table-column-center desk',
        width: 100,
        render: (data, row) =>
            data && row.bmOrderCode ? (
                <Popover
                    content={`파트너사 : ${data} / 배민 : ${row.bmOrderCode}`}
                    title="외부연동코드">
                  <div>{data}</div>
                </Popover>
            ) : (
                <div>{data ? data : row.bmOrderCode ? row.bmOrderCode : row.memo === 'BLICT' ? '영수증' : '-'}</div>
            ),
      },

      {
        title: '수정',
        dataIndex: 'updateOrder',
        className: 'table-column-center desk',
        width: 50,
        key: (row) => `updateOrder:${row.updateOrder}`,
        render: (data, row) => (
          <Button
            className="tabBtn-rt"
            onClick={(e) => {
              e.stopPropagation();
              this.openModifyOrderModal(row);
            }}>
            수정
          </Button>
        ),
      },
    ]


    const mobileColumns = [
      {
        title: '주문번호',
        dataIndex: 'idx',
        className: 'table-column-center desk',
        // key: (row) => `idx:${row.idx}`,
        sorter: (a, b) => a.idx - b.idx,
        // defaultSortOrder: 'descend',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '주문내용',
        dataIndex: 'idx',
        className: 'table-column-center mobile',
        render: (data, row) => {
          let remainTimeString = '';

          if (row.orderStatus == 5) remainTimeString = '';
          //취소는 남은시간 없음
          if (row.orderStatus == 4) remainTimeString = '';
          else if (row.orderStatus == 4) {
            //완료는 요청시간에서 완료시간까지 계산
            if (row.arriveReqTime > 1000) {
              //배차후 주문 처리
              const arriveReqDate = moment(row.assignDate).add(
                row.arriveReqTime % 1000,
                'minutes'
              );
              const time = arriveReqDate.diff(
                moment(row.completeDate),
                'minutes'
              );
              return (remainTimeString = time + '분');
            } else {
              const arriveReqDate = moment(row.orderDate).add(
                row.arriveReqTime,
                'minutes'
              );
              const time = arriveReqDate.diff(
                moment(row.completeDate),
                'minutes'
              );
              return (remainTimeString = time + '분');
            }
          } else {
            //진행중
            if (row.arriveReqTime > 1000) {
              if (row.orderStatus == 1) remainTimeString = '';
              else
                remainTimeString =
                  remainTime(row.assignDate, row.arriveReqTime % 1000) + '분';
            } else
              remainTimeString =
                remainTime(row.orderDate, row.arriveReqTime) + '분';
          }

          return (
            <div className="status-box">
              <p className="fr-order-info">
                No.{row.idx} / {row.frName} / {arriveReqTime[row.arriveReqTime]}{' '}
                / {remainTimeString}{' '}
                {/* {row.itemPrepared ? "완료" : "준비중"} <br />{" "} */}
              </p>
              <p className="rider-info">
                {row.riderName ? '라이더정보 : ' + row.riderName + ' / ' : null}
                {row.riderPhone ? row.riderPhone : null}
              </p>
              {row.destAddr3 + ' ' + row.destAddr2} <br />
              {comma(row.distance)}m /{' '}
              {
                paymentMethod[
                row.orderPayments[0]
                  ? row.orderPayments[0]['paymentMethod']
                  : 0
                ]
              }
              <br />
              <div className="table-column-sub">
                <Select
                  style={{ marginRight: 5, width: '5rem' }}
                  defaultValue={data}
                  value={row.orderStatus}
                  onChange={(value) => {
                    if (!modifyType[row.orderStatus].includes(value)) {
                      Modal.info({
                        content: <div>상태를 바꿀 수 없습니다.</div>,
                      });
                      return;
                    }
                    // 대기중 -> 픽업중 변경 시 추천배차 알림
                    if (row.orderStatus === 1 && value === 2) {
                      Modal.info({
                        content: <div>추천배차를 사용하세요.</div>,
                      });
                      return;
                    }
                    // 픽업 -> 접수 변경 시 배차상태로 변경 알림
                    if (row.orderStatus === 3 && value === 1) {
                      Modal.info({
                        content: (
                          <div>
                            배차상태로 먼저 변경한 후 접수로 변경해주세요.
                          </div>
                        ),
                      });
                      return;
                    }

                    //완료를 복원시키는 경우
                    if (row.orderStatus === 4 && value === 3) {
                      const self = this;
                      Modal.confirm({
                        title: '주문복구',
                        content:
                          '주문을 복구하는 경우 라이더에게 지급된 가맹점 배달료도 복구됩니다. 정말 복구하시겠습니까?',
                        okText: '확인',
                        cancelText: '취소',
                        onOk() {
                          httpPost(httpUrl.orderCompleteRestore, [], {
                            orderIdx: row.idx,
                          }).then((res) => {
                            if (
                              res.result === 'SUCCESS' &&
                              res.data === 'SUCCESS'
                            ) {
                              self.getList();
                            } else if (res.data === 'NOT_CHANGE_STATUS_7_DAYS') {
                              Modal.info({
                                title: '변경 실패',
                                content: '40일 전 주문은 복원 불가합니다.',
                              });
                            } else {
                              Modal.info({
                                title: '변경 실패',
                                content: '주문상태 변경에 실패했습니다.',
                              });
                            }
                          });
                        },
                      });
                      return;
                    }

                    const orderStatuseChangeApiCode = [
                      '',
                      httpUrl.orderAssignCancel,
                      httpUrl.orderPickupCancel,
                      httpUrl.orderPickup,
                      httpUrl.orderComplete,
                      httpUrl.orderCancel,
                    ];

                    httpPost(orderStatuseChangeApiCode[value], [], {
                      orderIdx: row.idx,
                    })
                      .then((res) => {
                        if (
                          res.result === 'SUCCESS' &&
                          res.data === 'SUCCESS'
                        ) {
                          this.getList();
                        } else {
                          Modal.info({
                            title: '변경 실패',
                            content: '주문상태 변경에 실패했습니다.',
                          });
                        }
                      })
                      .catch((e) => {
                        if (
                          e.response.data.status ===
                          HttpResponseStatusCode.SERVICE_UNAVAILABLE
                        ) {
                          // 상태변경 이중처리 시 에러코드 처리
                          Modal.warn({
                            title: '상태변경 실패',
                            content: '이미 처리된 주문입니다.',
                          });
                          // 목록 새로고침
                          global.getOrderList && global.getOrderList();
                          return;
                        }

                        Modal.info({
                          title: '변경 실패',
                          content: '주문상태 변경에 실패했습니다.',
                        });

                        throw e;
                      });
                  }}>
                  {deliveryStatusCode.map((value, index) => {
                    if (index === 0) return <></>;
                    else
                      return (
                        <Option key={index} value={index}>
                          {value}
                        </Option>
                      );
                  })}
                </Select>
              </div>
              {''}
              <div className="table-column-sub">
                {/* <ForceAllocateDialog */}
                <Button
                  style={{ marginLeft: 5, width: '5rem' }}
                  className="tabBtn"
                  onClick={() => this.openForceModal(row)}>
                  추천배차
                </Button>
              </div>
              <div className="table-column-sub">
                <Button
                  style={{ marginLeft: 10, width: '5rem' }}
                  className="tabBtn"
                  onClick={() => {
                    this.openModifyOrderModal(row);
                  }}>
                  주문수정
                </Button>
              </div>
            </div>
          );
        },
      },
    ];

    const expandedRowRender = (record) => {
      const dropColumns = [
        {
          title: '세부내용',
          dataIndex: 'distance',
          className: 'table-column-center mobile',
          render: (data, row) => (
            <div className="status-box">
              접수시간 :{formatDate(row.orderDate)}
              <br />
              배차시간 :{formatDate(row.assignDate)}
              <br />
              픽업시간 :
              {row.orderStatus >= 3 ? formatDate(row.pickupDate) : '-'}
              <br />
              완료시간 :
              {row.orderStatus >= 4 ? formatDate(row.completeDate) : '-'}
              <br />
              <hr className="light-hr" />
              가격 : {comma(row.orderPrice)} / 총요금 :{' '}
              {comma(row.deliveryPrice)}
              <br />
              기본요금 : {comma(row.basicDeliveryPrice)} / 할증요금 :{' '}
              {comma(row.extraDeliveryPrice)}
            </div>
          ),
        },
      ];

      const completeExpandedColumnFilter = ['배차', '주문수정'];
      let completeExpandedTableColumns = dropColumns.filter(
        (col) => !completeExpandedColumnFilter.includes(col.title)
      );

      return (
        <Table
          rowKey={(record) => `record: ${record.idx}`}
          columns={
            this.state.checkedCompleteCall
              ? completeExpandedTableColumns
              : dropColumns
          }
          dataSource={[record]}
          pagination={false}
        />
      );
    };

    const completeColumnFilter = ['대기'];
    let completeTableColumns = columns.filter(
      (col) => !completeColumnFilter.includes(col.title)
    );

    return (
      <>
        {this.state.orderRouteInfoOpen && (
          <OrderRouteInfo
            data={this.state.data}
            close={this.closeOrderRouteInfo}
          />
        )}
        {this.state.mapControlOpen && (
          <MapControlDialog
            getList={this.getList}
            callData={this.state.list}
            close={this.closeMapControlModal}
          />
        )}
        {this.state.forceOpen && (
          <SearchRiderDialog
            close={this.closeForceingModal}
            callback={(rider) => this.assignRider(rider)}
            availableOnly={true}
            assignRiderList={true}
            skipIfAssigned={this.state.skipIfAssigned}
            setSkipIfAssigned={(value) =>
              this.setState({
                skipIfAssigned: value,
              })
            }
            isSelectBox={true}
          />
        )}
        {this.state.modifyDeliveryPrice && (
          <ModifyDeliveryPrice
            data={this.state.data}
            close={this.closeModifyDeliveryPriceModal}
          />
        )}
        <div className="reception-box">
          {this.state.paymentOpen && (
            <PaymentDialog
              close={this.closePaymentModal}
              orderPayments={this.state.paymentData}
              orderPrice={this.state.orderPrice}
            />
          )}
          {this.state.MessageOpen && (
            <ChattingDialog close={this.closeMessageModal} />
          )}
          {this.state.directMessageOpen && (
            <ChattingCurrentRoom
              targetIdx={this.state.messageTarget}
              targetName={this.state.messageTargetName}
              targetLevel={this.state.messageTargetLevel}
              close={this.closeDirectMessageModal}
            />
          )}
          {this.state.surchargeOpen && (
            <SurchargeDialog
              branchName={this.state.branchInfo.branchName}
              companyId={this.state.branchInfo.companyId}
              close={this.closeSurchargeModal}
            />
          )}
          {this.state.RegionSurchargeOpen && (
            <RegionSurchargeDialog
              branchName={this.state.branchInfo.branchName}
              close={this.closeRegionSurchargeModal}
            />
          )}

          {this.state.addCallOpen && (
            <RegistCallDialog close={this.closeAddCallModal} />
          )}

          {this.state.modifyOrder && (
            <RegistCallDialog
              close={this.closeModifyOrderModal}
              editable={this.state.editable}
              data={this.state.data}
            />
          )}
          {this.state.modifyRegistOrder && (
            <RegistCallDialog
              close={this.closeModifyRegistModal}
              editable={this.state.editable}
              data={this.state.data}
              reRegist={true}
            />
          )}
          {this.state.orderLogOpen && (
            <OrderLogDialog
              close={this.closeOrderLogModal}
              data={this.state.orderLog}
            />
          )}
          {this.state.timeDelayOpen && (
            <TimeDelayDialog
              branchInfo={this.state.branchInfo}
              close={this.closeTimeDelayModal}
            />
          )}
          {this.state.orderShareModalOpen && (
            <OrderShareDialog
              companyId={this.state.branchInfo.companyId}
              close={this.closeOrderShareModal}
            />
          )}

          {this.state.assignShareModalOpen && (
            <AssignShareDialog
              companyId={this.state.branchInfo.companyId}
              close={this.closeAssignShareModal}
            />
          )}
          {this.state.orderShareViewModalOpen && (
            <OrderShareViewDialog
              companyId={this.state.branchInfo.companyId}
              close={this.closeOrderShareViewModal}
            />
          )}
          {this.state.riderWorkOpen && (
            <RiderList
              close={this.closeRiderWorkListDialog}
              status={this.state.status}
            />
          )}
          {this.state.openZeroFrList && (
            <ZeroCallFrListDialog
              login={true}
              close={this.closeZeroFrList} />
          )}

          {this.state.openZeroRiderList && (
            <ZeroCallRiderDialog
              login={true}
              close={this.closeZeroRiderList} />
          )}

          <PopupNotice />

          <div className="receptionContainer">
            <Affix offsetTop={70}>
              {/* <div
              // className="selectLayout"
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: "#fff",
                zIndex: 2,
              }}
            > */}

              <div className="selectLayout">
                <div className="selectLayout-wrapper">
                  <div className="btnLayout desk">
                    <Button
                      icon={<ShopOutlined />}
                      className="shareTab"
                      onClick={this.openOrderShareModal}>
                      주문공유설정
                    </Button>
                    <Button
                      icon={<ShopOutlined />}
                      className="shareTab"
                      onClick={this.openAssignShareModal}>
                      추천배차공유설정
                    </Button>
                    <Button
                      icon={<ShopOutlined />}
                      className="shareTab"
                      onClick={this.openOrderShareViewModal}>
                      공유콜보기
                    </Button>

                    <Button
                      icon={<FieldTimeOutlined />}
                      className="delayTab"
                      onClick={this.openTimeDelayModal}>
                      호출설정
                    </Button>
                    {this.state.deliveryZone && (
                      <DeliveryZoneDialog close={this.closeDeliveryZoneModal} />
                    )}
                    <Button
                      icon={<PushpinOutlined />}
                      className="tabBtn"
                      onClick={this.openDeliveryZoneModal}>
                      배송가능지역
                    </Button>
                    <Button
                      icon={<EnvironmentFilled />}
                      className="tabBtn mapTab"
                      onClick={this.openMapWindow}>
                      지도관제
                    </Button>

                    <Button
                      icon={<DollarCircleOutlined />}
                      className="tabBtn surchargeTab"
                      onClick={this.openSurchargeModal}>
                      시간할증
                    </Button>

                    <Button
                      icon={<GatewayOutlined />}
                      className="tabBtn surchargeTab"
                      onClick={this.openRegionSurchargeModal}>
                      지역할증
                    </Button>

                    <Button
                        icon={<PhoneOutlined />}
                        className="tabBtn registTab"
                        // hidden={!this.props.menuAuths.has(3)} 추후 각 버튼별 막아야 할 경우
                        onClick={this.openAddCallModal}>
                      주문등록
                    </Button>

                    <Button
                      icon={<MessageOutlined />}
                      className="tabBtn messageTab"
                      onClick={this.openMessageModal}>
                      상담메세지
                    </Button>

                    {this.state.sendSnsOpen && (
                      <SendSnsDialog
                        close={this.closeSendSnsModal}
                        callback={this.onSearchRiderDetail}
                      />
                    )}
                    <Button
                      className="riderManageBtn"
                      onClick={this.openSendSnsModal}>
                      전체메세지
                    </Button>

                    {this.state.noticeOpen && (
                      <NoticeDialog close={this.closeNoticeModal} />
                    )}
                    <Button
                      icon={<NotificationFilled />}
                      className="tabBtn noticeTab"
                      onClick={this.openNoticeModal}>
                      공지사항
                    </Button>
                    {this.state.blindControlOpen && (
                      <BlindControlDialog
                        isOpen={this.state.blindControlOpen}
                        close={this.closeBlindControlModal}
                      />
                    )}
                    <Button
                      className="tabBtn sectionTab"
                      onClick={this.openBlindControlModal}>
                      블라인드관리
                    </Button>
                    <div className="filtering-box-wrapper voice">
                      <div className="filtering-box">
                        <div className="filtering-name">음성</div>
                        <Checkbox.Group
                          value={String(this.state.selectedVoiceUpdate)}
                          defaultValue={
                            Object.keys(this.getVoiceChecked()).length !== 0
                              ? this.getVoiceChecked()
                              : this.state.selectedVoiceUpdate
                          }
                          onChange={this.handleUpdateVoice}>
                          {voiceUpdate.map((o) => {
                            return (
                              <div key={o.value} className="filtering-btn">
                                <Checkbox value={o.value}>{o.label}</Checkbox>
                              </div>
                            );
                          })}
                        </Checkbox.Group>
                      </div>
                    </div>
                    {this.state.getBranchSub.length !== 0 ? (
                      <div className="filtering-box-wrapper voice">
                        <div className="filtering-box">
                          <div className="filtering-name">하위지점</div>
                          <Checkbox.Group
                            defaultValue={
                              Object.keys(this.getBranchSubChecked()).length !==
                                0
                                ? this.getBranchSubChecked
                                : this.state.selectBranchSub
                            }
                            onChange={this.handleBranchSub}>
                            {this.state.getBranchSub.map((o) => {
                              return (
                                <div key={o.idx} className="filtering-btn">
                                  <Checkbox value={o.idx}>
                                    {o.branchSubName}
                                  </Checkbox>
                                </div>
                              );
                            })}
                          </Checkbox.Group>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="selectLayout_sub desk">
                  <div className="selectLayout-wrapper">
                    <Form
                      onFinish={() => {
                        this.handleSearch();
                      }}>
                      <Input
                        ref={this.franchiseRef}
                        placeholder="가맹점검색"
                        allowClear
                        style={{
                          width: 170,
                        }}
                      />
                      <Input
                        ref={this.riderRef}
                        placeholder="기사명검색"
                        allowClear
                        style={{
                          width: 170,
                          marginLeft: 10,
                        }}
                      />
                      <Input
                        ref={this.addrRef}
                        placeholder="도착지검색"
                        allowClear
                        style={{
                          width: 180,
                          marginLeft: 10,
                        }}
                      />
                      <Input
                        ref={this.idxRef}
                        placeholder="주문번호검색"
                        allowClear
                        style={{
                          width: 180,
                          marginLeft: 10,
                        }}
                      />
                      <Button type="primary" htmlType="submit">
                        조회
                      </Button>
                    </Form>
                    {/* {this.state.checkedCompleteCall && (
                    <DatePicker
                      style={{ marginLeft: 20, verticalAlign: "top" }}
                      defaultValue={moment(today, dateFormat)}
                      format={dateFormat}
                      onChange={(date) => {
                        if (date) {
                          const newDate = new Date(
                            date.get("year"),
                            date.get("month"),
                            date.get("date")
                          );

                          this.setState(
                            { selectedDate: newDate },
                            () => {
                              this.getCompleteList();
                              this.completedTotal();
                              this.canceledTotal();
                              console.log(
                                "BBB " +
                                moment(this.state.selectedDate).format(
                                  "YYYY-MM-DD"
                                )
                              );
                            },
                            () => console.log("aaa" + this.state.selectedDate)
                          );
                        }
                      }}
                    />
                   )} */}
                    {/* <Checkbox
                      defaultChecked={
                        this.state.checkedCompleteCall ? "checked" : ""
                      }
                      onChange={this.handleToggleCompleteCall}
                    >
                      <span className="span1">이력조회</span>
                    </Checkbox> */}

                    <div className="filtering-box-wrapper">
                      <div className="filtering-box">
                        <div className="filtering-name">주문</div>
                        <Checkbox.Group
                          defaultValue={
                            Object.keys(this.getOrderStatusChecked()).length !==
                              0
                              ? this.getOrderStatusChecked
                              : this.state.selectedOrderStatuses
                          }
                          onChange={this.handleChangeOrderStatus}
                          disabled={!this.state.idx ? false : true}>
                          {orderStatusDefs.map((o) => {
                            return (
                              <div key={o.value} className="filtering-btn">
                                <Checkbox value={o.value}>{o.label}</Checkbox>
                              </div>
                            );
                          })}
                        </Checkbox.Group>
                      </div>

                      <div className="filtering-box">
                        <div className="filtering-btn-01">
                          <Checkbox
                            checked={
                              this.state.checkedCompleteCall ? 'checked' : ''
                            }
                            onChange={this.handleToggleCompleteCall}
                            style={{ marginRight: 5 }}></Checkbox>
                        </div>
                        <div className="filtering-name">이력</div>

                        <div className="filter-picker">
                          {this.state.checkedCompleteCall ? (
                            <DatePicker
                              value={this.state.selectedDate}
                              //defaultValue={this.state.selectedDate}
                              format={dateFormat}
                              onChange={this.handleChangeSelectedDate}
                            />
                          ) : (
                            <DatePicker
                              value={this.state.selectedDate}
                              //defaultValue={this.state.selectedDate)}
                              format={dateFormat}
                              onChange={this.handleChangeSelectedDate}
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      {/* <div className="filtering-box">
                      <div className="filtering-name">이력</div>

                      {this.state.orderStatus.map((o) => {
                        return (
                          <div className="filtering-btn">
                            <Checkbox
                              key={o.key}
                              value={o.value}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  const resultComplete =
                                    this.state.selectedCompleteStatus.concat(
                                      e.target.value
                                    );
                                  this.setState({
                                    selectedCompleteStatus: resultComplete,
                                  });
                                } else {
                                  const resultComplete =
                                    this.state.selectedCompleteStatus.filter(
                                      (el) => el !== e.target.value
                                    );
                                  this.setState({
                                    selectedCompleteStatus: resultComplete,
                                  });
                                }
                              }}
                              defaultChecked={
                                this.state.selectedCompleteStatus.includes(
                                  o.value
                                )
                                  ? ""
                                  : "checked"
                              }
                            >
                              {o.text}
                            </Checkbox>
                          </div>
                        );
                      })}
                    </div> */}

                      <div className="filtering-box">
                        <div className="filtering-name">결제</div>
                        <Checkbox.Group
                          value={this.state.selectedPaymentMethods}
                          defaultValue={
                            Object.keys(this.getPaymentMethodsChecked())
                              .length !== 0
                              ? this.getPaymentMethodsChecked
                              : this.state.selectedPaymentMethods
                            // this.state.selectedPaymentMethods
                          }
                          onChange={this.handleChangePaymentMethod}>
                          {paymentMethodDefs.map((o) => {
                            return (
                              <div key={o.value} className="filtering-btn">
                                <Checkbox value={o.value}>{o.label}</Checkbox>
                              </div>
                            );
                          })}
                        </Checkbox.Group>
                      </div>
                      <div className="filtering-box">
                        <div className="filtering-name">정렬순서</div>
                        <Radio.Group
                          value={this.state.selectedOrderSort}
                          onChange={this.handleChangeOrderSort}>
                          {sortOrderCode.map((o) => {
                            return (
                              <div key={o.value} className="filtering-btn">
                                <Radio value={o.value}>{o.label}</Radio>
                              </div>
                            );
                          })}
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="selectLayout-wrapper2">
                  <div className="rider-status-box desk">
                    <div>라이더</div>
                    <div
                      onClick={() => this.openRiderWorkListDialog(1)}
                      style={{ cursor: 'pointer' }}>
                      근무 :{' '}
                      {this.state.riderStatus.find(
                        (riderStatusCount) => riderStatusCount.riderStatus === 1
                      )
                        ? this.state.riderStatus.find(
                          (riderStatusCount) =>
                            riderStatusCount.riderStatus === 1
                        ).count
                        : 0}
                      명
                    </div>
                    <div
                      onClick={() => this.openRiderWorkListDialog(2)}
                      style={{ cursor: 'pointer' }}>
                      휴식 :{' '}
                      {this.state.riderStatus.find(
                        (riderStatusCount) => riderStatusCount.riderStatus === 2
                      )
                        ? this.state.riderStatus.find(
                          (riderStatusCount) =>
                            riderStatusCount.riderStatus === 2
                        ).count
                        : 0}
                      명
                    </div>
                    <div
                      onClick={() => this.openRiderWorkListDialog(3)}
                      style={{ cursor: 'pointer' }}>
                      퇴근 :{' '}
                      {this.state.riderStatus.find(
                        (riderStatusCount) => riderStatusCount.riderStatus === 3
                      )
                        ? this.state.riderStatus.find(
                          (riderStatusCount) =>
                            riderStatusCount.riderStatus === 3
                        ).count
                        : 0}
                      명
                    </div>
                  </div>
                  <div className="delivery-show-box desk">
                    <div>
                      현재 기사노출건수 : <span>{this.props.showCount}</span>개
                    </div>

                    <Input
                      ref={this.showCountRef}
                      name="showCount"
                      type="number"
                      placeholder="건수 변경"
                      style={{ width: 94 }}
                    />
                    <Button onClick={this.setShowCountAsync}>변경</Button>
                  </div>
                  <div className="delivery-status-box desk">
                    <div style={{ background: 'rgb(255, 204, 204)' }}>
                      접수 : {this.getOrderStatusCount(1)} 건
                    </div>
                    <div style={{ background: '#d6edfe' }}>
                      배차 : {this.getOrderStatusCount(2)} 건
                    </div>
                    <div style={{ background: '#fedeaa' }}>
                      픽업 : {this.getOrderStatusCount(3)} 건
                    </div>
                    <div style={{ background: '#ffffbf' }}>
                      완료 : {this.getOrderStatusCount(4)} 건
                    </div>
                    <div style={{ background: '#a9a9a9' }}>
                      취소 : {this.getOrderStatusCount(5)} 건
                    </div>
                  </div>

                  <Button
                    className="desk"
                    onClick={() =>
                      global.getOrderList && global.getOrderList()
                    }>
                    목록 새로고침
                  </Button>
                  <div className="filtering-box1 desk">
                    <Checkbox
                      checked={
                        this.state.checkedAddrUpdateCall ? 'checked' : ''
                      }
                      onChange={this.handleToggledAddrUpdateCall}>
                      배차 주소 수정
                    </Checkbox>
                  </div>
                  <Pagination
                    className="tabBtn"
                    style={{ display: 'inline' }}
                    onChange={this.handlePagesChange}
                    pageSize={this.state.pagination.pageSize}
                    total={this.state.pagination.total}
                    current={this.state.pagination.current}
                    simple={true}
                  />
                </div>
                <div>
                  <div className="delivery-show-box desk">
                    <Checkbox
                      ref={this.allowAssignCancelRef}
                      onChange={(e) =>
                        this.setState({ allowAssignCancel: e.target.checked })
                      }
                      checked={this.state.allowAssignCancel}>
                      배차취소 허용
                    </Checkbox>

                    <div>
                      1분당페널티 :{' '}
                      <span>{this.state.assignCancelPenaltyUnit}</span>원
                    </div>

                    <Input
                      ref={this.assignCancelPenaltyUnitRef}
                      name="assignCancelPenaltyUnit"
                      type="number"
                      style={{ width: 100 }}
                    />

                    <div>
                      페널티최대값 :{' '}
                      <span>{this.state.assignCancelPenaltyLimit}</span>원
                    </div>

                    <Input
                      ref={this.assignCancelPenaltyLimitRef}
                      name="assignCancelPenaltyLimit"
                      type="number"
                      style={{ width: 100 }}
                    />
                    <Button
                      onClick={async () =>
                        await this.setAssignCancelSettings()
                      }>
                      변경
                    </Button>

                  </div>
                </div>
              </div>
              <div className="mobile reception-detail">
                <details className="r-details">
                  <summary className="r-summary"> 상세보기</summary>

                  <div className="btnLayout mobile">
                    <Button
                      icon={<ShopOutlined />}
                      className="tabBtn shareTab"
                      onClick={this.openOrderShareModal}>
                      주문공유
                    </Button>
                    <Button
                      icon={<FieldTimeOutlined />}
                      className="tabBtn delayTab"
                      onClick={this.openTimeDelayModal}>
                      호출설정
                    </Button>
                    <Button
                      icon={<EnvironmentFilled />}
                      className="tabBtn mapTab"
                      onClick={this.openMapControlModal}
                    // onClick={() => { this.props.openMapControl() }}
                    >
                      지도관제
                    </Button>
                  </div>
                  <div className="btnLayout mobile">
                    <Button
                      icon={<DollarCircleOutlined />}
                      className="tabBtn surchargeTab"
                      onClick={this.openSurchargeModal}>
                      시간할증
                    </Button>
                    <Button
                      icon={<GatewayOutlined />}
                      className="tabBtn surchargeTab"
                      onClick={this.openRegionSurchargeModal}>
                      지역할증
                    </Button>

                    <Button
                      icon={<PhoneOutlined />}
                      className="tabBtn registTab"
                      onClick={this.openAddCallModal}>
                      주문등록
                    </Button>
                  </div>
                  <div className="selectLayout-top mobile">
                    <Input
                      ref={this.franchiseRefM}
                      placeholder="가맹점검색"
                      className="select-mobile"
                      enterButton
                      allowClear
                      onSearch={this.handleSearch}
                    />
                    <Search
                      ref={this.riderRefM}
                      placeholder="기사명검색"
                      className="select-mobile"
                      enterButton
                      allowClear
                      onSearch={this.handleSearch}
                    />
                    <div className="delivery-show-m-box mobile">
                      <div className="">
                        현재 기사노출건수 : <span>{this.props.showCount}</span>
                        개
                      </div>

                      <Input
                        ref={this.showCountRefM}
                        name="showCount"
                        type="number"
                        placeholder="건수 변경"
                        style={{ width: 150, height: 30 }}
                      />
                      <Button onClick={this.setShowCountAsync}>변경</Button>
                    </div>
                    <div className="filtering-box">
                      <Checkbox.Group
                        defaultValue={this.state.selectedOrderStatuses}
                        onChange={this.handleChangeOrderStatus}>
                        {orderStatusDefs.map((o) => {
                          return (
                            <div key={o.value} className="filtering-btn">
                              <Checkbox value={o.value}>{o.label}</Checkbox>
                            </div>
                          );
                        })}
                      </Checkbox.Group>
                      <Button
                        style={{ marginLeft: 20 }}
                        onClick={() =>
                          global.getOrderList && global.getOrderList()
                        }>
                        목록 새로고침
                      </Button>
                    </div>
                  </div>
                  <div className="rider-status-box mobile">
                    <div className="rider-status-mobile">라이더 :</div>
                    <div
                      className="rider-status-mobile"
                      style={{ background: 'rgb(255, 204, 204)' }}
                      onClick={() => this.openRiderWorkListDialog(1)}>
                      근무 :{' '}
                      {this.state.riderStatus.find(
                        (riderStatusCount) => riderStatusCount.riderStatus === 1
                      )
                        ? this.state.riderStatus.find(
                          (riderStatusCount) =>
                            riderStatusCount.riderStatus === 1
                        ).count
                        : 0}
                      명
                    </div>
                    <div
                      className="rider-status-mobile"
                      style={{
                        background: '#d6edfe',
                        marginLeft: 5,
                        marginRight: 5,
                      }}
                      onClick={() => this.openRiderWorkListDialog(2)}>
                      휴식 :{' '}
                      {this.state.riderStatus.find(
                        (riderStatusCount) => riderStatusCount.riderStatus === 2
                      )
                        ? this.state.riderStatus.find(
                          (riderStatusCount) =>
                            riderStatusCount.riderStatus === 2
                        ).count
                        : 0}
                      명
                    </div>
                    <div
                      className="rider-status-mobile"
                      style={{
                        backgroundColor: 'white',
                        borderWidth: 1,
                        borderColor: '#d1d1d1',
                      }}
                      onClick={() => this.openRiderWorkListDialog(3)}>
                      퇴근 :{' '}
                      {this.state.riderStatus.find(
                        (riderStatusCount) => riderStatusCount.riderStatus === 3
                      )
                        ? this.state.riderStatus.find(
                          (riderStatusCount) =>
                            riderStatusCount.riderStatus === 3
                        ).count
                        : 0}
                      명
                    </div>
                  </div>
                  <div className="delivery-status-box mobile">
                    <div
                      className="delivery-status-mobile"
                      style={{ border: '1px solid black', marginRight: 5 }}>
                      전체 : {this.props.orderReducer.orders.length} 건
                    </div>
                    <div
                      className="delivery-status-mobile"
                      style={{ background: 'rgb(255, 204, 204)' }}>
                      접수 : {this.getOrderStatusCount(1)} 건
                    </div>
                    <div
                      className="delivery-status-mobile"
                      style={{
                        background: '#d6edfe',
                        marginLeft: 5,
                        marginRight: 5,
                      }}>
                      배차 : {this.getOrderStatusCount(2)} 건
                    </div>
                  </div>
                  <div className="delivery-status-box mobile">
                    <div
                      className="delivery-status-mobile"
                      style={{
                        background: '#fedeaa',
                        marginRight: 5,
                      }}>
                      픽업 : {this.getOrderStatusCount(3)} 건
                    </div>
                    <div
                      className="delivery-status-mobile"
                      style={{
                        background: '#ffffbf',
                      }}>
                      완료 : {this.getOrderStatusCount(4)} 건
                    </div>
                    <div
                      className="delivery-status-mobile"
                      style={{
                        background: '#a9a9a9',
                        marginLeft: 5,
                        marginRight: 5,
                      }}>
                      취소 : {this.getOrderStatusCount(5)} 건
                    </div>
                  </div>
                </details>
              </div>
            </Affix>
          </div>
          <div id="reception-table" className="desk">
            {/* <Table
              rowKey={(record) => record.idx}
              rowClassName={(record) =>
                record.deliveryPrice == 0
                  ? "table-redalert"
                  : record.waitingStatus === 1
                  ? "table-purple"
                  : rowColorName[record.orderStatus]
              }
              dataSource={
                this.state.checkedCompleteCall
                  ? this.state.totalList
                  : this.state.list
              }
              columns={
                this.state.checkedCompleteCall ? completeTableColumns : columns
              }
              pagination={this.state.pagination}
              onChange={this.handleTableChange}
              expandedRowRender={expandedRowRender}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: (event) => {
                    this.openModifyOrderModal(record);
                  },
                };
              }}
              // sticky={{ offsetHeader: 170 }}
            /> */}
            <VirtualTable
              rowKey={(record) => record.idx}
              rowClassName={(record) => this.setRowClassName(record)}
              dataSource={this.state.list}
              columns={
                this.state.checkedCompleteCall ? completeTableColumns : (companyId == 'connect9' ? columnsByConnect9 : columns)
              }
              pagination={this.state.pagination}
              onChange={this.handleTableChange}
              // expandedRowRender={expandedRowRender}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    global.orderSelectCallback &&
                      global.orderSelectCallback(record);
                  },
                  onDoubleClick: () => {
                    this.openModifyOrderModal(record);
                  },
                };
              }}
              scroll={{ x: 3000, y: 600 }}
              style={{ paddingLeft: 10 }}
            />
          </div>
          <div id="reception-table" className="mobile">
            <Table
              rowKey={(record) => record.idx}
              rowClassName={(record) => this.setRowClassName(record)}
              dataSource={this.state.list}
              columns={mobileColumns}
              pagination={this.state.pagination}
              onChange={this.handleTableChange}
              // onChange={this.handleTableChange}
              expandedRowRender={expandedRowRender}
              expandRowByClick={true}
              // onRow={(record, rowIndex) => {
              //   return {
              //     onDoubleClick: (event) => {
              //       this.openModifyOrderModal(record);
              //     },
              //   };
              // }}
              style={{ padding: 10, marginTop: 30 }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
            }}></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orderReducer: state.orderReducer,
  riderReducer: state.riderReducer,
  settingReducer: state.settingReducer,
  branchIdx: state.login.loginInfo.branchIdx,
  info: state,
  showCount: state.branchReducer.branchInfo.showCount,
  idx: state.login.loginInfo.idx,
  //menuAuths: state.login.loginInfo.menuAuths,
  branchInfo: state.branchReducer.branchInfo
});

const mapDispatchToProps = (dispatch) => {
  return {
    addOrder: (category, order) => dispatch(addOrder(category, order)),
    deleteOrder: (category, order) => dispatch(deleteOrder(category, order)),
    updateOrder: (order) => dispatch(updateOrder(order)),
    setBranchInfo: (branchInfo) => dispatch(setBranchInfo(branchInfo)),
    setSetting: (setting) => dispatch(setSetting(setting)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceptionStatus);
