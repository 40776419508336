import React, { useEffect, useRef, useState } from 'react';
import { Button, DatePicker, Input, Table, Modal, Checkbox, Select } from 'antd';
import { DownloadOutlined, CloseOutlined, SearchOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  url,
  excelUrl,
  menuTab,
  checkTab,
  placeholderText,
  columns,
  parseExcelJsonForSettlement,
  detailDataOfFr,
  selectTab,
} from '../../../lib/util/settlementUtil';
import axios from 'axios';
import Const from '../../../const';
import { useSelector } from 'react-redux';
import { httpGet, httpUrl } from '../../../api/httpClient';
import FileSaver from 'file-saver';
import xlsx from 'xlsx';

const SettlementDialog = (props) => {
  const title = props.params.title ? props.params.title : '';
  const tabVisible = props.params.tab;
  const inputVisible = props.params.input;
  const radioVisible = props.params.radio;
  const selectVisible = props.params.select;
  const [tabType, setTabType] = useState(props.params.def);
  const [checkType, setCheckType] = useState([0]);
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const loginInfo = useSelector((state) => state.login.loginInfo);
  const [sum, setSum] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingforDetail, setIsLoadingforDetail] = useState(false);
  const [deliveryVatDetailModalOpen, setDeliveryVatDetailModalOpen] = useState(false);
  const [fr, setFr] = useState({});
  const [selectCategoryType, setSelectCategoryType] = useState('');
  const [selectCategoryTypeStr, setSelectCategoryTypeStr] = useState('전체');
  const [pageNum, setPageNum] = useState(1);
  const [headquarter, setHeadquarter] = useState('');
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 80,
  });
  const [paginationForDetail, setPaginationForDetail] = useState({
    total: 0,
    current: 1,
    pageSize: 80,
  });
  const CancelToken = axios.CancelToken;
  const source = useRef(null);

  const onChangeDate = (type, _, date) => {
    let selectDate = '';
    if (type === 'start') {
      selectDate = moment(date).format('YYYY-MM-DD');
      setStartDate(selectDate);
    } else {
      selectDate = moment(date).format('YYYY-MM-DD');
      setEndDate(selectDate);
    }
    setDeliveryVatDetailModalOpen(false);
    setPaginationForDetail({
      total: 0,
      current: 1,
      pageSize: 80,
    });
  };
  const getSettlementDate = async (customParams, paramsUrl) => {
    if (source.current !== null) {
      // already exists request
      source.current.cancel();
    }
    source.current = CancelToken.source();

    try {
      if (!paramsUrl) setData([]); // delete data of list is existed.
      return await axios.get(paramsUrl ? paramsUrl : url[title], {
        baseURL: Const.settlementUrl,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        params: customParams,
        cancelToken: source.current.token,
        timeout: 60000,
      });
    } catch (e) {
      if (e.code === 'ECONNABORTED') {
        Modal.error({
          content: '데이터 연결시간이 초과되었습니다. 다시 시도하시거나 관리자에게 문의해주세요.',
        });
      }
      return e.code;
      console.error('getting settlement-date is fail: ' + e);
    }
  };
  const getBatchData = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const res = await httpGet(
        httpUrl.getDashboardData,
        [
          loginInfo.companyId,
          moment(endDate).add(1, 'day').format('YYYY-MM-DD'),
          tabType,
          pagination.current,
          pagination.pageSize,
          startDate,
          checkType,
          search,
        ],
        {}
      );
      if (res) {
        // console.log(res.data);
        setData(() => {
          return res.data.misuUserBatchWorkList;
        });
        // let sumTemp = [];
        // sumTemp.push(
        //   (res.data.totalMisu - res.data.totalRecoveredMisu) * -1,
        //   res.data.totalRecoveredMisu
        // );
        // setSum(sumTemp);
      }
    } catch (e) {
      console.error('getting batch-work-ncash-list is fail: ' + e);
    }
    setIsLoading(false);
  };
  useEffect(async () => {
    getData();
    if (title === '기타') {
      const res = await httpGet(httpUrl.getHeadquarterBranchName, [], {});
      setHeadquarter(res.data.branchName);
    }
    return () => {
      if (source.current !== null) {
        source.current.cancel();
      }
    };
  }, [tabType, checkType, pagination.current, startDate, endDate, selectCategoryType]);

  useEffect(() => {
    if (deliveryVatDetailModalOpen) getOrderListofFr();
    return () => {
      if (source.current !== null) {
        source.current.cancel();
      }
    };
  }, [paginationForDetail.current]);

  const getData = () => {
    if (props.params.title === '전체 미회수') {
      getBatchData();
    } else getDetailData();
  };
  const getDetailData = async () => {
    setIsLoading(true);
    let customParams = {
      startDate: startDate,
      endDate: endDate,
      companyId: loginInfo.companyId,
      page: pagination.current,
      size: pagination.pageSize,
    };
    if (title === '대리점 수수료' && tabType !== '') {
      customParams.category2 = tabType;
    }
    if ((title === '바이크 렌트' || title === '바이크 렌트 납부') && tabType !== '') {
      customParams.kind = tabType;
    }
    if (search !== '') {
      customParams.search = search;
    }
    if (selectCategoryType !== '') {
      customParams.category = selectCategoryType;
    }
    const datas = await getSettlementDate(customParams);
    if (datas === 'ECONNABORTED') setIsLoading(false);
    else {
      if (datas) {
        console.log(datas);
        setData(() => {
          return datas.data.contents;
        });
        setPagination({
          ...pagination,
          total: datas.data.totalCount,
          current: pagination.current,
        });
        setIsLoading(false);
      }
    }
    if (!tabVisible) setIsLoading(false);
  };
  const changTab = (category, value) => {
    setDeliveryVatDetailModalOpen(false);
    if (category === 'tab') {
      if (value === 22) {
        setTabType(value);
        setCheckType([1]);
      } else {
        setTabType(value);
      }
    } else if (category === 'check') {
      setCheckType(value);
    } else if (category === 'search') {
      setSearch(value);
    }
    setPagination({
      ...pagination,
      current: 1,
    });
  };
  const getExcelData = async (customParamsForExcel) => {
    if (title === '전체 미회수') {
      const res = await httpGet(
        httpUrl.downloadDashboardBatchWork,
        [loginInfo.companyId, moment(endDate).add(1, 'day').format('YYYY-MM-DD'), startDate],
        {}
      );
      return res.data;
    } else {
      if (source.current !== null) {
        // already exists request
        source.current.cancel();
      }
      source.current = CancelToken.source();
      try {
        return await axios.get(excelUrl[title], {
          baseURL: Const.settlementUrl,
          params: customParamsForExcel,
          responseType: 'arraybuffer',
          cancelToken: source.current.token,
        });
      } catch (e) {
        console.error('getting excel-data is failed: ' + e);
      }
    }
  };

  const onDownloadExcel = async () => {
    let customParamsForExcel = {
      startDate: startDate,
      endDate: endDate,
      companyId: loginInfo.companyId,
    };
    if (title === '대리점 수수료' && tabType != '') {
      customParamsForExcel.category2 = tabType;
    }
    if ((title === '바이크 렌트' || title === '바이크 렌트 납부') && tabType != '') {
      customParamsForExcel.kind = tabType;
    }
    if (search !== '') {
      customParamsForExcel.search = search;
    }
    if (isLoading) return;
    setIsLoading(true);
    const excelData = await getExcelData(customParamsForExcel);
    const response = await excelData;

    if (response) {
      if (title === '전체 미회수') {
        const excelJson = parseExcelJsonForSettlement(response);
        const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
        const wb = xlsx.utils.book_new();
        ws['!cols'] = [];
        xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
        xlsx.writeFile(wb, `${'전체 미회수 세부내역-' + startDate}.xlsx`);
      } else {
        // const filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
        // const extension = response.headers['content-disposition'].split('.')[1].split(';')[0];
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(blob, `${title + ' 세부내역-' + startDate}.xlsx`);
      }
      setIsLoading(false);
      Modal.success({
        content: '엑셀파일 다운로드가 완료되었습니다.',
      });
    } else {
      setIsLoading(false);
      Modal.error({
        content: '다운로드에 실패했습니다. 다시 시도하시거나 관리자에게 문의해주세요.',
      });
    }
  };

  // const onChangePagination = (type) => {
  //   if (type === 'prev') {
  //     setPageNum((page) => page - 1);
  //   } else if (type === 'next') {
  //     setPageNum((page) => page + 1);
  //   }
  // };

  const getOrderListofFr = async (param) => {
    let frInfo = param ? param : fr;
    const params = {
      startDate: moment(frInfo.createDate).format('YYYY-MM-DD'),
      endDate: moment(frInfo.createDate).format('YYYY-MM-DD'),
      businessNumber: frInfo.businessNumber,
      companyId: loginInfo.companyId,
      page: paginationForDetail.current,
      size: paginationForDetail.pageSize,
    };
    const url = '/tax-details/delivery-price-vat/order-of-fr';
    setIsLoadingforDetail(true);
    const datas = await getSettlementDate(params, url);
    if (datas) {
      console.log(datas);
      setDetailData(() => {
        return datas.data.contents;
      });
      setPaginationForDetail({
        ...paginationForDetail,
        total: datas.data.totalCount,
        current: paginationForDetail.current,
      });
    }
    setIsLoadingforDetail(false);
  };

  const disabledDate = (current, from) => {
    return current && (current < moment(props.startDate) || current > moment(props.endDate));
  };
  return (
    <div>
      <div className={'Dialog-overlay'} onClick={props.close} />
      <div className={'DialogBackground'}>
        <div className={'settlementDialog'}>
          {/* 헤더 */}
          <div className={'settle-title-wrap'}>
            <div className={'settle-title-content'}>
              <div>{title} 세부내역</div>
              {/* 몽고DB에 각 컬렉션을 쌓기 전이라 냠냠로그에서 합산해올경우 속도저하로 인해 임시 주석*/}
              {/* 몽고DB로 조회하게 될 경우 다시 사용할 가능성 있음*/}
              {/*<div className={'settle-title-sub'}>*/}
              {/*  {sumNumberForModal[title] &&*/}
              {/*    sumNumberForModal[title].map((item, index) => {*/}
              {/*      return (*/}
              {/*        <span*/}
              {/*          key={index}*/}
              {/*          style={{*/}
              {/*            marginRight: 16,*/}
              {/*            color:*/}
              {/*              title === '일차감'*/}
              {/*                ? index === 0*/}
              {/*                  ? '#FA2020'*/}
              {/*                  : '#2036FA'*/}
              {/*                : '#000',*/}
              {/*          }}>*/}
              {/*          {item} : {comma(sum[index])}*/}
              {/*        </span>*/}
              {/*      );*/}
              {/*    })}*/}
              {/*</div>*/}
            </div>
            <Button
              onClick={props.close}
              icon={<CloseOutlined style={{ fontSize: 30 }} />}
              type="text"
              shape="circle"
            />
          </div>
          <div className={'settle-search-wrap'}>
            {selectVisible && (
              <div className={'elem-box'} style={{ marginRight: 10, flex: 0.5, marginLeft: 0 }}>
                <Select
                  defaultValue={selectCategoryType}
                  value={selectCategoryTypeStr}
                  onChange={(value, item) => {
                    setSelectCategoryType(value);
                    setSelectCategoryTypeStr(item.label);
                  }}
                  style={{ border: 'none', flex: 1 }}
                  options={selectTab[title]}
                />
              </div>
            )}
            {inputVisible && (
              <div className={'elem-box'} style={{ marginRight: 10, flex: 1.5 }}>
                <Input
                  onChange={(e) => changTab('search', e.target.value)}
                  value={search}
                  onPressEnter={getData}
                  placeholder={placeholderText[title]}
                  style={{ flex: 1, border: 'none' }}
                />
                <SearchOutlined style={{ padding: '0 10px', cursor: 'pointer' }} onClick={getData} />
              </div>
            )}
            <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
              <div className={'elem-box'} style={{ marginRight: 5, maxWidth: 230 }}>
                <DatePicker
                  style={{ flex: 1, border: 'none' }}
                  onChange={(_, date) => onChangeDate('start', _, date)}
                  value={moment(startDate)}
                  allowClear={false}
                  disabledDate={disabledDate}
                />
              </div>
              <span> ~ </span>
              <div className={'elem-box'} style={{ marginLeft: 5, marginRight: 10, maxWidth: 230 }}>
                <DatePicker
                  style={{ flex: 1, border: 'none' }}
                  onChange={(_, date) => onChangeDate('end', _, date)}
                  value={moment(endDate)}
                  allowClear={false}
                  disabledDate={disabledDate}
                />
              </div>
            </div>
            {radioVisible && (
              <Checkbox.Group
                options={checkTab[title]}
                defaultValue={[0]}
                value={checkType}
                disabled={tabType === 22}
                onChange={(value) => changTab('check', value)}
              />
            )}
            <Button
              style={{
                height: 40,
                backgroundColor: '#217D4B',
                borderColor: '#005929',
                color: '#fff',
                borderRadius: 5,
              }}
              disabled={isLoading}
              onClick={onDownloadExcel}>
              엑셀 다운로드
              <DownloadOutlined />
            </Button>
          </div>
          {/* 메뉴버튼 */}
          {tabVisible && (
            <div className={'settle-menu-wrap'}>
              {menuTab[title] &&
                menuTab[title].map((item, index) => {
                  return (
                    <div
                      key={index}
                      value={item.value}
                      className={(tabType === item.value ? 'active' : '') + ' settle-menu-tab'}
                      style={{ flex: 1 }}
                      onClick={() => changTab('tab', item.value)}>
                      {item.label}
                    </div>
                  );
                })}
            </div>
          )}
          <div
            className={data != null && data.length > 0 && 'settlementDataTable'}
            style={{
              flex: 1,
              margin: 20,
              marginBottom: 30,
              border: '1px solid #000',
            }}>
            <Table
              rowKey={(record) => record.idx}
              dataSource={
                title === '기타'
                  ? data.map((s) => {
                      const isFromAgencyHeadquarter = s.etcSalesAmount < 0;
                      return {
                        ...s,
                        riderOrFrName: isFromAgencyHeadquarter ? headquarter : s.riderOrFrName,
                      };
                    })
                  : data
              }
              scrollToFirstRowOnChange={true}
              rowClassName={(record, index) => {
                // if (title === '배달비 부가세') {
                //   return index % 2 !== 0 ? 'settlementDataTable-row cursor' : 'cursor';
                // } else
                return index % 2 !== 0 && 'settlementDataTable-row';
              }}
              loading={isLoading}
              columns={tabType === 22 ? columns['일차감음식보험'] : columns[title]}
              size="middle"
              onRow={(record) => {
                // if (title === '배달비 부가세') {
                //   return {
                //     onClick: async () => {
                //       setFr(record);
                //       setDeliveryVatDetailModalOpen(true);
                //       getOrderListofFr(record);
                //       setPaginationForDetail({
                //         total: 0,
                //         current: 1,
                //         pageSize: 80,
                //       });
                //     },
                //   };
                // } else
                if (title === '전체 미회수') {
                  return {
                    onClick: async () => {
                      Modal.info({
                        title: '미회수 세부 내역',
                        content: record.memo,
                      });
                    },
                  };
                } else return;
              }}
              onChange={(pagination) => setPagination(pagination)}
              pagination={pagination}
            />
            {/*<div className={'settle-pagination'}>*/}
            {/*  <Button disabled={pageNum === 1} onClick={() => onChangePagination('prev')}>*/}
            {/*    <LeftOutlined /> 이전*/}
            {/*  </Button>*/}
            {/*  <Button disabled={pageSize > data.length} onClick={() => onChangePagination('next')}>*/}
            {/*    다음 <RightOutlined />*/}
            {/*  </Button>*/}
            {/*</div>*/}
          </div>
        </div>
        {deliveryVatDetailModalOpen && (
          <div className={'settlementDialog'}>
            <div className={'settle-title-wrap2'}>
              <Button
                onClick={() => {
                  setFr({});
                  setDeliveryVatDetailModalOpen(false);
                  setDetailData([]);
                  setPaginationForDetail({
                    total: 0,
                    current: 1,
                    pageSize: 80,
                  });
                }}
                icon={<LeftOutlined style={{ fontSize: 30 }} />}
                type="text"
                shape="circle"
              />
              <div className={'settle-title-content'}>
                <div style={{ marginLeft: 20 }}>{fr.frName} 세부내역</div>
              </div>
            </div>
            <div
              className={'settlementDataTable'}
              style={{
                flex: 1,
                margin: 20,
                marginBottom: 30,
                border: '1px solid #000',
              }}>
              <Table
                rowKey={(record) => record.idx}
                dataSource={detailData}
                rowClassName={(record, index) => index % 2 !== 0 && 'settlementDataTable-row'}
                scrollToFirstRowOnChange={true}
                loading={isLoadingforDetail}
                columns={detailDataOfFr}
                pagination={paginationForDetail}
                onChange={(pagination) => {
                  setPaginationForDetail(pagination);
                }}
                size="middle"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default SettlementDialog;
