import { ArrowLeftOutlined, QuestionCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import {Button, Form, Input, Modal, Popover, Select} from 'antd';
import React, { Component } from 'react';
import { Marker, NaverMap, Polygon } from 'react-naver-maps';
import { connect } from 'react-redux';
import { httpGet, httpPost, httpUrl } from '../../../api/httpClient';
import { comma } from '../../../lib/util/numberUtil';
import SelectBox from '../../input/SelectBox';
import SearchFranchiseDialog from '../common/SearchFranchiseDialog';
import CloseIcon from '../../../img/login/close.png';

const FormItem = Form.Item;

class RegionSurchargeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navermaps: true,
      mapLat: null,
      mapLng: null,

      deliveryZone: [],
      prevDeliveryZone: [],
      selectMenu: 0,
      mapCenter: {
        lat: 37.643623625321474,
        lng: 126.66509442649551,
      },

      // 신규 할증 구역 parameter
      paths: [],
      viewPaths: [],
      inputName: '',
      inputPrice: '',
      inputDeliveryPriceFeeByDpRegion: '',

      deliveryPriceRegionExcept: 0,
      exceptionFranListOpen: false,
      selectedRegion: null,
      deliveryPriceRegionIdx: null,
      nameEditable: false,
      regionName: '',
      nameRef: React.createRef(),
    };
    this.navermaps = window.naver.maps;
  }

  componentDidMount() {
    this.state.deliveryZone.forEach((value) => {
      value.enabled = true;
    });
    this.getList();
    console.log(this.props.loginInfo);

    // default mapCenter : 지점 위치
    this.setState({
      mapCenter: {
        lat: this.props.loginInfo.branchLatitude,
        lng: this.props.loginInfo.branchLongitude,
      },
    });
  }

  getList = () => {
    httpGet(httpUrl.getDeliveryPriceExtraRegion, [1, 10000], {})
      .then((res) => {
        console.log(res);
        if (res.result === 'SUCCESS') {
          // 처음 시작시 map에 보여지는 좌표
          let viewPaths = [];
          // db(string) => JSON(list<list>) => naverMap(list<navermap.LatLng>)
          let parsedList = [];
          res.data.list.forEach((item) => {
            let parsedItem = { ...item };
            // string => JSON(list<list>)
            const JSONParsed = JSON.parse(parsedItem.polygon);
            // JSON(list<list>) => naverMap(list<navermap.LatLng>)
            let naverParsedList = [];
            JSONParsed.forEach((item) => {
              naverParsedList.push(this.navermaps.LatLng(item[0], item[1]));
            });
            // 최종 parsing 된 객체
            parsedItem.polygon = naverParsedList;
            parsedList.push(parsedItem);

            if (parsedItem.enabled) {
              viewPaths.push(parsedItem);
            }
          });

          this.setState({
            deliveryZone: parsedList,
            prevDeliveryZone: parsedList,
            viewPaths,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        throw e;
      });
  };

  openExceptionFranModal = () => {
    if (!this.state.deliveryPriceRegionIdx) {
      Modal.warn({
        title: '지역선택',
        content: '할증지역을 먼저 선택해주세요.',
      });
      return;
    }
    this.setState({ exceptionFranListOpen: true });
  };
  closeExceptionFranModal = () => {
    this.setState({ exceptionFranListOpen: false });
  };

  handleUpdate = async () => {
    let failed = [];
    for (let i = 0; i < this.state.deliveryZone.length; i++) {
      console.log(i);
      console.log(this.state.deliveryZone[i].enabled);
      console.log(this.state.prevDeliveryZone[i].enabled);
      // if (
      //   this.state.deliveryZone[i].canDeliver !==
      //   this.state.prevDeliveryZone[i].canDeliver
      // ) {
      const res = await httpPost(
        httpUrl.toggleEnabledDeliveryPriceExtraRegion,
        [
          this.state.deliveryZone[i].idx,
          this.state.deliveryZone[i].enabled ? 1 : 0,
        ],
        {}
      );

      console.log('res');
      console.log(res);

      if (res.result !== 'SUCCESS') {
        failed.push(this.state.deliveryZone[i].name);
        this.getList();
      }
      // }
    }
    if (failed.length === 0) {
      Modal.info({
        title: '적용 성공',
        content: '지역할증 적용에 성공했습니다.',
      });
    } else {
      Modal.info({
        title: '적용 실패',
        content: `${failed.map((item, idx) => {
          if (idx === 0) {
            return item.name;
          } else {
            return ', ' + item.name;
          }
        })} 지역의 적용에 실패했습니다.`,
      });
    }
  };
  handleDelete = async () => {
    let failed = [];
    for (let i = 0; i < this.state.deliveryZone.length; i++) {
      const item = this.state.deliveryZone[i];
      if (item.enabled) {
        let res = await httpPost(
          httpUrl.deleteDeliveryPriceExtraRegion,
          [item.idx],
          {}
        );
        if (res.result !== 'SUCCESS') {
          failed.push(item);
        }
      }
    }

    // this.state.deliveryZone.forEach(async (item) => {
    //   if (item.enabled) {
    //     let res = await httpPost(
    //       httpUrl.deleteDeliveryPriceExtraRegion,
    //       [item.idx],
    //       {}
    //     );
    //     if (res.result !== "SUCCESS") {
    //       failed.push(item);
    //     }
    //   }
    // });

    if (failed.length === 0) {
      Modal.info({
        title: '삭제 성공',
        content: '지역할증 삭제에 성공했습니다.',
      });
    } else {
      Modal.info({
        title: '삭제 실패',
        content: `${failed.map((item, idx) => {
          if (idx === 0) {
            return item.name;
          } else {
            return ', ' + item.name;
          }
        })} 지역의 삭제에 실패했습니다.`,
      });
    }
    this.getList();
  };

  deleteCustomzone = async (region) => {
    const res = await httpPost(
      httpUrl.deleteDeliveryPriceExtraRegion,
      [region.idx],
      {}
    );
    console.log(res);
    if (!res.data) {
      Modal.info({
        title: '삭제 실패',
        content: '지역할증 삭제에 실패했습니다.',
      });
      return;
    }
    Modal.info({
      title: '삭제 성공',
      content: '지역할증 삭제에 성공했습니다.',
    });
    this.setState({
      selectedRegion: null,
      nameEditable: false,
    });

    this.getList();
  };

  addPath = (e) => {
    let newPath = this.state.paths;
    newPath.push(new this.navermaps.LatLng(e.coord.y, e.coord.x));
    this.setState({ paths: newPath }, () => console.log(this.state.paths));
  };

  handleToggle = (index) => {
    let newState = [...this.state.deliveryZone];
    newState[index].enabled = !newState[index].enabled;
    this.setState({ deliveryZone: newState }, () => {
      console.log(this.state.deliveryZone);
      console.log(this.state.prevDeliveryZone);
    });
  };

  registCustomZone = () => {
    const deliveryPriceFeeByDpRegion = this.state.inputDeliveryPriceFeeByDpRegion;
    const price = this.state.inputPrice;
    if (!this.state.inputName) {
      Modal.warn({
        title: '등록실패',
        content: '지역할증명을 입력해주세요',
      });
      return;
    } else if (!price) {
      Modal.warn({
        title: '등록실패',
        content: '지역할증 가격을 입력해주세요',
      });
      return;
    } else if (isNaN(deliveryPriceFeeByDpRegion) || deliveryPriceFeeByDpRegion === '' || deliveryPriceFeeByDpRegion < 0) {
      Modal.warn({
        title: '등록실패',
        content: '할증 건별수수료를 정확히 입력해주세요',
      });
      return;
    } else if (isNaN(price) || price === '') {
      Modal.warn({
        title: '등록실패',
        content: '할증 금액을 정확히 입력해주세요',
      });
      return;
    } else if (this.state.paths.length < 3) {
      Modal.warn({
        title: '등록실패',
        content: '지역할증 지역을 정확히 설정해주세요',
      });
      return;
    }
    let listParsedPaths = [];
    this.state.paths.forEach((path) => {
      const listParsedPath = [path.y, path.x];
      listParsedPaths.push(listParsedPath);
    });
    const formData = {
      name: this.state.inputName,
      price,
      deliveryPriceFeeByDpRegion,
      enabled: 1,
      polygon: JSON.stringify(listParsedPaths),
      branchIdx: this.props.branchIdx,
    };

    httpPost(httpUrl.createDeliveryPriceExtraRegion, [], formData).then(
      (res) => {
        if (res.result === 'SUCCESS' && res.data === true) {
          Modal.info({
            title: '등록성공',
            content: '지역할증이 등록되었습니다.',
          });
          this.getList();
          this.setState({ inputName: '', inputPrice: '', inputDeliveryPriceFeeByDpRegion: '', paths: [] });
        } else if (res.data === false) {
          Modal.info({
            title: '등록실패',
            content: '할증금액과 할증 건별수수료를 정확히 입력해주세요.',
          });
        } else {
          Modal.info({
            title: '등록실패',
            content: '관리자에게 문의해주세요.',
          });
        }
      }
    );
  };

  handleToggleCustom = (index) => {
    let newState = this.state.deliveryZone;
    newState[index].enabled = !newState[index].enabled;

    // if (newState[index].toggle) {
    //   let newViewPath = this.state.viewPaths;
    //   newViewPath.push(newState[index].coords);
    //   this.setState({ customDeliveryZone: newState, viewPaths: newViewPath });
    // } else {
    //   this.setState({ customDeliveryZone: newState, viewPaths: [] });
    // }

    // if (this.state.deleteMode) {
    //   const self = this;
    //   Modal.confirm({
    //     onOk: () => {
    //       self.setState({
    //         customDeliveryZone: self.state.customDeliveryZone.filter(
    //           (item) =>
    //             !(item.code === self.state.customDeliveryZone[index].code)
    //         ),
    //         viewPaths: self.state.viewPaths.filter(
    //           (item) =>
    //             !(item.code === self.state.customDeliveryZone[index].code)
    //         ),
    //       });
    //       return;
    //     },
    //   });
    // } else {
    this.setState(
      {
        deliveryZone: newState,
        viewPaths: newState[index].enabled
          ? this.state.viewPaths.concat(this.state.deliveryZone[index])
          : this.state.viewPaths.filter(
              (item) => !(item.idx === this.state.deliveryZone[index].idx)
            ),
      },
      () => {
        if (newState[index].enabled) {
          let sumX = 0;
          let sumY = 0;

          newState[index].polygon.map((el) => {
            sumX += el.x;
            sumY += el.y;
          });
          const meanX = sumX / newState[index].polygon.length;
          const meanY = sumY / newState[index].polygon.length;

          this.setState({
            mapCenter: {
              lat: meanY,
              lng: meanX,
            },
          });
        }
        console.log('viewpaths');
        console.log(this.state.viewPaths);
      }
    );
    // }
  };

  setSelectedRegion = (region) => {
    this.setState({
      selectedRegion: region,
      deliveryPriceRegionIdx: region.idx,
      nameEditable: true,
      regionName: region && region.name ? region.name : '',
    });
  };

  handleSelectedRegionChange = (key, value) => {
    let prevState = { ...this.state.selectedRegion };
    prevState[key] = value;
    console.log(prevState);
    this.setState({ selectedRegion: prevState });
  };

  updateCustomZone = async (region) => {
    const deliveryPriceFeeByDpRegion = region.deliveryPriceFeeByDpRegion;
    const price = region.price;
    if (isNaN(deliveryPriceFeeByDpRegion) || deliveryPriceFeeByDpRegion === '' || deliveryPriceFeeByDpRegion < 0) {
      Modal.info({
        title: "수정 실패",
        content: "할증 건별수수료를 정확히 입력해주세요."
      });
      return;
    }
    if (isNaN(price) || price === '') {
      Modal.info({
        title: "수정 실패",
        content: "할증 금액을 정확히 입력해주세요."
      });
      return;
    }
    const res = await httpPost(httpUrl.updateDeliveryPriceExtraRegion, [], {
      idx: region.idx,
      price: region.price,
      name: this.state.regionName,
      deliveryPriceFeeByDpRegion,
    });
    if (!res.data) {
      Modal.info({
        title: '수정 실패',
        content: '지역할증 수정에 실패했습니다.',
      });
      return;
    }

    Modal.info({
      title: '수정 성공',
      content: '지역할증 수정에 성공했습니다.',
    });
    this.setState({
      selectedRegion: null,
      nameEditable: false,
      regionName: '',
      deliveryPriceRegionIdx: null,
    });
    this.getList();
  };

  exceptionFranCallback = async (exceptionFranList, frIdx, frName) => {
    let res = null;
    if (exceptionFranList.includes(frIdx)) {
      res = await httpPost(httpUrl.deleteDeliveryPriceRegionException, [], {
        frIdx,
        deliveryPriceRegionIdx: this.state.deliveryPriceRegionIdx,
      });
    } else {
      res = await httpPost(httpUrl.createDeliveryPriceRegionException, [], {
        frIdx,
        deliveryPriceRegionIdx: this.state.deliveryPriceRegionIdx,
        frName,
      });
    }
    return res;
  };

  exceptionFranAllCallback = async () => {
    let res = null;
    res = await httpPost(httpUrl.createDpreAll, [], {
      deliveryPriceRegionIdx: this.state.deliveryPriceRegionIdx,
      branchIdx: this.props.branchIdx,
      branchName: this.props.branchName,
    });
    return res;
  };

  exceptionFranNoneCallback = async () => {
    let res = null;
    res = await httpPost(httpUrl.deleteDpreAll, [], {
      deliveryPriceRegionIdx: this.state.deliveryPriceRegionIdx,
    });
    return res;
  };

  getExceptionFranList = async () => {
    const res = await httpGet(
      httpUrl.getDeliveryPriceRegionException,
      [this.state.deliveryPriceRegionIdx],
      {}
    );
    return res;
  };

  render() {
    const lat = 37.643623625321474;
    const lng = 126.66509442649551;

    const navermaps = window.naver.maps;
    const { isOpen, close } = this.props;

    const { Option } = Select;
    let ojoom = [];
    for (let i = 0; i < this.state.deliveryZone.length; i++) {
      ojoom.push(
        <Option key={this.state.deliveryZone[i]}>
          {this.state.deliveryZone[i].name}
        </Option>
      );
    }

    return (
      <React.Fragment>
        <div className="flex-modal-overlay" onClick={close} />

        <div className="flex-wrapper">
          <div className="flex-header">
            <div className="flex-title">지역할증</div>
            <img
              onClick={close}
              src={CloseIcon}
              className="dialog-close"
              alt="close"
            />
          </div>

          <div className="flex-inner zoneBox">
            <div className="zoneBox-inner-left">
              <div className="zone-text" style={{ marginTop: 0 }}>
                할증 지역
                <Popover content={
                  <div>
                    <p>지역이름<br/>(지역할증금액)<br/>(할증 건별수수료)</p>
                    *할증 건별수수료는 0원 이상만 설정가능합니다.
                  </div>
                } title="할증지역 세부항목">
                  <QuestionCircleOutlined style={{marginLeft: 5}}/>
                </Popover>
                <Button
                  onClick={() => this.handleUpdate()}
                  style={{ float: 'right', marginLeft: 5 }}
                  type="primary">
                  적용하기
                </Button>
                {/* <Button
                  checked={this.state.deleteMode}
                  className="zone-radio-btn"
                  type="danger"
                  onClick={() => {
                    Modal.confirm({
                      title: "삭제",
                      content: `삭제하시겠습니까?`,
                      onOk: () => {
                        this.handleDelete();
                        //   this.setState({
                        //     deliveryZone: this.state.deliveryZone.filter(
                        //       (item) => !item.enabled
                        //     ),
                        //     viewPaths: [],
                        //   });
                      },
                    });
                  }}
                >
                  삭제하기
                </Button> */}
              </div>
              <div className="zoneBox-wrap">
                {this.state.deliveryZone.map((obj, idx) => (
                  <div key={obj.idx} className="zone-box">
                    <div
                      className={
                        obj.enabled
                          ? 'zone-b-active sub-zone-active'
                          : 'zone-b sub-zone'
                      }
                      onClick={() => {
                        this.handleToggleCustom(idx);
                        this.setSelectedRegion(obj);
                      }}>
                      {obj.name}
                      <br />
                      <div style={{ fontSize: 13 }}>({comma(obj.price)}원)</div>
                      <div style={{ fontSize: 13 }}>({comma(obj.deliveryPriceFeeByDpRegion)}원)</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="zone-text">
                기존지역 수정
                {this.state.selectedRegion && (
                  <Button
                    onClick={() => {
                      this.setState({
                        selectedRegion: null,
                        nameEditable: false,
                        regionName: '',
                        deliveryPriceRegionIdx: null,
                      });
                    }}
                    className="zone-btn">
                    취소
                  </Button>
                )}
                <Button
                  style={{ marginRight: 5 }}
                  type="primary"
                  onClick={() =>
                    this.updateCustomZone(this.state.selectedRegion)
                  }
                  className="zone-btn">
                  수정하기
                </Button>
                <Button
                  style={{ marginRight: 5 }}
                  type="danger"
                  onClick={() =>
                    this.deleteCustomzone(this.state.selectedRegion)
                  }
                  className="zone-btn">
                  삭제하기
                </Button>
              </div>
              <div style={{ marginBottom: 0 }}>
                <div className="zone-input-title">지역 이름</div>
                <div>
                  <span
                    onDoubleClick={() =>
                      this.state.selectedRegion &&
                      this.setState(
                        {
                          nameEditable: !this.state.nameEditable,
                        },
                        () =>
                          this.state.nameEditable &&
                          this.state.nameRef.current.focus()
                      )
                    }>
                    {!this.state.nameEditable ? (
                      <Select
                        className="desk"
                        placeholder="미선택"
                        style={{ width: 200 }}
                        value={this.state.deliveryPriceRegionIdx}
                        onChange={(value) => {
                          this.setSelectedRegion(
                            this.state.deliveryZone.filter(
                              (x) => x.idx === value
                            )[0]
                          );
                        }}>
                        {this.state.deliveryZone.map((value) => {
                          return (
                            <Option value={value.idx}>{value.name}</Option>
                          );
                        })}
                      </Select>
                    ) : (
                      <Input
                        style={{ width: 200 }}
                        ref={this.state.nameRef}
                        value={this.state.regionName}
                        onChange={(e) => {
                          this.setState({
                            regionName: e.target.value,
                          });
                        }}
                      />
                    )}
                  </span>
                  <Button
                    className="zone-btn"
                    onClick={this.openExceptionFranModal}>
                    예외상점 등록/수정
                  </Button>
                </div>

                {this.state.exceptionFranListOpen && (
                  <SearchFranchiseDialog
                    getExceptionFranList={this.getExceptionFranList}
                    exceptionFranCallback={this.exceptionFranCallback}
                    exceptionFranAllCallback={this.exceptionFranAllCallback}
                    exceptionFranNoneCallback={this.exceptionFranNoneCallback}
                    close={this.closeExceptionFranModal}
                  />
                )}
              </div>

              <div>
                <div className="zone-input-title">금액</div>
                <div className="regionSurcharge-box">
                  <Input
                    value={
                      this.state.selectedRegion
                        ? this.state.selectedRegion.price
                        : null
                    }
                    onChange={(e) => {
                      this.handleSelectedRegionChange('price', e.target.value);
                    }}
                    disabled={!this.state.selectedRegion}
                    className="zone-input"
                  />
                </div>
                <div className="zone-input-title">할증 건별수수료</div>
                <div className="regionSurcharge-box">
                  <Input
                    value={
                      this.state.selectedRegion
                        ? this.state.selectedRegion.deliveryPriceFeeByDpRegion
                        : null
                    }
                    onChange={(e) => {
                      this.handleSelectedRegionChange('deliveryPriceFeeByDpRegion', e.target.value);
                    }}
                    disabled={!this.state.selectedRegion}
                    className="zone-input"
                  />
                </div>
              </div>

              <div className="zone-text">신규지역 등록</div>
              <div>
                <div className="zone-input-title">지역 이름</div>
                <Input
                  value={this.state.inputName}
                  onChange={(e) => this.setState({ inputName: e.target.value })}
                  placeholder="신규 할증지역명을 입력해주세요."
                  className="zone-input"
                />
              </div>

              <div style={{ marginBottom: 0 }}>
                <div className="zone-input-title">금액</div>
                <div className="regionSurcharge-box">
                  <Input
                    value={this.state.inputPrice}
                    onChange={(e) =>
                      this.setState({ inputPrice: e.target.value })
                    }
                    placeholder="신규 할증지역 할증금액을 입력해주세요."
                    className="zone-input"
                  />
                </div>
              </div>
              <div>
                <div className="zone-input-title">할증 건별수수료</div>
                <div className="regionSurcharge-box">
                  <Input
                    value={this.state.inputDeliveryPriceFeeByDpRegion}
                    onChange={(e) =>
                      this.setState({ inputDeliveryPriceFeeByDpRegion: e.target.value })
                    }
                    placeholder="신규 할증지역 할증 건별수수료를 입력해주세요."
                    className="zone-input"
                  />

                  <Button
                    type="primary"
                    onClick={() => this.registCustomZone()}
                    className="zone-btn">
                    등록하기
                  </Button>
                </div>
              </div>
            </div>

            <div className="zoneBox-inner-right">
              {navermaps && (
                <NaverMap
                  defaultZoom={14}
                  center={this.state.mapCenter}
                  // style={{
                  //   width: "550px",
                  //   height: "600px",
                  //   display: "inline-block",
                  // }}
                  className="navermap-region"
                  onClick={(e) => this.addPath(e)}>
                  {this.state.paths.length > 0 && (
                    <Polygon
                      paths={this.state.paths}
                      fillColor={'#ff0000'}
                      fillOpacity={0.3}
                      strokeColor={'#ff0000'}
                      strokeOpacity={0.6}
                      strokeWeight={3}
                    />
                  )}
                  {this.state.viewPaths.length > 0 &&
                    this.state.viewPaths.map((elem) => {
                      let sumX = 0;
                      let sumY = 0;

                      elem.polygon.map((el) => {
                        sumX += el.x;
                        sumY += el.y;
                      });
                      const meanX = sumX / elem.polygon.length;
                      const meanY = sumY / elem.polygon.length;

                      const position = navermaps.LatLng(meanY, meanX);

                      return (
                        <>
                          <Polygon
                            paths={elem.polygon}
                            fillColor={'#ff0000'}
                            fillOpacity={0.3}
                            strokeColor={'#ff0000'}
                            strokeOpacity={0.6}
                            strokeWeight={3}
                          />
                          <Marker
                            position={position}
                            icon={{
                              content: [
                                '<div style="background-color: black; color: #fddc00; padding: 10px 20px; border-radius: 5px; font-size:16px; transform:translate(-50%,-50%);">' +
                                  elem.name +
                                  '</div>',
                              ].join(''),
                            }}
                            title={elem.name}
                          />
                        </>
                      );
                    })}
                  <Button
                    type="primary"
                    icon={<ArrowLeftOutlined />}
                    style={{ zIndex: 1 }}
                    onClick={() => {
                      this.setState(
                        {
                          paths: this.state.paths.slice(
                            0,
                            this.state.paths.length - 1
                          ),
                        },
                        () => {
                          if (this.state.paths.length === 1) {
                            this.setState({
                              paths: [],
                            });
                          }
                        }
                      );
                    }}
                  />
                  <Button
                    type="primary"
                    icon={<ReloadOutlined />}
                    style={{ zIndex: 1 }}
                    onClick={() => this.setState({ paths: [] })}
                  />
                </NaverMap>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
    loginInfo: state.login.loginInfo,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionSurchargeDialog);
