import { Layout } from 'antd';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FranchiseMain, OrderMain, RiderMain, SettingMain } from '../pages';
import MallMain from '../pages/mall/MallMain';
import OrderStats from '../pages/order/OrderStats';
import JoinExitStats from '../pages/joinexit/JoinExitStats';
import NcashCompanyList from '../pages/ncash/NcashCompanyList';
import NcashLayout from '../pages/ncash/NcashLayout';
import AccountMain from '../pages/account/AccountMain';
import AgencyTaxReturn from '../pages/ncash/AgencyTaxReturn';

export default class Content extends Component {
  componentDidMount() {
    // console.log("Content fr idx :"+this.props.selectedFrIdx)
  }

  render() {
    return (
      <Layout.Content style={{ marginTop: 60 }}>
        <div style={{ background: '#fff' }}>
          <Switch>
            {/* 게시글 */}
            <Route exact path="/order/OrderMain" component={OrderMain} />
            <Route exact path="/joinexit/JoinExitStats" component={JoinExitStats} />
            <Route exact path="/order/OrderStats" component={OrderStats} />
            <Route exact path="/franchise/FranchiseMain" component={FranchiseMain} />
            <Route exact path="/rider/RiderMain" component={RiderMain} />
            <Route exact path="/mall/MallMain" component={MallMain} />
            <Route exact path="/setting/SettingMain" component={SettingMain} />
            <Route exact path="/ncash/AgencyTaxReturn" component={AgencyTaxReturn} />
            <Route exact path="/ncash" component={NcashLayout} />
            <Route exact path="/account/AccountMain" component={AccountMain} />
            {/*<Route exact path="/ncash/NcashMain" component={NcashMain} />*/}
            <Redirect to="/" />
          </Switch>
        </div>
      </Layout.Content>
    );
  }
}
