import { Button, DatePicker, Input, Radio, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import xlsx from 'xlsx';
import { httpGetRenewMakeUrl, httpUrl } from '../../api/httpClient';
import ExcelIcon from '../../img/login/excel.png';

const FranchiseStat = () => {
  const period = {
    일간: 0,
    주간: 1,
    월간: 2,
  };
  const { RangePicker } = DatePicker;
  const today = new Date();

  const [startDate, setStartDate] = useState(
    moment(today).add(-6, 'days').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment(today).format('YYYY-MM-DD'));
  const [searchName, setSearchName] = useState('');

  const [list, setList] = useState([]);
  const [excelList, setExcelList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });

  const [columns, setColumns] = useState([]);
  const [statPeriod, setStatPeriod] = useState(0);
  const [originDate, setOriginDate] = useState(
    moment(today).format('YYYY-MM-DD')
  );

  const getList = async () => {
    let pageSize = pagination.pageSize;
    let pageNum = pagination.current;
    let endDateAdd = moment(endDate).clone().add(1, 'd').format('YYYY-MM-DD');

    let params = {
      pageSize,
      pageNum,
      startDate,
      endDate: endDateAdd,
    };

    if (searchName) params['searchName'] = searchName;

    let result = await httpGetRenewMakeUrl(httpUrl.frOrderStats, params, {})
    if(result) {
      const [parserdData, parsedColumns] = await parseStat(

        result.data.franchiseOrderStatList
      );
      setList(parserdData);
      setColumns(parsedColumns);
      setPagination({ ...pagination, total: result.data.totalCount });
    }
  };

  const calcStartDate = (endDate) => {
    if (statPeriod === 0) {
      return endDate.clone().add(-6, 'days').format('YYYY-MM-DD');
    } else if (statPeriod === 1) {
      return endDate
        .clone()
        .add(-4, 'week')
        .startOf('week')
        .format('YYYY-MM-DD');
    } else if (statPeriod === 2) {
      return endDate
        .clone()
        .add(-3, 'month')
        .startOf('month')
        .format('YYYY-MM-DD');
    }
  };

  const calcEndDate = (endDate) => {
    if (statPeriod === 0) {
      return endDate.clone().format('YYYY-MM-DD');
    } else if (statPeriod === 1) {
      return endDate
        .clone()
        .add(-1, 'day')
        .endOf('week')
        .add(1, 'day')
        .format('YYYY-MM-DD');
    } else if (statPeriod === 2) {
      return endDate.clone().endOf('month').format('YYYY-MM-DD');
    }
  };

  const parseStat = async (rawData) => {
    let frSet = new Set();
    let orderDateSet = new Set();

    let parsedData = [];
    let parsedColumns = [
      {
        title: '가맹점명',
        dataIndex: 'frName',
      },
    ];
    const parseDateFormat = (orderDateString) => {
      if (statPeriod === 0) {
        return orderDateString;
      } else if (statPeriod === 1) {
        return moment(orderDateString)
          .add(-1, 'day')
          .startOf('week')
          .format('YYYY-MM-DD');
      } else if (statPeriod === 2) {
        return moment(orderDateString).startOf('month').format('YYYY-MM');
      }
    };

    rawData.forEach((data) => {
      data.userIdx && frSet.add(data.userIdx);
      data.orderDate && orderDateSet.add(parseDateFormat(data.orderDate));
    });

    orderDateSet = Array.from(orderDateSet);
    orderDateSet.sort();

    frSet.forEach((userIdx) => {
      let newParsedData = {
        userIdx,
        frName: rawData.find(x=>x.userIdx === userIdx).frName
      };
      orderDateSet.forEach((orderDate) => {
        newParsedData[`${orderDate}`] = orderDate;
        newParsedData[`total-${orderDate}`] = 0;

        if (
          rawData.find(
            (data) =>
              data.userIdx === userIdx &&
              parseDateFormat(data.orderDate) === parseDateFormat(orderDate) &&
              data.orderStatus === 4
          )
        ) {
          const filteredRawData = rawData.filter(
            (data) =>
              data.userIdx === userIdx &&
              parseDateFormat(data.orderDate) === orderDate &&
              data.orderStatus === 4
          );
          console.log('filteredRawData');
          console.log(filteredRawData);
          filteredRawData.forEach((targetData) => {
            newParsedData[`total-${orderDate}`] =
              newParsedData[`total-${orderDate}`] + targetData.count;
            newParsedData[`complete-${orderDate}`] =
              (newParsedData[`complete-${orderDate}`]
                ? newParsedData[`complete-${orderDate}`]
                : 0) + targetData.count;
          });
          console.log('newParsedData');
          console.log(newParsedData);
        }

        if (
          rawData.find(
            (data) =>
              data.userIdx === userIdx &&
              parseDateFormat(data.orderDate) === orderDate &&
              data.orderStatus === 5
          )
        ) {
          const filteredRawData = rawData.filter(
            (data) =>
              data.userIdx === userIdx &&
              parseDateFormat(data.orderDate) === orderDate &&
              data.orderStatus === 5
          );
          filteredRawData.forEach((targetData) => {
            newParsedData[`total-${orderDate}`] =
              newParsedData[`total-${orderDate}`] + targetData.count;
            newParsedData[`cancel-${orderDate}`] =
              (newParsedData[`cancel-${orderDate}`]
                ? newParsedData[`cancel-${orderDate}`]
                : 0) + targetData.count;
          });
        }
      });
      parsedData.push(newParsedData);
    });

    orderDateSet.forEach((orderDate) => {
      if (
        moment(orderDate).startOf('week').add(1, 'day') < moment(startDate) ||
        moment(endDate) >
          moment(orderDate).endOf('week').add(1, 'day').format('YYYY-MM-DD')
      )
        return;
      parsedColumns.push({
        title:
          statPeriod === 1
            ? moment(orderDate)
                .startOf('week')
                .add(1, 'day')
                .format('YYYY-MM-DD') +
              ' ~ ' +
              moment(orderDate).endOf('week').add(1, 'day').format('YYYY-MM-DD')
            : orderDate,
        dataIndex: '',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            {row[`complete-${orderDate}`] ? row[`complete-${orderDate}`] : 0}(
            {row[`cancel-${orderDate}`] ? row[`cancel-${orderDate}`] : 0})
          </div>
        ),
      });
    });

    let slicedParsedColumns = parsedColumns.slice(1, parsedColumns.length);
    slicedParsedColumns.sort((a, b) => {
      if (moment(a.title) > moment(b.title)) {
        return 1;
      }
      if (moment(a.title) < moment(b.title)) {
        return -1;
      }
      return 0;
    });

    parsedColumns = [parsedColumns[0], ...slicedParsedColumns];

    console.log(parsedData);
    console.log(parsedColumns);

    return [parsedData, parsedColumns];
  };

  useEffect(() => {
    setStartDate(calcStartDate(new moment(originDate)));
    setEndDate(calcEndDate(new moment(originDate)));
  }, [statPeriod]);

  useEffect(() => {
    console.log(startDate);
    console.log(endDate);
    getList();
  }, [pagination.current, pagination.pageSize, startDate, endDate]);

  const getExcelList = async () => {
    let pageSize = pagination.pageSize;
    let pageNum = pagination.current;
    let endDateAdd = moment(endDate).clone().add(1, 'd').format('YYYY-MM-DD');

    let params = {
      pageSize,
      pageNum,
      startDate,
      endDate: endDateAdd,
    };

    if (searchName) params['searchName'] = searchName;

    let result = await httpGetRenewMakeUrl(httpUrl.frOrderStatsForExcel, params, {})
    if(result.result === "SUCCESS") {
      const [parserdData, parsedColumns] = await parseStat(
        result.data.franchiseOrderStatList
      );
      return [parserdData, parsedColumns];
    }
  };


  const onDownload = async (data, excelColumns) => {
    let params = {};
    params['pageSize'] = 10000;
    params['pageNum'] = 1;

    const excelJson = await parseExcelJson(data, excelColumns);
    const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
    const wb = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(wb, ws, '일자별_가맹점_통계');
    xlsx.writeFile(wb, '일자별_가맹점_통계.xlsx');
  };

  const parseExcelJson = async (data, excelColumns) => {
    let result = [];
    let headerJson = {};
    excelColumns.forEach((column) => {
      if (column.title === '가맹점명')
        headerJson[column.dataIndex] = column.title;
      else headerJson[column.title] = column.title;
    });
    result.push(headerJson);

    data.forEach((item) => {
      let parsedItem = { frName: item.frName };
      excelColumns.forEach((column) => {
        if (column.title === '가맹점명') return;
        parsedItem[`${column.title}`] = `${
          item[`complete-${column.title}`]
            ? item[`complete-${column.title}`]
            : 0
        } (${
          item[`cancel-${column.title}`] ? item[`cancel-${column.title}`] : 0
        })`;
      });
      result.push(parsedItem);
    });

    console.log('parseExcelJson result');
    console.log(result);

    return result;
  };

  return (
    <>
      <div className="orderStats">
        <div>
          <Input.Search
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            onSearch={() => {
              if(pagination.current === 1) {
                getList()
              } else {
                setPagination({ ...pagination, current: 1 })
              }
            }}
            placeholder="가맹점명/관제메모/사업자번호/대표자명 입력"
            enterButton
            allowClear
          />
        </div>
        <div>
          <Radio.Group
            value={statPeriod}
            onChange={(e) => setStatPeriod(e.target.value)}
            style={{ marginLeft: 20, marginTop: 20 }}>
            {Object.keys(period).map((key) => {
              return <Radio value={period[key]}>{key}</Radio>;
            })}
          </Radio.Group>
          <DatePicker
            picker={statPeriod === 2 ? 'month' : null}
            placeholder={'일자선택'}
            className="stats-date"
            locale={locale}
            format={statPeriod === 2 ? 'YYYY-MM' : 'YYYY-MM-DD'}
            value={moment(endDate, 'YYYY-MM-DD')}
            onChange={(value) => {
              console.log(value);
              if (statPeriod !== 2) setOriginDate(value);
              setEndDate(calcEndDate(value));
              setStartDate(calcStartDate(value));
            }}
          />
          <Button
            className="tabBtn sectionTab exel"
            onClick={async ()=>{
              const [parserdData, parsedColumns] = await getExcelList();
              await onDownload(parserdData, parsedColumns)
            }}>
            <img src={ExcelIcon} alt="" />
            엑셀 다운로드
          </Button>
        </div>
      </div>
      <Table
        className="stats-tables-not-total"
        dataSource={list}
        columns={columns}
        pagination={{
          ...pagination,
          position: ['bottomCenter'],
        }}
        onChange={setPagination}
      />
    </>
  );
};

export default FranchiseStat;
