import { BankOutlined } from '@ant-design/icons';
import { Affix, Button, Form, Input, Modal, Popover, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { updateComplete, updateError } from '../../api/Modals';
import VirtualTable from '../../components/dialog/common/VirtualTable';
// import AddrDeliveryExceptionDialog from '../../components/dialog/franchise/AddrDeliveryExceptionDialog';
// import BlindControlDialog from "../../components/dialog/franchise/BlindControlDialog";
import { reactLocalStorage } from 'reactjs-localstorage';
import AddrDeliveryExceptionDialog from '../../components/dialog/franchise/AddrDeliveryExceptionDialog';
import BlindFranListDialog from '../../components/dialog/franchise/BlindFranListDialog';
import CopyRegionException from '../../components/dialog/franchise/CopyRegionException';
import DeliveryPriceBasicListDialog from '../../components/dialog/franchise/DeliveryPriceBasicListDialog';
import DuesExceptionDialog from '../../components/dialog/franchise/DuesExceptionDialog';
import FrOrderAgencyDialog from '../../components/dialog/franchise/FrOrderAgencyDialog';
import RegistAccountDialog from '../../components/dialog/franchise/RegistAccountDialog';
import RegistFranDialog from '../../components/dialog/franchise/RegistFranDialog';
import RegistVANDialog from '../../components/dialog/franchise/RegistVANDialog';
import SearchAddressDialog from '../../components/dialog/franchise/SearchAddressDialog';
import SearchRegionException from '../../components/dialog/franchise/SearchRegionException';
import SelectBox from '../../components/input/SelectBox';
import Const from '../../const';
import '../../css/figma/modal.css';
import '../../css/franchise.css';
import '../../css/franchise_m.css';
import ExcelIcon from '../../img/login/excel.png';
import {
  franIsConnectedString,
  statusString,
  tableStatusString,
} from '../../lib/util/codeUtil';
import { formatDateToDay } from '../../lib/util/dateUtil';
import { comma, sortDpbJson } from '../../lib/util/numberUtil';
import ZeroCallFrListDialog from '../../components/dialog/franchise/ZeroCallFrListDialog';
import ZeroCallFrMemoHistory from '../../components/dialog/franchise/ZeroCallFrMemoHistory';
import DeliveryPriceDongRateDialog from '../../components/dialog/franchise/DeliveryPriceDongRateDialog';
import { createSortParam } from "../../lib/util/pagingSortUtil";

const Search = Input.Search;

class FranchiseMain extends Component {
  constructor(props) {
    super(props);
    this.franchiseRef = React.createRef();
    this.state = {
      franchisee: '',
      pagination: {
        total: 0,
        current: 1,
        pageSize: 100,
      },
      paginationStatus: {
        total: 0,
        current: 1,
        pageSize: 2000,
      },
      sortParam: {
        column: 'idx',
        direction: 'desc',
      },
        // test data
      list: [],
      listStatus: {
        connected: 0,
        disconnected: 0,
      },
      withdrawSet: 0,
      franStatus: 1,
      frName: '',
      franGroup: 0,
      franSelectStatus: 0,
      ResistFranchiseOpen: false,
      // duesExceptionOpen: false,
      modifyFranOpen: false,
      SearchAddressOpen: false,
      // blindControlOpen: false,
      dialogData: [],
      accountData: [],
      accountRegistOpen: false,
      blindFrData: [],
      registVANData: [],
      blindListOpen: false,
      inputOpen: false,
      ResistVANOpen: false,
      franIsConnected: 0,

      frOrderAgencyDialogOpen: false,
      selectedFrOrderAgency: null,

      copyRegionException: false,
      searchRegionException: false,

      addrDeliveryExceptionDialogOpen: false,
      // excel data
      data: {},
      openZeroFrList: false,
      openZeroFrMemo: false,
      zeroFrIdx: '',
      zeroFrName: '',
      needFrValidation: false,
      dpbList: [],
      dongRateNameList: [],
      frOrderAgencyCode: '',
    };
    this.excelInputRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
    this.getStatusList();
    // console.log("props tag :"+this.props)
    this.getDpbList();
    this.getDeliveryPriceDongRateList();
  }

  componentWillUnmount() {}

  vanWatinAlert() {
    alert('준비중입니다.');
  }

  // 가맹점 검색
  onSearchFranchisee = (value) => {
    this.setState(
      {
        frName: value,
        pagination: {
          total: 0,
          current: 1,
          pageSize: this.state.pagination.pageSize,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  getDeliveryPriceDongRateList = async () => {
    let dongRateNameList = this.state.dongRateNameList;
    try {
      const res = await httpGet(httpUrl.getDeliveryPriceDongRateList, [], {});
      if (res.result === 'SUCCESS') {
        res.data.list.map((item) => (dongRateNameList[item.idx] = item.name));
        this.setState({
          dongRateNameList: dongRateNameList,
        });
      }
    } catch (error) {
      console.error('getDeliveryPriceDongRateList error: {}', error);
    }
  };

  getDpbList = async () => {
    const res = await httpGet(
      httpUrl.getDeliveryPriceBasicList,
      [10000, 1, ''],
      {}
    );

    console.log(res);

    let sortedList = [];

    if (Array.isArray(res.data.deliveryPriceBasics)) {
      res.data.deliveryPriceBasics.forEach((dpb) => {
        dpb.json = sortDpbJson(dpb.json);
        sortedList.push(dpb);
      });
    } else return;

    this.setState({
      dpbList: sortedList,
    });
  };

  renderDpbTable = (json) => {
    try {
      const distanceAndPrice = JSON.parse(json);
      var list = [];
      var object = { distance: 0, price: 0 };
      for (let i = 0; i < distanceAndPrice.length; i++) {
        object.distance = distanceAndPrice[i][0];
        object.price = distanceAndPrice[i][1];
        list.push({ ...object });
      }

      const columns = [
        {
          title: '거리(m)',
          dataIndex: 'distance',
          className: 'table-column-center',
        },
        {
          title: '100m당 요금',
          dataIndex: 'price',
          className: 'table-column-center',
          render: (data) => <div>{data}원</div>,
        },
      ];

      return <Table dataSource={list} columns={columns} pagination={false} />;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  makeDpbTable = (groupIdx) => {
    const targetIdx = this.state.dpbList.findIndex(
      (dpb) => dpb.groupIdx === groupIdx
    );

    if (targetIdx < 0) return <div>해당 그룹번호 없음</div>;
    else {
      // console.log(this.state.dpbList[targetIdx]);
      return this.renderDpbTable(this.state.dpbList[targetIdx].json);
    }
  };

  openFrOrderAgencyDialog = (row) => {
    this.setState({
      frOrderAgencyDialogOpen: true,
      selectedFrOrderAgency: row,
    });
  };

  closeFrOrderAgencyDialog = () => {
    this.setState({
      frOrderAgencyDialogOpen: false,
      selectedFrOrderAgency: null,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
        sortParam: { column: sorter.field, direction: sorter.order }
      },
      () => this.getList()
    );
  };

  getList = () => {
    const requestSortParam = createSortParam(this.state.sortParam);
    const sort = requestSortParam ?? '';
    httpPost(httpUrl.franchiseListWithSort, [], {
      franIsConnected: this.state.franIsConnected,
      frName: this.state.frName,
      pageNum: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      userGroup: this.state.franGroup,
      userStatus: this.state.franStatus === 0 ? '' : this.state.franStatus,
      sort: sort,
    }).then((result) => {
      // console.log(result.data.franchises);
      const pagination = {
        ...this.state.pagination,
      };
      pagination.current = result.data.currentPage;
      pagination.total = result.data.totalCount;
      this.setState({ list: result.data.franchises, pagination });
    });
  };

  getStatusList = () => {
    httpPost(httpUrl.getFrStatusCount, [], {}).then((res) => {
      if (res.result === 'SUCCESS') {
        let listStatus = {
          connected: 0,
          disconnected: 0,
        };
        res.data.data.forEach((item) => {
          if (item.isConnected) {
            listStatus.connected = item.count;
          } else {
            listStatus.disconnected = item.count;
          }
        });
        // console.log('listStatus');
        // console.log(listStatus);
        this.setState({ listStatus });
      }
    });
  };

  getZeroFrList = () => {
    const BEFORE_DATE = reactLocalStorage.getObject(
      Const.appName + '#zeroCallFrDate'
    );
    const NOW_DATE = moment(new Date()).format('YYYY-MM-DD');
    const NOW_HOURS = Math.floor(new Date().getHours());

    if (
      (Object.keys(BEFORE_DATE).length === 0 && NOW_HOURS >= 8) ||
      (BEFORE_DATE !== null &&
        Object.keys(BEFORE_DATE).length !== 0 &&
        !BEFORE_DATE.includes(NOW_DATE) &&
        NOW_HOURS >= 8)
    ) {
      reactLocalStorage.remove(Const.appName + '#zeroCallFrDate');

      const expiry = moment(new Date()).format('YYYY-MM-DD HH:mm');
      reactLocalStorage.setObject(Const.appName + '#zeroCallFrDate', expiry);

      this.setState(
        {
          openZeroFrList: true,
        },
        () => {
          console.log('getZeroFrList!!!!!!!!!!!!!!!!!!');
        }
      );
    }
  };

  handleSearch = () => {
    this.setState(
      {
        frName: this.franchiseRef.current
          ? this.franchiseRef.current.state.value
          : '',

        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      },
      () => {
        this.getList();
      }
    );
  };
  onSearchFranchiseDetail = (data) => {
    console.log('### get fran list data : ' + data);
    // this.setState({list: data});
  };

  // 가맹점등록 dialog
  openRegistFranchiseModal = () => {
    this.setState({ ResistFranchiseOpen: true });
  };
  closeRegistFranchiseModal = () => {
    this.setState({ ResistFranchiseOpen: false });
  };
  // 가맹점수정 dialog
  openModifyFranModal = (row) => {
    this.setState({
      modifyFranOpen: true,
      dialogData: row,
    });
  };

  closeModifyFranModal = () => {
    this.setState({ modifyFranOpen: false, needFrValidation: false });
    this.getList();
  };

  openCopyRegionException = (row) => {
    this.setState({
      copyRegionException: true,
      dialogData: row,
    });
  };

  closeCopyRegionException = () => {
    this.setState({ copyRegionException: false });
    this.getList();
  };

  openSearchRegionException = (row) => {
    this.setState({
      searchRegionException: true,
      dialogData: row,
    });
  };

  closeSearchRegionException = () => {
    this.setState({ searchRegionException: false });
    this.getList();
  };

  // 주소검색관리 dialog
  openSearchAddressModal = () => {
    this.setState({ SearchAddressOpen: true });
  };
  closeSearchAddressModal = () => {
    this.setState({ SearchAddressOpen: false });
  };

  // VAN등록요청 dialog
  openRegistVANModal = (data) => {
    this.setState({ registVANData: data }, () =>
      this.setState({ ResistVANOpen: true })
    );
  };

  closeRegistVANModal = () => {
    this.setState({ ResistVANOpen: false }, () =>
      this.setState({ registVANData: null })
    );
    this.getList();
  };

  // 출금계좌 dialog
  openAccountModal = () => {
    this.setState({ accountRegistOpen: true });
  };
  closeAccountModal = () => {
    this.setState({ accountRegistOpen: false });
    this.getList();
  };

  // 블라인드 dialog
  openBlindModal = () => {
    this.setState({ blindListOpen: true });
  };
  closeBlindModal = () => {
    this.setState({ blindListOpen: false });
  };

  closeZeroFrList = () => {
    this.setState(
      {
        openZeroFrList: false,
      },
      () => this.getList()
    );
  };

  closeZeroFrMemo = () => {
    this.setState(
      {
        openZeroFrMemo: false,
      },
      () => this.getList()
    );
  };

  openAddrDeliveryExceptionDialog = () => {
    this.setState({
      addrDeliveryExceptionDialogOpen: true,
    });
  };

  closeAddrDeliveryExceptionDialog = () => {
    this.setState({
      addrDeliveryExceptionDialogOpen: false,
    });
  };

  openDeliveryPriceBasicDialog = () => {
    this.setState({
      deliveryPriceBasicDialogOpen: true,
    });
  };
  closeDeliveryPriceBasicDialog = () => {
    this.setState({
      deliveryPriceBasicDialogOpen: false,
    });
  };

  openDeliveryPriceDongRateDialog = () => {
    this.setState({
      deliveryPriceDongRateDialogOpen: true,
    });
  };
  closeDeliveryPriceDongRateDialog = () => {
    this.setState({
      deliveryPriceDongRateDialogOpen: false,
    });
  };

  // 출금설정
  onSetting = (value) => {
    let withdrawSet = value;
    this.setState(
      {
        withdrawSet: withdrawSet,
      },
      () => {
        this.getList();
      }
    );
  };

  // 상태설정
  onStatusSetting = (value) => {
    let franStatus = value;
    this.setState(
      {
        franStatus: franStatus,
      },
      () => {
        this.getList();
      }
    );
  };

  // 가맹점 상태변경
  onChangeStatus = (idx, value) => {
    httpPost(httpUrl.franchiseUpdate, [], {
      idx: idx,
      userStatus: value,
    })
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          updateComplete();
        } else {
          updateError();
        }
        this.getList();
      })
      .catch((e) => {
        updateError();
      });
  };
  getLatLng = async (roadAddress, jibunAddress, formData) => {
    try {
      console.log('getLatLng start');
      if (typeof jibunAddress !== 'undefined' && jibunAddress !== '') {
        jibunAddress = '?query2=' + jibunAddress;
      }
      const address = roadAddress + jibunAddress;
      const res = await httpGet(httpUrl.getGeocode, [address], {});
      formData.addr1 = res.data.roadAddress;
      formData.addr3 = res.data.jibunAddress;
      formData.latitude = res.data.latitude;
      formData.longitude = res.data.longitude;
      // if (res.result === "SUCCESS") {
      //   const data = JSON.parse(res.data.json);
      //   if (data.meta.totalCount === 0) throw "";
      //   if (data.addresses.length > 0) {
      //     formData.latitude = parseFloat(data.addresses[0].y);
      //     formData.longitude = parseFloat(data.addresses[0].x);
      //     formData.addr1 = data.addresses[0].roadAddress
      //       ? data.addresses[0].roadAddress
      //       : data.addresses[0].jibunAddress;
      //     formData.addr3 = data.addresses[0].jibunAddress;
      //     console.log(data);
      //     console.log("getLatLng end");
      //   }
      // } else {
      //   throw "";
      // }
    } catch (e) {
      throw e;
    }
  };

  getFrSalesUserIdx = async (formData) => {
    try {
      console.log('getFrSalesUserIdx start');
      const res = await httpGet(
        httpUrl.riderList,
        [10000, 1, formData.frSalesUserIdx, 1, [1, 2, 3, 4, 5, 6, 7]],
        {}
      );
      console.log(res);
      if (res.data.riders.length > 0) {
        formData['frSalesUserIdx'] = res.data.riders[0].idx;
        console.log('getFrSalesUserIdx end');
        return;
      } else {
        return;
      }
    } catch (e) {
      throw e;
    }
  };
  validateExcelData = async (data) => {
    try {
      const requiredKeyList = [
        '가맹점명',
        '사업자번호',
        '대표자명',
        '가맹점전화번호',
        '휴대전화',
        '주소',
        '지번주소',
        '상세주소',
        '아이디',
        '이메일',
      ];
      let invaliadtedReason = [];
      const regexNum = /[^0-9]/g;
      for (let i = 0; i < requiredKeyList.length; i++) {
        if (!data[requiredKeyList[i]]) {
          invaliadtedReason.push(requiredKeyList[i]);
        } else if (
          (requiredKeyList[i] === '가맹점전화번호' ||
            requiredKeyList[i] === '휴대전화') &&
          regexNum.test(data[requiredKeyList[i]])
        ) {
          invaliadtedReason.push(requiredKeyList[i]);
        } else if (requiredKeyList[i] === '사업자번호') {
          //사업자번호 확인
          if (regexNum.test(data[requiredKeyList[i]])) {
            invaliadtedReason.push(requiredKeyList[i]);
          } else {
            const number = data[requiredKeyList[i]].toString();
            const numData = number
              .replace(/[^0-9]/g, '')
              .split('')
              .map((num) => {
                return parseInt(num, 10);
              });
            if (numData.length === 10) {
              var keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
              var chk = 0;
              keyArr.forEach(function (d, i) {
                chk += d * numData[i];
              });
              chk += parseInt((keyArr[8] * numData[8]) / 10, 10);
              if (Math.floor(numData[9]) !== (10 - (chk % 10)) % 10) {
                invaliadtedReason.push(requiredKeyList[i]);
              }
            } else invaliadtedReason.push(requiredKeyList[i]);
          }
        }
      }
      // if (data.건별가맹비 > 0 && data.건별가맹비 % 100 != 0) {
      //   invaliadtedReason.push('건별가맹비');
      // }
      if (invaliadtedReason.length > 0) {
        return [false, `${invaliadtedReason} 데이터 오류`];
      } else {
        return [true, ''];
      }
    } catch (e) {
      console.log(e.message);
      return [false, '필수 입력값 양식 또는 미기재 오류'];
    }
  };

  createFranchise = async (formData) => {
    try {
      const res = await httpPost(httpUrl.registFranchise, [], formData);
      // console.log('createFranchise');
      // console.log(res);
      if (res.data === 'SUCCESS') {
        return [true, null];
      } else if (res.data === 'ID_DUPLICATED') {
        return await this.createFranchise({
          ...formData,
          id: 'n' + formData.id,
        });
      } else {
        return [false, '요청실패'];
      }
    } catch (e) {
      return [false, '요청실패'];
    }
  };
  removeString = (data) => {
    return data.toString().replace(/[^0-9]/g, '');
  };
  handleExcelRegist = async () => {
    if (this.state.data) {
      let failedIdx = [];
      let failedFrName = [];
      let failedReason = [];
      for (let i = 0; i < this.state.data.length; i++) {
        if (i < 2) continue;
        const data = this.state.data[i];

        data['가맹점전화번호'] = (data['가맹점전화번호']+'').replace(/-/gi, '')
        data['휴대전화'] = (data['휴대전화']+'').replace(/-/gi, '')
        data['사업자번호'] = (data['사업자번호']+'').replace(/-/gi, '')

        const [validationRes, validationReason] = await this.validateExcelData(
          data
        );
        if (!validationRes) {
          failedIdx.push(i - 1);
          failedFrName.push(data['가맹점명']);
          failedReason.push(validationReason);
          continue;
        }
        const charge = data['최초가맹비'] ? moment(this.removeString(data['최초가맹비'])).format('YYYY-MM-DD') : moment(new Date()).add(1, 'M').format( 'YYYY-MM-DD');
        console.log(data['사업자등록일'].toString().replace(/[^0-9]/g, ''));
        const formData = {
          // // EXCEL 로 받는 데이터
          branchIdx: this.props.branchIdx,
          frName: data['가맹점명'],
          frNameExtra: data['실가맹점명'],
          controlMemo: data['관제용메모'],
          businessNumber: data['사업자번호'],
          businessRegistDate: data['사업자등록일']
            ? data['사업자등록일'].toString().replace(/[^0-9]/g, '')
            : '',
          ownerName: data['대표자명'],
          frPhone: data['가맹점전화번호'],
          phone: data['휴대전화'],
          addr1: data['주소'],
          addr3: data['지번주소'],
          addr2: data['상세주소'] ? data['상세주소'] : '',
          transferableCashNcash:
            data['현금코인이체'] === ''
              ? true
              : data['현금코인이체'] === '사용'
              ? true
              : false,
          distType:
            data['거리요금제'] === ''
              ? 0
              : data['거리요금제'] === '직선'
              ? 1
              : data['거리요금제'] === '내비'
              ? 2
              : 0,
          chargeDate: charge,
          riderMemo: data['라이더용메모'],
          overload: data['과적기준'],
          id: data['아이디']
            ? data['아이디']
            : 'n' + String(data['사업자번호']).replace(/-/gi, ''),
          password: data['비밀번호'],
          email: data['이메일'],
          agreeSms:
            data['SMS수신동의'] === '' ? true : data['SMS수신동의'] === '동의',
          basicPickupTime: data['기본호출시간'] ? data['기본호출시간'] : 0,
          duesType: data['가맹비설정'],
          dues:
            data['가맹비설정'] === '수동'
              ? data['가맹비'] === ''
                ? 0
                : data['가맹비']
              : -1,
          duesDeliveryPriceCount: data['건별가맹비건수'] ? data['건별가맹비건수'] : 0,
          duesDeliveryPrice: data['건별가맹비'] ? data['건별가맹비'] : 0,
          simplifiedTaxPayer:
            data['간이사업자여부'] === ''
              ? false
              : data['간이사업자여부'] === '간이사업자',
          tidNormalRate: 100,
          isMember: true,
          nonmemberFee: 0,
          registDate: moment().format('YYYY-MM-DD'),
          ncash: 0,
          userStatus: 1,
          recommenderIdx: '',
          userType: 2,
          withdrawPassword: '0000',
          bank: '',
          bankAccount: '',
          depositor: '',
          userGroup: 0,
          latitude: this.state.targetLat,
          longitude: this.state.targetLng,
          frStatus: 1,
          ncashPayEnabled: false,
          tidNormal: '',
          tidPrepay: '',
          duesAutoChargeEnabled: false,
          frSalesUserIdx: '',
          useBasicDeliveryPrice: data['기본배달비'] === '' ? false : true,
          basicDeliveryPrice: data['기본배달비'] === '' ? 0 : data['기본배달비'],
          basicDeliveryDistance: 0,
          withdrawLimit: 0,
          frOrderAgency: null,
          deliveryPriceBasicGroupIdx: 1,
          dongRateIdx: 0,
          duesPayAtFirstday: data['가맹비 1일 차감'] === '' ? true : data['가맹비 1일 차감'] === '사용',
          duesExtraCount: data['추가가맹비건수'] === '' ? 0 : data['추가가맹비건수'],
          duesExtraPrice: data['추가가맹비금액'] === '' ? 0 : data['추가가맹비금액'],
          deliveryPriceSupportFundCount: data['콜수수료건수'] === '' ? 0 : data['콜수수료건수'],
          deliveryPriceSupportFund: data['콜수수료'] === '' ? 0 : data['콜수수료'],
        };
        console.log('formData');
        console.log(formData);
        try {
          await this.getLatLng(data['주소'], data['지번주소'], formData);
        } catch (e) {
          failedIdx.push(i - 1);
          failedFrName.push(data['가맹점명']);
          failedReason.push('잘못된주소(주소검색불가)');
          continue;
        }

        const [createRes, createReason] = await this.createFranchise(formData);
        if (!createRes) {
          failedIdx.push(i - 1);
          failedFrName.push(data['가맹점명']);
          failedReason.push(createReason);
        }
      }
      if (failedFrName.length > 0) {
        let contentTxt = [];
        for (let i = 0; i < failedFrName.length; i++) {
          contentTxt.push(
            <div>
              {`${failedIdx[i]}번: ${failedFrName[i]} 매장등록이 실패했습니다.`}
              <br />
              {`실패원인 : ${failedReason[i]}`}
            </div>
          );
        }
        Modal.warn({
          title: `총 ${this.state.data.length - 2}개 요청 중 ${
            failedIdx.length
          }개 실패`,
          content: <div style={{maxHeight: 200, overflow: 'scroll'}}>{contentTxt}</div>
        });
      } else if (this.state.data.length < 3) {
        Modal.warn({
          title: '등록 오류',
          content: '양식에 입력된 정보가 없습니다.',
        });
      } else {
        Modal.success({
          title: '등록 성공',
          content: '모든 가맹점이 등록되었습니다.',
        });
      }
    } else {
      Modal.error({
        title: '등록 오류',
        content: '엑셀파일을 등록해주세요.',
      });
    }
    this.getList();
  };

  onResetPW = (row) => {
    let self = this;
    Modal.confirm({
      title: <div> {'로그인 잠금 해제'} </div>,
      content: <div> {'로그인 잠금 해제하겠습니까?'} </div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        console.log({
          idx: row.idx,
          clearPassword: null,
        });
        httpPost(httpUrl.clearPassword, [], {
          userIdx: row.idx,
        })
          .then((result) => {
            console.log(result);
            if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
              // customAlert("완료", "로그인 잠금 해제 완료되었습니다.");
            } else if (result.data === 'NOT_ADMIN') updateError();
            else updateError();
            self.getList();
          })
          .catch((error) => {
            updateError();
          });
      },
    });
  };

  // 출금설정 변경
  onChangeWithdraw = (idx, value) => {
    httpPost(httpUrl.franchiseUpdate, [], {
      idx: idx,
      withdrawEnabled: value,
    })
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          updateComplete();
        } else {
          updateError();
        }
        this.getList();
      })
      .catch((e) => {
        updateError();
      });
  };
  // 엑셀올리기
  readExcel = async (file) => {
    console.log('step!!!');
    let self = this;
    if (file) {
      let reader = new FileReader();
      reader.onload = async () => {
        let data = reader.result;
        let workBook = XLSX.read(data, { type: 'binary' });
        const rows = XLSX.utils.sheet_to_json(
          workBook.Sheets[workBook.SheetNames[0]],
          { defval: '' }
        );
        self.setState({ data: rows }, () => this.handleExcelRegist());
      };
      reader.readAsBinaryString(file);
    }
  };

  setRowClassName = (record) => {
    if (!record.updateTime) return '';
    const connectDate = moment(record.updateTime).add(20, 'minutes');
    return new Date(connectDate) - new Date() < 0 ? '' : 'table-lightyellow';
  };

  addAllException = (idx, frName) => {
    Modal.confirm({
      title: '지역할증 예외처리',
      content:
        frName +
        ' 상점은 모든 지역할증에서 예외처리 됩니다. 실행 하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.addAllDeliveryPriceRegionException, [], {
          frIdx: idx,
          frName,
        })
          .then((result) => {
            if (result.result === 'SUCCESS' && result.data) {
              Modal.info({
                title: '예외등록',
                content: <div>모든 지역할증에서 예외처리되었습니다.</div>,
              });
            } else if (result.result === 'SUCCESS' && !result.data) {
              Modal.info({
                title: '예외등록 실패',
                content: <div>이미 모든 지역할증에서 예외처리되었습니다.</div>,
              });
            } else {
              Modal.error({
                title: '예외등록',
                content: <div>예외등록에 실패하였습니다.</div>,
              });
            }
          })
          .catch((e) => {
            Modal.error({
              title: '예외등록',
              content: <div>예외등록에 실패하였습니다.</div>,
            });
          });
      },
    });
  };

  onDormantRemovalID = (idx) => {
    let self = this;
    Modal.confirm({
      title: <div> {'계정휴면해제'} </div>,
      content: <div> {'계정 휴면 해제하겠습니까?'} </div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        console.log({
          userIdx: idx,
        });
        httpPost(httpUrl.updateLoginDate, [], {
          userIdx: idx,
        })
          .then((result) => {
            console.log(result);
            if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
              updateComplete();
              // customAlert("완료", "로그인 잠금 해제 완료되었습니다.");
            } else updateError();
            self.getList();
          })
          .catch((error) => {
            updateError();
          });
      },
    });
  };

  openZeroCallFrMemoHistory = (row) => {
    console.log(row);
    this.setState({
      openZeroFrMemo: true,
      zeroFrIdx: row.idx,
      zeroFrName: row.frName,
    });
  };

  validateFranchise = (row) => {
    this.setState({
      modifyFranOpen: true,
      dialogData: row,
      needFrValidation: true,
    });
  };

  render() {
    const mobileColumns = [
      // 모바일
      {
        title: '가맹점 정보',
        dataIndex: 'frName',
        className: 'table-column-center mobile',
        render: (data, row) => (
          <div className="status-box">
            <p>
              {row.frName}
              <br />
            </p>
            사업자: {row.businessNumber}
            <br />
            가맹점: {row.frPhone}
            <br />
            대표자: {row.phone}
            <br />
            주소: {row.addr3}
            <br />
            {/* 코인잔액: {comma(row.ncash)} */}
            <div>
              상태 :{' '}
              <SelectBox
                value={statusString[row.userStatus]}
                code={Object.keys(statusString)}
                codeString={statusString}
                onChange={(value) => {
                  if (parseInt(value) !== row.userStatus) {
                    this.onChangeStatus(row.idx, value);
                  }
                }}
              />
              <Button
                className="tabBtn surchargeTab blind-mobilebtn"
                onClick={() =>
                  this.setState({ blindListOpen: true, blindFrData: row })
                }>
                블라인드
              </Button>
            </div>
          </div>
        ),
      },
    ];
    const columns = [
      {
        title: '상태',
        dataIndex: 'userStatus',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div>
            <SelectBox
              value={statusString[data]}
              code={Object.keys(statusString)}
              codeString={statusString}
              onChange={(value) => {
                if (parseInt(value) !== row.userStatus) {
                  this.onChangeStatus(row.idx, value);
                }
              }}
            />
          </div>
        ),
      },
      {
        title: '순번',
        dataIndex: 'idx',
        className: 'table-column-center desk',
        sorter: true,
        render: (data) => <div className="c-p-20">{data}</div>,
      },
      {
        title: '접속상태',
        dataIndex: 'updateTime',
        className: 'table-column-center desk',
        sorter: true,
        render: (data, row) => {
          if (!data) return <div className="c-p-30">미접속</div>;
          const connectDate = moment(data).add(20, 'minutes');
          return new Date(connectDate) - new Date() < 0 ? (
            <div className="c-p-30">미접속</div>
          ) : (
            <div className="c-p-30">접속중</div>
          );
        },
      },
      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center desk',
        sorter: true,
      },
      {
        title: '상호명진위여부',
        dataIndex: 'frNameValid',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div>
            {data === true ? (
              '인증완료'
            ) : (
              <Button onClick={() => this.validateFranchise(row)}>인증</Button>
            )}
          </div>
        ),
      },
      {
        title: '비밀번호',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div className="c-p-20">
            <Button className="tabBtn" onClick={() => this.onResetPW(row)}>
              잠금해제
            </Button>
          </div>
        ),
      },
      {
        title: '관제용 메모',
        dataIndex: 'controlMemo',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-30">{data}</div>,
        // sorter: (a, b) => a.frName.localeCompare(b.frName),
      },
      {
        title: '라이더용 메모',
        dataIndex: 'riderMemo',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-30">{data}</div>,
        // sorter: (a, b) => a.frName.localeCompare(b.frName),
      },
      {
        title: '0콜 사유',
        className: 'table-column-center desk',
        render: (row) => (
          <Button onClick={() => this.openZeroCallFrMemoHistory(row)}>
            내역보기
          </Button>
        ),
      },
      {
        title: '사업자번호',
        dataIndex: 'businessNumber',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-10">{data}</div>,
      },
      {
        title: '가맹점번호',
        dataIndex: 'frPhone',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-10">{data}</div>,
      },
      {
        title: '대표자번호',
        dataIndex: 'phone',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-10">{data}</div>,
      },
      {
        title: '주소',
        dataIndex: 'addr1',
        className: 'table-column-center desk',
        width: 300,
        sorter: true,
        render: (data, row) => <div>{row.addr1 + ' ' + row.addr2}</div>,
      },
      {
        title: '코인잔액',
        dataIndex: 'ncash',
        className: 'table-column-center desk',
        sorter: true,
        render: (data, row) => (
          <div className="c-p-10">
            {comma(
              data - (row.misu ?? 0) - (row.misu2 ?? 0) - (row.misu3 ?? 0)
            )}
          </div>
        ),
      },
      {
        title: 'VAN',
        dataIndex: 'tidNormal',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-20">{data}</div>,
      },
      {
        title: 'PG',
        dataIndex: 'tidPrepay',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-15">{data}</div>,
      },
      {
        title: 'PG 사용여부',
        dataIndex: 'tidNormalRate',
        className: 'table-column-center desk',
        render: (data) => (
          <div className="c-p-25">{data === 100 ? '미사용' : '사용'}</div>
        ),
      },
      {
        title: '출금계좌 정보',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div className="c-p-10">
            {row.bank.split(',')[0] + ' ' + row.bankAccount}
          </div>
        ),
      },
      {
        title: '입금용 가상계좌',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div className="c-p-10">
            {row.vaccountBank
              ? row.vaccountBank.split(',')[0] + ' ' + row.vaccountNumber
              : '-'}
          </div>
        ),
      },
      {
        title: '가입일',
        dataIndex: 'registDate',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-25">{formatDateToDay(data)}</div>,
      },
      {
        title: '영업담당자',
        dataIndex: 'frSalesRiderName',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-25">{data}</div>,
      },

      {
        title: '기본배달비',
        dataIndex: 'basicDeliveryPrice',
        className: 'table-column-center desk',
        sorter: true,
        render: (data, row) => {
          if(!row.useBasicDeliveryPrice) {
            return (<div className="c-p-10">
              {comma(this.props.branchInfo.basicDeliveryPrice)}
            </div>)
          } else {
            return (<div className="c-p-10">
                {comma(data)}
              </div>)
          }
        }
      },
      {
        title: '가맹비설정',
        dataIndex: 'dues',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div>
            {row.dues === -1 ? '자동' : row.dues === 0 ? '무료' : '수동 / '}
            {row.dues > 0 && data}
          </div>
        ),
      },
      {
        title: '최초가맹비납부일',
        dataIndex: 'chargeDate',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-25">{formatDateToDay(data)}</div>,
      },
      {
        title: '건별가맹비',
        dataIndex: 'duesDeliveryPrice',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-25">{comma(data)}</div>,
      },
      {
        title: '요금제그룹',
        dataIndex: 'deliveryPriceBasicName',
        className: 'table-column-center desk',
        render: (data, row) => (
          <Popover
            title={<div style={{ fontWeight: 'bold' }}>거리별요금</div>}
            content={this.makeDpbTable(row.deliveryPriceBasicGroupIdx)}>
            {data}
          </Popover>
        ),
      },
      {
        title: '동별요금제',
        dataIndex: 'dongRateIdx',
        className: 'table-column-center desk',
        render: (data) => (
          <Popover
            title={<div style={{ fontWeight: 'bold' }}>동별요금제</div>}
            content={this.state.dongRateNameList[data]}>
            {this.state.dongRateNameList[data]}
          </Popover>
        ),
      },
      // {
      //   title: '가맹비예외승인',
      //   className: 'table-column-center desk',
      //   render: (data, row) => (
      //     <div>
      //       <Button
      //         className="tabBtn surchargeTab"
      //         onClick={() =>
      //           this.setState({
      //             duesExceptionOpen: true,
      //             duesExceptionData: row,
      //           })
      //         }>
      //         승인신청
      //       </Button>
      //     </div>
      //   ),
      // },
      {
        title: 'VAN등록요청',
        className: 'table-column-center desk',
        render: (row) =>
          row.vaccountNumber && row.tidNormal ? (
            <div>
              <Button
                className="tabBtn surchargeTab"
                disabled
                // onClick={() => this.vanWatinAlert()}
              >
                완료
              </Button>
            </div>
          ) : row.vcrIdx === null ? (
            <div>
              <Button
                className="tabBtn surchargeTab"
                onClick={() => this.openRegistVANModal(row)}
                // onClick={() => this.vanWatinAlert()}
              >
                등록요청
              </Button>
            </div>
          ) : (
            <div>
              <Button
                className="tabBtn surchargeTab"
                style={{ backgroundColor: '#C2C2C2' }}
                onClick={() => this.openRegistVANModal(row)}
                // onClick={() => this.vanWatinAlert()}
              >
                신청
              </Button>
            </div>
          ),
      },
      {
        title: '출금계좌',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn surchargeTab"
              onClick={() =>
                this.setState({ accountRegistOpen: true, accountData: row })
              }>
              등록/수정
            </Button>
          </div>
        ),
      },
      {
        title: '블라인드',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn surchargeTab"
              onClick={() =>
                this.setState({ blindListOpen: true, blindFrData: row })
              }>
              블라인드
            </Button>
          </div>
        ),
      },
      {
        title: '수정',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div>
            {/* {this.state.modifyFranOpen && (
                <RegistFranDialog
                  close={this.closeModifyFranModal}
                  data={this.state.dialogData}
                />
              )} */}
            <Button
              className="tabBtn surchargeTab"
              onClick={() =>
                this.setState({ modifyFranOpen: true, dialogData: row })
              }>
              수정하기
            </Button>
          </div>
        ),
      },
      {
        title: '지역할증',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div>
            {/* {this.state.modifyFranOpen && (
                <RegistFranDialog
                  close={this.closeModifyFranModal}
                  data={this.state.dialogData}
                />
              )} */}
            <Button
              className="tabBtn surchargeTab"
              onClick={() => this.addAllException(row.idx, row.frName)}>
              전체예외처리
            </Button>
            <Button
              className="tabBtn surchargeTab"
              onClick={() => this.openCopyRegionException(row)}>
              복사
            </Button>
            <Button
              className="tabBtn surchargeTab"
              onClick={() => this.openSearchRegionException(row)}>
              조회
            </Button>
          </div>
        ),
      },
      {
        title: '매장연동설정',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn surchargeTab"
              onClick={() => this.openFrOrderAgencyDialog(row)}>
              매장연동설정
            </Button>
          </div>
        ),
      },

      //계정휴면해제 버튼 추가 220905 hms
      {
        title: '계정휴면해제',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div className="c-p-20">
            <Button
              className="tabBtn"
              onClick={() => this.onDormantRemovalID(row.idx)}>
              계정휴면해제
            </Button>
          </div>
        ),
      },
      {
        title: '간이사업자여부',
        dataIndex: 'simplifiedTaxPayer',
        className: 'table-column-center desk',
        render: (data) => <div>{data && 'O'}</div>,
      },
      {
        title: '주문자동취소시간(초)',
        dataIndex: 'autoCancelTimeSec',
        className: 'table-column-center desk',
        render: (data) => <div>{data}</div>,
      },

      {
        title: '상태변경일자',
        dataIndex: 'statusChangeDate',
        className: 'table-column-center desk',
      },
    ];

    const expandedRowRender = (record) => {
      const dropColumns = [
        // 모바일
        {
          title: '세부정보',
          dataIndex: 'chargeDate',
          className: 'table-column-center mobile',
          render: (data, row) => (
            <div>
              <b>코인잔액:</b>{' '}
              {comma(
                row.ncash -
                  (row.misu ?? 0) -
                  (row.misu2 ?? 0) -
                  (row.misu3 ?? 0)
              )}
              <br />
              {/* <b>가맹여부:</b> {row.isMember ? "가맹" : "무가맹"} */}
              {/* <br /> */}
              <b>가입일:</b> {formatDateToDay(row.registDate)}
              <br />
              {/* <b>최초납부일:</b> {formatDateToDay(row.chargeDate)}
              <br /> */}
              {/* <b>월회비:</b> {row.isMember ? row.dues : "-"} */}
              {/* <br /> */}
              <b>VAN:</b> {row.tidNormal}
              <br />
              <b>PG:</b> {row.tidPrepay}
              <br />
              <b>PG사용여부:</b>
              {row.tidNormalRate === 100 ? '미사용' : '사용'}
              <br />
              <b>출금계좌:</b> {row.bank + ' ' + row.bankAccount}
              <br />
              <b>영업담당자:</b> {row.frSalesRiderName}
              <br />
            </div>
          ),
        },

        // {
        //   title: "월회비 최초납부일",
        //   dataIndex: "chargeDate",
        //   className: "table-column-center desk",
        //   render: (data) => <div>{formatDateToDay(data)}</div>,
        // },
        // {
        //   title: "가맹여부",
        //   dataIndex: "isMember",
        //   className: "table-column-center desk",
        //   render: (data) => <div>{data ? "가맹" : "무가맹"}</div>,
        // },

        // {
        //   title: "적용타입",
        //   dataIndex: "applyType",
        //   className: "table-column-center",
        //   render: (data) => <div>{}</div>,
        // },
        // {
        //   title: "월회비",
        //   dataIndex: "dues",
        //   className: "table-column-center desk",
        //   render: (data, row) => <div>{row.isMember ? data : "-"}</div>,
        // },
        // {
        //   title: "카드가맹상태",
        //   dataIndex: "cardStatus",
        //   className: "table-column-center",
        //   render: (data) => <div>{cardStatus[data]}</div>,
        // },
        // {
        //   title: "VAN",
        //   dataIndex: "tidNormal",
        //   className: "table-column-center desk",
        //   render: (data) => <div>{data}</div>,
        // },
        // {
        //   title: "PG",
        //   dataIndex: "tidPrepay",
        //   className: "table-column-center desk",
        //   render: (data) => <div>{data}</div>,
        // },
        // {
        //   title: "PG 사용여부",
        //   dataIndex: "tidNormalRate",
        //   className: "table-column-center desk",
        //   render: (data) => <div>{data === 100 ? "미사용" : "사용"}</div>,
        // },
        // {
        //   title: "메모",
        //   dataIndex: "memo",
        //   className: "table-column-center desk",
        // },
        // {
        //   title: "출금계좌 정보",
        //   className: "table-column-center desk",
        //   render: (data, row) => <div>{row.bank.split(',')[0] + " " + row.bankAccount}</div>,
        // },
        // {
        //   title: "가입일",
        //   dataIndex: "registDate",
        //   className: "table-column-center desk",
        //   render: (data) => <div>{formatDateToDay(data)}</div>,
        // },
        // {
        //   title: "영업담당자",
        //   dataIndex: "frSalesRiderName",
        //   className: "table-column-center desk",
        //   render: (data) => <div>{data}</div>,
        // },
        // {
        //   title: "영업담당자",
        //   dataIndex: "registDate",
        //   className: "table-column-center",
        //   render: (data) => <div>{formatDate(data)}</div>,
        // },
      ];

      return (
        <Table
          className="droptable_fr mobile"
          rowKey={(record) => `record: ${record.idx}`}
          columns={dropColumns}
          dataSource={[record]}
          pagination={false}
        />
      );
    };

    return (
      <div>
        <div className="franchiseContainer">
          <Affix offsetTop={70}>
            <div className="selectLayout">
              <div className="selectLayout-box">
                <div className="searchRequirementText desk">검색조건</div>
                <SelectBox
                  value={tableStatusString[this.state.franStatus]}
                  code={Object.keys(tableStatusString)}
                  codeString={tableStatusString}
                  onChange={(value) => {
                    if (parseInt(value) !== this.state.franStatus) {
                      this.setState(
                        {
                          franStatus: parseInt(value),
                          pagination: {
                            total: 0,
                            current: 1,
                            pageSize: this.state.pagination.pageSize,
                          },
                        },
                        () => this.getList()
                      );
                    }
                  }}
                />

                <Form
                  onFinish={() => {
                    this.handleSearch();
                  }}>
                  <Input
                    ref={this.franchiseRef}
                    placeholder="가맹점검색"
                    allowClear
                    style={{
                      width: 180,
                      marginLeft: 10,
                    }}
                  />
                  <Button type="primary" htmlType="submit">
                    조회
                  </Button>
                </Form>
                {this.state.ResistFranchiseOpen && (
                  <RegistFranDialog
                    getList={this.getList}
                    close={this.closeRegistFranchiseModal}
                  />
                )}
                <Button
                  icon={<BankOutlined />}
                  className="tabBtn addFranTab"
                  onClick={this.openRegistFranchiseModal}>
                  가맹점등록
                </Button>
                {this.state.SearchAddressOpen && (
                  <SearchAddressDialog
                    isOpen={this.state.SearchAddressOpen}
                    close={this.closeSearchAddressModal}
                  />
                )}
                <Button
                  className="tabBtn sectionTab"
                  onClick={this.openSearchAddressModal}>
                  주소검색관리
                </Button>

                {/* 가맹비예외설정 */}
                {this.state.duesExceptionOpen && (
                  <DuesExceptionDialog
                    close={this.closeDuesExceptionModal}
                    data={this.state.duesExceptionData}
                  />
                )}
                {/* VAN등록요청 */}
                {this.state.ResistVANOpen && (
                  <RegistVANDialog
                    close={this.closeRegistVANModal}
                    data={this.state.registVANData}
                  />
                )}

                {/* 출금계좌 */}
                {this.state.accountRegistOpen && (
                  <RegistAccountDialog
                    close={this.closeAccountModal}
                    data={this.state.accountData}
                  />
                )}

                {/* 블라인드 */}
                {this.state.blindListOpen && (
                  <BlindFranListDialog
                    close={this.closeBlindModal}
                    data={this.state.blindFrData}
                  />
                )}
                {/* 가맹비예외설정 */}
                {/* {this.state.duesExceptionOpen && (
                      <DuesExceptionDialog
                          close={this.closeDuesExceptionModal}
                          data={this.state.duesExceptionData}
                      />
                  )} */}
                {/* VAN등록요청 수정 */}
                {this.state.ResistVANOpen && (
                  <RegistVANDialog
                    close={this.closeRegistVANModal}
                    data={this.state.registVANData}
                    getList={() => {
                      this.getList();
                      this.setState({
                        registVANData: {
                          ...this.state.registVANData,
                          vcrIdx: null,
                        },
                      });
                    }}
                  />
                )}

                {/* 출금계좌 */}
                {this.state.accountRegistOpen && (
                  <RegistAccountDialog
                    close={this.closeAccountModal}
                    data={this.state.accountData}
                  />
                )}

                {/* 블라인드 */}
                {this.state.blindListOpen && (
                  <BlindFranListDialog
                    close={this.closeBlindModal}
                    data={this.state.blindFrData}
                  />
                )}
                {this.state.frOrderAgencyDialogOpen && (
                  <FrOrderAgencyDialog
                    close={this.closeFrOrderAgencyDialog}
                    data={this.state.selectedFrOrderAgency}
                  />
                )}

                {this.state.addrDeliveryExceptionDialogOpen && (
                  <AddrDeliveryExceptionDialog
                    close={this.closeAddrDeliveryExceptionDialog}
                  />
                )}

                {this.state.deliveryPriceBasicDialogOpen && (
                  <DeliveryPriceBasicListDialog
                    close={this.closeDeliveryPriceBasicDialog}
                    companyId={this.props.branchInfo.companyId}
                  />
                )}
                {this.state.deliveryPriceDongRateDialogOpen && (
                  <DeliveryPriceDongRateDialog
                    close={this.closeDeliveryPriceDongRateDialog}
                  />
                )}
                {this.state.openZeroFrList && (
                  <ZeroCallFrListDialog close={this.closeZeroFrList} />
                )}

                {this.state.openZeroFrMemo && (
                  <ZeroCallFrMemoHistory
                    frIdx={this.state.zeroFrIdx}
                    frName={this.state.zeroFrName}
                    close={this.closeZeroFrMemo}
                  />
                )}

                {/* <Button className="tabBtn excel" onClick={this.onDownload}>



                  {/* <Button className="tabBtn excel" onClick={this.onDownload}>
                  <img
                    src={ExcelIcon}
                    alt=""
                  />
                  엑셀 다운로드
                </Button> */}

                {/* 엑셀업로드버튼 */}
                <a
                  href="/franchise_regist_templete.xlsx"
                  download
                  className="desk">
                  <Button className="tabBtn sectionTab exel">
                    <img src={ExcelIcon} alt="" />
                    양식 다운로드
                  </Button>
                </a>

                <Button
                  className="tabBtn sectionTab exel desk"
                  onClick={() =>
                    this.setState({ inputOpen: !this.state.inputOpen })
                  }>
                  <img src={ExcelIcon} alt="" />
                  올리기
                </Button>

                <Button
                  className="tabBtn sectionTab exel desk"
                  onClick={() => this.openAddrDeliveryExceptionDialog()}>
                  배송중단 예외설정
                </Button>

                <Button
                  className="tabBtn sectionTab exel desk"
                  onClick={() =>
                    this.setState({
                      openZeroFrList: true,
                    })
                  }>
                  0콜 가맹점 확인
                </Button>

                <Button
                  className="tabBtn sectionTab exel desk"
                  onClick={() => this.openDeliveryPriceBasicDialog()}>
                  거리별요금제설정
                </Button>

                <Button
                  className="tabBtn sectionTab exel desk"
                  onClick={() => this.openDeliveryPriceDongRateDialog()}>
                  동별요금제
                </Button>

                {this.state.inputOpen && (
                  <div
                    className="excelUpload desk"
                    // style={{ marginTop: "15px" }}
                  >
                    <Input
                      ref={this.excelInputRef}
                      type="file"
                      // onChange={this.readExcel}
                    />
                    <Button
                      style={{ height: '40px' }}
                      onClick={() =>
                        this.readExcel(
                          this.excelInputRef.current.input.files[0]
                        )
                      }>
                      일괄등록
                    </Button>
                  </div>
                )}
              </div>

              <div className="frStatus desk">
                <SelectBox
                  value={franIsConnectedString[this.state.franIsConnected]}
                  code={Object.keys(franIsConnectedString)}
                  codeString={franIsConnectedString}
                  onChange={(value) => {
                    if (parseInt(value) !== this.state.franIsConnected) {
                      this.setState(
                        {
                          franIsConnected: parseInt(value),
                          pagination: {
                            total: 0,
                            current: 1,
                            pageSize: this.state.pagination.pageSize,
                          },
                        },
                        () => this.getList()
                      );
                    }
                  }}
                />

                <div className="fr-status">
                  접속중 : {this.state.listStatus.connected}
                </div>
                <div className="fr-status">
                  미접속 : {this.state.listStatus.disconnected}
                </div>
              </div>
            </div>
          </Affix>
          {this.state.modifyFranOpen && (
            <RegistFranDialog
              getList={this.getList}
              isOpen={this.state.modifyFranOpen}
              close={this.closeModifyFranModal}
              data={this.state.dialogData}
              needFrValidation={this.state.needFrValidation}
              companyId={this.props.branchInfo.companyId}
            />
          )}
          {this.state.copyRegionException && (
            <CopyRegionException
              getList={this.getList}
              isOpen={this.state.copyRegionException}
              close={this.closeCopyRegionException}
              data={this.state.dialogData}
            />
          )}
          {this.state.searchRegionException && (
            <SearchRegionException
              getList={this.getList}
              isOpen={this.state.searchRegionException}
              close={this.closeSearchRegionException}
              data={this.state.dialogData}
            />
          )}

          <div id="franchise-table" className="dataTableLayout2 desk">
            <VirtualTable
              rowKey={(record) => record.idx}
              rowClassName={(record) => this.setRowClassName(record)}
              dataSource={this.state.list}
              columns={columns}
              pagination={this.state.pagination}
              onChange={this.handleTableChange}
              scroll={{ x: 3000, y: 600 }}
              style={{ padding: 10 }}
              onRow={(record) => {
                return {
                  onDoubleClick: () => {
                    this.setState({ modifyFranOpen: true, dialogData: record });
                  },
                };
              }}
            />
            {/* <Table
              rowKey={(record) => record.idx}
              dataSource={this.state.list}
              columns={columns}
              pagination={this.state.pagination}
              onChange={this.handleTableChange}
              // expandedRowRender={expandedRowRender}
              // scroll={{ y: "50vh" }}
              scroll={{ x: true }}
              sticky={{ offsetHeader: 60 }}
            /> */}
          </div>
          <div className="dataTableLayout2 mobile">
            <Table
              rowKey={(record) => record.idx}
              dataSource={this.state.list}
              columns={mobileColumns}
              pagination={this.state.pagination}
              onChange={this.handleTableChange}
              expandedRowRender={expandedRowRender}
              // scroll={{ y: "50vh" }}
              // scroll={{ x: true }}
              // sticky={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  branchIdx: state.login.loginInfo.branchIdx,
  branchInfo: state.branchReducer.branchInfo,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(FranchiseMain));
