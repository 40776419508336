let voices = [];

function populateVoiceList() {
  voices = window.speechSynthesis.getVoices().sort((a, b) => {
    const aname = a.name.toUpperCase(),
      bname = b.name.toUpperCase();
    if (aname < bname) return -1;
    else if (aname === bname) return 0;
    else return +1;
  });
}

populateVoiceList();

// function getVoice(lang) {
//     let voice = false;
//     for (let i = 0; i < voices.length ; i++) {
//         // console.log(voices[i]);
//         if (voices[i].lang.indexOf(lang) >= 0 || voices[i].lang.indexOf(lang.replace('-', '_')) >= 0) {
//             voice = voices[i];
//         }
//     }
//     return voice;
// }

function speak(text, lang = 'ko-KR') {
  if (
    typeof SpeechSynthesisUtterance === 'undefined' ||
    !window.speechSynthesis ||
    typeof window.speechSynthesis === 'undefined'
  ) {
    alert('TTS not supported');
    return;
  }

  window.speechSynthesis.cancel();

  const prop = {
    rate: 1,
    pitch: 1,
    lang,
  };

  const speechSynthesis = new SpeechSynthesisUtterance();
  speechSynthesis.onend = function (event) {
    console.log('SpeechSynthesisUtterance.onend');
  };
  speechSynthesis.onerror = function (event) {
    console.error('SpeechSynthesisUtterance.onerror');
  };
  speechSynthesis.rate = prop.rate;
  speechSynthesis.pitch = prop.pitch;
  speechSynthesis.lang = prop.lang;
  speechSynthesis.text = text;
  window.speechSynthesis.speak(speechSynthesis);
}

export { speak };
