import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { httpGet, httpPost, httpUrl } from '../../../api/httpClient';
import CloseIcon from '../../../img/login/close.png';

const AssignShareDialog = (props) => {
  const [branchList, setBranchList] = useState([]);
  const [sharedBranches, setSharedBranches] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);

  const branchIdx = useSelector((state) => state.login.loginInfo.branchIdx);
  useEffect(async () => {
    try {
      const branchListRes = await httpGet(httpUrl.branchList, [1, 100000], {});
      const branchOrderShareRes = await httpGet(
        httpUrl.getBranchAssignShare,
        [branchIdx],
        {}
      );
      if (
        branchListRes.result === 'SUCCESS' &&
        branchOrderShareRes.result === 'SUCCESS'
      ) {
        setBranchList(branchListRes.data.branches);
        const targetBranchIdxes = [];
        branchOrderShareRes.data.branchOrderShareList.forEach(
          (branchOrderShare) => {
            targetBranchIdxes.push(branchOrderShare.targetBranchIdx);
          }
        );
        setSharedBranches(targetBranchIdxes);
        setSelectedBranches(targetBranchIdxes);
      }
    } catch (e) {
      Modal.warn({
        title: props.companyId !== 'connect9' ? '지사조회실패' : '지점조회실패',
        content: props.companyId !== 'connect9'
          ? '지사 정보를 조회하는데 실패했습니다.'
          : '지점 정보를 조회하는데 실패했습니다.',
      });
    }
  }, []);

  const handleToggle = (branchIdx) => {
    if (selectedBranches.includes(branchIdx)) {
      setSelectedBranches(selectedBranches.filter((idx) => branchIdx !== idx));
    } else {
      setSelectedBranches(selectedBranches.concat(branchIdx));
    }
  };

  const submitShare = async () => {
    try {
      const res = await httpPost(
        httpUrl.setBranchAssignShare,
        [],
        selectedBranches
      );
      if (res.data === 'SUCCESS') {
        Modal.info({
          title: '등록 성공',
          content: '추천배차 공유 설정에 성공했습니다.',
        });
        props.close();
      }
    } catch (e) {
      console.error(e);
      Modal.warn({
        title: '등록 실패',
        content: '추천배차 공유 설정에 실패했습니다.',
      });
    }
  };
  return (
    <>
      <div className="flex-modal-overlay">
        <div onClick={(e) => e.stopPropagation()} className={'flex-wrapper'}>
          <div className="flex-header">
            <div className="flex-title">추천배차공유설정</div>
            <img
              onClick={props.close}
              src={CloseIcon}
              className="dialog-close"
              alt="닫기"
            />
          </div>
          <div className="flex-inner flex-wrap">
            {branchList.map((branch) => {
              if (branchIdx === branch.idx) return <></>;
              return (
                <div
                  key={branch.idx}
                  className={
                    selectedBranches.includes(branch.idx)
                      ? 'order-share-icon-on'
                      : 'order-share-icon-off'
                  }
                  onClick={() => handleToggle(branch.idx)}>
                  {branch.branchName}
                </div>
              );
            })}

            <div className="share-btn">
              <Button type="primary" onClick={() => submitShare()}>
                등록하기
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignShareDialog;
