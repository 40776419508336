import { Button, Form, Input, Modal } from 'antd';
import React, { useEffect, useRef } from 'react';
import { httpPost, httpUrl } from '../../../api/httpClient';
import FormItem from 'antd/lib/form/FormItem';

const DeliveryPriceBasicDialog = ({ dpb, close }) => {
  const { deliveryPriceFeeByDpBasic, name, jsono } = dpb;
  const formRef = useRef();

  useEffect(() => {
    if (jsono) {
      const jsonop = JSON.parse(jsono);
      const jsonList = [];
      for (let i = 0; i < jsonop.length; i++) {
        const ojlod = jsonop[i][0];
        const ojlop = jsonop[i][1];
        const jlo = { distance: ojlod, price: ojlop };
        jsonList.push(jlo);
      }
      initFormValue(jsonList);
    } else {
      formRef.current.setFieldsValue({ deliveryPriceFeeByDpBasic: 0 });
    }
  }, []);

  const initFormValue = (jsonList) => {
    formRef.current.setFieldsValue({ deliverPriceBasic: jsonList });
  };
  const handelReset = () => {
    formRef.current.resetFields();
    // formRef.current.setFieldsValue({ name: '', deliveryPriceFeeByDpBasic: '' });
  };

  const validationCheck = (ojlod, ojlop) => {
    if ((!ojlod && ojlod !== 0) || (!ojlop && ojlop !== 0)) {
      return false;
    }
    return true;
  };
  const handleSubmit = () => {
    const formBody = formRef.current.getFieldValue();
    if (!formBody.deliverPriceBasic || formBody.deliverPriceBasic.length <= 0) {
      Modal.warn({ title: '필수값 누락', content: '빈값을 확인해주세요.' });
      return false;
    }
    const jsonList = [];
    for (let i = 0; i < formBody.deliverPriceBasic.length; i++) {
      if (!formBody.deliverPriceBasic[i]) {
        Modal.warn({ title: '필수값 누락', content: '빈값을 확인해주세요.' });
        return false;
      }
      const ojlod = formBody.deliverPriceBasic[i].distance;
      const ojlop = formBody.deliverPriceBasic[i].price;
      const result = validationCheck(ojlod, ojlop);
      if (!result) {
        Modal.warn({ title: '필수값 누락', content: '빈값을 확인해주세요.' });
        return false;
      }
      const jlo = [ojlod, ojlop];
      jsonList.push(jlo);
    }
    const json = JSON.stringify(jsonList);
    formBody['json'] = json;
    if (jsono) {
      handleUpdate(formBody).then();
    } else {
      handleCreate(formBody).then();
    }
  };

  const handleCreate = async (formBody) => {
    const res = await httpPost(httpUrl.createDeliveryPriceBasicGroup, [], {
      ...formBody,
    });
    if (res.result === 'SUCCESS') {
      Modal.info({ title: '등록 성공', content: '등록 되었습니다.' });
      handleClose();
    } else {
      Modal.error({ title: '요청 실패', content: '등록요청이 실패했습니다.' });
    }
  };

  const handleUpdate = async (formBody) => {
    const res = await httpPost(httpUrl.updateDeliveryPriceBasicGroup, [], {
      ...dpb,
      ...formBody,
    });
    if (res.result === 'SUCCESS') {
      Modal.info({ title: '변경 성공', content: '변경 되었습니다.' });
      handleClose();
    } else {
      Modal.error({ title: '요청 실패', content: '변경요청이 실패했습니다.' });
    }
  };
  const createEmptyForm = () => {
    console.log('hello');
    console.log(formRef.current.getFieldValue().deliverPriceBasic);
    let currentFormValue = formRef.current.getFieldValue().deliverPriceBasic;
    if (currentFormValue !== undefined) {
      currentFormValue.push({ distance: '', price: '' });
      formRef.current.setFieldsValue({ deliverPriceBasic: currentFormValue });
    } else {
      formRef.current.setFieldsValue({ deliverPriceBasic: [{ distance: '', price: '' }] });
    }
  };

  const handleClose = () => {
    close();
  };

  return (
    <div className="nn-modal-background">
      <div className="nn-modal-container">
        <div className="nn-modal-title-container">
          <div>거리별 요금제 설정</div>
          <div className="nn-modal-close" onClick={() => close()}></div>
        </div>
        <div className="nn-modal-title-divider"></div>
        <div className="nn-modal-content-wrapper w-[550px]">
          <Form ref={formRef} onFinish={handleSubmit}>
            <div className="flex mb-[20px]">
              <div className="flex-1 mr-[20px]">
                <div className="nn-modal-input-label">요금제명</div>
                <FormItem
                  name="name"
                  style={{ margin: 0 }}
                  rules={[{ required: true, message: '요금제 명을 입력해주세요.' }]}
                  initialValue={name}>
                  <Input className="nn-modal-input" placeholder="요금명을 입력해주세요." />
                </FormItem>
              </div>
              <div className="flex-1">
                <div className="nn-modal-input-label">건별수수료</div>
                <FormItem
                  name="deliveryPriceFeeByDpBasic"
                  style={{ margin: 0 }}
                  rules={[{ required: true, message: '건별수수료를 입력해주세요.' }]}
                  initialValue={deliveryPriceFeeByDpBasic}>
                  <Input
                    className="nn-modal-input"
                    type="number"
                    placeholder="건별수수료를 입력해주세요."
                    suffix="원"
                  />
                </FormItem>
              </div>
            </div>
            <div className="nn-modal-input-label">요금제 설정</div>
            <div className="nn-modal-input-wrapper">
              <div>
                <Button
                  style={{ height: '40px' }}
                  className="nn-modal-button w-[100px] h-[50px] mb-[10px] bg-[#fadc00]"
                  htmlType="button"
                  onClick={() => createEmptyForm()}>
                  추가
                </Button>
              </div>
              <Form.List name="deliverPriceBasic" className="flex h-[40px]">
                {(fields, { add, remove }) => (
                  <div className="m-0">
                    {fields.map(({ key, name, ...restField }, index) => (
                      <div
                        key={key}
                        className="flex mb-[10px] items-center justify-between"
                        style={{ display: "flex", marginBottom: 10 }}>
                        <div className="flex items-center ">
                          <Form.Item
                            {...restField}
                            className="m-0 h-[40px]"
                            name={[name, "distance"]}
                            // rules={[{ required: true, message: "거리를 입력해주세요." }]}
                          >
                            <Input
                              className="nn-modal-input w-[100px]"
                              type="number"
                              style={{ margin: '0px !important' }}
                              placeholder=""
                            />
                          </Form.Item>
                          <div className="ml-[5px] mr-[10px]">m 부터 100m 마다</div>
                          <Form.Item
                            {...restField}
                            className="m-0 h-[40px]"
                            name={[name, "price"]}
                            // rules={[{ required: true, message: "가격을 입력해주세요." }]}
                          >
                            <Input className="nn-modal-input w-[100px]" type="number" placeholder="" />
                          </Form.Item>
                          <div className="ml-[5px]">원 추가</div>
                        </div>
                        <Button
                          className="h-[40px] w-[43px] p-0 rounded-[5px] nn-noto-font-bold"
                          htmlType="button"
                          onClick={() => remove(name)}>
                          삭제
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </Form.List>
            </div>
            <div className="flex justify-between">
              <Button className="nn-modal-button h-[50px] w-[122px]" onClick={handleClose} htmlType="button">
                취소
              </Button>
              <Button className="nn-modal-button h-[50px] w-[122px] bg-[#eeeeee]" onClick={handelReset} htmlType="button">
                초기화
              </Button>
              <Button className="nn-modal-button h-[50px] w-[256px] bg-[#fadc00]" htmlType="submit">
                저장
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPriceBasicDialog;
