import { Button, Form, Input, Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { comma } from '../../lib/util/numberUtil';
import { login } from '../../actions/loginAction';
import { withRouter } from 'react-router-dom';

const FormItem = Form.Item;

class WithdrawAgencyNcash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyIdInfo: {
        ncash: 0,
        bank: '',
        bankAccount: '',
        depositor: ''
      },
      step: 1, //1-초기, 2-발송, 3-인증완료
      smsTimer: 180,
    };
    this.formRef = React.createRef();
    this.smsInterval = null;
  }

  componentDidMount() {
    this.getCompanyIdNcash();
  }
  componentWillUnmount() {
    if (this.smsInterval) clearInterval(this.smsInterval)
  }

  getCompanyIdNcash = async () => {
    let res = await httpGet(
      httpUrl.getCompanyIdInfo,
      [this.props.loginInfo.branchIdx],
      {}
    );
    if (res.result === 'SUCCESS') {
      if (res.data != null && res.data.bank != null) {
        const bank = res.data.bank.substring(0, res.data.bank.indexOf(','));
        res.data.bank = bank;
      }
      this.setState({
        companyIdInfo: res.data,
      });
    }
  };

  handleSubmit = () => {
    const form = this.formRef.current.getFieldsValue();
    if (isNaN(form.withdrawAmount)) {
      Modal.info({
        title: '금액입력 오류',
        content: '출금금액은 숫자만 입력가능합니다.',
      });
      return;
    }
    Modal.confirm({
      title: '출금 요청',
      content: `${comma(form.withdrawAmount)}원을 출금하시겠습니까?`,
      onOk: () => {
        httpPost(httpUrl.reqWithdrawByAgency, [], {
          reqAmount: form.withdrawAmount,
          token: this.token,
        })
          .then((res) => {
            if (res.data === 'SUCCESS') {
              Modal.info({
                title: '출금성공',
                content: '출금에 성공했습니다.',
              });
              this.setState({step: 1})
              this.formRef.current.setFieldsValue({
                code: '',
                withdrawAmount: ''
              })
              this.getCompanyIdNcash();
            } else if (res.data === 'WITHDRAW_REQUEST_SUCCESS') {
              Modal.info({
                title: '출금 요청 성공',
                content: '대리점 본사에 출금 요청을 완료 하였습니다. 대리점 본사에서 승인 시 출금이 진행 됩니다.',
              });
              this.setState({step: 1})
              this.formRef.current.setFieldsValue({
                code: '',
                withdrawAmount: ''
              })
              this.getCompanyIdNcash();
            } else if (res.data === 'BRANCH_NOT_MATCHED') {
              Modal.info({
                title: '출금실패',
                content: '지사정보가 일치하지 않습니다.',
              });
            } else if (res.data === 'USER_TYPE_NOT_MATCHED') {
              Modal.info({
                title: '출금실패',
                content: '관제 계정이 아닌 계정으로 출금시도를 하였습니다.',
              });
            } else if (res.data === 'NOT_AGENCY') {
              Modal.info({
                title: '출금실패',
                content: '대리점 계정이 아닌 계정으로 출금시도를 하였습니다.',
              });
            } else if (res.data === 'SMS_VERIFICATION_INVALID') {
              Modal.info({
                title: '출금실패',
                content: 'SMS인증이 유효하지 않습니다',
              });
            } else if (res.data === 'WITHDRAW_PASSWORD_NOT_MATCH') {
              Modal.info({
                title: '출금실패',
                content: '보안비밀번호가 올바르지 않습니다.',
              });
            } else if (res.data === 'NOT_ENOUGH_NCASH') {
              Modal.info({
                title: '출금실패',
                content: '잔액이 부족합니다.',
              });
            } else {
              Modal.info({
                title: '출금실패',
                content: '출금에 실패했습니다.',
              });
            }
          })
          .catch((e) => {
            Modal.info({
              title: '출금 실패',
              content: '출금에 실패했습니다.',
            });
            throw e;
          });
      },
      onCancel: () => {},
    });
  };

  sendCode = () => {
    const to = this.props.loginInfo?.phone;
    const userIdx = this.props.loginInfo?.idx;
    if (null === to || undefined === to || '' === to) {
      Modal.warn({
        title: '인증실패',
        content:
          '등록된 핸드폰 번호가 유효하지 않습니다. 번호 확인 후 다시 시도해주세요.',
      });
      return;
    }
    if (null === userIdx || undefined === userIdx || '' === userIdx) {
      Modal.warn({
        title: '인증실패',
        content:
          '로그인 정보가 유효하지 않습니다. 다시 로그인 후 시도해주세요.',
      });
      return;
    }
    const self = this;
    Modal.confirm({
      title: '인증번호 전송',
      content: `${this.props.loginInfo.phone} 으로 인증번호를 전송하시겠습니까?`,
      onOk: async () => {
        const res = await httpPost(httpUrl.sendCertiSms, [], {});
        self.token = res.data.token;

        if (!self.token || self.token.length == 0) {
          Modal.warn({
            title: '인증번호 전송실패',
            content: '인증번호 전송에 실패했습니다. 인증코드는 3분이내에 여러번 보낼 수 없습니다.',
          });
        } else {
          this.formRef.current.setFieldsValue({
            code: ''
          })
          self.setState({ step: 2, smsTimer: 180 });
          self.smsInterval = setInterval(()=>{
            const timer = self.state.smsTimer - 1;
            self.setState({ smsTimer: timer });
            if (timer <= 0) {
              self.setState({ step: 1 });
              if (self.smsInterval) clearInterval(self.smsInterval)
            }
          }, 1000)
        }
      },
    });
  };
  checkCode = async () => {
    const form = this.formRef.current.getFieldsValue();

    const res = await httpPost(httpUrl.checkCertiCode, [], {
      token: this.token,
      code: form.code
    });

    if (res.data) {
      this.setState({step: 3})
    }
    else {
      Modal.warn({
        title: '인증실패',
        content: '인증코드가 잘못되었습니다. 다시 확인해주세요.',
      });
    }
  }

  render() {
    return (
      <div className="pwChange-root">
        <div className="pwChange-Layout">
          <div className="pwChange-title">지사입출금</div>
          <br />
          <Form ref={this.formRef} onFinish={this.handleSubmit}>
            <div className="pwChange-box">
              <div className="twl pwChange-list">
                <td>지사잔액: </td>
                <div className="inputBox inputBox-pwChange sub">
                  <Input value={comma(this.state.companyIdInfo?.ncash - (this.state.companyIdInfo?.misu ?? 0)
                      - (this.state.companyIdInfo?.misu2 ?? 0) - (this.state.companyIdInfo?.misu3 ?? 0)) + ' 원'}
                         disabled />
                </div>
              </div>
              <div className="twl pwChange-list">
                <td>입금가상계좌: </td>
                <div className="inputBox inputBox-pwChange sub">
                  <Input
                    value={this.state.companyIdInfo?.vaccountBank && this.state.companyIdInfo?.vaccountNumber && this.state.companyIdInfo?.vaccountDepositor
                        ? this.state.companyIdInfo?.vaccountBank.split(',')[0] + ' ' + this.state.companyIdInfo?.vaccountNumber + ' ' + this.state.companyIdInfo?.vaccountDepositor
                        : '가상계좌를 발급해주세요.'}
                    disabled
                  />
                </div>
              </div>
              <div className="twl pwChange-list">
                <td>출금계좌: </td>
                <div className="inputBox inputBox-pwChange sub">
                  <Input
                    value={
                      this.state.companyIdInfo?.bank && this.state.companyIdInfo?.bankAccount && this.state.companyIdInfo?.depositor ?
                      this.state.companyIdInfo?.bank + ' ' + this.state.companyIdInfo?.bankAccount + ' ' + this.state.companyIdInfo?.depositor
                      : '출금계좌를 등록해주세요.'
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="twl pwChange-list">
                <td>연락처: </td>
                <div className="inputBox inputBox-pwChange sub">
                  <Input value={this.props.loginInfo.phone} disabled />
                  {this.state.step == 1 && (
                    <Button
                      onClick={() => this.sendCode()}
                      style={{position:'absolute'}}
                    >
                      인증번호전송
                    </Button>
                  )}
                  {this.state.step == 2 && (
                    <span style={{color: 'red', position:'absolute', paddingLeft: '10px'}}>{this.state.smsTimer}</span>
                  )}
                  {this.state.step == 3 && (
                    <span style={{color: 'blue', position:'absolute', paddingLeft: '10px'}}>인증완료</span>
                  )}
                </div>
              </div>
              {this.state.step > 1 && (
                <div className="twl pwChange-list">
                  <td style={{ marginRight: -10 }}>인증번호:</td>
                  <div className="inputBox inputBox-pwChange sub">
                    <FormItem
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: 'SMS인증번호를 입력해주세요',
                        },
                      ]}>
                      <Input
                        type="number"
                        disabled={this.state.step == 3}
                        value={this.state.code}
                        placeholder="인증번호를 입력해주세요"
                        onChange={(e) =>
                          this.setState({
                            code: e.target.value,
                          })
                        }
                      />
                    </FormItem>
                    {this.state.step == 2 && (
                      <Button
                        onClick={() => this.checkCode()}
                        style={{position: 'absolute'}}
                      >
                        확인
                      </Button>
                    )}
                  </div>
                </div>
              )}
              <div className="twl pwChange-list">
                <td style={{ marginRight: -10 }}>출금금액:</td>
                <div className="inputBox inputBox-pwChange sub">
                  <FormItem
                    name="withdrawAmount"
                    rules={[
                      { required: true, message: '출금금액을 입력해주세요' },
                    ]}>
                    <Input placeholder="출금할 금액을 입력해주세요" />
                  </FormItem>
                </div>
              </div>
            </div>

            <div className="pwChange-btn">
              <Button
                disabled={this.state.step < 3}
                htmlType="submit"
                className="tabBtn"
                onClick={() => {}}>
                출금하기
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
let mapStateToProps = (state) => {
  return {
    loginInfo: state.login.loginInfo,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (userinfo) => dispatch(login(userinfo)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WithdrawAgencyNcash)
);
