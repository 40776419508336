import { Button, Input, Modal, Table } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../../api/httpClient';
import '../../../css/modal.css';
import { userGroupString } from '../../../lib/util/codeUtil';
import CloseIcon from '../../../img/login/close.png';

const Search = Input.Search;
const today = new Date();

class SearchBydStoreDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      multiList: this.props.multiList,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {}

  handleSelect = (record) => {
    Modal.confirm({
      title: `${record.storeId} 매장으로 연동을 진행하시겠습니까?`,
      onOk: () => {
        this.props.onSelect(record);
        this.props.close();
      },
    });
  };

  onSubmit = async () => {
    const res = await httpGet(
      httpUrl.getFtStore,
      [this.props.businessNumber, this.state.keyword],
      {}
    );
    if (res.data) {
      this.setState({
        multiList: res.data,
      });
    }
  };

  render() {
    const { close, multiList } = this.props;

    const columns = [
      {
        title: '가게명',
        dataIndex: 'storeName',
        className: 'table-column-center desk',
      },
      {
        title: '사업자번호',
        dataIndex: 'bizNo',
        className: 'table-column-center',
      },
      {
        title: '비욘드코드',
        dataIndex: 'storeId',
        className: 'table-column-center desk tableSub',
      },
      {
        title: '대표자명',
        dataIndex: 'ownerName',
        className: 'table-column-center mobile',
      },
      {
        title: '주소',
        dataIndex: 'address',
        className: 'table-column-center desk',
      },
      {
        title: '가게번호',
        dataIndex: 'phoneNo',
        className: 'table-column-center desk',
      },
    ];

    return (
      <React.Fragment>
        <div className="searchFtStore-Dialog-overlay" onClick={close} />
        <div className="searchFtStore-Dialog">
          <div className="searchFranchise-content">
            <div className="searchFranchise-title" style={{ marginBottom: 20 }}>
              연동가맹점 조회
            </div>
            <img
              onClick={close}
              src={CloseIcon}
              className="surcharge-close"
              alt="닫기"
            />

            <div className="layout">
              <Input
                onKeyPress={(e) => {
                  if (e.key === 'Enter') this.onSubmit();
                }}
                value={this.state.keyword}
                onChange={(e) => this.setState({ keyword: e.target.value })}
                placeholder="키워드검색"
                allowClear
                style={{
                  width: 180,
                  marginLeft: 10,
                }}
              />
              <Button type="primary" onClick={() => this.onSubmit()}>
                조회
              </Button>

              <div className="dataTableLayout-01">
                <Table
                  rowKey={(record) => record.storeId}
                  dataSource={this.state.multiList}
                  columns={columns}
                  rowClassName="searchFtStore-row"
                  onRow={(record) => {
                    return {
                      onClick: (e) => {
                        this.handleSelect(record);
                      },
                    };
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SearchBydStoreDialog;
