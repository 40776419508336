import { Button, Form, Input, Modal, Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpPost, httpUrl } from '../../../api/httpClient';
import { customAlert, customError } from '../../../api/Modals';
import '../../../css/modal.css';
import '../../../css/modal_m.css';
import { bankCode, dwServiceTypeCode } from '../../../lib/util/codeUtil';
import CloseIcon from '../../../img/login/close.png';

const Option = Select.Option;
const FormItem = Form.Item;

class RegistAccountDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      staffAuth: 1,
      selRiderList: [],
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {}

  // 출금계좌 등록,수정 처리
  handleSubmit = () => {
    let self = this;
    var bankAccount = self.formRef.current
      .getFieldValue('bankAccount')
      .replace(/\-/gi, '');
    const data = this.props.data;
    Modal.confirm({
      title: '출금계좌 수정',
      content: (
        <div>
          {data
            ? '출금계좌을 수정하시겠습니까?'
            : '출금계좌을 등록하시겠습니까?'}
        </div>
      ),
      okText: '확인',
      cancelText: '취소',
      onOk() {
        if (dwServiceTypeCode.KWANGWON == data.dwServiceType) {
          let url = httpUrl.frAccountModify;
          if (!data.bankAccount) {
            url = httpUrl.frAccountCreate;
          }
          httpPost(url, [], {
            userIdx: data.idx,
            ...self.formRef.current.getFieldsValue(),
            bankAccount,
          })
            .then((res) => {
              if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
                customAlert('새로운 출금계좌가 등록되었습니다.');
              } else if (res.data === 'PG_NOT_REGISTERED') {
                Modal.info({
                  title: '설정실패',
                  content: 'pg 미등록 매장입니다. pg등록을 먼저 진행해주세요.',
                });
                return;
              } else if (res.data === 'WRONG_ACCOUNT_INFO') {
                Modal.info({
                  title: '설정실패',
                  content: '잘못된 계좌정보입니다.',
                });
                return;
              } else {
                Modal.info({
                  title: '설정실패',
                  content:
                    '출금계좌 설정에 실패했습니다. 관리자에게 문의해주세요.',
                });
                return;
              }
              self.props.close();
            })
            .catch((error) => {
              customError(
                '등록 오류',
                '오류가 발생하였습니다. 다시 시도해 주십시오.'
              );
            });
        } else if (dwServiceTypeCode.PAYSTORY == data.dwServiceType) {
          console.log({
            idx: data.userIdx,
            ...self.formRef.current.getFieldsValue(),
            bankAccount: self.formRef.current
              .getFieldValue('bankAccount')
              .replace(/\-/gi, ''),
          });
          httpPost(httpUrl.updateBank, [], {
            idx: data.idx,
            ...self.formRef.current.getFieldsValue(),
            bankAccount: self.formRef.current
              .getFieldValue('bankAccount')
              .replace(/\-/gi, ''),
          })
            .then((res) => {
              console.log(res);
              if (res.result === 'SUCCESS' && res.data.resultCd === 'SUCCESS') {
                customAlert('새로운 출금계좌가 등록되었습니다.');
              } else {
                Modal.info({
                  title: '설정실패',
                  content: res.data.resultMessage,
                });
                return;
              }
              self.props.close();
            })
            .catch((e) => {
              console.error(e);
              customError(
                '등록 오류',
                '오류가 발생하였습니다. 다시 시도해 주십시오.'
              );
            });
        } else {
          customError(
            '입출금 서비스 코드 오류',
            '잘못된 입출금 서비스 코드 입니다. 창 새로고침 후 다시 진행해주세요.'
          );
        }
      },
    });
  };

  render() {
    const { isOpen, close, data } = this.props;

    return (
      <React.Fragment>
        <div className="registAccount-Dialog-overlay" onClick={close} />
        <div className="registAccount-Dialog">
          <div className="registAccount-content">
            <div className="registAccount-title">출금계좌 관리</div>
            <img
              onClick={close}
              src={CloseIcon}
              className="registAccount-close"
              alt="close"
            />

            <Form ref={this.formRef} onFinish={this.handleSubmit}>
              <div className="registAccountlayout">
                <div className="registAccountWrapper">
                  <div className="contentBlock">
                    <div className="mainTitle">은행</div>
                    <FormItem
                      name="bank"
                      className="selectItem"
                      rules={[
                        { required: true, message: '은행을 선택해주세요' },
                      ]}
                      initialValue={data ? data.bank : '기업은행'}>
                      <Select className="override-select">
                        {Object.keys(bankCode).map((key) => {
                          return (
                            <Option value={key + ',' + bankCode[key]}>
                              {key}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">계좌번호</div>
                    <FormItem
                      name="bankAccount"
                      className="selectItem"
                      initialValue={data ? data.bankAccount : null == ''}>
                      <Input
                        placeholder="계좌번호를 입력해 주세요."
                        className="override-input"></Input>
                    </FormItem>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">예금주</div>
                    <FormItem
                      name="depositor"
                      className="selectItem"
                      initialValue={data ? data.depositor : null == ''}>
                      <Input
                        placeholder="예금주를 입력해 주세요."
                        className="override-input"></Input>
                    </FormItem>
                  </div>

                  <div className="submitBlock">
                    <Button type="primary" htmlType="submit">
                      등록하기
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistAccountDialog);
