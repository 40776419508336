import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import { login, logout } from './actions/loginAction';
import { setOrder } from './actions/orderAction';
import MapControlDialog from './components/dialog/order/MapControlDialog';
import Header from './components/Header';
import './App.css';
import Layout from './components/Layout';
import con from './const';
import { Login, NotFound, ReceptionStatus } from './pages';
import { setRider } from './actions/riderAction';
import {getBranchNameList} from './lib/util/branchUtil';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
    };
    this.initializeUserInfo();
  }

  // componentWillMount() {
  //   this.initializeUserInfo();
  // }

  componentDidMount() {
    getBranchNameList(); //최초한번 로딩해준다
    // 지도관제 새창 띄울시 Redux 정보 필요
    // this.initializeOrderInfo();
    // this.initializeRiderInfo();
    // const userInfo = reactLocalStorage.getObject(`${con.appName}#adminUser`);
    // const pathname = this.props.location.pathname.split('/');
    // if (!userInfo.idx && this.props.location.pathname !== "/") {
    //   alert("로그인이 필요합니다.");
    //   this.props.history.push("/");
    // }
    // if (userInfo.idx && this.props.location.pathname === "/") {
    //   this.props.history.push("/main");
    // }
  }

  initializeUserInfo = () => {
    const userInfo = reactLocalStorage.getObject(con.appName + '#adminUser');
    if (!userInfo || !userInfo.id) return;
    this.props.onLogin(userInfo);
  };

  // 지도관제 새창 띄울시 Redux 정보 필요
  // initializeOrderInfo = () => {
  //   const orders = reactLocalStorage.getObject(con.appName + "#orders");
  //   if (!orders) return;
  //   this.props.setOrders("NEW", orders);
  // };

  // initializeRiderInfo = () => {
  //   const riders = reactLocalStorage.getObject(con.appName + "#riders");
  //   if (!riders) return;
  //   this.props.setRiders(riders);
  // };

  render() {
    const { location } = this.props;
    return (
      <Switch>
        <Route exact path="/" component={Login} />
        {location.pathname === '/404' ? (
          <Route component={NotFound} />
        ) : location.pathname ===
          '/components/dialog/order/MapControlDialog' ? (
          <Route
            path="/components/dialog/order/MapControlDialog"
            component={MapControlDialog}
          />
        ) : (
          <Route path="/:page" component={Layout} />
        )}
      </Switch>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo,
    orders: state.orderReducer.orders,
    riders: state.riderReducer.riders,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (userinfo) => dispatch(login(userinfo)),
    onLogout: () => dispatch(logout()),
    setOrders: (category, orders) => dispatch(setOrder(category, orders)),
    setRiders: (category, riders) => dispatch(setRider(category, riders)),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
