import { Button, Input, Modal, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { httpGet, httpPost, httpUrl } from '../../../api/httpClient';

const AddrDeliveryExceptionDialog = ({ close }) => {
  const searchStringRef = useRef();

  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });

  useEffect(async () => {
    await getList();
  }, []);

  useEffect(async () => {
    await getList();
  }, [pagination.current, pagination.pageSize]);

  const getList = async () => {
    console.log(searchStringRef);
    const res = await httpGet(
      httpUrl.get1000CallFranchiseList,
      [
        pagination.pageSize,
        pagination.current,
        searchStringRef.current.input.value,
      ],
      {}
    );

    setList(res.data.frList);
    setPagination({
      ...pagination,
      total: res.data.totalCount,
    });
  };

  const updateAddrDeliveryException = async (
    userIdx,
    addrDeliveryException
  ) => {
    const res = await httpPost(httpUrl.updateAddrDeliveryException, [], {
      userIdx,
      addrDeliveryException,
    });
    console.log(res);
    if (res.data.result === 'SUCCESS') {
      Modal.info({
        title: '수정성공',
        content: res.data.resultMessage,
      });
      await getList();
    } else
      Modal.warn({
        title: '수정실패',
        content: res.data.resultMessage,
      });
  };

  const columns = [
    {
      title: '순번',
      dataIndex: 'userIdx',
      className: 'table-column-center',
    },
    {
      title: '가맹점명',
      dataIndex: 'frName',
      className: 'table-column-center',
    },
    {
      title: '대표자명',
      dataIndex: 'ownerName',
      className: 'table-column-center',
    },
    {
      title: '사업자번호',
      dataIndex: 'businessNumber',
      className: 'table-column-center',
    },
    {
      title: '예외설정',
      dataIndex: 'addrDeliveryException',
      className: 'table-column-center',
      render: (data, row) =>
        data === true ? (
          <Button
            onClick={async () => {
              await updateAddrDeliveryException(row.userIdx, false);
            }}>
            예외설정해제
          </Button>
        ) : (
          <Button
            onClick={async () => {
              await updateAddrDeliveryException(row.userIdx, true);
            }}>
            예외설정
          </Button>
        ),
    },
  ];

  return (
    <div className="flex-modal-overlay" onClick={() => close()}>
      <div className="flex-wrapper" onClick={(e) => e.stopPropagation()}>
        <div className="flex-header">배송중단 예외설정</div>
        <div className="flex-inner" style={{ flexDirection: 'column' }}>
          <Input.Search
            placeholder="아이디/가맹점명/대표자명/검색"
            enterButton
            allowClear
            onSearch={async () => await getList()}
            ref={searchStringRef}
            style={{ marginBottom: 10 }}
          />
          <Table
            rowKey={(record) => record.userIdx}
            rowClassName={'padding10'}
            dataSource={list}
            columns={columns}
            pagination={pagination}
            onChange={(p) => setPagination(p)}
          />
        </div>
      </div>
    </div>
  );
};

export default AddrDeliveryExceptionDialog;
