import { Button, Modal, Table } from 'antd';
import { useEffect, useState } from 'react';
import { customAlert, customError, updateError } from '../../api/Modals';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { importantNotice } from '../../lib/util/codeUtil';
import { formatDate } from '../../lib/util/dateUtil';
import RegistNoticeDialog from '../dialog/order/RegistNoticeDialog';

const initialPagination = {
    total: 0,
    current: 1,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 50, 100],
};

const SendNoticeTable = ({ checkedDeletedCall, refreshTableTrigger, refreshTable }) => {
    const [pagination, setPagination] = useState(initialPagination);
    const [list, setList] = useState([]);
    const [showContent, setShowContent] = useState(0);
    const [updateNotice, setUpdateNotice] = useState(false);
    const [dialogData, setDialogData] = useState(null);

    useEffect(() => {
        setPagination(initialPagination);
        if (refreshTable) {
            refreshTable();
        }
    }, [checkedDeletedCall]);

    useEffect(() => {
        getList();
    }, [refreshTableTrigger, pagination.current, pagination.pageSize]);

    const getList = () => {
        httpGet(httpUrl.noticeList, [checkedDeletedCall, pagination.current, pagination.pageSize, [1, 2, 3]], {}).then(
          (res) => {
              setList(res.data.notices);
              setPagination({
                  ...pagination,
                  total: res.data.totalCount,
              });
          }
        );
    };

    const changeShowContent = (idx) => {
        if (showContent === idx) {
            setShowContent(0);
        } else {
            setShowContent(idx);
        }
    };

    const categoryToString = (category) => {
        const noticecategoryToString = ['', '전체', '라이더', '가맹점', '관제'];
        if (category > 4 || category < 0) {
            return '-';
        } else {
            return noticecategoryToString[category];
        }
    };

    const onDelete = (row) => {
        let self = this;
        console.log(row.important);
        if (!row.important) {
            if (!checkedDeletedCall) {
                Modal.confirm({
                    title: '공지사항 삭제',
                    content: '해당 공지사항을 삭제하시겠습니까?',
                    okText: '확인',
                    cancelText: '취소',
                    onOk() {
                        httpPost(httpUrl.updateNotice, [], {
                            idx: row.idx,
                            deleted: true,
                        })
                          .then((result) => {
                              console.log(result);
                              if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
                                  customAlert('완료', '해당공지사항을 삭제합니다.');
                              } else updateError();
                              getList();
                          })
                          .catch((error) => {
                              console.error(error);
                              customError('삭제오류', '에러가 발생하였습니다. 다시 시도해주세요.');
                          });
                    },
                });
            } else {
                Modal.confirm({
                    title: '공지사항 재공지',
                    content: '해당 공지사항을 재공지하시겠습니까?',
                    okText: '확인',
                    cancelText: '취소',
                    onOk() {
                        httpPost(httpUrl.updateNotice, [], {
                            idx: row.idx,
                            deleted: false,
                        })
                          .then((result) => {
                              if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
                                  customAlert('완료', '해당공지사항을 재공지합니다.');
                              } else updateError();
                              getList();
                          })
                          .catch((error) => {
                              updateError();
                          });
                    },
                });
            }
        } else customError('삭제불가', '해당공지는 중요 공지사항입니다.');
    };

    const columns = [
        {
            title: '제목',
            dataIndex: 'title',
            className: 'table-column-center',
            width: 400,
            render: (data, row) => (
              <>
                  <div
                    className="noticeTag"
                    onClick={() => {
                        changeShowContent(row.idx);
                    }}>
                      {data}
                  </div>
                  {showContent === row.idx && (
                    <div className="table-column-content">
                        {row.content.split('\n').map((line) => {
                            return (
                              <span key={row.idx}>
                    {line}
                                  <br />
                  </span>
                            );
                        })}
                    </div>
                  )}
              </>
            ),
        },
        {
            title: '대상',
            dataIndex: 'category',
            className: 'table-column-center',
            width: 100,
            render: (data) => <div>{categoryToString(data)}</div>,
        },
        {
            title: '날짜',
            dataIndex: 'createDate',
            className: 'table-column-center',
            width: 150,
            render: (data) => <div>{formatDate(data)}</div>,
        },
        {
            title: '노출순위',
            dataIndex: 'sortOrder',
            className: 'table-column-center',
            render: (data) => <div>{data}</div>,
        },
        {
            className: 'table-column-center',
            render:
              !checkedDeletedCall &&
              ((row) => (
                <div>
                    <Button
                      className="tabBtn surchargeTab"
                      onClick={() => {
                          setUpdateNotice(true);
                          setDialogData(row);
                      }}>
                        수정
                    </Button>
                </div>
              )),
        },
        {
            className: 'table-column-center',
            render: (row) => (
              <div>
                  <Button
                    className="tabBtn surchargeTab"
                    onClick={() => {
                        onDelete(row);
                    }}>
                      {!checkedDeletedCall ? <div>삭제</div> : <div>등록</div>}
                  </Button>
              </div>
            ),
        },
    ];

    return (
      <>
          {updateNotice && (
            <RegistNoticeDialog data={dialogData} refreshTable={refreshTable} close={() => setUpdateNotice(false)} />
          )}
          <Table
            scroll={{ y: 500 }}
            // style={{ 'scrollbar-width': 'none' }}
            className="scroll-bar-hidden"
            rowKey={(record) => record.idx}
            rowClassName={(record) => importantNotice[record.important]}
            dataSource={list}
            columns={columns}
            pagination={pagination}
            onChange={(pagination) => setPagination(pagination)}
          />
      </>
    );
};

export default SendNoticeTable;
