import {httpPost, httpUrl} from "../../api/httpClient";

const calcCenter = (location1, location2, location3) => {
  return {
    lat: (location1.lat + location2.lat + location3.lat) / 3,
    lng: (location1.lng + location2.lng + location3.lng) / 3,
  };
};

const rad = (x) => {
  return (x * Math.PI) / 180;
};

const getDist = (p1, p2) => {
  if (!p1 || !p2) return 100000000;
  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) *
      Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return parseInt(d); // returns the distance in meter
};

const setProperNavermapZoom = (location1, location2, location3) => {
  const center = calcCenter(location1, location2, location3);
  const l1FromCenter = getDist(center, location1);
  const l2FromCenter = getDist(center, location2);
  const l3FromCenter = getDist(center, location3);

  const avgDist = (l1FromCenter + l2FromCenter + l3FromCenter) / 3;

  if (avgDist > 30000) {
    return 5;
  } else if (avgDist > 20000) {
    return 8;
  } else if (avgDist > 10000) {
    return 11;
  } else if (avgDist > 5000) {
    return 14;
  } else {
    return 16;
  }
};


const pointInPolygon = function (polygon, point) {
  //A point is in a polygon if a line from the point to infinity crosses the polygon an odd number of times
  let odd = false;
  //For each edge (In this case for each point of the polygon and the previous one)
  for (let i = 0, j = polygon.length - 1; i < polygon.length; i++) {
    //If a line from the point into infinity crosses this edge
    if (((polygon[i].y > point.y) !== (polygon[j].y > point.y)) // One point needs to be above, one below our y coordinate
        // ...and the edge doesn't cross our Y corrdinate before our x coordinate (but between our x coordinate and infinity)
        && (point.x < ((polygon[j].x - polygon[i].x) * (point.y - polygon[i].y) / (polygon[j].y - polygon[i].y) + polygon[i].x))) {
      // Invert odd
      odd = !odd;
    }
    j = i;

  }
  //If the number of crossings was odd, the point is in the polygon
  return odd;
};

const sendAdminInfoAtMapControl = (type) => {
  httpPost(httpUrl.sendAdminInfoAtMapControl, [], {
    type,
  })
      .then()
      .catch(e=> console.error(e))
}

export { calcCenter, rad, getDist, setProperNavermapZoom, pointInPolygon, sendAdminInfoAtMapControl };
