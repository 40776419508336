import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { Marker, NaverMap, Polyline } from 'react-naver-maps';
import { connect } from 'react-redux';
import { customError, deleteError } from '../../../api/Modals';
import {
  httpGet,
  httpGetWithNoLoading,
  httpPost,
  httpPostWithNoLoading,
  httpUrl,
} from '../../../api/httpClient';
import RegistCallDialog from '../../../components/dialog/order/RegistCallDialog';
import SelectBox from '../../../components/input/SelectBox';
import '../../../css/modal.css';
import '../../../css/order.css';
import CloseIcon from '../../../img/login/close.png';
import ArriveRedIcon from '../../../img/login/map/arrive_red.png';
import FranchiseYellowIcon from '../../../img/login/map/franchise_yellow.png';
import MarkerRiderIcon from '../../../img/login/map/marker_rider_2.png';
import MarkerRiderBlueIcon from '../../../img/login/map/marker_rider_blue_2.png';
import MarkerRiderRedIcon from '../../../img/login/map/marker_rider_red_2.png';
import {
  HttpResponseStatusCode,
  arriveReqTime,
  deliveryStatusCode,
  modifyType,
  orderCnt,
  riderLevelText,
  rowColorName, assignRiderFailResult,
} from '../../../lib/util/codeUtil';
import { formatDate, formatHM } from '../../../lib/util/dateUtil';
import { comma, remainTime } from '../../../lib/util/numberUtil';
import {getOrderShareViewInfo} from '../../../lib/util/orderShareUtil';
import {getBranchNameList} from '../../../lib/util/branchUtil';
import {sendAdminInfoAtMapControl} from "../../../lib/util/mapUtil";

const Option = Select.Option;
const navermaps = window.naver.maps;
const Search = Input.Search;

class MapControlDialog extends Component {
  constructor(props) {
    super(props);
    this.franchiseRef = React.createRef();
    this.riderRef = React.createRef();
    this.state = {
      riderName: '',
      phoneNum: '',
      workTab: 0,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 100000,
      },
      paginationList: {
        total: 0,
        totalPage: Number.MAX_VALUE,
        current: 1,
        pageSize: 50,
      },
      paginationRiderList: {
        total: 0,
        current: 1,
        pageSize: 50,
      },
      paginationCallList: {
        total: 0,
        current: 1,
        pageSize: 50,
      },

      // test data
      list: [],
      franchisee: '',

      // 라이더 리스트
      riderListSave: [],
      results: [],
      riderStatus: 1,
      riderLevel: [1, 2],
      userData: 1,
      searchName: '',

      riderListOpen: false,
      selectedRider: '',

      // 라이더 위치
      selectedRiderIdx: 0,
      // riderLocates: [],

      // 라이더 배차된 주문
      riderOrderList: [],

      // 선택된 라이더
      selRider: {},
      selectedRiderLatitude: 37.643623625321474,
      selectedRiderLongitude: 126.66509442649551,
      selectedRiderAssignedOrderCnt: 0,

      // 선택된 라이더 이동경로
      selRiderPath: [],

      modifyOrder: false,

      // 주문 테이블 다중선택
      orderListOpen: false,
      selectedRowKeys: [],
      dataIdxs: [],

      isAssignRider: false,

      waitingList: [],
      callData: [],

      searchFr: '',
      searchText: '',
      searchedColumn: '',

      selOrderCnt: 99,
      allResults: [],
      allResultsSave: [],

      // 가맹점, 라이더 위치 리스트
      frLocates: [],
      riderAllLocates: [],

      // 지도 좌표 경계
      mapBounds: null,
      mapCenter: {
        lat: 0,
        lng: 0,
      },

      loginInfo: {},

      selectedOrdersFromReceptionStatus: [],
      riderTableList: [],
      branchNameList: {},
      selBranchIdx: ['0'],

      skipIfAssigned: false,
    };
    this.mapRef = React.createRef();
  }

  componentDidMount() {
    this.getRiderList(true);
    // this.getRiderLocateList();

    // this.getBounds();
    this.getOrderList();
    this.getUserInfo(this.setMapCenter);
    this.sendAdminInfo("ACCESS");

    this.riderListInterval = setInterval(this.getRiderList, 1000);
    // this.riderLocateListInterval = setInterval(this.getRiderLocateList, 7033);

    this.orderListInterval = setInterval(this.getOrderList, 10033);

    window.opener &&
      window.opener.setOrderSelectCallback((order) => {
        this.orderSelectCallback(order);
      });

    window.opener &&
      window.opener.setOrderUpdateCallback(() => {
        this.getOrderList();
        this.getList();
      });
    window.addEventListener('beforeunload', this.sendAdminInfoOnUnload);
  }

  componentWillUnmount() {
    // if (this.riderLocateListInterval)
    //   clearInterval(this.riderLocateListInterval);
    window.removeEventListener('beforeunload', this.sendAdminInfoOnUnload);
    if (this.riderListInterval) clearInterval(this.riderListInterval);
    if (this.orderListInterval) clearInterval(this.orderListInterval);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.riderReducer !== this.props.riderReducer) {
  //     this.getRiderList();
  //     this.getRiderLocateList();
  //   }
  // }

  // getBounds = () => {
  //   const bounds = this.mapRef.getBounds();
  //   this.setState({ mapBounds: bounds }, () => {});
  // };

  // handleBoundChange = (bounds) => {
  //   this.setState({ mapBounds: bounds });
  // };

  sendAdminInfo = (action) => {
    sendAdminInfoAtMapControl(action);
  }

  sendAdminInfoOnUnload = () => {
    this.sendAdminInfo("OUT");
  }

  // 가맹점검색
  handleFrSearch = () => {
    this.setState(
      {
        searchFr: this.franchiseRef.current
          ? this.franchiseRef.current.state.value
          : '',
      },
      () => {
        this.getOrderList();
      }
    );
  };
  //기사 검색
  handleRiderSearch = () => {
    if (
      this.riderRef.current.state.value !== '' &&
      !this.riderRef.current.state.value
    )
      return;
    let searchList = this.state.results.filter(
      (rider) => rider.riderName.indexOf(this.riderRef.current.state.value) > -1
    );

    const total = searchList.length;
    if (total > this.state.paginationRiderList.pageSize)
      searchList = searchList.slice(0, this.state.paginationRiderList.pageSize);

    this.setState({
      riderTableList: searchList,
      paginationRiderList: {
        ...this.state.paginationRiderList,
        total: total,
        current: 1,
      },
    });
  };

  getUserInfo = (callback) => {
    httpPost(httpUrl.getUserInfo, [], {})
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data) {
          this.setState(
            {
              loginInfo: res.data,
            },
            () => callback()
          );
        }
      })
      .catch((e) => {
        throw e;
      });
  };

  setMapCenter = () => {
    this.setState({
      mapCenter: {
        lat: this.state.loginInfo.branchLatitude
          ? this.state.loginInfo.branchLatitude
          : 0,
        lng: this.state.loginInfo.branchLongitude
          ? this.state.loginInfo.branchLongitude
          : 0,
      },
    });
  };

  assignRiderApi = async (orderIdx, rider, failedOrderList) => {
    try {
      const res = await httpPost(httpUrl.assignRiderAdmin, [], {
        orderIdx: orderIdx,
        userIdx: this.state.selectedRiderIdx,
        skipIfAssigned: this.state.skipIfAssigned,
      });
      this.getList(rider.idx ? rider.idx : rider.userIdx);
      this.getOrderList();
      this.setState({
        selectedRowKeys: [],
        dataIdxs: [],
      });
      if ((res.result === 'SUCCESS' && res.data !== 'SUCCESS') || (res.result !== 'SUCCESS')) {
        failedOrderList.push({
          orderIdx,
          reason: res.data
        });
      }
    } catch (e) {
      console.error("assignRiderApi error", e);
      failedOrderList.push({
        orderIdx,
        reason: 'EXCEPTION'
      });
    }
  };

  orderSelectCallback = async (order) => {
    if ([4, 5].includes(order.orderStatus)) {
      return;
    }
    if (order.orderStatus === 1) {
      this.setState(
        {
          selectedOrdersFromReceptionStatus:
            this.state.selectedOrdersFromReceptionStatus.find(
              (selectedOrder) => selectedOrder.idx === order.idx
            )
              ? this.state.selectedOrdersFromReceptionStatus.filter(
                (selectedOrder) => selectedOrder.idx !== order.idx
              )
              : this.state.selectedOrdersFromReceptionStatus.concat(order),
        },
        () => console.log(this.state.selectedOrdersFromReceptionStatus)
      );
      return;
    }
    const flag = await this.getRiderLocate(order.userIdx);
    if (!flag) return;

    this.setState({
      selectedRiderIdx: order.userIdx,
      riderName: order.riderName,
    });
  };

  // 기사 테이블에서 아이디 클릭했을 때
  // rider : 선택된 라이더 정보
  onSearchWorkerSelected = async (rider) => {
    var self = this;
    const flag = await this.getRiderLocate(
      rider.userIdx ? rider.userIdx : rider.idx
    );
    if (!flag) return;

    this.setState(
      {
        selectedRiderIdx: rider.userIdx ? rider.userIdx : rider.idx,
        riderName: rider.riderName,
      },
      () => {
        let failedOrderList = [];
        if (self.state.selectedRowKeys.length > 0) {
          if (rider.riderStatus === 1) {
            Modal.confirm({
              title: '배차 설정',
              content: `${self.state.selectedRowKeys} 번의 주문을 ${rider.riderName} 기사에게 배정하시겠습니까?`,
              onOk: async () => {
                const selectedRowKeys = self.state.selectedRowKeys;
                for (let i = 0; i < selectedRowKeys.length; i++) {
                  let orderIdx = selectedRowKeys[i];
                  await self.assignRiderApi(orderIdx, rider, failedOrderList);
                  console.log('orderIdx');
                  console.log(orderIdx);
                }
                self.setState({
                  selectedRowKeys: [],
                });
                if (Array.isArray(failedOrderList) && failedOrderList.length > 0) {
                  for (let i = 0; i < failedOrderList.length; i++) {
                    const failedOrder = failedOrderList[i];
                    if (failedOrder?.reason) {
                      const content = `${failedOrder.orderIdx} 번 주문이 주문 배차에 실패했습니다.\n이유는 다음과 같습니다.\n${assignRiderFailResult[failedOrder.reason]}`;
                      Modal.error({
                        title: '배차 실패',
                        content: <div style={{ whiteSpace: 'pre' }}>{content}</div>,
                        onOk() {},
                      });
                    }
                  }
                }
              },
              onCancel: () => { },
            });
          } else {
            customError('배차 실패', '근무중인 라이더가 아닙니다.');
          }
        } else {
          this.getList(rider.idx);
        }
      }
    );
  };

  setRiderOrderData = (result) => {
    var list = result.data.orders;
    var addPaths = [];
    var addRiderLocates = [];
    var addFrLocates = [];
    for (let i = 0; i < list.length; i++) {
      var addPath = [];
      addPath[0] = navermaps.LatLng(
        this.state.selectedRiderLatitude,
        this.state.selectedRiderLongitude
      );
      if (list[i].orderStatus === 3) {
        addPath[1] = navermaps.LatLng(list[i].latitude, list[i].longitude);
      } else {
        addPath[1] = navermaps.LatLng(list[i].frLatitude, list[i].frLongitude);
        addPath[2] = navermaps.LatLng(list[i].latitude, list[i].longitude);
        addFrLocates.push(
          navermaps.LatLng(list[i].frLatitude, list[i].frLongitude)
        );
      }
      addPaths[i] = addPath;
      addRiderLocates.push(
        navermaps.LatLng(list[i].latitude, list[i].longitude)
      );
    }
    const pagination = { ...this.state.pagination };
    pagination.current = result.data.currentPage;

    this.setState({
      selRider: result.data,
      selRiderPath: addPaths,
      frLocates: addFrLocates,
      riderAllLocates: addRiderLocates,
      riderOrderList: list,
      pagination,
    });
  };

  getList = (riderIdx) => {
    let selectedRiderIdx;
    if (riderIdx) selectedRiderIdx = riderIdx;
    else selectedRiderIdx = this.state.selectedRiderIdx;
    var p = { ...this.state.pagination };
    httpPost(httpUrl.getAssignedRider, [], {
      pageNum: p.current,
      pageSize: p.pageSize,
      userIdx: parseInt(selectedRiderIdx),
    }).then((result) => {
      if (result.result === 'SUCCESS') {
        if (result.data !== null) {
          this.setRiderOrderData(result);
        } else {
          this.setState({
            riderOrderList: [],
          });
        }
      } else
        customError(
          '배차 목록 오류',
          '배차목록을 불러오는 데 실패했습니다. 관리자에게 문의하세요.'
        );
    });
  };

  getOrderList = async () => {
    // 기존 api 방식

    const now = new moment();

    const res = await getOrderShareViewInfo(this.props.loginInfo.branchIdx, true)

    var data = {
      orderStatuses: [1],
      pageNum: this.state.paginationCallList.current,
      pageSize: this.state.paginationCallList.pageSize,
      paymentMethods: [1, 2, 3],
      startDate: now.clone().add(-3, 'h').format('YYYY-MM-DD HH:mm'),
      endDate: now
        .clone()
        .add('1', 'd')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm'),
      frName: this.state.searchFr,
      branchIdxes: res.view
    };

    console.log(data);

    httpPostWithNoLoading(httpUrl.orderList, [], data)
      .then((res) => {
        if (res.result === 'SUCCESS') {
          // console.log("getOrderList");

          // console.log(res);
          const paginationCallList = { ...this.state.paginationCallList };
          paginationCallList.current = res.data.currentPage;
          paginationCallList.total = res.data.totalCount;
          this.setState({
            waitingList: res.data.orders,
            callData: res.data.orders,
            paginationCallList,
          });
        } else {
          Modal.info({
            title: '적용 오류',
            content: '처리가 실패했습니다.',
          });
        }
      })
      .catch((e) => {
        Modal.info({
          title: '적용 오류',
          content: '처리가 실패했습니다.',
        });
      });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.paginationCallList,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        paginationCallList: pager,
      },
      () => this.getOrderList()
    );
  };

  getRiderLocate = (riderIdx) => {
    return new Promise((resolve, reject) => {
      httpGet(httpUrl.riderLocate, [riderIdx], {}).then((result) => {
        if (result.data !== null) {
          console.log('getRiderLocate');
          console.log(result);
          this.setState(
            {
              selectedRiderAssignedOrderCnt: result.data.assignedOrderCnt,
              mapCenter: {
                lat: result.data.latitude,
                lng: result.data.longitude,
              },
              selectedRiderLatitude: result.data.latitude,
              selectedRiderLongitude: result.data.longitude,
              selectedRiderIdx: result.data.userIdx,
              riderName: result.data.riderName,
            },
            () => {
              this.getList();
            }
          );
          resolve(true);
        } else {
          Modal.info({
            title: '위치정보 조회 오류',
            content: '라이더의 위치정보가 존재하지 않습니다.',
          });
          reject(false);
        }
      });
    });
  };

  getRiderList = (isInitialization = false) => {
    // 기존 api 방식
    const pageNum =
      this.state.paginationList.current > this.state.paginationList.totalPage
        ? 1
        : this.state.paginationList.current;

    // console.log(pageSize, pageNum);

    httpGetWithNoLoading(httpUrl.riderListRedis, [], {}).then((result) => {
      const pagination = { ...this.state.paginationList };
      pagination.current = pageNum + 1;
      pagination.total = result.data.totalCount;
      pagination.totalPage = result.data.totalPage;

      let riderList = [...this.state.results];

      console.log(result);
      const now = new moment();
      result.data.riders.forEach((rider) => {
        const targetIdx = riderList.findIndex((rlr) => rlr.idx === rider.idx);
        rider.updateTime = now;
        if (targetIdx > -1) riderList.splice(targetIdx, 1, rider);
        else riderList.push(rider);
      });

      riderList = riderList.filter((rider) => {
        if (
          rider.updateTime &&
          moment.duration(now.diff(rider.updateTime)).asMinutes() > 1
        )
          return false;
        return true;
      });
      riderList.sort((a, b) => a.idx - b.idx);

      let branchNameList =
        this.props.loginInfo.companyId !== 'connect9'
          ? { 0: '전체지사' }
          : { 0: '전체지점' };
      branchNameList =
        riderList.length !== 0
          ? _.uniqBy(riderList, 'branchIdx').map(function (item) {
            branchNameList[item.branchIdx] = item.branchName;
            return branchNameList;
          })[0]
          : branchNameList;


      riderList = riderList.filter((row) =>
        this.state.selBranchIdx.includes('0')
          ? true
          : this.state.selBranchIdx.includes('' + row.branchIdx)
      )
      this.setState(
        {
          results: riderList,
          paginationList: pagination,
          paginationRiderList: {
            ...this.state.paginationRiderList,
            total: riderList.length,
          },
          branchNameList: branchNameList,
        },
        () => {
          // console.log("this.state.results");
          // console.log(this.state.results);
          this.handleListTableChange(this.state.paginationRiderList);
        }
      );
    });
  };

  // 주문수정 dialog
  openModifyOrderModal = (order) => {
    this.setState({ data: order, modifyOrder: true });
  };
  closeModifyOrderModal = () => {
    this.setState({ modifyOrder: false });
  };

  // 주문 삭제 API
  deleteAssign = (orderIdx) => {
    let self = this;
    Modal.confirm({
      title: '배차 삭제',
      content: <div> 배차목록을 삭제하시겠습니까?</div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.assignRiderCancel, [], { orderIdx: orderIdx })
          .then((res) => {
            if (res.result === 'SUCCESS') {
              switch (res.data) {
                case 'SUCCESS':
                  self.getList();
                  self.getOrderList();
                  break;
                case 'ALREADY_CANCELED':
                  customError('배차 오류', '이미 취소된 배차입니다.');
                  break;
                case 'ORDER_NOT_EXISTS':
                  customError('배차 오류', '존재하지 않은 주문입니다.');
                  break;
                case 'PICKUPED_ORDER':
                  customError('배차 오류', '이미 픽업된 배차입니다.');
                  break;
                case 'COMPLETED_ORDER':
                  customError('배차 오류', '이미 주문 완료된 배차입니다.');
                  break;
                case 'NOT_ADMIN':
                  customError(
                    '배차 오류',
                    '관리자만 추천배차를 해제할 수 있습니다.'
                  );
                  break;
                default:
                  customError(
                    '배차 오류',
                    '배차에 실패했습니다. 관리자에게 문의하세요.'
                  );
                  break;
              }
            } else deleteError();
          })
          .catch(() => {
            deleteError();
          });
      },
    });
  };

  onSelectChange = (selectedRowKeys) => {
    var curList = this.state.callData;
    var overrideData = {};
    for (let i = 0; i < curList.length; i++) {
      var idx = curList[i].idx;
      if (selectedRowKeys.includes(idx)) overrideData[idx] = true;
      else overrideData[idx] = false;
    }

    var curIdxs = this.state.dataIdxs;
    curIdxs = Object.assign(curIdxs, overrideData);

    selectedRowKeys = [];
    for (let i = 0; i < curIdxs.length; i++) {
      if (curIdxs[i]) selectedRowKeys = [...selectedRowKeys, i];
    }
    this.setState({
      selectedRowKeys: selectedRowKeys,
      dataIdxs: curIdxs,
    });
  };

  assignRider = () => {
    this.setState({
      riderListOpen: true,
      isAssignRider: true,
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}>
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
  });

  setAssignCnt = (cnt) => {
    var list = [];
    if (cnt >= 0 && cnt < 5)
      list = this.state.allResultsSave.filter((x) => x.orders.length === cnt);
    else if (cnt === 5)
      list = this.state.allResultsSave.filter((x) => x.orders.length >= cnt);
    else if (cnt === 99) list = this.state.allResultsSave;
    var addPaths = this.clearPath();

    this.setState({
      frLocates: [],
      riderAllLocates: [],
      selRiderPath: addPaths,
      selOrderCnt: cnt,
      allResults: list,
      selectedRiderIdx: 0,
    });
  };

  setBranchIdx = (num) => {
    this.setState({
      selBranchIdx: num,
    });
  };

  clearPath = () => {
    var addPaths = [];
    var addPath = [];
    addPath[0] = navermaps.LatLng(0, 0);
    addPath[1] = navermaps.LatLng(0, 0);
    addPaths[0] = addPath;
    return addPaths;
  };

  clearSelected = (e) => {
    this.setState({
      riderName: '',
      selectedRiderIdx: '',
      selRiderPath: [],
      riderAllLocates: [],
      frLocates: [],
    });
  };

  handleListTableChange = (pagination) => {
    let searchList = [...this.state.results];
    if (this.riderRef.current && this.riderRef.current.state.value) {
      searchList = searchList.filter(
        (rider) =>
          rider.riderName.indexOf(this.riderRef.current.state.value) > -1
      );
    }

    // console.log("searchList");
    // console.log(searchList);

    // const pagedRiderList =
    searchList.slice(
      (pagination.current - 1) * pagination.pageSize,
      pagination.pageSize
    );

    // console.log("pagedRiderList");
    // console.log(pagedRiderList);

    this.setState({
      riderTableList: searchList,
      paginationRiderList: pagination,
    });
  };

  render() {
    const columns = [
      {
        title: '상태',
        dataIndex: 'orderStatus',
        className: 'table-column-center',
        render: (data, row) => (
          <div className="table-column-sub">
            <Select
              defaultValue={data}
              value={row.orderStatus}
              onChange={(value) => {
                var flag = true;

                // 제약조건 미성립
                if (!modifyType[row.orderStatus].includes(value)) {
                  Modal.info({
                    content: <div>상태를 바꿀 수 없습니다.</div>,
                  });
                  flag = false;
                  return;
                }

                // 대기중 -> 픽업중 변경 시 추천배차 알림
                if (row.orderStatus === 1 && value === 2) {
                  Modal.info({
                    content: <div>추천배차를 사용하세요.</div>,
                  });
                  return;
                }

                // 픽업 -> 접수 변경 시 배차상태로 변경 알림
                if (row.orderStatus === 3 && value === 1) {
                  Modal.info({
                    content: (
                      <div>배차상태로 먼저 변경한 후 접수로 변경해주세요.</div>
                    ),
                  });
                  return;
                }

                //완료를 복원시키는 경우
                if (row.orderStatus === 4 && value === 3) {
                  const self = this;
                  Modal.confirm({
                    title: '주문복구',
                    content:
                      '주문을 복구하는 경우 라이더에게 지급된 가맹점 배달료도 복구됩니다. 정말 복구하시겠습니까?',
                    okText: '확인',
                    cancelText: '취소',
                    onOk() {
                      httpPost(httpUrl.orderCompleteRestore, [], {
                        orderIdx: row.idx,
                      }).then((res) => {
                        if (
                          res.result === 'SUCCESS' &&
                          res.data === 'SUCCESS'
                        ) {
                          self.getCompleteList();
                        } else if (res.data === 'NOT_CHANGE_STATUS_7_DAYS') {
                          Modal.info({
                            title: '변경 실패',
                            content: '40일 전 주문은 복원 불가합니다.',
                          });
                        } else {
                          Modal.info({
                            title: '변경 실패',
                            content: '주문상태 변경에 실패했습니다.',
                          });
                        }
                      });
                    },
                  });
                  return;
                } else if (row.orderStatus === 5 && value === 1) {
                  const self = this;
                  Modal.confirm({
                    title: '주문복구',
                    content: '취소된 주문을 복구하시겠습니까?',
                    okText: '확인',
                    cancelText: '취소',
                    onOk() {
                      httpPost(httpUrl.orderCancelRestore, [], {
                        orderIdx: row.idx,
                      }).then((res) => {
                        if (
                          res.result === 'SUCCESS' &&
                          res.data === 'SUCCESS'
                        ) {
                          self.getCompleteList();
                        } else {
                          Modal.info({
                            title: '변경 실패',
                            content: '주문상태 변경에 실패했습니다.',
                          });
                        }
                      });
                    },
                  });
                  return;
                }

                const orderStatuseChangeApiCode = [
                  '',
                  httpUrl.orderAssignCancel,
                  httpUrl.orderPickupCancel,
                  httpUrl.orderPickup,
                  httpUrl.orderComplete,
                  httpUrl.orderCancel,
                ];

                httpPost(orderStatuseChangeApiCode[value], [], {
                  orderIdx: row.idx,
                })
                  .then((res) => {
                    if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
                      this.getList();
                    } else {
                      Modal.info({
                        title: '변경 실패',
                        content: '주문상태 변경에 실패했습니다.',
                      });
                    }
                  })
                  .catch((e) => {
                    if (
                      e.response.data.status ===
                      HttpResponseStatusCode.SERVICE_UNAVAILABLE
                    ) {
                      Modal.warn({
                        title: '상태변경 실패',
                        content: '이미 상태변경이 처리된 주문입니다.',
                      });
                      this.getList();
                      return;
                    }

                    Modal.info({
                      title: '변경 실패',
                      content: '주문상태 변경에 실패했습니다.',
                    });

                    throw e;
                  });
              }}>
              {deliveryStatusCode.map((value, index) => {
                if (index === 0) return <></>;
                else return <Option value={index}>{value}</Option>;
              })}
            </Select>
          </div>
        ),
      },
      {
        title: '요청시간',
        dataIndex: 'arriveReqTime',
        className: 'table-column-center',
        render: (data) => <div>{arriveReqTime[data]}</div>,
      },
      {
        title: '남은시간',
        dataIndex: 'remainTime',
        className: 'table-column-center desk',
        key: (row) => `remainTime:${row.idx}`,
        sorter: !this.state.checkedCompleteCall,
        render: (data, row) => {
          if (row.orderStatus == 5) return <div></div>;
          //취소는 남은시간 없음
          else if (row.orderStatus == 4) {
            //완료는 요청시간에서 완료시간까지 계산
            if (row.arriveReqTime > 1000) {
              //배차후 주문 처리
              const arriveReqDate = moment(row.assignDate).add(
                row.arriveReqTime % 1000,
                'minutes'
              );
              const time = arriveReqDate.diff(
                moment(row.completeDate),
                'minutes'
              );
              return <div>{time}분</div>;
            } else {
              const arriveReqDate = moment(row.orderDate).add(
                row.arriveReqTime,
                'minutes'
              );
              const time = arriveReqDate.diff(
                moment(row.completeDate),
                'minutes'
              );
              return <div>{time}분</div>;
            }
          } else {
            //진행중
            if (row.arriveReqTime > 1000) {
              //배차후 주문 처리
              if (row.orderStatus == 1) return <div></div>;
              else
                return (
                  <div>
                    {remainTime(row.assignDate, row.arriveReqTime % 1000)}분
                  </div>
                );
            } else
              return (
                <div>{remainTime(row.orderDate, row.arriveReqTime)}분</div>
              );
          }
        },
      },


      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },

      {
        title: '음식준비',
        dataIndex: 'itemPrepared',
        className: 'table-column-center',
        render: (data) => <div>{data ? '완료' : '준비중'}</div>,
      },
      {
        title: '픽업시간',
        dataIndex: 'pickupDate',
        className: 'table-column-center',
        render: (data) => <div>{data ? formatDate(data) : '대기중'}</div>,
      },
      {
        title: '완료시간',
        dataIndex: 'completeDate',
        className: 'table-column-center',
        render: (data) => <div>{data ? formatDate(data) : '배달중'}</div>,
      },
      {
        title: '기사명',
        dataIndex: 'riderName',
        className: 'table-column-center',
        render: (data) => <div>{this.state.riderName}</div>,
      },
      {
        title: '기사 연락처',
        dataIndex: 'riderPhone',
        className: 'table-column-center',
      },
      {
        title: '도착지',
        // dataIndex: "destAddr1",
        className: 'table-column-center',
        render: (data, row) => (
          <div className="arriveArea">
            {row.destAddr3 + ' ' + row.destAddr2}
          </div>
        ),
      },
      {
        title: '거리(m)',
        dataIndex: 'distance',
        className: 'table-column-center',
        render: (data, row) => <div>{comma(data)}</div>,
      },
      {
        title: '주문수정',
        dataIndex: 'updateOrder',
        className: 'table-column-center',
        render: (data, row) => (
          <Button
            onClick={(e) => {
              console.log(e);
              this.openModifyOrderModal(row);
            }}>
            수정
          </Button>
        ),
      },
      {
        title: '주문삭제',
        dataIndex: 'updateOrder',
        className: 'table-column-center',
        render: (data, row) => (
          <Button
            onClick={() => {
              this.deleteAssign(row.idx);
            }}>
            삭제
          </Button>
        ),
      },
    ];

    const companyId = this.props.loginInfo.companyId;

    const columns_riderList = [
      {
        title: '아이디',
        dataIndex: 'id',
        className: 'table-column-center',
        render: (data, row) => (
          <div
            className="riderName"
            onClick={() => {
              this.onSearchWorkerSelected(row);
            }}>
            {data}
          </div>
        ),
      },
      {
        title: companyId !== 'connect9' ? '지사명' : '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '기사명',
        dataIndex: 'riderName',
        className: 'table-column-center',
      },
      {
        title: '직급',
        dataIndex: 'riderLevel',
        className: 'table-column-center',
        render: (data) => <div>{riderLevelText[data]}</div>,
      },
      {
        title: '배차갯수',
        dataIndex: 'assignedOrderCnt',
        className: 'table-column-center',
        render: (data) => <div>{data + '건'}</div>,
      },
    ];

    const columns_callList = [
      {
        title: '지점',
        dataIndex: 'branchIdx',
        className: 'table-column-center',
        render: (data, row) => {
          let name = '-';
          const obj = getBranchNameList().find(x=>x.IDX == data);
          if (obj) name = obj.BRANCH_NAME;
          return (
              <div>
                {name}
              </div>
          );
        },
      },
      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center',
      },
      {
        title: '접수시간',
        dataIndex: 'orderDate',
        className: 'table-column-center',
        width: 70,
        render: (data, row) => <div>{formatHM(data)}</div>,
      },
      {
        title: '남은시간(분)',
        dataIndex: 'orderDate',
        className: 'table-column-center',
        width: 100,
        sorter: (a, b) => {
          const aRemainTime =
            a.arriveReqTime > 1000
              ? remainTime(a.orderDate, 0)
              : remainTime(a.orderDate, a.arriveReqTime);

          const bRemainTime =
            b.arriveReqTime > 1000
              ? remainTime(b.orderDate, 0)
              : remainTime(b.orderDate, b.arriveReqTime);
          return aRemainTime - bRemainTime;
        },

        render: (data, row) => (
          <div>
            {row.arriveReqTime > 1000
              ? 0
              : remainTime(row.orderDate, row.arriveReqTime)}
            분
          </div>
        ),
      },
      {
        title: '도착지',
        className: 'table-column-center arrive',
        ...this.getColumnSearchProps('destAddr1'),
        render: (data, row) => (
          <div className="arriveArea">
            {row.destAddr3 === '' ? '-' : row.destAddr3 + ' ' + row.destAddr2}
          </div>
        ),
      },
    ];

    const selectedRowKeys = this.state.selectedRowKeys;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const { close } = this.props;
    const selectRiderList = this.state.results.filter(
      (x) => x.userIdx === this.state.selectedRiderIdx
    );

    return (
      <React.Fragment>
        {this.state.modifyOrder && (
          <RegistCallDialog
            close={this.closeModifyOrderModal}
            editable={this.state.editable}
            data={this.state.data}
            getList={this.getList}
            callback={() => {
              this.closeModifyOrderModal();
              this.getList(this.state.selectedRider.userIdx);
            }}
          />
        )}
        <div className="Dialog-overlay" />
        <div className="map-Dialog">
          <div className="map-menu-bar">
            <div className="select-rider-orderCnt">
              <div>
                <SelectBox
                  value={orderCnt[this.state.selOrderCnt]}
                  code={Object.keys(orderCnt)}
                  codeString={orderCnt}
                  onChange={(value) => {
                    if (parseInt(value) !== this.state.selOrderCnt) {
                      this.setAssignCnt(parseInt(value));
                    }
                  }}
                />
              </div>
              <div>
                <SelectBox
                  mode="multiple"
                  style={{ marginLeft: '5px', width: Math.max(100, 100 * this.state.selBranchIdx.length) }}
                  value={this.state.selBranchIdx}
                  code={Object.keys(this.state.branchNameList)}
                  codeString={this.state.branchNameList}
                  onChange={(value) => {
                    if (value.includes('0') && value.length > 1) this.setBranchIdx(value.filter(v => v !== '0'));
                    else if (!value.includes('0') && value.length === Object.keys(this.state.branchNameList).length - 1) this.setBranchIdx(['0']);
                    else this.setBranchIdx(value);
                  }
                  }
                />
              </div>
            </div>

            <img
              onClick={close}
              src={CloseIcon}
              className="map-close mobile"
              alt="닫기"
            />
          </div>

          <div className="map-content">
            <div className="map-inner">
              {this.state.riderName && (
                <div className="riderTableLayout">
                  <div className="textLayout">
                    <span className="riderText">
                      {this.state.riderName}의 배차 목록
                    </span>
                    <Button
                      style={{
                        marginLeft: 10,
                        backgroundColor: 'black',
                        color: 'white',
                      }}
                      onClick={(e) => this.clearSelected(e)}>
                      X
                    </Button>
                  </div>
                  <Table
                    rowKey={(record) => record.idx}
                    dataSource={this.state.riderOrderList}
                    rowClassName={(record) => rowColorName[record.orderStatus]}
                    columns={columns}
                    pagination={false}
                    className={'riderOrderTable'}
                  />
                </div>
              )}

              <div className="mapLayout">
                {/* <MapContainer/> */}

                {navermaps && (
                  <NaverMap
                    className="map-navermap"
                    defaultZoom={14}
                    center={this.state.mapCenter}
                    naverRef={(ref) => {
                      this.mapRef = ref;
                    }}
                    bounds={this.state.bounds}
                    // onBoundsChanged={this.handleBoundChange}
                    onCenterChanged={(center) =>
                      this.setState({ mapCenter: center })
                    }>
                    {selectRiderList.length > 0 &&
                      selectRiderList.map((row) => {
                        return (
                          <>
                            <Marker
                              position={navermaps.LatLng(
                                this.state.selectedRiderLatitude,
                                this.state.selectedRiderLongitude
                              )}
                              icon={MarkerRiderRedIcon}
                            />
                            <Marker
                              key={this.state.selectedRiderIdx}
                              position={navermaps.LatLng(
                                this.state.selectedRiderLatitude,
                                this.state.selectedRiderLongitude
                              )}
                              icon={{
                                content: [
                                  '<div class="marker-name"><div class="disable-drag-select" style="transform: translateX(-50%)">' +
                                  '[' +
                                  row.branchName +
                                  ']' +
                                  row.riderName +
                                  ' (' +
                                  row.assignedOrderCnt +
                                  ')' +
                                  '</div></div>',
                                ].join(''),
                              }}
                              title={this.state.riderName}
                              onClick={() =>
                                this.getRiderLocate(this.state.selectedRiderIdx)
                              }
                            />
                          </>
                        );
                      })}

                    {this.state.results
                      .filter((row) => {
                        return this.state.selOrderCnt === 99
                          ? true
                          : this.state.selOrderCnt === 5
                            ? row.assignedOrderCnt >= 5
                            : row.assignedOrderCnt === this.state.selOrderCnt;
                      })
                      .filter((row) =>
                        this.state.selBranchIdx.includes('0')
                          ? true
                          : this.state.selBranchIdx.includes('' + row.branchIdx)
                      )
                      .map((row, index) => {
                        return (
                          <>
                            {this.state.selectedRiderIdx !== row.userIdx &&
                              row.riderStatus === 1 && (
                                <>
                                  <Marker
                                    key={row.userIdx + ' ' + row.riderName}
                                    position={navermaps.LatLng(
                                      row.riderLocation.latitude,
                                      row.riderLocation.longitude
                                    )}
                                    // 팀장 이상 파랑 마크
                                    icon={
                                      row.riderLevel >= 3
                                        ? MarkerRiderBlueIcon
                                        : MarkerRiderIcon
                                    }
                                    title={row.riderName}
                                    onClick={() => {
                                      // this.getRiderLocate(row.userIdx);
                                      this.onSearchWorkerSelected(row);
                                    }}
                                  />
                                  {
                                    <Marker
                                      key={row.userIdx}
                                      position={navermaps.LatLng(
                                        row.riderLocation.latitude,
                                        row.riderLocation.longitude
                                      )}
                                      icon={{
                                        content: [
                                          '<div class="marker-name"><div class="disable-drag-select" style="transform: translateX(-50%)">' +
                                          '[' +
                                          row.branchName +
                                          ']' +
                                          row.riderName +
                                          ' (' +
                                          row.assignedOrderCnt +
                                          ')' +
                                          '</div></div>',
                                        ].join(''),
                                      }}
                                      title={row.riderName}
                                      onClick={() => {
                                        this.onSearchWorkerSelected(row);
                                      }}
                                    />
                                  }
                                </>
                              )}
                          </>
                        );
                      })}

                    {this.state.riderAllLocates.map((row, index) => {
                      return (
                        <Marker
                          key={row.userIdx}
                          position={row}
                          icon={ArriveRedIcon}
                        />
                      );
                    })}

                    {this.state.frLocates.map((row, index) => {
                      return (
                        <Marker
                          key={row.userIdx}
                          position={row}
                          icon={FranchiseYellowIcon}
                        />
                      );
                    })}

                    {this.state.selectedOrdersFromReceptionStatus.map(
                      (order) => (
                        <>
                          <Marker
                            key={
                              'selectedFromReceptionStatus-' +
                              order.idx +
                              'franchise-header'
                            }
                            position={{
                              lat: order.frLatitude,
                              lng: order.frLongitude,
                            }}
                            icon={{
                              content: [
                                '<div class="marker-name"><div style="transform: translateX(-50%)">' +
                                order.idx +
                                '</div>',
                              ].join(''),
                            }}
                          />

                          <Marker
                            key={
                              'selectedFromReceptionStatus-' +
                              order.idx +
                              'franchise'
                            }
                            position={{
                              lat: order.frLatitude,
                              lng: order.frLongitude,
                            }}
                            icon={FranchiseYellowIcon}
                          />
                          <Polyline
                            key={
                              'selectedFromReceptionStatus-' +
                              order.idx +
                              'line'
                            }
                            path={[
                              {
                                lat: order.frLatitude,
                                lng: order.frLongitude,
                              },
                              {
                                lat: order.latitude,
                                lng: order.longitude,
                              },
                            ]}
                            strokeColor={'red'}
                            strokeWeight={5}
                          />
                          <Marker
                            key={
                              'selectedFromReceptionStatus-' +
                              order.idx +
                              'destination'
                            }
                            position={{
                              lat: order.latitude,
                              lng: order.longitude,
                            }}
                            icon={ArriveRedIcon}
                          />
                        </>
                      )
                    )}

                    {this.state.selRiderPath.map((row, index) => {
                      const strokeColor = [
                        '#ff0000',
                        '#00ff00',
                        '#0000ff',
                        '#ED7D31',
                        '#EC32E3',
                        '#AA7474',
                        '#8076A8',
                        '#8AFE20',
                        '#FAEB24',
                        '#A923FB',
                      ];
                      return (
                        <Polyline
                          key={index}
                          path={row}
                          strokeColor={strokeColor[index]}
                          strokeWeight={5}
                        />
                      );
                    })}
                    {/* <Polyline
                      path={this.state.selRiderPath}
                      strokeColor={"#ff0000"}
                      strokeWeight={5}
                    /> */}
                  </NaverMap>
                )}
              </div>

              {/* 콜 정보 */}
              {this.state.callData && this.state.orderListOpen && (
                <div className="orderListInMapControl">
                  <div className="riderListInMapControl-inner">
                    <div
                      className="order-list-show-btn-01"
                      onClick={() => this.setState({ orderListOpen: false })}>
                      닫기
                    </div>
                    <div
                      className="order-list-show-btn-02-open"
                      onClick={() =>
                        this.setState({ selectedOrdersFromReceptionStatus: [] })
                      }>
                      선택접수주문제거
                    </div>
                    <div className="selectLayout-01">
                      <Form
                        onFinish={() => {
                          this.handleFrSearch();
                        }}>
                        <Input
                          ref={this.franchiseRef}
                          placeholder="가맹점 검색"
                          allowClear
                          style={{
                            width: 170,
                          }}
                        />
                        <Button type="primary" htmlType="submit">
                          조회
                        </Button>
                      </Form>
                    </div>
                  </div>

                  <Table
                    rowKey={(record) => record.idx}
                    dataSource={this.state.callData.filter(
                      (x) => x.orderStatus === 1 && x.waitingStatus === 0
                    )}
                    rowSelection={rowSelection}
                    columns={columns_callList}
                    rowClassName={(row) => {
                      const remainTimeNum =
                        row.arriveReqTime > 1000
                          ? 0
                          : remainTime(row.orderDate, row.arriveReqTime);
                      if (remainTimeNum >= 0) return 'table-white';
                      else if (remainTimeNum <= -30) return 'table-red';
                      else if (remainTimeNum <= -20) return 'table-orange';
                      else if (remainTimeNum <= -10) return 'table-yellow';
                    }}
                    onChange={this.handleTableChange}
                    className={'callDataTable'}
                    style={{ borderWidth: 1, borderColor: 'red' }}
                    pagination={this.state.paginationCallList}
                    onRow={(record) => {
                      return {
                        onClick: () => this.orderSelectCallback(record),
                      };
                    }}
                  />
                </div>
              )}

              {!this.state.orderListOpen && (
                <>
                  <div
                    className="order-list-show-btn desk"
                    onClick={() => this.setState({ orderListOpen: true })}>
                    주문 목록 열기
                  </div>
                  <div
                    className="order-list-show-btn-02 desk"
                    onClick={() =>
                      this.setState({ selectedOrdersFromReceptionStatus: [] })
                    }>
                    선택접수주문제거
                  </div>
                </>
              )}

              {this.state.riderListOpen && (
                <>
                  <div className="riderListInMapControl">
                    <div className="riderListInMapControl-inner">
                      <div
                        className="rider-list-show-btn-01"
                        onClick={() => this.setState({ riderListOpen: false })}>
                        닫기
                      </div>

                      <div className="selectLayout-01">
                        <Form
                          onFinish={() => {
                            this.handleRiderSearch();
                          }}>
                          <Input
                            ref={this.riderRef}
                            placeholder="기사명검색"
                            allowClear
                            style={{
                              width: 170,
                            }}
                          />
                          <Button type="primary" htmlType="submit">
                            조회
                          </Button>

                          <div
                            style={{
                              display: 'inline-block',
                              backgroundColor: 'white',
                              border: '1px solid black',
                              marginLeft: 10,
                              padding: 5,
                            }}>
                            <Checkbox
                              style={{
                                fontWeight: 'bold',
                              }}
                              onChange={(e) =>
                                this.setState({
                                  skipIfAssigned: e.target.checked,
                                })
                              }
                              checked={this.state.skipIfAssigned}>
                              배차주문제외
                            </Checkbox>
                          </div>
                        </Form>
                      </div>
                    </div>
                    <Table
                      rowKey={(record) => record.idx}
                      dataSource={this.state.riderTableList}
                      columns={columns_riderList}
                      pagination={this.state.paginationRiderList}
                      onChange={this.handleListTableChange}
                      className={'riderListTable'}
                    />
                  </div>
                </>
              )}
              {!this.state.riderListOpen && (
                <div
                  className="rider-list-show-btn desk"
                  onClick={() => this.setState({ riderListOpen: true })}>
                  기사 목록 열기
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    loginInfo: state.login.loginInfo,
    riderReducer: state.riderReducer,
    orderReducer: state.orderReducer,
  };
};

export default connect(mapStateToProps, null)(MapControlDialog);
