import {useState} from 'react';

export default function useStateCustomHook(initialValue) {
  const [value, setValue] = useState(initialValue);

  let current = value;

  const get = () => current;

  const set = newValue => {
    current = newValue;
    setValue(newValue);
    return current;
  };

  return {get, set};
}
