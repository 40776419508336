import {
  ADD_ORDER,
  ADD_ORDER_LIST,
  DELETE_ORDER,
  SET_ORDER,
  UPDATE_ORDER,
} from '../actions/orderAction';

const orderInitialState = {
  orders: [],
  completeOrders: [],
};

const orderReducer = (state = orderInitialState, action) => {
  let category = '';
  let addIdx = 0;
  let newState = { ...state };

  switch (action.type) {
    case SET_ORDER:
      if (action.category === 'NEW') category = 'orders';
      else if (action.category === 'COMPLETE') category = 'completeOrders';
      else return state;

      newState[category] = action.orderList;

      return newState;

    case ADD_ORDER:
      if (action.category === 'NEW') category = 'orders';
      else if (action.category === 'COMPLETE') category = 'completeOrders';
      else return state;

      console.log(action.order.idx !== null && action.order.idx !== undefined);
      if (action.order.idx !== null && action.order.idx !== undefined) {
        if (
          state.orders.length === 0 ||
          action.order.idx > state.orders[0].idx
        ) {
          newState[category] = [action.order, ...state.orders];
        } else {
          while (state.orders[addIdx].idx > action.order.idx) {
            addIdx += 1;
          }
          let addedOrder = [...state.orders];
          if (addedOrder[addIdx].idx === action.order.idx) {
            addedOrder[addIdx] = action.order;
          } else {
            addedOrder.splice(addIdx, 0, action.order);
          }
          newState[category] = addedOrder;
        }
      }

      return newState;

    case ADD_ORDER_LIST:
      if (action.category === 'NEW') category = 'orders';
      else if (action.category === 'COMPLETE') category = 'completeOrders';
      else return state;

      const sortedOrderList = action.orderList.sort((a, b) => b - a);

      newState[category] = [...sortedOrderList, ...state.orders];

      return newState;

    case DELETE_ORDER:
      if (action.category === 'NEW') category = 'orders';
      else if (action.category === 'COMPLETE') category = 'completeOrders';
      else return state;

      var prevOrder = [...state[category]];
      const targetIdx = prevOrder.findIndex((order) => {
        return order.idx === action.order.idx;
      });
      prevOrder.splice(targetIdx, 1);

      if (targetIdx === -1) {
        return state;
      } else {
        newState[category] = prevOrder;
        return newState;
      }

    case UPDATE_ORDER:
      const updateTargetIdx = state.orders.findIndex(
        (order) => order.idx === action.order.idx
      );
      if (updateTargetIdx === -1) return state;

      var updatedOrders = [...state.orders];
      updatedOrders.splice(updateTargetIdx, 1, action.order);

      return {
        ...state,
        orders: updatedOrders,
      };

    default:
      return state;
  }
};

export default orderReducer;
