import React, {useEffect, useRef, useState} from 'react';
import CloseIcon from "../../img/close.png";
import {Button, Input, Modal} from "antd";
import {comma} from "../../lib/util/numberUtil";
import {httpPost, httpUrl} from "../../api/httpClient";
import {useSelector} from "react-redux";
import VerificationCheckDialog from "./VerificationCheckDialog";
import {paystoryTransferResultMsg} from "../../lib/util/codeUtil";

const WithdrawDialog = ({selectedFr, close, getList}) => {
    const [approved, setApproved] = useState(false);
    const [smsVerificationDialogOpen, setSmsVerificationDialogOpen] = useState(false);
    const inputRef = useRef();

    useEffect(() => {
        console.log(selectedFr);
    }, []);

    const reqWithdraw = async () => {
        try {
            const userIdx = selectedFr?.idx;
            if (null === userIdx || undefined === userIdx || '' === userIdx) {
                Modal.warn({
                    title: "가맹점 정보 오류",
                    content: "잘못된 가맹점 정보입니다. 창을 다시 켜주세요."
                })
                return;
            }
            if (isNaN(inputRef.current.input.value) || inputRef.current.input.value < 0) {
                Modal.warn({
                    title: "출금금액 오류",
                    content: "잘못된 출금금액입니다. 다시 입력해주세요."
                })
                return;
            }
            if (Number(inputRef.current.input.value) + 300 > selectedFr?.ncash) {
                Modal.warn({
                    title: "예치금 잔액 부족",
                    content: "예치금 잔액이 부족합니다."
                })
                return;
            }

            console.log({
                userIdx,
                amt: inputRef.current.input.value,
            });
            const res = await httpPost(httpUrl.reqPaystoryWithdraw, [], {
                userIdx,
                amt: inputRef.current.input.value,
            });
            if (res.data.resultCd === '0000') {
                Modal.info({
                    title: "출금성공",
                    content: "출금에 성공했습니다."
                });
                await getList();
                close();
            } else {
                Modal.warn({
                    title: "출금실패",
                    content: Object.keys(paystoryTransferResultMsg).includes(res.data.resultMsg) ?
                        paystoryTransferResultMsg[res.data.resultMsg] : res.data.resultMsg,
                });
            }
        } catch (e) {
            console.error(e);
            Modal.warn({
                title: "출금실패",
                content: "서버오류. 잠시 후 다시 시도해주세요.",
            });
        }
    }

    const loginInfo = useSelector(state => state.login.loginInfo);

    const openSmsVerificationDialog = () => {
        const to = loginInfo?.phone;
        const userIdx = loginInfo?.idx;
        if (null === to || undefined === to || '' === to) {
            Modal.warn({
                title: "인증실패",
                content: "등록된 핸드폰 번호가 유효하지 않습니다. 번호 확인 후 다시 시도해주세요."
            });
            return;
        }
        if (null === userIdx || undefined === userIdx || '' === userIdx) {
            Modal.warn({
                title: "인증실패",
                content: "로그인 정보가 유효하지 않습니다. 다시 로그인 후 시도해주세요."
            });
            return;
        }
        Modal.confirm({
            title: "인증번호 전송",
            content: `${loginInfo.phone} 으로 인증번호를 전송하시겠습니까?`,
            onOk: async () => {
                const res = await httpPost(httpUrl.sendVerificationSms, [], {userIdx, to});

                if (res.data === 'SUCCESS') {
                    setSmsVerificationDialogOpen(true);
                } else {
                    Modal.warn({
                        title: "인증번호 전송실패",
                        content: "인증번호 전송에 실패했습니다."
                    });

                }
            }
        })
    }

    return <>
        {smsVerificationDialogOpen &&
            <VerificationCheckDialog close={() => setSmsVerificationDialogOpen(false)}
                                     onCheck={() => setApproved(true)} />}

        <div className="Dialog-overlay" onClick={close}/>
        <div className="deposit-Dialog">
            <div className="deposit-content">
                <div className="deposit-title">{`${selectedFr?.frName}`}</div>
                <img
                    onClick={close}
                    style={{display: 'inline', float: 'right'}}
                    src={CloseIcon}
                    className="dialog-close"
                    alt="img"
                />
                <div className="deposit-inner">
                    <div className="contentBlock">
                        <div>
                            <div className="mainTitle">예치금잔액</div>
                            <div className="serach-input">
                                {`${comma(selectedFr?.ncash)}`}
                            </div>
                        </div>
                    </div>
                    <div className="contentBlock">
                        <div>
                            <div className="mainTitle">출금금액</div>
                            <div className="serach-input">
                                <Input
                                    type={"number"}
                                    ref={inputRef}
                                    className="override-input"
                                    placeholder="검색해주세요."
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button
                            disabled={approved}
                            onClick={() => openSmsVerificationDialog()}
                        >
                            인증하기
                        </Button>
                        <Button
                            disabled={!approved}
                            onClick={async () => await reqWithdraw()}
                            type="primary"
                        >
                            출금하기
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default WithdrawDialog;