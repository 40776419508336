import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popover,
  Radio,
  Select
} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPost, httpUrl, imageUrl } from '../../../api/httpClient';
import {
  alreadyJoin,
  deleteComplete,
  idDuplicated,
  invalidBikeNum,
  noOwnBike,
  notExist,
  registComplete,
  registError,
  updateComplete,
  updateError
} from '../../../api/Modals';
import '../../../css/modal.css';
import CloseIcon from '../../../img/login/close.png';
import {
  bankCode,
  bikeType,
  foreignerCountryCode,
  foreignerStatusCode,
  items,
  riderLevelText,
  riderType
} from '../../../lib/util/codeUtil';
import { formatDateToDay } from '../../../lib/util/dateUtil';
import SearchBikeDialog from '../../dialog/common/SearchBikeDialog';
import SearchRiderDialog from '../common/SearchRiderDialog';
import UpdateRiderBankInfoDialog from './UpdateRiderBankInfoDialog'

const Option = Select.Option;
const FormItem = Form.Item;

class RegistRiderDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      staffAuth: 1,
      feeManner: 1,
      userGroup: 1,
      riderLevel: null,
      riderGroup: 0,
      withdrawLimit: 100000,

      bikeType: -1,
      isSearchBikeOpen: false,
      searchOnlyTeamManagerOpen: false,
      searchAllRiderOpen: false,
      selectedBike: null,
      selectedRider: null,

      // agreeSms: true,

      // 바이크 등록 param
      bikeNumber: '',
      makeDate: '',
      maker: '',
      modelName: '',
      modelYear: '',
      mileage: '',

      riderSettingGroup: [],
      joinInsurance: false,
      accidentInsurance: false,
      rentBikeNum: '',
      selectRecommender: null,
      zeroCallMemo: '',
      riderTypeCode: 0,
      isWithholdingTax: true,
      data: null,
      isForeigner: false,
      isConnect9: false,
      fullTimeEmployee: false,
      updateBankInfoOpen: false,
      levelUpPointFlag: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.data) {
      const { data } = this.props;
      const userIdx = data.idx;

      // this.setState(
      //   {
      //     bikeType: data.bikeRentOwnedCode - 1,
      //     selectedBike: data.bike,
      //     riderLevel: data.riderLevel,
      //     selectedRider: { idx: data.teamManagerIdx },
      //     selectRecommender: { idx: data.recommenderIdx },
      //     riderTypeCode: data.riderType - 1,
      //     isWithholdingTax: data.isWithholdingTax,
      //   },
      //   () => {
      //     console.log('마운트 된 this.state.bikeType!!!!!!!');
      //     console.log(this.state.bikeType);
      //     this.formRef.current.setFieldsValue({
      //       teamManagerIdx: this.state.selectedRider.idx,
      //       recommenderIdx: this.state.selectRecommender.idx,
      //     });
      //   }
      // );

      // if (data.bikeRentOwnedCode === 2) {
      //   try {
      //     httpGet(httpUrl.getRiderBikeNumber, [userIdx], {}).then((res) => {
      //       if (res.result === 'SUCCESS' && res.data.riderOwnedBike !== null) {
      //         this.setState({
      //           bikeNumber: res.data.riderOwnedBike.bikeNumber,
      //         });
      //         this.formRef.current.setFieldsValue({
      //           ownedBikeNumber: res.data.riderOwnedBike.bikeNumber,
      //         });
      //       } else {
      //         noOwnBike();
      //         return;
      //       }
      //     });
      //   } catch (e) {
      //     console.warn('getRiderBikeNumber raised exception. e: ' + e);
      //     return { result: 'EXCEPTION' };
      //   }
      // }
      this.getRiderInfo(userIdx)

      this.getRiderAccidentInsurance();
    } else{
      httpPost(httpUrl.getRiderGroup, [], {})
      .then(res=>{
        console.log(res);
        if (typeof res.data !== 'string') {
          this.setState(
            {
              riderSettingGroup: res.data.riderSettingGroups,
            },
            () => {
              this.formRef.current.setFieldsValue({
                riderSettingGroup: this.state.riderSettingGroup[0].idx,
              });
            }
          );
        }
      })
      if (this.props.branchInfo?.defaultLevelUpPointFlag != null) {
        this.setState({
          levelUpPointFlag: this.props.branchInfo.defaultLevelUpPointFlag,
        });
      }
    }
    if (this.props.loginInfo.companyId === 'connect9') {
      this.setState({
        isConnect9: true,
      })
    }
    console.log(this.props.data);
    // this.getRiderSettingGroup();
    this.isAgencyRider();
  }

  getRiderInfo = async (userIdx) => {
    const result = await httpGet(httpUrl.getRiderInfo, [userIdx], {})
    if(result.result === "SUCCESS") {
      let riderInfo = result.data
      this.setState({data: riderInfo})
      const resGroup = await httpPost(httpUrl.getRiderGroup, [], {});
      console.log(resGroup);
      if (typeof resGroup.data !== 'string') {
        this.setState(
          {
            riderSettingGroup: resGroup.data.riderSettingGroups,
          },
          () => {
            const targetSettingGroup = this.state.riderSettingGroup.find(
              (item) => item.idx === riderInfo.riderSettingGroup.idx
            );
            this.formRef.current.setFieldsValue({
              riderSettingGroup: targetSettingGroup
                ? targetSettingGroup.idx
                : this.state.riderSettingGroup[0].idx,
            });
          }
        );
      }
      delete riderInfo.riderSettingGroup
      this.formRef.current.setFieldsValue({
        ...riderInfo,
        joinDate: moment(riderInfo.joinDate, 'YYYY-MM-DD'),
        password: '',
        items: riderInfo.items.split(','),
        foreignerAcquisitionDate: riderInfo.foreignerAcquisitionDate ? moment(riderInfo.foreignerAcquisitionDate, 'YYYY-MM-DD') : null
      })
      if (riderInfo.bikeRentOwnedCode === 2) {
        try {
          httpGet(httpUrl.getRiderBikeNumber, [userIdx], {}).then((res) => {
            if (res.result === 'SUCCESS' && res.data.riderOwnedBike !== null) {
              this.setState({
                bikeNumber: res.data.riderOwnedBike.bikeNumber,
              });
              this.formRef.current.setFieldsValue({
                ownedBikeNumber: res.data.riderOwnedBike.bikeNumber,
              });
            } else {
              noOwnBike();
              return;
            }
          });
        } catch (e) {
          console.warn('getRiderBikeNumber raised exception. e: ' + e);
          return { result: 'EXCEPTION' };
        }
      }
      this.setState({
        bikeType: riderInfo.bikeRentOwnedCode - 1,
        selectedBike: riderInfo.bike,
        riderLevel: riderInfo.riderLevel,
        selectedRider: { idx: riderInfo.teamManagerIdx },
        selectRecommender: { idx: riderInfo.recommenderIdx },
        riderTypeCode: riderInfo.riderType - 1,
        isWithholdingTax: riderInfo.isWithholdingTax,
        isForeigner: riderInfo.isForeigner ? true : false,
        fullTimeEmployee: riderInfo.fullTimeEmployee,
        levelUpPointFlag: riderInfo.levelUpPointFlag,
      })
      console.log(this.props.branchIdx);
    }
  }

  validateForm = () => {
    var ssn = this.formRef.current
      .getFieldValue('registrationNumber')
      .replace('-', ''),
      arr_ssn = [],
      compare = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5],
      sum = 0;

    // 자리수 체크
    if (ssn.length != 13) {
      // alert("올바른 주민등록 번호를 입력하여 주세요.");
      return false;
    }

    // 공식: M = (11 - ((2×A + 3×B + 4×C + 5×D + 6×E + 7×F + 8×G + 9×H + 2×I + 3×J + 4×K + 5×L) % 11)) % 10
    for (var i = 0; i < 13; i++) {
      arr_ssn[i] = ssn.substring(i, i + 1);
    }

    for (var i = 0; i < 12; i++) {
      sum = sum + arr_ssn[i] * compare[i];
    }

    // 외국인 등록번호(1:성별, 23: 등록기관번호, 45: 일련번호, 6:등록자 구분, 7: 검증번호)
    // 1900년대 출생자: 남5, 여6, 2000년대 출생자: 남7, 여8
    if (
      arr_ssn[6] === '5' ||
      arr_ssn[6] === '6' ||
      arr_ssn[6] === '7' ||
      arr_ssn[6] === '8'
    ) {
      sum = (13 - (sum % 11)) % 10;
    } else sum = (11 - (sum % 11)) % 10;

    if (sum != arr_ssn[12]) {
      // alert("올바른 주민등록 번호를 입력하여 주세요.");
      return false;
    }

    return true;
  };

  getRiderAccidentInsurance = async () => {
    const userIdx = this.props.data.idx;
    await httpGet(httpUrl.getRiderInsurance, [userIdx], {}).then((res) => {
      console.log('라이더상해보험 가져오기!!!!!!!');
      console.log(res);
      if (res.result === 'SUCCESS') {
        this.setState({
          accidentInsurance: res.data,
        });
      } else {
        Modal.warn({
          title: '오류',
          content: '상해보험 내역을 불러오지 못하였습니다.',
        });
      }
    });
  };

  handleSubmit = () => {
    let self = this;
    let { data } = this.props;
    Modal.confirm({
      title: <div> {data ? '기사 수정' : '기사 등록'}</div>,
      content: (
        <div>
          {data
            ? '기사 정보를 수정하시겠습니까?'
            : '새로운 기사를 등록하시겠습니까?'}
        </div>
      ),

      okText: '확인',
      cancelText: '취소',
      onOk() {
        if (!self.validateForm()) {
          Modal.warn({
            title: !self.state.isForeigner ? '주민등록번호 오류' : '외국인 등록번호 오류',
            content: !self.state.isForeigner ? '주민등록번호를 확인해주세요.' : '외국인 등록번호를 확인해주세요',
          });
          return;
        }
        if (data) {
          const formData = self.formRef.current.getFieldsValue();

          if (typeof formData.zeroCallMemo === 'string' && formData.zeroCallMemo.trim().length === 0) {
            Modal.warn({
              title: '수정 실패',
              content: '0콜 사유를 입력하셨는지 확인해주세요.',
            });
            return;
          }
          console.log(formData.bankAccount.trim());
          httpPost(httpUrl.updateRider, [], {
            ...formData,
            bankAccount: formData.bankAccount.trim(),
            idx: data.idx,
            riderSettingGroup: {
              idx: self.formRef.current.getFieldValue('riderSettingGroup'),
            },
            bikeRentOwnedCode: self.state.bikeType + 1,
            ownedBikeNumber: self.state.bikeNumber,
            items: self.formRef.current.getFieldValue('items').join(','),
            joinDate: formatDateToDay(
              self.formRef.current.getFieldValue('joinDate')
            ),
            password: self.formRef.current.getFieldValue('password')
              ? self.formRef.current.getFieldValue('password')
              : null,
            registrationNumber: self.formRef.current
              .getFieldValue('registrationNumber')
              .replace('-', ''),
            ncashMin: self.formRef.current.getFieldValue('withdrawLimit'),
            isForeigner: self.state.isForeigner ? 1 : 0,

            // 삭제컬럼
            agreeSms: false,
            riderType: self.state.riderTypeCode + 1,
            exactRiderName: self.formRef.current.getFieldValue('exactRiderName') || null,
            needRiderValidation: self.props.needRiderValidation,
            zeroCallMemo:
              self.formRef.current.getFieldValue('zeroCallMemo') !== undefined &&
                self.formRef.current.getFieldValue('zeroCallMemo') !== null &&
                typeof self.formRef.current.getFieldValue('zeroCallMemo') === 'string'
                ? self.formRef.current.getFieldValue('zeroCallMemo').replace('<br/>', '\n')
                : null,
            isWithholdingTax: self.state.showWithholdingTax ? self.state.isWithholdingTax : false,
            fullTimeEmployee: self.state.fullTimeEmployee,
            levelUpPointFlag: self.state.levelUpPointFlag,
          })
            .then((res) => {
              console.log(JSON.stringify(res.data, null, 4));
              if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
                updateComplete();
              } else if (res.data === 'WRONG_ACCOUNT_INFO') {
                Modal.info({
                  title: '등록실패',
                  content: '잘못된 계좌정보입니다.',
                });
                return;
              } else if (
                res.result === 'SUCCESS' &&
                res.data === 'DUPLICATE_BIKE_NUMBER'
              ) {
                Modal.info({
                  title: '등록실패',
                  content: '중복된 바이크번호입니다.',
                });
                return;
              } else if (
                res.result === 'SUCCESS' &&
                res.data === 'INVALID_RIDER_TYPE'
              ) {
                Modal.info({
                  title: '등록실패',
                  content: '잘못된 라이더타입입니다.',
                });
                return;
              } else if (res.result === "SUCCESS" && res.data === "INVALID_EXACT_RIDER_NAME_REGISTRATION_NUM") {
                Modal.info({
                  title: "등록실패",
                  content: "본명과 주민번호를 다시 확인해주세요.",
                });
                return;
              } else {
                updateError();
                return;
              }
              self.props.close();
            })
            .catch((e) => {
              updateError();
            });
        } else {
          if (self.state.bikeType === 1 && self.state.bikeNumber.length === 0) {
            Modal.warn({
              title: '바이크번호 오류',
              content: '바이크번호를 등록해주세요.',
            });
            return;
          }
          const formData = self.formRef.current.getFieldsValue();
          console.log('formData.bankAccount.trim()');
          console.log(formData.bankAccount.trim());
          httpPost(httpUrl.registRider, [], {
            ...formData,
            bankAccount: formData.bankAccount.trim(),
            id: self.formRef.current.getFieldValue('id').replace(/ /gi, ''),
            branchIdx: self.props.branchIdx,
            bikeRentOwnedCode: self.state.bikeType + 1,
            ownedBikeNumber: self.state.bikeNumber,
            riderSettingGroup: {
              idx: self.formRef.current.getFieldValue('riderSettingGroup'),
            },
            userType: 1,
            items: self.formRef.current.getFieldValue('items').join(','),
            joinDate: formatDateToDay(
              self.formRef.current.getFieldValue('joinDate')
            ),
            agreeSms: false,
            registrationNumber: self.formRef.current
              .getFieldValue('registrationNumber')
              .replace('-', ''),
            ncashMin: self.formRef.current.getFieldValue('withdrawLimit'),
            isForeigner: self.state.isForeigner ? 1 : 0,

            // 기사 생성 시 예치금 정책 어떻게 될지에 따라 변경 될 수 있음
            // ncash 컬럼이 not null 이어서 기사 등록 시 0 설정
            ncash: 0,
            recommenderIdx: self.state.selectRecommender
              ? self.state.selectRecommender.idx
              : null,
            riderType: self.state.riderTypeCode + 1,
            exactRiderName: self.formRef.current.getFieldValue('exactRiderName') || null,
            isWithholdingTax: self.state.showWithholdingTax ? self.state.isWithholdingTax : false,
            fullTimeEmployee: self.state.fullTimeEmployee,
            levelUpPointFlag: self.state.levelUpPointFlag,
          })
            .then((res) => {
              if (res.result === 'SUCCESS' && res.data == 'SUCCESS') {
                registComplete();
                self.props.close();
              } else if (
                res.result === 'SUCCESS' &&
                res.data == 'ID_DUPLICATED'
              ) {
                idDuplicated();
              } else if (
                res.result === 'SUCCESS' &&
                res.data === 'INVALID_BIKE_RENT_OWNED_CODE'
              ) {
                Modal.error({
                  title: '처리 오류',
                  content: '바이크 타입이 올바르지 않습니다.',
                });
              } else if (
                res.result === 'SUCCESS' &&
                res.data === 'INVALID_BIKE_NUMBER'
              ) {
                invalidBikeNum();
              } else if (
                res.result === 'SUCCESS' &&
                res.data === 'DUPLICATE_BIKE_NUMBER'
              ) {
                Modal.info({
                  title: '등록실패',
                  content: '중복된 바이크번호입니다.',
                });
                return;
              } else if (
                res.result === 'SUCCESS' &&
                res.data === 'INVALID_RIDER_TYPE'
              ) {
                Modal.info({
                  title: '등록실패',
                  content: '잘못된 라이더타입입니다.',
                });
                return;
              } else {
                registError();
                // self.props.close();
              }
            })
            .catch((e) => {
              registError();
            });
        }
      },
    });
  };

  handleClear = () => {
    this.formRef.current.resetFields();
  };

  handleToggle = (data) => {
    console.log('토글!!!!!!!!!');
    console.log(data);
    let userIdx = data.idx;
    !this.state.accidentInsurance
      ? httpPost(httpUrl.riderCancelInsurance, [], {
        userIdx,
      }).then((res) => {
        console.log(res);
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          deleteComplete();
          this.getRiderAccidentInsurance();
          // this.props.close();
        } else if ((res.result = 'NOT_EXIST')) {
          notExist();
        }
      })
      : httpPost(httpUrl.riderJoinInsurance, [], {
        userIdx,
      }).then((res) => {
        console.log(res);
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          registComplete();
          this.getRiderAccidentInsurance();
          // this.props.close();
        } else if (res.result === 'SUCCESS' && res.data === 'FAIL') {
          registError();
        } else if ((res.result = 'ALREADY_JOIN')) {
          alreadyJoin();
        }
      });
  };

  // createBike = () => {
  //   httpPost(httpUrl.createBike, [], {
  //     bikeNumber: this.state.bikeNumber,
  //     makeDate: this.state.makeDate,
  //     maker: this.state.maker,
  //     modelName: this.state.modelName,
  //     modelYear: this.state.modelYear,
  //     mileage: this.state.mileage,
  //   })
  //     .then((res) => {
  //       if (res.result === "SUCCESS" && res.data === "SUCCESS") {
  //         Modal.info({
  //           title: "등록성공",
  //           content: "바이크 등록에 성공했습니다.",
  //         });
  //       } else {
  //         Modal.info({
  //           title: "등록실패",
  //           content: "바이크 등록에 실패했습니다.",
  //         });
  //       }
  //     })
  //     .catch((e) => {
  //       Modal.info({
  //         title: "등록실패",
  //         content: "바이크 등록에 실패했습니다.",
  //       });
  //       console.log(e);
  //       throw e;
  //     });
  // };

  getRiderSettingGroup = async () => {
    console.log(this.props.branchIdx);
    const res = await httpPost(httpUrl.getRiderGroup, [], {});
    console.log(res);
    if (typeof res.data !== 'string') {
      this.setState(
        {
          riderSettingGroup: res.data.riderSettingGroups,
        },
        () => {
          if (this.props.data) {
            const targetSettingGroup = this.state.riderSettingGroup.find(
              (item) => item.idx === this.props.data.riderSettingGroup.idx
            );
            this.formRef.current.setFieldsValue({
              riderSettingGroup: targetSettingGroup
                ? targetSettingGroup.idx
                : this.state.riderSettingGroup[0].idx,
            });
          } else {
            this.formRef.current.setFieldsValue({
              riderSettingGroup: this.state.riderSettingGroup[0].idx,
            });
          }
        }
      );
    }
  };

  onChangFeeManner = (e) => {
    this.setState({ feeManner: e.target.value }, () => { });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys: selectedRowKeys });
  };

  onCheckBikeType = (e) => {
    console.log(e);
    const { data } = this.props;
    console.log(this.state.bikeType)
    if (
      data &&
      this.state.bikeType === 0 &&
      this.state.selectedBike.idx !== null &&
      this.state.selectedBike.idx !== undefined &&
      // if (data && (data.bikeRentOwnedCode === 1)
      (e.target.value === 1 || e.target.value === 2)
    ) {
      alert('냠냠바이크에 문의 후 변경 가능합니다.');
      return;
    }
    if (data === undefined && e.target.value === 0) {
      this.setState({
        bikeNumber: '',
      });
    }
    this.setState({ bikeType: e.target.value }, () => {
      console.log('this.state.bikeType');
      console.log(this.state.bikeType);
    });
  };

  onCheckRiderType = (e) => {
    this.setState({ riderTypeCode: e.target.value });
  };

  openSearchBikeModal = () => {
    this.setState({ isSearchBikeOpen: true });
  };

  closeSearchBikeModal = () => {
    this.setState({ isSearchBikeOpen: false });
  };

  openSearchOnlyTeamManagerModal = () => {
    this.setState({ searchOnlyTeamManagerOpen: true });
  };

  closeSearchOnlyTeamManagerModal = () => {
    this.setState({ searchOnlyTeamManagerOpen: false });
  };

  openSearchAllRiderModal = () => {
    this.setState({ searchAllRiderOpen: true });
  };

  closeSearchAllRiderModal = () => {
    this.setState({ searchAllRiderOpen: false });
  };

  isAgencyRider = async () => {
    try {
      const res = await httpGet(httpUrl.getBranch, [this.props.branchIdx], {});
      if (res.result === 'SUCCESS' && typeof res.data === 'object') {
        if (res.data.companyId != 'connect9') {
          this.setState({
            showWithholdingTax: true,
          });
        } else {
          this.setState({
            showWithholdingTax: false,
          });
        }}
    } catch (e) {
      console.error("isAgencyRider error...", e);
    }
  }

  openUpdateBankInfoOpen = () => {
    this.setState({ updateBankInfoOpen: true });
  };
  closeUpdateBankInfoOpen = () => {
    this.setState({ updateBankInfoOpen: false });
    this.getRiderInfo(this.props.data.idx);
  };

  render() {
    const { close, data, needRiderValidation } = this.props;
    console.log(data);

    const dateFormat = 'YYYY/MM/DD';
    const today = new Date();

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="registRider-Dialog">
          <div className="registRider-content">
            <div className="registRider-title">
              {needRiderValidation ? '기사 인증' : data ? '기사 수정' : '기사 등록'}
            </div>
            <img
              onClick={close}
              src={CloseIcon}
              className="registRider-close"
              alt="close"
            />

            <Form ref={this.formRef} onFinish={this.handleSubmit}>
              <div className="registRiderLayout">
                <div className="registRiderBox">
                  <div className="registFranTitle">
                    <div className="registFranTitle-sub">기본정보</div>
                    <div className="registRiderTitle-foreign">외국인</div>
                    <Checkbox
                      className="override-input checkbox"
                      checked={this.state.isForeigner}
                      onChange={(e)=>this.setState({isForeigner: e.target.checked})}
                      disabled={data ? true : false}
                    />
                    <div className="registRiderTitle-foreign-sub">(기사가 외국인 경우 체크해 주세요.)</div>
                  </div>
                  <div className="registRiderWrapper">
                    <div className="contentBlock">
                      <div className="mainTitle">기사그룹</div>
                      <FormItem
                        name="riderSettingGroup"
                        className="selectItem"
                        rules={[
                          { required: true, message: '그룹을 선택해주세요' },
                        ]}>
                        <Select disabled={needRiderValidation || false}>
                          {this.state.riderSettingGroup &&
                            this.state.riderSettingGroup.map((item) => {
                              return (
                                <Option value={item.idx}>
                                  {item.settingGroupName}
                                </Option>
                              );
                            })}
                        </Select>
                      </FormItem>
                    </div>
                    <div className="contentBlock">
                      <div className="mainTitle">직급</div>
                      <FormItem
                        name="riderLevel"
                        className="selectItem"
                        rules={[
                          { required: true, message: '직급을 선택해주세요' },
                        ]}
                        initialValue={1}>
                        <Select
                          disabled={needRiderValidation || false}
                          onChange={(value) =>
                            this.setState({ riderLevel: value })
                          }>
                          {riderLevelText.map((v, index) => {
                            if (index === 0) return <></>;
                            return <Option value={index}>{v}</Option>;
                          })}
                        </Select>
                      </FormItem>
                    </div>
                    <div className="contentBlock" style={{ marginTop: 10 }}>
                      <div className="mainTitle"></div>
                      <FormItem
                        name="riderType"
                        className="selectItem"
                        rules={[
                          {
                            required: true,
                            message: '라이더 타입을 선택해주세요.',
                          },
                        ]}
                        valuePropName="checked"
                        initialValue={0}>
                        <Radio.Group
                          disabled={needRiderValidation || false}
                          className="searchRequirement"
                          onChange={(e) => this.onCheckRiderType(e)}
                          value={this.state.riderTypeCode}
                          style={{ marginRight: 19 }}>
                          {riderType.map((value, key) => {
                            return <Radio value={key}>{value}</Radio>;
                          })}
                        </Radio.Group>
                      </FormItem>
                    </div>
                    {/* {this.state.riderLevelSelected && (
                      <div className="contentBlock">
                        <div className="mainTitle">소속팀장</div>
                        <FormItem
                          name="teamManager"
                          className="selectItem"
                          rules={[
                            {
                              required: true,
                              message: "팀장을 선택해주세요",
                            },
                          ]}
                        >
                          <Select
                            placeholder="팀장을 선택해주세요."
                            className="override-select branch"
                          >
                            <Option value={1}>김동일</Option>
                            <Option value={2}>문승현</Option>
                            <Option value={3}>송용학</Option>
                            <Option value={4}>김시욱</Option>
                            <Option value={5}>홍원표</Option>
                          </Select>
                        </FormItem>
                      </div>
                    )} */}

                    <div className="contentBlock">
                      <div className="mainTitle">기사명</div>
                      <FormItem
                        name="riderName"
                        className="selectItem"
                        rules={[
                          {
                            required: true,
                            message: '직원명을 입력해주세요',
                          },
                        ]}>
                        <Input
                          placeholder="직원명을 입력해주세요."
                          className="override-input"
                          disabled={needRiderValidation || false}
                        />
                      </FormItem>
                    </div>
                    <div className="contentBlock">
                      <div className="mainTitle">본명</div>
                      <FormItem
                        name="exactRiderName"
                        className="selectItem">
                        <Input
                          placeholder="본명을 정확히 입력해주세요."
                          className="override-input"
                        />
                      </FormItem>
                    </div>
                    <div className="contentBlock">
                      <div className="mainTitle">아이디</div>
                      <FormItem
                        name="id"
                        className="selectItem"
                        rules={[
                          {
                            required: true,
                            message: '아이디를 입력해주세요',
                          },
                        ]}>
                        <Input
                          placeholder="아이디를 입력해 주세요."
                          className="override-input"
                          disabled={data && true}
                        />
                      </FormItem>
                    </div>
                    <div className="contentBlock">
                      <div className="mainTitle">이메일</div>
                      <FormItem
                        name="email"
                        className="selectItem"
                        // rules={[{ required: true, message: "이메일을 입력해주세요" }]}
                      >
                        <Input
                          placeholder="ex) example@naver.com"
                          className="override-input"
                          disabled={needRiderValidation || false}
                        />
                      </FormItem>
                    </div>
                    <div className="contentBlock">
                      <div className="mainTitle">패스워드</div>
                      <FormItem
                        name="password"
                        className="selectItem"
                        rules={[
                          {
                            validator: (rule, value, cb) => {
                              // let strongRegex = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,15}$');
                              // if (!value || value == '') cb();
                              // else if (!strongRegex.test(value)) cb('영문, 숫자, 특수문자 조합 8~15자로 입력');
                              // else cb();
                              cb();
                            },
                          },
                        ]}>
                        <Input.Password
                          autoComplete="new-password"
                          placeholder="패스워드를 입력해 주세요."
                          className="override-input"
                          disabled={needRiderValidation || false}
                        />
                      </FormItem>
                    </div>
                    <div className="contentBlock">
                      <div className="mainTitle">전화번호</div>
                      <FormItem
                        name="phone"
                        className="selectItem"
                        rules={[
                          {
                            required: true,
                            message: '전화번호를 입력해주세요',
                          },
                        ]}>
                        <Input
                          placeholder="휴대전화 번호를 입력해 주세요."
                          className="override-input"
                          disabled={needRiderValidation || false}
                        />
                      </FormItem>
                    </div>
                    {this.state.isSearchBikeOpen && (
                      <SearchBikeDialog
                        onSelect={(selectedBike) =>
                          this.setState({ selectedBike: selectedBike }, () => {
                            console.log('selectedBike');
                            console.log(
                              this.state.selectedBike
                                ? this.state.selectedBike.bikeNumber
                                : ''
                            );
                            console.log(this.state.selectedBike);
                          })
                        }
                        close={this.closeSearchBikeModal}
                      />
                    )}
                    <div className="contentBlock" style={{ marginTop: 10 }}>
                      <div className="mainTitle">바이크</div>
                      <FormItem
                        name="bikeType"
                        className="selectItem"
                        rules={[
                          {
                            required: true,
                            message: '바이크 타입을 선택해주세요.',
                          },
                        ]}
                        valuePropName="checked"
                        initialValue={this.state.bikeType}>
                        <Radio.Group
                          disabled={needRiderValidation || false}
                          className="searchRequirement"
                          onChange={(e) => this.onCheckBikeType(e)}
                          value={this.state.bikeType}
                          style={{ marginRight: 19 }}>
                          {bikeType.map((value, key) => {
                            return <Radio value={key}>{value}</Radio>;
                          })}
                        </Radio.Group>
                      </FormItem>
                    </div>
                    {data === undefined ? (
                      <></>
                    ) : !this.state.data ? (
                      <></>
                    ) : this.state.data.bikeRentOwnedCode === 2 ||
                      this.state.data.bikeRentOwnedCode === 3 ? (
                      <></>
                    ) : this.state.data.bikeRentOwnedCode === 1  &&
                      this.state.bikeType !== 0 ? (
                      <></>
                    ) : this.state.data.bike.idx === null ? (
                      <div className="contentBlock" style={{ marginTop: 10 }}>
                        <div className="mainTitle"></div>
                        <div className="selectItem">
                          <Input
                            className="override-input"
                            value="바이크 미배정 상태입니다."
                            disabled
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="contentBlock" style={{ marginTop: 10 }}>
                        <div className="mainTitle"></div>
                        <div className="selectItem">
                          <Input
                            className="override-input"
                            value={'바이크번호: ' + this.state.data.bike.bikeNumber}
                            disabled
                          />
                        </div>
                      </div>
                    )}
                    <div className="contentBlock">
                      <div className="mainTitle">메모</div>
                      <FormItem
                        name="memo"
                        className="selectItem"
                        // rules={[{ required: true, message: "메모를 입력해주세요" }]}
                      >
                        <Input
                          placeholder="메모를 입력해 주세요."
                          className="override-input"
                          disabled={needRiderValidation || false}
                        />
                      </FormItem>
                    </div>
                    <div className="contentBlock">
                      {/* 컬럼 확인 필요 */}
                      <div className="mainTitle">최소보유잔액</div>
                      <FormItem
                        name="withdrawLimit"
                        className="selectItem"
                        initialValue={100000}>
                        <Input
                          placeholder="최소보유잔액을 입력해 주세요."
                          className="override-input"
                          disabled={needRiderValidation || false}
                        />
                      </FormItem>
                    </div>
                    {data ? (
                      <>
                        <div className="contentBlock">
                          <div className="mainTitle">면허정보</div>
                          <FormItem name="driverLicense" className="selectItem">
                            <Input
                              disabled
                              style={{ width: 170 }}
                            />
                            { this.state.data &&
                              <Popover
                                content={
                                  <Image
                                    src={imageUrl(this.state.data.driverLicenseFileIdx)}
                                    style={{ width: 400, height: 300 }}
                                    alt="면허증 사진"
                                  />
                                }>
                                <Button>파일보기</Button>
                              </Popover>
                            }
                          </FormItem>
                        </div>
                        <div className="contentBlock">
                          <div className="mainTitle">0콜 사유</div>
                          <FormItem
                            name="zeroCallMemo"
                            className="selectItem">
                            <Input.TextArea
                              placeholder={'0콜 사유가 없습니다.'}
                              className="override-input"
                              disabled={data && data.zeroCallMemo ? false : true}
                              maxLength={50}
                            />
                          </FormItem>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="bike-type-box">
                      <div>지입바이크 등록</div>
                      <ul>
                        <li>
                          <p className="regist-bike-title">바이크번호</p>
                          <div className="selectItem">
                            <FormItem
                              name="ownedBikeNumber"
                              className="selectItem"
                              rules={[
                                {
                                  required:
                                    this.state.bikeType === 1 ? true : false,
                                  message: '바이크번호를 입력해주세요',
                                },
                              ]}>
                              <Input
                                disabled={this.state.bikeType === 1 ? false : true}
                                placeholder="번호를 입력해주세요."
                                className="override-input"
                                value={this.state.bikeNumber}
                                onChange={(e) =>
                                  this.setState({
                                    bikeNumber: e.target.value,
                                  })
                                }
                              />
                            </FormItem>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="registRiderWrapper sub">
                    {this.state.riderLevel >= 3 && (
                      <>
                        <div className="contentBlock">
                          <div className="mainTitle">기본 배달료</div>
                          <FormItem
                            name="basicDeliveryPrice"
                            className="selectItem"
                            initialValue={3600}>
                            <Input
                              placeholder="기본배달료를 입력해 주세요."
                              className="override-input"
                              disabled={needRiderValidation || false}
                            />
                          </FormItem>
                        </div>
                        <div className="contentBlock">
                          <div className="mainTitle">월기본건수</div>
                          <FormItem
                            name="monthBasicAmount"
                            className="selectItem"
                            initialValue={250}>
                            <Input
                              placeholder="월기본건수를 입력해 주세요."
                              className="override-input"
                              disabled={needRiderValidation || false}
                            />
                          </FormItem>
                        </div>
                      </>
                    )}
                    {this.state.riderLevel < 2 && (
                      <>
                        <div className="contentBlock">
                          <div className="mainTitle">소속팀장</div>
                          * 소속팀장 설정은 '팀 관리'에서 가능합니다.
                          {/* <FormItem
                            name="teamManagerIdx"
                            className="selectItem"
                            rules={[
                              {
                                required: true,
                                message: '팀장순번을 입력해주세요',
                              },
                            ]}>
                            <Input
                              disabled={needRiderValidation || false}
                              style={{ width: 170 }}
                              placeholder="팀장순번을 입력해 주세요."
                              value={
                                this.state.selectedRider &&
                                this.state.selectedRider.idx
                              }
                            />
                            <Button
                              onClick={() =>
                                this.openSearchOnlyTeamManagerModal()
                              }>
                              기사 조회
                            </Button>
                          </FormItem> */}
                        </div>
                        {/* {this.state.searchOnlyTeamManagerOpen && (
                          <SearchRiderDialog
                            teamManagerOnly={true}
                            riderIdx={data && data.idx}
                            callback={(selectedRider) =>
                              this.setState(
                                { selectedRider: selectedRider },
                                () => {
                                  this.formRef.current.setFieldsValue({
                                    teamManagerIdx:
                                      this.state.selectedRider.idx,
                                  });
                                }
                              )
                            }
                            close={this.closeSearchOnlyTeamManagerModal}
                          />
                        )} */}
                      </>
                    )}

                    { !this.state.isForeigner &&
                      <div className="contentBlock">
                        <div className="mainTitle">주민등록번호</div>
                        <FormItem
                          name="registrationNumber"
                          className="selectItem"
                          rules={[
                            {
                              required: true,
                              message: "주민번호를 '-'를 빼고 입력해주세요",
                            },
                          ]}
                          initialValue={''}>
                          <Input
                            placeholder="주민번호를 입력해 주세요."
                            className="override-input"
                          />
                        </FormItem>
                      </div>
                    }
                    <div className="contentBlock">
                      <div className="mainTitle">은행</div>
                      <FormItem
                        name="bank"
                        className="selectItem"
                        rules={[
                          { required: true, message: '은행을 선택해주세요' },
                        ]}
                        initialValue={'기업은행,003'}>
                        <Select disabled={data ? true : false}>
                          {Object.keys(bankCode).map((key) => {
                            return (
                              <Option value={key + ',' + bankCode[key]}>
                                {key}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </div>
                    <div className="contentBlock">
                      <div className="mainTitle">계좌번호</div>
                      <FormItem
                        name="bankAccount"
                        className="selectItem"
                        rules={[
                          {
                            required: true,
                            message: '계좌번호를 입력해주세요',
                          },
                        ]}>
                        <Input
                          placeholder="계좌번호를 입력해 주세요."
                          className="override-input"
                          disabled={data ? true : false}
                        />
                      </FormItem>
                    </div>
                    <div className="contentBlock">
                      <div className="mainTitle">예금주</div>
                      <FormItem
                        name="depositor"
                        className="selectItem"
                        rules={[
                          { required: true, message: '예금주를 입력해주세요' },
                        ]}>
                        <Input
                          placeholder="예금주를 입력해 주세요."
                          className="override-input"
                          disabled={data ? true : false}
                        />
                      </FormItem>
                    </div>
                    {!this.state.isConnect9 && data?
                        <div className="contentBlock">
                          <div className="mainTitle"></div>
                          <Button onClick={() => this.openUpdateBankInfoOpen()}>
                            {'계좌정보 수정'}
                          </Button>
                        </div> : <div></div>
                    }
                    <div className="contentBlock">
                      <div className="mainTitle">입사일</div>
                      <FormItem
                        name="joinDate"
                        className="selectItem"
                        initialValue={moment(today, dateFormat)}>
                        <DatePicker
                          style={{ width: 260 }}
                          className="selectItem"
                          format={dateFormat}
                          disabled={needRiderValidation || false}
                        />
                      </FormItem>
                    </div>
                    {/* <div className="contentBlock">
                      <div className="mainTitle">퇴사일</div>
                      <FormItem name="leaveDate" className="selectItem">
                        <DatePicker
                          style={{ width: 260 }}
                          className="selectItem"
                          defaultValue={
                            data
                              ? data.leaveDate
                                ? moment(data.leaveDate, "YYYY-MM-DD")
                                : ""
                              : ""
                          }
                          format={dateFormat}
                        />
                      </FormItem>
                    </div> */}
                    <div className="contentBlock">
                      <div className="mainTitle">비품지급</div>
                      <FormItem
                        name="items"
                        className="giveBox selectItem"
                        initialValue={[]}>
                        <Checkbox.Group
                          options={items}
                          // initialValue={
                          //   data && data.items ? data.items.split(",") : []
                          // }
                          onChange={(value) => console.log(value)}
                          disabled={needRiderValidation || false}
                        />
                      </FormItem>
                    </div>
                    {data ? (
                      <div className="contentBlock">
                        <div className="mainTitle">상해보험</div>
                        <Button onClick={() => this.handleToggle(data)} disabled={needRiderValidation || false}>
                          {!this.state.accidentInsurance
                            ? '해지하기'
                            : '가입하기'}
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                    <>
                      <div className="contentBlock">
                        <div className="mainTitle">추천인 등록</div>
                        <FormItem name="recommenderIdx" className="selectItem">
                          <Input
                            style={{ width: 170 }}
                            placeholder={
                              data
                                ? this.state.data
                                  ? this.state.data.recommenderIdx > 0
                                    ? this.state.data.recommenderIdx
                                    : '추천인 없음'
                                  : '추천인 없음'
                                : '추천인순번을 입력해 주세요.'
                            }
                            disabled={data ? true : false}
                            value={
                              this.state.selectRecommender &&
                              this.state.selectRecommender.idx
                            }
                          />
                          <Button
                            disabled={data ? true : false}
                            onClick={() => this.openSearchAllRiderModal()}>
                            기사 조회
                          </Button>
                        </FormItem>
                      </div>
                      {this.state.searchAllRiderOpen && (
                        <SearchRiderDialog
                          teamManagerOnly={false}
                          riderIdx={data && data.idx}
                          callback={(selectRecommender) =>
                            this.setState(
                              { selectRecommender: selectRecommender },
                              () => {
                                this.formRef.current.setFieldsValue({
                                  recommenderIdx:
                                    this.state.selectRecommender.idx,
                                });
                              }
                            )
                          }
                          close={this.closeSearchAllRiderModal}
                          isSelectBox={true}
                        />
                      )}
                      {this.state.updateBankInfoOpen && (
                          <UpdateRiderBankInfoDialog
                              userIdx={data.idx}
                              bank={data.bank}
                              bankAccount={data.bankAccount}
                              depositor={data.depositor}
                              close={this.closeUpdateBankInfoOpen}/>
                      )}
                    </>

                    {this.state.showWithholdingTax && (
                    <div className="contentBlock">
                      <div className="mainTitle">원천징수(3.3%)</div>
                      <FormItem name="isWithholdingTax" className="selectItem">
                        <Checkbox
                            className="override-input checkbox"
                            checked={this.state.isWithholdingTax}
                            disabled={needRiderValidation || false}
                            onChange={(e) =>
                                this.setState({isWithholdingTax: e.target.checked})
                            }/>
                      </FormItem>
                    </div>
                    )}
                    <div className="contentBlock">
                      <div className="mainTitle">정직원 여부</div>
                      <FormItem name="fullTimeEmployee" className="selectItem">
                        <Checkbox
                            className="override-input checkbox"
                            checked={this.state.fullTimeEmployee}
                            disabled={this.state.isConnect9}
                            onChange={(e) =>
                                this.setState({fullTimeEmployee: e.target.checked})
                            }/>
                      </FormItem>
                    </div>

                    { this.state.isForeigner &&
                      <>
                        <div className="contentBlock">
                          <div className="mainTitle">외국인 등록번호*</div>
                          <FormItem
                            name="registrationNumber"
                            className="selectItem"
                            rules={[
                              {
                                required: true,
                                message: '외국인 등록번호를 입력해주세요',
                              },
                            ]}
                            >
                            <Input
                              placeholder='"-"를 빼고 숫자만 입력해주세요.'
                              className="override-input"
                              disabled={this.state.data ? this.state.data.riderNameValid : false}
                            />
                          </FormItem>
                        </div>
                        <div className="contentBlock">
                          <div className="mainTitle">국가*</div>
                          <FormItem
                            name="foreignerCountryCode"
                            className="selectItem"
                            rules={[
                              {
                                required: true,
                                message: '국가를 선택해주세요',
                              },
                            ]}
                            >
                            <Select
                              placeholder="국가를 선택해주세요"
                              disabled={data ? true : false}
                            >
                              {Object.keys(foreignerCountryCode).map((v)=>{
                                return <Option value={parseInt(v)} key={v}>{foreignerCountryCode[v]}</Option>
                              })}
                            </Select>
                          </FormItem>
                        </div>
                        <div className="contentBlock">
                          <div className="mainTitle">취득일*</div>
                          <FormItem
                            name="foreignerAcquisitionDate"
                            className="selectItem"
                            rules={[
                              {
                                required: true,
                                message: '취득일을 선택해주세요',
                              },
                            ]}>
                            <DatePicker
                              placeholder="YYYY/MM/DD"
                              format={dateFormat}
                              style={{ width: 260 }}
                              className="selectItem"
                              disabled={data ? true : false}
                            />
                          </FormItem>
                        </div>
                        <div className="contentBlock">
                          <div className="mainTitle">체류자격*</div>
                          <FormItem
                            name="foreignerStatusCode"
                            className="selectItem"
                            rules={[
                              {
                                required: true,
                                message: '체류자격을 선택해주세요',
                              },
                            ]}
                            >
                            <Select
                              placeholder="체류자격을 선택해주세요"
                              disabled={data ? true : false}
                            >
                              {
                                Object.keys(foreignerStatusCode).map(v=>{
                                  return <Option key={v} value={v}>{v+"("+foreignerStatusCode[v]+")"}</Option>
                                })
                              }
                            </Select>
                          </FormItem>
                        </div>
                        <div className='contentBlock' style={{display:'flex', justifyContent: 'flex-end'}}>
                          <div style={{fontSize: 12, width: 350, paddingRight: 20}}>체류 자격이 목록에 없는 경우, 고용보험 신고 대상자가 아니며, 체류 자격을 잘못 선택 하면 고용보험 가입이 거절될 수 있습니다.</div>
                        </div>
                      </>
                    }
                    {this.state.isConnect9 && (<div className="contentBlock">
                      <div className="mainTitle">라이더 레벨업</div>
                      <FormItem name="fullTimeEmployee" className="selectItem">
                        <Checkbox
                            className="override-input checkbox"
                            checked={this.state.levelUpPointFlag}
                            onChange={(e) =>
                                this.setState({levelUpPointFlag: e.target.checked})
                            }/>
                      </FormItem>
                    </div>)}

                    {/* <div className="contentBlock" style={{ marginTop: 10 }}>
                      <div className="mainTitle">추천배차 사용</div>
                      <FormItem
                        name="agreeForceAllocate"
                        className="giveBox selectItem"
                        defaultChecked={data ? data.agreeForceAllocate : true}
                      >
                        <Checkbox
                          className="override-input"
                          defaultChecked={data ? data.agreeForceAllocate : true}
                        >
                          사용
                        </Checkbox>
                      </FormItem>
                    </div> */}
                    {/* <div className="contentBlock" style={{ marginTop: 10 }}>
                      <div className="mainTitle">SMS수신동의</div>
                      <FormItem name="agreeSms" className="giveBox selectItem">
                        <Checkbox
                          className="override-input"
                          defaultChecked={data ? data.agreeSms : true}
                          onChange={(e) =>
                            this.setState({ agreeSms: e.target.checked })
                          }
                        >
                          수신동의
                        </Checkbox>
                      </FormItem>
                    </div> */}
                    <div className="submitBlock">
                      <Button type="primary" htmlType="submit">
                        {needRiderValidation ? '인증하기' : '등록하기'}
                      </Button>

                      {!data && (
                        <Button className="clearBtn" onClick={this.handleClear}>
                          초기화
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="bike-type-box">
                  <div>지입바이크 등록</div>
                  <ul>
                    <li>
                      <p className="regist-bike-title">바이크번호</p>
                      <div className="selectItem">
                        <FormItem
                          name="ownedBikeNumber"
                          className="selectItem"
                          rules={[
                            {
                              required:
                                this.state.bikeType === 1 ? true : false,
                              message: '바이크번호를 입력해주세요',
                            },
                          ]}>
                          <Input
                            disabled={this.state.bikeType === 1 ? false : true}
                            placeholder="번호를 입력해주세요."
                            className="override-input"
                            value={this.state.bikeNumber}
                            onChange={(e) =>
                              this.setState({
                                bikeNumber: e.target.value,
                              })
                            }
                          />
                        </FormItem>
                      </div>
                    </li>
                  </ul>
                </div> */}
              </div>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loginInfo: state.login.loginInfo,
  branchIdx: state.login.loginInfo.branchIdx,
  branchInfo: state.branchReducer.branchInfo,
});

const mapDispatchToProps = () => { };

export default connect(mapStateToProps, mapDispatchToProps)(RegistRiderDialog);
