import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { comma } from '../../lib/util/numberUtil';
import axios from 'axios';
import Const from '../../const';
import FileSaver from 'file-saver';
import { Button, DatePicker, Input, Modal, Select, Table, Tooltip } from 'antd';
import { SearchOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';

const today = moment().format('YYYY-MM-DD');
const AgecnyDeliveryList = () => {
  const loginInfo = useSelector((state) => state.login.loginInfo);
  const CancelToken = axios.CancelToken;
  const source = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [exceldownloading, setExceldownloading] = useState(false);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 20,
  });
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [search, setSearch] = useState(null);
  const [deliveryStatus, setDeliveryStatus] = useState('All');
  const [callType, setCallType] = useState('All');
  useEffect(() => {
    getData();
    return () => {
      if (source.current !== null) {
        source.current.cancel();
      }
    };
  }, [pagination.current, callType]);

  const getDeliveryDate = async (customParams) => {
    if (source.current !== null) {
      // already exists request
      source.current.cancel();
    }
    source.current = CancelToken.source();
    setIsLoading(true);
    try {
      return await axios.get('deposit/delivery-list', {
        baseURL: Const.settlementUrl,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        params: customParams,
        cancelToken: source.current.token,
      });
    } catch (e) {
      console.error('getting ncash-delivery-list is fail: ' + e);
    }
  };
  const getData = async (paging) => {
    if (exceldownloading) return;
    let currPage = pagination.current;
    if (paging) {
      currPage = 1;
    }
    let customParams = {
      startDate: startDate,
      endDate: endDate,
      companyId: loginInfo.companyId,
      page: currPage,
      size: pagination.pageSize,
      callType: callType,
    };
    if (search !== '' || search != null) {
      customParams.search = search;
    }
    const datas = await getDeliveryDate(customParams);
    console.log(datas);
    if (datas) {
      setList(() => {
        return datas.data.results;
      });
      setPagination({
        ...pagination,
        current: currPage,
        total: datas.data.totalCount,
      });
      setIsLoading(false);
    }
    if (source.current === null) {
      setIsLoading(false);
    }
  };
  const onChangeDate = (type, _, date) => {
    if (type === 'start') {
      setStartDate(moment(date).format('YYYY-MM-DD'));
      const temp = moment(date).add(30, 'd').format('YYYY-MM-DD');
      if (moment(endDate).isAfter(temp)) setEndDate(temp);
    } else {
      setEndDate(moment(date).format('YYYY-MM-DD'));
    }
  };
  const disabledDate = (current, from) => {
    if (startDate) {
      return Math.abs(current.diff(startDate, 'days')) > 30;
    }
    return false;
  };
  const downloadExcelApi = async (customParamsForExcel) => {
    if (source.current !== null) {
      // already exists request
      source.current.cancel();
    }
    source.current = CancelToken.source();
    try {
      return await axios.get('deposit/delivery-list/excel', {
        baseURL: Const.settlementUrl,
        params: customParamsForExcel,
        cancelToken: source.current.token,
        responseType: 'arraybuffer',
      });
    } catch (e) {
      console.error('ncashCompanyList-execl-downloading is failed: ' + e);
    }
  };
  const onDownloadExcel = async () => {
    let customParamsForExcel = {
      startDate: startDate,
      endDate: endDate,
      companyId: loginInfo.companyId,
      callType: callType,
    };
    if (search !== '' || search != null) customParamsForExcel.search = search;

    if (isLoading) return;
    setIsLoading(true);
    setExceldownloading(true);
    const response = await downloadExcelApi(customParamsForExcel);
    if (response) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, '예치금-배달내역-' + `${moment(startDate).format('YYYY-MM-DD')}.xlsx`);
      setIsLoading(false);
      setExceldownloading(false);
      Modal.success({
        content: '엑셀파일 다운로드가 완료되었습니다.',
      });
      setIsLoading(false);
      setExceldownloading(false);
    } else {
      Modal.error({
        content: '다운로드에 실패했습니다. 검색 기간을 줄여서 다시 시도하시거나 관리자에게 문의해주세요.',
      });
      setIsLoading(false);
      setExceldownloading(false);
    }
  };
  const columns = [
    {
      title: '콜 구분',
      dataIndex: 'callType',
      className: 'table-column-center',
    },
    {
      title: '배달상태',
      dataIndex: 'deliveryStatus',
      className: 'table-column-center',
    },
    {
      title: '접수일시',
      dataIndex: 'orderDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '완료일시',
      dataIndex: 'completeDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '주문번호',
      dataIndex: 'orderIdx',
      className: 'table-column-center',
    },
    {
      title: '가맹점 소속 대리점(A)',
      dataIndex: 'frBranch',
      className: 'table-column-center',
    },
    {
      title: '실가맹점명',
      dataIndex: 'frNameExtra',
      className: 'table-column-center',
    },
    {
      title: '사업자번호',
      dataIndex: 'businessNum',
      className: 'table-column-center',
    },
    {
      title: '대표자명',
      dataIndex: 'ownerName',
      className: 'table-column-center',
    },
    {
      title: '라이더 소속 대리점(B)',
      dataIndex: 'riderBranch',
      className: 'table-column-center',
    },
    {
      title: '라이더 실명',
      dataIndex: 'exactRiderName',
      className: 'table-column-center',
    },
    {
      title: '직급',
      dataIndex: 'riderLevel',
      className: 'table-column-center',
    },
    {
      title: '이메일',
      dataIndex: 'riderEmail',
      className: 'table-column-center',
    },
    {
      title: '주민번호',
      dataIndex: 'riderRegistNum',
      className: 'table-column-center',
    },
    {
      title: '연락처',
      dataIndex: 'riderPhoneNum',
      className: 'table-column-center',
    },
    {
      title: '도착지',
      dataIndex: 'addr',
      className: 'table-column-center',
    },
    {
      title: '금액',
      dataIndex: 'orderPrice',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '기본배달비(A)',
      dataIndex: 'basicDeliveryPrice',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '할증배달비(A)',
      dataIndex: 'extraDeliveryPrice',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '총 배달비(A)',
      dataIndex: 'deliveryPrice',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '총 배달비 부가세(A)',
      dataIndex: 'deliveryPriceVat',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '총 배달비 수수료(A+B)',
      dataIndex: 'riderFee',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '공유콜 수수료(A)',
      dataIndex: 'shareCallFeeFrBranch',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '공유콜 수수료 부가세(A)',
      dataIndex: 'shareCallFeeVatFrBranch',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '공유콜 수수료(B)',
      dataIndex: 'shareCallFeeRiderBranch',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '공유콜 수수료 부가세(B)',
      dataIndex: 'shareCallFeeVatRiderBranch',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '공유콜 배달비(B)',
      dataIndex: 'shareCallPrice',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '공유콜 배달비 매입 부가세(A)',
      dataIndex: 'shareCallPriceBuyingVat',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '공유콜 배달비 매출 부가세(B)',
      dataIndex: 'shareCallPriceSalesVat',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '건별 가맹비(A)',
      dataIndex: 'duesDeliveryPrice',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '건별 가맹비 부가세(A)',
      dataIndex: 'duesDeliveryPriceVat',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '제휴본사 수수료(A)',
      dataIndex: 'agencyHeadquarterFee',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '건별 수수료(A)',
      dataIndex: 'deliveryFeeByDpBasic',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '할증 건별 수수료(A)',
      dataIndex: 'deliveryFeeByDpRegion',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '콜수수료(A)',
      dataIndex: 'callFee',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '콜수수료 부가세(A)',
      dataIndex: 'callFeeVat',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '프로그램 사용료(A)',
      dataIndex: 'agencyFee',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '프로그램 사용료 매입 부가세(A)',
      dataIndex: 'agencyFeeVat',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '고용(대리점)(B)',
      dataIndex: 'employInsurance',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '산재(대리점)(B)',
      dataIndex: 'accidentInsurance',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '취소패널티(B)',
      dataIndex: 'cancelPenalty',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '라이더수익(B)',
      dataIndex: 'riderIncome',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '라이더원천세(B)',
      dataIndex: 'riderTax',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ];
  return (
    <div id={'ncashCompanyList'}>
      <div className={'ncl-menu-wrap'}>
        <div className={'ncl-select-box'} style={{ marginLeft: 0, marginRight: 10, width: 120 }}>
          <Select
            defaultValue={'콜 구분'}
            onChange={(value) => {
              setCallType(value);
              setPagination({
                ...pagination,
                current: 1,
              });
            }}
            style={{ flex: 1 }}
            options={[
              { value: 'All', label: '콜 구분' },
              { value: '매입', label: '매입' },
              { value: '매출', label: '매출' },
              { value: '자사', label: '자사' },
            ]}
          />
        </div>
        {/*<div className={'ncl-select-box'} style={{ marginRight: 10, width: 120 }}>*/}
        {/*  <Select*/}
        {/*    defaultValue={'배달 상태'}*/}
        {/*    onChange={(value) => {*/}
        {/*      setDeliveryStatus(value);*/}
        {/*      setPagination({*/}
        {/*        ...pagination,*/}
        {/*        current: 1,*/}
        {/*      });*/}
        {/*    }}*/}
        {/*    style={{ flex: 1 }}*/}
        {/*    options={[*/}
        {/*      { value: 'All', label: '배달 상태' },*/}
        {/*      { value: '완료', label: '완료' },*/}
        {/*      { value: '복원', label: '복원' },*/}
        {/*    ]}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className={'ncl-input-box'} style={{ width: 280 }}>
          <Input
            onPressEnter={getData}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={'주문번호/라이더명/가맹점명/사업자번호'}
            style={{
              border: 'none',
              width: 260,
            }}
          />
          <SearchOutlined style={{ padding: '0 10px', cursor: 'pointer' }} onClick={getData} />
        </div>
        <div className={'ncl-input-box'} style={{ width: 140 }}>
          <DatePicker
            style={{ border: 'none', flex: 1 }}
            onChange={(_, date) => onChangeDate('start', _, date)}
            value={moment(startDate)}
            allowClear={false}
          />
        </div>
        <span style={{ marginRight: 5 }}>-</span>
        <div className={'ncl-input-box'} style={{ width: 140, marginLeft: 0 }}>
          <DatePicker
            style={{ border: 'none', flex: 1 }}
            onChange={(_, date) => onChangeDate('end', _, date)}
            value={moment(endDate)}
            disabledDate={disabledDate}
            allowClear={false}
          />
        </div>
        <Button
          onClick={getData}
          style={{
            height: 40,
            borderColor: '#c1c1c1',
            borderRadius: 5,
          }}>
          검색
        </Button>
        <Tooltip title="기간 설정은 한달 단위로 선택이 가능합니다." placement="topRight">
          <div className={'ncl-excel-box'} onClick={onDownloadExcel}>
            엑셀다운로드
            <VerticalAlignBottomOutlined />
          </div>
        </Tooltip>
      </div>
      <div className={'ncl-table delivery'}>
        <Table
          rowKey={(record) => record.idx}
          dataSource={list}
          columns={columns}
          pagination={pagination}
          loading={isLoading}
          scroll={{
            x: 4500,
          }}
          rowClassName={(record, index) => index % 2 !== 0 && 'ncl-table-row'}
          onChange={(pagination) => setPagination(pagination)}
        />
      </div>
    </div>
  );
};

export default AgecnyDeliveryList;
