import { Button, Modal, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPost, httpUrl } from '../../../api/httpClient';
import { updateComplete, updateError, registError } from '../../../api/Modals';
import '../../../css/modal.css';
import CloseIcon from '../../../img/login/close.png';
import { statusString } from '../../../lib/util/codeUtil';
import SelectBox from '../../input/SelectBox';
import ZeroCallFrMemoDialog from './ZeroCallFrMemoDialog';

class ZeroCallFrListDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      zeroFrList: [],
      openZeroCallFrMemo: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({
        pagination: pager,
      }, () => this.getList()
    );
  };

  getList = () => {
    const branchIdx = this.props.branchIdx;
    try {
      httpGet(httpUrl.getZeroFrList, [branchIdx], {}).then((res) => {
        console.log(res.data.list);
        if (res.result === 'SUCCESS' && res.data !== null && res.data !== undefined) {
          this.setState({
            zeroFrList: res.data.list,
          });
        }
      });
    } catch (error) {
      console.error('getZeroFrList: ', error);
    }
  };

  // 가맹점 상태변경
  onChangeStatus = (idx, value) => {
    httpPost(httpUrl.franchiseUpdate, [], {
      idx: idx,
      userStatus: value,
    })
      .then((res) => {
        if (res.result === "SUCCESS" && res.data === "SUCCESS") {
          updateComplete();
        } else {
          updateError();
        }
        this.getList();
      })
      .catch((e) => {
        updateError();
      });
  };

  openZeroCallFrMemoDialog = (row) => {
    this.setState({
      openZeroCallFrMemo: true,
      data: row,
    });
  };

  closeZeroCallFrMemoDialog = () => {
    this.setState({
      openZeroCallFrMemo: false,
    });
  };

  callback = (idx, frIdx, zeroCallMemo, zeroCallMemoExists) => {
    let object = { idx: idx, frIdx: frIdx, zeroCallMemo: zeroCallMemo, zeroCallMemoExists: zeroCallMemoExists };
    this.setState({
        list: this.state.list.concat(object),
      },
      () => console.log(this.state.list)
    );
  };

  render() {
    const { list } = this.state;
    const columns = [
      {
        title: '이름',
        dataIndex: 'frName',
        className: 'table-column-center',
        render: (data, row) => (
          <div
            style={{
              background:
                row.zeroCallMemoExists === 1 ||
                list.filter((item) => item.idx === row.idx).length > 0
                  ? '#45bade'
                  : '#e66963',
            }}>
            {data}
          </div>
        ),
      },
      {
        title: "마지막 이용날짜",
        dataIndex: "lastOrderDate",
        className: "table-column-center",
        render:(data) => <div>{moment(data).format("YYYY-MM-DD")}</div>
      },
      {
        title: "번호",
        dataIndex: "frPhone",
        className: "table-column-center",
      },
      {
        title: "0콜 연속일수",
        dataIndex: "zeroCallDays",
        className: "table-column-center",
        render:(data) => <div>{data}일</div>
      },
      {
        title: '상태',
        dataIndex: 'userStatus',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            <SelectBox
              value={statusString[data]}
              code={Object.keys(statusString)}
              codeString={statusString}
              onChange={(value) => {
                if (parseInt(value) !== row.userStatus) {
                  this.onChangeStatus(row.frIdx, value);
                }
              }}
            />
          </div>
        ),
      },
      {
        title: '사유',
        className: 'table-column-center',
        render: (row) => (
          <div>
            <Button onClick={() => this.openZeroCallFrMemoDialog(row)}>
              사유작성
            </Button>
          </div>
        ),
      },
    ];

    const { close, login } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className={login ? "franchise-login-Dialog2" : "franchise-Dialog2"}>
          <div className="rider-content2" style={{ padding: '50px' }}>
            <div className="rider-title2">전날 0콜 가맹점 리스트</div>
            <img
              onClick={close}
              src={CloseIcon}
              className="rider-close2"
              alt="close"
            />
            <div>
              <div style={{fontSize: "18px", fontWeight: "bold"}}>오늘 날짜 : {moment(new Date()).format("YYYY-MM-DD")}</div>
            </div>
            <div>
              <div style={{fontSize: "16px", fontWeight: "bold"}}>* 사유 작성후 저장 버튼을 눌러야 저장이 완료됩니다.</div>
            </div>
            <div className="listBlock">
              {this.state.openZeroCallFrMemo && (
                <ZeroCallFrMemoDialog
                  callback={this.callback}
                  data={this.state.data}
                  close={this.closeZeroCallFrMemoDialog}
                />
              )}
              <Table
                dataSource={this.state.zeroFrList}
                columns={columns}
                pagination={{...this.state.pagination, pageSizeOptions: ["10", "20"]}}
                onChange={this.handleTableChange}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  branchIdx: state.login.loginInfo.branchIdx,
});

export default connect(mapStateToProps)(ZeroCallFrListDialog);
