import { Affix, Button, Checkbox, Form, Input, Modal, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reactLocalStorage } from "reactjs-localstorage";
import { setRider } from '../../actions/riderAction';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { customAlert, updateError } from '../../api/Modals';
import VirtualTable from '../../components/dialog/common/VirtualTable';
import BatchWorkedListDialog from '../../components/dialog/rider/BatchWorkedListDialog';
import BatchWorkListDialog from '../../components/dialog/rider/BatchWorkListDialog';
import BlindRiderListDialog from '../../components/dialog/rider/BlindRiderListDialog';
import CoinTransferDialog from '../../components/dialog/rider/CoinTransferDialog';
import LicenseApproveDialog from '../../components/dialog/rider/LicenseApproveDialog';
import RegistRiderDialog from '../../components/dialog/rider/RegistRiderDialog';
import RiderGroupDialog from '../../components/dialog/rider/RiderGroupDialog';
import RiderUploadFileDialog from '../../components/dialog/rider/RiderUploadFileDialog';
import UpdatePasswordDialog from '../../components/dialog/rider/UpdatePasswordDialog';
import SelectBox from '../../components/input/SelectBox';
import '../../css/modal.css';
import '../../css/modal_m.css';
import '../../css/order.css';
import '../../css/order_m.css';
import '../../css/rider.css';
import ZeroCallRiderDialog from "../../components/dialog/rider/ZeroCallRiderDialog";
import Const from "../../const";
import {
  dwServiceTypeCode,
  mapPaystoryVacntResultCd,
  rentOwnedPaperCodeList,
  riderApprovedCode,
  riderLevelText,
  riderPaperNoCodeToText,
  riderPaperStatusCode,
  riderStatusCode,
  statusString,
  tableStatusString,
} from '../../lib/util/codeUtil';
import { formatDateToDay } from '../../lib/util/dateUtil';
import { comma } from '../../lib/util/numberUtil';
import RiderTeamDialog from '../../components/dialog/rider/RiderTeamDialog';
import RiderAccidentInsurance from "../../components/dialog/rider/RiderAccidentInsurance";
// import xlsx from "xlsx";
import { createSortParam } from "../../lib/util/pagingSortUtil";

const Search = Input.Search;

class RiderMain extends Component {
  constructor(props) {
    super(props);
    this.riderRef = React.createRef();
    this.state = {
      selectedRiderLevel: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      // selectedRiderSettingGroup: [1, 2, 3, 4, 5],
      userData: 1,
      searchName: '',
      taskSchedulerOpen: false, // 일차감
      taskWorkListOpen: false, //일차감 내역
      riderGroupOpen: false, // 기사 그룹 관리
      registRiderOpen: false, // 기사등록
      riderCoinOpen: false, // 기사코인충전
      riderBankOpen: false, //기사 입출금내역
      workTabOpen: false, // 작업
      riderUpdateOpen: false, // 기사 수정
      updatePasswordOpen: false, // 출금 비밀번호
      blindListOpen: false, // 블라인드
      coinOpen: false, // 코인이력
      riderLevelOpen: false, //지급필터
      settingGroupName: false,
      // riderSettingGroupOpen: false,
      uploadFileOpen: false, //서류제출 모달
      uploadFileData: [],
      blindRiderData: [], //블라인드 정보
      pagination: {
        total: 0,
        current: 1,
        pageSize: 100,
      },
      sortParam: {
        column: 'idx',
        direction: 'desc',
      },
      // paginationStatus: {
      //   total: 0,
      //   current: 1,
      //   pageSize: 10500,
      // },
      dialogData: [],
      userStatus: 1,
      selRider: '',
      withdrawPassword: 1111,
      statusList: [],
      settingGroupCount: [],
      resultsStatus: [],
      results: [],
      //selectedRow : null,
      licenseApproveModalOpen: false,
      excelList: [],
      riderStatus: 0,
      openZeroRiderList: false,
      riderTeamOpen: false,
      needRiderValidation: false,
      openAccidentInsuranceList: false,
      riderLevel: [
        {
          key: 'riderLevel-1',
          value: 1,
          text: '라이더',
        },
        {
          key: 'riderLevel-2',
          value: 2,
          text: '팀장',
        },
        {
          key: 'riderLevel-3',
          value: 3,
          text: '본부장',
        },
        {
          key: 'riderLevel-4',
          value: 4,
          text: '부본부장',
        },
        {
          key: 'riderLevel-5',
          value: 5,
          text: '수석본부장',
        },
        {
          key: 'riderLevel-6',
          value: 6,
          text: '부지점장',
        },
        {
          key: 'riderLevel-7',
          value: 7,
          text: '지점장',
        },
        {
          key: 'riderLevel-7',
          value: 8,
          text: '부센터장',
        },

        {
          key: 'riderLevel-7',
          value: 9,
          text: '센터장',
        },
      ],
      // riderSettingGroup: [
      //   {
      //     key: "riderSettingGroup-1",
      //     value: 1,
      //     text: "A",
      //   },
      //   {
      //     key: "riderSettingGroup-2",
      //     value: 2,
      //     text: "B",
      //   },
      //   {
      //     key: "riderSettingGroup-3",
      //     value: 3,
      //     text: "C",
      //   },
      //   {
      //     key: "riderSettingGroup-4",
      //     value: 4,
      //     text: "D",
      //   },
      //   {
      //     key: "riderSettingGroup-5",
      //     value: 5,
      //     text: "E",
      //   },

      // ],
    };
  }

  componentDidMount() {
    this.getList();
    this.getStatusList();
    this.getRiderGroupSettingCount();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.riderReducer !== this.props.riderReducer) {
  //     this.getList();
  //     this.getStatusList();
  //   }
  // }

  componentWillUnmount() {
  }
  handleTableChange = (pagination, filters, sorter) => {

    console.log(pagination);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
        sortParam: { column: sorter.field, direction: sorter.order }
      },
      () => this.getList()
    );
  };

  getList = () => {
    // 기존 api getList
    const requestSortParam = createSortParam(this.state.sortParam);
    const sort = requestSortParam ?? null;
    let pageNum = this.state.pagination.current;
    let riderLevel = this.state.selectedRiderLevel;
    let userStatus = this.state.userStatus === 0 ? '' : this.state.userStatus;
    let searchName = this.state.searchName ? this.state.searchName : '';
    let riderStatus =
      this.state.riderStatus === 0 ? '' : this.state.riderStatus;
    const approvedCode = '';
    const allbranchStatus = '';

    httpGet(
      httpUrl.riderListWithSort,
      [
        this.state.pagination.pageSize,
        pageNum,
        searchName,
        userStatus,
        riderLevel,
        riderStatus,
        approvedCode,
        allbranchStatus,
        sort,
      ],
      {}
    ).then((result) => {
      console.log(result);
      const pagination = { ...this.state.pagination };
      pagination.current = result.data.currentPage;
      pagination.total = result.data.totalCount;
      this.setState({
        results: result.data.riders,
        pagination,
      });
    });

    // const searchName = this.state.searchName;
    // const riderLevel = this.state.selectedRiderLevel;
    // const riderSettingGroup = this.state.selectedRiderSettingGroup;
    // const userStatus =
    //   this.state.userStatus === 0 ? null : [this.state.userStatus];
    // const riderStatus =
    //   this.state.riderStatus === 0 ? null : [this.state.riderStatus];
    // const { current, pageSize } = this.state.pagination;

    // let { riders } = this.props.riderReducer;
    // // console.log("riders-1");
    // // console.log(riders);

    // // 기사명 검색
    // if (searchName) {
    //   const re = new RegExp(searchName);
    //   riders = riders.filter((rider) => {
    //     if (!rider.riderName) return false;
    //     const res = rider.riderName.match(re);
    //     if (res) {
    //       console.log(rider.riderName + " " + re);
    //     }
    //     return res === null ? false : true;
    //   });
    // }

    // console.log("searchName");
    // console.log(riders);

    // 그룹 필터링
    // if (riderSettingGroup !== [1, 2, 3, 4, 5]) {
    //   riders = riders.filter((rider) => {
    //     return riderSettingGroup.includes(rider.riderSettingGroup);
    //   });
    // }

    // 직급 필터링
    // if (riderLevel !== [1, 2, 3, 4, 5, 6, 7, 8, 9]) {
    //   riders = riders.filter((rider) => {
    //     return riderLevel.includes(rider.riderLevel);
    //   });
    // }

    // console.log("riderLevel");
    // console.log(riders);

    // userStatus 필터링
    // if (userStatus) {
    //   riders = riders.filter((rider) => {
    //     return userStatus.includes(rider.userStatus);
    //   });
    // }

    // console.log("userStatus");
    // console.log(riders);

    // if (riderStatus) {
    //   riders = riders.filter((rider) => {
    //     return riderStatus.includes(rider.riderStatus);
    //   });
    // }

    // console.log("최종");
    // console.log(riders);

    // this.setState({
    //   excelList: riders,
    // });

    // const total = riders.length;
    // riders = riders.slice(
    //   (current - 1) * pageSize,
    //   (current - 1) * pageSize + pageSize
    // );

    // this.setState({
    //   results: riders,
    //   pagination: {
    //     ...this.state.pagination,
    //     total,
    //   },
    // });
  };

  getStatusList = async () => {
    // 기존 api getStatusList
    // let pageSize = this.state.paginationStatus.pageSize;
    // let pageNum = this.state.paginationStatus.current;
    // // riderLevel = this.state.riderLevel;
    // let searchName = "";
    // let userStatus = this.state.userStatus === 0 ? "" : this.state.userStatus;
    // var data = [
    //   pageSize,
    //   pageNum,
    //   // riderLevel = this.state.riderLevel,
    //   searchName,
    //   userStatus,
    // ];
    // httpGetWithNoLoading(httpUrl.riderList, data, {})
    //   .then((res) => {
    //     if (res.result === "SUCCESS") {
    //       // console.log(res);
    //       this.setState({
    //         resultsStatus: res.data.riders,
    //         pagination: {
    //           ...this.state.pagination,
    //           total: res.data.totalCount,
    //         },
    //       });
    //     } else {
    //       console.log("riderStatus Error");
    //       return;
    //     }
    //   })
    //   .catch((e) => {
    //     console.log("riderStatus Error");
    //     console.log(e);
    //     throw e;
    //   });
    // Redux 기사정보 전부 세팅
    // const { riders } = this.props.riderReducer;
    // this.setState({
    //   resultsStatus: riders,
    // });

    console.log('getStatusList');
    try {
      const res = await httpGet(httpUrl.getRiderStatusList, [], {});
      this.setState({
        statusList: res.data,
      });
    } catch (e) {
      console.log('getStatusList error', e);
    }
  };

  getRiderGroupSettingCount = async () => {
    try {
      const res = await httpGet(httpUrl.getRiderSettingGroupCount, [], {});
      console.log('getRiderGroupSettingCount');
      console.log(res.data);
      this.setState({
        settingGroupCount: res.data,
      });
    } catch (e) {
      console.log('getStatusList error', e);
    }
  };

  getZeroRiderList = () => {
    const BEFORE_DATE = reactLocalStorage.getObject(Const.appName + '#zeroCallRiderDate');
    const NOW_DATE = moment(new Date()).format("YYYY-MM-DD");
    const NOW_HOURS = Math.floor(new Date().getHours());

    if (
      (Object.keys(BEFORE_DATE).length === 0 &&
        NOW_HOURS >= 8) ||
      (BEFORE_DATE !== null &&
        Object.keys(BEFORE_DATE).length !== 0 &&
        !BEFORE_DATE.includes(NOW_DATE) &&
        NOW_HOURS >= 8)
    ) {
      reactLocalStorage.remove(Const.appName + '#zeroCallRiderDate');

      const expiry = moment(new Date()).format('YYYY-MM-DD HH:mm');
      reactLocalStorage.setObject(Const.appName + '#zeroCallRiderDate', expiry);

      this.setState({
        openZeroRiderList: true
      }, () => {console.log('getZeroRiderList!!!!!!!!!!!!!!!!!!')});
    }
  };

  onChangeStatus = (index, value) => {
    let self = this;
    httpPost(httpUrl.updateRider, [], {
      idx: index,
      userStatus: value,
      //loginDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    })
      .then((result) => {
        Modal.info({
          title: '변경 완료',
          content: <div>상태가 변경되었습니다.</div>,
        });
        self.getList();
      })
      .catch((error) => {
        Modal.error({
          title: '변경 실패',
          content: <div>변경에 실패했습니다.</div>,
        });
      });
  };

  // onSearchRider = (value) => {
  //   this.setState(
  //     {
  //       searchName: value,
  //       pagination: {
  //         current: 1,
  //         pageSize: this.state.pagination.pageSize,
  //       },
  //     },
  //     () => {
  //       this.getList();
  //     }
  //   );
  // };

  onChange = (e) => {
    this.setState(
      {
        userStatus: e.target.value,
      },
      () => this.getList()
    );
  };

  onResetPW = (row) => {
    let self = this;
    Modal.confirm({
      title: <div> {'로그인 잠금 해제'} </div>,
      content: <div> {'로그인 잠금 해제하겠습니까?'} </div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        console.log({
          idx: row.idx,
          clearPassword: null,
        });
        httpPost(httpUrl.clearPassword, [], {
          userIdx: row.idx,
        })
          .then((result) => {
            console.log(result);
            if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
              customAlert('완료', '로그인 잠금 해제 완료되었습니다.');
            } else if (result.data === 'NOT_ADMIN') updateError();
            else updateError();
            self.getList();
          })
          .catch((error) => {
            updateError();
          });
      },
    });
  };

  onResetWithdrawPW = (row) => {
    let self = this;
    Modal.confirm({
      title: <div> {'비밀번호 초기화'} </div>,
      content: <div> {'비밀번호를 초기화하시겠습니까?'} </div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        console.log({
          idx: row.idx,
          withdrawPassword: null,
        });
        httpPost(httpUrl.clearWithdrawPassword, [], {
          userIdx: row.idx,
        })
          .then((result) => {
            console.log(result);
            if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
              customAlert('완료', '출금비밀번호가 초기화되었습니다.');
            } else if (result.data === 'NOT_ADMIN') updateError();
            else updateError();
            self.getList();
          })
          .catch((error) => {
            updateError();
          });
      },
    });
  };

  onResetSignupDeviceId = (row) => {
    let self = this;
    Modal.confirm({
      title: <div> {'디바이스 초기화'} </div>,
      content: <div> {'디바이스를 초기화하시겠습니까?'} </div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.clearSignupDeviceId, [], {
          userIdx: row.idx,
        })
          .then((result) => {
            if (result.result === 'SUCCESS' && result.data?.toUpperCase() === 'SUCCESS') {
              customAlert('완료', '디바이스가 초기화되었습니다.');
            } else updateError();
            self.getList();
          })
          .catch((error) => {
            updateError();
          });
      },
    });
  };

  handleSearch = () => {
    this.setState(
      {
        searchName: this.riderRef.current
          ? this.riderRef.current.state.value
          : '',
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  onSearchRiderDetail = (data) => {
    console.log('### get fran list data : ' + data);
    // this.setState({ results: data });
  };

  // sns dialog
  openSendSnsModal = () => {
    this.setState({ sendSnsOpen: true });
  };
  closeSendSnsModal = () => {
    this.setState({ sendSnsOpen: false });
  };
  //일차감
  openTaskSchedulerModal = () => {
    this.setState({ taskSchedulerOpen: true });
  };
  closeTaskSchedulerModal = () => {
    this.setState({ taskSchedulerOpen: false });
  };
  //일차감 내역
  openTaskWorkListModal = () => {
    this.setState({ taskWorkListOpen: true });
  };
  closeTaskWorkListModal = () => {
    this.setState({ taskWorkListOpen: false });
  };

  //기사 그룹관리
  openRiderGroupModal = () => {
    this.setState({ riderGroupOpen: true });
  };
  closeRiderGroupModal = () => {
    this.setState({ riderGroupOpen: false });
    this.getList();
  };

  //기사 등록
  openRegistRiderModal = () => {
    this.setState({ registRiderOpen: true });
  };
  closeRegistRiderModal = () => {
    this.setState({ registRiderOpen: false });
    this.getList();
    this.getStatusList();
  };

  //기사 수정
  closeUpdateRiderModal = () => {
    this.setState({ riderUpdateOpen: false, needRiderValidation: false });
    this.getList();
  };

  // 블라인드 dialog
  openBlindModal = () => {
    this.setState({ blindListOpen: true });
  };
  closeBlindModal = () => {
    this.setState({ blindListOpen: false });
  };

  //코인충전
  openRiderCoinModal = () => {
    this.setState({ riderCoinOpen: true });
  };
  closeRiderCoinModal = () => {
    this.setState({ riderCoinOpen: false });
  };

  //코인이력
  openCoinTransferModal = (row) => {
    this.setState({ coinOpen: true, dialogData: row });
  };
  closeCoinTransferModal = () => {
    this.setState({ coinOpen: false });
  };

  //입출금내역
  openRiderBankModal = () => {
    this.setState({ riderBankOpen: true });
  };
  closeRiderBankModal = () => {
    this.setState({ riderBankOpen: false });
  };

  //출금비밀번호
  openUpdatePasswordModal = (rider) => {
    this.setState({
      updatePasswordOpen: true,
      selRider: rider,
    });
  };
  closeUpdatePasswordModal = () => {
    this.setState({ updatePasswordOpen: false });
  };
  //서류제출
  closeUploadFileModal = () => {
    this.setState({ uploadFileData: null, uploadFileOpen: false });
  };
  //블라인드
  setBlackList = () => {
    alert('블라인드 처리 되었습니다.');
  };

  openLicenseApproveModal = (row) => {
    this.setState({
      licenseApproveModalOpen: true,
      dialogData: row,
    });
  };

  closeLicenseApproveModal = () => {
    this.setState({
      licenseApproveModalOpen: false,
      dialogData: null,
    });
    this.getList();
  };

  closeZeroRiderList = () => {
    this.setState(
      {
        openZeroRiderList: false,
      },
      () => this.getList()
    );
  };

  closeAccidentInsuranceList = () => {
    this.setState(
      {
        openAccidentInsuranceList: false,
      },
      () => this.getList()
    );
  };

  //기사 그룹관리
  openRiderTeamModal = () => {
    this.setState({ riderTeamOpen: true });
  };
  closeRiderTeamModal = () => {
    this.setState({ riderTeamOpen: false });
    this.getList();
  };

  // parseExcelJson = (data) => {
  //   let result = [
  //     {
  //       riderName: "기사명",
  //       id: "아이디",
  //       riderStatus: "직급",
  //       driverLicenseNumber: "면허정보",
  //       ncash: "코인잔액",
  //       createDate: "입사일",
  //       deleteDate: "퇴사일",
  //       withdrawLimit: "최소보유잔액",
  //       phone: "전화번호",
  //       memo: "메모",
  //       riderSettingGroup: "기사그룹",
  //       deliveryPriceFeeType: "수수료방식",
  //       bank: "은행명",
  //       bankAccount: "계좌번호",
  //       depositor: "예금주",
  //       userStatus: "상태",
  //     },
  //   ];
  //   data.forEach((item) => {
  //     result.push({
  //       riderName: item.riderName,
  //       id: item.id,
  //       riderStatus: riderLevelText[item.riderStatus],
  //       driverLicenseNumber: item.driverLicenseNumber,
  //       ncash: item.ncash,
  //       createDate: formatDateToDay(item.createDate),
  //       deleteDate: item.deleteDate ? formatDateToDay(item.deleteDate): "-",
  //       withdrawLimit: item.withdrawLimit,
  //       phone: item.phone,
  //       memo: item.memo,
  //       riderSettingGroup: item.riderSettingGroup.settingGroupName,
  //       deliveryPriceFeeType: item.riderSettingGroup.deliveryPriceFeeType === 1 ? "정량" : "정율",
  //       bank: item.bank.split(",")[0],
  //       bankAccount: item.bankAccount,
  //       depositor: item.riderName,
  //       userStatus: statusString[item.userStatus],
  //     });
  //   });

  //   return result;
  // };

  // onDownload = () => {
  //   // let pageNum = this.state.paginationExcel.current;
  //   // let pageSize = this.state.paginationExcel.pageSize;
  //   // let userId = this.state.searchText;
  //   // let userType = this.state.searchType;
  //   // httpGet(
  //   //   httpUrl.riderList,
  //   //   [pageNum, pageSize, userId, userType],
  //   //   {}
  //   //   ).then((res) => {
  //     const excelJson = this.parseExcelJson(this.state.excelList);
  //     const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
  //     const wb = xlsx.utils.book_new();

  //     ws["!cols"] = [];
  //     ws["!cols"][0] = { width: 15 };
  //     ws["!cols"][1] = { width: 15 };
  //     ws["!cols"][2] = { width: 10 };
  //     ws["!cols"][3] = { width: 20 };
  //     ws["!cols"][8] = { width: 15 };
  //     ws["!cols"][13] = { width: 20 };
  //     xlsx.utils.book_append_sheet(wb, ws, "sheet1");
  //     xlsx.writeFile(wb, "기사목록.xlsx");
  //   // });
  // };

  setRowClassName = (record) => {
    switch (record.riderStatus) {
      // 출근
      case 1:
        return '';
      // 휴식
      case 2:
        return 'table-lightyellow';
      // 퇴근
      case 3:
        return 'table-gray';
      default:
        return '';
    }
  };

  mapPaystoryVacnt = (rider) => {
    console.log(rider);
    Modal.confirm({
      title: '가상계좌발급',
      content: `${rider.riderName} 라이더에게 가상계좌를 발급하시겠습니까?`,
      onOk: async () => {
        const res = await httpPost(httpUrl.mapPaystoryVacnt, [rider.idx], {});

        console.log(res);

        if (res.data !== 'SUCCESS') {
          Modal.warn({
            title: "가상계좌 발급 실패",
            content: mapPaystoryVacntResultCd[res.data.result],
          });
        } else {
          Modal.info({
            title: "가상계좌 발급 성공",
            content: mapPaystoryVacntResultCd[res.data.result],
          });
        }
      },
    });
  };
  onEnableWithdraw = (idx) => {
    let self = this;
    Modal.confirm({
      title: <div> {"출금LOCK해제"} </div>,
      content: <div> {"입금내역을 반드시 확인하고 문제없는경우에만 해제해주세요. 불법적인 행위가 있는 경우 민,형사상의 책임이 발생할 수 있습니다. 해제하겠습니까?"} </div>,
      okText: "확인",
      cancelText: "취소",
      onOk() {
        console.log({
          userIdx: idx,
        });
        httpPost(httpUrl.enableWithdraw, [idx], {})
          .then((result) => {
            console.log(result);
            if (result.result === "SUCCESS" && result.data) {
              Modal.info({
                title: "해제 완료",
                content: <div>출금LOCK해제가 완료되었습니다.</div>,
              });
            } else {
              Modal.error({
                title: "해제 실패",
                content: <div>출금LOCK해제가 실패했습니다.</div>,
              });
            }
            self.getList();
          })
          .catch((error) => {
            updateError();
          });
      },
    });
  }
  onDormantRemovalID = (idx) => {
    let self = this;
    Modal.confirm({
      title: <div> {'계정휴면해제'} </div>,
      content: <div> {'계정 휴면 해제하겠습니까?'} </div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        console.log({
          userIdx: idx,
        });
        httpPost(httpUrl.updateLoginDate, [], {
          userIdx: idx,
        })
          .then((result) => {
            console.log(result);
            if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
              Modal.info({
                title: '변경 완료',
                content: <div>계정휴면해제가 완료되었습니다.</div>,
              });
            } else {
              Modal.error({
                title: '변경 실패',
                content: <div>계정휴면해제가 실패했습니다.</div>,
              });
            }
            self.getList();
          })
          .catch((error) => {
            updateError();
          });
      },
    });
  };

  approveRider = async (row) => {
    try {
      // #4280 일감에서 서류상태 상관없이 승인되도록 변경

      // const paperListRes = await httpGet(
      //   httpUrl.getRiderPaperList,
      //   [row.idx],
      //   {}
      // );
      // const paperList = paperListRes.data.paperList;
      // const neededPaperList = rentOwnedPaperCodeList[row.bikeRentOwnedCode];
      // console.log(paperList);
      //
      // let notApprovedPaperCodeList = [];
      // neededPaperList.forEach((p) => {
      //   const targetPaper = paperList.find((paper) => paper.paperNo === p);
      //   if (
      //     targetPaper &&
      //     targetPaper.status === riderPaperStatusCode.NO_SUBMIT
      //   ) {
      //     notApprovedPaperCodeList.push(p);
      //   }
      // });
      //
      // if (notApprovedPaperCodeList.length > 0) {
      //   let warningText = '';
      //   notApprovedPaperCodeList.forEach((code) => {
      //     warningText += ` ${riderPaperNoCodeToText[code]} /`;
      //   });
      //   Modal.warn({
      //     title: '승인실패',
      //     content: `${warningText} 서류를 제출해 주세요`,
      //   });
      //   return;
      // }

      const res = await httpPost(httpUrl.approveRiderPaperWithoutPaper, [], {
        userIdx: row.idx,
        approveCode: riderApprovedCode.APPROVED,
      });

      if (res.data === 'SUCCESS') {
        Modal.info({
          title: '승인성공',
          content: '라이더가 승인되었습니다.',
        });
        this.getList();
      }
    } catch (e) {
      console.error(e);
      Modal.warn({
        title: '승인실패',
        content: '서버오류로 인한 승인실패입니다. 잠시 후 다시 시도해주세요.',
      });
    }
  };

  validateRider = (row) => {
    this.setState({
      riderUpdateOpen: true,
      dialogData: row,
      needRiderValidation: true,
    })
  }

  render() {
    const mobileColumns = [
      {
        title: '기사명',
        dataIndex: 'riderName',
        className: 'table-column-center mobile',
        render: (data, row) => (
          <div className="status-box">
            <p>
              {row.riderName}({row.id}) {riderLevelText[row.riderLevel]}(
              {row.riderSettingGroup.settingGroupName})
            </p>
            {row.phone} / {riderStatusCode[row.riderStatus]} <br />
            {formatDateToDay(row.createDate)} /{' '}
            {formatDateToDay(row.deleteDate)} <br />
            <hr className="light-hr" />
            코인잔액:{comma(row.ncash - (row.misu ?? 0) - (row.misu2 ?? 0)- (row.misu3 ?? 0))} <br />
            최소보유잔액 : {row.withdrawLimit}
            <br />
            수수료 :{' '}
            {row.riderSettingGroup.deliveryPriceFeeType === 1
              ? row.riderSettingGroup.deliveryPriceFeeAmount + '원'
              : row.riderSettingGroup.deliveryPriceFeeAmount + '%'}
            (
            {row.riderSettingGroup.deliveryPriceFeeType === 1 ? '정량' : '정율'}
            )<br />
            계좌번호 : {row.bank.split(',')[0]} / {row.bankAccount} /{' '}
            {row.riderName}
            <hr className="light-hr" />
            <div className="table-column-sub">
              상태 :{' '}
              <SelectBox
                value={statusString[row.userStatus]}
                code={Object.keys(statusString)}
                codeString={statusString}
                onChange={(value) => {
                  if (parseInt(value) !== row.userStatus) {
                    this.onChangeStatus(row.idx, value);
                  }
                }}
              />
            </div>
            <div className="table-column-sub">
              <Button
                className="tabBtn"
                style={{ marginLeft: 10 }}
                onClick={() =>
                  this.setState({ blindRiderData: row, blindListOpen: true })
                }>
                블라인드
              </Button>
            </div>
          </div>
        ),
      },
    ];
    const columns = [
      {
        title: '기사명',
        dataIndex: 'riderName',
        className: 'table-column-center desk tableSub',
        sorter: true,
      },
      {
        title: '아이디',
        dataIndex: 'id',
        className: 'table-column-center desk tableSub',
        sorter: true,
      },
      {
        title: '직급',
        dataIndex: 'riderLevel',
        className: 'table-column-center desk tableSub',
        sorter: true,
        render: (data) => <div className="c-p-20">{riderLevelText[data]}</div>,
      },
      {
        title: '실명확인여부',
        dataIndex: 'riderNameValid',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div>
            {data === true ? (
              '인증완료'
            ) : (
              <Button onClick={() => this.validateRider(row)}>인증</Button>
            )}
          </div>
        ),
      },
      {
        title: '비밀번호',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div className="c-p-20">
            <Button className="tabBtn" onClick={() => this.onResetPW(row)}>
              잠금해제
            </Button>
          </div>
        ),
      },
      {
        title: '디바이스',
        dataIndex: 'signupDeviceId',
        className: 'table-column-center desk',
        render: (data, row) => (
            <div className="c-p-20">
              <Button
                  className="tabBtn"
                  onClick={() => this.onResetSignupDeviceId(row)}
                  disabled={!data}>
                초기화
              </Button>
            </div>
        ),
      },
      {
        title: '라이더승인',
        dataIndex: 'approvedCode',
        className: 'table-column-center desk',
        render: (data, row) => {
          return riderApprovedCode.APPROVED == data ? (
            <div>승인완료</div>
          ) : (
            <div>
              <Button onClick={() => this.approveRider(row)}>승인</Button>
            </div>
          );
        },
      },
      {
        title: '서류제출',
        dataIndex: 'isAllPaperUploaded',
        className: 'table-column-center desk',
        render: (data) => (
          <div style={{ color: data ? 'black' : 'red' }}>
            {data ? '완료' : '미완'}
          </div>
        ),
      },
      {
        title: '출금비밀번호',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div className="c-p-20">
            <Button
              className="tabBtn"
              onClick={() => this.onResetWithdrawPW(row)}>
              초기화
            </Button>
          </div>
        ),
      },
      {
        title: '블라인드',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn"
              onClick={() =>
                this.setState({ blindRiderData: row, blindListOpen: true })
              }>
              블라인드
            </Button>
          </div>
        ),
      },
      {
        title: '코인잔액',
        dataIndex: 'ncash',
        className: 'table-column-center desk',
        sorter: true,
        render: (data, row) => (
          <div
            className="c-p-25"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              this.openCoinTransferModal(row);
            }}>
            {comma(data - (row.misu ?? 0) - (row.misu2 ?? 0)- (row.misu3 ?? 0))}
          </div>
        ),
      },
      {
        title: '입사일',
        dataIndex: 'createDate',
        className: 'table-column-center desk',
        sorter: true,
        render: (data) => <div className="c-p-30">{formatDateToDay(data)}</div>,
      },
      {
        title: '퇴사일',
        dataIndex: 'deleteDate',
        className: 'table-column-center desk',
        sorter: true,
        render: (data) => <div className="c-p-30">{formatDateToDay(data)}</div>,
      },

      {
        title: '최소보유잔액',
        dataIndex: 'withdrawLimit',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-20">{data}</div>,
      },
      {
        title: () => (
          <div>
            잔액
            <br />
            (최소보유잔액)
          </div>
        ),
        dataIndex: ['withdrawLimit', 'ncash'],
        className: 'table-column-center mobile',
        render: (data, row) => (
          <div className="c-p-20">
            {comma(row.withdrawLimit)} <br />({row.withdrawLimit})
          </div>
        ),
      },
      {
        title: '전화번호',
        dataIndex: 'phone',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-20">{data}</div>,
      },
      {
        title: '메모',
        dataIndex: 'memo',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-20">{data}</div>,
      },
      {
        title: '기사그룹',
        dataIndex: 'riderSettingGroup',
        className: 'table-column-center desk',
        sorter: true,
        render: (data) => <div className="c-p-30">{data.settingGroupName}</div>,
      },

      {
        title: '수수료방식',
        dataIndex: 'riderSettingGroup',
        className: 'table-column-center desk',
        render: (data) => (
          <div className="c-p-20">
            {data.deliveryPriceFeeType === 1 ? '정량' : '정율'}
          </div>
        ),
      },
      {
        title: '수수료',
        dataIndex: 'riderSettingGroup',
        className: 'table-column-center desk',
        render: (data) => (
          <div className="c-p-20">
            {data.deliveryPriceFeeType === 1
              ? data.deliveryPriceFeeAmount + '원'
              : data.deliveryPriceFeeAmount + '%'}
          </div>
        ),
      },
      {
        title: '은행명',
        dataIndex: 'bank',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-20">{data.split(',')[0]}</div>,
      },
      {
        title: '계좌번호',
        dataIndex: 'bankAccount',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-20">{data}</div>,
      },
      {
        title: '계좌번호',
        dataIndex: ['bank', 'bankAccount'],
        className: 'table-column-center mobile',
        render: (data, row) => (
          <div className="c-p-20">
            {row.bank.split(',')[0]} <br /> {row.bankAccount} <br />{' '}
            {row.riderName}
          </div>
        ),
      },
      {
        title: '예금주',
        dataIndex: 'riderName',
        className: 'table-column-center desk',
        render: (data) => <div className="c-p-20">{data}</div>,
      },
      this.props.branchInfo.dwServiceType === dwServiceTypeCode.KWANGWON
        ? {
            title: '출금등록',
            dataIndex: 'walletId',
            className: 'table-column-center desk',
            render: (data, row) => (
              <div>
                {data ? (
                  '완료'
                ) : (
                  <Button
                    onClick={() => {
                      httpPost(httpUrl.createUserWallet, [row.idx], {})
                        .then((response) => {
                          console.log(response);
                          if (response.data.resultCd == '0000') {
                            this.getList();
                          } else {
                            alert(response.data.advanceMsg);
                          }
                        })
                        .catch((e) => {});
                    }}>
                    등록하기
                  </Button>
                )}
              </div>
            ),
          }
        : {
            title: '가상계좌',
            dataIndex: '',
            className: 'table-column-center desk',
            render: (data, row) => (
              <div>
                {(row.vaccountBank && row.vaccountNumber) ? (
                  `${row.vaccountBank} / ${row.vaccountNumber}`
                ) : (
                  <Button onClick={() => this.mapPaystoryVacnt(row)}>
                    등록하기
                  </Button>
                )}
              </div>
            ),
          },

      {
        title: '상태',
        dataIndex: 'userStatus',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div>
            <SelectBox
              value={statusString[data]}
              code={Object.keys(statusString)}
              codeString={statusString}
              onChange={(value) => {
                if (parseInt(value) !== row.userStatus) {
                  this.onChangeStatus(row.idx, value);
                }
              }}
            />
          </div>
        ),
      },
      {
        title: '수정',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn surchargeTab"
              onClick={() =>
                this.setState(
                  { riderUpdateOpen: true, dialogData: row },
                  () => {
                    console.log(row);
                  }
                )
              }>
              수정하기
            </Button>
          </div>
        ),
      },
      {
        title: '서류제출',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn surchargeTab"
              onClick={() =>
                this.setState(
                  { uploadFileOpen: true, uploadFileData: row },
                  () => {
                    console.log(row);
                  }
                )
              }>
              서류제출
            </Button>
          </div>
        ),
      },
      //계정휴면해제 버튼 추가 220905 hms
      {
        title: '계정휴면해제',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div className="c-p-20">
            <Button
              className="tabBtn"
              onClick={() => this.onDormantRemovalID(row.idx)}>
              계정휴면해제
            </Button>
          </div>
        ),
      },
      // {
      //   title: "출금LOCK해제",
      //   className: "table-column-center desk",
      //   render: (data, row) => (
      //     <div className="c-p-20">
      //       {!row.withdrawEnabled && (
      //         <Button
      //           className="tabBtn"
      //           onClick={() => this.onEnableWithdraw(row.idx)}
      //         >
      //           해제
      //         </Button>
      //       )}
      //     </div>
      //   ),
      // },
      {
        title: '출근상태',
        dataIndex: 'riderStatus',
        className: 'table-column-center desk',
        sorter: true,
        render: (data) => <div className="c-p-30">{riderStatusCode[data]}</div>,
      },
      {
        title: '상태변경일자',
        dataIndex: 'statusChangeDate',
        className: 'table-column-center desk',
      },
    ];

    return (
      <div>
        <div className="mobile">
          <SelectBox
            className="selectRiderBox"
            style={{ marginTop: 15 }}
            value={tableStatusString[this.state.userStatus]}
            code={Object.keys(tableStatusString)}
            codeString={tableStatusString}
            onChange={(value) => {
              if (parseInt(value) !== this.state.userStatus) {
                this.setState(
                  {
                    userStatus: parseInt(value),
                    pagination: {
                      current: 1,
                      pageSize: this.state.pagination.pageSize,
                    },
                  },
                  () => this.getList()
                );
              }
            }}
          />
          <Search
            placeholder="기사검색"
            className="searchRiderInput"
            enterButton
            allowClear
            onSearch={this.onSearchRider}
            style={{ marginTop: 15, marginLeft: 15 }}
          />
        </div>
        <div className="riderMain-container">
          <Affix offsetTop={70}>
            <div className="selectLayout desk">
              <div className="selectLayout-box">
                <div className="searchRequirementText">검색조건</div>
                <SelectBox
                  value={tableStatusString[this.state.userStatus]}
                  code={Object.keys(tableStatusString)}
                  codeString={tableStatusString}
                  onChange={(value) => {
                    if (parseInt(value) !== this.state.userStatus) {
                      this.setState(
                        {
                          userStatus: parseInt(value),
                          pagination: {
                            current: 1,
                            pageSize: this.state.pagination.pageSize,
                          },
                        },
                        () => this.getList()
                      );
                    }
                  }}
                />

                {/* <Button
                  className="rider-level-btn"
                  style={{
                    marginLeft: "10px",
                    background: this.state.riderLevelOpen && "#fddc00",
                    border: this.state.riderLevelOpen && "#fddc00",
                  }}
                  onClick={() =>
                    this.setState({
                      riderLevelOpen: !this.state.riderLevelOpen,
                    })
                  }
                >
                  직급
                </Button> */}
                <Form
                  onFinish={() => {
                    this.handleSearch();
                  }}>
                  <Input
                    ref={this.riderRef}
                    placeholder="기사명검색"
                    allowClear
                    style={{
                      width: 160,
                      marginLeft: 10,
                    }}
                  />
                  <Button type="primary" htmlType="submit">
                    조회
                  </Button>
                </Form>
                {this.state.registRiderOpen && (
                  <RegistRiderDialog close={this.closeRegistRiderModal} />
                )}
                <Button
                  className="riderManageBtn"
                  onClick={this.openRegistRiderModal}>
                  기사 등록
                </Button>

                {this.state.riderGroupOpen && (
                  <RiderGroupDialog close={this.closeRiderGroupModal} />
                )}
                <Button
                  className="riderManageBtn"
                  onClick={this.openRiderGroupModal}>
                  기사 그룹 관리
                </Button>
                {this.state.taskSchedulerOpen && (
                  <BatchWorkListDialog close={this.closeTaskSchedulerModal} />
                )}
                <Button
                  className="riderManageBtn"
                  onClick={this.openTaskSchedulerModal}>
                  일/월차감
                </Button>
                {this.state.taskWorkListOpen && (
                  <BatchWorkedListDialog close={this.closeTaskWorkListModal} />
                )}
                <Button
                  className="riderManageBtn"
                  onClick={this.openTaskWorkListModal}>
                  일/월차감 내역
                </Button>
                {this.state.riderTeamOpen && (
                  <RiderTeamDialog close={this.closeRiderTeamModal} />
                )}
                {this.props.branchInfo.companyId === 'connect9' && (
                <Button
                  className="riderManageBtn"
                  onClick={this.openRiderTeamModal}>
                  팀 관리
                </Button>)}
                <Button
                  className="riderManageBtn"
                  onClick={() => this.setState({
                    openZeroRiderList: true
                  })}
                >
                  0콜 라이더 확인
                </Button>
                <Button
                  className="riderManageBtn"
                  style={{background: "#34ebc0", color: "white"}}
                  onClick={() => {
                    this.setState({
                      openAccidentInsuranceList: true
                    });
                  }}
                >
                  상해보험 명단
                </Button>

                {/* <Button
                  className="riderManageBtn excel"
                  onClick={this.onDownload}
                >
                  <img
                    src={ExcelIcon}
                    alt=""
                  />
                  엑셀 다운로드
                </Button> */}
              </div>
              <div className="desk">
                <div className="riderStatus">
                  <Button
                    className="rider-level-btn"
                    style={{
                      marginLeft: '10px',
                      background: this.state.riderLevelOpen && '#fddc00',
                      border: this.state.riderLevelOpen && '#fddc00',
                    }}
                    onClick={() =>
                      this.setState({
                        riderLevelOpen: !this.state.riderLevelOpen,
                      })
                    }>
                    직급
                  </Button>
                  <div className="riderGroup">
                    {this.state.settingGroupCount.map((rsgc, index) =>
                      index === 0 ? (
                        <div>
                          {rsgc.settingGroupName} : {rsgc.groupCount}
                        </div>
                      ) : (
                        <>
                          <span className="riderGroup-span">/</span>
                          <div>
                            {rsgc.settingGroupName} : {rsgc.groupCount}
                          </div>
                        </>
                      )
                    )}
                    {/* <div>
                      A :{" "}
                      {
                        this.state.resultsStatus.filter(
                          (item) =>
                            item.riderSettingGroup.settingGroupName.indexOf(
                              "A"
                            ) >= 0 &&
                            item.userStatus === 1 &&
                            item.branchIdx === this.props.branchIdx
                        ).length
                      }{" "}
                      명
                    </div>
                    <span className="riderGroup-span">/</span>
                    <div>
                      B :{" "}
                      {
                        this.state.resultsStatus.filter(
                          (item) =>
                            item.riderSettingGroup.settingGroupName.indexOf(
                              "B"
                            ) >= 0 &&
                            item.userStatus === 1 &&
                            item.branchIdx === this.props.branchIdx
                        ).length
                      }{" "}
                      명
                    </div>
                    <span className="riderGroup-span">/</span>
                    <div>
                      C :{" "}
                      {
                        this.state.resultsStatus.filter(
                          (item) =>
                            item.riderSettingGroup.settingGroupName.indexOf(
                              "C"
                            ) >= 0 &&
                            item.userStatus === 1 &&
                            item.branchIdx === this.props.branchIdx
                        ).length
                      }{" "}
                      명
                    </div>
                    <span className="riderGroup-span">/</span>
                    <div>
                      D :{" "}
                      {
                        this.state.resultsStatus.filter(
                          (item) =>
                            item.riderSettingGroup.settingGroupName.indexOf(
                              "D"
                            ) >= 0 &&
                            item.userStatus === 1 &&
                            item.branchIdx === this.props.branchIdx
                        ).length
                      }{" "}
                      명
                    </div>
                    <span className="riderGroup-span">/</span>
                    <div>
                      E :{" "}
                      {
                        this.state.resultsStatus.filter(
                          (item) =>
                            item.riderSettingGroup.settingGroupName.indexOf(
                              "E"
                            ) >= 0 &&
                            item.userStatus === 1 &&
                            item.branchIdx === this.props.branchIdx
                        ).length
                      }{" "}
                      명
                    </div> */}
                  </div>
                  <div className="riderGroup">
                    <SelectBox
                      // style={{ marginLeft: 25 }}
                      value={riderStatusCode[this.state.riderStatus]}
                      code={Object.keys(riderStatusCode)}
                      codeString={riderStatusCode}
                      onChange={(value) => {
                        if (parseInt(value) !== this.state.riderStatus) {
                          this.setState(
                            {
                              riderStatus: parseInt(value),
                              pagination: {
                                current: 1,
                                pageSize: this.state.pagination.pageSize,
                              },
                            },
                            () => this.getList()
                          );
                        }
                      }}
                    />
                    <div className="rider-status">
                      근무 :{' '}
                      {this.state.statusList.find(
                        (riderStatusCount) => riderStatusCount.riderStatus === 1
                      )
                        ? this.state.statusList.find(
                            (riderStatusCount) =>
                              riderStatusCount.riderStatus === 1
                          ).count
                        : 0}
                      명
                    </div>
                    <div className="rider-status">
                      휴식 :{' '}
                      {this.state.statusList.find(
                        (riderStatusCount) => riderStatusCount.riderStatus === 2
                      )
                        ? this.state.statusList.find(
                            (riderStatusCount) =>
                              riderStatusCount.riderStatus === 2
                          ).count
                        : 0}
                      명
                    </div>
                    <div className="rider-status">
                      퇴근 :{' '}
                      {this.state.statusList.find(
                        (riderStatusCount) => riderStatusCount.riderStatus === 3
                      )
                        ? this.state.statusList.find(
                            (riderStatusCount) =>
                              riderStatusCount.riderStatus === 3
                          ).count
                        : 0}
                      명
                    </div>
                  </div>
                </div>
                <div
                  className="rider-filtering-box"
                  style={{ display: !this.state.riderLevelOpen && 'none' }}>
                  {this.state.riderLevelOpen &&
                    this.state.riderLevel.map((o) => {
                      return (
                        <div>
                          <Checkbox
                            style={{ marginLeft: '8px' }}
                            key={o.key}
                            value={o.value}
                            onChange={(e) => {
                              if (e.target.checked) {
                                const selectRiderLevel =
                                  this.state.selectedRiderLevel.concat(
                                    e.target.value
                                  );
                                this.setState({
                                  selectedRiderLevel: selectRiderLevel,
                                });
                              } else {
                                const selectRiderLevel =
                                  this.state.selectedRiderLevel.filter(
                                    (el) => el !== e.target.value
                                  );
                                this.setState({
                                  selectedRiderLevel: selectRiderLevel,
                                });
                              }
                            }}
                            defaultChecked={
                              this.state.selectedRiderLevel.includes(o.value)
                                ? 'checked'
                                : ''
                            }>
                            {o.text}
                          </Checkbox>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </Affix>
          {this.state.blindListOpen && (
            <BlindRiderListDialog
              close={this.closeBlindModal}
              data={this.state.blindRiderData}
            />
          )}
          <div id="rider-table" className="dataTableLayout desk">
            <VirtualTable
              rowClassName={(record) => this.setRowClassName(record)}
              rowKey={(record) => record.idx}
              dataSource={this.state.results}
              columns={columns}
              pagination={this.state.pagination}
              onChange={this.handleTableChange}
              scroll={{ x: 3000, y: 600 }}
              style={{ padding: 10 }}
              onRow={(record) => {
                return {
                  onDoubleClick: () => {
                    this.setState({
                      riderUpdateOpen: true,
                      dialogData: record,
                    });
                  },
                };
              }}
            />
            {/* <Table
              rowKey={(record) => record.idx}
              dataSource={this.state.results}
              columns={columns}
              pagination={this.state.pagination}
              onChange={this.handleTableChange}
              // expandedRowRender={expandedRowRender}
              // scroll={{ y: "50vh" }}
              scroll={{ x: true }}
              sticky={{ offsetHeader: 60 }}
            /> */}
          </div>
          <div id="rider-table" className="dataTableLayout mobile">
            <Table
              rowKey={(record) => record.idx}
              dataSource={this.state.results}
              columns={mobileColumns}
              pagination={this.state.pagination}
              onChange={this.handleTableChange}
            />
          </div>

          {this.state.licenseApproveModalOpen && (
            <LicenseApproveDialog
              close={this.closeLicenseApproveModal}
              data={this.state.dialogData}
            />
          )}
          {this.state.riderUpdateOpen && (
            <RegistRiderDialog
              close={this.closeUpdateRiderModal}
              data={this.state.dialogData}
              needRiderValidation={this.state.needRiderValidation}
            />
          )}
          {this.state.coinOpen && (
            <CoinTransferDialog
              close={this.closeCoinTransferModal}
              data={this.state.dialogData}
            />
          )}
          {this.state.updatePasswordOpen && (
            <UpdatePasswordDialog
              rider={this.state.selRider}
              close={this.closeUpdatePasswordModal}
            />
          )}
          {this.state.uploadFileOpen && (
            <RiderUploadFileDialog
              close={this.closeUploadFileModal}
              data={this.state.uploadFileData}
            />
          )}
          {this.state.openZeroRiderList && (
            <ZeroCallRiderDialog close={this.closeZeroRiderList} />
          )}
          {this.state.openAccidentInsuranceList && (
            <RiderAccidentInsurance
              branchInfo={this.props.branchInfo}
              close={this.closeAccidentInsuranceList}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  riderReducer: state.riderReducer,
  branchIdx: state.login.loginInfo.branchIdx,
  branchInfo: state.branchReducer.branchInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setRider: (riderList) => dispatch(setRider(riderList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RiderMain);
