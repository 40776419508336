import React, { useState } from 'react';
import { Button, DatePicker, Form, Layout, Menu, Modal, Select, Table } from 'antd';
import { comma } from '../../lib/util/numberUtil';
import { adminSendKindString, agencyBatchWorkKind, categoryStatus, kindString } from '../../lib/util/codeUtil';
import moment from 'moment';
import DepositDialog from '../../components/dialog/DepositDialog';
import NcashCompanyList from './NcashCompanyList';
import MisuList from './MisuList';
import WithdrawList from './WithdrawList';
import WithdrawAgencyNcash from './WithdrawAgencyNcash';
import NcashList from './NcashList';
import NcashDailyList from './NcashDailyList';
import NcashListLayout from './NcashListLayout';
import WithdrawListLayout from './WithdrawListLayout';
import BatchworkListLayout from './BatchworkListLayout';
import SettlementList from './SettlementList';
import { useSelector } from 'react-redux';
import NcashListLayoutForSettlement from './NcashListLayoutForSettlement';
import AgencyTaxReturn from './AgencyTaxReturn';

const NcashLayout = () => {
  const loginInfo = useSelector((state) => state.login.loginInfo);
  const [pageContent, setPageContent] = useState(loginInfo.companyId === 'foodbank' ? 1 : 0);
  const pageContentCode = [
    {
      value: 0,
      menu: '정산내역',
    },
    {
      value: 1,
      menu: '내역조회',
    },
    {
      value: 2,
      menu: '입/출금',
    },
    {
      value: 3,
      menu: '일차감',
    },
    // {
    //   value: 4,
    //   menu: '세금신고',
    // },
  ];
  return (
    <>
      <Layout.Header>
        {pageContentCode.map((code) => {
          if (loginInfo.companyId === 'foodbank' && code.value === 0) return <></>;
          return (
            <div
              key={code.value}
              onClick={() => setPageContent(code.value)}
              className={'top-menu ' + (pageContent === code.value ? 'active' : '')}>
              {code.menu}
            </div>
          );
        })}
      </Layout.Header>

      <div style={{ padding: 20 }}>
        {pageContent === 0 && <SettlementList />}
        {pageContent === 1 && <NcashListLayout />}
        {/*{pageContent === 1 && <NcashListLayoutForSettlement />}*/}

        {pageContent === 2 && <WithdrawListLayout />}
        {pageContent === 3 && <BatchworkListLayout />}
        {/*{pageContent === 4 && <AgencyTaxReturn />}*/}
      </div>
    </>
  );
};

export default NcashLayout;
