import { Button, Form, Input, Modal, Popover, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { httpExec, httpGet, httpUrl, makeUrl } from '../../../api/httpClient';
import {
  frOrderAgencyCode,
  frOrderAgencyPosList
} from '../../../lib/util/codeUtil';
import SearchFtStoreDialog from './SearchFtStoreDialog';
import SearchBydStoreDialog from './SearchBydStoreDialog';
import SearchBydBtStoreDialog from './SearchBydBtStoreDialog';

const FrOrderAgencyCard = ({
  frOrderAgency,
  setFrOrderAgency,
  deleteFrOrderAgency,
  getFrOrderAgency,
  data,
}) => {
  const exceptionPosCode = [
    'ftCode',
    'deCode',
    'deCode',
    'dgCode',
    'bpCode',
    'psCode',
  ];

  const [posCode, setPosCode] = useState(frOrderAgency.posCode);
  const [posStoreCode, setPosStoreCode] = useState(
    exceptionPosCode.includes(frOrderAgency.posCode)
      ? frOrderAgency[frOrderAgency.posCode]
      : frOrderAgency.posStoreCode
  );
  const [frOrderAgencyCodeSelected, setFrOrderAgencyCodeSelected] =
    useState(true);
  const [searchFtStoreOpen, setSearchFtStoreOpen] = useState(false);
  const [searchBydStoreOpen, setSearchBydStoreOpen] = useState(false);
  const [searchBydBtStoreOpen, setSearchBydBtStoreOpen] = useState(false);
  const [multiList, setMultiList] = useState([]);

  useEffect(() => {
    checkFrOrderAgency();
  });
  useEffect(() => {
    console.log('posCode, posStoreCode useEffect');
    console.log(posCode, posStoreCode);
    let newFrOrderAgency = {
      userIdx: data.idx,
      nnCode: frOrderAgency.nnCode,
      ftCode: null,
      deCode: null,
      dgCode: null,
      bpCode: null,
      psCode: null,
      posCode: null,
      posStoreCode: null,
      createDate: frOrderAgency.createDate,
    };
    if (exceptionPosCode.includes(posCode)) {
      newFrOrderAgency.posCode = posCode;
      newFrOrderAgency[posCode] = posStoreCode ? posStoreCode : '';
      newFrOrderAgency.posStoreCode = posStoreCode;
    } else {
      newFrOrderAgency.posCode = posCode;
      newFrOrderAgency.posStoreCode = posStoreCode;
    }
    setFrOrderAgency(newFrOrderAgency);
  }, [posCode, posStoreCode]);

  const checkFrOrderAgency = () => {
    if (!frOrderAgency.createDate) {
      setFrOrderAgencyCodeSelected(false);
      return;
    }
    const codeValues = Object.values(frOrderAgencyCode);
    if (frOrderAgency.posCode) {
      setFrOrderAgencyCodeSelected(true);
      setPosCode(frOrderAgency.posCode);
      // setPosStoreCode(frOrderAgency.posStoreCode);
    } else {
      setFrOrderAgencyCodeSelected(false);
    }

    for (let i = 0; i < 4; i++) {
      if (frOrderAgency[codeValues[i]] !== null) {
        setFrOrderAgencyCodeSelected(true);
        setPosCode(codeValues[i]);
        setPosStoreCode(
          frOrderAgency[codeValues[i]] === '1'
            ? ''
            : frOrderAgency[codeValues[i]]
        );
        return;
      }
    }
    setFrOrderAgencyCodeSelected(false);
  };

  const checkFrOrderAgencyCreateDate = () => {
    const now = new moment().startOf('day');
    const frOrderAgencyCreateDate = moment(frOrderAgency.createDate).startOf(
      'day'
    );

    return moment.duration(now.diff(frOrderAgencyCreateDate)).asDays() <= 3
      ? true
      : false;
  };

  const onChangeFrOrderAgency = (value) => {
    if (value === '' && checkFrOrderAgencyCreateDate()) {
      Modal.confirm({
        title: '연동해제',
        content: '연동해제 하시겠습니까?',
        onOk: async () => {
          const res = await httpGet(
            httpUrl.deleteFrOrderAgency,
            [frOrderAgency.userIdx],
            {}
          );
          if (res.data) getFrOrderAgency();
          else
            Modal.warn({
              title: '해제 실패',
              content: '연동 해제에 실패했습니다.',
            });
        },
      });
      return;
    }

    if (!frOrderAgencyCodeSelected) {
      setPosCode(value);
      return;
    } else {
      Modal.info({
        title: '연동 설정 오류',
        content: '연동 완료된 이후에는 변경이 불가합니다.',
      });
      return;
    }
  };

  const handleFtMapping = () => {
    if (frOrderAgencyCodeSelected) return;
    if (!frOrderAgencyCodeSelected) {
      if (posCode !== '') {
        var foa = {
          userIdx: null,
          nncode: null,
          ftCode: null,
          deCode: null,
          dgCode: null,
          bpCode: null,
          psCode: null,
        };

        foa[posCode] = '';
      }
    }
    Modal.confirm({
      title: '푸드테크 연동',
      content: '푸드테크 연동은 즉시 연동됩니다. 연동하시겠습니까?',
      onOk: async () => {
        const res = await httpExec('POST', makeUrl(httpUrl.mapFrToFt, []), {
          idx: data.idx,
          businessNumber: data.businessNumber,
          addr1: data.addr1,
          addr2: data.addr2,
          latitude: data.latitude,
          longitude: data.longitude,
          branchIdx: data.branchIdx,
          phone: data.phone,
          frOrderAgency: !frOrderAgencyCodeSelected ? [foa] : null,
        });
        // .then((res) => {
        console.log(res);
        if (res.data.result === 'SUCCESS') {
          Modal.info({
            title: '연동성공',
            content: '푸드테크 연동에 성공했습니다.',
          });
          setFrOrderAgencyCodeSelected(true);
          setPosStoreCode(res.data.deliveryStoreCode);
          setPosCode('ftCode');
        } else if (res.data.result === 'NO_MATCHED_STORE') {
          Modal.info({
            title: '연동실패',
            content:
              '해당 사업자 번호에 맞는 푸드테크 연동매장이 존재하지 않습니다.',
          });
          setPosCode('');
        } else if (res.data.result === 'MULTIPLE_MATCHED_STORE') {
          Modal.confirm({
            title: '연동실패',
            content:
              '해당 사업자 번호에 맞는 푸드테크 연동매장이 중복됩니다.\n 해당하는 매장을 조회하시겠습니까?',
            onOk: () => {
              setMultiList(res.data.multiList);
              setSearchFtStoreOpen(true);
            },
          });
        } else if (res.data.result === 'INVALID_AUTHORIZATION') {
          Modal.info({
            title: '연동실패',
            content: '인증정보가 올바르지 않습니다.',
          });
          setPosCode('');
        } else if (res.data.result === 'CREATE_FR_ORDER_AGENCY_ERROR') {
          Modal.info({
            title: '연동실패',
            content: '서버에러. 관리자에게 문의하세요.',
          });
          setPosCode('');
        } else if (res.data.result === 'FR_UPDATE_ERROR') {
          Modal.info({
            title: '연동실패',
            content: '연동 후 사업자번호 변경처리에 실패했습니다.',
          });
          setPosCode('');
        } else {
          Modal.info({
            title: '연동실패',
            content: '연동 호출 시 에러 발생. 관리자에게 문의하세요.',
          });
          setPosCode('');
        }
        // })
        // .catch((e) => console.error(e));
      },
    });
  };

  const mapSelectedFtStore = (store) => {
    if (!frOrderAgencyCodeSelected) {
      if (posCode !== '') {
        var foa = {
          userIdx: null,
          nncode: null,
          ftCode: null,
          deCode: null,
          dgCode: null,
          bpCode: null,
          psCode: null,
        };

        foa[posCode] = '';
      }
    }
    httpExec(
      'POST',
      makeUrl(httpUrl.mapFrToFtBySelectedStore, []),
      {
        idx: data.idx,
        businessNumber: data.businessNumber,
        addr1: data.addr1,
        addr2: data.addr2,
        latitude: data.latitude,
        longitude: data.longitude,
        branchIdx: data.branchIdx,
        phone: data.phone,
        frOrderAgency: !frOrderAgencyCodeSelected ? [foa] : null,
        ftStore: store,
      },
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    )
      .then((res) => {
        // console.log(res);
        if (res.data.result === 'SUCCESS') {
          Modal.info({
            title: '연동성공',
            content: '푸드테크 연동에 성공했습니다.',
          });
          console.log(res.data);
          setFrOrderAgencyCodeSelected(true);
          setPosStoreCode(res.data.deliveryStoreCode);
          setPosCode('ftCode');
        } else if (res.data.result === 'NO_MATCHED_STORE') {
          Modal.info({
            title: '연동실패',
            content:
              '해당 사업자 번호에 맞는 푸드테크 연동매장이 존재하지 않습니다.',
          });
          setPosCode('');
        } else if (res.data.result === 'MULTIPLE_MATCHED_STORE') {
          Modal.confirm({
            title: '연동실패',
            content:
              '해당 사업자 번호에 맞는 푸드테크 연동매장이 중복됩니다.\n 해당하는 매장을 조회하시겠습니까?',
            onOk: () => {
              setMultiList(res.data.multiList);
              setSearchFtStoreOpen(true);
            },
          });
        } else if (res.data.result === 'INVALID_AUTHORIZATION') {
          Modal.info({
            title: '연동실패',
            content: '인증정보가 올바르지 않습니다.',
          });
          setPosCode('');
        } else if (res.data.result === 'CREATE_FR_ORDER_AGENCY_ERROR') {
          Modal.info({
            title: '연동실패',
            content: '서버에러. 관리자에게 문의하세요.',
          });
          setPosCode('');
        } else if (res.data.result === 'FR_UPDATE_ERROR') {
          Modal.info({
            title: '연동실패',
            content: '연동 후 사업자번호 변경처리에 실패했습니다.',
          });
          setPosCode('');
        } else {
          Modal.info({
            title: '연동실패',
            content: '연동 호출 시 에러 발생. 관리자에게 문의하세요.',
          });
          setPosCode('');
        }
      })
      .catch((e) => console.error(e));
  };

  const handleBydMapping = () => {
    if (frOrderAgencyCodeSelected) return;
    if (!frOrderAgencyCodeSelected) {
      if (posCode !== '') {
        var foa = {
          userIdx: null,
          nncode: null,
          ftCode: null,
          deCode: null,
          dgCode: null,
          bpCode: null,
          psCode: null,
        };

        foa[posCode] = '';
      }
    }
    Modal.confirm({
      title: '비욘드 연동',
      content: '비욘드 연동은 즉시 연동됩니다. 연동하시겠습니까?',
      onOk: async () => {
        const res = await httpExec('PUT', makeUrl(httpUrl.mapFrToByd, []), {
          idx: data.idx,
          businessNumber: data.businessNumber,
          addr1: data.addr1,
          addr2: data.addr2,
          latitude: data.latitude,
          longitude: data.longitude,
          branchIdx: data.branchIdx,
          phone: data.phone,
          frOrderAgency: !frOrderAgencyCodeSelected ? [foa] : null,
        });
        // .then((res) => {
        console.log("res : " ,res);
        if (res.data.result === 'SUCCESS') {
          Modal.info({
            title: '연동성공',
            content: '비욘드 연동에 성공했습니다.',
          });
          setFrOrderAgencyCodeSelected(true);
          setPosStoreCode(res.data.storeId);
          setPosCode('byd');
        } else if (res.data.result === 'NO_MATCHED_STORE') {
          Modal.info({
            title: '연동실패',
            content:
                '해당 사업자 번호에 맞는 비욘드 연동매장이 존재하지 않습니다.',
          });
          setPosCode('');
        } else if (res.data.result === 'MULTIPLE_MATCHED_STORE') {
          Modal.confirm({
            title: '연동실패',
            content:
                '해당 사업자 번호에 맞는 비욘드 연동매장이 중복됩니다.\n 해당하는 매장을 조회하시겠습니까?',
            onOk: () => {
              setMultiList(res.data.multiList);
              // setSearchFtStoreOpen(true);
              setSearchBydStoreOpen(true);
            },
          });
        } else if (res.data.result === 'INVALID_AUTHORIZATION') {
          Modal.info({
            title: '연동실패',
            content: '인증정보가 올바르지 않습니다.',
          });
          setPosCode('');
        } else if (res.data.result === 'CREATE_FR_ORDER_AGENCY_ERROR') {
          Modal.info({
            title: '연동실패',
            content: '서버에러. 관리자에게 문의하세요.',
          });
          setPosCode('');
        } else if (res.data.result === 'FR_UPDATE_ERROR') {
          Modal.info({
            title: '연동실패',
            content: '연동 후 사업자번호 변경처리에 실패했습니다.',
          });
          setPosCode('');
        } else {
          Modal.info({
            title: '연동실패',
            content: '연동 호출 시 에러 발생. 관리자에게 문의하세요.',
          });
          setPosCode('');
        }
        // })
        // .catch((e) => console.error(e));
      },
    });
  };

  const mapSelectedBydStore = (store) => {
    if (!frOrderAgencyCodeSelected) {
      if (posCode !== '') {
        var foa = {
          userIdx: null,
          nncode: null,
          ftCode: null,
          deCode: null,
          dgCode: null,
          bpCode: null,
          psCode: null,
        };

        foa[posCode] = '';
      }
    }
    httpExec(
        'POST',
        makeUrl(httpUrl.mapFrToBydBySelectedStore, []),
        {
          idx: data.idx,
          businessNumber: data.businessNumber,
          addr1: data.addr1,
          addr2: data.addr2,
          latitude: data.latitude,
          longitude: data.longitude,
          branchIdx: data.branchIdx,
          phone: data.phone,
          frOrderAgency: !frOrderAgencyCodeSelected ? [foa] : null,
          bydStore: store,
        },
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
    )
        .then((res) => {
          console.log(res.data);
          console.log("연동코드 : ", res.data.storeId);
          if (res.data.result === 'SUCCESS') {
            Modal.info({
              title: '연동성공',
              content: '비욘드 연동에 성공했습니다.',
            });
            console.log(res.data);
            setFrOrderAgencyCodeSelected(true);
            setPosStoreCode(res.data.storeId);
            setPosCode('byd');
          } else if (res.data.result === 'NO_MATCHED_STORE') {
            Modal.info({
              title: '연동실패',
              content:
                  '해당 사업자 번호에 맞는 비욘드 연동매장이 존재하지 않습니다.',
            });
            setPosCode('');
          } else if (res.data.result === 'MULTIPLE_MATCHED_STORE') {
            Modal.confirm({
              title: '연동실패',
              content:
                  '해당 사업자 번호에 맞는 비욘드 연동매장이 중복됩니다.\n 해당하는 매장을 조회하시겠습니까?',
              onOk: () => {
                setMultiList(res.data.multiList);
                setSearchFtStoreOpen(true);
              },
            });
          } else if (res.data.result === 'INVALID_AUTHORIZATION') {
            Modal.info({
              title: '연동실패',
              content: '인증정보가 올바르지 않습니다.',
            });
            setPosCode('');
          } else if (res.data.result === 'CREATE_FR_ORDER_AGENCY_ERROR') {
            Modal.info({
              title: '연동실패',
              content: '서버에러. 관리자에게 문의하세요.',
            });
            setPosCode('');
          } else if (res.data.result === 'FR_UPDATE_ERROR') {
            Modal.info({
              title: '연동실패',
              content: '연동 후 사업자번호 변경처리에 실패했습니다.',
            });
            setPosCode('');
          } else {
            Modal.info({
              title: '연동실패',
              content: '연동 호출 시 에러 발생. 관리자에게 문의하세요.',
            });
            setPosCode('');
          }
        })
        .catch((e) => console.error(e));
  };

  const handleBydBtMapping = () => {
    if (frOrderAgencyCodeSelected) return;
    if (!frOrderAgencyCodeSelected) {
      if (posCode !== '') {
        var foa = {
          userIdx: null,
          nncode: null,
          ftCode: null,
          deCode: null,
          dgCode: null,
          bpCode: null,
          psCode: null,
        };

        foa[posCode] = '';
      }
    }
    Modal.confirm({
      title: '비욘드 연동',
      content: '비욘드 연동은 즉시 연동됩니다. 연동하시겠습니까?',
      onOk: async () => {
        const res = await httpExec('PUT', makeUrl(httpUrl.mapFrToByd, []), {
          idx: data.idx,
          businessNumber: data.businessNumber,
          addr1: data.addr1,
          addr2: data.addr2,
          latitude: data.latitude,
          longitude: data.longitude,
          branchIdx: data.branchIdx,
          phone: data.phone,
          frOrderAgency: !frOrderAgencyCodeSelected ? [foa] : null,
        });
        // .then((res) => {
        console.log("res : " ,res);
        if (res.data.result === 'SUCCESS') {
          Modal.info({
            title: '연동성공',
            content: '비욘드 연동에 성공했습니다.',
          });
          setFrOrderAgencyCodeSelected(true);
          setPosStoreCode(res.data.storeId);
          setPosCode('bydbt');
        } else if (res.data.result === 'NO_MATCHED_STORE') {
          Modal.info({
            title: '연동실패',
            content:
                '해당 사업자 번호에 맞는 비욘드 연동매장이 존재하지 않습니다.',
          });
          setPosCode('');
        } else if (res.data.result === 'MULTIPLE_MATCHED_STORE') {
          Modal.confirm({
            title: '연동실패',
            content:
                '해당 사업자 번호에 맞는 비욘드 연동매장이 중복됩니다.\n 해당하는 매장을 조회하시겠습니까?',
            onOk: () => {
              setMultiList(res.data.multiList);
              // setSearchFtStoreOpen(true);
              setSearchBydBtStoreOpen(true);
            },
          });
        } else if (res.data.result === 'INVALID_AUTHORIZATION') {
          Modal.info({
            title: '연동실패',
            content: '인증정보가 올바르지 않습니다.',
          });
          setPosCode('');
        } else if (res.data.result === 'CREATE_FR_ORDER_AGENCY_ERROR') {
          Modal.info({
            title: '연동실패',
            content: '서버에러. 관리자에게 문의하세요.',
          });
          setPosCode('');
        } else if (res.data.result === 'FR_UPDATE_ERROR') {
          Modal.info({
            title: '연동실패',
            content: '연동 후 사업자번호 변경처리에 실패했습니다.',
          });
          setPosCode('');
        } else {
          Modal.info({
            title: '연동실패',
            content: '연동 호출 시 에러 발생. 관리자에게 문의하세요.',
          });
          setPosCode('');
        }
        // })
        // .catch((e) => console.error(e));
      },
    });
  };

  const mapSelectedBydBtStore = (store) => {
    if (!frOrderAgencyCodeSelected) {
      if (posCode !== '') {
        var foa = {
          userIdx: null,
          nncode: null,
          ftCode: null,
          deCode: null,
          dgCode: null,
          bpCode: null,
          psCode: null,
        };

        foa[posCode] = '';
      }
    }
    httpExec(
        'POST',
        makeUrl(httpUrl.mapFrToBydBySelectedStore, []),
        {
          idx: data.idx,
          businessNumber: data.businessNumber,
          addr1: data.addr1,
          addr2: data.addr2,
          latitude: data.latitude,
          longitude: data.longitude,
          branchIdx: data.branchIdx,
          phone: data.phone,
          frOrderAgency: !frOrderAgencyCodeSelected ? [foa] : null,
          bydStore: store,
        },
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
    )
        .then((res) => {
          console.log(res.data);
          console.log("연동코드 : ", res.data.storeId);
          if (res.data.result === 'SUCCESS') {
            Modal.info({
              title: '연동성공',
              content: '비욘드 연동에 성공했습니다.',
            });
            console.log(res.data);
            setFrOrderAgencyCodeSelected(true);
            setPosStoreCode(res.data.storeId);
            setPosCode('bydbt');
          } else if (res.data.result === 'NO_MATCHED_STORE') {
            Modal.info({
              title: '연동실패',
              content:
                  '해당 사업자 번호에 맞는 비욘드 연동매장이 존재하지 않습니다.',
            });
            setPosCode('');
          } else if (res.data.result === 'MULTIPLE_MATCHED_STORE') {
            Modal.confirm({
              title: '연동실패',
              content:
                  '해당 사업자 번호에 맞는 비욘드 연동매장이 중복됩니다.\n 해당하는 매장을 조회하시겠습니까?',
              onOk: () => {
                setMultiList(res.data.multiList);
                setSearchBydBtStoreOpen(true);
              },
            });
          } else if (res.data.result === 'INVALID_AUTHORIZATION') {
            Modal.info({
              title: '연동실패',
              content: '인증정보가 올바르지 않습니다.',
            });
            setPosCode('');
          } else if (res.data.result === 'CREATE_FR_ORDER_AGENCY_ERROR') {
            Modal.info({
              title: '연동실패',
              content: '서버에러. 관리자에게 문의하세요.',
            });
            setPosCode('');
          } else if (res.data.result === 'FR_UPDATE_ERROR') {
            Modal.info({
              title: '연동실패',
              content: '연동 후 사업자번호 변경처리에 실패했습니다.',
            });
            setPosCode('');
          } else {
            Modal.info({
              title: '연동실패',
              content: '연동 호출 시 에러 발생. 관리자에게 문의하세요.',
            });
            setPosCode('');
          }
        })
        .catch((e) => console.error(e));
  };

  return (
    <>
      {searchFtStoreOpen && (
        <SearchFtStoreDialog
          multiList={multiList}
          businessNumber={data.businessNumber}
          onSelect={(store) => mapSelectedFtStore(store)}
          close={() => setSearchFtStoreOpen(false)}
        />
      )}

      {searchBydStoreOpen && (
          <SearchBydStoreDialog
              multiList={multiList}
              businessNumber={data.businessNumber}
              onSelect={(store) => mapSelectedBydStore(store)}
              close={() => setSearchBydStoreOpen(false)}
          />
      )}

      {searchBydBtStoreOpen && (
          <SearchBydBtStoreDialog
              multiList={multiList}
              businessNumber={data.businessNumber}
              onSelect={(store) => mapSelectedBydBtStore(store)}
              close={() => setSearchBydBtStoreOpen(false)}
          />
      )}

      <div style={{ border: '1px solid grey', padding: 20 }}>
        <div className="create-control-form-item-title">연동업체</div>
        <Form.Item className="create-control-form-item">
          <Select
            value={posCode}
            className="create-control-input"
            style={{width: 100, marginRight: 10}}
            onChange={(value) => {
              if (value === 'ftCode' && !frOrderAgencyCodeSelected) {
                handleFtMapping();
                return;
              }
              if (value === 'byd' && !frOrderAgencyCodeSelected) {
                handleBydMapping();
                return;
              }
              if (value === 'bydbt' && !frOrderAgencyCodeSelected) {
                handleBydBtMapping();
                return;
              }

              onChangeFrOrderAgency(value);
            }}>
            {Object.keys(frOrderAgencyCode).map((key) => {
              return (
                <Select.Option value={frOrderAgencyCode[key]}>
                  {frOrderAgencyPosList[key] ? (
                    <Popover
                      className="fr-order-agency-popover"
                      placement="left"
                      content={frOrderAgencyPosList[key]}
                      title="연동가능포스">
                      {key}
                    </Popover>
                  ) : (
                    <div>{key}</div>
                  )}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <div className="create-control-form-item-title">연동코드</div>
        <Form.Item className="create-control-form-item">
          <Input
            onChange={(e) => {
              if (frOrderAgencyCodeSelected) return;
              setPosStoreCode(e.target.value);
            }}
            value={posStoreCode}
            className="create-control-input"
            placeholder="연동코드"
            disabled={frOrderAgencyCodeSelected}
          />
        </Form.Item>
        {posCode === 'lte' && (
          <div>* 롯데리아 연동은 관제에서 코드를 직접 넣어야 합니다. </div>
        )}

        <Button
          onClick={() => {
            deleteFrOrderAgency();
          }}>
          삭제
        </Button>
      </div>
    </>
  );
};

export default FrOrderAgencyCard;
