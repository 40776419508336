import { Button, DatePicker, Input, Modal, Select, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import SelectBox from '../../components/input/SelectBox';
import '../../css/common.css';
import '../../css/staff.css';
import { agencyBatchWorkKind, batchCategory, buyStatusString, kindString } from '../../lib/util/codeUtil';
import { formatDate } from '../../lib/util/dateUtil';
import { comma } from '../../lib/util/numberUtil';
import { customError } from '../../api/Modals';
import xlsx from 'xlsx';
const Option = Select.Option;
const today = new moment();
const todayString = today.format('YYYY-MM-DD');
const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

class NcashDailyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
      },
      kind: '0',
      branchName: '',
      searchName: '',
      endDate: todayString,
      startDate: todayString,
      userId: '',
    };
    this.formRef = React.createRef();
  }

  pressSearch = () => {
    this.setState(
      {
        pagination: {
          current: 1,
          pageSize: 20,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  componentDidMount() {
    this.getList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.refreshTrigger !== this.props.refreshTrigger) this.getList();
  }

  handleTableChange = (pagination) => {
    // console.log(pagination);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  parseExcelJson = (data) => {
    let result = [
      {
        createDate: '일시',
        kind: '구분',
        batchCategory: '일/월차감구분',
        batchTitle: '차감명',
        branchName: '지사명',
        userId: '사용자아이디',
        userName: '사용자이름',
        registrationNumber: '주민/사업자번호',
        phone: '연락처',
        ncashDelta: '차감금액',
      },
    ];
    data.forEach((item) => {
      result.push({
        createDate: item.createDate,
        kind: agencyBatchWorkKind[item.kind],
        batchCategory: batchCategory[item.batchCategory],
        batchTitle: item.batchTitle ? item.batchTitle : '-',
        userId: item.userId,
        userName: item.userName,
        phone: item.phone,
        ncashDelta: item.ncashDelta,
      });
    });

    return result;
  };

  onDownload = () => {
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;

    if (null == startDate || null == endDate) {
      Modal.warn({
        title: '조회기간 설정',
        content: '조회기간을 30일 이내로 설정해주세요.',
      });
      return;
    }
    const startMoment = new moment(startDate);
    const endMoment = new moment(endDate);
    const dateDiff = moment.duration(endMoment.diff(startMoment)).asDays();
    console.log('dateDiff', dateDiff);
    if (dateDiff > 30) {
      Modal.warn({
        title: '조회기간 설정',
        content: '조회기간을 30일 이내로 설정해주세요.',
      });
      return;
    }

    httpGet(
      httpUrl.riderBatchWorkDailyListForExcel,
      [this.state.kind === '0' ? '' : this.state.kind, this.state.searchName, this.state.startDate, this.state.endDate],
      {}
    ).then((res) => {
      const excelJson = this.parseExcelJson(res.data.ncashDailies);
      const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
      const wb = xlsx.utils.book_new();
      ws['!cols'] = [];
      xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
      xlsx.writeFile(wb, '일차감내역.xlsx');
    });
  };

  onChangeStatus = (value) => {
    this.setState(
      {
        kind: value,
        pagination: {
          current: 1,
          pageSize: 20,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  //   getList = () => {
  //     var list = [
  //         {
  //             createDate: "2021-06-12",
  //             kind: 1,
  //             userId: "rider03",
  //             userName: "rider03",
  //             registrationNumber: "930507-1000000",
  //             phone: "010-1111-2222",
  //             ncashDelta: 50000
  //         },
  //         {
  //             createDate: "2021-06-23",
  //             kind: 2,
  //             userId: "rider04",
  //             userName: "rider04",
  //             registrationNumber: "950721-2000000",
  //             phone: "010-1212-3333",
  //             ncashDelta: 30000
  //         },
  //         {
  //             createDate: "2021-07-15",
  //             kind: 3,
  //             userId: "rider06",
  //             userName: "rider06",
  //             registrationNumber: "941108-1000000",
  //             phone: "010-2121-1111",
  //             ncashDelta: 20000
  //         }
  //     ]
  //     this.setState({
  //         list:list,
  //     })
  // }

  getList = () => {
    let kind = this.state.kind === '0' ? '' : this.state.kind;
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let userId = this.state.userId;

    if (null == startDate || null == endDate) {
      Modal.warn({
        title: '조회기간 설정',
        content: '조회기간을 30일 이내로 설정해주세요.',
      });
      return;
    }
    const startMoment = new moment(startDate);
    const endMoment = new moment(endDate);
    const dateDiff = moment.duration(endMoment.diff(startMoment)).asDays();
    console.log('dateDiff', dateDiff);
    if (dateDiff > 30) {
      Modal.warn({
        title: '조회기간 설정',
        content: '조회기간을 30일 이내로 설정해주세요.',
      });
      return;
    }

    httpGet(httpUrl.riderBatchWorkDailyList, [kind, pageNum, pageSize, '', startDate, endDate, userId], {})
      .then((res) => {
        // console.log('res.data :' + JSON.stringify(res.data, null, 4));
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState(
          {
            list: res.data.ncashDailies,
            pagination,
          }
          // () => console.log(this.state.list)
        );
      })
      .catch((e) => {
        customError('목록 에러', '에러가 발생하여 목록을 불러올수 없습니다.');
      });
  };

  onSearch = () => {
    this.setState(
      {
        pagination: {
          current: 1,
          pageSize: 20,
        },
      },
      () => {
        this.getList();
        // this.getExcelList();
      }
    );
  };

  render() {
    const columns = [
      {
        title: '일시',
        dataIndex: 'createDate',
        className: 'table-column-center',
      },
      {
        title: '구분',
        dataIndex: 'kind',
        className: 'table-column-center',
        render: (data) => <div>{agencyBatchWorkKind[data]}</div>,
      },
      {
        title: '일/월차감구분',
        dataIndex: 'batchCategory',
        className: 'table-column-center',
        render: (data) => <div>{batchCategory[data]}</div>,
      },
      {
        title: '차감명',
        dataIndex: 'batchTitle',
        className: 'table-column-left-only',
        render: (data) => <div>{data ? data : '-'}</div>,
      },
      {
        title: '지사명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '사용자아이디',
        dataIndex: 'userId',
        className: 'table-column-center',
      },
      {
        title: '사용자이름',
        dataIndex: 'userName',
        className: 'table-column-center',
      },
      {
        title: '주민/사업자번호',
        dataIndex: 'registrationNumber',
        className: 'table-column-center',
      },
      {
        title: '연락처',
        dataIndex: 'phone',
        className: 'table-column-center',
      },
      {
        title: '차감금액',
        dataIndex: 'ncashDelta',
        className: 'table-column-right-only',
        render: (data) => <div>{comma(data)}원</div>,
      },
    ];
    return (
      <div style={{ marginTop: 20 }}>
        <SelectBox
          // placeholder={'전체'}
          style={{ width: 200, marginBottom: 10, textAlign: 'center' }}
          value={this.state.kind}
          code={Object.keys(agencyBatchWorkKind)}
          codeString={agencyBatchWorkKind}
          onChange={(value) => {
            if (parseInt(value) !== this.state.kind) {
              this.onChangeStatus(value);
            }
          }}
        />
        <Search
          placeholder="아이디 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ userId: e.target.value })}
          onSearch={this.onSearch}
          style={{
            width: 220,
            marginLeft: 10,
          }}
        />

        <Button style={{ float: 'right' }} onClick={() => this.onDownload()}>
          엑셀 다운로드
        </Button>
        <RangePicker
          allowClear={false}
          allowEmpty={[false, false]}
          style={{ width: 300, float: 'right', marginRight: 10 }}
          placeholder={['시작일', '종료일']}
          value={[moment(this.state.startDate), moment(this.state.endDate)]}
          onChange={(_, dateStrings) => {
            if (dateStrings[(0, 1)]) {
              this.setState(
                {
                  startDate: dateStrings[0],
                  endDate: dateStrings[1],
                  pagination: {
                    current: 1,
                    pageSize: 20,
                  },
                },
                () => {
                  this.getList();
                }
              );
            } else {
              this.setState(
                {
                  startDate: '',
                  endDate: '',
                  pagination: {
                    current: 1,
                    pageSize: 20,
                  },
                },
                () => {
                  this.getList();
                }
              );
            }
          }}
        />

        {/* <div className="selectLayout"/> */}
        <div className="dataTableLayout" style={{ marginTop: 10 }}>
          <Table
            rowKey={(record) => record.idx}
            dataSource={this.state.list}
            columns={columns}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  branchIdx: state.login.loginInfo.branchIdx,
});

export default connect(mapStateToProps, null)(NcashDailyList);
