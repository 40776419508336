import moment from 'moment';
import { comma } from './numberUtil';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { httpGet, httpUrl } from '../../api/httpClient';

const url = {
  '대리점 수수료': '/details/agency-fees',
  가맹비: '/details/dues',
  '건별 가맹비': '/details/dues-delivery-price',
  '바이크 렌트': '/details/bike-rent',
  '대여금 회수': '/details/sales-loan',
  '선충전 회수': '/details/sales-precharge',
  기타: '/details/sales-etc',
  '프로그램 사용료': '/cost-details/program-fees',
  '산재보험료(대리점 부담분)': '/cost-details/accident-insurance',
  '고용보험료(대리점 부담분)': '/cost-details/employ-insurance',
  '배달 지원금': '/cost-details/subsidy',
  음식배상: '/cost-details/food-compensation',
  '바이크 렌트 납부': '/cost-details/bike-rent',
  프로모션: '/cost-details/promotion',
  '대여금 지급': '/cost-details/loan',
  '선충전 지급': '/cost-details/precharge',
  '기타 비용': '/cost-details/etc',
  '배달비 부가세': '/tax-details/delivery-price-vat-v2',
  '가맹비 부가세': '/tax-details/dues-vat-v2',
  '공유콜 매입 부가세': '/tax-details/share-call-delivery-price-cost-vat',
  '라이더 원천세': '/tax-details/withholding-tax-v2',
  '선충전 미회수': '/transaction-details/precharge',
  '예치금 입금': '/transaction-details/ncash-deposit',
  '예치금 출금': '/transaction-details/ncash-withdraw',
  '매출 공유콜 배달비': '/tax-return/sales/share-call',
  '매입 공유콜 배달비': '/tax-return/cost/share-call',
  '콜수수료 부가세': '/tax-details/call-fee-vat',
};
const excelUrl = {
  '대리점 수수료': '/details/agency-fees/excel',
  가맹비: '/details/dues/excel',
  '건별 가맹비': '/details/dues-delivery-price/excel',
  '바이크 렌트': '/details/bike-rent/excel',
  '대여금 회수': '/details/sales-loan/excel',
  '선충전 회수': '/details/sales-precharge/excel',
  기타: '/details/sales-etc/excel',
  '프로그램 사용료': '/cost-details/program-fees/excel',
  '산재보험료(대리점 부담분)': '/cost-details/accident-insurance/excel',
  '고용보험료(대리점 부담분)': '/cost-details/employ-insurance/excel',
  '배달 지원금': '/cost-details/subsidy/excel',
  음식배상: '/cost-details/food-compensation/excel',
  '바이크 렌트 납부': '/cost-details/bike-rent/excel',
  프로모션: '/cost-details/promotion/excel',
  '대여금 지급': '/cost-details/loan/excel',
  '선충전 지급': '/cost-details/precharge/excel',
  '기타 비용': '/cost-details/etc/excel',
  '배달비 부가세': '/tax-details/delivery-price-vat-v2/excel',
  '가맹비 부가세': '/tax-details/dues-vat-v2/excel',
  '공유콜 매입 부가세': '/tax-details/share-call-delivery-price-cost-vat/excel',
  '라이더 원천세': '/tax-details/withholding-tax-v2/excel',
  '선충전 미회수': '/transaction-details/precharge/excel',
  '예치금 입금': '/transaction-details/ncash-deposit/excel',
  '예치금 출금': '/transaction-details/ncash-withdraw/excel',
  '콜수수료 부가세': '/tax-details/call-fee-vat/excel',
};
const menuTab = {
  '대리점 수수료': [
    { label: '전체', value: '' },
    { label: '배달비 수수료', value: 'RIDER_FEE' },
    { label: '공유콜 수수료', value: 'SHARE_CALL_RIDER_FEE' },
    { label: '할증건별 수수료', value: 'DELIVERY_PRICE_FEE_BY_DP_REGION' },
    { label: '건별 수수료', value: 'DELIVERY_PRICE_FEE_BY_DP_BASIC' },
    { label: '콜수수료', value: 'SUPPORT_FUND' },
  ],
  '바이크 렌트': [
    { label: '전체', value: '' },
    { label: '렌트비', value: 'RENT' },
    { label: '수리비', value: 'REPAIR' },
    { label: '과태료', value: 'FINE' },
  ],
  '전체 미회수': [
    { label: '전체', value: 999 },
    { label: '렌트비', value: 10 },
    { label: '수리비', value: 11 },
    { label: '과태료', value: 17 },
    { label: '음식보험', value: 22 },
    { label: '대여금', value: 6 },
    { label: '가맹비', value: 19 },
    { label: '기타', value: 20 },
  ],
  '바이크 렌트 납부': [
    { label: '전체', value: '' },
    { label: '렌트비', value: 'RENT' },
    { label: '수리비', value: 'REPAIR' },
    { label: '과태료', value: 'FINE' },
  ],
};
const checkTab = {
  가맹비: [
    { label: '미회수', value: 0 },
    { label: '회수', value: 1 },
  ],
  '전체 미회수': [
    { label: '미회수', value: 0 },
    { label: '회수', value: 1 },
  ],
};
const selectTab = {
  '배달 지원금': [
    { value: '', label: '전체' },
    { value: 'DELIVERY_SUBSIDY_SEND', label: '배달 지원금' },
    { value: 'DELIVERY_SUBSIDY_SEND_RESTORE', label: '배달 지원금 복원' },
  ],
};
const placeholderText = {
  '대리점 수수료': '주문번호',
  가맹비: '가맹점명/대표자명',
  '건별 가맹비': '가맹점명/대표자명',
  '바이크 렌트': '라이더명',
  '대여금 회수': '라이더명',
  '선충전 회수': '가맹점명',
  기타: '대분류명/가맹점명/라이더명',
  '프로그램 사용료': '주문번호',
  '산재보험료(대리점 부담분)': '주문번호',
  '고용보험료(대리점 부담분)': '주문번호',
  '배달 지원금': '지급자명/대상자명',
  음식배상: '가맹점명/라이더명',
  '바이크 렌트 납부': '라이더명',
  프로모션: '가맹점명/라이더명',
  '대여금 지급': '라이더명',
  '선충전 지급': '가맹점명',
  '기타 비용': '대분류명/가맹점명/라이더명',
  '배달비 부가세': '가맹점명/사업자번호',
  '가맹비 부가세': '가맹점명/사업자번호',
  '콜수수료 부가세': '주문번호',
  '공유콜 매입 부가세': '주문번호',
  '라이더 원천세': '주민번호/라이더명',
  '전체 미회수': '가맹점명/라이더명',
  '매출 공유콜 배달비': '대리점명/가맹점명/라이더명',
  '매입 공유콜 배달비': '대리점명/가맹점명/라이더명',
};
const batchCategory = {
  10: '렌트비',
  11: '수리비',
  17: '과태료',
  22: '음식보험',
  6: '대여금',
  19: '가맹비',
  20: '기타',
};
const sumNumberForModal = {
  '전체 미회수': ['미회수 합계', '회수 합계'],
};
const categoryForAgencyFee = {
  RIDER_FEE: '배달비 수수료',
  SHARE_CALL_RIDER_FEE: '공유콜 수수료',
  DELIVERY_PRICE_FEE_BY_DP_BASIC: '건별 수수료',
  DELIVERY_PRICE_FEE_BY_DP_REGION: '할증건별 수수료',
  RIDER_FEE_RESTORE: '배달비 수수료 복원',
  SHARE_CALL_RIDER_FEE_RESTORE: '공유콜 수수료 복원',
  DELIVERY_PRICE_FEE_BY_DP_BASIC_RESTORE: '건별 수수료 복원',
  DELIVERY_PRICE_FEE_BY_DP_REGION_RESTORE: '할증건별 수수료 복원',
  SUPPORT_FUND: '콜수수료',
  SUPPORT_FUND_RESTORE: '콜수수료 복원',
};
const categoryForBikeRent = {
  RENT: '렌트비',
  REPAIR: '수리비',
  FINE: '과태료',
};
// const userTypeCode = {
//   FRANCHISE: '가맹점',
//   RIDER: '라이더',
//   AGENCY: '대리점',
//   AGENCY_HEADQUARTER: '제휴본사',
// };
const commonBatchColumns = [
  {
    title: '일시',
    dataIndex: 'createDate',
    className: 'table-column-center',
    width: 200,
    render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: '대분류',
    dataIndex: 'dailyKind',
    className: 'table-column-center',
    render: (data, row) => (row.category === 'DUES_PAY' ? batchCategory[19] : batchCategory[data]),
  },
  {
    title: '가맹점명/라이더명',
    dataIndex: 'name',
    className: 'table-column-center',
    width: 280,
    render: (data, row) => (
      <span style={{ cursor: 'pointer' }}>
        {data}
        {row.memo && <ExclamationCircleOutlined />}
      </span>
    ),
  },
  {
    title: '상태',
    dataIndex: 'recoveredAmount',
    className: 'table-column-center',
    render: (data) =>
      data > 0 ? <span style={{ color: 'blue' }}>회수</span> : <span style={{ color: 'red' }}>미회수</span>,
  },
];
const columns = {
  '대리점 수수료': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '분류',
      dataIndex: 'category2',
      className: 'table-column-center',
      render: (data) => categoryForAgencyFee[data],
    },
    {
      title: '주문번호',
      dataIndex: 'orderIdx',
      className: 'table-column-center',
    },
    {
      title: '금액',
      dataIndex: 'feeAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  가맹비: [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '가맹점명',
      dataIndex: 'frNameExtra',
      className: 'table-column-center',
    },
    {
      title: '대표자명',
      dataIndex: 'ownerName',
      className: 'table-column-center',
    },
    {
      title: '가맹비',
      dataIndex: 'needToCollectAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '추가 가맹비',
      dataIndex: 'additionalDuesAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '총 가맹비',
      dataIndex: 'needToCollectAmountWithoutAdditionalDues',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '회수된 가맹비',
      dataIndex: 'actualCollectAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '건별 가맹비': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '가맹점명',
      dataIndex: 'frName',
      className: 'table-column-center',
    },
    {
      title: '대표자명',
      dataIndex: 'ownerName',
      className: 'table-column-center',
    },
    {
      title: '건별 가맹비 금액',
      dataIndex: 'duesDeliveryAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '바이크 렌트': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '라이더명',
      dataIndex: 'riderName',
      className: 'table-column-center',
    },
    {
      title: '대분류',
      dataIndex: 'kind',
      className: 'table-column-center',
      render: (data) => categoryForBikeRent[data],
    },
    {
      title: '회수 금액',
      dataIndex: 'bikeRentSalesAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '대여금 회수': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '라이더명',
      dataIndex: 'riderName',
      className: 'table-column-center',
    },
    {
      title: '회수 금액',
      dataIndex: 'ncashDelta',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '선충전 회수': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '가맹점명',
      dataIndex: 'frName',
      className: 'table-column-center',
    },
    {
      title: '회수 금액',
      dataIndex: 'ncashDelta',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  기타: [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '대분류',
      dataIndex: 'kind',
      className: 'table-column-center',
      render: (data) => (data ? data : '기타'),
    },
    {
      title: '상세내용',
      dataIndex: 'memo',
      className: 'table-column-center',
    },
    {
      title: '가맹점명/라이더명/제휴본사명',
      dataIndex: 'riderOrFrName',
      className: 'table-column-center',
    },
    {
      title: '회수 금액',
      dataIndex: 'etcSalesAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '프로그램 사용료': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '주문번호',
      dataIndex: 'orderIdx',
      className: 'table-column-center',
    },
    {
      title: ' 금액',
      dataIndex: 'programFee',
      className: 'table-column-center',
      render: (data) => -comma(data),
    },
  ],
  '산재보험료(대리점 부담분)': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '주문번호',
      dataIndex: 'orderIdx',
      className: 'table-column-center',
    },
    {
      title: '금액',
      dataIndex: 'accidentInsuranceAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '고용보험료(대리점 부담분)': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '주문번호',
      dataIndex: 'orderIdx',
      className: 'table-column-center',
    },
    {
      title: '금액',
      dataIndex: 'employInsuranceAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '배달 지원금': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '주문번호',
      dataIndex: 'orderIdx',
      className: 'table-column-center',
    },
    {
      title: '지급자명',
      dataIndex: 'adminId',
      className: 'table-column-center',
    },
    {
      title: '구분',
      dataIndex: 'category',
      className: 'table-column-center',
    },
    {
      title: '대상자명',
      dataIndex: 'targetName',
      className: 'table-column-center',
    },
    {
      title: '금액',
      dataIndex: 'ncashDelta',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  음식배상: [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '가맹점명/라이더명',
      dataIndex: 'riderOrFrName',
      className: 'table-column-center',
    },
    {
      title: '금액',
      dataIndex: 'ncashDelta',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '바이크 렌트 납부': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '구분',
      dataIndex: 'kind',
      className: 'table-column-center',
      render: (data) => categoryForBikeRent[data],
    },
    {
      title: '라이더명',
      dataIndex: 'riderName',
      className: 'table-column-center',
    },
    {
      title: '금액',
      dataIndex: 'bikeRentCostAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  프로모션: [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '가맹점명/라이더명',
      dataIndex: 'riderOrFrName',
      className: 'table-column-center',
    },
    {
      title: '금액',
      dataIndex: 'promotionAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '대여금 지급': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '라이더명',
      dataIndex: 'riderName',
      className: 'table-column-center',
    },
    {
      title: '금액',
      dataIndex: 'loanCostAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '선충전 지급': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '가맹점명',
      dataIndex: 'frName',
      className: 'table-column-center',
    },
    {
      title: '금액',
      dataIndex: 'preChargeCostAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '기타 비용': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '메모',
      dataIndex: 'memo',
      className: 'table-column-center',
    },
    {
      title: '가맹점명/라이더명',
      dataIndex: 'riderOrFrName',
      className: 'table-column-center',
    },
    {
      title: '금액',
      dataIndex: 'etcCostAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '배달비 부가세': [
    {
      title: '일자',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD'),
    },
    {
      title: '가맹점명',
      dataIndex: 'frName',
      className: 'table-column-center',
      render: (data) => <span>{data}</span>,
    },
    {
      title: '사업자번호',
      dataIndex: 'businessNumber',
      className: 'table-column-center',
    },
    {
      title: '완료건수',
      dataIndex: 'deliveryCount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '총 배달비',
      dataIndex: 'deliveryPrice',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '부가세',
      dataIndex: 'deliveryPriceVat',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '가맹비 부가세': [
    {
      title: '일자',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD'),
    },
    {
      title: '가맹점명',
      dataIndex: 'frNameExtra',
      className: 'table-column-center',
    },
    {
      title: '사업자번호',
      dataIndex: 'businessNumber',
      className: 'table-column-center',
    },
    {
      title: '완료건수',
      dataIndex: 'duesCount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '가맹비',
      dataIndex: 'needToCollectAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '추가가맹비',
      dataIndex: 'additionalDuesAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '총 가맹비',
      dataIndex: 'needToCollectAmountWithoutAdditionalDues',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '총 회수된 가맹비',
      dataIndex: 'actualCollectAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '총 부가세',
      dataIndex: 'duesVat',
      className: 'table-column-center',
      render: (data) => (data === 0 ? '간이사업자' : comma(data)),
    },
  ],
  '콜수수료 부가세': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '주문번호',
      dataIndex: 'orderIdx',
      className: 'table-column-center',
    },
    {
      title: '콜수수료',
      dataIndex: 'callFeeAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '부가세',
      dataIndex: 'callFeeVatAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '공유콜 매입 부가세': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '주문번호',
      dataIndex: 'orderIdx',
      className: 'table-column-center',
    },
    {
      title: '배달비',
      dataIndex: 'deliveryPrice',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '부가세',
      dataIndex: 'shareCallDeliveryPriceCostVat',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '라이더 원천세': [
    {
      title: '일자',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD'),
    },
    {
      title: '라이더명',
      dataIndex: 'riderName',
      className: 'table-column-center',
    },
    {
      title: '주민번호',
      dataIndex: 'riderRegistNum',
      className: 'table-column-center',
    },
    {
      title: '완료건수',
      dataIndex: 'deliveryCount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '총 라이더 배달비',
      dataIndex: 'totalDeliveryPriceAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '총 라이더 수익',
      dataIndex: 'totalRiderRevenueAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '원천세',
      dataIndex: 'withholdingTaxAmount',
      className: 'table-column-center',
      render: (data) => (data === 0 ? '소액부징수' : comma(data)),
    },
  ],
  '전체 미회수': [
    ...commonBatchColumns,
    {
      title: '회수 원금',
      dataIndex: 'misuAmount',
      className: 'table-column-center',
      render: (data, row) => (row.recoveredAmount === 0 ? 0 : comma(data)),
    },
    {
      title: '미회수 금액',
      dataIndex: 'misuAmount',
      className: 'table-column-center',
      render: (data, row) => (
        <span style={{ color: data - row.recoveredAmount !== 0 ? 'red' : 'black' }}>
          {comma(data - row.recoveredAmount)}
        </span>
      ),
    },
  ],
  일차감음식보험: [
    ...commonBatchColumns,
    {
      title: '회수 금액',
      dataIndex: 'misuAmount',
      className: 'table-column-center',
      render: (data, row) => (row.recoveredAmount === 0 ? 0 : comma(data)),
    },
    {
      title: '미회수 금액',
      dataIndex: 'misuAmount',
      className: 'table-column-center',
      render: (data, row) => (
        <span style={{ color: data - row.recoveredAmount !== 0 ? 'red' : 'black' }}>
          {comma(data - row.recoveredAmount)}
        </span>
      ),
    },
  ],
  '선충전 미회수': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '구분',
      dataIndex: 'category',
      className: 'table-column-center',
    },
    {
      title: '가맹점명',
      dataIndex: 'frName',
      className: 'table-column-center',
    },
    {
      title: '대표자명',
      dataIndex: 'ownerName',
      className: 'table-column-center',
    },
    {
      title: '회수 원금',
      dataIndex: 'needToCollectAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '회수 금액',
      dataIndex: 'actualCollectAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '미회수 금액',
      dataIndex: 'misuCollectAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '예치금 입금': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '입금 금액',
      dataIndex: 'ncashDepositAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '예치금 출금': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '출금 금액',
      dataIndex: 'ncashWithDrawAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
  ],
  '매출 공유콜 배달비': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center black',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '가맹점 소속 대리점',
      dataIndex: 'branchName',
      className: 'table-column-center black',
    },
    {
      title: '가맹점명',
      dataIndex: 'franchiseName',
      className: 'table-column-center black',
    },
    {
      title: '라이더 소속 대리점',
      dataIndex: 'targetName',
      className: 'table-column-center black',
    },
    {
      title: '라이더명',
      dataIndex: 'riderName',
      className: 'table-column-center black',
    },
    {
      title: '매출 공유콜 배달비',
      dataIndex: 'shareCallDeliveryPrice',
      className: 'table-column-center grey',
      render: (data) => comma(data),
    },
    {
      title: '매출 공유콜 배달비 부가세',
      dataIndex: 'shareCallDeliveryPriceVat',
      className: 'table-column-center grey',
      render: (data) => comma(data),
    },
  ],
  '매입 공유콜 배달비': [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center black',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '가맹점 소속 대리점',
      dataIndex: 'branchName',
      className: 'table-column-center black',
    },
    {
      title: '가맹점명',
      dataIndex: 'franchiseName',
      className: 'table-column-center black',
    },
    {
      title: '라이더 소속 대리점',
      dataIndex: 'targetName',
      className: 'table-column-center black',
    },
    {
      title: '라이더명',
      dataIndex: 'riderName',
      className: 'table-column-center black',
    },
    {
      title: '매입 공유콜 배달비',
      dataIndex: 'shareCallDeliveryPrice',
      className: 'table-column-center grey',
      render: (data) => comma(data),
    },
    {
      title: '매입 공유콜 배달비 부가세',
      dataIndex: 'shareCallDeliveryPriceVat',
      className: 'table-column-center grey',
      render: (data) => comma(data),
    },
  ],
};

const detailDataOfFr = [
  {
    title: '일시',
    dataIndex: 'createDate',
    className: 'table-column-center black',
    render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: '가맹점 소속 대리점',
    dataIndex: 'frBranchName',
    className: 'table-column-center black',
  },
  {
    title: '가맹점명',
    dataIndex: 'frName',
    className: 'table-column-center black',
  },
  {
    title: '라이더 소속 대리점',
    dataIndex: 'riderBranchName',
    className: 'table-column-center black',
  },
  {
    title: '라이더명',
    dataIndex: 'riderName',
    className: 'table-column-center black',
  },
  {
    title: '배달비',
    dataIndex: 'deliveryPrice',
    className: 'table-column-center grey',
    render: (data) => comma(data),
  },
  {
    title: '배달비 부가세',
    dataIndex: 'deliveryPriceVat',
    className: 'table-column-center grey',
    render: (data) => comma(data),
  },
];

const parseExcelJsonForSettlement = (data) => {
  let result = [
    {
      createDate: '일시',
      category: '대분류',
      riderOrFrName: '가맹점명/라이더명',
      recoveredAmount: '상태',
      misuAmount: '회수 원금',
      notRecoverdAmount: '미회수 금액',
      memo: '메모',
    },
  ];
  data.forEach((item) => {
    result.push({
      createDate: moment(item.createDate).format('YYYY-MM-DD HH:mm:ss'),
      category: item.category === 'DUES_PAY' ? batchCategory[19] : batchCategory[item.dailyKind],
      riderOrFrName: item.name,
      recoveredAmount: item.recoveredAmount > 0 ? '회수' : '미회수',
      misuAmount: item.recoveredAmount === 0 ? 0 : item.misuAmount,
      notRecoverdAmount: item.misuAmount - item.recoveredAmount,
      memo: item.memo,
    });
  });
  return result;
};

export {
  url,
  excelUrl,
  menuTab,
  checkTab,
  columns,
  placeholderText,
  sumNumberForModal,
  batchCategory,
  parseExcelJsonForSettlement,
  detailDataOfFr,
  selectTab,
};
