import {Button, Input, Modal, Table} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPost, httpUrl } from '../../../api/httpClient';
import { updateComplete, updateError, registError } from '../../../api/Modals';
import '../../../css/modal.css';
import CloseIcon from '../../../img/login/close.png';
import { statusString } from '../../../lib/util/codeUtil';
import SelectBox from '../../input/SelectBox';
import ZeroCallFrMemoDialog from './ZeroCallFrMemoDialog';
import TextArea from "antd/lib/input/TextArea";

class ZeroCallFrMemoHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
    };
  }

  componentDidMount() {
    this.getList();
  }

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  getList = () => {
    const frIdx = this.props.frIdx;
    console.log({frIdx});
    try {
      httpGet(httpUrl.getZeroCallFrMemo, [frIdx], {}).then((res) => {
        console.log(res.data.list);
        if (res.result === 'SUCCESS' && res.data !== null && res.data !== undefined) {
          this.setState({
            list: res.data.list,
          });
        }
      });
    } catch (error) {
      console.error('getZeroFrList: ', error);
    }
  };

  render() {
    const columns = [
      {
        title: '사유입력 일시',
        dataIndex: 'zeroCallMemoDate',
        className: 'table-column-center',
        render: (data) => <div>{moment(data).format('YYYY-MM-DD HH:mm')}</div>,
      },
      {
        title: '사유 내역',
        dataIndex: 'zeroCallMemo',
        className: 'table-column-center',
        render: (data) => <div>
          <TextArea
            placeholder={data}
            className="zeroCallFrMemoHistory"
            disabled={true}
            style={{background: 'white'}}
          />
        </div>
      },
    ];

    const { close } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="rider-Dialog2">
          <div className="rider-content2" style={{ padding: '50px' }}>
            <div className="rider-title2">0콜 사유 전체 내역</div>
            <img
              onClick={close}
              src={CloseIcon}
              className="rider-close2"
              alt="close"
            />
            <div style={{ fontSize: '18px' }}>가맹점: {this.props.frName}</div>
            <div className="listBlock">
              <Table
                dataSource={this.state.list}
                columns={columns}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  branchIdx: state.login.loginInfo.branchIdx,
});

export default connect(mapStateToProps)(ZeroCallFrMemoHistory);
