import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { httpGet, httpPost, httpUrl } from '../../../api/httpClient';
import CloseIcon from '../../../img/login/close.png';
import {comma} from '../../../lib/util/numberUtil';
import {getOrderShareViewInfo,setOrderShareViewInfo} from '../../../lib/util/orderShareUtil';
import { reactLocalStorage } from 'reactjs-localstorage';

const OrderShareViewDialog = (props) => {
  const [shared, setShared] = useState([]);
  const [selected, setSelected] = useState([]);

  const branchIdx = useSelector((state) => state.login.loginInfo.branchIdx);
  useEffect(async () => {
    const companyId = props.companyId;
    try {
      const res = await getOrderShareViewInfo(branchIdx, true)
      setShared(res.share);
      setSelected(res.view);
      
    console.log('*******res.view*********')
    console.log(res.view)
    } catch (e) {
      Modal.warn({
        title: companyId !== 'connect9' ? '지사조회실패' : '지점조회실패',
        content:
          companyId !== 'connect9'
            ? '지사 정보를 조회하는데 실패했습니다.'
            : '지점 정보를 조회하는데 실패했습니다.',
      });
    }
  }, []);

  const handleToggle = (branchIdx) => {
    if (selected.includes(branchIdx)) {
      setSelected(selected.filter((idx) => branchIdx !== idx));
    } else {
      setSelected(selected.concat(branchIdx));
    }
  };

  const submitShare = async () => {
    await setOrderShareViewInfo(selected);
    if (global.getOrderList) global.getOrderList();
    props.close();
    // const companyId = props.companyId;
    // try {
    //   const res = await httpPost(
    //     httpUrl.setBranchOrderShare,
    //     [],
    //     selectedBranches
    //   );
    //   if (res.data === 'SUCCESS') {
    //     Modal.info({
    //       title: '등록 성공',
    //       content:
    //         companyId !== 'connect9'
    //           ? '지사 주문 공유 설정에 성공했습니다.'
    //           : '지점 주문 공유 설정에 성공했습니다.',
    //     });
    //     props.close();
    //   }
    // } catch (e) {
    //   console.error(e);
    //   Modal.warn({
    //     title: '등록 실패',
    //     content:
    //       companyId !== 'connect9'
    //         ? '지사 주문 공유 설정에 실패했습니다.'
    //         : '지점 주문 공유 설정에 실패했습니다.',
    //   });
    // }
  };
  return (
    <>
      <div className="flex-modal-overlay">
        <div onClick={(e) => e.stopPropagation()} className={'flex-wrapper'}>
          <div className="flex-header">
            <div className="flex-title">공유콜 보기 설정</div>
            <img
              onClick={props.close}
              src={CloseIcon}
              className="dialog-close"
              alt="닫기"
            />
          </div>
          <div className="flex-title">* 아래 공유된 지점을 클릭하여 설정을 활성화 하면 접수목록에서 해당 지점의 콜이 같이 조회됩니다.</div>
          <div className="flex-inner flex-wrap">
            {shared.map((branch) => {
              if (branchIdx === branch.idx) return <></>;
              return (
                <div
                  key={branch.idx}
                  className={
                    selected.includes(branch.idx)
                      ? 'order-share-icon-on'
                      : 'order-share-icon-off'
                  }
                  onClick={() => handleToggle(branch.idx)}>
                    {branch.branchName}
                </div>
              );
            })}
          </div>

          <div className="share-btn">
            <Button type="primary" onClick={() => submitShare()}>
              적용하기
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderShareViewDialog;
