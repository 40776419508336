import { Button, Checkbox, Form, Input, Modal, Table, Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPost, httpUrl } from '../../../api/httpClient';
import {
  blindComplete,
  blindError,
  unBlindAgree,
  unBlindAgreeError,
  unBlindComplete,
  unBlindDeny,
  unBlindError,
} from '../../../api/Modals';
import SelectBox from '../../../components/input/SelectBox';
import '../../../css/modal.css';
import '../../../css/modal_m.css';
import { blockStatusString, blockString, frRiderString } from '../../../lib/util/codeUtil';
import { formatDate } from '../../../lib/util/dateUtil';
import SearchRiderDialog from '../../dialog/common/SearchRiderDialog';
import CloseIcon from '../../../img/login/close.png';
import SearchFranchiseToRiderDialog from '../common/SearchFranchiseToRiderDialog';
import { reactLocalStorage } from 'reactjs-localstorage';
import Const from '../../../const';
import SearchFranchiseDialog from '../common/SearchFranchiseDialog';

const FormItem = Form.Item;
const Option = Select.Option;

class BlindFranListDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      deletedCheck: false,
      selectedRider: null,
      selectedFr: this.props.data,
      searchRiderOpen: false,
      searchFranchiseOpen: false,
      blindStatus: 0,
      branchNameList: {},
      branchNameValue: 0,
    };
    this.formRef = React.createRef();
    this.AllblindformRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
    this.getBranchInfo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.getList();
    }
  }

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  getList = () => {
    let { data } = this.props;
    let frIdx = data.idx;
    httpPost(httpUrl.blindList, [], {
      frIdx: frIdx,
      pageNum: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      deletedList: this.state.deletedCheck !== true ? [0] : [0, 1],
      // direction: [2]
    }).then((res) => {
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.riderFrBlocks,
        });
      } else {
        Modal.info({
          title: '목록 에러',
          content: <div>에러가 발생하여 목록을 불러올수 없습니다.</div>,
        });
      }
    });
  };

  getBranchInfo = async () => {
    let branchNameList = this.state.branchNameList;
    try {
      httpGet(httpUrl.branchListInfo, [], {}).then((res) => {
        if (res.result === 'SUCCESS') {
          console.log('res');
          console.log(res.data.branches);
          res.data.branches.map((item) => (branchNameList[item.IDX] = item.BRANCH_NAME));
          this.setState({
            branchNameList: branchNameList,
          });
        }
      });
    } catch (error) {
      console.warn('getBranchInfo error: {}', error);
    }
  };

  handleSubmit = () => {
    const form = this.formRef.current;
    let self = this;
    Modal.confirm({
      title: '차단 등록',
      content: '새로운 차단을 등록하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        console.log({
          direction: 2,
          deleted: false,
          frIdx: self.state.selectedFr.idx,
          memo: form.getFieldValue('memo'),
          riderIdx: self.state.selectedRider.idx,
        });
        httpPost(httpUrl.registBlind, [], {
          direction: 2,
          deleted: false,
          frIdx: self.state.selectedFr.idx,
          memo: form.getFieldValue('memo'),
          riderIdx: self.state.selectedRider.idx,
        })
          .then((result) => {
            if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
              blindComplete();
              self.handleClear();
              // self.getList();
              self.setState(
                {
                  pagination: {
                    current: 1,
                    pageSize: 5,
                  },
                },
                () => self.getList()
              );
            } else {
              blindError();
            }
          })
          .catch((e) => {
            blindError();
          });
      },
    });
  };

  handleAllSubmit = () => {
    const form = this.AllblindformRef.current;
    let self = this;
    Modal.confirm({
      title: '일괄 차단 등록',
      content: '새로운 일괄 차단을 등록하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.registAllRiderBlind, [], {
          direction: 2,
          branchIdx: form.getFieldValue('branchIdx'),
          frIdx: self.state.selectedFr.idx,
          memo: form.getFieldValue('memo'),
        })
          .then((result) => {
            if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
              blindComplete();
              self.handleAllClear();
              self.setState(
                {
                  pagination: {
                    current: 1,
                    pageSize: 5,
                  },
                },
                () => self.getList()
              );
            } else {
              blindError();
            }
          })
          .catch((e) => {
            blindError();
          });
      },
    });
  };

  handleClear = () => {
    this.formRef.current.resetFields();
    this.setState({
      selectedRider: null,
    });
  };

  handleAllClear = () => {
    this.AllblindformRef.current.resetFields();
    this.setState({
      branchIdx: null,
      memo: null,
    });
  };

  onDeleteCheck = (e) => {
    this.setState({ deletedCheck: e.target.checked }, () => {
      this.getList();
    });
  };

  onDelete = (idx, deleted) => {
    let self = this;
    if (deleted !== true) {
      Modal.confirm({
        title: '차단 해제',
        content: '차단을 해제하시겠습니까?',
        okText: '확인',
        cancelText: '취소',
        onOk() {
          httpPost(httpUrl.deleteBlind, [], {
            idx: idx,
          })
            .then((result) => {
              if (result.result === 'SUCCESS') {
                unBlindComplete();
                self.getList();
              } else {
                unBlindError();
              }
              self.getList();
            })
            .catch((e) => {
              unBlindError();
            });
        },
      });
    } else {
      unBlindError();
    }
  };

  // 승인대기 or 승인거부로 상태변경
  onChangeDeletedStatus = (idx, value) => {
    let self = this;
    if (parseInt(value) === 2) {
      Modal.confirm({
        title: '승인 완료',
        content: '해당 블라인드를 승인하시겠습니까?',
        okText: '확인',
        cancelText: '취소',
        onOk() {
          httpPost(httpUrl.statusBlind, [], {
            idx: idx,
            status: 2,
          })
            .then((result) => {
              if (result.result === 'SUCCESS') {
                unBlindAgree();
                self.getList();
              } else {
                unBlindAgreeError();
              }
              self.getList();
            })
            .catch((e) => {
              unBlindAgreeError();
            });
        },
      });
    } else {
      Modal.confirm({
        title: '승인 거부',
        content: '해당 블라인드 승인을 거부하시겠습니까?',
        okText: '확인',
        cancelText: '취소',
        onOk() {
          httpPost(httpUrl.statusBlind, [], {
            idx: idx,
            status: 3,
          })
            .then((result) => {
              if (result.result === 'SUCCESS') {
                httpPost(httpUrl.deleteBlind, [], {
                  idx: idx,
                }).then(() => {
                  unBlindDeny();
                  self.getList();
                });
              } else {
                unBlindAgreeError();
              }
              self.getList();
            })
            .catch((e) => {
              unBlindAgreeError();
            });
        },
      });
    }
  };

  // 가맹점조회 dialog
  // openSearchFranchiseModal = () => {
  //     this.setState({ searchFranchiseOpen: true });
  // };
  // closeSearchFranchiseModal = () => {
  //     this.setState({ searchFranchiseOpen: false });
  // };

  // 기사조회 dialog
  openSearchRiderModal = () => {
    this.setState({ searchRiderOpen: true });
  };
  closeSearchRiderModal = () => {
    this.setState({ searchRiderOpen: false });
  };

  handleClose = (data) => {
    this.setState({ selectedRider: data });
    this.formRef.current.setFieldsValue({ riderName: data.riderName });
  };

  handleSelect = (e) => {
    console.log(e.target.value);
  };

  render() {
    const columns = [
      {
        title: '블라인드 정보',
        dataIndex: 'direction',
        className: 'table-column-center mobile',
        render: (data, row) => (
          <div>
            <b>차단자:</b>{' '}
            {row.direction === 1 ? (
              <div style={{ color: 'blue', fontWeight: 'bold' }}>기사</div>
            ) : (
              <div style={{ color: 'red', fontWeight: 'bold' }}>가맹점</div>
            )}
            <b>가맹점명:</b> {row.frName}
            <br />
            <b>기사명:</b> {row.frName}
            <br />
            <b>차단메모:</b> {row.frName}
            <br />
            <b>설정일:</b> {formatDate(row.createDate)}
            <br />
            <b>해제일:</b> {formatDate(row.endDate)}
            <br />
            <b>상태:</b>{' '}
            {row.status !== 1
              ? row.status !== true
                ? blockString[1]
                : blockString[2]
              : row.status === 1 && row.status == true
              ? blockString[4]
              : blockString[0]}
            <br />
            <b>처리:</b>
            {row.status === 1 && row.direction === 1 && row.deleted !== true && (
              <div>
                <SelectBox
                  value={blockStatusString[data]}
                  code={Object.keys(blockStatusString)}
                  codeString={blockStatusString}
                  placeholder={'승인대기'}
                  onChange={(value) => {
                    console.log(value, row.status);
                    if (value !== row.status.toString()) {
                      this.onChangeDeletedStatus(row.idx, value);
                    }
                  }}
                />
              </div>
            )}
            {row.status === 2 && (
              <div>
                {row.deleted !== true ? (
                  <Button
                    onClick={(value) => {
                      if (parseInt(value) !== row.deleted) {
                        this.onDelete(row.idx, row.deleted);
                      }
                    }}>
                    해제
                  </Button>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
        ),
      }, //모바일
      {
        title: '차단자',
        dataIndex: 'direction',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div>
            {data === 1 ? (
              <div style={{ color: 'blue', fontWeight: 'bold' }}>기사</div>
            ) : (
              <div style={{ color: 'red', fontWeight: 'bold' }}>가맹점</div>
            )}
          </div>
        ),
      },
      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center desk',
        render: (data) => <div className="elipsis-table-row">{data}</div>,
      },
      {
        title: '기사명',
        dataIndex: 'riderName',
        className: 'table-column-center desk',
      },
      {
        title: '차단메모',
        dataIndex: 'memo',
        className: 'table-column-center desk',
        render: (data) => <div className="elipsis-table-row">{data}</div>,
      },
      {
        title: '설정일',
        dataIndex: 'createDate',
        className: 'table-column-center desk',
        render: (data) => <div>{formatDate(data)}</div>,
      },
      {
        title: '해제일',
        dataIndex: 'deleteDate',
        className: 'table-column-center desk',
        render: (data) => <div>{formatDate(data)}</div>,
      },
      {
        title: '상태',
        dataIndex: 'deleted',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div>
            {row.status !== 1
              ? data !== true
                ? blockString[1]
                : blockString[2]
              : row.status === 1 && data == true
              ? blockString[4]
              : blockString[0]}
          </div>
        ),
      },
      {
        title: '처리',
        className: 'table-column-center desk',
        render: (data, row) => (
          <>
            {/* {console.log(row.idx)} */}
            {row.status === 1 && row.direction === 1 && row.deleted !== true && (
              <div>
                <SelectBox
                  value={blockStatusString[data]}
                  code={Object.keys(blockStatusString)}
                  codeString={blockStatusString}
                  placeholder={'승인대기'}
                  onChange={(value) => {
                    console.log(value, row.status);
                    if (value !== row.status.toString()) {
                      this.onChangeDeletedStatus(row.idx, value);
                    }
                  }}
                />
              </div>
            )}

            {row.status === 2 && (
              <div>
                {row.deleted !== true ? (
                  <Button
                    onClick={(value) => {
                      if (parseInt(value) !== row.deleted) {
                        this.onDelete(row.idx, row.deleted);
                      }
                    }}>
                    해제
                  </Button>
                ) : (
                  ''
                )}
              </div>
            )}
          </>
        ),
      },
    ];

    const { close, data } = this.props;
    const { branchNameList } = this.state;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="blind-Dialog">
          <div className="blind-container">
            <div className="blind-title desk">{data.frName} 가맹점의 블라인드 목록</div>

            <div className="desk">
              <Checkbox
                style={{ marginRight: 5, marginLeft: 20 }}
                defaultChecked={this.state.deletedCheck ? 'checked' : ''}
                onChange={this.onDeleteCheck}
              />
              해제 포함
            </div>
            <img onClick={close} src={CloseIcon} className="blind-close desk" alt="close" />

            <div className="riderBlind-box mobile">
              <div className="blind-title">
                {data.frName} 님의 <br />
                블라인드 목록
              </div>
              <div>
                <div>
                  <img onClick={close} src={CloseIcon} className="blind-close" alt="닫기" />
                </div>
                <div>
                  <Checkbox
                    className="riderBlind-checkbox"
                    style={{ marginRight: 5, marginTop: 5 }}
                    defaultChecked={this.state.deletedCheck ? 'checked' : ''}
                    onChange={this.onDeleteCheck}
                  />
                  해제 포함
                </div>
              </div>
            </div>

            <div className="blindLayout">
              <div className="listBlock">
                <Table
                  // rowKey={(record) => record.idx}
                  dataSource={this.state.list}
                  columns={columns}
                  pagination={this.state.pagination}
                  onChange={this.handleTableChange}
                />
              </div>
            </div>
            <div className="w-full mt-[15px]">
              <Form className="flex" ref={this.formRef} onFinish={this.handleSubmit}>
                <div className="w-full">
                  <div className="flex items-center mb-[15px]">
                    <div className="w-[70px]">차단자</div>
                    <div className="w-[260px]">
                      <Input className="cursor-default" placeholder="" value={'가맹점'} readOnly={true} />
                    </div>
                  </div>
                  <div className="flex items-center mb-[15px]">
                    <div className="w-[70px]">기사명</div>
                    <div className="flex w-[260px]">
                      {this.state.searchRiderOpen && (
                        <SearchFranchiseToRiderDialog
                          close={this.closeSearchRiderModal}
                          callback={this.handleClose}
                          isSelectBox={true}
                        />
                      )}
                      <FormItem className="w-[260px] m-0" name="riderName">
                        <Input placeholder="기사명 입력" className="flex" required />
                      </FormItem>
                      <div>
                        <Button onClick={this.openSearchRiderModal}>조회</Button>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center mb-[15px]">
                    <div className="w-[70px]">메모</div>
                    <FormItem name="memo" className="w-[260px] m-0">
                      <Input className="" placeholder="차단 메모를 입력해주세요." />
                    </FormItem>
                  </div>
                </div>
                <div>
                  <Button type="primary" htmlType="submit">
                    차단등록
                  </Button>
                </div>
              </Form>
            </div>

            {/*일괄기사블라인드 추가 hms*/}

            <div className="mt-15">
              <div className="w-full mt-[50px]">
                <Form className="flex" ref={this.AllblindformRef} onFinish={this.handleAllSubmit}>
                  <div className="w-full">
                    <div className="flex items-center mb-[15px]">
                      <div className="w-[70px]">일괄차단</div>
                      <div className="w-[260px]">
                        <FormItem
                          name="branchIdx"
                          className="m-0"
                          rules={[{ required: true, message: '지점명을 선택해주세요.' }]}>
                          <SelectBox
                            style={{ width: '100%' }}
                            placeholder={'지점 선택'}
                            value={branchNameList[this.state.branchNameValue]}
                            code={Object.keys(branchNameList)}
                            codeString={branchNameList}
                            onChange={(value) => {
                              if (parseInt(value) !== this.state.branchNameValue) {
                                this.setState({ branchNameValue: parseInt(value) });
                              }
                            }}
                          />
                        </FormItem>
                      </div>
                    </div>
                    <div className="flex items-center mb-[15px]">
                      <div className="w-[70px]">메모</div>
                      <FormItem name="memo" className="w-[260px] m-0">
                        <Input className="" placeholder="차단 메모를 입력해주세요." />
                      </FormItem>
                    </div>
                  </div>
                  <div>
                    <Button type="primary" htmlType="submit">
                      일괄등록
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginInfo: state.login.loginInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BlindFranListDialog);
