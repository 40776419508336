import Axios from 'axios';
import { reactLocalStorage } from 'reactjs-localstorage';
import util from 'util';
import Const from '../const';

let loadingCount = 0;

global.language = 'ko';
global.lanList = ['ko', 'en', 'ja', 'zh'];

const serverUrl = Const.serverProtocol + '://' + Const.serverIp + ':' + Const.serverPort;

const userInfo = reactLocalStorage.getObject(Const.appName + '#adminUser');

const makeUrl = (url, params) => {
  var result = serverUrl + url;
  if (params === null) return result;
  params.forEach((param) => {
    result = util.format(result, param);
  });
  return result;
};

const httpExecWithNoLoading = (method, url, data) => {
  return new Promise((resolve, reject) => {
    // api 응답시간 측정
    // console.log(url + " req");
    // const start = new Date();

    Axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Branch-Idx': userInfo.branchIdx,
        'User-Idx': userInfo.idx,
      },
    })
      .then((response) => {
        // api 응답시간 측정
        // console.log(url + " res");
        // const end = new Date();
        // console.log(url + " : response time (ms) : " + String(end - start));

        resolve(response.data);
      })
      .catch((error) => {
        if (error.message.includes('401')) {
          // alert("로그인이 만료되었습니다. 다시 로그인해주세요");
          reactLocalStorage.remove('adminUser');
          global.location.href = '/';
        }
        reject(error);
      });
  });
};

const httpExec = (method, url, data) => {
  const headers =
    userInfo && userInfo.id
      ? {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Branch-Idx': userInfo.branchIdx,
          'User-Idx': userInfo.idx,
        }
      : {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        };
  loadingCount++;
  if (loadingCount === 1) global.document.getElementById('loadingSpinner').style.display = 'block';
  // console.log("spinner start");
  const startSpinner = new Date();

  return new Promise((resolve, reject) => {
    // api 응답시간 측정
    // console.log(url + " req");
    // const start = new Date();

    Axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
      headers,
    })
      .then((response) => {
        // if (
        //   url === serverUrl + httpUrl.login ||
        //   url === serverUrl + httpUrl.logout
        // ) {
        // } else {
        //   if (
        //     (method === 'PUT' || method === 'POST' || method === 'DELETE') &&
        //     response.data.result === 'SUCCESS'
        //   ) {
        //     alert('완료');
        //   }
        // }
        // api 응답시간 측정
        // console.log(url + " res");
        // const end = new Date();
        // console.log(url + " : response time (ms) : " + String(end - start));

        resolve(response.data);
        loadingCount--;
        if (loadingCount === 0) global.document.getElementById('loadingSpinner').style.display = 'none';
        // console.log("spinner end");
        const endSpinner = new Date();
        console.log(endSpinner - startSpinner);
      })
      .catch((error) => {
        // console.log(JSON.stringify(error, null, 4));
        if (error.message.includes('401')) {
          // alert("로그인이 만료되었습니다. 다시 로그인해주세요");
          reactLocalStorage.remove('adminUser');
          global.location.href = '/';
        }
        // if (error.response.data.message === 'E10003') {
        //   if (!isAlertOpened) {
        //     isAlertOpened = true;
        //     alert('장기간 미사용으로 자동 로그아웃 되었습니다.');
        //     global.location.href = '/';
        //   }
        // } else if (error.response.data.data === 'E10003') {
        //   if (!isAlertOpened) {
        //     isAlertOpened = true;
        //     alert('접근 권한이 없습니다. 로그인 해주세요.');
        //     global.location.href = '/';
        //   }
        // }
        // alert(JSON.stringify(error));
        reject(error);
        loadingCount--;
        if (loadingCount === 0) global.document.getElementById('loadingSpinner').style.display = 'none';
      });
  });
};

const httpExecMultiPartReq = (method, url, data) => {
  loadingCount++;
  if (loadingCount === 1) global.document.getElementById('loadingSpinner').style.display = 'block';

  return new Promise((resolve, reject) => {
    console.log(method, url, data);
    Axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        resolve(response.data);
        loadingCount--;
        if (loadingCount === 0) global.document.getElementById('loadingSpinner').style.display = 'none';
      })
      .catch((error) => {
        // console.log(JSON.stringify(error, null, 4));
        if (error.message.includes('401')) {
          // alert("로그인이 만료되었습니다. 다시 로그인해주세요");
          reactLocalStorage.remove('adminUser');
          global.location.href = '/';
        }
        // if (error.response.data.message === 'E10003') {
        //   if (!isAlertOpened) {
        //     isAlertOpened = true;
        //     alert('장기간 미사용으로 자동 로그아웃 되었습니다.');
        //     global.location.href = '/';
        //   }
        // } else if (error.response.data.data === 'E10003') {
        //   if (!isAlertOpened) {
        //     isAlertOpened = true;
        //     alert('접근 권한이 없습니다. 로그인 해주세요.');
        //     global.location.href = '/';
        //   }
        // }
        // alert(JSON.stringify(error));
        reject(error);
        loadingCount--;
        if (loadingCount === 0) global.document.getElementById('loadingSpinner').style.display = 'none';
      });
  });
};

const httpGet = (url, params, data) => {
  console.log(makeUrl(url, params));
  return httpExec('GET', makeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.get(makeUrl(url, params), data)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
};

const httpGetRenewMakeUrl = (url, params, data) => {
  console.log(renewMakeUrl(url, params));
  return httpExec('GET', renewMakeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.get(makeUrl(url, params), data)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
};

const renewMakeUrl = (url, params) => {
  var result = serverUrl + url;
  Object.keys(params).forEach((key, index) => {
    if (index === 0) {
      result += '?' + key + '=' + params[key];
    } else {
      result += '&' + key + '=' + params[key];
    }
  });
  return result;
};

const httpPut = (url, params, data) => {
  return httpExec('PUT', makeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.put(makeUrl(url, params), data)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
};

const httpDelete = (url, params, data) => {
  return httpExec('DELETE', makeUrl(url, params), data);
};

const httpPost = (url, params, data) => {
  // console.log(makeUrl(url, params));
  return httpExec('POST', makeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.post(makeUrl(url, params), data)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
};

const httpPostMultiPartReq = (url, params, data) => {
  // console.log(makeUrl(url, params));
  return httpExecMultiPartReq('POST', makeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.post(makeUrl(url, params), data)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
};

const httpPostWithNoLoading = (url, params, data) => {
  console.log(makeUrl(url, params));
  return httpExecWithNoLoading('POST', makeUrl(url, params), data);
};
const httpGetWithNoLoading = (url, params, data) => {
  console.log(makeUrl(url, params));
  return httpExecWithNoLoading('GET', makeUrl(url, params), data);
};

const httpDownload = (url, params, data) => {
  // return httpExec('GET', makeUrl(url, params), data);
  return new Promise((resolve, reject) => {
    Axios({
      method: 'GET',
      url: makeUrl(url, params),
      data: data,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        branchIdx: userInfo && userInfo.branchIdx,
        userIdx: userInfo && userInfo.idx,
      },
      responseType: 'arraybuffer',
    })
      .then((response) => {
        var blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        resolve(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const httpFileUpload = async (file, hideLoading) => {
  if (
    file === null ||
    typeof file !== 'object' ||
    typeof file.name === 'undefined' ||
    typeof file.type === 'undefined' ||
    typeof file.uri === 'undefined'
  ) {
    return {
      result: 'INVALID_FILE',
      message: '',
    };
  }
  const url = makeUrl(httpUrl.fileUpload);
  try {
    const formData = new FormData();
    formData.append('file', file);
    const res = await fetch(url, {
      method: 'post',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data; ',
        branchIdx: userInfo && userInfo.branchIdx,
        userIdx: userInfo && userInfo.idx,
      },
    });
    return await res.json();
  } catch (e) {
    console.warn(`httpFileUpload, raised exception. e: ${e}, url: ${url}, file: ${JSON.stringify(file)}`);
    return {
      result: 'EXCEPTION',
      message: e,
    };
  } finally {
  }
};

const imageUrl = (idx) => {
  return serverUrl + '/file/' + idx;
};

const invokeFunction = (func, background) => {
  if (!background) {
    loadingCount++;
    if (loadingCount === 1) global.document.getElementById('loadingSpinner').style.display = 'block';
  }

  const startSpinner = new Date();
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        if (func) {
          const result = func();
          resolve(result);
        } else {
          resolve();
        }
      } catch (e) {
        reject(e);
      }

      if (!background) {
        loadingCount--;
        if (loadingCount === 0) global.document.getElementById('loadingSpinner').style.display = 'none';
      }

      const endSpinner = new Date();
      console.log('invokeFunction time: ', endSpinner - startSpinner);
    }, 10);
  });
};

const httpUrl = {
  login: '/login',
  logout: '/logout',
  getUserInfo: '/loginInfo',

  // 접수 현황
  receptionStatusList: '/order/assignList?pageSize=%s&pageNum=%s',

  // 주문 현황
  orderList: '/order/list',
  orderUpdate: '/order/update',
  orderCreate: '/order/create',
  orderComplete: '/order/complete',
  getDeliveryPrice: '/fr/expectDeliveryPrice?frIdx=%s&destLatitude=%s&destLongitude=%s',
  completedCount: '/order/count/completed?selectedDate=%s',
  canceledCount: '/order/count/canceled?selectedDate=%s',
  getOrderInfo: '/order/%d',
  orderUpdateWaitingStatus: '/order/update/waitingStatus',

  // 주문 상태 변경
  orderAssignCancel: '/order/admin/admin/assignRiderCancel',
  orderPickup: '/order/pickup',
  orderPickupCancel: '/order/pickupCancel',
  orderCancel: '/order/cancel',
  orderCompleteRestore: '/order/complete/restore',
  orderCancelRestore: '/order/cancel/restore',
  orderStats: '/order/totalCallList?dateRange=%s&userIdx=%s&pageNum=%s&pageSize=%s&startDate=%s&endDate=%s',
  orderChartStats: '/order/totalCallChartList?dateRange=%s&userIdx=%s&pageNum=%s&pageSize=%s&startDate=%s&endDate=%s',
  orderRiderStats: '/order/totalCallRiderList?dateRange=%s&userIdx=%s&pageNum=%s&pageSize=%s&startDate=%s&endDate=%s',
  orderRiderChartStats:
    '/order/totalCallRiderChartList?dateRange=%s&userIdx=%s&pageNum=%s&pageSize=%s&startDate=%s&endDate=%s',
  frOrderStats: '/order/franchise/stat',
  riderOrderStats: '/order/rider/stat',
  frOrderStatsForExcel: '/order/franchise/stat/excel',
  riderOrderStatsForExcel: '/order/rider/stat/excel',

  priceExtraList: '/branch/deliveryPriceExtra/list?pageNum=%s&pageSize=%s',
  priceExtraRegist: '/branch/deliveryPriceExtra/create',
  priceExtraUpdate: '/branch/deliveryPriceExtra/update',
  priceExtraDelete: '/branch/deliveryPriceExtra/delete/{idx}?idx=%s',
  priceExtraGroupList: '/fr/settingGroup/list',
  priceExtraRegistGroup: '/fr/settingGroup/create',
  priceExtraDeleteGroup: '/fr/settingGroup/delete',

  // 직원 관리
  staffList: '/rider/list?pageSize=%s&pageNum=%s&riderLevels=%s&userStatus=%s',
  registStaff: '/rider/create',
  staffUpdate: '/rider/update',

  // 라이더 위치
  getGeocode: '/geometry/geocode/%s',

  // 예상 배달요금
  expectDeliveryPrice: '/fr/expectDeliveryPrice?destLatitude=%s&destLongitude=%s',

  // rider
  riderTotalList: '/rider/list',
  // riderList:
  //   '/rider/list?pageSize=%s&pageNum=%s&searchName=%s&userStatus=%s&riderLevels=%s&riderStatus=%s&approvedCode=%s',
  riderList:
    '/rider/list?pageSize=%s&pageNum=%s&searchName=%s&userStatus=%s&riderLevels=%s&riderStatus=%s&approvedCode=%s&allbranchStatus=%s',
  riderShareList:
    '/rider/sharelist?pageSize=%s&pageNum=%s&searchName=%s&userStatus=%s&riderLevels=%s&riderStatus=%s&approvedCode=%s',
  riderAssignShareList:
    '/rider/assign/share/list?pageSize=%s&pageNum=%s&searchName=%s&userStatus=%s&riderLevels=%s&riderStatus=%s&approvedCode=%s',
  riderListRedis: '/rider/list/redis',
  getRiderStatusList: '/rider/status/count',
  getRiderSettingGroupCount: '/rider/riderSettingGroup/count',
  registRider: '/rider/create',
  updateRider: '/rider/update',
  riderListOne: '/rider/list',
  riderLocateList: '/rider/location/list',
  riderLocate: '/rider/location/%s',
  riderBatchWorkList: '/rider/batchWork/list',
  riderBatchWorkCreate: '/rider/batchWork/create',
  riderBatchWorkUpdate: '/rider/batchWork/update',
  riderBatchWorkDelete: '/rider/batchWork/delete',
  userBatchWorkCreate: '/rider/userBatchWork/create',
  userBatchWorkDelete: '/rider/userBatchWork/delete',
  riderBatchWorkDailyList:
    '/rider/daily/list?kind=%s&pageNum=%s&pageSize=%s&searchName=%s&startDate=%s&endDate=%s&userId=%s',
  riderBatchWorkDailyListForExcel: '/rider/daily/list/excel?kind=%s&searchName=%s&startDate=%s&endDate=%s&userId=%s',
  clearPassword: '/user/clearPassword',
  clearWithdrawPassword: '/rider/clear/withdraw-password',
  clearSignupDeviceId: '/user/clear/signupDeviceId',
  ncashList: '/ncash/list?category=%s&pageNum=%s&pageSize=%s&userIdx=%s&startDate=%s&endDate=%s',
  riderUploadFile: '/rider/upload/file',
  noDriverLicenseApprove: '/rider/update/licenseApprove?noLicenseApprove=%s&idx=%s',
  riderJoinInsurance: '/rider/accident-insurance/join',
  riderCancelInsurance: '/rider/accident-insurance/cancel',
  getRiderInsurance: '/rider/get/accident-insurance?userIdx=%s',
  getRiderBikeNumber: '/rider/get/bikeNumber?userIdx=%s',
  getRiderInfo: '/rider/%d',
  riderListWithSort:
    '/rider/listWithSort?pageSize=%s&pageNum=%s&searchName=%s&userStatus=%s&riderLevels=%s&riderStatus=%s&approvedCode=%s&allbranchStatus=%s&sort=%s',

  // 배차
  assignRiderAdmin: '/order/admin/assignRider',
  assignRider: '/order/assignRider',
  assignRiderCancel: '/order/admin/admin/assignRiderCancel',
  getAssignedRider: '/order/assignList',

  // 가맹점 관리
  registFranchise: '/fr/create',
  franchiseList: '/fr/list',
  franchiseUpdate: '/fr/update',
  frAccountCreate: '/fr/account/create',
  frAccountModify: '/fr/account/modify',
  duesExceptionList: '/fr/nyamnyamApproval/list?userIdx=%s',
  duesExceptionCreate: '/fr/nyamnyamApproval/create',
  // duesExceptionUpdate: "/fr/nyamnyamApproval/update",
  updateBank: '/user/update/bank',
  getFrInfo: '/fr/%d',
  franchiseListWithSort: '/fr/listWithSort',

  // 지점
  getBranch: '/branch/%s',
  branchList: '/branch/list?pageNum=%s&pageSize=%s',
  updateBranch: '/branch/update',
  getBranchOrderShare: '/branch/order/share/%s',
  getBranchOrderShareByTarget: '/branch/order/share/target/%s',
  setBranchOrderShare: '/branch/set/order/share',
  getBranchAssignShare: '/branch/assign/share/%s',
  setBranchAssignShare: '/branch/set/assign/share',
  setShowCount: '/branch/set/showCount',
  searchFrByBranchSub: '/branch/searchFrByBranchSub?branchSubIdxes=%s',
  getBranchSubName: '/branch/getBranchSubName?branchIdx=%s',
  changeBranchTel: '/branch/changeBranchTel',
  changeBranchTime: '/branch/changeBranchTime',
  changeBranchSetting: '/branch/changeBranchSetting',
  getCompanyIdInfo: '/branch/get/companyIdInfo/%s',
  branchListInfo: '/branch/getBranchListInfo',

  // 공지사항
  noticeList: '/notice/list?deleted=%s&pageNum=%s&pageSize=%s&category=%s',
  registNotice: '/notice/create',
  updateNotice: '/notice/update',
  specificNoticeList: '/notice/%s',
  smsSendFran: '/sms/all/create',
  nextNotice: '/notice/next?category=%s&noticeIdx=%s',
  prevNotice: '/notice/prev?category=%s&noticeIdx=%s',

  // 블라인드
  blindList: '/rider/admin/block/list',
  registBlind: '/rider/admin/block/create',
  deleteBlind: '/rider/admin/block/delete',
  statusBlind: '/rider/block/update',
  blindAllList: '/rider/block/all/list?deletedList=%s&direction=%s&pageNum=%s&pageSize=%s',
  registAllBlind: '/rider/admin/block/all/create', //전체블라인드처리 추가 hms
  registAllRiderBlind: '/rider/admin/block/allcreate',

  // 채팅
  chatList: '/chat/chatList?pageSize=%s&pageNum=%s&searchName=%s',
  chatListByUser: '/chat/chatList?pageSize=%s&pageNum=%s&searchName=%s&receiveUserIdx=%s',
  chatMessageList: '/chat/messageList?pageSize=%s&pageNum=%s&chatRoomIdx=%s',
  chatSend: '/chat/send',
  chatRoom: '/chat/chatroom?receiveUserIdx=%s',

  // 주소검색관리
  getAddrAptList: '/order/addrAptList?pageNum=%s&pageSize=%s&searchDong=%s&searchString=%s',
  createAddrApt: '/branch/addrApt/create',
  deleteAddrApt: '/branch/addrApt/delete',
  updateAddrApt: '/branch/addrApt/update',

  // 패스워드 변경
  changePassword: '/user/changePassword',

  // 바이크
  createBike: '/bike/create',
  getBikeList: '/bike/list?modelName=%s&pageNum=%s&pageSize=%s',
  getBikeListNoModelName: '/bike/list?pageNum=%s&pageSize=%s',

  // 상품관리
  buyList: '/mall/buyList?branchIdx=%s&pageNum=%s&pageSize=%s',
  updateBuy: '/mall/buyProduct/update',

  // 라이더 전체메세지
  riderMessageAll: '/chat/send/allRider',

  // 기사그룹
  getRiderGroup: '/rider/settingGroup/list',
  updateRiderGroup: '/rider/settingGroup/update',
  createRiderGroup: '/rider/settingGroup/create',

  // PG연동
  createUserWallet: '/payment/wallet/create/user/%s',

  // 배송가능지역
  // getAddrBranch: "/branch/addrBranch/list?pageNum=%s&pageSize=%s",
  branchAddrDeliveryList: '/branch/addrDelivery/list?pageNum=%s&pageSize=%s',
  // updateAddrBranch: "/branch/addrBranch/update",
  updateAddrDelivery: '/branch/addrDelivery/update',

  // VAN등록요청 - 파일업로드
  FileUpload: '/file/upload',
  createVAN: '/van/create',
  updateVAN: '/van/update',
  uploadVAN: '/van/file/upload',
  vanFileView: '/van/file/view?userIdx=%s',
  deleteVANFile: '/van/file/delete',
  createVANWithUpload: '/van/createWithUpload',

  // 주문 로그
  getOrderLog: '/order/nyamnyamLog/list?orderIdx=%s&pageNum=%s&pageSize=%s',

  // 지역 할증
  getDeliveryPriceExtraRegion: '/branch/deliveryPriceRegion/list?pageNum=%s&pageSize=%s',
  createDeliveryPriceExtraRegion: '/branch/deliveryPriceRegion/create',
  toggleEnabledDeliveryPriceExtraRegion: '/branch/deliveryPriceRegion/enable/%s/%s',
  deleteDeliveryPriceExtraRegion: '/branch/deliveryPriceRegion/delete/%s',
  updateDeliveryPriceExtraRegion: '/branch/deliveryPriceRegion/update',
  copyRegionException: '/branch/copyRegionException',
  searchRegionException: '/branch/searchRegionException?frIdx=%s&branchIdx=%s&pageNum=%s&pageSize=%s',

  // 가맹점 접속상태 조회
  getFrStatusCount: '/fr/status',

  // 가맹점 메세지 그룹
  getMessageGroupList: '/fr/messageGroup/list',
  sendGroupMessage: '/chat/send/messageGroup',
  createMessageGroup: '/fr/create/messageGroup',
  deleteMessageGroup: '/fr/delete/messageGroup',
  getMessageGroupDetail: '/fr/messageGroup/%s',

  //비밀번호 변경
  setPassword: '/user/changePassword',

  getFrOrderAgency: '/fr/frOrderAgency/%s',
  getFrOrderAgencyList: '/fr/frOrderAgency/list/%s',
  deleteFrOrderAgency: '/fr/frOrderAgency/delete/%s',
  setFrOrderAgencyList: '/fr/frOrderAgency/set/%s',

  // 푸드테크 연동
  mapFrToFt: '/fr/map/ft',
  mapFrToFtBySelectedStore: '/fr/map/ft/store',
  getFtStore: '/fr/ft/store?businessNo=%s&keyword=%s',

  //주소 조회
  getAddress: '/address/getAddress?currentPage=%s&countPerPage=%s&keyword=%s',
  addrExList: '/order/addrAptList?pageSize=%d&pageNum=%d&searchDong=%s&searchString=%s',

  getDeliveryPriceRegionException: '/fr/deliveryPriceRegionException/list?deliveryPriceRegionIdx=%s',
  createDeliveryPriceRegionException: '/fr/deliveryPriceRegionException/create',
  deleteDeliveryPriceRegionException: '/fr/deliveryPriceRegionException/delete',
  addAllDeliveryPriceRegionException: '/fr/deliveryPriceRegionException/add/all',
  createDpreAll: '/fr/dpreAll/create',
  deleteDpreAll: '/fr/dpreAll/delete',

  selectCompleteOrder: '/order/completeOrder?orderIdx=%s',
  changeDeliveryPrice: '/order/change/deliveryPrice',

  setBranchOrderSort: '/branch/update/sortOrder',

  mapPaystoryVacnt: '/paystory/vacnt/map/%s',

  getRiderPaperList: '/rider/paper/list/%s',
  uploadRiderPaper: '/rider/paper/upload',
  riderPaperView: '/rider/paper/view?userIdx=%s&paperNo=%s',
  approveRiderPaper: '/rider/paper/approve',
  approveRiderPaperWithoutPaper: '/rider/paper/approve/manual',
  riderPaperDelete: '/rider/paper/delete',

  //계정휴면해제 220905 hms
  updateLoginDate: '/user/update/dormantRemovalId',
  enableWithdraw: '/user/enableWithdraw/%s',

  setAssignCancelSettings: '/branch/update/assignCancel/setting',
  getTodayPoopPoint: '/branch/todayPoopPoint/%s',
  updateTodayPoopPoint: '/branch/update/todayPoopPoint',

  getZeroFrList: '/fr/get/zeroCallFrList?branchIdx=%s',
  getZeroRiderList: '/rider/get/zeroCallRiderList?branchIdx=%s',
  insertZeroCallFrMemo: '/fr/insert/zeroCallFr',
  insertZeroCallRiderMemo: '/rider/insert/zeroCallRider',
  getZeroCallFrMemo: '/fr/get/zeroCallFrMemo?frIdx=%s',
  getZeroCallRiderMemo: '/rider/get/zeroCallRiderMemo?riderIdx=%s',

  get1000CallFranchiseList: '/fr/1000CallFranchiseList?pageSize=%s&pageNum=%s&searchString=%s',
  updateAddrDeliveryException: '/fr/addrDeliveryException/update',

  getDeliveryPriceBasicList: '/order/deliveryPriceBasic/list?pageSize=%s&pageNum=%s&searchString=%s',
  createDeliveryPriceBasicGroup: '/order/deliveryPriceBasic/group/create',
  updateDeliveryPriceBasicGroup: '/order/deliveryPriceBasic/group/update',
  deleteDeliveryPriceBasicGroup: '/order/deliveryPriceBasic/group',
  getBranchDpbnList: '/order/deliveryPriceBasicName/list',

  getRiderTeamList: '/rider/team/list',
  registRiderTeam: '/rider/team/regist',
  deleteRiderTeam: '/rider/team/delete',
  deleteOneRiderFromTeam: '/rider/team/deleteOneRider',
  updateTeamRider: '/rider/team/updateRider',
  updateTeamHead: '/rider/team/updateHead',

  // 가입/탈퇴 통계
  getJoinExitStatList: '/statistics/joinExitStat/getStatList?dateRange=%s&userType=%s&startDate=%s&endDate=%s',

  updateRedOrderShowSetting: '/branch/update/redOrderSetting',

  // 동별 요금제
  getDeliveryPriceDongRateList: '/fr/deliveryPriceDongRate/list',
  getDeliveryPriceDongRateAreaList: '/fr/deliveryPriceDongRateArea/list?dongRateIdx=%s',
  createDeliveryPriceDongRate: '/fr/deliveryPriceDongRate/create',
  copyDeliveryPriceDongRate: '/fr/deliveryPriceDongRate/copy',
  updateDeliveryPriceDongRate: '/fr/deliveryPriceDongRate/update',
  deleteDeliveryPriceDongRate: '/fr/deliveryPriceDongRate/delete',
  createDeliveryPriceDongRateArea: '/fr/deliveryPriceDongRateArea/create',
  updateDeliveryPriceDongRateArea: '/fr/deliveryPriceDongRateArea/update',

  // 상해보험 가입요청/해지요청 리스트
  getAccidentInsuranceList: '/rider/accident-insurance/list?pageNum=%s&pageSize=%s&requestType=%s&startDate=%s',

  ncashCompanyList:
    '/ncash/company/list?pageNum=%s&pageSize=%s&category=%s&category2=%s&kind=%s&startDate=%s&endDate=%s&withPagination=%s&dwFilter=%s&searchString=%s&branchIdx=%s',
  depositSend: '/ncash/create',
  getFrMisuList: '/ncash/misu/list?pageNum=%s&pageSize=%s&userType=%s&category2=%s&name=%s',
  subBranchList: '/branch/subBranchList',

  //아래로 변경후 폐기예정
  sendVerificationSms: '/sms/naverSms',
  checkVerificationCode: '/sms/checkCode',
  //이걸사용
  sendCertiSms: '/sms/send/code',
  checkCertiCode: '/sms/check/code',

  reqPaystoryWithdraw: '/paystory/transfer',

  controllerList: '/user/control/list',
  registController: '/user/control/create',
  updateController: '/user/control/update',

  // sms 전송
  sendNaverSms: '/sms/naverSms',
  checkCode: '/sms/checkCode',
  checkAlreadyVerified: '/sms/checkAlreadyVerified',
  smsRiderList: '/rider/list?pageSize=%s&pageNum=%s&searchName=%s',
  smsFrList: '/fr/list',
  reqWithdrawByAgency: '/user/reqWithdrawByAgency',
  getNcash: '/user/ncash',

  getUserInfoForUpdate: '/user/list?searchIdx=%s',
  //sdf
  getsettlementData: '/api/dashboard/statistics?targetDate=%s&companyId=%s',

  riderBankUpdate: '/user/bank/update',
  getDashboardData:
    '/ncash/dashboard/misuUserBatchWork/list?companyId=%s&endDate=%s&kind=%s&pageNum=%s&pageSize=%s&startDate=%s&type=%s&SearchName=%s',
  getDashboardBatchWorkData: '/ncash/dashboard/misuUserBatchWork?companyId=%s&endDate=%s&startDate=%s',
  downloadDashboardBatchWork:
    '/ncash/dashboard/misuUserBatchWork/list/excelDownload?companyId=%s&endDate=%s&startDate=%s',
  // 비욘드 연동
  mapFrToByd: '/fr/map/byd',
  mapFrToBydBySelectedStore: '/fr/map/byd/store',
  getHeadquarterBranchName: '/branch/getHeadquarterBranchName',
  sendAdminInfoAtMapControl: '/user/send/adminInfo',
};

const imageType = ['image/jpeg', 'image/png', 'image/bmp'];

export {
  httpDelete,
  httpDownload,
  httpExec,
  httpFileUpload,
  httpGet,
  httpGetRenewMakeUrl,
  httpGetWithNoLoading,
  httpPost,
  httpPostMultiPartReq,
  httpPostWithNoLoading,
  httpPut,
  httpUrl,
  imageType,
  imageUrl,
  invokeFunction,
  makeUrl,
  serverUrl,
};
