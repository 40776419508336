import moment from 'moment';

const comma = (a) => {
  if (!a) return 0;
  if (a === 0 || a === '0') return 0;
  var parts = a.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
};

const remainTime = (orderDate, arriveReqTime) => {
  const arriveReqDate = moment(orderDate).add(arriveReqTime, 'minutes');
  const time = arriveReqDate.diff(moment(), 'minutes');
  return time;
};

const accountFormat = (a) => {
  if (!a) return <div>-</div>;
  if (a === 0 || a === '0') return <div>-</div>;
  var parts = a.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return (
    <div style={{ color: a > 0 ? 'blue' : a < 0 ? 'red' : 'black' }}>
      {parts.join('.')}원
    </div>
  );
};

const sortDpbJson = (rawJson) => {
  try {
    const parsedJson = JSON.parse(rawJson);
    if (Array.isArray(parsedJson)) {
      parsedJson.sort((a, b) => {
        if (
          Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === 2 &&
          b.length === 2
        )
          return a[0] - b[0];
        else return false;
      });
      return JSON.stringify(parsedJson);
    } else return rawJson;
  } catch (e) {
    return rawJson;
  }
};

export { comma, remainTime, accountFormat, sortDpbJson };

