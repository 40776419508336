import React, { useRef } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { httpPost, httpUrl } from '../../../api/httpClient';
import { customAlert, customError } from '../../../api/Modals';

const DeliveryPriceDongRateRegisterGroupDialog = ({ close, data, isCopy }) => {
  console.log(data)
  const formRef = useRef();
  const handleClose = () => {
    close();
  };

  const handleSubmit = () => {
    if(data) {
      if (isCopy) {
        httpPost(httpUrl.copyDeliveryPriceDongRate, [], {
          name: formRef.current.getFieldsValue().name,
          idx: data.idx,
        })
            .then((res) => {
              if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
                customAlert('새로운 요금제가 등록되었습니다.');
              } else if (res.data === 'DUPLICATED') {
                Modal.info({
                  title: '등록실패',
                  content: '동일한 요금제 이름이 있습니다. 다른 이름으로 등록해 주세요.',
                });
                return;
              } else {
                Modal.info({
                  title: '등록실패',
                  content:
                      '요금제 등록에 실패했습니다. 관리자에게 문의해주세요.',
                });
                return;
              }
              handleClose();
            })
            .catch((error) => {
              customError(
                  '등록 오류',
                  '오류가 발생하였습니다. 다시 시도해 주십시오.'
              );
            });
      } else{
        httpPost(httpUrl.updateDeliveryPriceDongRate, [], {
          name: formRef.current.getFieldsValue().name,
          idx: data.idx,
        })
            .then((res) => {
              if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
                customAlert('요금제 명이 수정되었습니다.');
              } else if (res.data === 'DUPLICATED') {
                Modal.info({
                  title: '수정실패',
                  content:
                      '동일한 요금제 이름이 있습니다. 다른 이름으로 등록해 주세요.',
                });
                return;
              } else {
                Modal.info({
                  title: '수정실패',
                  content: '요금제명 수정에 실패했습니다. 관리자에게 문의해주세요.',
                });
                return;
              }
              handleClose();
            })
            .catch((error) => {
              customError(
                  '수정 오류',
                  '오류가 발생하였습니다. 다시 시도해 주십시오.'
              );
            });
      }
    }
    else {
      httpPost(httpUrl.createDeliveryPriceDongRate, [], {
        name: formRef.current.getFieldsValue().name,
      })
        .then((res) => {
          if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
            customAlert('새로운 요금제가 등록되었습니다.');
          } else if (res.data === 'DUPLICATED') {
            Modal.info({
              title: '등록실패',
              content: '동일한 요금제 이름이 있습니다. 다른 이름으로 등록해 주세요.',
            });
            return;
          } else {
            Modal.info({
              title: '등록실패',
              content:
                '요금제 등록에 실패했습니다. 관리자에게 문의해주세요.',
            });
            return;
          }
          handleClose();
        })
        .catch((error) => {
          customError(
            '등록 오류',
            '오류가 발생하였습니다. 다시 시도해 주십시오.'
          );
        });
    }
  };

  return (
    <div style={{ backgroundColor: 'rgba(0,0,0,0.56)' }} className="absolute flex-1 h-full w-full z-[12]">
      <div className="realtive">
      <div style={{position: "absolute"}} className="nn-modal-container">
        <div className="nn-modal-title-container">
          <span>요금제 그룹 {(!isCopy && data) ? "수정" : "등록"}</span>
          <div className="nn-modal-close" onClick={handleClose}></div>
        </div>
        <div className="nn-modal-title-divider"></div>
        <div className="w-[450px] p-[20px]">
          <div className="flex mb-[20px] items-center justify-between">
            <Form className="w-full" ref={formRef} onFinish={handleSubmit}>
              { data &&
                <div className="flex-1 mb-[20px]">
                  <div className="nn-modal-input-label">선택된 요금제</div>
                  <Input disabled className="nn-modal-input" placeholder="요금명을 입력해주세요." defaultValue={data.name} />
                </div>
              }
              <div className="flex-1">
                <div className="nn-modal-input-label">요금제 이름</div>
                <FormItem
                  name="name"
                  style={{ margin: 0 }}
                  rules={[{ required: true, message: '요금제 명을 입력해주세요.' }]}
                  // initialValue={name}
                >
                  <Input className="nn-modal-input" placeholder="요금명을 입력해주세요." />
                </FormItem>
              </div>
            </Form>
          </div>
          <div className="flex justify-between">
            <Button className="flex-1 nn-modal-button h-[50px] mr-[10px]" onClick={handleClose} htmlType="button">
              취소
            </Button>
            <Button className="flex-1 nn-modal-button h-[50px] bg-[#fadc00]" onClick={handleSubmit}>
              저장
            </Button>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default DeliveryPriceDongRateRegisterGroupDialog;
