/*global kakao*/
import { Button, Checkbox, Form, Input, Modal, Radio, Select } from 'antd';
import React, { Component } from 'react';
import Draggable from 'react-draggable';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Marker, NaverMap, Polyline } from 'react-naver-maps';
import { connect } from 'react-redux';
import { reactLocalStorage } from 'reactjs-localstorage';
import {
  httpGet,
  httpGetWithNoLoading,
  httpPost,
  httpUrl
} from '../../../api/httpClient';
import { registError, updateError } from '../../../api/Modals';
import Const from '../../../const';
import '../../../css/modal.css';
import CloseIcon from '../../../img/login/close.png';
import MarkerRiderIcon from '../../../img/login/map/marker_rider.png';
import MarkerTargetIcon from '../../../img/login/map/marker_target_2.png';
import {
  arriveReqTime,
  deliveryStatusCode,
  HttpResponseStatusCode, mainMenus,
  modifyType,
  packAmount,
  paymentMethod
} from '../../../lib/util/codeUtil';
import { checkCompleteDateExpired } from '../../../lib/util/dateUtil';
import { comma } from '../../../lib/util/numberUtil';
import PaymentDialog from './PaymentDialog';
import { cloneDeep } from 'lodash';
import TextArea from "antd/lib/input/TextArea";

const pageSize = 20;
const Search = Input.Search;
const Option = Select.Option;
const FormItem = Form.Item;
const newOrder = {
  arriveReqTime: 5,
  assignDate: '',
  cancelReason: '',
  completeDate: '',
  custMessage: '',
  custPhone: '',
  deliveryPrice: 0,
  basicDeliveryPrice: 0,
  extraDeliveryPrice: 0,
  destAddr1: '',
  destAddr2: '',
  destAddr3: '',
  distance: 0,
  frId: '',
  frIdx: 0,
  frLatitude: 0,
  frLongitude: 0,
  frName: '',
  frPhone: '',
  idx: -1,
  itemPrepared: false,
  itemPreparingTime: 0,
  latitude: 0,
  longitude: 0,
  memo: '',
  ncashPayEnabled: true,
  orderIdx: 0,
  orderPayments: [
    {
      idx: 1,
      paymentAmount: 0,
      paymentMethod: 3,
      paymentStatus: 1,
    },
  ],
  orderPrice: 0,
  orderStatus: 1,
  pickupDate: '',
  riderName: '',
  riderPhone: '',
  tidNormal: '',
  tidNormalRate: 0,
  tidPrepay: '',
  userIdx: 0,
  packAmount: 1,
  size: 0,
  poopPoint: 0,
  deliverySubsidy: 0,
};

class RegistCallDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // 모달창 관련
      paymentOpen: false,
      searchFranchiseOpen: false,
      searchRiderOpen: false,

      // 가맹점, 도착지 정보
      selectedFr: {
        idx: 0,
        latitude: 0,
        longitude: 0,
        frName: '',
        frPhone: ''
      },
      selectedDest: null,
      selectedRider: {
        riderName: '',
      },

      // 가맹점조회 정보
      frList: [],
      franStatus: 0,
      frName: '',
      franGroup: 0,
      frDataCurrent: 0,
      frDataEnd: false,

      // 라이더조회 정보
      riderList: [],
      riderName: '',
      riderDataEnd: false,

      // 조회 / 수정창 구분
      editable: true,
      navermaps: true,

      mapLat: null,
      mapLng: null,

      taskWorkOpen: false,

      pageWidth: window.innerWidth,

      //주소 검색
      address: '',
      selectedDong: { idx: 0, name: '전체' },
      tableData: [],
      tableDataCurrent: 0,
      tableDataCurrent2: 1,
      addrTableEnd: false,
      tableDataEnd: false,
      errorMessage: '',
      branchName: '',

      //핀 설정
      pinStatus: false,

      distance: 0,

      poopPoint: 0,
      todayPayablePoopPoint: 0,
      data: newOrder,
      deliverySubsidyCheck: false,
    };
    this.formRef = React.createRef();

    // if (
    //   this.props.data &&
    //   (checkCompleteDateExpired(this.props.data.completeDate) ||
    //     checkCompleteDateExpired(this.props.data.cancelDate))
    // ) {
    //   this.props.data.destAddr1 = '***';
    //   this.props.data.destAddr2 = '***';
    //   this.props.data.destAddr3 = '***';
    //   this.props.data.custPhone = '***';
    // }
    this.pickupTimeMap = {};
    window.addEventListener('resize', this.windowSizer);
    this.getTodayPoopPoint();
  }

  windowSizer = () => {
    console.log(document.body.clientWidth);
    this.setState({ pageWidth: window.innerWidth });
  };

  setDefaultState = () => {
    httpGet(httpUrl.getBranch, [this.props.login.loginInfo.branchIdx], {}).then(
      (res) => {
        if (res.result === 'SUCCESS' && res.data) {
          console.log('branchInfo');
          console.log(res);
          this.setState({
            mapLat: res.data.latitude,
            mapLng: res.data.longitude,
          });
        }
      }
    );
    !this.props.data && this.getDeliveryData();
    // this.setState(
    //   {
    //     // data: this.props.data ? this.props.data : newOrder,
    //     selectedDest: this.props.data
    //       ? {
    //           address: this.props.data.destAddr1,
    //           roadAddress: this.props.data.destAddr1,
    //           jibunAddress: this.props.data.destAddr3,
    //         }
    //       : null,
    //     selectedFr: {
    //       idx: this.props.data ? this.props.data.frIdx : 0,
    //       latitude: this.props.data ? this.props.data.frLatitude : 0,
    //       longitude: this.props.data ? this.props.data.frLongitude : 0,
    //       frName: this.props.data ? this.props.data.frName : '',
    //       frPhone: this.props.data ? this.props.data.frPhone : '',
    //     },
    //     selectedRider: {
    //       riderName: this.props.data ? this.props.data.riderName : '',
    //       // riderPhone: this.props.data ? this.props.data.riderPhone : "",
    //     },
    //     distance: this.props.data ? this.props.data.distance : 0,
    //   },
    //   () => {
    //     console.log('setDefaultState state');
    //     console.log(this.state.data);
    //     this.getDeliveryData();
    //   }
    // );

    // 배달 지원금 권한 체크 추가
    const menuAuths = this.props.login.loginInfo.menuAuths;
    if (menuAuths != null && Array.isArray(menuAuths) && menuAuths.length > 0 && menuAuths.find((idx) => 101 === idx)) {
      this.setState({deliverySubsidyCheck : true});
    }
  };

  componentDidMount() {
    this.setDefaultState();
    this.props.data && this.getOrderInfo();
  }

  getOrderInfo = () => {
    httpGet(httpUrl.getOrderInfo, [this.props.data.idx], {})
    .then((res)=>{
      if (
        res.data &&
        (checkCompleteDateExpired(res.data.completeDate) ||
          checkCompleteDateExpired(res.data.cancelDate))
      ) {
        res.data.destAddr1 = '***';
        res.data.destAddr2 = '***';
        res.data.destAddr3 = '***';
        res.data.custPhone = '***';
      }
      this.setState({
        data: res.data,
        selectedDest: {
          address: res.data.destAddr1,
          roadAddress: res.data.destAddr1,
          jibunAddress: res.data.destAddr3,
        },
        selectedFr: {
          idx: res.data.frIdx,
          latitude: res.data.frLatitude,
          longitude: res.data.frLongitude,
          frName: res.data.frName,
          frPhone: res.data.frPhone,
        },
        selectedRider: {
          riderName: res.data.riderName,
          // riderPhone: this.props.data ? this.props.data.riderPhone : "",
        },
        distance: res.data.distance,
      },()=>{
        this.getDeliveryData()
      })
      this.formRef.current.setFieldsValue({
        ...res.data,
        addrMain: res.data.destAddr1,
        addrSub: res.data.destAddr2,
        packAmount: packAmount[res.data.packAmount],
        arriveReqTime: arriveReqTime[res.data.arriveReqTime],
        callmemo: res.data.custMessage
      })
    })
  }

  handleChangeInput = (value, stateKey) => {
    let newData = this.cloneObj(this.state.data);
    newData[stateKey] = value;
    this.setState({
      data: newData,
    });
  };
  cloneObj = (obj) => Object.assign({}, obj);

  openPaymentModal = () => {
    this.setState({ paymentOpen: true });
  };
  closePaymentModal = () => {
    this.setState({ paymentOpen: false });
  };

  // 주소검색 dialog
  openPostCode = () => {
    this.setState({ isPostCodeOpen: true });
  };
  closePostCode = () => {
    this.setState({ isPostCodeOpen: false });
  };

  // 주소검색 dialog_m
  openPostCode_m = () => {
    this.setState({ isPostCodeOpen_m: true });
  };
  closePostCode_m = () => {
    this.setState({ isPostCodeOpen_m: false });
  };

  handlePaymentChange = (result) => {
    this.setState({
      data: {
        ...this.state.data,
        orderPayments: result,
      },
    });
  };

  getGeocode = (roadAddress) => {
    httpGet(httpUrl.getGeocode, [roadAddress], {})
      .then((res) => {
        return {
          lat: res.data.latitude,
          lng: res.data.longitude,
        };
      })
      .catch((e) => {
        Modal.info({
          title: '좌표 변화 실패',
          content: '좌표변환에 실패했습니다.',
        });
      });
  };
  addressSearchKakao = (address) => {
    const geocoder = new kakao.maps.services.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.addressSearch(address, function (result, status) {
        if (status === kakao.maps.services.Status.OK) {
          const coords = [result[0].y, result[0].x];
          resolve(coords);
        } else {
          reject(status);
        }
      });
    });
  };

  getDeliveryPrice = () => {
    console.log('getDeliiveryPrice!!!!!!!!!!!!!!!!!');
    var self = this;
    const searchAddress = this.state.selectedDest.roadAddr
      ? this.state.selectedDest.roadAddr +
        '?query2=' +
        this.state.selectedDest.jibunAddr
      : this.state.selectedDest.jibunAddr;
    httpGet(httpUrl.getGeocode, [searchAddress], {})
      .then((res) => {
        const lat = res.data.latitude;
        const lng = res.data.longitude;

        this.setState({
          mapLat: lat,
          mapLng: lng,
          data: {
            ...this.state.data,
            latitude: lat,
            longitude: lng,
          },
        });
        console.log(this.state.selectedFr.idx, lat, lng);
        httpGet(
          httpUrl.getDeliveryPrice,
          [this.state.selectedFr.idx, lat, lng],
          {}
        )
          .then((res) => {
            console.log('getdeliveryprice res');
            console.log(res);

            if (this.props.data) {
              self.formRef.current.setFieldsValue({
                deliveryPrice: comma(
                  res.data.deliveryPriceBasic +
                    this.formRef.current.getFieldValue('extraDeliveryPrice')
                ),
                basicDeliveryPrice: comma(res.data.deliveryPriceBasic),
                extraDeliveryPrice: self.props.reRegist ? comma(res.data.deliveryPriceExtra) : comma(self.props.data.extraDeliveryPrice),
              });
              this.setState({
                data: {
                  ...this.state.data,
                  deliveryPrice:
                    res.data.deliveryPriceBasic +
                    this.formRef.current.getFieldValue('extraDeliveryPrice'),
                  basicDeliveryPrice: res.data.deliveryPriceBasic,
                  extraDeliveryPrice: self.props.reRegist ? res.data.deliveryPriceExtra : self.props.data.extraDeliveryPrice,
                },
                distance: res.data.distance,
              });
            } else {
              self.formRef.current.setFieldsValue({
                deliveryPrice: comma(
                  res.data.deliveryPriceBasic + res.data.deliveryPriceExtra
                ),
                basicDeliveryPrice: comma(res.data.deliveryPriceBasic),
                extraDeliveryPrice: res.data.deliveryPriceExtra,
              });
              this.setState({
                data: {
                  ...this.state.data,
                  deliveryPrice:
                    res.data.deliveryPriceBasic + res.data.deliveryPriceExtra,
                  basicDeliveryPrice: res.data.deliveryPriceBasic,
                  extraDeliveryPrice: res.data.deliveryPriceExtra,
                },
                distance: res.data.distance,
              });
            }
          })
          .catch((e) => {
            Modal.info({
              title: '등록오류',
              content: '배달요금 계산 오류2',
            });
          });
      })
      .catch((e) => {
        Modal.info({
          title: '등록오류',
          content: '좌표 계산 오류',
        });
      });
  };

  getDeliveryPriceByLatLng = (lat, lng) => {
    const self = this;
    console.log(this.state.selectedFr.idx, lat, lng);
    httpGet(httpUrl.getDeliveryPrice, [this.state.selectedFr.idx, lat, lng], {})
      .then((res) => {
        if (res.result === 'SUCCESS') {
          console.log('resresresresresresresres');
          console.log(res);

          if (this.props.data) {
            self.formRef.current.setFieldsValue({
              deliveryPrice: comma(
                res.data.deliveryPriceBasic +
                  this.formRef.current.getFieldValue('extraDeliveryPrice')
              ),
              basicDeliveryPrice: comma(res.data.deliveryPriceBasic),
            });
            this.setState({
              data: {
                ...this.state.data,
                deliveryPrice:
                  res.data.deliveryPriceBasic +
                  this.formRef.current.getFieldValue('extraDeliveryPrice'),
                basicDeliveryPrice: res.data.deliveryPriceBasic,
              },
              distance: res.data.distance,
            });
          } else {
            self.formRef.current.setFieldsValue({
              deliveryPrice: comma(
                res.data.deliveryPriceBasic + res.data.deliveryPriceExtra
              ),
              basicDeliveryPrice: comma(res.data.deliveryPriceBasic),
              extraDeliveryPrice: res.data.deliveryPriceExtra,
            });

            this.setState({
              data: {
                ...this.state.data,
                deliveryPrice:
                  res.data.deliveryPriceBasic + res.data.deliveryPriceExtra,
                basicDeliveryPrice: res.data.deliveryPriceBasic,
                extraDeliveryPrice: res.data.deliveryPriceExtra,
              },
              distance: res.data.distance,
            });
          }
        } else {
          Modal.info({
            title: '등록오류',
            content: '배달요금 계산 오류1',
          });
        }
      })
      .catch((e) => {
        Modal.info({
          title: '등록오류',
          content: '배달요금 계산 오류2',
        });
      });
  };

  clearData = () => {
    this.setState({ data: newOrder });
  };

  handleSubmit = async () => {
    this.setState(
      {
        data: {
          ...this.state.data,
          deliveryPrice:
            this.state.data.extraDeliveryPrice +
            this.state.data.basicDeliveryPrice,
          orderDate: this.props.reRegist ? '' : this.state.data.orderDate,
          bmOrderCode: this.props.reRegist ? null : this.state.data.bmOrderCode,
          ftOrderCode: this.props.reRegist ? null : this.state.data.ftOrderCode,
          userIdx: this.props.reRegist ? null : this.state.data.userIdx,
        },
      },
      () => {
        console.log(JSON.stringify(this.state.data));
        if (this.state.data.orderPayments.length === 1) {
          let newData = { ...this.state.data };
          newData.orderPayments[0].paymentAmount = newData.orderPrice;
          this.setState({ data: newData });
        }

        if (this.state.data.destAddr1 == '') {
          Modal.info({
            title: '주소설정오류',
            content: '반드시 주소를 조회하고 등록해주세요.',
          });
          return;
        }

        if (this.state.data.extraDeliveryPrice > 50000) {
          Modal.info({
            title: '할증 배달요금 오류',
            content: '할증 배달요금은 5만원을 초과할 수 없습니다. 할증 배달요금을 확인해주세요.',
          });
          return;
        }

        if (this.props.data && !this.props.reRegist) {
          let paySum = 0;
          this.state.data.orderPayments.forEach(
            (payment) => (paySum += payment.paymentAmount)
          );
          if (paySum !== this.state.data.orderPrice) {
            Modal.info({
              title: '등록 오류',
              content:
                '주문가격과 결제내역이 다릅니다. 결제내역을 확인해주세요.',
            });
            return;
          }

          if (this.state.poopPoint > this.state.todayPayablePoopPoint) {
            Modal.info({
              title: '똥콜 점수 지급 오류',
              content: '오늘 지급 가능한 포인트를 초과 하였습니다.',
            });
            return;
          }

          let formData = { ...this.state.data, poopPoint: this.state.poopPoint };
          // 주문 수정시에 바뀌어서는 안되는 정보들
          delete formData.orderStatus; // 주문상태는 따로 업데이트함
          delete formData.orderDate; // 주문시간
          delete formData.cancelDate; // 주문시간

          console.log('====================update===============');
          console.log(formData);

          httpPost(httpUrl.orderUpdate, [], formData)
            .then((res) => {
              console.log(res);

              if (res.result === 'SUCCESS') {
                if (
                  res.data === 'SUCCESS' ||
                  res.data === 'CANNOT_UPDATE_COMPLETED_ORDER'
                ) {
                  // updateComplete();
                  this.props.close(true);
                  if (this.props.callback) this.props.callback();
                } else if (res.data === 'NOT_ENOUGH_NCASH') {
                  Modal.info({
                    title: '등록 오류',
                    content: '가맹점 예치금이 부족합니다.',
                  });
                } else if (res.data === 'BRANCH_CLOSED') {
                  Modal.info({
                    title: '등록 오류',
                    content: '가맹점 영업시간이 아닙니다.',
                  });
                } else if (res.data === 'DELIVERY_START_DISABLED_AREA') {
                  Modal.info({
                    title: '배달요청 실패',
                    content: '배달요청이 허용되지 않는 지역의 가맹점입니다.',
                  });
                } else if (res.data === 'DELIVERY_END_DISABLED_AREA') {
                  Modal.info({
                    title: '배달요청 실패',
                    content: '배달요청이 허용되지 않는 주소입니다.',
                  });
                } else if (res.data === 'NOT_CHANGE_STATUS_7_DAYS') {
                  Modal.info({
                    title: '주문 수정 불가',
                    content: '40일 전 주문은 수정 불가합니다.',
                  });
                } else if (res.data === 'DELIVERY_SUBBIDY_INVALID_PRICE') {
                  Modal.info({
                    title: '주문수정 실패',
                    content: '배달 지원금은 음수로 입력 할 수 없습니다.',
                  });
                } else if (res.data === 'DELIVERY_SUBBIDY_INVALID_ORDER_STATUS') {
                  Modal.info({
                    title: '주문수정 실패',
                    content: '배달 지원금은 접수 상태에서만 수정 가능합니다.',
                  });
                } else if (res.data === 'DELIVERY_SUBBIDY_MAX_PRICE_ORVER') {
                  Modal.info({
                    title: '주문수정 실패',
                    content: '배달 지원금은 기본 배달요금(' + comma(this.state.data.basicDeliveryPrice) + ')원 이하로 입력 할수 있습니다.',
                  });
                } else if (res.data === 'DELIVERY_SUBBIDY_NO_MONEY_LEFT') {
                  Modal.info({
                    title: '주문수정 실패',
                    content: '지사의 잔액이 부족합니다.',
                  });
                }
              } else {
                updateError();
              }
            })
            .catch((e) => {
              updateError();
            });
          if (this.state.poopPoint > 0) {
            httpPost(httpUrl.updateTodayPoopPoint, [], {
              poopPointDelta: this.state.poopPoint * -1,
              orderIdx: this.props.data.idx,
            })
              .then((res) => {
                console.debug(res);
              })
              .catch((e) => {
                console.error('updateTodayPoopPoint fail.');
              });
          }
        } else if (this.props.data && this.props.reRegist) {
          let paySum = 0;
          this.state.data.orderPayments.forEach(
            (payment) => (paySum += payment.paymentAmount)
          );
          if (paySum !== this.state.data.orderPrice) {
            Modal.info({
              title: '등록 오류',
              content:
                '주문가격과 결제내역이 다릅니다. 결제내역을 확인해주세요.',
            });
            return;
          }
          httpPost(httpUrl.orderCreate, [], this.state.data)
            .then((res) => {
              console.log(res);
              if (res.result === 'SUCCESS') {
                if (res.data === 'SUCCESS') {
                  // registComplete();

                  this.props.close(true);
                  this.clearData();
                } else if (res.data === 'DELIVERY_NOT_ENABLED') {
                  Modal.info({
                    title: '배달불가',
                    content: '현재 배달불가 상태입니다.',
                  });
                } else if (res.data === 'NOT_ENOUGH_NCASH') {
                  Modal.info({
                    title: '등록 오류',
                    content: '가맹점 예치금이 부족합니다.',
                  });
                } else if (res.data === 'BRANCH_CLOSED') {
                  Modal.info({
                    title: '등록 오류',
                    content: '가맹점 영업시간이 아닙니다.',
                  });
                } else if (res.data === 'DELIVERY_START_DISABLED_AREA') {
                  Modal.info({
                    title: '배달요청 실패',
                    content: '배달요청이 허용되지 않는 지역의 가맹점입니다.',
                  });
                } else if (res.data === 'DELIVERY_END_DISABLED_AREA') {
                  Modal.info({
                    title: '배달요청 실패',
                    content: '배달요청이 허용되지 않는 주소입니다.',
                  });
                }
              } else {
                registError();
              }
            })
            .catch((e) => {
              registError();
            });
        } else {
          let paySum = 0;
          this.state.data.orderPayments.forEach(
            (payment) => (paySum += payment.paymentAmount)
          );
          if (paySum !== this.state.data.orderPrice) {
            Modal.info({
              title: '등록 오류',
              content:
                '주문가격과 결제내역이 다릅니다. 결제내역을 확인해주세요.',
            });
            return;
          }
          httpPost(httpUrl.orderCreate, [], this.state.data)
            .then((res) => {
              console.log(res);
              if (res.result === 'SUCCESS') {
                if (res.data === 'SUCCESS') {
                  // registComplete();

                  this.props.close(true);
                  this.clearData();
                } else if (res.data === 'DELIVERY_NOT_ENABLED') {
                  Modal.info({
                    title: '배달불가',
                    content: '현재 배달불가 상태입니다.',
                  });
                } else if (res.data === 'NOT_ENOUGH_NCASH') {
                  Modal.info({
                    title: '등록 오류',
                    content: '가맹점 예치금이 부족합니다.',
                  });
                } else if (res.data === 'BRANCH_CLOSED') {
                  Modal.info({
                    title: '등록 오류',
                    content: '가맹점 영업시간이 아닙니다.',
                  });
                } else if (res.data === 'DELIVERY_START_DISABLED_AREA') {
                  Modal.info({
                    title: '배달요청 실패',
                    content: '배달요청이 허용되지 않는 지역의 가맹점입니다.',
                  });
                } else if (res.data === 'DELIVERY_END_DISABLED_AREA') {
                  Modal.info({
                    title: '배달요청 실패',
                    content: '배달요청이 허용되지 않는 주소입니다.',
                  });
                } else if (res.data === 'RIDER_NAME_NOT_VERIFIED') {
                  Modal.info({
                    title: '배달요청 실패',
                    content: '실명인증이 되어있지 않은 라이더입니다.',
                  });
                } else if (res.data === 'AUTO_CANCEL_TIME_OVER') {
                  Modal.info({
                    title: '배달요청 실패',
                    content: '주문 자동 취소 시간이 지나서 자동 취소 된 주문 입니다.',
                  });
                }
              } else {
                registError();
              }
            })
            .catch((e) => {
              registError();
            });
        }
      }
    );
  };

  getDeliveryData = () => {
    console.log('getDeliveryData');
    console.log(this.state.data);
    const deliveryData = reactLocalStorage.getObject(
      Const.appName + '#deliverydata'
    );
    if (deliveryData.pickupTimeMap) {
      console.log(deliveryData);
      this.pickupTimeMap = deliveryData.pickupTimeMap;
      const keyList = Object.keys(deliveryData.pickupTimeMap);
      for (let i = 0; i < keyList.length; i++) {
        console.log(i);
        console.log(keyList[i]);
        console.log(deliveryData.pickupTimeMap[keyList[i]]);
        if (deliveryData.pickupTimeMap[keyList[i]]) {
          newOrder.arriveReqTime = keyList[i];
          console.log('data');
          console.log({
            ...this.state.data,
            arriveReqTime: keyList[i],
          });
          this.setState(
            {
              data: {
                ...this.state.data,
                arriveReqTime: keyList[i],
              },
            },
            () => console.log(newOrder)
          );
          break;
        }
      }
    }
  };

  // 가맹점 목록
  getFranchseList = () => {
    this.setState({ searchFranchiseOpen: true });
    if (this.state.frDataEnd) return;
    httpPost(httpUrl.franchiseList, [], {
      frName: this.state.frName,
      pageNum: this.state.frDataCurrent + 1,
      pageSize: 20,
      userGroup: this.state.franGroup,
      // userStatus: this.state.franStatus === 0 ? null : this.state.franStatus,
      frListForRegistOrder: true,
      userStatus: 1,
    }).then((result) => {
      result = result.data;
      console.log(result);
      if (result.franchises.length === 0) {
        this.setState({
          frDataEnd: true,
          frList: this.state.frList,
        });
        return;
      }
      if (this.state.frDataCurrent === result.currentPage) {
        result.franchises = [];
      }

      this.setState({
        frList: this.state.frList.concat(result.franchises),
        frDataCurrent: result.currentPage,
      });
    });
  };

  // 가맹점 검색
  onSearchFranchise = (value) => {
    this.setState(
      {
        frList: [],
        frName: value,
        frDataCurrent: 0,
        frDataEnd: false,
      },
      () => {
        this.getFranchseList();
      }
    );
  };

  // 가맹점 선택 데이터
  onSelect = (fr) => {
    this.setState({ selectedFr: fr }, () => {
      const fr = this.state.selectedFr;
      this.setState({
        data: {
          ...this.state.data,
          // idx
          frIdx: fr.idx,
          frLatitude: fr.latitude,
          frLongitude: fr.longitude,
          frName: fr.frName,
          frPhone: fr.frPhone,
        },
      });
      console.log(this.state.selectedDest);
      if (this.state.selectedDest) {
        if (Object.keys(this.state.selectedDest).includes('zipNo')) {
          this.getDeliveryPrice();
        } else {
          this.getDeliveryPriceByLatLng(this.state.mapLat, this.state.mapLng);
        }
      } else return;
    });
  };

  getRiderList = () => {
    console.log('getRiderList start');
    console.log(this.state.data);

    const pageNum = 1;
    const pageSize = 100000;
    const riderLevel = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const userStatus = '';
    const searchName = this.state.riderName;
    const riderStatus = '';
    const approvedCode = 2;

    httpGetWithNoLoading(
      httpUrl.riderList,
      [
        pageSize,
        pageNum,
        searchName,
        userStatus,
        riderLevel,
        riderStatus,
        approvedCode,
      ],
      {}
    )
      .then((res) => {
        if (res.result === 'SUCCESS') {
          console.log('getRiderList success');
          console.log(res);
          if (res.data.riders.length === 0) {
            this.setState({
              riderDataEnd: true,
              riderList: this.state.riderList,
            });
            return;
          }
          this.setState({
            riderList: res.data.riders,
          });
        } else {
          return;
        }
      })
      .catch((e) => {
        throw e;
      });
  };

  //라이더 검색
  onSearchRider = (value) => {
    this.setState(
      {
        riderList: [],
        riderName: value,
        riderDataEnd: false,
        // frDataCurrent: 0,
      },
      () => {
        console.log('라이더목록' + this.state.riderName);
        this.getRiderList();
      }
    );
  };

  // 라이더 선택 데이터
  onSelectRider = (rider) => {
    console.log('라이더 선택');
    console.log(rider);
    this.setState({ selectedRider: rider }, () => {
      const rider = this.state.selectedRider;
      this.setState(
        {
          data: {
            ...this.state.data,
            userIdx: rider.idx,
          },
        },
        () => console.log('라이더 클릭시' + JSON.stringify(this.state.data))
      );
    });
  };

  // 주소 검색
  onSearchAddress = (value) => {
    this.setState(
      {
        tableData: [],
        tableDataCurrent: 0,
        tableDataCurrent2: 0,
        tableDataEnd: false,
        addrTableEnd: false,
        errorMessage: '',
        searchAddressOpen: true,
        address: value,
      },
      () => {
        this.findAddress();
      }
    );
  };

  parseRegexExpressions = (string) => {
    string = string
      .replace(/&amp;/gi, '&')
      .replace(/&quot;/gi, '"')
      .replace(/&apos;/gi, "'")
      .replace(/&lt;/gi, '<')
      .replace(/&gt;/gi, '>');
    return string.substring(1, string.length - 1);
  };

  // 주소 조회
  findAddress = () => {
    if (this.state.address === '') {
      this.setState({ errorMessage: '검색어를 입력해 주세요.' });
      return;
    }
    if (this.state.tableDataEnd) return;
    if (this.state.addrTableEnd) {
      console.log('findAddressAll');
      this.findAddressAll();
    } else {
      console.log('findAddressEx');
      this.findAddressEx();
    }
  };

  findAddressAll = () => {
    const currentPage = parseInt(this.state.tableDataCurrent2) + 1;
    const countPerPage = pageSize;
    console.log(
      `keyword : { this.state.selectedDong.siDo : ${this.state.selectedDong.siDo}, this.state.selectedDong.siGunGu : ${this.state.selectedDong.siGunGu},  this.state.selectedDong.name : ${this.state.selectedDong.name}, this.state.address : ${this.state.address}}`
    );
    const keyword = this.state.address ? this.state.address : '';
    httpGet(httpUrl.getAddress, [currentPage, countPerPage, encodeURIComponent(keyword)], {})
      .then((response) => {
        console.log('findAll response');
        console.log(response);
        if (response.data.type === 'juso') {
          console.log(this.parseRegexExpressions(response.data.json));
          const results = JSON.parse(
            this.parseRegexExpressions(response.data.json)
          ).results;
          if (results.juso == null) {
            this.setState({ errorMessage: results.common.errorMessage });
          } else {
            let tableData = results.juso;
            if (results.juso.length === 0) {
              this.setState({
                tableDataEnd: true,
                errorMessage: '검색 결과가 존재하지 않습니다.',
              });
              return;
            }
            if (this.state.tableDataCurrent2 === results.common.currentPage) {
              tableData = [];
            }
            console.log('tableData');
            console.log(tableData);
            this.setState({
              tableData: this.state.tableData.concat(tableData),
              tableDataCurrent2: results.common.currentPage,
              errorMessage: '',
            });
          }
        } else {
          const results = JSON.parse(response.data.json).results;
          console.log(results);
          if (results.length === 0) {
            this.setState({
              tableDataEnd: true,
              errorMessage: '검색 결과가 존재하지 않습니다.',
            });
            return;
          }

          console.log('===== nyamnyamjuso tableData =====');
          console.log(results);

          let tableData = results.map((row) => ({
            bdNm: '',
            roadAddr: row.도로명주소,
            jibunAddr: row.지번주소,
          }));
          if (this.state.tableDataCurrent2 === 2) {
            tableData = [];
          }

          console.log('tableData');
          console.log(tableData);
          this.setState({
            tableData: this.state.tableData.concat(tableData),
            // tableDataCurrent2: 2,
            errorMessage: '',
          });
        }
      })
      .catch((e) => {
        this.setState({ errorMessage: '오류가 발생하였습니다.' });
        console.log(e);
      });
  };

  findAddressEx = () => {
    const searchDong =
      this.state.selectedDong.idx === 0 ? '' : this.state.selectedDong.name;
    const searchString = this.state.address ? this.state.address : '';

    httpGet(
      httpUrl.addrExList,
      [
        pageSize,
        parseInt(this.state.tableDataCurrent) + 1,
        searchDong,
        encodeURIComponent(searchString),
      ],
      {}
    )
      .then((res) => {
        if (res.result === 'SUCCESS') {
          console.log('findEx res');
          console.log(res.data);
          if (res.data.addrAptBranches.length < pageSize) {
            console.log('addrList end');
            this.setState({ addrTableEnd: true, errorMessage: '' }, () =>
              this.findAddress()
            );
          }
          if (this.state.tableDataCurrent === res.result.currentPage) {
            res.result.addrAptBranches = [];
          }

          const tableData = res.data.addrAptBranches.map((row) => ({
            // bdNm: row.name,
            name: row.name,
            bdNm: '',
            roadAddr: row.addr1,
            jibunAddr: row.addr3,
          }));
          console.log('tableData');
          console.log(tableData);
          this.setState({
            tableData: this.state.tableData.concat(tableData),
            tableDataCurrent: res.data.currentPage,
            errorMessage: '',
          });
        }
      })
      .catch((e) => {
        this.setState({ errorMessage: 'error' });

        console.log(e);
      });
  };

  setAddress = (value) => {
    this.formRef.current.setFieldsValue({ addrMain: value.roadAddr });
    this.setState(
      {
        selectedDest: value,
        tableData: [],
        searchAddressOpen: false,
      },
      () => {
        this.setState({
          data: {
            ...this.state.data,
            destAddr1: this.state.selectedDest.roadAddr
              ? `${this.state.selectedDest.roadAddr} ${
                  this.state.selectedDest.bdNm && this.state.selectedDest.bdNm
                }`
              : `${this.state.selectedDest.jibunAddr} ${
                  this.state.selectedDest.bdNm && this.state.selectedDest.bdNm
                }`,
            destAddr3: this.state.selectedDest.jibunAddr
              ? `${this.state.selectedDest.jibunAddr} ${
                  this.state.selectedDest.bdNm && this.state.selectedDest.bdNm
                }`
              : '',
          },
        });
        console.log(this.state.selectedFr);
        if (this.state.selectedFr.idx !== 0) {
          console.log('기본 state 가맹점');
          console.log(this.state.selectedFr);
          this.getDeliveryPrice();
        }
      }
    );
  };

  handlePostCode = (data) => {
    this.onDestSelect(data);
    this.closePostCode();
    this.closePostCode_m();
  };

  onDestSelect = (value) => {
    this.setState({ selectedDest: value }, () => {
      console.log('this.state.selectedDest');
      console.log(this.state.selectedDest);
      this.setState({
        data: {
          ...this.state.data,
          destAddr1: this.state.selectedDest.roadAddress
            ? `${this.state.selectedDest.roadAddress} ${
                this.state.selectedDest.buildingName &&
                this.state.selectedDest.buildingName
              }`
            : `${this.state.selectedDest.jibunAddress} ${
                this.state.selectedDest.buildingName &&
                this.state.selectedDest.buildingName
              }`,
          destAddr3: this.state.selectedDest.jibunAddress
            ? `${this.state.selectedDest.jibunAddress} ${
                this.state.selectedDest.buildingName &&
                this.state.selectedDest.buildingName
              }`
            : '',
        },
      });
      console.log(this.state.selectedFr);
      if (this.state.selectedFr.idx !== 0) {
        console.log('기본 state 가맹점');
        console.log(this.state.selectedFr);
        this.getDeliveryPrice();
      }
    });
  };

  onChangePoopPoint = (e) => {
    console.log(e.target.value);
    const now = new Date();
    const month = now.getMonth() + 1;

    // if (
    //   month != 12 &&
    //   month != 1 &&
    //   month != 7 &&
    //   month != 8 &&
    //   e.target.value > 0
    // ) {
    //   Modal.error({
    //     title: '할당 불가',
    //     content: <div>똥콜 점수 할당은 성수기에만 가능합니다.</div>,
    //     onOk() {},
    //   });
    //   return;
    // }

    this.setState({ poopPoint: e.target.value }, () => {
      console.log(this.state.poopPoint);
      // this.setState({
      //   data: {
      //     ...this.state.data,
      //     poopPoint: this.state.poopPoint,
      //   },
      // });
    });
  };

  getTodayPoopPoint = async () => {
    try {
      const res = await httpGet(
        httpUrl.getTodayPoopPoint,
        [this.props.login.loginInfo.branchIdx],
        {}
      );
      console.log(res);
      this.setState({
        todayPayablePoopPoint: res.data,
      });
      console.log(
        'getTodayPoopPoint todayPayablePoopPoint',
        +this.state.todayPayablePoopPoint
      );
    } catch (e) {
      console.log('getStatusList error', e);
    }
  };

  renderContent() {
    const lat = 37.643623625321474;
    const lng = 126.66509442649551;

    const { close } = this.props;
    const data = this.state.data;
    const navermaps = window.naver.maps;
    let basicDeliveryPrice = this.state.data
      ? this.state.data.basicDeliveryPrice
      : '';

    const postCodeStyle = {
      display: 'inlineBlock',
      position: 'absolute',
      top: '55%',
      left: '-3%',
      width: '340px',
      height: '500px',
      padding: '10px',
      className: 'desk',
      zIndex: 30,
    };
    const postCodeStyle_m = {
      display: 'inlineBlock',
      position: 'absolute',
      top: '55%',
      left: '-10%',
      width: '100%',
      height: '500px',
      padding: '10px',
      className: 'mobile',
      zIndex: 30,
    };

    return (
      <div className="registCall-Dialog handle" style={{ zIndex: 1000 }}>
        <div className="registCall-container">
          <div className="registCall-title">
            {this.props.data ? '주문수정' : '주문등록'}
          </div>
          <img
            onClick={() => close(false)}
            src={CloseIcon}
            className="surcharge-close"
            alt="닫기"
          />

          <Form
            ref={this.formRef}
            onFinish={this.handleSubmit}
            onSubmit={() => false}>
            <div
              className="registCallLayout"
              onClick={() =>
                this.setState({
                  isPostCodeOpen: false,
                  isPostCodeOpen_m: false,
                })
              }>
              <div className="registCallWrapper">
                <div className="registCallWrapper sub">
                  <div className="contentBlock">
                    <div className="mainTitle">가맹점명</div>
                    <FormItem name="frMain" className="selectItem">
                      <div className="orderPayment-wrapper desk">
                        <div className="dis-input">
                          <Input
                            value={
                              this.state.selectedFr
                                ? this.state.selectedFr.frName
                                : data.frName
                            }
                            style={{
                              width: 540,
                              border: '3px solid darkgray',
                              padding: 0,
                              color: '#000000',
                              textAlign: 'center',
                            }}
                            disabled
                          />
                        </div>

                        <Search
                          onKeyDown={(e) => {
                            console.log(e);
                            if (e.key === 'Enter') e.preventDefault();
                          }}
                          placeholder={'가맹점명 입력'}
                          enterButton={'가맹점 조회'}
                          onFocus={() =>
                            this.setState({ searchFranchiseOpen: true }, () =>
                              this.getFranchseList()
                            )
                          }
                          onChange={(e) =>
                            this.setState({ frName: e.target.value })
                          }
                          onSearch={this.onSearchFranchise}
                          onBlur={(e) => {
                            if (!e.relatedTarget) {
                              this.setState({ searchFranchiseOpen: false });
                            }
                          }}
                        />
                      </div>

                      <div className="orderPayment-wrapper mobile">
                        <div className="dis-input">
                          <Input
                            onKeyDown={(e) => {
                              console.log(e);
                              if (e.key === 'Enter') e.preventDefault();
                            }}
                            value={
                              this.state.selectedFr
                                ? this.state.selectedFr.frName
                                : data.frName
                            }
                            style={{
                              width: 260,
                              border: '3px solid darkgray',
                              padding: 0,
                              color: '#000000',
                              textAlign: 'center',
                            }}
                            disabled
                          />
                        </div>

                        <Search
                          onKeyDown={(e) => {
                            console.log(e);
                            if (e.key === 'Enter') e.preventDefault();
                          }}
                          onFocus={() =>
                            this.setState({ searchFranchiseOpen: true }, () =>
                              this.getFranchseList()
                            )
                          }
                          placeholder={'가맹점명을 입력해주세요'}
                          enterButton={'조회'}
                          onChange={(e) =>
                            this.setState({ frName: e.target.value })
                          }
                          onSearch={this.onSearchFranchise}
                        />
                      </div>

                      {this.state.searchFranchiseOpen && (
                        <div tabIndex={'0'} id="fran-list">
                          <InfiniteScroll
                            dataLength={this.state.frList.length}
                            height={'10.5em'}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                            next={this.getFranchseList}
                            hasMore={!this.frDataEnd}
                            scrollableTarget="fran-list">
                            {this.state.frList.map((row, index) => {
                              return (
                                <div
                                  className="frNameTag"
                                  onClick={() => {
                                    this.onSelect(row);
                                    this.setState({
                                      searchFranchiseOpen: false,
                                    });
                                  }}>
                                  <div>{row.frName}</div>
                                </div>
                              );
                            })}
                          </InfiniteScroll>
                        </div>
                      )}
                    </FormItem>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">도착지</div>
                    <FormItem name="addrMain" className="selectItem">
                      <Input.Search
                        onKeyDown={(e) => {
                          console.log(e);
                          if (e.key === 'Enter') e.preventDefault();
                        }}
                        className="search-address-Dialog"
                        placeholder={'주소를 입력해 주세요.'}
                        enterButton={'조회'}
                        // defaultValue={
                        //   this.state.selectedDest
                        //     ? `${this.state.selectedDest.roadAddr} ${
                        //         this.state.selectedDest.bdNm &&
                        //         this.state.selectedDest.bdNm
                        //       }`
                        //     : this.props.data
                        //     ? this.props.data.destAddr1
                        //     : ''
                        // }
                        onSearch={(value) => this.onSearchAddress(value)}
                        onBlur={(e) => {
                          if (!e.relatedTarget) {
                            this.setState({ searchAddressOpen: false });
                          }
                        }}
                      />
                    </FormItem>
                    {this.state.searchAddressOpen && (
                      <div
                        tabIndex={'0'}
                        id="addr-list"
                        className="search-address-Dialog">
                        <InfiniteScroll
                          className="search-address-Dialog-scroll"
                          dataLength={this.state.tableData.length}
                          next={this.findAddress}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }} //To put endMessage and loader to the top.
                          hasMore={!this.tableDataEnd}
                          height={'10.5em'}
                          scrollableTarget="addr-list">
                          {this.state.tableData.length > 0 ? (
                            this.state.tableData.map((row) => {
                              return (
                                <div
                                  className="frNameTag"
                                  onClick={() => {
                                    this.setAddress(row);
                                    this.setState({
                                      searchAddressOpen: false,
                                    });
                                  }}>
                                  <div>
                                    {typeof row.name !== 'undefined' &&
                                      '[' + row.name + '] '}
                                    {row.roadAddr}
                                    <br />
                                    {row.jibunAddr}
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="frNameTag">
                              {this.state.errorMessage}
                            </div>
                          )}
                        </InfiniteScroll>
                      </div>
                    )}
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">상세주소</div>
                    <FormItem name="addrSub" className="selectItem">
                      <Input
                        onKeyDown={(e) => {
                          console.log(e);
                          if (e.key === 'Enter') e.preventDefault();
                        }}
                        placeholder="상세주소를 입력해 주세요."
                        className="override-input"
                        onChange={(e) =>
                          this.handleChangeInput(e.target.value, 'destAddr2')
                        }></Input>
                    </FormItem>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">기본 배달요금</div>
                    <FormItem name="basicDeliveryPrice" className="selectItem">
                      <Input
                        onKeyDown={(e) => {
                          console.log(e);
                          if (e.key === 'Enter') e.preventDefault();
                        }}
                        placeholder="배달요금 입력"
                        className="override-input"
                        defaultValue={comma(basicDeliveryPrice)}
                        required
                        disabled></Input>
                    </FormItem>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">할증 배달요금</div>
                    <FormItem name="extraDeliveryPrice" className="selectItem">
                      <Input
                        onKeyDown={(e) => {
                          console.log(e);
                          if (e.key === 'Enter') e.preventDefault();
                        }}
                        // type="number"
                        placeholder="할증 배달요금 입력"
                        className="override-input"
                        defaultValue={comma(data.extraDeliveryPrice)}
                        onChange={(e) =>
                          this.handleChangeInput(
                              e.target.value ?
                                  parseInt(e.target.value.replace(/,/gi, '')) : 0,
                            'extraDeliveryPrice'
                          )
                        }></Input>
                    </FormItem>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">가격</div>
                    <FormItem name="orderPrice" className="selectItem">
                      <Input
                        onKeyDown={(e) => {
                          console.log(e);
                          if (e.key === 'Enter') e.preventDefault();
                        }}
                        // type="number"
                        placeholder="가격 입력"
                        className="override-input"
                        defaultValue={comma(data.orderPrice)}
                        onChange={(e) =>
                          this.handleChangeInput(
                            parseInt(e.target.value.replace(/,/gi, '')),
                            'orderPrice'
                          )
                        }
                        required></Input>
                    </FormItem>
                  </div>
                  {this.props.data ? (
                    <div className="contentBlock">
                      <div
                        style={{
                          width: '170%',
                          textAlign: 'left',
                          borderBottom: '1.5px solid #aaa',
                          lineHeight: '1em',
                          margin: '10px 0 20px',
                        }}></div>
                      {this.props.data.frCompanyId === 'connect9' ? (this.state.data.poopPoint > 0 ?
                        (<><div className="payablePoopPoint-sub-title-gray">
                        {' '}
                        똥콜 할당 점수
                        <Radio.Group
                            value={this.state.data.poopPoint}
                            className="selPoopPoint"
                            disabled={true}>
                          <Radio value={0}>0</Radio>
                          <Radio value={2}>2</Radio>
                          <Radio value={3}>3</Radio>
                          <Radio value={4}>4</Radio>
                          <Radio value={5}>5</Radio>
                        </Radio.Group>
                      </div>
                      <div className="payablePoopPoint-sub-title-gray">
                        {' '}
                        오늘 할당 가능 점수 :
                        <div className="payablePoopPoint-content-gray">
                          {' '}
                          {this.state.todayPayablePoopPoint}
                        </div>
                      </div></>
                      ) : (<><div className="payablePoopPoint-sub-title">
                          {' '}
                          똥콜 할당 점수
                          <Radio.Group
                              onChange={this.onChangePoopPoint}
                              value={this.state.poopPoint}
                              className="selPoopPoint">
                            <Radio value={0}>0</Radio>
                            <Radio value={2}>2</Radio>
                            <Radio value={3}>3</Radio>
                            <Radio value={4}>4</Radio>
                            <Radio value={5}>5</Radio>
                          </Radio.Group>
                        </div>
                        <div className="payablePoopPoint-sub-title">
                          {' '}
                          오늘 할당 가능 점수 :
                          <div className="payablePoopPoint-content">
                            {' '}
                            {this.state.todayPayablePoopPoint}
                          </div>
                        </div></>)) : ( this.state.deliverySubsidyCheck === true ? (<><div className="mainTitle">{'배달 지원금'}</div>
                            <div>{'배달 지원금은 지사의 예치금 잔액에서 차감 됩니다.'}</div>
                            <>
                            <FormItem name="deliverySubsidy" style={{display: 'inline-block', width: '150px'}}  className="selectItem">
                              <Input
                                  onKeyDown={(e) => {
                                    console.log(e);
                                    if (e.key === 'Enter') e.preventDefault();
                                  }}
                                  disabled={this.state.data.orderStatus >= 4 ? true : false}
                                  onChange={(e) =>
                                      this.handleChangeInput(e.target.value, 'deliverySubsidy')
                                  }/>
                            </FormItem>
                              <div style={{display: 'inline-block', padding: '5px'}}>원</div>
                            </>
                          </>

                      ) : (<></>))}

                      {/*{this.state.data.poopPoint > 0 ? (*/}
                      {/*  <div className="payablePoopPoint-sub-title-gray">*/}
                      {/*    {' '}*/}
                      {/*    똥콜 할당 점수*/}
                      {/*    <Radio.Group*/}
                      {/*      value={this.state.data.poopPoint}*/}
                      {/*      className="selPoopPoint"*/}
                      {/*      disabled={true}>*/}
                      {/*      <Radio value={0}>0</Radio>*/}
                      {/*      <Radio value={2}>2</Radio>*/}
                      {/*      <Radio value={3}>3</Radio>*/}
                      {/*      <Radio value={4}>4</Radio>*/}
                      {/*      <Radio value={5}>5</Radio>*/}
                      {/*    </Radio.Group>*/}
                      {/*  </div>*/}
                      {/*) : (*/}
                      {/*  <div className="payablePoopPoint-sub-title">*/}
                      {/*    {' '}*/}
                      {/*    똥콜 할당 점수*/}
                      {/*    <Radio.Group*/}
                      {/*      onChange={this.onChangePoopPoint}*/}
                      {/*      value={this.state.poopPoint}*/}
                      {/*      className="selPoopPoint">*/}
                      {/*      <Radio value={0}>0</Radio>*/}
                      {/*      <Radio value={2}>2</Radio>*/}
                      {/*      <Radio value={3}>3</Radio>*/}
                      {/*      <Radio value={4}>4</Radio>*/}
                      {/*      <Radio value={5}>5</Radio>*/}
                      {/*    </Radio.Group>*/}
                      {/*  </div>*/}
                      {/*)}*/}

                      {/*{this.state.data.poopPoint > 0 ? (*/}
                      {/*  <div className="payablePoopPoint-sub-title-gray">*/}
                      {/*    {' '}*/}
                      {/*    오늘 할당 가능 점수 :*/}
                      {/*    <div className="payablePoopPoint-content-gray">*/}
                      {/*      {' '}*/}
                      {/*      {this.state.todayPayablePoopPoint}*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*) : (*/}
                      {/*  <div className="payablePoopPoint-sub-title">*/}
                      {/*    {' '}*/}
                      {/*    오늘 할당 가능 점수 :*/}
                      {/*    <div className="payablePoopPoint-content">*/}
                      {/*      {' '}*/}
                      {/*      {this.state.todayPayablePoopPoint}*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*)}*/}
                    </div>
                  ) : (
                    <div className="contentBlock">
                      <div className="mainTitle">라이더</div>
                      <FormItem name="assignRider" className="selectItem">
                        <div className="orderPayment-wrapper desk">
                          <div className="dis-input">
                            <Input
                              value={
                                this.state.selectedRider
                                  ? this.state.selectedRider.riderName
                                  : data.riderName
                              }
                              style={{
                                width: 540,
                                border: '3px solid darkgray',
                                padding: 0,
                                color: '#000000',
                                textAlign: 'center',
                              }}
                              disabled
                            />
                          </div>

                          <Search
                            onKeyDown={(e) => {
                              console.log(e);
                              if (e.key === 'Enter') e.preventDefault();
                            }}
                            placeholder={
                              '라이더를 즉시 배정하고 싶은 경우 조회해주세요.'
                            }
                            enterButton={'라이더 조회'}
                            onFocus={() =>
                              this.setState({ searchRiderOpen: true }, () =>
                                this.getRiderList()
                              )
                            }
                            onChange={(e) =>
                              this.setState({ riderName: e.target.value }, () =>
                                console.log(this.state.riderName)
                              )
                            }
                            onSearch={this.onSearchRider}
                            onBlur={(e) => {
                              if (!e.relatedTarget) {
                                this.setState({ searchRiderOpen: false });
                              }
                            }}
                          />
                        </div>

                        {this.state.searchRiderOpen && (
                          <div tabIndex={'1'} id="rider-list">
                            <InfiniteScroll
                              dataLength={this.state.riderList.length}
                              height={'10.5em'}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                              next={this.getRiderList}
                              hasMore={!this.riderDataEnd}
                              scrollableTarget="rider-list">
                              {this.state.riderList.map((row, index) => {
                                return (
                                  <div
                                    className="frNameTag"
                                    onClick={() => {
                                      this.onSelectRider(row);
                                      this.setState({
                                        searchRiderOpen: false,
                                      });
                                    }}>
                                    <div>{row.riderName}</div>
                                  </div>
                                );
                              })}
                            </InfiniteScroll>
                          </div>
                        )}
                      </FormItem>
                    </div>
                  )}
                </div>

                <div className="registCallWrapper sub2">
                  {this.props.data && (
                    <div>
                      <div className="contentBlock">
                        <div className="mainTitle">주문상태</div>
                        <Select
                          className="override-input"
                          value={data.orderStatus}
                          onChange={(value) => {
                            if (!modifyType[data.orderStatus].includes(value)) {
                              Modal.info({
                                content: <div>상태를 바꿀 수 없습니다.</div>,
                              });
                              return;
                            }
                            // 대기중 -> 픽업중 변경 시 추천배차 알림
                            if (data.orderStatus === 1 && value === 2) {
                              Modal.info({
                                content: <div>추천배차를 사용하세요.</div>,
                              });
                              return;
                            }
                            // 픽업 -> 접수 변경 시 배차상태로 변경 알림
                            if (data.orderStatus === 3 && value === 1) {
                              Modal.info({
                                content: (
                                  <div>
                                    배차상태로 먼저 변경한 후 접수로
                                    변경해주세요.
                                  </div>
                                ),
                              });
                              return;
                            }
                            //완료를 복원시키는 경우
                            if (data.orderStatus === 4 && value === 3) {
                              const self = this;
                              Modal.confirm({
                                title: '주문복구',
                                content:
                                  '주문을 복구하는 경우 라이더에게 지급된 가맹점 배달료도 복구됩니다. 정말 복구하시겠습니까?',
                                okText: '확인',
                                cancelText: '취소',
                                onOk() {
                                  httpPost(httpUrl.orderCompleteRestore, [], {
                                    orderIdx: data.idx,
                                  }).then((res) => {
                                    if (
                                      res.result === 'SUCCESS' &&
                                      res.data === 'SUCCESS'
                                    ) {
                                      // Modal.info({
                                      //   title: "변경 성공",
                                      //   content: "주문상태가 변경되었습니다.",
                                      // });
                                      this.setState({
                                        data: {
                                          ...data,
                                          orderStatus: value,
                                        },
                                      });
                                    } else if (res.data === 'NOT_CHANGE_STATUS_7_DAYS') {
                                      Modal.info({
                                        title: '변경 실패',
                                        content: '40일 전 주문은 복원 불가합니다.',
                                      });
                                    } else {
                                      Modal.info({
                                        title: '변경 실패',
                                        content:
                                          '주문상태 변경에 실패했습니다.',
                                      });
                                    }
                                  });
                                },
                              });
                              return;
                            } else if (data.orderStatus === 5 && value === 1) {
                              const self = this;
                              Modal.confirm({
                                title: '주문복구',
                                content: '취소된 주문을 복구하시겠습니까?',
                                okText: '확인',
                                cancelText: '취소',
                                onOk() {
                                  httpPost(httpUrl.orderCancelRestore, [], {
                                    orderIdx: data.idx,
                                  }).then((res) => {
                                    if (
                                      res.result === 'SUCCESS' &&
                                      res.data === 'SUCCESS'
                                    ) {
                                      // Modal.info({
                                      //   title: "변경 성공",
                                      //   content: "주문상태가 변경되었습니다.",
                                      // });
                                      this.setState({
                                        data: {
                                          ...data,
                                          orderStatus: value,
                                        },
                                      });
                                    } else {
                                      Modal.info({
                                        title: '변경 실패',
                                        content:
                                          '주문상태 변경에 실패했습니다.',
                                      });
                                    }
                                  });
                                },
                              });
                              return;
                            }
                            const orderStatuseChangeApiCode = [
                              '',
                              httpUrl.orderAssignCancel,
                              httpUrl.orderPickupCancel,
                              httpUrl.orderPickup,
                              httpUrl.orderComplete,
                              httpUrl.orderCancel,
                            ];

                            httpPost(orderStatuseChangeApiCode[value], [], {
                              orderIdx: data.idx,
                            })
                              .then((res) => {
                                if (
                                  res.result === 'SUCCESS' &&
                                  res.data === 'SUCCESS'
                                ) {
                                  this.setState({
                                    data: { ...data, orderStatus: value },
                                  });
                                } else {
                                  Modal.info({
                                    title: '변경 실패',
                                    content: '주문상태 변경에 실패했습니다.',
                                  });
                                }
                              })
                              .catch((e) => {
                                if (
                                  e.response.data.status ===
                                  HttpResponseStatusCode.SERVICE_UNAVAILABLE
                                ) {
                                  Modal.warn({
                                    title: '상태변경 실패',
                                    content:
                                      '이미 상태변경이 처리된 주문입니다.',
                                  });
                                  // 목록 새로고침
                                  global.getOrderList && global.getOrderList();
                                  close();
                                  return;
                                }
                                Modal.info({
                                  title: '변경 실패',
                                  content: '주문상태 변경에 실패했습니다.',
                                });

                                throw e;
                              });
                          }}>
                          {deliveryStatusCode.map((value, index) => {
                            if (index === 0) return <></>;
                            else
                              return (
                                <Option key={index} value={index}>
                                  {value}
                                </Option>
                              );
                          })}
                        </Select>
                      </div>
                      <div
                        className="sub-txt"
                        style={{
                          color: 'red',
                          marginTop: 5,

                          fontSize: 14,
                        }}>
                        *주문상태는 클릭하는 즉시 적용되니 주의하시기 바랍니다.
                      </div>
                    </div>
                  )}
                  <div className="contentBlock">
                    <div className="mainTitle">결제방식</div>
                    <Select
                      className="override-input"
                      defaultValue={
                        data
                          ? data.orderPayments[0].paymentMethod
                          : 1
                      }
                      value={
                        data
                          ? data.orderPayments[0].paymentMethod
                          : 1
                      }
                      disabled={
                        data
                          ? data.orderPayments.length > 1
                          : false
                      }
                      onChange={(value) => {
                        let orderData = cloneDeep(this.state.data);
                        orderData.orderPayments[0].paymentMethod = value;
                        this.setState({ data: orderData });
                      }}>
                      {paymentMethod.map((value, index) => {
                        if (index === 0) {
                          return <></>;
                        }
                        return <Option value={index}>{value}</Option>;
                      })}
                    </Select>
                  </div>
                  <div className="contentBlock content-btn">
                    {/* <div className="mainTitle desk"></div> */}
                    {this.state.paymentOpen && (
                      <PaymentDialog
                        close={this.closePaymentModal}
                        handlePaymentChange={this.handlePaymentChange}
                        orderPayments={
                          data
                            ? data.orderPayments
                            : ''
                        }
                        editable={this.state.editable}
                        orderPrice={
                          data
                            ? data.orderPrice
                            : ''
                        }
                      />
                    )}

                    <Button
                      onClick={this.openPaymentModal}
                      className="paymentBtn"
                      // className="override-input"
                    >
                      분할결제 지정
                    </Button>
                    <Button
                      type="danger"
                      onClick={() => {
                        this.setState({
                          data: {
                            ...data,
                            orderPayments: [
                              {
                                idx: 1,
                                paymentMethod: 1,
                                paymentStatus: 1,
                                paymentAmount: '',
                              },
                            ],
                          },
                        });
                      }}
                      className="paymentBtn-cancel">
                      분할결제 취소
                    </Button>
                  </div>
                  {this.state.data &&
                    this.state.data.orderPayments.length > 1 && (
                      <div className="contentBlock">
                        <div className="mainTitle" />
                        <div className="selectItem" style={{ marginLeft: 20 }}>
                          {this.state.data.orderPayments.map((el) => {
                            return (
                              <div
                                style={{
                                  display: 'inline-block',
                                  backgroundColor: 'black',
                                  color: '#fddc00',
                                  padding: '5px 8px',
                                  borderRadius: 5,
                                  marginRight: 10,
                                }}>
                                {paymentMethod[el.paymentMethod]} :{' '}
                                {comma(el.paymentAmount)} 원
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  <div
                    className="contentBlock ver02"
                    style={{ marginTop: 14, height: 32 }}>
                    <div className="mainTitle">음식준비완료</div>
                    <FormItem name="itemPrepared" className="selectItem">
                      <Checkbox
                        checked={data.itemPrepared}
                        onChange={(e) =>
                          this.handleChangeInput(
                            e.target.checked,
                            'itemPrepared'
                          )
                        }
                      />
                    </FormItem>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">요청시간</div>
                    <FormItem name="arriveReqTime" className="selectItem">
                      <Select
                        defaultValue={arriveReqTime[newOrder.arriveReqTime]}
                        placeholder="시간단위"
                        className="override-input"
                        onChange={(value) =>
                          this.handleChangeInput(
                            parseInt(value),
                            'arriveReqTime'
                          )
                        }
                        required>
                        {Object.keys(arriveReqTime).map((key) => {
                          if (
                            this.pickupTimeMap[key] === null ||
                            this.pickupTimeMap[key] === undefined ||
                            this.pickupTimeMap[key]
                          )
                            return (
                              <Option value={key}>{arriveReqTime[key]}</Option>
                            );
                        })}
                      </Select>
                    </FormItem>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">묶음배송 봉지수</div>
                    <FormItem name="packAmount" className="selectItem">
                      <Select
                        defaultValue={packAmount[1]}
                        placeholder="배달갯수"
                        className="override-input"
                        onChange={(value) =>
                          this.handleChangeInput(parseInt(value), 'packAmount')
                        }
                        required>
                        {Object.keys(packAmount).map((key) => (
                          <Option value={key}>{packAmount[key]}</Option>
                        ))}
                      </Select>
                    </FormItem>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">고객 전화번호</div>
                    <FormItem name="custPhone" className="selectItem">
                      <Input
                        onKeyDown={(e) => {
                          console.log(e);
                          if (e.key === 'Enter') e.preventDefault();
                        }}
                        placeholder="고객 전화번호를 입력해 주세요."
                        className="override-input"
                        onChange={(e) =>
                          this.setState({
                            data: {
                              ...data,
                              custPhone: e.target.value,
                            },
                          })
                        }
                        required
                      />
                    </FormItem>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">메모</div>
                    <FormItem name="callmemo" className="selectItem">
                      <Input
                        onKeyDown={(e) => {
                          console.log(e);
                          if (e.key === 'Enter') e.preventDefault();
                        }}
                        placeholder="메모를 입력해 주세요."
                        className="override-input"
                        onChange={(e) =>
                          this.handleChangeInput(e.target.value, 'custMessage')
                        }></Input>
                    </FormItem>
                  </div>

                  <div className="contentBlock content-btn">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="callTab">
                      등록하기
                    </Button>
                  </div>
                </div>
              </div>
              <div className="mapLayout regist-call-map desk" id="myMap">
                <div
                  className="mainTitle"
                  style={{
                    position: 'absolute',
                    zIndex: 20,
                    marginTop: 10,
                    marginLeft: 10,
                    background: 'black',
                    color: '#fddc00',
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingRight: 10,
                    paddingLeft: 10,
                    borderRadius: 15,
                  }}>
                  거리 : {this.state.distance / 1000} km
                </div>
                {navermaps && (
                  <NaverMap
                    className="mapLayout"
                    defaultZoom={14}
                    center={
                      this.props.data && !this.state.pinStatus
                        ? data.latitude && data.longitude
                          ? navermaps.LatLng(
                              data.latitude,
                              data.longitude
                            )
                          : navermaps.LatLng(lat, lng)
                        : this.state.mapLat && this.state.mapLng
                        ? navermaps.LatLng(this.state.mapLat, this.state.mapLng)
                        : navermaps.LatLng(lat, lng)
                    }
                    onClick={(e) => {
                      this.setState({
                        mapLat: e.latlng.y,
                        mapLng: e.latlng.x,
                        pinStatus: true,
                      });
                      window.naver.maps.Service.reverseGeocode(
                        {
                          location: window.naver.maps.LatLng(
                            e.latlng.y,
                            e.latlng.x
                          ),
                        },
                        (status, response) => {
                          console.log(status);
                          console.log(response);
                          if (status !== navermaps.Service.Status.OK) {
                            Modal.info({
                              title: '조회실패',
                              content:
                                '유효하지 않은 지역입니다. 다시 선택해주세요.',
                            });
                          } else {
                            if (response.result.items.length > 0) {
                              this.setState(
                                {
                                  data: {
                                    ...data,
                                    latitude: e.latlng.y,
                                    longitude: e.latlng.x,
                                    destAddr1: response.v2.address.roadAddress
                                      ? response.v2.address.roadAddress
                                      : response.v2.address.jibunAddress,
                                    destAddr3: response.v2.address.jibunAddress
                                      ? response.v2.address.jibunAddress
                                      : '',
                                  },
                                  selectedDest: {
                                    roadAddress: response.v2.address.roadAddress
                                      ? response.v2.address.roadAddress
                                      : response.v2.address.jibunAddress,
                                    jibunAddress: response.v2.address
                                      .jibunAddress
                                      ? response.v2.address.jibunAddress
                                      : '',
                                  },
                                },
                                () => {
                                  if (this.state.selectedFr.idx !== 0) {
                                    this.getDeliveryPriceByLatLng(
                                      e.latlng.y,
                                      e.latlng.x
                                    );
                                  }
                                  console.log(this.state);
                                }
                              );
                            } else {
                              Modal.info({
                                title: '조회실패',
                                content:
                                  '유효하지 않은 지역입니다. 다시 선택해주세요.',
                              });
                            }
                          }
                        }
                      );
                    }}>
                    <Marker
                      position={
                        this.props.data && !this.state.pinStatus
                          ? data.latitude && data.longitude
                            ? navermaps.LatLng(
                                data.latitude,
                                data.longitude
                              )
                            : navermaps.LatLng(lat, lng)
                          : this.state.mapLat && this.state.mapLng
                          ? navermaps.LatLng(
                              this.state.mapLat,
                              this.state.mapLng
                            )
                          : navermaps.LatLng(lat, lng)
                      }
                      icon={MarkerTargetIcon}
                    />
                    {this.state.selectedFr &&
                      this.state.selectedFr.latitude !== 0 &&
                      this.state.selectedFr.longitude !== 0 && (
                        <>
                          <Marker
                            position={navermaps.LatLng(
                              this.state.selectedFr.latitude,
                              this.state.selectedFr.longitude
                            )}
                            icon={MarkerRiderIcon}
                          />
                          <Polyline
                            path={[
                              this.props.data && !this.state.pinStatus
                                ? data.latitude && data.longitude
                                  ? navermaps.LatLng(
                                      data.latitude,
                                      data.longitude
                                    )
                                  : navermaps.LatLng(lat, lng)
                                : this.state.mapLat && this.state.mapLng
                                ? navermaps.LatLng(
                                    this.state.mapLat,
                                    this.state.mapLng
                                  )
                                : navermaps.LatLng(lat, lng),
                              navermaps.LatLng(
                                this.state.selectedFr.latitude,
                                this.state.selectedFr.longitude
                              ),
                            ]}
                            strokeColor={'#0000ff'}
                            strokeWeight={5}
                          />
                        </>
                      )}
                  </NaverMap>
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }

  render() {
    const lat = 37.643623625321474;
    const lng = 126.66509442649551;

    const { close } = this.props;
    const data = this.state.data;
    const navermaps = window.naver.maps;
    let basicDeliveryPrice = data
      ? data.basicDeliveryPrice : '';

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={() => close(false)} />
        {this.state.pageWidth < 1200 ? (
          <div>{this.renderContent()}</div>
        ) : (
          <Draggable
            axis="both"
            handle=".handle"
            position={null}
            // defaultPosition={{ x: -0, y: -180 }}
            defaultPosition={{ x: -0, y: -180 }}
            cancel=".selectItem, #myMap">
            {this.renderContent()}
          </Draggable>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { login } = state;
  return { login };
};
const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(RegistCallDialog);
