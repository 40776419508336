import { Checkbox, Form, Input } from 'antd';
import React, { Component } from 'react';
import { httpPost, httpUrl } from '../../../api/httpClient';
import { customError, updateError } from '../../../api/Modals';
import '../../../css/modal.css';
import CloseIcon from '../../../img/login/close.png';

class LicenseApproveDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noLicenseApprove: 0,
    };
  }

  componentDidMount() {}

  onRiderSelected = (data) => {
    // console.log(data)
    if (this.props.callback) {
      this.props.callback(data);
    }
    this.props.close();
  };

  noLicenseApprove = async () => {
    const res = await httpPost(httpUrl.noDriverLicenseApprove, [], {
      noLicenseApprove: this.state.noLicenseApprove,
      idx: this.props.data.idx,
    })
      .then((result) => {
        if (result.result === 'SUCCESS' && result.data === true) {
          this.props.close();
        } else updateError();
      })
      .catch((error) => {
        customError('경고', '에러가 발생하였습니다. 다시 시도해주세요.');
      });

    return res;
  };

  render() {
    const { close, multi } = this.props;
    const { data } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="searchFranchise-Dialog">
          <img
            onClick={close}
            src={CloseIcon}
            className="surcharge-close"
            alt="닫기"
          />
          <div className="searchFranchise-content">
            <div className="layout">
              <Checkbox
                className="override-input checkbox"
                //value={this.state.transferableCashNcash}
                checked={
                  data.driverLicenseNumber.includes('0000') ? true : false
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    this.setState({
                      noLicenseApprove: 1,
                    });
                  } else {
                    this.setState({
                      noLicenseApprove: 0,
                    });
                  }
                }}
                onClick={this.noLicenseApprove}>
                승인하는 경우 면허정보를 등록하지 않아도 라이더앱
                이용가능합니다. 자전거를 이용하는 경우만 체크해주세요
              </Checkbox>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LicenseApproveDialog;
