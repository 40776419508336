import { combineReducers } from 'redux';
import branchReducer from './branchReducer';
import login from './loginReducer';
import orderReducer from './orderReducer';
import riderReducer from './riderReducer';
import settingReducer from './settingReducer';
import websock from './websocketReducer';

export default combineReducers({
  login,
  websock,
  orderReducer,
  riderReducer,
  branchReducer,
  settingReducer,
});
