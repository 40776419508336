import { Button, DatePicker, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import moment from 'moment';
import React, { Component } from 'react';
import xlsx from 'xlsx';
import { customError } from '../../../api/Modals';
import { httpGet, httpUrl } from '../../../api/httpClient';
import SelectBox from '../../../components/input/SelectBox';
import '../../../css/modal.css';
import CloseIcon from '../../../img/login/close.png';
import ExcelIcon from '../../../img/login/excel.png';
import { coinCategory } from '../../../lib/util/codeUtil';
import { comma } from '../../../lib/util/numberUtil';

const { RangePicker } = DatePicker;
const today = new Date();

class CoinTransferDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      category: '',
      startDate: moment(today).add(-1, 'M').format('YYYY-MM-DD'),
      endDate: moment(today).format('YYYY-MM-DD'),
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onChangeStatus = (value) => {
    this.setState(
      {
        category: value === 'NONE' ? '' : value,
        pagination: {
          current: 1,
          pageSize: 5,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  //   getList = () => {
  //     var list = [
  //         {
  //             createDate: "2021-06-12",
  //             kind: 1,
  //             userId: "rider03",
  //             userName: "rider03",
  //             registrationNumber: "930507-1000000",
  //             phone: "010-1111-2222",
  //             ncashDelta: 50000
  //         },
  //         {
  //             createDate: "2021-06-23",
  //             kind: 2,
  //             userId: "rider04",
  //             userName: "rider04",
  //             registrationNumber: "950721-2000000",
  //             phone: "010-1212-3333",
  //             ncashDelta: 30000
  //         },
  //         {
  //             createDate: "2021-07-15",
  //             kind: 3,
  //             userId: "rider06",
  //             userName: "rider06",
  //             registrationNumber: "941108-1000000",
  //             phone: "010-2121-1111",
  //             ncashDelta: 20000
  //         }
  //     ]
  //     this.setState({
  //         list:list,
  //     })
  // }

  getList = () => {
    let { data } = this.props;
    let category = this.state.category;
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let userIdx = data.idx;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    httpGet(
      httpUrl.ncashList,
      [category, pageNum, pageSize, userIdx, startDate, endDate],
      {}
    )
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState(
          {
            list: res.data.ncash,
            pagination,
          },
          () => console.log(this.state.list)
        );
      })
      .catch((e) => {
        customError('목록 에러', '에러가 발생하여 목록을 불러올수 없습니다.');
      });
  };

  parseExcelJson = (data) => {
    let result = [
      {
        createDate: '날짜',
        categoryKr: '내역',
        ncashDelta: '금액',
        ncash: '잔액',
        misu: '미수금',
        ncashDeltaMisu: '미수금증감분',
      },
    ];
    data.forEach((item) => {
      result.push({
        createDate: item.createDate,
        categoryKr: item.categoryKr,
        ncashDelta: (item.ncashDelta),
        ncash: item.ncash,
        misu: ((item.misu1 ?? 0) + (item.misu2 ?? 0) + (item.misu3 ?? 0)),
        ncashDeltaMisu: (item.ncashDeltaMisu ?? 0)
      });
    });

    return result;
  };

  onDownload = () => {
    let { data } = this.props;
    httpGet(
      httpUrl.ncashList,
      [
        this.state.category,
        1,
        10000,
        data.idx,
        this.state.startDate,
        this.state.endDate,
      ],
      {}
    ).then((res) => {
      const excelJson = this.parseExcelJson(res.data.ncash);
      const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
      const wb = xlsx.utils.book_new();
      ws['!cols'] = [];
      xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
      xlsx.writeFile(wb, '코인 이력.xlsx');
    });
  };

  onChange = (dateString) => {
    this.setState(
      {
        startDate: moment(dateString[0]).format('YYYY-MM-DD'),
        endDate: moment(dateString[1]).format('YYYY-MM-DD'),
        pagination: {
          current: 1,
          pageSize: 5,
        },
      },
      () => this.getList()
    );
  };

  render() {
    const columns = [
      {
        title: '날짜',
        dataIndex: 'createDate',
        className: 'table-column-center',
        width: '12%',
        // render: (data) => <div>{formatDateToDay(data)}</div>,
      },
      {
        title: '내역',
        dataIndex: 'categoryKr',
        className: 'table-column-center',
        render: (data, row) => (
          <div>{row.batchTitle ? `${data} / ${row.batchTitle}` : data}</div>
        ),
      },
      {
        title: '금액',
        dataIndex: 'ncashDelta',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)} 원</div>,
      },
      {
        title: '잔액',
        dataIndex: 'ncash',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)} 원</div>,
      },
      {
        title: '미수금',
        className: 'table-column-center',
        render: (row) => <div>{comma((row.misu1 ?? 0) + (row.misu2 ?? 0) + (row.misu3 ?? 0))} 원</div>,
      },
      {
        title: '미수금증감분',
        dataIndex: 'ncashDeltaMisu',
        className: 'table-column-center',
        render: (data) => <div>{comma(data ?? 0)} 원</div>,
      },
    ];

    const { close } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="coinList-Dialog">
          <div className="coinList-content">
            <div className="coinList-title">코인 이력</div>
            <img
              onClick={close}
              src={CloseIcon}
              className="coinList-close"
              alt="close"
            />
            <div className="coinList-inner">
              <SelectBox
                // placeholder={'전체'}
                style={{ width: 200 }}
                value={
                  this.state.category === ''
                    ? coinCategory['NONE']
                    : coinCategory[this.state.category]
                }
                code={Object.keys(coinCategory)}
                codeString={coinCategory}
                onChange={(value) => {
                  if (parseInt(value) !== this.state.category) {
                    this.onChangeStatus(value);
                  }
                }}
              />
              <Button
                className="tabBtn sectionTab exel"
                onClick={this.onDownload}>
                <img src={ExcelIcon} alt="" />
                엑셀 다운로드
              </Button>
              <RangePicker
                placeholder={['시작일', '종료일']}
                onChange={this.onChange}
                className="stats-date"
                locale={locale}
                format={'YYYY-MM-DD'}
                style={{ float: 'right' }}
                value={[
                  moment(this.state.startDate, 'YYYY-MM-DD'),
                  moment(this.state.endDate, 'YYYY-MM-DD'),
                ]}
              />
              <div className="listBlock">
                <Table
                  rowKey={(record) => record.idx}
                  dataSource={this.state.list}
                  columns={columns}
                  pagination={this.state.pagination}
                  onChange={this.handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CoinTransferDialog;
