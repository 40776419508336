import React, { Component } from 'react';
import {Form, Input, Button, Select, Modal, Checkbox} from 'antd';
import '../../../css/modal.css';
import '../../../css/modal_m.css';
import { httpUrl, httpPost } from '../../../api/httpClient';
import CloseIcon from '../../../img/login/close.png';
import {
  statusString
} from "../../../lib/util/codeUtil";
import SelectBox from "../../input/SelectBox";
import SMSVerificationDialog from "../sms/SMSVerificationDialog";

const FormItem = Form.Item;

class RegistAccountDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      isModifyMode: false,
      idx: 0,
      id : '',
      password: '',
      name: '',
      phone: '',
      memo: '',
      userStatus: 1,
      passRefresh: false,
      menuAuths: new Set(),
      joinCheck: false,
      orderCheck: false,
      frCheck: false,
      riderCheck: false,
      mallCheck: false,
      ncashCheck: false,
      dayCheck: false,
      accountCheck: false,
      mobileCheck: false,
      isVerificationSuccess: false,
      smsVerification: false,
      deliverySubsidyCheck: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.data != undefined) {
      this.formRef.current.setFieldsValue({ ...this.props.data, password: '' });
      this.setState({
        isModifyMode: true,
        idx: this.props.data.idx,
        id : this.props.data.id,
        name: this.props.data.riderName,
        phone: this.props.data.phone,
        memo: this.props.data.memo,
        userStatus: this.props.data.userStatus,
      });

      if (this.props.data.menuAuths != undefined) {
        for(const auth of this.props.data.menuAuths) {
          this.state.menuAuths.add(auth);
          this.onChangeAuth(auth, true);
        }
      }
    }
  }

  handleSubmit = () => {
    let { id, password, phone, riderName, memo } =
        this.formRef.current.getFieldsValue();
    const menuAuthArr = [...this.state.menuAuths];
    httpPost(
        this.state.isModifyMode
            ? httpUrl.updateController
            : httpUrl.registController,
        [],
        this.state.isModifyMode
            ? {
              idx: this.props.data.idx,
              id,
              password: this.state.passRefresh ? '1111' : null,
              phone: phone.split('-').join(''),
              name: riderName,
              memo,
              userStatus : this.state.userStatus,
              menuAuths: menuAuthArr
            }
            : {
              id,
              password,
              phone: phone.split('-').join(''),
              name: riderName,
              memo,
              userStatus : this.state.userStatus,
              menuAuths: menuAuthArr,
            }
    ).then((res) => {
      console.log(res);
      if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
        Modal.info({
          title: '완료',
          content: this.state.isModifyMode ? '관제계정 수정을 성공했습니다.' : '관제계정 등록에 성공했습니다.',
        });
        this.props.close();
      } else if (res.data === 'DUPLICATED_ID') {
        Modal.warn({
          title: '실패',
          content: '중복된 아이디입니다.',
        });
      } else {
        Modal.warn({
          title: '실패',
          content: this.state.isModifyMode ? '관제계정 수정을 실패했습니다.' : '관제계정 등록에 실패했습니다.',
        });
      }
    });
  };

  onChangeStatus = (e) => {
    this.setState(
        {
          userStatus: e,
        }
    );
  };

  onChangePassRefresh = (e) => {
    this.setState(
        {
          passRefresh: e,
        }
    );
  };

  onChangeAuth = (menu, e) => {
    if (e) {
      this.state.menuAuths.add(menu);
    } else {
      this.state.menuAuths.delete(menu);
    }

    if (menu == 2) {
      this.setState({joinCheck: e})
    }
    else if (menu == 3) {
      this.setState({orderCheck: e})
    }
    else if (menu == 4) {
      this.setState({frCheck: e})
    }
    else if (menu == 5) {
      this.setState({riderCheck: e})
    }
    else if (menu == 6) {
      this.setState({mallCheck: e})
    }
    else if (menu == 7) {
      this.setState({ncashCheck: e})
    }
    else if (menu == 8) {
      this.setState({dayCheck: e})
    }
    else if (menu == 9) {
      this.setState({accountCheck: e})
    }
    else if (menu == 11) {
      this.setState({mobileCheck: e})
    }
    else if (menu == 101) {
      this.setState({deliverySubsidyCheck: e})
    }
  }

  openSmsVerification = () => {
    this.setState({ smsVerification: true });
  };

  closeSmsVerification = () => {
    this.setState({ smsVerification: false });
  };

  verificationSuccess = () => {
    this.setState({ isVerificationSuccess: true });
  }

  render() {
    const { close, data } = this.props;

    return (
        <React.Fragment>
          <div className="Dialog-overlay" onClick={close} />
          <div className="systemKey-Dialog">
            <div className="systemKey-content">
              <div className="systemKey-title">
                {this.state.isModifyMode ? '관제계정 수정' : '관제계정 생성'}
              </div>
              <img
                  onClick={close}
                  src={CloseIcon}
                  className="surcharge-close"
                  alt="img"
              />

              <div className="systemKey-inner">
                <Form
                    style={{width: '700px'}}
                    ref={this.formRef}
                    onFinish={this.handleSubmit}
                    className="create-control-form">

                  <div style={{display: 'inline-block'}}>
                  <div className="create-control-form-sub">
                    <div>
                  <div className="create-control-form-item-title">아이디</div>
                  <FormItem
                      name="id"
                      className="create-control-form-item"
                      rules={[
                        {
                          required: true,
                          message: '아이디를 입력해주세요',
                        },
                      ]}>
                    <Input
                        className="create-control-input"
                        placeholder="아이디"
                        disabled={this.state.isModifyMode}
                    />
                  </FormItem>
                    </div>

                    <div className="create-control-form-item-sub">
                  <div className="create-control-form-item-title">이름</div>
                  <FormItem
                      name="riderName"
                      className="create-control-form-item"
                      rules={[{ required: true, message: '이름을 입력해주세요' }]}>
                    <Input className="create-control-input" placeholder="이름" />
                  </FormItem>
                    </div>

                    <div className="create-control-form-item-sub">
                  <div className="create-control-form-item-title">전화번호</div>
                  <FormItem
                      name="phone"
                      className="create-control-form-item"
                      rules={[
                        { required: true, message: '전화번호를 입력해주세요' },
                      ]}>
                    <Input
                        className="create-control-input"
                        placeholder="전화번호"
                    />
                  </FormItem>
                    </div>

                    <div className="create-control-form-item-sub">
                  <div className="create-control-form-item-title">비고</div>
                  <FormItem
                      name="memo"
                      className="create-control-form-item">
                    <Input
                        className="create-control-input"
                        placeholder="비고"
                        disabled={this.state.isModifyMode}
                    />
                  </FormItem>
                    </div>

                    <div className="create-control-form-item-sub">
                  <div className="create-control-form-item-title">상태</div>
                  <FormItem
                      className="create-control-form-item">
                      <SelectBox
                          style={{display : 'inline'}}
                          defaultValue={this.state.isModifyMode ? statusString[data.userStatus] : statusString[1]}
                          code={Object.keys(statusString)}
                          codeString={statusString}
                          value={this.state.isModifyMode ? statusString[this.state.userStatus] : statusString[this.state.userStatus]}
                          onChange={this.onChangeStatus}
                      />
                  </FormItem>
                    </div>

                    <div className="create-control-form-item-sub">
                  <div className="create-control-form-item-title">비밀번호</div>
                  {this.state.isModifyMode ? (
                    <FormItem
                      name="passRefresh"
                      className="create-control-form-item">
                      <Checkbox
                          defaultChecked={false}
                          onChange={(e) => {
                            this.onChangePassRefresh(e.target.checked)
                          }}
                      ><p style={{width: '190px'}}>초기화( 1111 로 초기화 됩니다.)</p></Checkbox>
                  </FormItem>) : (<FormItem
                      name="password"
                      className="create-control-form-item"
                      rules={[
                        {
                          required: this.state.isModifyMode ? false : true,
                          message: '비밀번호를 입력해주세요',
                        },
                      ]}>
                      <Input
                          type="password"
                          className="create-control-input"
                          placeholder="비밀번호"
                      />
                  </FormItem>)
                  }
                  </div>
                  </div>

                  <div className="create-control-form-sub2">
                  {/*{this.state.smsVerification && (*/}
                  {/*    <SMSVerificationDialog success={this.verificationSuccess} close={this.closeSmsVerification} />*/}
                  {/*)}*/}

                  <div className="create-control-form-item-title2">권한부여</div>
                    {/*<div*/}
                    {/*    style={{textAlign: 'left', paddingLeft: '20px', border: '1px solid', borderRadius: '10px', backgroundColor: this.state.mobileCheck ? 'yellow': 'white'}}>*/}
                    {/*  <p*/}
                    {/*      style={{*/}
                    {/*        paddingTop: '20px',*/}
                    {/*        display: 'inline-block',*/}
                    {/*        width: '50%',*/}
                    {/*        fontWeight: 'bold',*/}
                    {/*      }}>*/}
                    {/*    모바일 관제*/}
                    {/*    <p*/}
                    {/*        style={{*/}
                    {/*          paddingLeft: '12px',*/}
                    {/*          display: 'inline-block',*/}
                    {/*          fontSize: '7px',*/}
                    {/*        }}>*/}
                    {/*      (모바일 관제를 이용하기 위해서는 본인 인증이 필수 입니다.)*/}
                    {/*    </p>*/}
                    {/*  </p>*/}
                    {/*  <Checkbox*/}
                    {/*      disabled={!this.state.isVerificationSuccess}*/}
                    {/*      style={{paddingLeft: '22%'}}*/}
                    {/*      checked={this.state.mobileCheck}*/}
                    {/*      onChange={(e) => {*/}
                    {/*        this.onChangeAuth(11, e.target.checked)*/}
                    {/*      }}*/}
                    {/*  >*/}
                    {/*    <p*/}
                    {/*        style={{*/}
                    {/*          display: 'inline-block',*/}
                    {/*        }}>*/}
                    {/*      권한부여*/}
                    {/*    </p>*/}
                    {/*    <div style={{width: '10px', display: 'inline-block'}}></div>*/}
                    {/*    <Button*/}
                    {/*        disabled={this.state.isVerificationSuccess}*/}
                    {/*        onClick={this.openSmsVerification}*/}
                    {/*        style={{*/}
                    {/*          display: 'inline-block',*/}
                    {/*        }}>*/}
                    {/*      인증하기*/}
                    {/*    </Button>*/}

                    {/*  </Checkbox>*/}
                    {/*</div>*/}
                    <div className="create-control-form-item-auth"
                        style={{backgroundColor: this.state.joinCheck ? 'yellow': 'white'}}>
                      <p
                          style={{
                            paddingTop: '10px',
                            display: 'inline-block',
                            width: '50%',
                          }}>
                        가입/탈퇴 통계
                      </p>
                      <Checkbox
                          checked={this.state.joinCheck}
                          onChange={(e) => {
                            this.onChangeAuth(2, e.target.checked)
                          }}
                      >
                        <p
                            style={{
                              display: 'inline-block',
                            }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                    <div style={{height: '3px'}}></div>
                    <div className="create-control-form-item-auth"
                         style={{backgroundColor: this.state.orderCheck ? 'yellow': 'white'}}>
                      <p
                          style={{
                            paddingTop: '10px',
                            display: 'inline-block',
                            width: '50%',
                          }}>
                        주문 통계
                      </p>
                      <Checkbox
                          checked={this.state.orderCheck}
                          onChange={(e) => {
                            this.onChangeAuth(3, e.target.checked)
                          }}
                      >
                        <p
                            style={{
                              display: 'inline-block',
                            }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                    <div style={{height: '3px'}}></div>
                    <div className="create-control-form-item-auth"
                         style={{backgroundColor: this.state.frCheck ? 'yellow': 'white'}}>
                      <p
                          style={{
                            paddingTop: '10px',
                            display: 'inline-block',
                            width: '50%',
                          }}>
                        가맹점 관리
                      </p>
                      <Checkbox
                          checked={this.state.frCheck}
                          onChange={(e) => {
                            this.onChangeAuth(4, e.target.checked)
                          }}
                      >
                        <p
                            style={{
                              display: 'inline-block',
                            }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                    <div style={{height: '3px'}}></div>
                    <div className="create-control-form-item-auth"
                         style={{backgroundColor: this.state.riderCheck ? 'yellow': 'white'}}>
                      <p
                          style={{
                            paddingTop: '10px',
                            display: 'inline-block',
                            width: '50%',
                          }}>
                        기사 관리
                      </p>
                      <Checkbox
                          checked={this.state.riderCheck}
                          onChange={(e) => {
                            this.onChangeAuth(5, e.target.checked)
                          }}
                      >
                        <p
                            style={{
                              display: 'inline-block',
                            }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                    <div style={{height: '3px'}}></div>
                    <div className="create-control-form-item-auth"
                         style={{backgroundColor: this.state.mallCheck ? 'yellow': 'white'}}>
                      <p
                          style={{
                            paddingTop: '10px',
                            display: 'inline-block',
                            width: '50%',
                          }}>
                        냠냠몰
                      </p>
                      <Checkbox
                          checked={this.state.mallCheck}
                          onChange={(e) => {
                            this.onChangeAuth(6, e.target.checked)
                          }}
                      >
                        <p
                            style={{
                              display: 'inline-block',
                            }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                    <div style={{height: '3px'}}></div>
                    <div className="create-control-form-item-auth"
                         style={{backgroundColor: this.state.ncashCheck ? 'yellow': 'white'}}>
                      <p
                          style={{
                            paddingTop: '10px',
                            display: 'inline-block',
                            width: '50%',
                          }}>
                        예치금 관리
                      </p>
                      <Checkbox
                          checked={this.state.ncashCheck}
                          onChange={(e) => {
                            this.onChangeAuth(7, e.target.checked)
                          }}
                      >
                        <p
                            style={{
                              display: 'inline-block',
                            }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                    <div style={{height: '3px'}}></div>
                    <div className="create-control-form-item-auth"
                         style={{backgroundColor: this.state.accountCheck ? 'yellow': 'white'}}>
                      <p
                          style={{
                            paddingTop: '10px',
                            display: 'inline-block',
                            width: '50%',
                          }}>
                        계정 관리
                      </p>
                      <Checkbox
                          checked={this.state.accountCheck}
                          onChange={(e) => {
                            this.onChangeAuth(9, e.target.checked)
                          }}
                      >
                        <p
                            style={{
                              display: 'inline-block',
                            }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                    <div style={{height: '3px'}}></div>
                    <div className="create-control-form-item-auth"
                         style={{backgroundColor: this.state.deliverySubsidyCheck ? 'yellow': 'white'}}>
                      <p
                          style={{
                            paddingTop: '10px',
                            display: 'inline-block',
                            width: '50%',
                          }}>
                        배달 지원금
                      </p>
                      <Checkbox
                          checked={this.state.deliverySubsidyCheck}
                          onChange={(e) => {
                            this.onChangeAuth(101, e.target.checked)
                          }}
                      >
                        <p
                            style={{
                              display: 'inline-block',
                            }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                  </div>
                    <div style={{width: '10px', height: '10px'}}></div>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="create-control-form-button">
                      {this.state.isModifyMode ? '수정하기' : '등록하기'}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </React.Fragment>
    );
  }
}

export default RegistAccountDialog;
