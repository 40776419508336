import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, Select, Button, Spin, ConfigProvider, Tooltip, Modal } from 'antd';
import { RightSquareOutlined, CloseOutlined, VerticalAlignBottomOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/ko';
import locale from 'antd/lib/locale/ko_KR';
import { comma } from '../../lib/util/numberUtil';
import FileSaver from 'file-saver';
import axios from 'axios';
import Const from '../../const';
import { useSelector } from 'react-redux';
import TaxReturnDialog from '../../components/dialog/settlement/TaxReturnDialog';
import SettlementDialog from '../../components/dialog/settlement/SettlementDialog';
import DepositDialog from '../../components/dialog/DepositDialog';
import AgencyNcashList from './AgencyNcashList';
import AgecnyDeliveryList from './AgecnyDeliveryList';
import AgencyTaxReturnFr from './AgencyTaxReturnFr';
import AgencyTaxReturnRider from './AgencyTaxReturnRider';
import AgencyTaxReturnShareCall from './AgencyTaxReturnShareCall';

const today = moment().format('YYYY-MM-DD');

const AgencyTaxReturn = () => {
  moment.locale('ko');
  const loginInfo = useSelector((state) => state.login.loginInfo);
  const CancelToken = axios.CancelToken;
  const source = useRef(null);
  const [sales, setSales] = useState([]);
  const [costes, setCostes] = useState([]);
  const [payment, setPayment] = useState([]);
  const [totalSales, setTotalSales] = useState([]);
  const [totalCost, setTotalCost] = useState([]);
  const [totalPayment, setTotalPayment] = useState([]);
  const [selectStartDate, setSelectStartDate] = useState(today);
  const [selectEndDate, setSelectEndDate] = useState(today);
  const [isLoading, setIsLoading] = useState(false);
  const [selectDateType, setSelectDateType] = useState('기간 선택');
  const [reqModalOpen, setReqModalOpen] = useState(false);
  const [detailTaxReturnModalOpen, setDetailTaxReturnModalOpen] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalVat, setTotalVat] = useState(0);
  // const [selectOp, setSelectOp] = useState(true);
  const [title, setTitle] = useState('');

  const [pageContent, setPageContent] = useState(0);
  const pageContentCode = [
    {
      value: 0,
      menu: '가맹점',
    },
    {
      value: 1,
      menu: '라이더',
    },
    {
      value: 2,
      menu: '공유콜',
    },
  ];

  const onChangeDate = (type, _, date) => {
    if (type === 'start') {
      setSelectStartDate(moment(date).format('YYYY-MM-DD'));
      const temp = moment(date).add(30, 'd').format('YYYY-MM-DD');
      if (moment(selectEndDate).isAfter(temp)) setSelectEndDate(temp);
    } else {
      setSelectEndDate(moment(date).format('YYYY-MM-DD'));
    }
  };
  const disabledDate = (current, from) => {
    if (selectStartDate) {
      return Math.abs(current.diff(selectStartDate, 'days')) > 30;
    }
    return false;
  };

  const onRequestTaxReturn = () => {
    Modal.success({
      title: '통합 세금 기장 신청',
      content: '신청이 완료되었습니다. 세무사에서 확인하기까지 며칠 소요될 수 있습니다',
    });
    setReqModalOpen(false);
  };

  const getDataFromMongodb = async (params, url) => {
    if (source.current !== null) {
      source.current.cancel();
    }
    source.current = CancelToken.source();
    try {
      return await axios.get(url, {
        baseURL: Const.settlementUrl,
        params: params,
        responseType: 'arraybuffer',
        cancelToken: source.current.token,
      });
    } catch (e) {
      console.error('getting tax-invoice-excel-data is failed: ' + e);
    }
  };

  const onDownloadTaxinvoice = async () => {
    if (isLoading) return;
    let params = {
      startDate: selectStartDate,
      endDate: selectEndDate,
      companyId: loginInfo.companyId,
    };
    const url = '/tax-return/total/excel';
    setIsLoading(true);
    const response = await getDataFromMongodb(params, url);
    if (response) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(
        blob,
        `${loginInfo.branchName}_세금계산서_${moment(selectStartDate).format('YYMMDD')}_${moment(selectEndDate).format(
          'YYMMDD'
        )}.xlsx`
      );
      Modal.success({
        content: '엑셀파일 다운로드가 완료되었습니다.',
      });
      setIsLoading(false);
    } else {
      Modal.error({
        content: '다운로드에 실패했습니다. 관리자에게 문의해주세요.',
      });
    }
    setIsLoading(false);
  };
  const height = window.innerHeight;
  return (
    <div className={'agency-tr-layout'}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          paddingTop: 20,
          flexBasis: 80,
          flexGrow: 0,
        }}>
        <div
          style={{
            width: 15,
            borderBottom: '1px solid #c1c1c1',
          }}
        />
        {pageContentCode.map((code) => {
          return (
            <div
              key={code.value}
              onClick={() => setPageContent(code.value)}
              style={{
                width: 150,
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: 14,
                border: '1px solid #c1c1c1',
                borderRight: code.value !== 2 ? 'none' : '1px solid #c1c1c1',
                borderBottom: pageContent === code.value ? '1px solid #fff' : '1px solid #c1c1c1',
                backgroundColor: pageContent === code.value ? '#fff' : '#eee',
                cursor: 'pointer',
              }}>
              {code.menu}
            </div>
          );
        })}
        <div
          style={{
            borderBottom: '1px solid #c1c1c1',
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingRight: 15,
            height: 60,
          }}>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
            <ConfigProvider locale={locale}>
              <div className={'ncl-input-box'} style={{ width: 140 }}>
                <DatePicker
                  locale={locale}
                  style={{ border: 'none', flex: 1 }}
                  onChange={(_, date) => onChangeDate('start', _, date)}
                  value={moment(selectStartDate)}
                  allowClear={false}
                />
              </div>
              <span style={{ marginRight: 10 }}>-</span>
              <div className={'ncl-input-box'} style={{ width: 140, marginLeft: 0 }}>
                <DatePicker
                  style={{ border: 'none', flex: 1 }}
                  onChange={(_, date) => onChangeDate('end', _, date)}
                  value={moment(selectEndDate)}
                  disabledDate={disabledDate}
                  allowClear={false}
                />
              </div>
            </ConfigProvider>
          </div>
          {/*  5월 배포 이후에 진행예정*/}
          {/*<div>*/}
          {/*  <span style={{ fontSize: 12 }}>복잡하고 번거로운 신고, 냠냠박스에서 대신 처리해드립니다.</span>*/}
          {/*  <Button*/}
          {/*    style={{*/}
          {/*      marginLeft: 10,*/}
          {/*      backgroundColor: '#FADC00',*/}
          {/*      fontSize: 12,*/}
          {/*      fontWeight: 'bold',*/}
          {/*      height: 40,*/}
          {/*      borderRadius: 5,*/}
          {/*    }}*/}
          {/*    onClick={() => {*/}
          {/*      setReqModalOpen(true);*/}
          {/*    }}>*/}
          {/*    통합 세금 기장 신청*/}
          {/*  </Button>*/}
          {/*</div>*/}
        </div>
      </div>
      <div style={{ marginTop: 15, flexGrow: 1 }}>
        {pageContent === 0 && <AgencyTaxReturnFr startDate={selectStartDate} endDate={selectEndDate} />}
        {pageContent === 1 && <AgencyTaxReturnRider startDate={selectStartDate} endDate={selectEndDate} />}
        {pageContent === 2 && <AgencyTaxReturnShareCall startDate={selectStartDate} endDate={selectEndDate} />}
      </div>
    </div>
  );
};
export default AgencyTaxReturn;
