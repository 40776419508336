/*global kakao*/
import { Button, Form, Input, Modal, Table } from 'antd';
import React, { Component } from 'react';
import { httpDelete, httpGet, httpPost, httpUrl } from '../../../api/httpClient';
import { customError } from '../../../api/Modals';
import '../../../css/rider.css';
import PostCodeDialog from '../common/PostCodeDialog';
import SelfAddressDialog from '../franchise/SelfAddressDialog';
import { Marker, NaverMap, Polyline } from 'react-naver-maps';
import CloseIcon from '../../../img/login/close.png';

const FormItem = Form.Item;
const Search = Input.Search;

class SearchAddressDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
        pageSizeOptions: [5, 10, 20, 50, 100],
      },
      list: [],

      // createAptAddr params
      // addr1:'',
      // addr2:'',
      // addr3:'',
      RegistAddType: 1,
      latitude: 0,
      longitude: 0,
      name: '',

      // getList params
      selectAddType: 1,
      searchAddress: '',

      selfAddOpen: false,
      isPostCodeOpen: false,

      addrDeliveryList: [],

      mapLat: null,
      mapLng: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
    // this.getAddrDeliveryList();
  }

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  getList = () => {
    httpGet(
      httpUrl.getAddrAptList,
      [this.state.pagination.current, this.state.pagination.pageSize, '', encodeURIComponent(this.state.searchAddress)],
      {}
    )
      .then((res) => {
        console.log(res);
        if (res.result === 'SUCCESS' && res.data) {
          this.setState({
            list: res.data.addrAptBranches,
            pagination: {
              ...this.state.pagination,
              total: res.data.totalCount,
              current: res.data.currentPage,
            },
          });
        }
      })
      .catch((e) => {});
  };

  // getAddrDeliveryList = () => {
  //   httpGet(httpUrl.branchAddrDeliveryList, [1, 10000], {}).then((res) => {
  //     if (res.result === "SUCCESS") {
  //       console.log(res.data);
  //       this.setState({
  //         addrDeliveryList: res.data.addrDiliveries,
  //         prevAddrDeliveryList: res.data.addrDiliveries,
  //       });
  //     }
  //   });
  // };

  // handleToggle = (index) => {
  //   let newState = [...this.state.addrDeliveryList];
  //   newState[index].canDeliver = !newState[index].canDeliver;
  //   this.setState({ addrDeliveryList: newState }, () => {
  //     console.log(this.state.deliveryZone);
  //     console.log(this.state.prevDeliveryZone);
  //   });
  // };

  // 우편번호 검색
  openPostCode = () => {
    this.setState({ isPostCodeOpen: true });
  };

  closePostCode = () => {
    this.setState({ isPostCodeOpen: false });
  };

  openSelfAdd = () => {
    this.setState({ selfAddOpen: true });
  };

  closeSelfAdd = () => {
    this.setState({ selfAddOpen: false });
  };

  addressSearchKakao = (address) => {
    const geocoder = new kakao.maps.services.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.addressSearch(address, function (result, status) {
        if (status === kakao.maps.services.Status.OK) {
          const coords = [result[0].y, result[0].x];
          resolve(coords);
        } else {
          reject(status);
        }
      });
    });
  };

  // 우편번호 - 주소 저장
  getAddr = (addrData) => {
    console.log('addrData!!!!!!!!!!!');
    console.log(addrData);
    if (addrData.apartment === 'Y') {
      this.setState({
        RegistAddType: 1,
      });
      this.formRef.current.setFieldsValue({
        name: addrData.buildingName,
      });
    }
    this.formRef.current.setFieldsValue({
      addr1: addrData.roadAddress, // 도로명 주소
      addr3: addrData.jibunAddress === '' ? addrData.autoJibunAddress : addrData.jibunAddress, // 지번
    });

    // 좌표변환
    let address = addrData.roadAddress;
    if (typeof addrData.jibunAddress !== 'undefined' && addrData.jibunAddress !== '') {
      address = address + '?query2=' + addrData.jibunAddress;
    }
    httpGet(httpUrl.getGeocode, [address], {})
      .then((res) => {
        const lat = res.data.latitude;
        const lng = res.data.longitude;

        this.setState({
          mapLat: lat,
          mapLng: lng,
        });
      })
      .catch((e) => {
        customError('위치 반환 오류', '위치 데이터가 존재하지 않습니다. 관리자에게 문의하세요.');
        throw e;
      });

    // let result = JSON.parse(res.data.json);
    // if (res.result === "SUCCESS" && result.meta.totalCount !== 0) {
    //   this.setState({
    //     latitude: result.addresses[0].y,
    //     longitude: result.addresses[0].x,
    //   });
    // } else {
    //   this.addressSearchKakao(addrData.roadAddress)
    //     .then((res) => {
    //       const [lat, lng] = res;
    //       this.setState({
    //         latitude: lat,
    //         longitude: lng,
    //       });
    //     })
    //     .catch((e) => {
    //       customError(
    //         "위치 반환 오류",
    //         "위치 데이터가 존재하지 않습니다. 관리자에게 문의하세요."
    //       );
    //       throw e;
    //     });
    // }
  };

  // 주소 검색
  onSearchAddress = (value, event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    this.setState(
      {
        searchAddress: value,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  handleSubmit = () => {
    const formData = this.formRef.current.getFieldsValue();
    if (!formData.name || formData.name.trim().length === 0) {
      Modal.info({
        title: '등록실패',
        content: '이름을 입력해주세요.',
      });
      return false;
    }

    if (!formData.addr1 || formData.addr1.trim().length === 0) {
      Modal.info({
        title: '등록실패',
        content: '주소를 입력해주세요.',
      });
      return false;
    }
    if (!formData.name || formData.name.trim().length === 0) {
      Modal.info({
        title: '등록실패',
        content: '지번주소를 입력해주세요.',
      });
      return false;
    }


    var self = this;
    httpPost(
      httpUrl.createAddrApt,
      [],
      Object.assign(formData, {
        addrType: 1,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        addr2: '',
        status: 1,
      })
    )
      .then((res) => {
        if (res.result === 'SUCCESS') {
          Modal.info({
            title: '등록성공',
            content: '주소 등록에 성공했습니다.',
          });
          self.formRef.current.resetFields();
          self.setState(
            {
              pagination: {
                ...self.state.pagination,
                current: 1,
              },
            },
            () => self.getList()
          );
        }
      })
      .catch((e) => {
        Modal.info({
          title: '등록실패',
          content: '등록에 실패했습니다.',
        });
        throw e;
      });
  };

  onDelete = (idx) => {
    httpDelete(httpUrl.deleteAddrApt, [], { idx: idx })
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          Modal.info({
            title: '삭제성공',
            content: '주소삭제에 성공했습니다.',
          });
          this.getList();
        } else {
          Modal.info({
            title: '삭제실패',
            content: '주소삭제에 실패했습니다.',
          });
        }
      })
      .catch((e) => {
        Modal.info({
          title: '삭제실패',
          content: '주소삭제에 실패했습니다.',
        });
        throw e;
      });
  };

  onChangeStatus = (row) => {
    console.log({
      ...row,
      status: !row.status,
    });
    httpPost(httpUrl.updateAddrApt, [], {
      ...row,
      status: row.status === 0 ? 1 : 0,
    })
      .then((res) => {
        console.log(res);
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          Modal.info({
            title: '변경 성공',
            content: '상태변경에 성공했습니다.',
          });
          this.getList();
        } else {
          Modal.warn({
            title: '변경실패',
            conten: '관리자에게 문의하세요',
          });
        }
      })
      .catch((e) => {
        console.log(e);
        Modal.warn({
          title: '변경실패',
          conten: '관리자에게 문의하세요',
        });
        throw e;
      });
  };

  handleUpdate = async () => {
    let failed = [];
    for (let i = 0; i < this.state.addrDeliveryList.length; i++) {
      console.log(i);
      console.log(this.state.addrDeliveryList[i].canDeliver);
      console.log(this.state.prevAddrDeliveryList[i].canDeliver);
      const res = await httpPost(httpUrl.updateAddrDelivery, [], {
        canDeliver: this.state.addrDeliveryList[i].canDeliver,
        idx: this.state.addrDeliveryList[i].idx,
      });

      console.log('res');
      console.log(res);

      if (res.result !== 'SUCCESS') {
        failed.push(this.state.addrDeliveryList[i].eupMyeonDong);
      }
      // }
    }
    if (failed.length === 0) {
      Modal.info({
        title: '적용 성공',
        content: '배송가능지역 적용에 성공했습니다.',
      });
    } else {
      Modal.info({
        title: '적용 실패',
        content: `${failed} 지역의 적용에 실패했습니다.`,
      });
    }
  };

  // 라디오
  // onChangeRegistAddType = (e) => {
  //   this.setState({ RegistAddType: e.target.value }, () => {});
  // };

  // onChangeSelectAddType = (e) => {
  //   this.setState({ selectAddType: e.target.value }, () => {
  //     this.onSearchAddress("");
  //   });
  // };
  // onChange = (e) => {
  //   this.setState(
  //     {
  //       addressType: e.target.value,
  //     },
  //     () => { }
  //   );
  // };

  render() {
    const lat = 37.643623625321474;
    const lng = 126.66509442649551;

    const navermaps = window.naver.maps;

    const columns = [
      {
        title: '상세정보',
        dataIndex: 'name',
        className: 'table-column-center mobile',
        render: (data, row) => (
          <div className="status-box">
            <p>{row.name}</p>
            주소: {row.addr1}
            <br />
            <Button onClick={() => this.onChangeStatus(row)}>{data ? '배송가능' : '배송불가'}</Button>
            <Button className="tabBtn" onClick={() => this.onDelete(row.idx)}>
              삭제
            </Button>
          </div>
        ),
      },

      {
        title: '이름',
        dataIndex: 'name',
        className: 'table-column-center desk',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '도로명주소',
        dataIndex: 'addr1',
        className: 'table-column-center desk',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '삭제',
        dataIndex: 'delete',
        className: 'table-column-center desk',
        render: (data, row) => (
          <div className="pwChange-btn">
            <Button className="tabBtn" onClick={() => this.onDelete(row.idx)}>
              삭제
            </Button>
          </div>
        ),
      },
    ];

    const { close } = this.props;

    return (
      <React.Fragment>
        <div className="flex-modal-overlay" onClick={close} />

        <div className="flex-wrapper">
          <div className="flex-header">
            <div className="flex-title">주소검색관리</div>

            <img onClick={close} src={CloseIcon} className="dialog-close" alt="" />
          </div>
          <div className="flex-inner searchAddr-box">
            <Form ref={this.formRef} onFinish={this.handleSubmit}>
              <div className="dialog-inner searchAddr-inner">
                <div className="searchAddressWrapper">
                  <div className="searchAddress-name mainText-blue desk">주소 등록</div>
                  <div className="searchAddr-innerBox">
                    <div className="contentBox">
                      <div className="subText">주소</div>
                      <FormItem name="addr1" className="selectItem">
                        <Input placeholder="주소를 입력해 주세요." disabled className="override-input sub" />
                      </FormItem>
                      <PostCodeDialog
                        data={(addrData) => this.getAddr(addrData)}
                        isOpen={this.state.isPostCodeOpen}
                        close={this.closePostCode}
                      />
                    </div>
                    <Button onClick={this.openPostCode} className="override-input addrBtn mobile">
                      검색
                    </Button>
                    <div className="contentBox addCss">
                      <div className="subText desk">지번주소</div>
                      <div className="subText mobile">지번</div>

                      <FormItem name="addr3" className="selectItem">
                        <Input placeholder="지번주소를 입력해 주세요." disabled className="override-input sub" />
                      </FormItem>
                    </div>
                    <div className="contentBox">
                      <div className="subText">이름</div>
                      <FormItem name="name" className="selectItem">
                        <Input placeholder="이름을 입력해 주세요." className="override-input sub" />
                      </FormItem>
                    </div>
                  </div>
                  <div className="searchAddress-btn desk">
                    <Button onClick={this.openPostCode} className="override-input addrBtn">
                      우편번호 검색
                    </Button>
                    {this.state.selfAddOpen && <SelfAddressDialog close={this.closeSelfAdd} />}
                    <Button
                      className="addrBtn"
                      type="primary"
                      htmlType="submit"
                      style={{
                        backgroundColor: '#1890ff',
                        marginBottom: '24px',
                      }}>
                      등록하기
                    </Button>
                  </div>
                </div>
                <Button
                  className="addrBtn addCss mobile"
                  type="primary"
                  htmlType="submit"
                  style={{
                    backgroundColor: '#1890ff',
                    marginBottom: '24px',
                  }}>
                  등록하기
                </Button>
                <div className="mapLayout2 desk" id="myMap">
                  {navermaps && (
                    <NaverMap
                      className="mapLayout2"
                      defaultZoom={16}
                      center={
                        this.state.mapLat && this.state.mapLng
                          ? navermaps.LatLng(this.state.mapLat, this.state.mapLng)
                          : navermaps.LatLng(lat, lng)
                      }
                      onClick={(e) => {
                        this.setState({
                          mapLat: e.latlng.y,
                          mapLng: e.latlng.x,
                          latitude: e.latlng.y,
                          longitude: e.latlng.x,
                        });
                        window.naver.maps.Service.reverseGeocode(
                          {
                            location: window.naver.maps.LatLng(e.latlng.y, e.latlng.x),
                          },
                          (status, response) => {
                            if (status !== navermaps.Service.Status.OK) {
                              Modal.info({
                                title: '조회실패',
                                content: '유효하지 않은 지역입니다. 다시 선택해주세요.',
                              });
                            } else {
                              if (response.result.items.length > 0) {
                                for (let i = 0; i < response.result.items.length; i++) {
                                  const items = response.result.items[i];
                                  if (items.isRoadAddress) {
                                    this.formRef.current.setFieldsValue({
                                      addr1: items.address,
                                    });
                                  }
                                  if (items.isAdmAddress) {
                                    if ((this.state.destAddr1 === null) | (this.state.destAddr1 === '')) {
                                      this.formRef.current.setFieldsValue({
                                        addr1: items.address,
                                        addr3: items.address,
                                      });
                                    }
                                    this.formRef.current.setFieldsValue({
                                      addr3: items.address,
                                    });
                                  }
                                }
                              } else {
                                Modal.info({
                                  title: '조회실패',
                                  content: '유효하지 않은 지역입니다. 다시 선택해주세요.',
                                });
                              }
                            }
                          }
                        );
                      }}>
                      <Marker
                        position={
                          this.state.mapLat && this.state.mapLng
                            ? navermaps.LatLng(this.state.mapLat, this.state.mapLng)
                            : navermaps.LatLng(lat, lng)
                        }
                      />
                    </NaverMap>
                  )}
                </div>
                {/* <div className="searchAddressWrapper">
                  <div className="searchAddress-name mainText-blue  desk">배송가능동</div>
                  <div className="searchAddr-innerBox">
                    {this.state.addrDeliveryList &&
                      this.state.addrDeliveryList.map((obj, idx) => (
                        <div key={obj.idx} className="zone-box">
                          <div
                            className={
                              obj.canDeliver ? "zone-el-active" : "zone-el"
                            }
                            onClick={() => this.handleToggle(idx)}
                          >
                            {obj.eupMyeonDong}
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="searchAddress-btn">
                    <Button
                      onClick={() => this.handleUpdate()}
                      style={{
                        width: 100,
                        backgroundColor: "#1890ff",
                        color: "white",
                      }}
                    >
                      적용하기
                    </Button>
                  </div>
                </div> */}
                <div className="searchAddressWrapper">
                  <div className="searchAddress-name mainText-blue desk">주소 검색</div>

                  <div className="third">
                    <div className="contentBox desk">
                      <div className="subText">주소</div>
                      <div className="searchAddr">
                        <Search
                          placeholder="주소를 검색해 주세요."
                          enterButton
                          allowClear
                          onSearch={(value, event) => this.onSearchAddress(value, event)}
                        />
                      </div>
                    </div>
                    <div className="contentBox mobile">
                      <div className="subText">주소</div>
                      <Search
                        placeholder="주소를 검색해 주세요."
                        enterButton
                        allowClear
                        onSearch={(value, event) => this.onSearchAddress(value, event)}
                        style={{
                          width: '100%',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="dataTableLayout-03">
                  <Table
                    dataSource={this.state.list}
                    columns={columns}
                    pagination={this.state.pagination}
                    onChange={(pagination) => this.handleTableChange(pagination)}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SearchAddressDialog;
