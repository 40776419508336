import { Modal } from "antd";
import React from "react";
import { httpGet, httpUrl } from "../../api/httpClient";
import CloseIcon from '../../img/login/close.png';

const NoticeDetailDialog = ({ close, notice, setNotice, fromWebSocket }) => {
    const getNextNotice = async () => {
        try {
            const res = await httpGet(httpUrl.nextNotice, [[4, 5], notice?.idx], {});

            if (res?.data) setNotice(res?.data);
            else {
                Modal.info({
                    title: '공지사항',
                    content: '마지막 공지입니다.'
                });
            }
        } catch (e) {

        }
    }
    const getPrevNotice = async () => {
        try {
            const res = await httpGet(httpUrl.prevNotice, [[4, 5], notice?.idx], {});

            if (res?.data) setNotice(res?.data);
            else {
                Modal.info({
                    title: '공지사항',
                    content: '첫 공지입니다.'
                });
            }
        } catch (e) {

        }
    }
    return (
        <div className="notice-detail-dialog">
            <div className="notice-detail-content">

                <div style={{display:"flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div className="notice-detail-title">
                        공지사항
                    </div>
                    <div style={{float: "right"}}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: "center"
                        }}>
                            우선순위:
                            <div style={{ fontWeight: 'bold' }}>{notice?.sortOrder ? notice?.sortOrder : '-'}</div>
                            <div style={{marginRight: 20, marginLeft: 20}}>
                                {`등록 일시: ${notice?.createDate ? notice?.createDate : '-'}`}
                            </div>
                            <img
                                onClick={close}
                                // Visible, non-interactive elements with click handlers must have at least one keyboard listener.
                                onKeyDown={() => { }}
                                src={CloseIcon}
                                className="notice-detail-close"
                                alt="닫기"
                            />
                        </div>
                    </div>
                </div>
                
                <div className="notice-detail-categoryTitle">
                    제목
                </div>
                <div className="notice-detail-titlearea">
                    {notice?.title ? notice?.title : '-'}
                </div>

                <div className="notice-detail-categoryTitle">
                    내용
                </div>
                <div className="notice-detail-textarea">
                    {notice?.content ? notice?.content.split('\n').map((line,index) => {
                        return (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        );
                    }) : '-'}
                </div>

                {   !fromWebSocket &&
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                        <div style={{ display: 'flex' }}>
                            <div 
                                className="notice-detail-page-btn" 
                                style={{ marginRight: 10 }}
                                onClick={async () => await getPrevNotice()}
                                // Visible, non-interactive elements with click handlers must have at least one keyboard listener.
                                onKeyDown={() => { }}
                            >이전글</div>
                            <div 
                                className="notice-detail-page-btn"
                                onClick={async () => await getNextNotice()}
                                // Visible, non-interactive elements with click handlers must have at least one keyboard listener.
                                onKeyDown={() => { }}
                            >다음글</div>
                        </div>
                        <div
                            className="notice-detail-list-btn"
                            onClick={() => close()}
                            // Visible, non-interactive elements with click handlers must have at least one keyboard listener.
                            onKeyDown={() => { }}
                        >
                            목록보기
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default NoticeDetailDialog;