import { Button, Form, Input, Modal, Radio, Table } from 'antd';
import React, { Component } from 'react';
import { httpPost, httpUrl } from '../../../api/httpClient';
import '../../../css/modal.css';
import { deliveryPriceFeeType } from '../../../lib/util/codeUtil';
import { comma } from '../../../lib/util/numberUtil';
import CloseIcon from '../../../img/login/close.png';
import { bindActionCreators } from 'redux';
import { login, logout } from '../../../actions/loginAction';
import { connect } from 'react-redux';

const FormItem = Form.Item;

class RiderGroupDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      rowId: 1,
      actionButtonMode: 0, // 0-기본, 1-Create API-Form, 2-Modify API-Form
      selectedGroup: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
    this.formRef.current.setFieldsValue({
      deliveryPriceInsurance: 0.5808,
      deliveryPriceFeeType: 0,
    });
  }

  // handleTableChange = (pagination) => {
  //   console.log(pagination);
  //   const pager = { ...this.state.pagination };
  //   pager.current = pagination.current;
  //   pager.pageSize = pagination.pageSize;
  //   this.setState(
  //     {
  //       pagination: pager,
  //     },
  //     () => this.getList()
  //   );
  // };

  handleSelectedGroupChange = (value, key) => {
    if (this.state.selectedGroup) {
      let inputRes = this.state.selectedGroup;
      inputRes[key] = value;
      this.setState({ selectedGroup: inputRes });
    } else {
      return null;
    }
  };

  onClickRow = (row) => {
    return {
      onClick: () => {
        const deepCopy = Object.assign({}, row);
        this.setState({ selectedGroup: deepCopy, rowId: deepCopy.idx }, () => {
          this.formRef.current.setFieldsValue({
            settingGroupName: row.settingGroupName,
            amountPerOneTime: row.amountPerOneTime,
            deliveryPriceFeeType: row.deliveryPriceFeeType,
            deliveryPriceFeeAmount: row.deliveryPriceFeeAmount,
            deliveryPriceTax: row.deliveryPriceTax,
            deliveryPriceInsurance: row.deliveryPriceInsurance,
          });
          this.changeActionModeState(2);
        });
      },
    };
  };
  setRowClassName = (row, index) => {
    if (this.state.selectedGroup) {
      return row.idx === this.state.selectedGroup.idx ? 'clickRowStyl' : '';
    } else {
      return '';
    }
  };

  getList = () => {
    httpPost(httpUrl.getRiderGroup, [], {}).then((res) => {
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.riderSettingGroups,
        });
      }
    });
  };

  handleSubmit = () => {
    if (this.state.actionButtonMode === 2) {
      httpPost(httpUrl.updateRiderGroup, [], this.state.selectedGroup).then((res) => {
        if (res.result === 'SUCCESS') {
          Modal.info({
            title: '변경 성공',
            content: '기사그룹 설정 변경에 성공했습니다.',
          });
          this.getList();
        } else {
          Modal.info({
            title: '변경 실패',
            content: '기사그룹 설정 변경에 실패했습니다.',
          });
        }
      });
    } else {
      const formBody = this.formRef.current.getFieldValue();
      formBody.branchIdx = this.props.loginReducer.loginInfo.branchIdx;
      httpPost(httpUrl.createRiderGroup, [], formBody)
        .then((res) => {
          if (res.result === 'SUCCESS') {
            Modal.info({
              title: '변경 성공',
              content: '기사그룹 설정 생성에 성공했습니다.',
            });
            this.getList();
          } else {
            Modal.info({
              title: '변경 실패',
              content: '기사그룹 설정 생성에 실패했습니다.',
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.data.message === '"RIDER_SETTING_GROUP_INVALID_PARAMS"') {
            Modal.info({
              title: '변경 실패',
              content: '기사그룹 설정 생성에 실패했습니다.',
            });
          } else {
            Modal.info({
              title: '변경 실패',
              content: '기사그룹 설정 생성에 실패했습니다.',
            });
          }
        });
    }
  };

  renderActionButton = () => {
    if (this.state.actionButtonMode === 0) {
      return (
        <Button type="button" className={'w-[115px] ant-btn-primary'} onClick={() => this.buttonAction()}>
          신규 등록하기
        </Button>
      );
    } else if (this.state.actionButtonMode === 1) {
      return (
        <Button type="button" className={'w-[115px] ant-btn-primary'} onClick={() => this.buttonAction()}>
          신규 등록하기
        </Button>
      );
    } else {
      return (
        <Button type="button" className={'w-[115px] bg-yellow-300 text-black'} onClick={() => this.buttonAction()}>
          수정하기
        </Button>
      );
    }
  };

  changeActionModeState = (nextMode) => {
    this.setState({ actionButtonMode: nextMode });
  };

  buttonAction = () => {
    if (this.state.actionButtonMode === 0) {
      this.changeActionModeState(1);
    } else if (this.state.actionButtonMode === 1) {
      this.handleSubmit();
      this.clearSelectMode();
      this.changeActionModeState(0);
    } else {
      this.handleSubmit();
      this.clearSelectMode();
      this.changeActionModeState(0);
    }
  };

  clearSelectMode() {
    this.setState({
      selectedGroup: null,
    });
    this.formRef.current.resetFields(['settingGroupName', 'amountPerOneTime', 'deliveryPriceFeeAmount']);
    this.changeActionModeState(0);
  }

  render() {
    const columns = [
      {
        title: '그룹명',
        dataIndex: 'settingGroupName',
        className: 'table-column-center',
        render: (data, row) => {
          return (
            <div style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectedGroup: row })}>
              {data}
            </div>
          );
        },
      },
      {
        title: '처리건수',
        dataIndex: 'amountPerOneTime',
        className: 'table-column-center',
        render: (data, row) => {
          return (
            <div style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectedGroup: row })}>
              {data}
            </div>
          );
        },
      },
      {
        title: '배달 수수료 형식',
        dataIndex: 'deliveryPriceFeeType',
        className: 'table-column-center',
        render: (data, row) => {
          return (
            <div style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectedGroup: row })}>
              {deliveryPriceFeeType[data]}
            </div>
          );
        },
      },
      {
        title: '배달수수료',
        dataIndex: 'deliveryPriceFeeType',
        className: 'table-column-center',
        render: (data, row) => {
          return (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => this.setState({ selectedGroup: row }, () => console.log(this.state.selectedGroup))}>
              {data === 0 ? comma(row.deliveryPriceFeeAmount) + ' %' : comma(row.deliveryPriceFeeAmount) + ' 원'}
            </div>
          );
        },
      },
      {
        title: '고용보험료율',
        dataIndex: 'deliveryPriceInsurance',
        className: 'table-column-center',
        render: (data, row) => {
          return (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => this.setState({ selectedGroup: row }, () => console.log(this.state.selectedGroup))}>
              {data + '%'}
            </div>
          );
        },
      },
    ];

    const { close } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="riderGroup-Dialog">
          <div className="riderGroup-content">
            <div className="riderGroup-title">기사 그룹관리</div>
            <img onClick={close} src={CloseIcon} className="riderGroup-close" alt="img" />
            <div className="riderGroup-inner">
              <Form ref={this.formRef} onFinish={this.handleIdSubmit}>
                <div className="listBlock">
                  <Table
                    rowKey="idx"
                    dataSource={this.state.list}
                    columns={columns}
                    // onChange={this.handleTableChange}
                    onRow={this.onClickRow}
                    rowClassName={(row, index) => this.setRowClassName(row, index)}
                    pagination={false}
                  />
                </div>
                <div className="mt-[20px] flex justify-end">{this.renderActionButton()}</div>
                {this.state.actionButtonMode === 0 ? null : (
                  <div>
                    <div className="flex items-center mb-[10px]">
                      <div className="w-[100px]">그룹명</div>
                      <FormItem style={{ margin: 0 }} name="settingGroupName">
                        <Input
                          onChange={(e) => this.handleSelectedGroupChange(e.target.value, 'settingGroupName')}
                          value={this.state.selectedGroup ? this.state.selectedGroup.settingGroupName : ''}
                        />
                      </FormItem>
                    </div>

                    <div className="flex items-center mb-[10px]">
                      <div className="w-[100px]">배차 제한 건수</div>
                      <FormItem style={{ margin: 0 }} name="amountPerOneTime">
                        <Input
                          onChange={(e) => this.handleSelectedGroupChange(e.target.value, 'amountPerOneTime')}
                          value={this.state.selectedGroup ? this.state.selectedGroup.amountPerOneTime : 0}
                        />
                      </FormItem>
                    </div>
                    <div className="flex items-center mb-[10px]">
                      <div className="w-[100px]">배달수수료</div>
                      <FormItem
                        name="deliveryPriceFeeAmount"
                        style={{ margin: 0, paddingRight: '10px' }}
                        rules={[
                          {
                            required: true,
                            message: '배달수수료를 입력해주세요.',
                          },
                        ]}>
                        <Input
                          type="number"
                          value={this.state.deliveryPriceFeeAmount}
                          onChange={(e) => this.handleSelectedGroupChange(e.target.value, 'deliveryPriceFeeAmount')}
                        />
                      </FormItem>
                      <FormItem style={{ margin: 0 }} name="deliveryPriceFeeType">
                        <Radio.Group
                          className=""
                          onChange={(e) => this.handleSelectedGroupChange(e.target.value, 'deliveryPriceFeeType')}>
                          <Radio value={0}>정률</Radio>
                          <Radio value={1}>정액</Radio>
                          <Radio value={2} disabled>
                            일차감
                          </Radio>
                        </Radio.Group>
                      </FormItem>
                    </div>
                    <div className="flex items-center mb-[10px]">
                      <div className="w-[100px]">고용보험료율</div>
                      <FormItem name="deliveryPriceInsurance" style={{ margin: 0 }}>
                        <Input
                          disabled
                          type="number"
                          onChange={(e) => this.handleSelectedGroupChange(e.target.value, 'deliveryPriceInsurance')}
                        />
                      </FormItem>
                    </div>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loginReducer: state.login,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ login, logout }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(RiderGroupDialog);
