import React, {useState} from "react";
import WithdrawList from "./WithdrawList";
import WithdrawAgencyNcash from "./WithdrawAgencyNcash";

const WithdrawListLayout = () => {
    const [pageContent, setPageContent] = useState(0);

    const pageContentCode = [
        {
            value: 0,
            menu: "가맹점출금"
        },
        {
            value: 1,
            menu: "지사입출금"
        }
    ]

    return <>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            marginBottom: 20
        }}>
            {pageContentCode.map((code) => {
                return <>
                    <div style={{
                        flex:1,
                        borderBottomColor: 'grey',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: 1,
                    }}/>
                    <div
                        style={{
                            flex:5,
                            padding: 10,
                            borderColor: 'grey',
                            borderStyle: 'solid',
                            borderWidth: 1,
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            borderBottom: pageContent === code.value ? 'none' : 'grey solid 1px',
                        }}
                        key={code.value}
                        onClick={() => setPageContent(code.value)}
                    >{code.menu}</div>
                </>
            })}
            <div style={{
                flex:100,
                borderBottomColor: 'grey',
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
            }}/>
        </div>
        {pageContent === 0 && <WithdrawList />}
        {pageContent === 1 && <WithdrawAgencyNcash />}
    </>

}

export default WithdrawListLayout;
