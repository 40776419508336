/*global kakao*/
import { ArrowLeftOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Table } from 'antd';
import React, { Component } from 'react';
import { Marker, NaverMap, Polygon } from 'react-naver-maps';
import { reactLocalStorage } from 'reactjs-localstorage';
import { httpGet, httpUrl } from '../../../api/httpClient';
import Const from '../../../const';
import '../../../css/rider.css';
import MarkerTarget2Icon from '../../../img/login/map/marker_target_2.png';
import CloseIcon from '../../../img/login/close.png';

const FormItem = Form.Item;
const Search = Input.Search;

class SearchRegionException extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      navermaps: true,
      mapLat: null,
      mapLng: null,

      deliveryZone: [],
      prevDeliveryZone: [],
      selectMenu: 0,
      mapCenter: {
        lat: 37.643623625321474,
        lng: 126.66509442649551,
      },

      // 신규 할증 구역 parameter
      paths: [],
      viewPaths: [],
    };
    this.formRef = React.createRef();
    this.navermaps = window.naver.maps;
  }

  componentDidMount() {
    // default mapCenter : 지점 위치
    this.setState({
      mapCenter: {
        lat: this.getBranchInfo().branchLatitude,
        lng: this.getBranchInfo().branchLongitude,
      },
    });
    this.getList();
  }

  getBranchInfo = () => {
    const data = reactLocalStorage.getObject(Const.appName + '#adminUser');
    return typeof data === 'object' && data;
  };

  getList = async () => {
    let frIdx = this.props.data.idx;
    let branchIdx = this.getBranchInfo().branchIdx;
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;

    const res = await httpGet(
      httpUrl.searchRegionException,
      [frIdx, branchIdx, pageNum, pageSize],
      {}
    )
      .then((res) => {
        this.setState({
          list: res.data.list,
          pagination: { ...this.state.pagination },
        });
      })
      .catch((e) => {
        console.log(e);
        throw e;
      });
    console.log('list.length: ' + JSON.stringify(this.state.list.length));

    const res2 = await httpGet(
      httpUrl.getDeliveryPriceExtraRegion,
      [1, 10000],
      {}
    )
      .then((res) => {
        console.log(res);
        if (res.result === 'SUCCESS') {
          // 처음 시작시 map에 보여지는 좌표
          let viewPaths = [];
          let parsedList = [];
          // res.data.list.forEach((item) => {
          this.state.list.forEach((item) => {
            let parsedItem = { ...item };
            const JSONParsed = JSON.parse(parsedItem.polygon);
            let naverParsedList = [];
            JSONParsed.forEach((item) => {
              naverParsedList.push(this.navermaps.LatLng(item[0], item[1]));
            });
            // 최종 parsing 된 객체
            parsedItem.polygon = naverParsedList;
            parsedList.push(parsedItem);

            if (parsedItem.enabled) {
              viewPaths.push(parsedItem);
            }
          });

          this.setState(
            {
              viewPaths,
            },
            () =>
              console.log(
                'viewPaths.length(enabled): ' + this.state.viewPaths.length
              )
          );
        }
      })
      .catch((e) => {
        console.log(e);
        throw e;
      });
  };

  render() {
    const lat = 37.643623625321474;
    const lng = 126.66509442649551;

    const navermaps = window.naver.maps;

    const { data, close } = this.props;

    return (
      <React.Fragment>
        <div className="flex-modal-overlay" onClick={close} />
        <div className="flex-wrapper">
          <div className="flex-header">
            <div className="flex-title">{data.frName} 지역할증지역 조회</div>
            <img
              onClick={close}
              src={CloseIcon}
              className="dialog-close"
              alt=""
            />
          </div>
          <div className="dataTableLayout-01">
            <div className="zoneBox-inner-right">
              {navermaps && (
                <NaverMap
                  defaultZoom={14}
                  center={this.state.mapCenter}
                  className="navermap-region">
                  {this.state.paths.length > 0 && (
                    <Polygon
                      paths={this.state.paths}
                      fillColor={'#ff0000'}
                      fillOpacity={0.3}
                      strokeColor={'#ff0000'}
                      strokeOpacity={0.6}
                      strokeWeight={3}
                    />
                  )}
                  {this.state.viewPaths.length > 0 &&
                    this.state.viewPaths.map((elem) => {
                      let sumX = 0;
                      let sumY = 0;

                      elem.polygon.map((el) => {
                        sumX += el.x;
                        sumY += el.y;
                      });
                      const meanX = sumX / elem.polygon.length;
                      const meanY = sumY / elem.polygon.length;

                      const position = navermaps.LatLng(meanY, meanX);

                      return (
                        <>
                          <Polygon
                            paths={elem.polygon}
                            fillColor={'#ff0000'}
                            fillOpacity={0.3}
                            strokeColor={'#ff0000'}
                            strokeOpacity={0.6}
                            strokeWeight={3}
                          />
                          <Marker
                            position={position}
                            icon={{
                              content: [
                                '<div style="background-color: black; color: #fddc00; padding: 10px 20px; border-radius: 5px; font-size:16px; transform:translate(-50%,-50%);">' +
                                  elem.name +
                                  '(' +
                                  elem.price +
                                  ')' +
                                  '</div>',
                              ].join(''),
                            }}
                            title={elem.name}
                          />
                          <Marker
                            position={navermaps.LatLng(
                              this.props.data.latitude,
                              this.props.data.longitude
                            )}
                            icon={MarkerTarget2Icon}
                          />
                        </>
                      );
                    })}
                </NaverMap>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SearchRegionException;
