import { Table } from "antd";
import { useEffect, useState } from "react";
import { httpGet, httpUrl } from "../../api/httpClient";
import { formatDate } from "../../lib/util/dateUtil";
import NoticeDetailDialog from "./NoticeDetailDialog";

const initialPagination = {
    total: 0,
    current: 1,
    pageSize: 5,
}

const ReceiveNoticeTable = ({ refreshTableTrigger, refreshTable }) => {
    const [pagination, setPagination] = useState(initialPagination);
    const [list, setList] = useState([]);
    const [selectedNotice, setSelectedNotice] = useState(null);

    useEffect(() => {
        setPagination(initialPagination);
        if (refreshTable) {
            refreshTable();
        }
    }, []);

    useEffect(() => {
        getList();
    }, [refreshTableTrigger, pagination.current]);

    const getList = () => {
        httpGet(httpUrl.noticeList,
            [   0,
                pagination.current,
                pagination.pageSize,
                [4, 5]
            ], {})
            .then(
                (res) => {
                    console.log(res);
                    setList(res.data.notices);
                    setPagination({
                        ...pagination,
                        total: res.data.totalCount,
                    })
                }
            );
    };

    const columns = [
        {
            title: '번호',
            dataIndex: 'idx',
            className: 'table-column-center',
        },
        {
            title: '우선순위',
            dataIndex: 'sortOrder',
            className: 'table-column-center',
        },
        {
            title: '제목',
            dataIndex: 'title',
            className: 'table-column-center',
            width: 400,
        },
        // {
        //     title: '대상',
        //     dataIndex: 'category',
        //     className: 'table-column-center',
        //     width: 100,
        //     render: (data) => (
        //         <div>{categoryToString(data)}</div>
        //     ),
        // },
        {
            title: '날짜',
            dataIndex: 'createDate',
            className: 'table-column-center',
            width: 150,
            render: (data) => <div>{formatDate(data)}</div>,
        },
    ];


    return <>
        {selectedNotice && (
            <NoticeDetailDialog
                notice={selectedNotice}
                setNotice={setSelectedNotice}
                close={() => setSelectedNotice(null)}
            />
        )}
        <Table
            className="noticeListTable"
            rowKey={(record) => record.idx}
            onRow={(row) => {
                return {
                    onClick: () => setSelectedNotice(row)
                }
            }}
            rowClassName={'receive-notice-table-row'}
            dataSource={list}
            columns={columns}
            pagination={pagination}
            onChange={pagination => setPagination(pagination)}
        />
    </>
}

export default ReceiveNoticeTable;