import { Button, Modal, Table, Tag } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpDelete, httpGet, httpPost, httpUrl } from '../../api/httpClient';
import SelectBox from '../../components/input/SelectBox';
import '../../css/common.css';
import '../../css/staff.css';
import { agencyBatchWorkKind, batchCategory, buyStatusString, kindString } from '../../lib/util/codeUtil';
import { formatDate, formatDateToDay } from '../../lib/util/dateUtil';
import { comma } from '../../lib/util/numberUtil';
import { customAlert, customError } from '../../api/Modals';
import SearchRiderDialog from '../../components/dialog/common/SearchRiderDialog';
import RegistBatchWorkDialog from '../../components/dialog/rider/RegistBatchWorkDialog';
import SearchFranchiseDialog from '../../components/dialog/common/SearchFranchiseDialog';
import NcashBatchWorkTargetDialog from '../../components/dialog/ncash/NcashBatchWorkTargetDialog';

const today = new Date();

class NcashList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      buyStatus: 1,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
      },
      searchRiderOpen: false,
      searchFranOpen: false,
      taskWorkOpen: false,
      updateTaskOpen: false,
      batchWorkTargetOpen: false,
      batchWorkTargetList: [],
      batchWorkSelectIdx: 0,
      batchWorkSelectId: "",
      dialogData: {},
      userIdx: 0,
      kind: '',
      selectedUserType: null
    };
  }

  componentDidMount() {
    console.log(this.props.branchIdx);
    this.getList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.refreshTrigger !== this.props.refreshTrigger) this.getList();
  }

  onChange = (e) => {
    this.setState(
      {
        buyStatus: e.target.value,
      },
      () => this.getList()
    );
  };

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onRefresh = () => {
    this.setState(
      {
        pagination: {
          total: 0,
          current: 1,
          pageSize: 20,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  getList = () => {
    let kind = this.state.kind === 0 ? '' : this.state.kind;
    httpPost(httpUrl.riderBatchWorkList, [], {
      pageNum: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      kind: kind,
    })
      .then((res) => {
        console.log('res.data :' + JSON.stringify(res.data, null, 4));
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState(
          {
            list: res.data.riderBatchWorkList,
            pagination,
          },
          () => console.log(this.state.list)
        );
      })
      .catch((e) => {
        customError('목록 에러', '에러가 발생하여 목록을 불러올수 없습니다.');
      });
  };

  // 일차감 삭제
  onDelete = (row) => {
    let self = this;
    const categoryName = batchCategory[row.category]
    Modal.confirm({
      title: categoryName +  ' 삭제',
      content: <div> {row.title} 를 삭제하시겠습니까?</div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpDelete(httpUrl.riderBatchWorkDelete, [], {
          idx: row.idx,
        })
          .then((res) => {
            if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
              customAlert(categoryName + ' 삭제', row.title + ' '+ categoryName +'이 삭제되었습니다.');
              self.getList();
            } else customError('삭제 오류', '삭제 오류가 발생하였습니다. 다시 시도해 주십시오.');
          })
          .catch((error) => {
            customError('삭제 오류', categoryName + '에 대상자가 있을시 대상자 삭제후 다시 진행해주세요.');
          });
      },
    });
  };

  onAddRider = (riderData) => {
    const isFranchise = riderData.userType === 2;
    httpPost(httpUrl.userBatchWorkCreate, [], {
      userIdx: riderData.idx,
      batchWorkNcashIdx: this.state.userIdx,
    })
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          customAlert(
            `일차감 ${isFranchise ? '가맹점' : '라이더'} 추가`,
            `${isFranchise ? riderData.frName : riderData.riderName} ${
              isFranchise ? '가맹점이' : '라이더가'
            } 추가되었습니다.`
          );
          this.getList();
        } else customError('추가 오류', '오류가 발생하였습니다. 다시 시도해 주십시오.');
      })
      .catch((error) => {
        customError('추가 오류', '오류가 발생하였습니다. 해당 라이더가 이미 추가 되있을 시 추가되지 않습니다.');
      });
  };

  onDeleteRider = (data, userIdx) => {
    var self = this;
    httpDelete(httpUrl.userBatchWorkDelete, [], {
      batchWorkNcashIdx: data.idx,
      userIdx,
    })
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          customAlert('일차감 대상 삭제', '일차감 대상이 삭제되었습니다.');
          self.getList();
        } else customError('삭제 오류', '오류가 발생하였습니다. 다시 시도해 주십시오.');
      })
      .catch((error) => {
        customError('삭제 오류', '오류가 발생하였습니다. 다시 시도해 주십시오.');
      });
  };

  onChangeStatus = (value) => {
    this.setState(
      {
        kind: value === '0' ? '' : value,
        pagination: {
          current: 1,
          pageSize: 20,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  // 기사추가 dialog
  openSearchRiderModal = (data, userType) => {
    // userIdx set
    if (userType === 2) this.setState({ searchFranOpen: true, userIdx: data });
    else this.setState({ searchRiderOpen: true, userIdx: data });
  };
  closeSearchRiderModal = () => {
    this.setState({ searchRiderOpen: false, searchFranOpen: false, userIdx: null });
  };

  //일차감 작업등록
  openTaskWorkModal = () => {
    this.setState({ taskWorkOpen: true });
  };
  closeTaskWorkModal = () => {
    this.setState({ taskWorkOpen: false });
  };

  //일차감 작업수정
  openUpdateTaskWorkModal = (row) => {
    this.setState({ updateTaskOpen: true, dialogData: row });
  };
  closeUpdateTaskWorkModal = () => {
    this.setState({ updateTaskOpen: false });
  };

  // 일차감 대상
  openBatchWorkTargetDialog = (batchWorkIdx, list,userType) => {
    this.setState({
      batchWorkTargetOpen: true,
      batchWorkSelectId: batchWorkIdx,
      batchWorkTargetList: list,
      selectedUserType: userType
    });
  };
  closeBatchWorkTargetDialog = () => {
    this.setState({
      batchWorkTargetOpen: false,
      batchWorkSelectId: "",
      batchWorkTargetList: [],
      selectedUserType: null,
    },()=>this.getList());
  };

  render() {
    const columns = [
      {
        title: '분류',
        dataIndex: 'userType',
        className: 'table-column-center',
        render: (data) => <div>{data === 1 ? '기사' : '가맹점'}</div>,
      },
      {
        title: '구분',
        dataIndex: 'kind',
        className: 'table-column-center',
        render: (data) => <div>{agencyBatchWorkKind[data]}</div>,
      },
      {
        title: '일/월구분',
        dataIndex: 'category',
        className: 'table-column-center',
        render: (data) => <div>{batchCategory[data]}</div>,
      },
      {
        title: '차감명',
        dataIndex: 'title',
        className: 'table-column-left-only',
      },
      {
        title: '차감금액',
        dataIndex: 'ncashDelta',
        className: 'table-column-right-only',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '시작일',
        dataIndex: 'startDate',
        className: 'table-column-center',
        render: (data) => <div>{formatDateToDay(data)}</div>,
      },
      {
        title: '종료일',
        dataIndex: 'endDate',
        className: 'table-column-center',
        render: (data) => <div>{formatDateToDay(data)}</div>,
      },
      {
        title: '받는 대상자',
        dataIndex: 'targetUserName',
        className: 'table-column-center',
        render: (data) => <div>{data||''}</div>,
      },
      {
        title: '회수 대상자',
        className: 'table-column-center',
        render: (row) => {
          const data = row.users;
          const batchWorkIdx = row.idx
          const count = data.length;
          if (count === 0) {
            return <span></span>;
          } else {
            let content = '';
            if (count === 1) {
              content = row.userType === 1 ? data[0].riderName: data[0].frName;
            } else {
              content = `${row.userType === 1 ? data[0].riderName: data[0].frName} 외 ${count - 1}명`;
            }
            return (
              <div onClick={() => this.openBatchWorkTargetDialog(batchWorkIdx ,data, row.userType)} className="cursor-pointer underline">
                {content}
              </div>
            );
          }
        },
      },
      {
        title: '회수 대상 추가',
        dataIndex: 'idx',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn"
              onClick={() => {
                // 모달열리면서 data추가
                this.openSearchRiderModal(data, row.userType);
              }}>
              추가
            </Button>
          </div>
        ),
      },
      {
        title: '수정',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            <Button className="tabBtn" onClick={() => this.openUpdateTaskWorkModal(row)}>
              수정
            </Button>
          </div>
        ),
      },
      {
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            <Button className="tabBtn surchargeTab" onClick={() => this.onDelete(row)}>
              삭제
            </Button>
          </div>
        ),
      },
    ];

    const expandedRowRender = (record) => {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {record.users.map((user) => {
            return (
              <Tag
                key={user.idx}
                style={{ fontSize: 14, padding: 5 }}
                closable
                onClose={() => this.onDeleteRider(record, user.idx)}>
                {user.riderName ? user.riderName : user.frName}
              </Tag>
            );
          })}
        </div>
      );
      // alert(JSON.stringify(record.users))
      // const dropColumns = [
      //   {
      //     dataIndex: 'riderName',
      //     className: 'table-column-center',
      //     render: (data, row) => (
      //       <>
      //         <Tag
      //           key={row.userIdx}
      //           style={{ fontSize: 14, padding: 5 }}
      //           closable
      //           onClose={() => this.onDeleteRider(record, row.idx)}>
      //           {data ? data : row.frName}
      //         </Tag>
      //       </>
      //     ),
      //   },
      // ];
      // return (
      //   <Table
      //     className="subTable"
      //     rowKey={(record) => `record: ${record.idx}`}
      //     columns={dropColumns}
      //     dataSource={record.users}
      //     pagination={false}
      //   />
      // );
    };

    return (
      <div style={{ marginTop: 20 }}>
        <div className="taskScheduler-btn-01">
          <SelectBox
            style={{ width: 200, marginBottom: 10 }}
            value={this.state.kind === '' || this.state.kind === 0 ? '전체' : agencyBatchWorkKind[this.state.kind]}
            code={Object.keys(agencyBatchWorkKind)}
            codeString={agencyBatchWorkKind}
            onChange={(value) => {
              if (parseInt(value) !== this.state.kind) {
                this.onChangeStatus(value);
              }
            }}
          />
          {/* <a href="/batchwork_regist_templete.ods" download>
            <Button
              className="download-btn"
              style={{
                float: 'right',
                marginLeft: 10,
                marginBottom: 20,
              }}
              onClick={{}}>
              <img
                style={{ display: 'inline' }}
                src={ExcelIcon}
                alt=""
              />
              양식 다운로드
            </Button>
          </a>

          <Button
            style={{ marginLeft: 10 }}
            className="download-btn"
            onClick={this.toggleExcelInput}>
            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
            엑셀 등록
          </Button>

          {this.state.excelInputOpen && (
            <div style={{ display: 'flex', height: 32 }}>
              <Input ref={this.excelInputRef} type="file" />
              <Button
                onClick={() =>
                  this.readExcel(
                    this.excelInputRef.current.input.files[0]
                  )
                }>
                일괄등록
              </Button>
            </div>
          )} */}
        </div>
        {/* <div className="selectLayout"/> */}
        <div className="dataTableLayout">
          <Table
            rowKey={(record) => record.idx}
            dataSource={this.state.list}
            columns={columns}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
            // expandedRowRender={expandedRowRender}
          />
        </div>

        {this.state.searchRiderOpen && (
          <SearchRiderDialog close={this.closeSearchRiderModal} callback={(riderData) => this.onAddRider(riderData)} />
        )}
        {this.state.searchFranOpen && (
          <SearchFranchiseDialog
            close={this.closeSearchRiderModal}
            callback={(riderData) => this.onAddRider(riderData)}
          />
        )}
        {this.state.batchWorkTargetOpen && (
          <NcashBatchWorkTargetDialog
            batchWorkIdx={this.state.batchWorkSelectId}
            list={this.state.batchWorkTargetList}
            userType={this.state.selectedUserType}
            close={this.closeBatchWorkTargetDialog}></NcashBatchWorkTargetDialog>
        )}
        {this.state.taskWorkOpen && <RegistBatchWorkDialog close={this.closeTaskWorkModal} callback={this.onRefresh} />}
        {this.state.updateTaskOpen && (
          <RegistBatchWorkDialog
            close={this.closeUpdateTaskWorkModal}
            callback={this.onRefresh}
            data={this.state.dialogData}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  branchIdx: state.login.loginInfo.branchIdx,
});

export default connect(mapStateToProps, null)(NcashList);
