import { SET_BRANCH_INFO } from '../actions/branchAction';

const branchInitialState = {
  branchInfo: {
    branchCode: 0,
    branchName: '',
    delayPickupTime: 0,
    deleteDate: null,
    deleted: null,
    deliveryEnabled: null,
    deliveryStatShow: null,
    endTime: '',
    extCode: '',
    idx: 0,
    latitude: 0,
    longitude: 0,
    maxDeliveryFee: 0,
    pickupAvTime0: null,
    pickupAvTime5: null,
    pickupAvTime5After: null,
    pickupAvTime10: null,
    pickupAvTime10After: null,
    pickupAvTime15: null,
    pickupAvTime15After: null,
    pickupAvTime20: null,
    pickupAvTime20After: null,
    pickupAvTime25: null,
    pickupAvTime25After: null,
    pickupAvTime30: null,
    pickupAvTime35: null,
    pickupAvTime40: null,
    pickupAvTime45: null,
    pickupAvTime50: null,
    pickupAvTime55: null,
    pickupAvTime60: null,
    pickupAvTime70: null,
    startDate: '',
    startTime: '',
    tel: '',
    showCount: '-',
    companyId: '',
  },
};

const branchReducer = (state = branchInitialState, action) => {
  switch (action.type) {
    case SET_BRANCH_INFO:
      return {
        ...state,
        branchInfo: action.branchInfo,
      };

    default:
      return state;
  }
};

export default branchReducer;
