import { Button, Checkbox, Input, Modal, Table } from 'antd';
import React, { Component } from 'react';
import { httpPost, httpUrl } from '../../../api/httpClient';
import SelectBox from '../../../components/input/SelectBox';
import '../../../css/modal.css';
import { tableStatusString } from '../../../lib/util/codeUtil';
import CloseIcon from '../../../img/login/close.png';

const Search = Input.Search;
const today = new Date();

class SearchFranchiseDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: today,
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      addressType: 0,
      franStatus: 0,
      frName: '',
      franGroup: 0,
      dataIdxs: [],
      selectedRowKeys: [],
      selectedRows: [],
      isMulti: this.props.multi,

      exceptionFranList: [],
    };
    // this.formRef = React.createRef();
  }

  async componentDidMount() {
    this.getList(true);
    if (this.props.getExceptionFranList) {
      const res = await this.props.getExceptionFranList();

      this.setState({
        exceptionFranList: res.data,
      });
    }
  }

  // 가맹점 검색
  onSearchFranchisee = (value) => {
    this.setState(
      {
        frName: value,
        pagination: {
          total: 0,
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  handleTableChange = (pagination) => {
    this.setState(
      {
        pagination,
      },
      () => this.getList()
    );
  };

  getList = (isInit) => {
    httpPost(httpUrl.franchiseList, [], {
      frName: this.state.frName,
      pageNum: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      userGroup: this.state.franGroup,
      userStatus: this.state.franStatus === 0 ? null : this.state.franStatus,
    }).then((result) => {
      console.log(result);
      this.setState({
        list: result.data.franchises,
        pagination: { ...this.state.pagination, total: result.data.totalCount },
      });
    });
  };

  onSelectChange = (selectedRowKeys) => {
    var cur_list = this.state.list;
    var overrideData = {};
    for (let i = 0; i < cur_list.length; i++) {
      var idx = cur_list[i].idx;
      if (selectedRowKeys.includes(idx)) overrideData[idx] = true;
      else overrideData[idx] = false;
    }

    var curIdxs = this.state.dataIdxs;
    curIdxs = Object.assign(curIdxs, overrideData);

    selectedRowKeys = [];
    for (let i = 0; i < curIdxs.length; i++) {
      if (curIdxs[i]) {
        selectedRowKeys = [...selectedRowKeys, i];
      }
    }
    this.setState({
      selectedRowKeys: selectedRowKeys,
      dataIdxs: curIdxs,
    });
  };

  onSubmit = (e) => {
    if (this.props.callback) {
      this.props.callback(this.state.selectedRows);
    }
    this.props.close();
  };

  onChangeMulti = (e) => {
    // console.log(e.target.value)
    this.setState({ isMulti: e.target.value });
  };

  onFrSelected = (data) => {
    // console.log(data)
    if (this.props.callback) {
      this.props.callback(data);
    }
    this.props.close();
  };

  onCheckAll = async () => {
    const res = await this.props.exceptionFranAllCallback();

    console.log('exceptionFranAllCallback');
    console.log(res);

    if (!res.data) {
      Modal.warn({
        title: '처리실패',
        content: '예외처리 적용에 실패했습니다.',
      });
    } else {
      const res = await this.props.getExceptionFranList();

      console.log('getExceptionFranList');
      console.log(res);

      this.setState({
        exceptionFranList: res.data,
      });
    }
  };

  onCheckNone = async () => {
    const res = await this.props.exceptionFranNoneCallback();

    console.log('exceptionFranNoneCallback');
    console.log(res);

    if (!res.data) {
      Modal.warn({
        title: '처리실패',
        content: '예외처리 적용에 실패했습니다.',
      });
    } else {
      const res = await this.props.getExceptionFranList();

      console.log('getExceptionFranList');
      console.log(res);

      this.setState({
        exceptionFranList: res.data,
      });
    }
  };

  render() {
    const columns = [
      {
        title: '순번',
        dataIndex: 'idx',
        className: 'table-column-center',
      },
      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center',
        render: (data, row) =>
          this.state.isMulti ? (
            <div>{data}</div>
          ) : (
            <div
              className="frNameTag"
              onClick={() => {
                if (this.props.onSelect) {
                  this.props.onSelect(row);
                }
                this.onFrSelected(row);
              }}>
              {data}
            </div>
          ),
      },
      this.props.exceptionFranCallback
        ? {
          title: '예외처리',
          dataIndex: 'idx',
          className: 'table-column-center',
          render: (data, row) => (
            /* {this.state.exceptionFranList.includes(data)
                  ? "제외"
                  : "예외처리"} */
            <Checkbox
              checked={this.state.exceptionFranList.includes(data)}
              onClick={async () => {
                console.log('넘겨주기' + this.state.exceptionFranList);
                const res = await this.props.exceptionFranCallback(
                  this.state.exceptionFranList,
                  row.idx,
                  row.frName
                );
                console.log('exceptionFranCallback');
                console.log(res);
                if (!res.data) {
                  Modal.warn({
                    title: '처리실패',
                    content: '예외처리 적용에 실패했습니다.',
                  });
                } else {
                  const res = await this.props.getExceptionFranList();

                  console.log('getExceptionFranList');
                  console.log(res);

                  this.setState({
                    exceptionFranList: res.data,
                  });
                }
              }}
              onChange={(e) => {
                if (e.target.checked) {
                  this.setState({
                    exceptionFranList:
                      this.state.exceptionFranList.concat(data),
                  });
                } else {
                  this.setState({
                    exceptionFranList: this.state.exceptionFranList.filter(
                      (item) => item.idx !== row.idx
                    ),
                  });
                }
              }}
            />
          ),
        }
        : {},
    ];
    const multiColumns = [
      {
        title: '선택',
        dataIndex: 'idx',
        className: 'table-column-center',
        render: (data, row) => (
          <Checkbox
            checked={this.state.selectedRows.includes(row)}
            onChange={(e) => {
              if (e.target.checked) {
                this.setState({
                  selectedRows: this.state.selectedRows.concat(row),
                });
              } else {
                this.setState({
                  selectedRows: this.state.selectedRows.filter(
                    (item) => item.idx !== row.idx
                  ),
                });
              }
            }}
          />
        ),
      },
      {
        title: '순번',
        dataIndex: 'idx',
        className: 'table-column-center',
      },
      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center',
        render: (data, row) =>
          this.state.isMulti ? (
            <div>{data}</div>
          ) : (
            <div
              className="frNameTag"
              onClick={() => {
                if (this.props.onSelect) {
                  this.props.onSelect(row);
                }
                this.onFrSelected(row);
              }}>
              {data}
            </div>
          ),
      },
    ];

    const selectedRowKeys = this.state.selectedRowKeys;
    // console.log(selectedRowKeys);
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const { close, multi } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="searchFranchise-Dialog">
          <div className="searchFranchise-content">
            <div className="searchFranchise-title">가맹점조회</div>
            <img
              onClick={close}
              src={CloseIcon}
              className="surcharge-close"
              alt="닫기"
            />

            <div className="layout">
              <div className="searchFranchiseWrapper">
                <div className="searchFranchise-list">
                  <div className="inputBox inputBox-searchFranchise sub autocomplete">
                    <SelectBox
                      value={tableStatusString[this.state.franStatus]}
                      code={Object.keys(tableStatusString)}
                      codeString={tableStatusString}
                      onChange={(value) => {
                        if (parseInt(value) !== this.state.franStatus) {
                          this.setState(
                            {
                              franStatus: parseInt(value),
                              pagination: {
                                current: 1,
                                pageSize: 10,
                              },
                            },
                            () => this.getList()
                          );
                        }
                      }}
                    />

                    <Search
                      id="autoInput"
                      placeholder="가맹점검색"
                      className="searchFranchiseInput"
                      enterButton
                      allowClear
                      onSearch={this.onSearchFranchisee}
                    />

                    <span className="searchFranchise-checkAll">
                      {this.props.exceptionFranAllCallback &&
                        this.props.exceptionFranNoneCallback ? (
                        <div>
                          <Button onClick={this.onCheckAll}>전체선택</Button>
                          <Button onClick={this.onCheckNone}>전체해제</Button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </span>

                    {/* 멀티 기능 */}
                    {/* {multi && (
                      <Radio.Group
                        onChange={this.onChangeMulti}
                        value={this.state.isMulti}
                        className="selMulti"
                      >
                        <Radio value={false}>single</Radio>
                        <Radio value={true}>multi</Radio>
                      </Radio.Group>
                    )} */}
                  </div>

                  {/* 멀티 기능 */}
                  {this.state.isMulti && (
                    <Button
                      type="primary"
                      onClick={() => this.onSubmit()}
                      className="submitBtn">
                      등록
                    </Button>
                  )}
                </div>
              </div>

              <div className="dataTableLayout-01">
                {this.state.isMulti ? (
                  <Table
                    rowKey={(record) => record.idx}
                    // rowSelection={rowSelection}
                    dataSource={this.state.list}
                    columns={multiColumns}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                  />
                ) : (
                  <Table
                    rowKey={(record) => record.idx}
                    dataSource={this.state.list}
                    columns={columns}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SearchFranchiseDialog;
