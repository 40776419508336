import React, { Component } from 'react';
import { Form, Input, Button, Modal } from 'antd';
import '../../../css/modal.css';
import '../../../css/modal_m.css';
import { connect } from 'react-redux';
import { httpUrl, httpPost } from '../../../api/httpClient';
import { customAlert, customError } from '../../../api/Modals';
import CloseIcon from '../../../img/login/close.png';

const FormItem = Form.Item;

class RiderTeamRegistDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {}

  handleClear = () => {
    this.formRef.current.resetFields();
  };

  // 팀추가
  handleSubmit = () => {
    const name = this.formRef.current.getFieldValue('name');
    if (name === undefined || name === null || name === '') {
      Modal.info({
        content: "팀명을 확인하세요."
      });
      return;
    }
    httpPost(httpUrl.registRiderTeam, [], {
      branchIdx: this.props.branchIdx,
      name,
    }).then((res) => {
      console.log(res);
      if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
        customAlert('새로운 그룹 추가', name + '그룹을 추가 하였습니다.');
        this.handleClear();
        this.props.close();
      } else if (
        res.result === 'SUCCESS' &&
        res.data === 'DUPLICATE_ID'
      ) {
        Modal.warn({
          title: '등록 오류',
          content: '동일한 팀명이 이미 존재합니다.',
        });
        return;
      } else {
        customError('목록 에러', '에러가 발생하여 삭제할수 없습니다.');
      }
    });
  };

  render() {
    const { close } = this.props;

    return (
      <React.Fragment>
        <React.Fragment>
          <div className="surchargeGroup-Dialog-overlay" onClick={close} />
          <div className="surchargeGroup-Dialog">
            <div className="surchargeGroup-content">
              <div className="surchargeGroup-title">팀 추가</div>
              <img
                onClick={close}
                src={CloseIcon}
                className="addRider-close"
                alt="close"
              />

              <Form ref={this.formRef} onFinish={this.handleSubmit}>
                <div className="surchargeGrouplayout">
                  <div className="surchargeGroupWrapper">
                    <div className="contentBlock">
                      <div className="mainTitle">팀명</div>
                      <div className="surGroup-input">
                        <FormItem name="name" className="selectItem">
                          <Input
                            placeholder="팀명을 입력해 주세요."
                            className="override-input"></Input>
                        </FormItem>
                      </div>
                    </div>

                    <div className="submitBlock">
                      <Button type="primary" htmlType="submit">
                        등록하기
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
  };
};
export default connect(mapStateToProps)(RiderTeamRegistDialog);
