
import { isArray } from 'lodash';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { reactLocalStorage } from 'reactjs-localstorage';
import Const from '../../const';

const getOrderShareViewInfo = async (branchIdx, noCache) => {
  /**
   * {
   *    share: [{}] - 공유걸린 모든 지점정보 목록
   *    view: [] - 접수목록 표시세팅된 branchIdx 리스트
   * }
   */

  if (!noCache && global.orderShareView && Array.isArray(global.orderShareView.view)) return global.orderShareView;

  let response = {
    share: [],
    view: [branchIdx]
  }
  try {
    const share = (await httpGet(httpUrl.getBranchOrderShareByTarget,[branchIdx],{})).data.branchOrderShareList;
    response.share = share;

    let view = [branchIdx];
    const storedView = reactLocalStorage.getObject(Const.appName + '#orderShareViewBranches');
    if (isArray(storedView)) {
      for (let i=0;i<storedView.length;i++) {
        if (share.findIndex(x=>x.idx == storedView[i]) >= 0) {
          if (!view.includes(storedView[i])) view.push(storedView[i])
        }
      }
    }
    response.view = view;
    global.orderShareView = response;
  }
  catch(e) {
  }
  return response;
};
const setOrderShareViewInfo = (view) => {
  reactLocalStorage.setObject(Const.appName + '#orderShareViewBranches', view);
}


export { getOrderShareViewInfo, setOrderShareViewInfo };
