import { Button, Form, Input, Modal, Radio, Select } from 'antd';
import React, { Component } from 'react';
import { httpPost, httpUrl } from '../../api/httpClient';
import { customAlert, customError } from '../../api/Modals';
import CloseIcon from '../../img/close.png';
import SearchRiderDialog from './common/SearchRiderDialog';
import SearchFranchiseDialog from './common/SearchFranchiseDialog';
import {
  adminSendKindString,
  adminSendKindStringForAgencyService,
  adminSendKindStringForAgencyServiceFranchise,
  searchType,
} from '../../lib/util/codeUtil';
import '../../css/modal.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import VerificationCheckDialog from '../../pages/ncash/VerificationCheckDialog';
import SelectBox from '../input/SelectBox';

const Search = Input.Search;
const FormItem = Form.Item;
const Option = Select.Option;

class DepositDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 1,
      },
      openSearchFranModal: false,
      selectedFran: null,
      searchType: 0,
      openSearchRiderModal: false,
      selectedRider: null,
      verified: false,
      openSmsVerificationDialog: false,
      kind: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    console.log(this.props);
  }

  openSearchFranModal = () => {
    this.setState({ openSearchFranModal: true });
  };
  closeSearchFranModal = () => {
    this.setState({ openSearchFranModal: false });
  };

  handleSubmit = () => {
    let self = this;
    const form = this.formRef.current;
    let searchType = this.props.preMode ? 1 : this.state.searchType;
    const ncashAmount = form.getFieldValue('ncashAmount');

    if (this.state.kind !== null && isNaN(form.getFieldValue('kind'))) {
      Modal.warn({
        title: '구분 선택 오류',
        content: '구분값을 확인해주세요.',
      });
      return;
    }

    console.log(self.formRef.current.getFieldsValue());
    if (ncashAmount < 0) {
      Modal.warn({
        title: '지급금액 오류',
        content: '지급금액을 확인해주세요.',
      });
      return;
    }

    if (searchType === 0) {
      Modal.confirm({
        title: '예치금 지금',
        content:
          this.state.selectedRider.riderName +
          ' 라이더에 ' +
          ' 예치금 ' +
          form.getFieldValue('ncashAmount') +
          ' 원을 지급하시겠습니까?',
        okText: '확인',
        cancelText: '취소',
        onOk() {
          httpPost(httpUrl.depositSend, [], {
            receiveUserIdx: self.state.selectedRider.userIdx,
            ...self.formRef.current.getFieldsValue(),
            userType: 1,
          })
            .then((result) => {
              console.log(result);
              if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
                customAlert('예치금 지급 완료', '해당 라이더에게 예치금을 지급하였습니다.');
                self.props.close();
                self.props.getList();
                // self.props.refresh();
              } else if (result.result === 'SUCCESS' && result.data === 'INVALID_NCASH_AMOUNT') {
                customError('예치금 지급 에러', '예치금 잔액이 부족합니다.');
              } else {
                customError('예치금 지급 에러', '서버 에러로 인해 예치금 지급에 실패하였습니다');
              }
            })
            .catch((e) => {
              console.error(e);
              customError('예치금 지급 에러', '서버 에러로 인해 예치금 지급에 실패하였습니다');
            });
        },
      });
    } else {
      Modal.confirm({
        title: '예치금 지금',
        content:
          self.state.selectedFran.frName +
          ' 가맹점에 ' +
          ' 예치금 ' +
          form.getFieldValue('ncashAmount') +
          ' 원을 지급하시겠습니까?',
        okText: '확인',
        cancelText: '취소',
        onOk() {
          httpPost(httpUrl.depositSend, [], {
            receiveUserIdx: self.state.selectedFran.idx,
            ...self.formRef.current.getFieldsValue(),
            userType: 2,
          })
            .then((result) => {
              if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
                customAlert('예치금 지급 완료', '해당 가맹점에 예치금을 지급하였습니다.');
                self.props.close();
                self.props.getList();
                // self.props.refresh();
              } else if (result.result === 'SUCCESS' && result.data === 'INVALID_NCASH_AMOUNT') {
                customError('예치금 지급 에러', '예치금 잔액이 부족합니다.');
              } else {
                customError('예치금 지급 에러', '서버 에러로 인해 예치금 지급에 실패하였습니다');
              }
            })
            .catch((e) => {
              console.error(e);
              customError('예치금 지급 에러', '서버 에러로 인해 예치금 지급에 실패하였습니다');
            });
        },
      });
    }
  };

  onCheckType = (e) => {
    this.setState({ searchType: e.target.value }, () => {
      this.formRef.current.setFieldsValue({ kind: null });
    });
  };

  openSearchRiderModal = () => {
    this.setState({ openSearchRiderModal: true });
  };
  closeSerchRiderModal = () => {
    this.setState({ openSearchRiderModal: false });
  };

  openSmsVerificationDialog = () => {
    const to = this.props?.loginInfo?.phone;
    const userIdx = this.props?.loginInfo?.idx;
    if (null === to || undefined === to || '' === to) {
      Modal.warn({
        title: '인증실패',
        content: '등록된 핸드폰 번호가 유효하지 않습니다. 번호 확인 후 다시 시도해주세요.',
      });
      return;
    }
    if (null === userIdx || undefined === userIdx || '' === userIdx) {
      Modal.warn({
        title: '인증실패',
        content: '로그인 정보가 유효하지 않습니다. 다시 로그인 후 시도해주세요.',
      });
      return;
    }
    Modal.confirm({
      title: '인증번호 전송',
      content: `${this.props.loginInfo.phone} 으로 인증번호를 전송하시겠습니까?`,
      onOk: async () => {
        const res = await httpPost(httpUrl.sendVerificationSms, [], { userIdx, to });

        if (res.data === 'SUCCESS') {
          this.setState({ openSmsVerificationDialog: true });
        } else {
          Modal.warn({
            title: '인증번호 전송실패',
            content: '인증번호 전송에 실패했습니다.',
          });
          return;
        }
      },
    });
  };
  closeSmsVerificationDialog = () => {
    this.setState({ openSmsVerificationDialog: false });
  };

  render() {
    const { close, data, preMode } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />

        <div className="deposit-Dialog">
          <div className="deposit-content">
            <div className="deposit-title">예치금 지급</div>
            <img
              onClick={close}
              style={{ display: 'inline', float: 'right' }}
              src={CloseIcon}
              className="dialog-close"
              alt="img"
            />

            {this.state.openSearchRiderModal && (
              <SearchRiderDialog
                callback={(selectedRider) => this.setState({ selectedRider })}
                close={this.closeSerchRiderModal}
              />
            )}

            {this.state.openSearchFranModal && (
              <SearchFranchiseDialog
                callback={(selectedFran) => this.setState({ selectedFran })}
                close={this.closeSearchFranModal}
              />
            )}

            {this.state.openSmsVerificationDialog && (
              <VerificationCheckDialog
                close={this.closeSmsVerificationDialog}
                onCheck={() => this.setState({ verified: true })}
              />
            )}
            <div className="deposit-inner">
              <Form ref={this.formRef} onFinish={this.handleSubmit}>
                {!preMode ? (
                  <>
                    <div className="contentBlock">
                      <Radio.Group
                        onChange={this.onCheckType}
                        value={this.state.searchType}
                        defaultValue={searchType[0]}
                        style={{ marginRight: 19 }}>
                        {Object.entries(searchType).map(([key, value]) => {
                          return (
                            <Radio key={key} value={parseInt(key)}>
                              {value}
                            </Radio>
                          );
                        })}
                      </Radio.Group>

                      {this.state.searchType === 0 ? (
                        <Button style={{ marginBottom: 20, marginLeft: 77 }} onClick={this.openSearchRiderModal}>
                          라이더 검색
                        </Button>
                      ) : (
                        <Button style={{ marginBottom: 20, marginLeft: 77 }} onClick={this.openSearchFranModal}>
                          가맹점 검색
                        </Button>
                      )}
                    </div>

                    <div className="contentBlock">
                      {/* <div className="contentBlock-inner"> */}
                      {this.state.searchType === 0 ? (
                        <div>
                          <div className="mainTitle">라이더명</div>
                          <div className="serach-input">
                            <Input
                              value={this.state.selectedRider ? this.state.selectedRider.riderName : ''}
                              className="override-input"
                              placeholder="검색해주세요."
                              disabled
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="mainTitle">가맹점명</div>
                          <div className="serach-input">
                            <Input
                              value={this.state.selectedFran ? this.state.selectedFran.frName : ''}
                              className="override-input"
                              placeholder="검색해주세요."
                              disabled
                            />
                          </div>
                          {/* </div> */}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mainTitle">가맹점명</div>
                    <div className="serach-input">
                      <Input
                        value={this.state.selectedFran ? this.state.selectedFran.frName : ''}
                        className="override-input"
                        placeholder="클릭해주세요."
                        onClick={this.openSearchFranModal}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </>
                )}
                <div className="contentBlock">
                  {/* <div className="contentBlock-inner"> */}
                  <div className="mainTitle">지급금액</div>
                  <div className="serach-input">
                    <FormItem
                      name="ncashAmount"
                      style={{ width: 230 }}
                      rules={[
                        {
                          required: true,
                          message: '금액을 입력해주세요',
                        },
                      ]}
                      initialValue={data ? data.price : ''}>
                      <Input placeholder="금액을 입력해주세요." />
                    </FormItem>
                  </div>
                  {/* </div> */}
                </div>
                <div className="contentBlock" style={{ marginBottom: 20 }}>
                  {/* <div className="contentBlock-inner"> */}
                  <div className="mainTitle">구분</div>
                  <div className="deposit-formItem">
                    <FormItem
                      name="kind"
                      className="selectItem"
                      rules={[{ required: true, message: '구분을 선택해주세요' }]}
                      style={{ marginLeft: 25, width: 230 }}>
                      <Select placeholder={'구분을 선택해주세요'}>
                        {this.state.searchType === 0
                          ? Object.keys(adminSendKindStringForAgencyService).map((value, index) => {
                              return (
                                <Option key={value} value={value}>
                                  {adminSendKindStringForAgencyService[value]}
                                </Option>
                              );
                            })
                          : Object.keys(adminSendKindStringForAgencyServiceFranchise).map((value, index) => {
                              return (
                                <Option key={value} value={value}>
                                  {adminSendKindStringForAgencyServiceFranchise[value]}
                                </Option>
                              );
                            })}
                      </Select>
                    </FormItem>
                  </div>
                  {/* </div> */}
                </div>
                <div className="contentBlock" style={{ marginBottom: 20 }}>
                  {/* <div className="contentBlock-inner"> */}
                  <div className="mainTitle">메모</div>
                  <div className="serach-input">
                    <FormItem name="ncashMemo" style={{ width: 230 }} initialValue={data ? data.ncashMemo : ''}>
                      <Input placeholder="메모를 입력해주세요." />
                    </FormItem>
                  </div>
                  {/* </div> */}
                </div>
                <Button disabled={!this.state.verified} style={{ float: 'right' }} type="primary" htmlType="submit">
                  지급하기
                </Button>
                <Button
                  onClick={() => this.openSmsVerificationDialog()}
                  disabled={this.state.verified}
                  style={{ float: 'right', marginRight: 10 }}>
                  인증하기
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    loginInfo: state.login.loginInfo,
  };
};
let mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DepositDialog));
