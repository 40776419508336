import React, {useRef} from 'react';
import CloseIcon from "../../img/close.png";
import {Button, Input, Modal} from "antd";
import {httpPost, httpPostMultiPartReq, httpUrl} from "../../api/httpClient";
import {withRouter} from "react-router-dom";
import {connect, useSelector} from "react-redux";

const VerificationCheckDialog = ({onCheck, close}) => {
    const inputRef = useRef();
    const userIdx = useSelector(state => state.login.loginInfo.idx);
    const verifyCode = async () => {
        try {
            console.log({userIdx, code:inputRef.current.input.value});
            const res = await httpPost(httpUrl.checkVerificationCode, [], {userIdx, code:inputRef.current.input.value});

            if (res.data) {
                Modal.info({
                    title:"인증성공",
                    content:"인증에 성공했습니다."
                });
                onCheck();
                close();
            } else {
                Modal.warn({
                    title:"인증실패",
                    content:"잘못된 인증번호입니다. 다시 입력해주세요."
                })
            }
        } catch (e) {
            console.error(e);
            Modal.warn({
                title:"인증실패",
                content:"연결에 실패했습니다. 다시 인증해주세요."
            })
        }
    }
    return <>
        <div className="Dialog-overlay" onClick={close}/>
        <div className="deposit-Dialog" style={{zIndex:1000}}>
            <div className="deposit-content">
                <div className="deposit-title">인증번호 확인</div>
                <img
                    onClick={close}
                    style={{display: 'inline', float: 'right'}}
                    src={CloseIcon}
                    className="dialog-close"
                    alt="img"
                />
                <div className="deposit-inner" style={{width:400}}>
                    <div>
                        <div className="mainTitle">인증번호</div>
                        <div className="serach-input">
                            <Input
                                ref={inputRef}
                                className="override-input"
                                placeholder="인증번호를 입력해주세요."
                                type={'number'}
                            />
                        </div>
                    </div>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <Button type={"primary"} onClick={() => verifyCode()}>인증하기</Button>
                    </div>
                </div>
            </div>
        </div>
    </>
}



export default VerificationCheckDialog;