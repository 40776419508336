/*global kakao*/
import {Button, Checkbox, DatePicker, Form, Input, Modal, Popover, Radio, Select} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { Marker, NaverMap } from 'react-naver-maps';
import { connect } from 'react-redux';
import { reactLocalStorage } from 'reactjs-localstorage';
import {
  httpExec,
  httpGet,
  httpPost,
  httpUrl,
  makeUrl
} from '../../../api/httpClient';
import {
  customError,
  idDuplicated,
  registComplete,
  registError,
  updateComplete,
  updateError
} from '../../../api/Modals';
import Const from '../../../const';
import '../../../css/modal.css';
import CloseIcon from '../../../img/login/close.png';
import MarkerTarget2Icon from '../../../img/login/map/marker_target_2.png';
import { frDuesType, DistType } from '../../../lib/util/codeUtil';
import {
  formatDateToDay
} from '../../../lib/util/dateUtil';
import PostCodeDialog from '../common/PostCodeDialog';
import SearchRiderDialog from '../common/SearchRiderDialog';
import SearchFtStoreDialog from './SearchFtStoreDialog';

const FormItem = Form.Item;
const dateFormat = 'YYYY/MM/DD';
const today = new Date();
const Option = Select.Option;

class RegistFranDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogData: [],
      isPostCodeOpen: false,
      roadAddr: '',
      localAddr: '',
      feeManner: 1,
      // 좌표
      targetLat: 0,
      targetLng: 0,
      //핀 설정
      pinStatus: false,

      agreeSms: true,
      basicPickupTime: '',
      searchRiderOpen: false,
      selectedRider: null,
      selectSubBranch: [],
      branchSubIdx: null,

      isMember: true,
      riderTotalList: [],
      chargeDate: 1,

      frOrderAgency: {},
      frOrderAgencyCode: '',
      frOrderAgencyCodeSelected: false,

      searchFtStoreOpen: false,

      transferableCashNcash: true,
      dues: -1,
      duesMonth: 3, //가맹점 무료설정 기본값 3개월

      branchDpbnList: [],
      isAutoDues: true,
      useBasicDeliveryPrice: false,
      dongRateNameList: [{ idx: 0, name: '없음' }],
      simplifiedTaxPayer: false,
      distType: 0,
      data: null,
      duesPayAtFirstday: true,
    };
    this.formRef = React.createRef();
  }

  async componentDidMount() {
    if (this.props.data) {
      console.log(JSON.stringify(this.props.data));
      await this.getFrInfo();
      // this.getRiderList(this.props.data.frSalesUserIdx);
      // this.setState({ chargeDate: this.props.data.chargeDate, simplifiedTaxPayer: this.props.data.simplifiedTaxPayer });
      /*this.getNcashList();*/
    }
    this.getBranchSubList();
    await this.getBranchDpbnList();
    await this.getDeliveryPriceDongRateList();
  }

  getFrInfo = async () => {
    const res = await httpGet(httpUrl.getFrInfo, [this.props.data.idx], {});
    if (res.result === "SUCCESS") {
      let frInfo = res.data
      this.setState({data: frInfo})
      this.formRef.current.setFieldsValue({
        ...frInfo,
        chargeDate: moment(frInfo.chargeDate, 'YYYY-MM-DD'),
        registDate: moment(frInfo.registDate, 'YYYY-MM-DD'),
        password: '',
        duesType: frInfo.dues >= 0 ? 1 : 0,
        useBasicDeliveryPrice: frInfo.useBasicDeliveryPrice ? 1 : 0
      })
      this.getRiderList(frInfo.frSalesUserIdx);
      this.setState({
        transferableCashNcash: frInfo.transferableCashNcash,
        chargeDate: frInfo.chargeDate,
        simplifiedTaxPayer: frInfo.simplifiedTaxPayer,
        agreeSms: frInfo.agreeSms,
        isAutoDues: frInfo.dues >= 0 ? false : true,
        distType: frInfo.distType,
        duesPayAtFirstday: frInfo.duesPayAtFirstday ?? true,
        useBasicDeliveryPrice: frInfo.useBasicDeliveryPrice
      })
    }
  }

  getBranchDpbnList = async () => {
    try {
      const res = await httpGet(httpUrl.getBranchDpbnList, [], {});
      this.setState({
        branchDpbnList: res.data.deliveryPriceBasics,
      });
    } catch (e) { }
  };

  getDeliveryPriceDongRateList = async () => {
    try {
      const res = await httpGet(httpUrl.getDeliveryPriceDongRateList, [], {});
      if (res.result === 'SUCCESS') {
        const tempList = [{ idx: 0, name: '없음' }, ...res.data.list];
        this.setState({
          dongRateNameList: tempList,
        })
      }
    } catch (error) {
      console.error('getDeliveryPriceDongRateList error: {}', error);
    }
  }

  getBranchSubList = () => {
    let branchIdx = this.getAdminUserInfo().branchIdx;
    const res = httpGet(httpUrl.getBranchSubName, [branchIdx], {}).then(
      (res) => {
        if (res.result === 'SUCCESS') {
          this.setState({
            selectSubBranch: res.data.list,
          });
        }
      }
    );
    return res;
  };

  /*getNcashList = () => {
    let category = 'DUES_PAY';
    let pageNum = 1;
    let pageSize = 10;
    let userIdx = this.props.data.idx;
    let startDate = new moment().clone().startOf('day').format('YYYY-MM-DD');
    // let startDate = '2022-05-01';
    let endDate = new moment().clone().format('YYYY-MM-DD');
    httpGet(
      httpUrl.ncashList,
      [category, pageNum, pageSize, userIdx, startDate, endDate],
      {}
    ).then((res) =>
      this.setState({
        dues: res.data.ncash.length !== 0 ? res.data.ncash[0].ncashDelta : -1,
      })
    );
  };*/

  getRiderList = (frSalesUserIdx) => {
    this.setState({
      selectedRider: this.props.riders.find(
        (rider) => rider.idx === frSalesUserIdx
      ),
    });
  };

  handleToggle = (value) => {
    const toggledBtnInfos = this.state.btnInfos.map((btnInfo) => {
      if (btnInfo.value === value) {
        return {
          value: value,
          text: btnInfo.text,
          toggle: !btnInfo.toggle,
        };
      } else {
        return btnInfo;
      }
    });
    this.setState({ btnInfos: toggledBtnInfos });
  };

  openSearchRider = () => {
    this.setState({ searchRiderOpen: true });
  };

  closeSearchRider = () => {
    this.setState({ searchRiderOpen: false });
  };

  verifyDuesDeliveryPrice = (duesDeliveryPrice) => {
    try {
      const ddp = parseInt(duesDeliveryPrice);
      if (ddp < 0)
        return [false, '건별 가맹비는 마이너스 값으로 설정할 수 없습니다.'];
      // if (ddp % 100 != 0)
      //   return [false, '건별 가맹비는 100원 단위로만 설정가능합니다.'];
      return [true, null];
    } catch (e) {
      return [false, '건별 가맹비 값을 확인해주세요.'];
    }
  };

  verifyAutoCancelTimeSec = (autoCancelTimeSec) => {
    try {
      const sec = parseInt(autoCancelTimeSec);
      if (isNaN(sec)) {
        return [false, '숫자가 아닌 값은 입력 할 수 없습니다.'];
      }
      if (sec != 0) {
        if (sec < 60)
          return [false, '60초 이하로 설정 할 수 없습니다.'];
        if (sec > 3600)
          return [false, '3600초 이상으로 설정 할 수 없습니다.'];
      }

      return [true, null];
    } catch (e) {
      return [false, '자동 취소 시간(초) 값을 확인해주세요.'];
    }
  };

  handleSubmit = () => {
    const currentFormRef = this.formRef.current;
    if (
      !this.verifyBusinessNumber(
        currentFormRef.getFieldValue('businessNumber')
      )
    ) {
      Modal.warn({
        title: '사업자번호 오류',
        content: '사업자번호를 다시 한번 확인해주세요.',
      });
      return;
    }

    if (!this.state.isAutoDues) {
      const dues = this.formRef.current.getFieldValue('dues');
      if (isNaN(dues) || parseInt(dues) < 0 || parseInt(dues) > 3000000) {
        Modal.warn({
          title: '가맹비 오류',
          content: '가맹비를 0 ~ 300만원 사이로 입력해주세요.',
        });
        return;
      }
    }

    if(this.state.useBasicDeliveryPrice) {
      if(isNaN(this.formRef.current.getFieldValue('basicDeliveryPrice'))) {
        Modal.warn({
          title: '기본 배달비 오류',
          content: '기본 배달비를 숫자로 입력해주세요.',
        });
        return;
      }
    }

    const duesExtraPrice = this.formRef.current.getFieldValue('duesExtraPrice');
    if (isNaN(duesExtraPrice) || parseInt(duesExtraPrice) < 0) {
      Modal.warn({
        title: '추가가맹비 설정 오류',
        content: '추가 가맹비 금액은 0이상의 숫자를 입력해주세요.',
      });
      return;
    }
    const duesExtraCount = this.formRef.current.getFieldValue('duesExtraCount');
    if (isNaN(duesExtraCount) || parseInt(duesExtraCount) < 0) {
      Modal.warn({
        title: '추가가맹비 설정 오류',
        content: '추가 가맹비 콜수는 0이상의 숫자를 입력해주세요.',
      });
      return;
    }
    const deliveryPriceSupportFund = this.formRef.current.getFieldValue('deliveryPriceSupportFund');
    if (this.props.loginInfo.companyId !== 'connect9' && (isNaN(deliveryPriceSupportFund) || parseInt(deliveryPriceSupportFund) < 0)) {
      Modal.warn({
        title: '콜수수료 오류',
        content: '콜수수료는 0이상의 숫자를 입력해주세요.',
      });
      return;
    }
    const deliveryPriceSupportFundCount = this.formRef.current.getFieldValue('deliveryPriceSupportFundCount');
    if (this.props.loginInfo.companyId !== 'connect9' && (isNaN(deliveryPriceSupportFundCount) || parseInt(deliveryPriceSupportFundCount) < 0)) {
      Modal.warn({
        title: '콜수수료 오류',
        content: '콜수수료 콜수는 0이상의 숫자를 입력해주세요.',
      });
      return;
    }

    const [verifyDdpRes, message] = this.verifyDuesDeliveryPrice(
      this.formRef.current.getFieldValue('duesDeliveryPrice')
    );
    if (!verifyDdpRes) {
      Modal.warn({
        title: '건별가맹비 오류',
        content: message,
      });
      return;
    }
    const duesDeliveryPriceCount = this.formRef.current.getFieldValue('duesDeliveryPriceCount');
    if (isNaN(duesDeliveryPriceCount) || parseInt(duesDeliveryPriceCount) < 0) {
      Modal.warn({
        title: '건별가맹비 오류',
        content: '건별가맹비 콜수는 0이상의 숫자를 입력해주세요.',
      });
      return;
    }

    const [verifyActsRes, message2] = this.verifyAutoCancelTimeSec(
        this.formRef.current.getFieldValue('autoCancelTimeSec')
    );
    if (!verifyActsRes) {
      Modal.warn({
        title: '주문 자동 취소 시간 오류',
        content: message2,
      });
      return;
    }

    const basicPickupTimeString =
      this.formRef.current.getFieldValue('basicPickupTime');
    const basicPickupTime = Number(basicPickupTimeString);

    if (isNaN(basicPickupTime)) {
      Modal.warn({
        title: '기본 호출시간 오류',
        content: '숫자를 입력해 주세요.',
      });
      return;
    }

    // if (basicPickupTime < 5) {
    //   Modal.warn({
    //     title: '기본 호출시간 오류',
    //     content: '기본 호출시간을 5분 이상 입력해 주세요.',
    //   });
    //   return;
    // }

    if (
      typeof currentFormRef.getFieldValue('businessRegistDate') !== 'undefined' &&
      typeof currentFormRef.getFieldValue('businessRegistDate') !== 'object' &&
      currentFormRef.getFieldValue('businessRegistDate').includes('-')
    ) {
      currentFormRef.setFieldsValue({
        businessRegistDate: currentFormRef.getFieldValue('businessRegistDate').replace(/-/gi, ''),
      });
    }

    const companyId = this.props.loginInfo.companyId;

    if (this.props.data) {
      if (!this.state.frOrderAgencyCodeSelected) {
        if (this.state.frOrderAgencyCode !== '') {
          var frOrderAgency = {
            userIdx: null,
            nncode: null,
            ftCode: null,
            deCode: null,
            dgCode: null,
            bpCode: null,
            psCode: null,
            posCode: null,
            posStoreCode: null,
          };

          if (
            Object.keys(frOrderAgency).includes(this.state.frOrderAgencyCode)
          ) {
            frOrderAgency[this.state.frOrderAgencyCode] = '';
          } else {
            frOrderAgency.posCode = this.state.frOrderAgencyCode;
            frOrderAgency.posStoreCode = this.formRef.current.getFieldValue(
              'frOrderAgencyCodeValue'
            );
          }
        }
      }

      console.log({
        ...this.formRef.current.getFieldsValue(),
        idx: this.props.data.idx,
        branchIdx: this.props.branchIdx,
        frSalesUserIdx: this.state.selectedRider
          ? this.state.selectedRider.idx
          : '',
        userGroup: 0,
        nonmemberFee: this.state.isMember ? 0 : 1000,
        registDate: formatDateToDay(
          this.formRef.current.getFieldValue('registDate')
        ),
        chargeDate: formatDateToDay(
          this.formRef.current.getFieldValue('chargeDate')
        ),
        password: this.formRef.current.getFieldValue('password')
          ? this.formRef.current.getFieldValue('password')
          : null,
        businessNumber: this.formRef.current
          .getFieldValue('businessNumber')
          .split('-')
          .join(''),
        basicPickupTime: this.state.basicPickupTime,
        basicDeliveryPrice: this.formRef.current.getFieldValue('basicDeliveryPrice') || 0,
        // 삭제컬럼
        basicDeliveryDistance: 0,
        frOrderAgency: !this.state.frOrderAgencyCodeSelected
          ? frOrderAgency
          : null,
        transferableCashNcash: this.state.transferableCashNcash,
      });
      console.log('BearerCode');
      console.log(this.bearerCode);

      httpExec('POST', makeUrl(httpUrl.franchiseUpdate, []), {
        ...this.formRef.current.getFieldsValue(),
        idx: this.props.data.idx,
        frSalesUserIdx: this.state.selectedRider
          ? this.state.selectedRider.idx
          : '',
        userGroup: 0,
        nonmemberFee: this.state.isMember ? 0 : 1000,
        registDate: formatDateToDay(
          this.formRef.current.getFieldValue('registDate')
        ),
        chargeDate: formatDateToDay(
          this.formRef.current.getFieldValue('chargeDate')
        ),
        password: this.formRef.current.getFieldValue('password')
          ? this.formRef.current.getFieldValue('password')
          : null,
        businessNumber: this.formRef.current
          .getFieldValue('businessNumber')
          .split('-')
          .join(''),
        // basicPickupTime: this.state.basicPickupTime,
        agreeSms: this.state.agreeSms,
        useBasicDeliveryPrice: this.state.useBasicDeliveryPrice,
        basicDeliveryPrice: this.formRef.current.getFieldValue('basicDeliveryPrice') || 0,
        basicDeliveryDistance: 0,
        frOrderAgency: !this.state.frOrderAgencyCodeSelected
          ? frOrderAgency
          : null,
        latitude:
          this.state.targetLat !== 0
            ? this.state.targetLat
            : this.state.data.latitude,
        longitude:
          this.state.targetLng !== 0
            ? this.state.targetLng
            : this.state.data.longitude,
        transferableCashNcash: this.state.transferableCashNcash,
        dues: this.state.isAutoDues ? -1 : this.formRef.current.getFieldValue('dues'),
        businessRegistDate: this.formRef.current.getFieldValue('businessRegistDate') || null,
        needFrValidation: this.props.needFrValidation,
        simplifiedTaxPayer: this.state.simplifiedTaxPayer,
        distType: this.state.distType,
        duesPayAtFirstday: companyId === 'connect9' ? true : this.state.duesPayAtFirstday,
      })
        .then((result) => {
          console.log('## result: ' + JSON.stringify(result, null, 4));
          if (result.data === 'SUCCESS') {
            this.props.getList();
            updateComplete();
          } else if (result.data === 'INVALID_FT_INFORMATION') {
            Modal.info({
              title: '연동설정실패',
              content:
                '올바르지 않은 연동 정보가 존재합니다. 푸드테크 연동정보를 확인해주세요.',
            });
            return;
          } else if (result.data === 'INVALID_FR_NAME_BUSINESS_NUM_BUSINESS_REGIST_DATE') {
            Modal.info({
              title: '가맹점인증실패',
              content:
                '대표자명, 사업자번호, 사업자등록일, 실제 가맹점명을 확인하세요.',
            });
            return;
          } else {
            updateError();
            return;
          }
          this.props.close();
        })
        .catch((e) => {
          updateError();
        });
    } else {
      if (this.state.frOrderAgencyCode !== '') {
        let frOrderAgency = {
          userIdx: null,
          nncode: null,
          ftCode: null,
          deCode: null,
          dgCode: null,
          bpCode: null,
          psCode: null,
        };
        frOrderAgency[this.state.frOrderAgencyCode] = '';
        console.log('frOrderAgency');
        console.log(this.state.frOrderAgencyCode);
      }

      console.log({
        ...this.formRef.current.getFieldsValue(),
        id: this.formRef.current.getFieldValue('id').replace(/ /gi, ''),
        ncash: 0,
        userStatus: 1,
        recommenderIdx: 0,
        userType: 2,
        withdrawPassword: '0000',
        bank: '',
        bankAccount: '',
        depositor: '',
        userGroup: 0,
        latitude: this.state.targetLat,
        longitude: this.state.targetLng,
        frStatus: 1,
        ncashPayEnabled: false,
        tidNormal: '',
        tidPrepay: '',
        // 입력창 없애고 기본값 미사용
        tidNormalRate: 100,
        duesAutoChargeEnabled: false,
        agreeSms: this.state.agreeSms,
        frSalesUserIdx: this.state.selectedRider
          ? this.state.selectedRider.idx
          : '',
        nonmemberFee: this.state.isMember ? 0 : 1000,
        registDate: formatDateToDay(
          this.formRef.current.getFieldValue('registDate')
        ),
        chargeDate: formatDateToDay(
          this.formRef.current.getFieldValue('chargeDate')
        ),
        businessNumber: this.formRef.current
          .getFieldValue('businessNumber')
          .split('-')
          .join(''),
        basicPickupTime: this.state.basicPickupTime,
        basicDeliveryPrice: this.formRef.current.getFieldValue('basicDeliveryPrice') || 0,
        useBasicDeliveryPrice: this.state.useBasicDeliveryPrice,
        basicDeliveryDistance: 0,
        frOrderAgency: frOrderAgency ? frOrderAgency : null,
        transferableCashNcash: this.state.transferableCashNcash,
      });
      httpPost(httpUrl.registFranchise, [], {
        ...this.formRef.current.getFieldsValue(),
        id: this.formRef.current.getFieldValue('id').replace(/ /gi, ''),
        ncash: 0,
        userStatus: 1,
        recommenderIdx: this.state.selectedRider
          ? this.state.selectedRider.idx
          : '',
        userType: 2,
        withdrawPassword: '0000',
        bank: '',
        bankAccount: '',
        depositor: '',
        userGroup: 0,
        latitude: this.state.targetLat,
        longitude: this.state.targetLng,
        frStatus: 1,
        ncashPayEnabled: false,
        tidNormal: '',
        tidPrepay: '',
        // 입력창 없애고 기본값 미사용
        tidNormalRate: 100,
        duesAutoChargeEnabled: false,
        agreeSms: this.state.agreeSms,
        frSalesUserIdx: this.state.selectedRider
          ? this.state.selectedRider.idx
          : '',
        nonmemberFee: this.state.isMember ? 0 : 1000,
        chargeDate: formatDateToDay(this.formRef.current.getFieldValue('chargeDate')),
        registDate: formatDateToDay(
          this.formRef.current.getFieldValue('registDate')
        ),
        businessNumber: this.formRef.current
          .getFieldValue('businessNumber')
          .split('-')
          .join(''),
        // basicPickupTime: this.state.basicPickupTime,
        useBasicDeliveryPrice: this.state.useBasicDeliveryPrice,
        basicDeliveryPrice: this.formRef.current.getFieldValue('basicDeliveryPrice') || 0,
        // 삭제컬럼
        basicDeliveryDistance: 0,
        // dues: 0,
        // chargeDate: "1970-01-01",
        isMember: true,
        withdrawLimit: 0,
        frOrderAgency,
        transferableCashNcash: this.state.transferableCashNcash,
        dues: this.state.isAutoDues ? -1 : this.formRef.current.getFieldValue('dues'),
        businessRegistDate: this.formRef.current.getFieldValue('businessRegistDate') || null,
        simplifiedTaxPayer: this.state.simplifiedTaxPayer,
        distType: this.state.distType,
        duesPayAtFirstday: companyId === 'connect9' ? true : this.state.duesPayAtFirstday,
      })
        .then((result) => {
          // console.log("## result: " + JSON.stringify(result, null, 4));
          if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
            this.props.getList();
            registComplete();
            this.props.close();
          } else if (
            result.result === 'SUCCESS' &&
            result.data == 'ID_DUPLICATED'
          ) {
            idDuplicated();
          } else {
            registError();
            this.props.close();
          }
        })
        .catch((e) => {
          registError();
        });
    }
  };

  // 우편번호 검색
  openPostCode = () => {
    this.setState({ isPostCodeOpen: true });
  };

  closePostCode = () => {
    this.setState({ isPostCodeOpen: false });
  };

  getAdminUserInfo = () => {
    const data = reactLocalStorage.getObject(Const.appName + '#adminUser');
    return typeof data === 'object' && data;
  };

  addressSearchKakao = (address) => {
    const geocoder = new kakao.maps.services.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.addressSearch(address, function (result, status) {
        if (status === kakao.maps.services.Status.OK) {
          const coords = [result[0].y, result[0].x];
          resolve(coords);
        } else {
          reject(status);
        }
      });
    });
  };

  // 우편번호 - 주소 저장
  getAddr = (addrData) => {
    // console.log(addrData.address)
    this.formRef.current.setFieldsValue({
      addr1: addrData.roadAddress, // 도로명 주소
      addr3:
        addrData.jibunAddress === ''
          ? addrData.autoJibunAddress
          : addrData.jibunAddress, // 지번
    });

    // 좌표변환
    let address = addrData.roadAddress;
    if (
      typeof addrData.jibunAddress !== 'undefined' &&
      addrData.jibunAddress !== ''
    ) {
      address = address + '?query2=' + addrData.jibunAddress;
    }
    httpGet(httpUrl.getGeocode, [address], {})
      .then((res) => {
        const lat = res.data.latitude;
        const lng = res.data.longitude;

        this.setState({
          targetLat: lat,
          targetLng: lng,
        });
      })
      .catch((e) => {
        customError(
          '위치 반환 오류',
          '위치 데이터가 존재하지 않습니다. 관리자에게 문의하세요.'
        );
        throw e;
      });
  };

  onChangFeeManner = (e) => {
    console.log(e.target.value);
    this.setState({ feeManner: e.target.value }, () => { });
  };

  onChangeIsMember(e) {
    this.setState({ isMember: e.target.value });
  }

  verifyBusinessNumber = (number) => {
    var numberMap = number
      .replace(/-/gi, '')
      .split('')
      .map(function (d) {
        return parseInt(d, 10);
      });

    if (numberMap.length == 10) {
      var keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
      var chk = 0;

      keyArr.forEach(function (d, i) {
        chk += d * numberMap[i];
      });
      chk += parseInt((keyArr[8] * numberMap[8]) / 10, 10);
      return Math.floor(numberMap[9]) === (10 - (chk % 10)) % 10;
    }

    return false;
  };

  onCheckDuesType = (e) => {
    this.setState({ isAutoDues: e.target.value === 0 ? true : false });
  };

  onCheckBasicDeliveryFeeType = (e) => {
    this.setState({ useBasicDeliveryPrice: e.target.value === 0 ? false : true });
  };


  onChangeDistType = (e) => {
    this.setState({ distType: e.target.value });
  }

  render() {
    const { close, data, needFrValidation } = this.props;
    const companyId = this.props.loginInfo.companyId;

    const lat = 37.643623625321474;
    const lng = 126.66509442649551;

    const navermaps = window.naver.maps;
    return (
      <React.Fragment>
        {this.state.searchFtStoreOpen && (
          <SearchFtStoreDialog
            multiList={this.state.multiList}
            businessNumber={this.state.businessNumber}
            onSelect={(store) => this.mapSelectedFtStore(store)}
            close={() => this.setState({ searchFtStoreOpen: false })}
          />
        )}
        <div className="flex-modal-overlay" onClick={() => close()} />
        <div className="flex-wrapper">
          <div className="flex-header">
            <div className="flex-title">
              {needFrValidation ? '가맹점 인증' : data ? '가맹점 수정' : '가맹점 등록'}
            </div>
            <img
              onClick={close}
              src={CloseIcon}
              className="dialog-close"
              alt="close"
            />
          </div>

          <div className="flex-inner registFran-box">
            <Form ref={this.formRef} onFinish={this.handleSubmit}>
              <div className="dialog-inner registFran-inner">
                <div className="registFranWrapper">
                  <div className="contentBox">
                    <div className="subText">가맹점명</div>
                    <FormItem
                      name="frName"
                      className="selectItem"
                      // initialValue={data && data.frName}
                      rules={[
                        {
                          required: true,
                          message: '가맹점이름을 입력해 주세요',
                        },
                      ]}>
                      <Input
                        placeholder="가맹점명을 입력해 주세요."
                        className="override-input"
                        disabled={needFrValidation || false}
                      />
                    </FormItem>
                  </div>
                  <div className="contentBox">
                    <div className="subText">실가맹점명</div>
                    <FormItem
                      name="frNameExtra"
                      className="selectItem">
                      <Input
                        placeholder="실가맹점명을 입력해 주세요."
                        className="override-input"
                      />
                    </FormItem>
                  </div>
                  <div className="contentBox">
                    <div className="subText">관제용메모</div>
                    <FormItem
                      name="controlMemo"
                      className="selectItem">
                      <Input
                        placeholder="관제용메모를 입력해 주세요."
                        className="override-input"
                        disabled={needFrValidation || false}
                      />
                    </FormItem>
                  </div>
                  <div className="contentBox">
                    <div className="subText">사업자번호</div>
                    <FormItem
                      name="businessNumber"
                      className="selectItem"
                      rules={[
                        {
                          required: true,
                          message: '사업자번호를 입력해 주세요',
                        },
                      ]}>
                      <Input
                        placeholder="사업자번호를 - 를 빼고 입력해 주세요."
                        className="override-input"
                      />
                    </FormItem>
                  </div>
                  <div style={{ color: 'red' }}>
                    <div className="subText" />
                    *** 사업자 번호를 변경하실 때는 van 재등록을 요청해주셔야
                    합니다.
                  </div>
                  <div className="contentBox">
                    <div className="subText">사업자등록일</div>
                    <FormItem
                      name="businessRegistDate"
                      className="selectItem"
                    >
                      <Input
                        placeholder="사업자등록일을 - 를 빼고 입력해 주세요."
                        className="override-input"
                      />
                    </FormItem>
                  </div>

                  <div className="contentBox">
                    <div className="subText">대표자명</div>
                    <FormItem
                      name="ownerName"
                      className="selectItem"
                      rules={[
                        {
                          required: true,
                          message: '대표자명을 입력해 주세요',
                        },
                      ]}>
                      <Input
                        placeholder="대표자명을 입력해 주세요."
                        className="override-input"
                      />
                    </FormItem>
                  </div>

                  <div className="contentBox">
                    <div className="subText">가맹점전화</div>
                    <FormItem
                      name="frPhone"
                      className="selectItem"
                      rules={[
                        {
                          required: true,
                          message: '가맹점 전화번호를 입력해주세요',
                        },
                      ]}>
                      <Input
                        placeholder="가맹점 전화번호를 입력해 주세요."
                        className="override-input"
                        disabled={needFrValidation || false}
                      />
                    </FormItem>
                  </div>

                  <div className="contentBox">
                    <div className="subText">휴대전화</div>
                    <FormItem
                      name="phone"
                      className="selectItem"
                      rules={[
                        {
                          required: true,
                          message: '핸드폰 번호를 입력해주세요',
                        },
                      ]}>
                      <Input
                        placeholder="휴대전화 번호를 입력해 주세요."
                        className="override-input"
                        disabled={needFrValidation || false}
                      />
                    </FormItem>
                  </div>

                  <div className="contentBox">
                    <div className="subText">주소</div>
                    <FormItem
                      name="addr1"
                      className="selectItem"
                      rules={[
                        {
                          required: true,
                          message: '주소를 검색하거나 지도에서 선택해주세요.',
                        },
                      ]}>
                      <Input
                        placeholder="주소를 입력해 주세요."
                        disabled
                        className="override-input addrText"
                      />
                      <PostCodeDialog
                        data={(addrData) => this.getAddr(addrData)}
                        isOpen={this.state.isPostCodeOpen}
                        close={this.closePostCode}
                      />
                      <Button
                        onClick={this.openPostCode}
                        className="override-input addrBtn">
                        우편번호 검색
                      </Button>
                    </FormItem>
                  </div>
                  <div className="contentBox">
                    <div className="subText">지번주소</div>
                    <FormItem
                      name="addr3"
                      className="selectItem"
                      rules={[
                        {
                          required: true,
                          message: '주소를 검색하거나 지도에서 선택해주세요.',
                        },
                      ]}>
                      <Input
                        placeholder="지번주소를 입력해 주세요."
                        className="override-input sub"
                        defaultValue={
                          this.state.localAddr
                            ? `${this.state.localAddr}`
                            : ''
                        }
                        disabled
                      />
                    </FormItem>
                  </div>
                  <div className="contentBox">
                    <div className="subText">상세주소</div>
                    <FormItem
                      name="addr2"
                      className="selectItem"
                      rules={[
                        {
                          required: true,
                          message: '상세주소를 입력해주세요',
                        },
                      ]}>
                      <Input
                        placeholder="상세주소를 입력해 주세요."
                        className="override-input sub"
                        disabled={needFrValidation || false}
                      />
                    </FormItem>
                  </div>

                  <div className="contentBox">
                    <div style={{ fontSize: '1rem' }} className="subText">
                      <span className="desk">현금 코인이체</span>
                      <span className="mobile">코인이체</span>
                    </div>
                    <FormItem
                      name="transferableCashNcash"
                      className="selectItem">
                      <Checkbox
                        className="override-input checkbox"
                        disabled={needFrValidation || false}
                        checked={this.state.transferableCashNcash}
                        onChange={(e) =>
                          this.setState({
                            transferableCashNcash: e.target.checked,
                          })
                        }>
                        현금 코인이체 사용
                      </Checkbox>
                    </FormItem>
                  </div>

                  <div className="contentBox">
                    <div style={{ fontSize: '1rem' }} className="subText">
                      <span className="desk">요금제 그룹</span>
                      <span className="mobile">요금제 그룹</span>
                    </div>
                    <FormItem
                      name="deliveryPriceBasicGroupIdx"
                      className="selectItem"
                      initialValue={1}
                      rules={[
                        {
                          required: true,
                          message: '요금제 그룹을 선택해주세요',
                        },
                      ]}>
                      <Select style={{ width: '20rem' }}>
                        {this.state.branchDpbnList.map((dpbn) => (
                          <Select.Option value={dpbn.groupIdx}>
                            {dpbn.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </FormItem>
                  </div>

                  <div className="contentBox">
                    <div className="subText">거리요금제</div>
                    <FormItem
                      name="distType"
                      className="selectItem"
                      rules={[
                        {
                          required: true,
                          message: '요금제를 설정해주세요',
                        },
                      ]}
                      initialValue={this.state.distType}
                  >
                    <Radio.Group
                        className="searchRequirement"
                        onChange={(e) => this.onChangeDistType(e)}
                        value={this.state.distType}
                        style={{ marginRight: 19 }}>
                        {DistType.map((value, key) => {
                          if (companyId !== 'connect9') {
                            if (value === '내비') {
                              return <Radio value={key} disabled={true}>{value}</Radio>;
                            }
                          }
                          return <Radio value={key}>{value}</Radio>
                        })}
                      </Radio.Group>
                    </FormItem>
                  </div>
                </div>

                <div className="registFranWrapper sub">
                  <div className="contentBox">
                    <div className="subText">영업담당자</div>
                    <FormItem name="addrMain" className="selectItem">
                      {this.state.searchRiderOpen && (
                        <SearchRiderDialog
                          callback={(rider) => {
                            this.setState({ selectedRider: rider }, () => {
                              console.log(this.state.selectedRider);
                            });
                          }}
                          close={this.closeSearchRider}
                          isSelectBox={true}
                        />
                      )}

                      <Input
                        className="override-input orderman"
                        placeholder="영업담당자를 선택해주세요."
                        value={
                          this.state.selectedRider
                            ? this.state.selectedRider.riderName
                            : ''
                        }
                        disabled={needFrValidation || false}
                      />
                      <Button
                        // style={{ width: 150 }}
                        className="override-input searchRiderBtn"
                        onClick={this.openSearchRider}
                        disabled={needFrValidation || false}>
                        기사조회
                      </Button>
                      {/* </div> */}
                    </FormItem>
                  </div>

                  <div className="contentBox">
                    <div className="subText"> 최초가맹비</div>
                    <FormItem
                      name="chargeDate"
                      className="selectItem"
                      initialValue={moment(new Date(), 'YYYY-MM-DD').add(1, 'M')}>
                      <DatePicker
                        format={dateFormat}
                        className={companyId !== 'connect9' ? "datepicker2" : "datepicker"}
                      />
                    </FormItem>
                    {companyId !== 'connect9' &&
                      <FormItem
                        name="duesPayAtFirstday"
                      >
                        <Popover content={
                          <div>
                            매달1일로 설정된 경우 최초납부일에 일할계산되어 차감되고, 이후 매달1일에 한달치가 차감됩니다.
                            <br/>매달1일로 설정되지 않은 경우에는 최초납부일에 한달치가 차감되고, 매달 해당 날짜에 한달치가 차감됩니다.
                          </div>
                        }>
                          <Checkbox
                            style={{ marginLeft: 10 }}
                            checked={this.state.duesPayAtFirstday}
                            onChange={(e) =>
                                this.setState({
                                  duesPayAtFirstday: e.target.checked,
                                })
                          }>
                            매달 1일
                          </Checkbox>
                        </Popover>
                      </FormItem>
                    }
                  </div>

                  <div className="contentBox">
                    <div className="subText">라이더용메모</div>
                    <FormItem
                      name="riderMemo"
                      className="selectItem"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "과적기준을 입력해주세요",
                      //   },
                      // ]}
                    >
                      <Input
                        placeholder="라이더용 메모를 입력해 주세요."
                        className="override-input"
                        disabled={needFrValidation || false}
                      />
                    </FormItem>
                  </div>
                  <div className="contentBox">
                    <div className="subText">과적기준</div>
                    <FormItem
                      name="overload"
                      className="selectItem"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "과적기준을 입력해주세요",
                      //   },
                      // ]}
                    >
                      <Input
                        placeholder="과적기준을 입력해 주세요."
                        className="override-input"
                        disabled={needFrValidation || false}
                      />
                    </FormItem>
                  </div>

                  <div className="contentBox">
                    <div className="subText">아이디</div>
                    <FormItem
                      name="id"
                      className="selectItem"
                      rules={[
                        {
                          required: true,
                          message: '아이디를 입력해주세요',
                        },
                      ]}
                    >
                      <Input
                        placeholder="아이디를 입력해 주세요."
                        className="override-input"
                        disabled={data && true}
                      />
                    </FormItem>
                  </div>

                  <div className="contentBox">
                    <div className="subText">비밀번호</div>
                    <FormItem
                      name="password"
                      className="selectItem"
                      rules={[
                        {
                          validator: (rule, value, cb) => {
                            cb();
                            // let strongRegex = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,15}$');
                            // if (!value || value == '') cb();
                            // else if (!strongRegex.test(value)) cb('영문, 숫자, 특수문자 조합 8~15자로 입력');
                            // else cb();
                          },
                        },
                      ]}>
                      {data ? (
                        <Input.Password
                          autoComplete="new-password"
                          placeholder="비밀번호를 입력해 주세요."
                          className="override-input sub"
                          disabled={needFrValidation || false}
                        />
                      ) : (
                        <Input.Password
                          autoComplete="new-password"
                          placeholder="비밀번호를 입력해 주세요."
                          className="override-input sub"
                        />
                      )}
                    </FormItem>
                  </div>

                  <div className="contentBox">
                    <div className="subText">이메일</div>
                    <FormItem
                      name="email"
                      className="selectItem"
                      rules={[
                        {
                          required: true,
                          message: '이메일을 입력해주세요',
                        },
                      ]}>
                      <Input
                        placeholder="ex) example@naver.com"
                        className="override-input"
                        disabled={needFrValidation || false}
                      />
                    </FormItem>
                  </div>
                  {/* <div className="contentBox">
                      <div className="subText">가상계좌 은행</div>
                      <FormItem
                        name="vAccoutBank"
                        className="selectItem"
                        rules={[
                          { required: true, message: "은행을 선택해주세요" },
                        ]}
                        initialValue={data ? data.vAccountBank : "기업은행,003"}
                      >
                        <Select className="override-input">
                          {Object.keys(bankCode).map((key) => {
                            return (
                              <Option value={key + "," + bankCode[key]}>
                                {key}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </div>
                    <div className="contentBox">
                      <div className="subText">가상계좌 번호</div>
                      <FormItem
                        name="vAccoutNumber"
                        className="selectItem"
                        rules={[
                          {
                            required: true,
                            message: "계좌번호를 입력해주세요",
                          },
                        ]}
                        initialValue={data ? data.vAccountNumber : ""}
                      >
                        <Input
                          placeholder="계좌번호를 입력해 주세요."
                          className="override-input"
                        />
                      </FormItem>
                    </div>

                    <div className="contentBox">
                      <div className="subText">예금주</div>
                      <FormItem
                        name="vAccoutDepositor"
                        className="selectItem"
                        rules={[
                          { required: true, message: "예금주를 입력해주세요" },
                        ]}
                        initialValue={data ? data.vAccountDepositor : ""}
                      >
                        <Input
                          placeholder="예금주를 입력해 주세요."
                          className="override-input"
                        />
                      </FormItem>
                    </div> */}

                  {/* <div className="contentBox">
                    <div className="subText">
                      <span className="desk">PG 사용여부</span>
                      <span className="mobile">PG 사용</span>
                    </div>
                    <div className="registRiderCheck">
                      <FormItem
                        name="tidNormalRate"
                        initialValue={data ? data.tidNormalRate : 100}
                      >
                        <Radio.Group
                          className="searchRequirement"
                          initialValue={data ? data.tidNormalRate : 100}
                          disabled
                        >
                          {Object.keys(pgUseRate)
                            .reverse()
                            .map((key) => {
                              return (
                                <Radio value={parseInt(key)}>
                                  {pgUseRate[key]}
                                </Radio>
                              );
                            })}
                        </Radio.Group>
                      </FormItem>
                    </div>
                  </div> */}
                  {/* <div className="contentBox">
                      <div className="subText">기본배달요금</div>
                      <FormItem
                        name="basicDeliveryPrice"
                        className="selectItem"
                        // initialValue={data && data.basicDeliveryPrice}
                        initialValue={data ? data.basicDeliveryPrice : 3600}
                        rules={[
                          {
                            required: true,
                            message: "기본배달요금을 입력해주세요.",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="배달요금을 입력해 주세요."
                          className="override-input"
                        />
                      </FormItem>
                    </div>
                    <div className="contentBox">
                      <div className="subText">기본거리</div>
                      <FormItem
                        name="basicDeliveryDistance"
                        className="selectItem"
                        initialValue={
                          data ? parseInt(data.basicDeliveryDistance) : 1500
                        }
                        rules={[
                          {
                            required: true,
                            message: "기본거리를 입력해주세요.",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="기본거리를 m(미터) 단위로 입력해주세요."
                          className="override-input"
                        />
                      </FormItem>
                    </div> */}

                  {/* <div className="contentBox">
                      <div className="subText">메모</div>
                      <FormItem name="memo" className="selectItem">
                        {data ? (
                          <Input
                            placeholder="메모를 입력해 주세요."
                            className="override-input sub"
                            defaultValue={data.memo}
                          />
                        ) : (
                          <Input
                            placeholder="메모를 입력해 주세요."
                            className="override-input sub"
                          />
                        )}
                      </FormItem>
                    </div> */}

                  <div className="contentBox">
                    <div className="subText">가입일자</div>
                    <FormItem
                      name="registDate"
                      className="selectItem"
                      initialValue={moment(today, dateFormat)}>
                      <DatePicker format={dateFormat} className="datepicker" disabled={needFrValidation || false} />
                    </FormItem>
                  </div>
                  <div className="contentBox">
                    <div style={{ fontSize: '1rem' }} className="subText">
                      <span className="desk">SMS수신동의</span>
                      <span className="mobile">SMS수신</span>
                    </div>
                    <FormItem name="agreeSms" className="selectItem">
                      <Checkbox
                        className="override-input checkbox"
                        checked={this.state.agreeSms}
                        onChange={(e) =>
                          this.setState({ agreeSms: e.target.checked })
                        }
                        disabled={needFrValidation || false}>
                        수신동의
                      </Checkbox>
                    </FormItem>
                  </div>
                  <div className="contentBox">
                    <div className="subText">기본 호출시간</div>
                    <FormItem
                      name="basicPickupTime"
                      className="selectItem"
                      rules={[
                        {
                          required: true,
                          message: '기본 호출시간을 설정해주세요',
                        },
                      ]}
                      initialValue={15}>
                      <Input
                        placeholder="기본 호출시간을 설정해주세요 ex) 15"
                        className="override-input"
                        style={{ width: '18.5rem' }}
                        disabled={needFrValidation || false}
                      />
                    </FormItem>
                    <div style={{ lineHeight: '32px' }}>&nbsp;&nbsp;분</div>
                  </div>

                  <div className="contentBox">
                    <div className="subText">기본 배달비</div>
                    <FormItem
                      name="useBasicDeliveryPrice"
                      className="selectItem"
                      rules={ this.state.useBasicDeliveryPrice && [
                        {
                          required: true,
                          message: "배달비를 설정해주세요"
                        }
                      ]}
                      initialValue={0}
                    >
                      <Radio.Group
                        className="searchRequirement"
                        onChange={(e) => this.onCheckBasicDeliveryFeeType(e)}
                        style={{ marginRight: 19 }}>
                        {frDuesType.map((value, key) => {
                          return <Radio value={key}>{value}</Radio>;
                        })}
                      </Radio.Group>
                    </FormItem>
                  </div>

                  { this.state.useBasicDeliveryPrice &&
                    <div className="contentBox">
                      <div className="subText"></div>
                      <FormItem
                        name="basicDeliveryPrice"
                        className="selectItem"
                        rules={[
                          {
                            required: true,
                            message: "수동배달비금액을 입력해주세요"
                          }
                        ]}>
                        <Input
                          placeholder="자동 시 지점 기본 배달비를 사용합니다."
                          className="override-input"
                          style={{ width: "18.5rem" }}
                          disabled={!this.state.useBasicDeliveryPrice}
                        />
                      </FormItem>
                      <div style={{ lineHeight: "32px" }}>&nbsp;&nbsp;원</div>
                    </div>
                  }

                  <div className="contentBox">
                    <div className="subText">가맹비설정</div>
                    <FormItem
                      name="duesType"
                      className="selectItem"
                      rules={[
                        {
                          required: true,
                          message: '가맹비를 설정해주세요',
                        },
                      ]}
                      initialValue={0}
                    >
                      <Radio.Group
                        className="searchRequirement"
                        onChange={(e) => this.onCheckDuesType(e)}
                        value={this.state.isAutoDues}
                        style={{ marginRight: 19 }}>
                        {frDuesType.map((value, key) => {
                          return <Radio value={key}>{value}</Radio>;
                        })}
                      </Radio.Group>
                    </FormItem>
                  </div>

                  {!this.state.isAutoDues && (
                    <div className="contentBox">
                      <div className="subText">가맹비</div>
                      <FormItem
                        name="dues"
                        className="selectItem"
                        rules={[
                          {
                            required: true,
                            message: '수동가맹비금액을 입력해주세요',
                          },
                        ]}
                        /**가맹점수정창에서 가맹비 설정을 자동->수동으로 바꿀때
                         *  data.dues=-1 이므로 수동가맹비 디폴트 110000원으로 설정 */
                        initialValue={110000}>
                        <Input
                          placeholder="가맹비를 설정해주세요 **0 ~ 300만원까지 가능"
                          className="override-input"
                          style={{ width: '18.5rem' }}
                        />
                      </FormItem>
                      <div style={{ lineHeight: '32px' }}>&nbsp;&nbsp;원</div>
                    </div>
                  )}

                  <div className="contentBox">
                    <div className="subText">추가가맹비</div>
                    <FormItem
                      name="duesExtraCount"
                      className="selectItem"
                      initialValue={0}>
                      <Input
                        placeholder="콜수"
                        className="override-input"
                        style={{ width: '4.5rem' }}
                      />
                    </FormItem>
                    <div style={{ lineHeight: '32px' }}>&nbsp;&nbsp;콜이상 건당&nbsp;&nbsp;</div>

                    <FormItem
                      name="duesExtraPrice"
                      className="selectItem"
                      initialValue={0}>
                      <Input
                        placeholder="금액"
                        className="override-input"
                        style={{ width: '4.5rem' }}
                      />
                    </FormItem>
                    <div style={{ lineHeight: '32px' }}>&nbsp;&nbsp;원</div>
                  </div>

                  <div className="contentBox">
                    <div className="subText">건별가맹비</div>
                    <FormItem
                      name="duesDeliveryPriceCount"
                      className="selectItem"
                      initialValue={0}>
                      <Input
                        placeholder="콜수"
                        className="override-input"
                        style={{ width: '4.5rem' }}
                      />
                    </FormItem>
                    <div style={{ lineHeight: '32px' }}>&nbsp;&nbsp;콜이상 건당&nbsp;&nbsp;</div>

                    <FormItem
                      name="duesDeliveryPrice"
                      className="selectItem"
                      initialValue={0}>
                      <Input
                        placeholder="금액"
                        className="override-input"
                        style={{ width: '4.5rem' }}
                      />
                    </FormItem>
                    <div style={{ lineHeight: '32px' }}>&nbsp;&nbsp;원</div>
                  </div>

                  <div className="contentBox">
                    <div style={{ fontSize: '1rem' }} className="subText">
                      <span className="desk">동별 요금제</span>
                      <span className="mobile">동별 요금제</span>
                    </div>
                    <FormItem
                      name="dongRateIdx"
                      className="selectItem"
                      initialValue={0}
                      rules={[
                        {
                          required: true,
                          message: '요금제를 선택해주세요',
                        },
                      ]}>
                      <Select style={{ width: '20rem' }}>
                        {this.state.dongRateNameList.map((item) => (
                          <Select.Option value={item.idx}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </FormItem>
                  </div>
                  <div className="contentBox">
                    <div style={{ fontSize: '1rem' }} className="subText">
                      <span className="desk">간이사업자여부</span>
                      <span className="mobile">간이사업자여부</span>
                    </div>
                    <FormItem name="simplifiedTaxPayer" className="selectItem">
                      <Checkbox
                        className="override-input checkbox"
                        checked={this.state.simplifiedTaxPayer}
                        onChange={(e) =>
                            this.setState({ simplifiedTaxPayer: e.target.checked })
                        }
                        disabled={needFrValidation || false}>
                        간이사업자 (미체크 시 일반과세자로 취급됨.)
                      </Checkbox>
                    </FormItem>
                  </div>
                  {companyId !== "connect9" &&
                    <div>
                      <div className="contentBox">
                        <div className="subText">콜수수료</div>
                        <FormItem
                          name="deliveryPriceSupportFundCount"
                          className="selectItem"
                          initialValue={0}>
                          <Input
                            placeholder="콜수"
                            className="override-input"
                            style={{ width: "4.5rem" }}
                          />
                        </FormItem>
                        <div style={{ lineHeight: "32px" }}>&nbsp;&nbsp;콜이상 건당&nbsp;&nbsp;</div>

                        <FormItem
                          name="deliveryPriceSupportFund"
                          className="selectItem"
                          initialValue={0}>
                          <Input
                            placeholder="금액"
                            className="override-input"
                            style={{ width: "4.5rem" }}
                          />
                        </FormItem>
                        <div style={{ lineHeight: "32px" }}>&nbsp;&nbsp;원</div>
                      </div>
                      <div className="text-[#ff0000]">※일반과세자: 콜수수료 부가세 포함하여 입력해주세요.</div>
                    </div>
                  }
                  <div className="contentBox">
                    <div className="subText">콜자동취소(초)</div>
                    <FormItem
                      name="autoCancelTimeSec"
                      className="selectItem"
                      initialValue={0}
                      rules={[
                        {
                          required: true,
                          message: "주문 자동 취소 시간(초)을 설정해주세요."
                        }
                      ]}>
                      <Input
                        placeholder="주문 자동 취소 시간(초)을 설정해주세요."
                        className="override-input"
                        style={{ width: "18.5rem" }}
                      />
                    </FormItem>
                    <div style={{ lineHeight: "32px" }}>&nbsp;&nbsp;초</div>
                  </div>


                  {Object.keys(this.state.selectSubBranch).length !== 0 ? (
                    <div className="contentBox">
                      <div style={{ fontSize: "1rem" }} className="subText">
                        <span className="desk">하위지점선택</span>
                      </div>
                      <FormItem
                        name="branchSubIdx"
                        className="selectItem"
                        >
                        <Select
                          className="desk"
                          placeholder="미선택"
                          style={{ width: 200 }}
                          // value={data && data.branchSubIdx}
                          onChange={(value) => {
                            this.setState({
                              branchSubIdx: value,
                            });
                          }}>
                          {this.state.selectSubBranch.map((value) => {
                            return (
                              <Option value={value.idx}>
                                {value.branchSubName}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div
                    className="registFran-btn "
                    style={{ marginTop: '10px' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="callTab">
                      {needFrValidation ? '인증하기' : '등록하기'}
                    </Button>
                  </div>
                </div>
              </div>

              <div className="mapLayout regist-call-map desk" id="myMap">
                {navermaps && (
                  <NaverMap
                    className="mapLayout3"
                    defaultZoom={14}
                    center={
                      this.props.data && !this.state.pinStatus
                        ? this.state.data
                          ? navermaps.LatLng(
                            this.state.data.latitude,
                            this.state.data.longitude
                          )
                          : navermaps.LatLng(lat, lng)
                        : this.state.targetLat && this.state.targetLng
                          ? navermaps.LatLng(
                            this.state.targetLat,
                            this.state.targetLng
                          )
                          : navermaps.LatLng(lat, lng)
                    }
                    onClick={(e) => {
                      // !this.props.data &&
                      this.setState({
                        targetLat: e.latlng.y,
                        targetLng: e.latlng.x,
                        pinStatus: true,
                      });

                      window.naver.maps.Service.reverseGeocode(
                        {
                          location: window.naver.maps.LatLng(
                            e.latlng.y,
                            e.latlng.x
                          ),
                        },
                        (status, response) => {
                          console.log(status);
                          console.log(response);
                          if (status !== navermaps.Service.Status.OK) {
                            Modal.info({
                              title: '조회실패',
                              content:
                                '유효하지 않은 지역입니다. 다시 선택해주세요.',
                            });
                          } else {
                            if (response.result.items.length > 0) {
                              this.setState(
                                {
                                  targetLat: e.latlng.y,
                                  targetLng: e.latlng.x,
                                  // destAddr1: response.v2.address.roadAddress
                                  //   ? response.v2.address.roadAddress
                                  //   : response.v2.address.jibunAddress,
                                  roadAddr: response.v2.address.roadAddress
                                    ? response.v2.address.roadAddress
                                    : response.v2.address.jibunAddress,
                                  // localAddr: response.v2.address.jibunAddress
                                  //   ? response.v2.address.jibunAddress
                                  //   : response.v2.address.roadAddress,
                                  localAddr: response.v2.results
                                    ? response.v2.results[1].region.area1.name +
                                    ' ' +
                                    response.v2.results[1].region.area2.name +
                                    ' ' +
                                    response.v2.results[1].region.area3.name
                                    : response.v2.address.jibunAddress
                                      ? response.v2.address.jibunAddress
                                      : response.v2.address.roadAddress,
                                },
                                () =>
                                  this.formRef.current.setFieldsValue({
                                    addr1: this.state.roadAddr, // 도로명 주소
                                    addr3: this.state.localAddr, // 지번
                                  })
                              );
                            } else {
                              Modal.info({
                                title: '조회실패',
                                content:
                                  '유효하지 않은 지역입니다. 다시 선택해주세요.',
                              });
                            }
                          }
                        }
                      );
                    }}>
                    <Marker
                      position={
                        this.props.data && !this.state.pinStatus
                          ? this.state.data
                            ? navermaps.LatLng(
                              this.state.data.latitude,
                              this.state.data.longitude
                            )
                            : navermaps.LatLng(lat, lng)
                          : this.state.targetLat && this.state.targetLng
                            ? navermaps.LatLng(
                              this.state.targetLat,
                              this.state.targetLng
                            )
                            : navermaps.LatLng(lat, lng)
                      }
                      icon={MarkerTarget2Icon}
                    />
                  </NaverMap>
                )}
              </div>

              {/* <div className="registFranWrapper bot">
                  <div className="registFranTitle">월관리비 설정</div>

                  <div className="contentBox">
                    <div className="subTitle">월회비 최초납부일</div>
                    {this.state.isMember ? (
                      <>
                        <FormItem
                          name="chargeDate"
                          className="selectItem"
                          style={{ marginLeft: 10 }}
                          initialValue={
                            data
                              ? moment(data.chargeDate, "YYYY-MM-DD")
                              : moment(today, dateFormat)
                          }
                        >
                          <DatePicker
                            style={{ marginLeft: 10 }}
                            format={dateFormat}
                          />
                        </FormItem>
                        <div className="subTitle">관리비</div>
                        <FormItem
                          name="dues"
                          className="selectItem"
                          initialValue={data ? data.dues : 100000}
                        >
                          <Input
                            placeholder="관리비 입력"
                            className="override-input sub"
                          />
                        </FormItem>
                      </>
                    ) : (
                      <>
                        <FormItem
                          className="selectItem"
                          style={{ marginLeft: 10 }}
                        >
                          <DatePicker
                            style={{ marginLeft: 10 }}
                            format={dateFormat}
                            disabled
                          />
                        </FormItem>
                        <div className="subTitle">관리비</div>
                        <FormItem className="selectItem">
                          <Input
                            placeholder="관리비 입력"
                            className="override-input sub"
                            disabled
                          />
                        </FormItem>
                      </>
                    )}
                  </div>

                  <div className="registFran-btn">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="callTab"
                      style={{
                        width: 180,
                        height: 40,
                        fontSize: 18,
                        marginTop: -5,
                      }}
                    >
                      등록하기
                    </Button>
                  </div>
                </div> */}
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  branchIdx: state.login.loginInfo.branchIdx,
  loginInfo: state.login.loginInfo,
  riders: state.riderReducer.riders,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RegistFranDialog);
