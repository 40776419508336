import { Button, Checkbox, DatePicker, Input, Select, Table, Modal } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import xlsx from 'xlsx';
import { useSelector } from 'react-redux';
import { httpGet, httpUrl } from '../../api/httpClient';
import { comma } from '../../lib/util/numberUtil';
import axios from 'axios';
import Const from '../../const';
import { SearchOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import FileSaver from 'file-saver';
import { selectCategory, selectCategoryForBranchForFr, selectCategoryForBranchForRider } from '../../lib/util/codeUtil';

const today = moment().format('YYYY-MM-DD');

const AgencyNcashList = (props) => {
  const loginInfo = useSelector((state) => state.login.loginInfo);
  const CancelToken = axios.CancelToken;
  const source = useRef(null);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 20,
  });
  const [category, setCategory] = useState();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [isLoading, setIsLoading] = useState(false);
  const [exceldownloading, setExceldownloading] = useState(false);
  const [type, setType] = useState([]);
  const [search, setSearch] = useState(null);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalWithdrawal, setTotalWithdrawal] = useState(0);
  const checkboxOption = ['입금', '출금'];
  const [kind, setKind] = useState(null);
  const [userType, setUserType] = useState(0);
  const [selectLabel, setSelectLabel] = useState('전체');

  const getNcashtDate = async (customParams) => {
    if (source.current !== null) {
      // already exists request
      source.current.cancel();
    }
    source.current = CancelToken.source();
    setIsLoading(true);
    try {
      return await axios.get('deposit/list', {
        baseURL: Const.settlementUrl,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        params: customParams,
        cancelToken: source.current.token,
      });
    } catch (e) {
      console.error('getting ncash-deposit-list is fail: ' + e);
    }
  };
  const getData = async (paging) => {
    if (exceldownloading) return;
    let currPage = pagination.current;
    if (paging) {
      currPage = 1;
    }
    const kindNum = kind === '' ? null : kind;
    const typeParams = type.length < 1 ? checkboxOption : type;
    const paramsCategory = category === '' ? null : category;
    let customParams = {
      startDate: startDate,
      endDate: endDate,
      companyId: loginInfo.companyId,
      search: search,
      category: paramsCategory,
      ncashType: typeParams.join(),
      kind: kindNum,
      page: currPage,
      size: pagination.pageSize,
      region: Const.region,
      userType: userType,
    };
    const datas = await getNcashtDate(customParams);
    if (datas) {
      setList(() => {
        return datas.data.results;
      });
      setPagination({
        ...pagination,
        current: currPage,
        total: datas.data.totalCount,
      });
      setTotalDeposit(datas.data.totalDepositAmount);
      setTotalWithdrawal(datas.data.totalWithdrawalAmount);
      setIsLoading(false);
    }
    if (source.current === null) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
    return () => {
      if (source.current !== null) {
        source.current.cancel();
      }
    };
  }, [pagination.current, category, type, kind, props.refresh, userType]);
  const downloadExcelApi = async (customParamsForExcel) => {
    if (source.current !== null) {
      // already exists request
      source.current.cancel();
    }
    source.current = CancelToken.source();
    try {
      return await axios.get('deposit/list/excel', {
        baseURL: Const.settlementUrl,
        params: customParamsForExcel,
        cancelToken: source.current.token,
        responseType: 'arraybuffer',
      });
    } catch (e) {
      console.error('ncashCompanyList-execl-downloading is failed: ' + e);
    }
  };
  const onDownloadExcel = async () => {
    const kindNum = kind === '' ? null : kind;
    const paramsCategory = category === '' ? null : category;
    let customParamsForExcel = {
      startDate: startDate,
      endDate: endDate,
      companyId: loginInfo.companyId,
      search: search,
      kind: kindNum,
      category: paramsCategory,
      region: Const.region,
      userType: userType,
    };
    if (isLoading) return;
    setIsLoading(true);
    setExceldownloading(true);
    const response = await downloadExcelApi(customParamsForExcel);
    if (response) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, '예치금-전체내역-' + `${moment(startDate).format('YYYY-MM-DD')}.xlsx`);
      setIsLoading(false);
      setExceldownloading(false);
      Modal.success({
        content: '엑셀파일 다운로드가 완료되었습니다.',
      });
      setIsLoading(false);
      setExceldownloading(false);
    } else {
      Modal.error({
        content: '다운로드에 실패했습니다. 검색 기간을 줄여서 다시 시도하시거나 관리자에게 문의해주세요.',
      });
      setIsLoading(false);
      setExceldownloading(false);
    }
  };
  //
  // const selectCategory = [
  //   '전체',
  //   '배달 수수료',
  //   '배달 수수료 복원',
  //   '렌트비 회수',
  //   '과태료 회수',
  //   '수리비 회수',
  //   '대여금 회수',
  //   '선충전 회수',
  //   '음식보험 회수',
  //   '가맹비 회수',
  //   '기타',
  //   '렌트비 납부',
  //   '과태료 납부',
  //   '수리비 납부',
  //   '프로모션 지급',
  //   '대여금 지급',
  //   '선충전 지급',
  //   '음식배상',
  //   '기타 비용',
  // ];
  const userTypeString = {
    RIDER: '라이더',
    FRANCHISE: '가맹점',
    AGENCY: '대리점',
    AGENCY_HEADQUARTER: '제휴본사',
  };
  const columns = [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '주문번호',
      dataIndex: 'orderIdx',
      className: 'table-column-center',
      render: (data, row) => row.category === 'DELIVERY_PRICE_RECEIVE' && data,
    },
    {
      title: '지급자명',
      dataIndex: 'payoutName',
      className: 'table-column-center',
    },
    // {
    //   title: '사업자번호',
    //   dataIndex: 'businessNum',
    //   className: 'table-column-center',
    // },
    // {
    //   title: '라이더 소속 대리점',
    //   dataIndex: 'riderBranch',
    //   className: 'table-column-center',
    // },
    {
      title: '구분',
      dataIndex: 'targetUserTypeString',
      className: 'table-column-center',
      render: (data) => userTypeString[data],
    },
    {
      title: '대상명',
      dataIndex: 'targetName',
      className: 'table-column-center',
    },
    {
      title: '사업자번호',
      dataIndex: 'businessNum',
      className: 'table-column-center',
    },
    {
      title: '카테고리',
      dataIndex: 'categoryName',
      className: 'table-column-center',
    },
    {
      title: '금액',
      dataIndex: 'total',
      className: 'table-column-center',
      render: (data, row) => (
        <div
          style={{
            textAlign: 'right',
            paddingRight: 10,
            color: data < 0 ? '#f00000' : '#1890ff',
          }}>
          {comma(data)}
        </div>
      ),
    },
    {
      title: '예치금 잔액',
      dataIndex: 'afterNcash',
      className: 'table-column-center',
      render: (data) => <div style={{ textAlign: 'right', paddingRight: 10 }}>{comma(data)}</div>,
    },
    {
      title: '메모',
      dataIndex: 'memo',
      className: 'table-column-center',
    },
  ];
  const onChangeDate = (type, _, date) => {
    if (type === 'start') {
      setStartDate(moment(date).format('YYYY-MM-DD'));
      const temp = moment(date).add(30, 'd').format('YYYY-MM-DD');
      if (moment(endDate).isAfter(temp)) setEndDate(temp);
    } else {
      setEndDate(moment(date).format('YYYY-MM-DD'));
    }
  };
  const disabledDate = (current, from) => {
    if (startDate) {
      return Math.abs(current.diff(startDate, 'days')) > 30;
    }
    return false;
  };
  // const onChangeCategory = (value) => {
  //   if (value !== null) {
  //     const val = value;
  //     const num = val.replace(/[^0-9]/g, '');
  //     const str = val.replace(/[0-9]/g, '');
  //     if (num !== null && isNaN(num)) setKind(num);
  //     if (str !== null) setCategory(str);
  //   } else {
  //     setKind(null);
  //     setCategory(null);
  //   }
  // };

  const selectCategoryType =
    userType === 1 ? selectCategoryForBranchForRider : userType === 2 ? selectCategoryForBranchForFr : selectCategory;

  return (
    <div id={'ncashCompanyList'}>
      <div className={'ncl-menu-wrap'}>
        <div className={'ncl-menu-totalsum'}>
          <span style={{ color: '#FA2020' }}>총 출금 합계: {comma(totalWithdrawal)}</span>
          <span style={{ color: '#c1c1c1', margin: '0 10px' }}> | </span>
          <span style={{ color: '#2036FA' }}>총 입금 합계: {comma(totalDeposit)}</span>
        </div>
        <div className={'ncl-select-box'}>
          <Select
            defaultValue={'구분'}
            onChange={(value, item) => {
              setUserType(value);
              setKind('');
              setCategory(item.category);
              setSelectLabel('전체');
              setPagination({
                ...pagination,
                current: 1,
              });
            }}
            style={{ flex: 1 }}
            options={[
              { value: 0, label: '구분', category: '' },
              { value: 1, label: '라이더', category: 'All' },
              { value: 2, label: '가맹점', category: 'All' },
            ]}
          />
        </div>
        <div className={'ncl-select-box'} style={{ marginRight: 10 }}>
          <Select
            defaultValue={'전체'}
            value={selectLabel}
            onChange={(value, item) => {
              setKind(item.kind);
              setCategory(item.category);
              setSelectLabel(value);
              setPagination({
                ...pagination,
                current: 1,
              });
            }}
            style={{ flex: 1 }}>
            {selectCategoryType.map((value) => {
              return (
                <Select.Option key={value.label} value={value.label} kind={value.kind} category={value.category}>
                  {value.label}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <div className={'ncl-input-box'} style={{ width: 240 }}>
          <Input
            onPressEnter={() => getData(true)}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={'가맹점명/지급자명/라이더명/대상명/주문번호/메모'}
            style={{
              border: 'none',
              paddingLeft: 10,
              width: 200,
            }}
          />
          <SearchOutlined style={{ padding: '0 10px', cursor: 'pointer' }} onClick={() => getData(true)} />
        </div>
        <div className={'ncl-input-box'} style={{ width: 140 }}>
          <DatePicker
            style={{ border: 'none', flex: 1 }}
            onChange={(_, date) => onChangeDate('start', _, date)}
            value={moment(startDate)}
            allowClear={false}
          />
        </div>
        <span style={{ marginRight: 10 }}>-</span>
        <div className={'ncl-input-box'} style={{ width: 140, marginLeft: 0 }}>
          <DatePicker
            style={{ border: 'none', flex: 1 }}
            onChange={(_, date) => onChangeDate('end', _, date)}
            value={moment(endDate)}
            disabledDate={disabledDate}
            allowClear={false}
          />
        </div>
        <Button
          style={{
            height: 40,
            borderColor: '#c1c1c1',
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => getData(true)}>
          검색
        </Button>
        <Checkbox.Group
          className={'ncl-checkbox'}
          options={checkboxOption}
          defaultValue={checkboxOption}
          onChange={(check) => setType(check)}
        />
        <div className={'ncl-excel-box'} onClick={onDownloadExcel}>
          엑셀다운로드
          <VerticalAlignBottomOutlined />
        </div>
      </div>

      <div className={'ncl-table'}>
        <Table
          rowKey={(record) => record.idx}
          dataSource={list}
          columns={columns}
          pagination={pagination}
          loading={isLoading}
          rowClassName={(record, index) => index % 2 !== 0 && 'ncl-table-row'}
          onChange={(pagination) => setPagination(pagination)}
        />
      </div>
    </div>
  );
};

export default AgencyNcashList;
