import { Button, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../css/modal.css';
import CloseIcon from '../../../img/login/close.png';
import {httpPost, httpUrl} from "../../../api/httpClient";
import {registError} from "../../../api/Modals";

class ZeroCallFrMemoDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      memo: '',
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    console.log(this.props.data);
  }

  handleSubmit = () => {
    let self = this;
    const memo = self.state.memo;
    const frIdx = self.props.data.frIdx;
    Modal.info({
      title: '사유 작성',
      content: '사유 작성을 완료하시겠습니까?',
      onOk() {
        if (memo == null || memo == ""
            || (typeof memo === 'string' && memo.trim().length === 0)) {
          Modal.info({
            title: "사유 미입력",
            content: "사유를 입력해주세요."
          });
          return;
        }
        httpPost(httpUrl.insertZeroCallFrMemo, [], {
          frIdx,
          zeroCallMemo: memo,
          zeroCallMemoExists: 1,
        }).then((res) => {
          if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
            Modal.info({
              title: '사유 작성 완료',
              content: '사유를 저장하였습니다.',
            });
            self.props.callback(self.props.data.idx, frIdx, memo.replace(/\n/g, '<br/>'), 1);
            self.props.close();
          } else if (res.data === 'NO_DATA') {
            Modal.info({
              title: '작성 내용 없음',
              content: '저장할 사유가 없습니다.',
            });
          } else if (res.data === 'SERVER_ERROR') {
            Modal.info({
              title: '서버에러',
              content: '서버 에러로 처리에 실패하였습니다.',
            });
          } else {
            registError();
          }
        });
        return;
      },
      okCancel() {},
    });
  };

  render() {
    const { close, data } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="rider-Dialog2">
          <div className="rider-content2" style={{ padding: '50px' }}>
            <div className="rider-title2">사유 작성</div>
            <img
              onClick={close}
              src={CloseIcon}
              className="rider-close2"
              alt="close"
            />
            <div>
              <div
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  marginTop: '10px',
                }}>
                가맹점명: {data.frName}
              </div>
              <div style={{ fontSize: '18px', fontWeight: 'bold' }}>
                마지막 이용날짜:{' '}
                {moment(data.lastOrderDate).format('YYYY-MM-DD')}
              </div>
            </div>
            <div className="listBlock">
              <TextArea
                rows={5}
                id="input6"
                placeholder={'사유를 작성해주세요.(50자 내외)'}
                autoComplete="off"
                onChange={(e) =>
                  this.setState({
                    memo: e.target.value,
                  })
                }
                maxLength={50}
              />
            </div>
            <Button
              style={{ position: 'absolute', left: '50%', marginTop: "10px" }}
              onClick={() => this.handleSubmit()}>
              작성완료하기
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  branchIdx: state.login.loginInfo.branchIdx,
});

export default connect(mapStateToProps)(ZeroCallFrMemoDialog);
