
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';

const getBranchNameList = () => {
  if (!global.branchNameList) {
    getBranchNameListFetch();
    return [];
  }
  else return global.branchNameList;
}

const getBranchNameListFetch = async () => {
  const res = await httpGet(httpUrl.branchListInfo, [], {});
  if (Array.isArray(res.data.branches)) global.branchNameList = res.data.branches;
}


export { getBranchNameList };
