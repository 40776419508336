const formatDateNumber = (num) => {
  num += '';
  return num.length < 2 ? '0' + num : num;
};

const formatDate = (date) => {
  // if (date === null) return '-';
  if (!date) return null;
  let newDate = new Date(date);
  return (
    newDate.getFullYear() +
    '-' +
    formatDateNumber(newDate.getMonth() + 1) +
    '-' +
    formatDateNumber(newDate.getDate()) +
    ' ' +
    formatDateNumber(newDate.getHours()) +
    ':' +
    formatDateNumber(newDate.getMinutes())
  );
  // let day = date.slice(0, 10);
  // let second = date.slice(11, 16);
  // return day + " " + second;
};

const formatDateToDay = (date) => {
  // if (date === null) return '-';
  if (!date) return null;
  let newDate = new Date(date);
  return (
    newDate.getFullYear() +
    '-' +
    formatDateNumber(newDate.getMonth() + 1) +
    '-' +
    formatDateNumber(newDate.getDate())
  );
};

const formatDateToDayPlusSelectedMonth = (date, num) => {
  // if (date === null) return '-';
  if (!date) return null;
  let newDate = new Date(date);
  let tomorrow = new Date(newDate.setDate(newDate.getDate() + 1));
  if (num == 99) {
    return '2999-12-30';
  } else if (num == 0) {
    return (
      tomorrow.getFullYear() +
      '-' +
      formatDateNumber(tomorrow.getMonth() + 1) +
      '-' +
      formatDateNumber(tomorrow.getDate())
    );
  } else if (Number(newDate.getMonth()) + Number(num) >= 12) {
    return (
      newDate.getFullYear() +
      1 +
      '-' +
      formatDateNumber(Number(newDate.getMonth()) + Number(num) - 11) +
      '-' +
      formatDateNumber(newDate.getDate())
    );
  } else {
    return (
      newDate.getFullYear() +
      '-' +
      formatDateNumber(Number(newDate.getMonth()) + Number(num) + 1) +
      '-' +
      formatDateNumber(newDate.getDate())
    );
  }
};

const getFreeDuesMonth = (date1, date2) => {
  if (!date1 || !date2) return null;
  // console.log(date);
  var newDate1 = new Date(date1);
  var newDate2 = new Date(date2);

  if (newDate1.getFullYear() == '2999') {
    return '무제한';
  } else if (
    12 * (newDate1.getFullYear() - newDate2.getFullYear()) +
      (newDate1.getMonth() - newDate2.getMonth()) ==
    0
  ) {
    return '미적용';
  } else {
    return (
      12 * (newDate1.getFullYear() - newDate2.getFullYear()) +
      (newDate1.getMonth() - newDate2.getMonth()) +
      '개월'
    );
  }
};

const getDay = (date) => {
  if (!date) return null;
  let newDate = new Date(date);
  return formatDateNumber(newDate.getDate());
};

const formatYear = (date) => {
  // if (date === null) return '-';
  if (!date) return null;
  let newDate = new Date(date);
  return newDate.getFullYear();

  // let day = date.slice(0, 10);
  // let second = date.slice(11, 16);
  // return day + " " + second;
};

const formatDateSecond = (date) => {
  // if (date === null) return '-';
  if (!date) return null;
  let newDate = new Date(date);
  // let day = date.slice(0, 10);
  // let second = date.slice(11, 16);
  // let result2 = day + " " + second;
  let result =
    newDate.getFullYear() +
    '-' +
    formatDateNumber(newDate.getMonth() + 1) +
    '-' +
    formatDateNumber(newDate.getDate()) +
    ' ' +
    formatDateNumber(newDate.getHours()) +
    ':' +
    formatDateNumber(newDate.getMinutes()) +
    ':' +
    formatDateNumber(newDate.getSeconds());
  // console.log(`prevResult: ${result} current:${result2}`);
  return result;
};

const dateFormat = (date) => {
  if (!date) return null;
  let hour = parseInt(date / 60);
  let minute = date % 60;
  return hour === 0
    ? minute + '분'
    : minute === 0
    ? hour + '시간'
    : hour + '시간 ' + minute + '분';
};

const minFormat = (date) => {
  if (!date) return null;
  let newDate = new Date(date);
  let hour = newDate.getHours() * 60;
  let min = parseInt(newDate.getMinutes());
  let time = hour + min;
  // console.log(time);
  return time;
};

const dayFormat = (date) => {
  if (!date) return null;
  // console.log(date);
  for (let i = 0; i < date.length; i++) {
    // console.log(date);
    let year = date.substr(0, 4);
    let month = date.substr(4, 2);
    let day = date.substr(6, 2);
    // return new Date(year, month, day);
    let time = year + '-' + month + '-' + day;
    return time;
  }
};

const monthFormat = (date) => {
  if (!date) return null;
  // console.log(date);
  for (let i = 0; i < date.length; i++) {
    // console.log(date);
    let year = date.substr(0, 4);
    let month = date.substr(4, 2);
    // return new Date(year, month, day);
    let time = year + '-' + month;
    return time;
  }
};

const startDateFormat = (date) => {
  if (!date) return null;
  let newDate = new Date(date);
  return (
    newDate.getFullYear() +
    '-' +
    formatDateNumber(newDate.getMonth() + 1) +
    '-' +
    formatDateNumber(newDate.getDate()) +
    ' 00:00:00'
  );
};

const endDateFormat = (date) => {
  if (!date) return null;
  let newDate = new Date(date);
  return (
    newDate.getFullYear() +
    '-' +
    formatDateNumber(newDate.getMonth() + 1) +
    '-' +
    formatDateNumber(newDate.getDate()) +
    ' 23:59:59'
  );
};

const statFormat = (date) => {
  if (!date) return null;
  let newDate = new Date(date);
  return (
    newDate.getFullYear() +
    formatDateNumber(newDate.getMonth() + 1) +
    formatDateNumber(newDate.getDate())
  );
};

const statMonthFormat = (date) => {
  if (!date) return null;
  let newDate = new Date(date);
  return newDate.getFullYear() + formatDateNumber(newDate.getMonth() + 1);
};

const formatDateUnit = (num) => {
  num += '';
  return num.length < 2 ? '0' + num : num;
};

const formatYMD = (date) => {
  if (!date) return null;
  if (date instanceof Date) {
  } else {
    date = new Date((date + '').substring(0, 19));
  }
  return (
    date.getFullYear() +
    '-' +
    formatDateUnit(date.getMonth() + 1) +
    '-' +
    formatDateUnit(date.getDate())
  );
};

const formatHM = (date) => {
  if (!date) return null;
  if (date instanceof Date) {
  } else {
    date = new Date((date + '').substring(0, 19));
  }
  return (
    formatDateUnit(date.getHours()) + ':' + formatDateUnit(date.getMinutes())
  );
};

const formatYMDHM = (date) => {
  if (!date) return null;
  if (date instanceof Date) {
  } else {
    date = new Date((date + '').substring(0, 19));
  }
  return (
    date.getFullYear() +
    '-' +
    formatDateUnit(date.getMonth() + 1) +
    '-' +
    formatDateUnit(date.getDate()) +
    ' ' +
    formatDateUnit(date.getHours()) +
    ':' +
    formatDateUnit(date.getMinutes())
  );
};

const formatYMDHMS = (date) => {
  if (!date) return null;
  if (date instanceof Date) {
  } else {
    date = new Date((date + '').substring(0, 19));
  }
  return (
    date.getFullYear() +
    '-' +
    formatDateUnit(date.getMonth() + 1) +
    '-' +
    formatDateUnit(date.getDate()) +
    ' ' +
    formatDateUnit(date.getHours()) +
    ':' +
    formatDateUnit(date.getMinutes()) +
    ':' +
    formatDateUnit(date.getSeconds())
  );
};
const formatMDHMS = (date) => {
  if (!date) return null;
  if (date instanceof Date) {
  } else {
    date = new Date((date + '').substring(0, 19));
  }
  return (
    formatDateUnit(date.getMonth() + 1) +
    '-' +
    formatDateUnit(date.getDate()) +
    ' ' +
    formatDateUnit(date.getHours()) +
    ':' +
    formatDateUnit(date.getMinutes()) +
    ':' +
    formatDateUnit(date.getSeconds())
  );
};
const formatJSONDateToString = (data) => {
  if (!data) return null;
  const { date, time } = data;
  return `${date.year}-${('00' + date.month).slice(-2)}-${(
    '00' + date.day
  ).slice(-2)} ${('00' + time.hour).slice(-2)}:${('00' + time.minute).slice(
    -2
  )}:${('00' + time.second).slice(-2)}`;
};

function localStringToDate(str) {
  if (!str) return new Date();
  return new Date(str.replace(' ', 'T') + '+09:00');
}

function checkCompleteDateExpired(str) {
  return false;
  console.log('checkdate:' + str);
  return (
    new Date().getTime() - localStringToDate(str).getTime() > 3 * 60 * 60 * 1000
  );
}

export {
  formatDate,
  formatDateToDayPlusSelectedMonth,
  getFreeDuesMonth,
  formatDateToDay,
  formatYear,
  formatDateSecond,
  dateFormat,
  minFormat,
  dayFormat,
  monthFormat,
  startDateFormat,
  endDateFormat,
  statFormat,
  statMonthFormat,
  formatYMD,
  formatYMDHM,
  formatYMDHMS,
  formatMDHMS,
  getDay,
  formatHM,
  formatJSONDateToString,
  checkCompleteDateExpired,
};
