import { Button, Modal, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import '../../../css/modal.css';
import { reactLocalStorage } from 'reactjs-localstorage';

const PopupNotice = (props) => {
  const [visible, setVisible] = useState(false);

  const aigoPopupKey = '@nnboxControl:noticePopup';
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const aigoPopupExpireDate = reactLocalStorage.getObject(aigoPopupKey);
    if (!aigoPopupExpireDate || aigoPopupExpireDate == undefined || (typeof aigoPopupExpireDate === 'object' && Object.keys(aigoPopupExpireDate).length === 0)) {
      setVisible(true);
    }
    else {
      const expireMoment = moment(aigoPopupExpireDate)
      if (expireMoment.isBefore(moment())) setVisible(true);
    }
  }

  const saveStorage = async () => {
    const curDate = moment().add(1, 'days').toISOString();
    reactLocalStorage.setObject(aigoPopupKey, curDate);
  }

  return (
    <Modal
      visible={visible}
      title="[프로그램(젠딜리) 업데이트 전환 안내공지]"
      onOk={() => {
      }}
      footer={[
        <Button
          key="answer"
          onClick={() => {
            saveStorage();
            setVisible(false)
          }}>
          하루동안 보지않음
        </Button>,
        <Button
          key="ok"
          onClick={() => {
            setVisible(false)
          }}>
          닫기
        </Button>,
      ]}
      destroyOnClose={true}
      closable={false}>
      <div style={{ fontSize: '16px' }}>
        <b style={{color: '#0600ff'}}>*전환 일정</b><br />
        - 2024년 08월12일 월요일부터
        지역별 순차적으로 진행 예정.<br />
        <br />
        <b style={{color: '#0600ff'}}>*프로그램 교육 및 관제 세팅 일정</b><br />
        - 2024년 08월 05일 월요일부터
        지역별 순차적으로 진행 예정. <br />
        <br />
        <b style={{color: '#0600ff'}}>*대리점 부가세, 원천세 안내 일정</b><br />
        - 2024년 08월 05일 월요일부터
        본사 담당부서에서 안내 <br />
        <br />
        현행 냠냠박스프로그램에서 <br />
        가맹점 / 라이더 / 관리자 분들의
        보다나은 편의성 제공과<br />
        직관적이고 안정된 연동으로
        업데이트 되었습니다.<br />
        <br />
        지역별 업데이트 전환 일정은 <br />
        지원센터에서 추후 한 번 더
        공지 드릴 예정입니다.<br />
        <br />
        감사합니다.
      </div>
    </Modal>
  );
}
export default PopupNotice;
