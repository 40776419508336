import { Button, Checkbox, Form, Input, Radio, Table } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../../api/httpClient';
import '../../../css/modal.css';
import CloseIcon from '../../../img/login/close.png';
import {
  riderLevelText,
  tableStatusString,
  userGroupString
} from '../../../lib/util/codeUtil';
import SelectBox from '../../input/SelectBox';
import {connect} from "react-redux";

const Search = Input.Search;
const today = new Date();

class SearchFranchiseToRiderDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: today,
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      addressType: 0,
      selectedRowKeys: [],
      isMulti: false,
      allbranchCheck: false,

      // data param
      riderLevel: [],
      searchName: '',
      userStatus: 0,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList(true);
  }

  // 라이더 검색
  onSearchRider = (value) => {
    this.setState(
      {
        searchName: value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  getList = (isInit) => {
    const { riderIdx, stillWorking, onlyRider, teamManagerOnly, availableOnly } = this.props;
    const pageNum = this.state.pagination.current;
    const userStatus = stillWorking
      ? 1
      : this.state.userStatus === 0
      ? ''
      : this.state.userStatus;
    const searchName = this.state.searchName;
    const riderLevel = onlyRider
      ? [1]
      : teamManagerOnly
      ? [2, 3, 4, 5, 6, 7, 8, 9]
      : [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const riderStatus = availableOnly ? 1 : '';
    const approvedCode = availableOnly ? 2 : '';
    const allbranchStatus = !this.state.allbranchCheck ? '' : 1;

    httpGet(
      this.props.assignRiderList
        ? httpUrl.riderAssignShareList
        : httpUrl.riderList,
      [
        10,
        pageNum,
        searchName,
        userStatus,
        riderLevel,
        riderStatus,
        approvedCode,
        allbranchStatus,
      ],
      {}
    ).then((result) => {
      console.log(result);
      const pagination = { ...this.state.pagination };
      pagination.current = result.data.currentPage;
      pagination.total = result.data.totalCount;
      this.setState({
        list: result.data.riders,
        pagination,
      });

      // mount될 때 data idx 배열 초기화
      if (isInit && result.data.riders.length > 0) {
        // console.log(result.data.franchises[0].idx)
        var totCnt = result.data.riders[0].idx;
        var lists = [];
        for (let i = 0; i < totCnt; i++) {
          lists.push(false);
          // console.log(lists)
        }
        this.setState({
          dataIdxs: lists.filter((x) => x.idx !== riderIdx),
        });
      }
    });
  };

  onSelectChange = (selectedRowKeys) => {
    // console.log('selectedRowKeys changed: ', selectedRowKeys)
    // console.log("selectedRowKeys.length :"+selectedRowKeys.length)

    // console.log(this.state.list)
    var cur_list = this.state.list;
    var overrideData = {};
    for (let i = 0; i < cur_list.length; i++) {
      var idx = cur_list[i].idx;
      if (selectedRowKeys.includes(idx)) overrideData[idx] = true;
      else overrideData[idx] = false;
    }
    // console.log(overrideData)

    var curIdxs = this.state.dataIdxs;
    curIdxs = Object.assign(curIdxs, overrideData);

    selectedRowKeys = [];
    for (let i = 0; i < curIdxs.length; i++) {
      if (curIdxs[i]) {
        console.log('push  :' + i);
        selectedRowKeys = [...selectedRowKeys, i];
        console.log(selectedRowKeys);
      }
    }
    console.log('#### :' + selectedRowKeys);
    this.setState({
      selectedRowKeys: selectedRowKeys,
      dataIdxs: curIdxs,
    });
  };

  onSubmit = () => {
    if (this.props.callback) {
      this.props.callback(this.state.selectedRowKeys);
    }
    this.props.close();
  };

  onChangeMulti = (e) => {
    this.setState({ isMulti: e.target.value });
  };

  onRiderSelected = (data) => {
    // console.log(data)
    if (this.props.callback) {
      this.props.callback(data);
    }
    this.props.close();
  };

  onAllBranchCheck = (e) => {
    this.setState({ allbranchCheck: e.target.checked }, () => {
      this.getList();
    });
  };

  render() {
    const { close, multi } = this.props;
    const companyId = this.props.branchInfo.companyId;

    const columns = [
      {
        title: '순번',
        dataIndex: 'idx',
        className: 'table-column-center desk',
      },
      {
        title: companyId !== 'connect9'? '지사명' : '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center desk',
      },
      {
        title: '기사명',
        dataIndex: 'riderName',
        className: 'table-column-center',
        render: (data, row) =>
          this.state.isMulti ? (
            <div>{data}</div>
          ) : (
            <div
              className="riderNameTag"
              onClick={() => {
                this.onRiderSelected(row);
              }}>
              {data}
            </div>
          ),
      },
      {
        title: '직급',
        dataIndex: 'riderLevel',
        className: 'table-column-center desk tableSub',
        render: (data) => <div>{riderLevelText[data]}</div>,
      },
      {
        title: '직급',
        dataIndex: 'riderLevel',
        className: 'table-column-center mobile',
        render: (data, row) => (
          <div>
            {riderLevelText[row.riderLevel]}(
            {row.riderSettingGroup.settingGroupName})
          </div>
        ),
      },
      {
        title: '기사그룹',
        dataIndex: 'riderSettingGroup',
        className: 'table-column-center desk',
        render: (data) => <div>{userGroupString[data]}</div>,
      },
    ];

    const selectedRowKeys = this.state.selectedRowKeys;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="searchFranchise-Dialog">
          <div className="searchFranchise-content">
            <div className="searchFranchise-title">기사조회</div>
            <img
              onClick={close}
              src={CloseIcon}
              className="surcharge-close"
              alt="닫기"
            />
                  <Checkbox
                      className="riderBlind-checkbox"
                      style={{ marginRight: 5, marginTop: 5, marginLeft: 15 }}
                      defaultChecked={this.state.allbranchCheck ? 'checked' : ''}
                      onChange={this.onAllBranchCheck}>
                    {companyId !== 'connect9' ? '전지사조회' : '전지점조회'}
                  </Checkbox>

            <Form ref={this.formRef} onFinish={this.onSubmit}>
              <div className="layout">
                <div className="searchFranchiseWrapper">
                  <div className="searchFranchise-list">
                    <div className="inputBox inputBox-searchFranchise sub">
                      <SelectBox
                        value={tableStatusString[this.state.userStatus]}
                        code={Object.keys(tableStatusString)}
                        codeString={tableStatusString}
                        onChange={(value) => {
                          if (parseInt(value) !== this.state.userStatus) {
                            this.setState(
                              {
                                userStatus: parseInt(value),
                                pagination: {
                                  current: 1,
                                  pageSize: 10,
                                },
                              },
                              () => this.getList()
                            );
                          }
                        }}
                      />
                      <Search
                        placeholder="기사검색"
                        className="searchRiderInput"
                        enterButton
                        allowClear
                        onSearch={this.onSearchRider}
                        style={{}}
                      />
                      {/* 멀티기능 */}
                      {multi && (
                        <Radio.Group
                          onChange={this.onChangeMulti}
                          value={this.state.isMulti}
                          className="selMulti">
                          <Radio value={false}>single</Radio>
                          <Radio value={true}>multi</Radio>
                        </Radio.Group>
                      )}
                      {this.props.setSkipIfAssigned && (
                        <div
                          style={{
                            display: 'inline-block',
                            border: '1px solid',
                            marginLeft: 10,
                            paddingLeft: 10,
                            paddingRight: 10,
                          }}>
                          <Checkbox
                            onChange={(e) => {
                              this.props.setSkipIfAssigned(e.target.checked);
                            }}
                            checked={this.props.skipIfAssigned}>
                            배차 주문 제외
                          </Checkbox>
                        </div>
                      )}
                    </div>

                    {/* 멀티기능 */}
                    {multi && (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="submitBtn">
                        조회
                      </Button>
                    )}
                  </div>
                </div>

                <div className="dataTableLayout-01">
                  {this.state.isMulti ? (
                    <Table
                      rowKey={(record) => record.idx}
                      rowSelection={rowSelection}
                      dataSource={this.state.list}
                      columns={columns}
                      pagination={this.state.pagination}
                      onChange={this.handleTableChange}
                    />
                  ) : (
                    <Table
                      rowKey={(record) => record.idx}
                      dataSource={this.state.list}
                      columns={columns}
                      pagination={this.state.pagination}
                      onChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

let mapStateToProps = (state) => ({
  branchInfo: state.branchReducer.branchInfo
});

export default connect(mapStateToProps)(SearchFranchiseToRiderDialog);
