import { DualAxes, Line } from '@ant-design/charts';
import { Button, DatePicker, Form, Input, Radio, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import moment from 'moment';
import 'moment/locale/ko';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../api/httpClient';
import SearchFranchiseDialog from '../../components/dialog/common/SearchFranchiseDialog';
import SearchRiderDialog from '../../components/dialog/common/SearchRiderDialog';
import '../../css/common.css';
import '../../css/staff.css';
import { comma } from '../../lib/util/numberUtil';
import FranchiseStat from './FranchiseStat';
import RiderStat from './RiderStat';

const Search = Input.Search;
const FormItem = Form.Item;
const today = new Date();
const { RangePicker } = DatePicker;

const STAT_CATEGORY = {
  TOTAL: '전체통계',
  FRANCHISE: '가맹점통계',
  RIDER: '기사통계',
};

class orderStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      chartList: [],
      listRider: [],
      chartListRider: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      paginationRider: {
        total: 0,
        current: 1,
        pageSize: 10,
      },

      searchType: 0,
      searchFranchiseOpen: false,
      searchRiderOpen: false,
      selectedFr: [],
      selectedRider: [],
      dateRange: 1,
      dateRangeRider: 1,
      startDate: moment(today).add(-1, 'M').format('YYYY-MM-DD'),
      endDate: moment(today).format('YYYY-MM-DD'),
      startDateRider: moment(today).add(-1, 'M').format('YYYY-MM-DD'),
      endDateRider: moment(today).format('YYYY-MM-DD'),

      statCategory: 'TOTAL',
    };
  }

  componentDidMount() {
    this.getList();
    this.getChartList();
    this.getRiderCallList();
    this.getRiderCallChartList();
  }

  onChange = (e) => {
    this.setState(
      {
        pagination: {
          current: 1,
          pageSize: 10,
        },
        dateRange: e.target.value,
      },
      () => {
        this.state.dateRange === 1
          ? this.setState(
            {
              startDate: moment(today).add(-1, 'M').format('YYYY-MM-DD'),
              endDate: moment(today).format('YYYY-MM-DD'),
            },
            () => {
              this.getList();
              this.getChartList();
            }
          )
          : this.state.dateRange === 2
            ? this.setState(
              {
                startDate: moment(today).add(-10, 'W').format('YYYY-MM-DD'),
                endDate: moment(today).format('YYYY-MM-DD'),
              },
              () => {
                this.getList();
                this.getChartList();
              }
            )
            : this.setState(
              {
                startDate: moment(today)
                  .add(-1, 'Y')
                  .format('YYYY-MM')
                  .concat('-01'),
                endDate: moment(today).format('YYYY-MM-DD'),
              },
              () => {
                this.getList();
                this.getChartList();
              }
            );

        // this.getList();
        // this.getChartList();
      }
    );
  };

  onChangeRider = (e) => {
    this.setState(
      {
        paginationRider: {
          current: 1,
          pageSize: 10,
        },
        dateRangeRider: e.target.value,
      },
      () => {
        this.state.dateRangeRider === 1
          ? this.setState(
            {
              startDateRider: moment(today).add(-1, 'M').format('YYYY-MM-DD'),
              endDateRider: moment(today).format('YYYY-MM-DD'),
            },
            () => {
              this.getRiderCallList();
              this.getRiderCallChartList();
            }
          )
          : this.state.dateRangeRider === 2
            ? this.setState(
              {
                startDateRider: moment(today)
                  .add(-10, 'W')
                  .format('YYYY-MM-DD'),
                endDateRider: moment(today).format('YYYY-MM-DD'),
              },
              () => {
                this.getRiderCallList();
                this.getRiderCallChartList();
              }
            )
            : this.setState(
              {
                startDateRider: moment(today)
                  .add(-1, 'Y')
                  .format('YYYY-MM')
                  .concat('-01'),
                endDateRider: moment(today).format('YYYY-MM-DD'),
              },
              () => {
                this.getRiderCallList();
                this.getRiderCallChartList();
              }
            );

        // this.getList();
        // this.getChartList();
      }
    );
  };

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  handleRiderTableChange = (pagination) => {
    const pagerRider = { ...this.state.paginationRider };
    pagerRider.current = pagination.current;
    pagerRider.pageSize = pagination.pageSize;
    this.setState(
      {
        paginationRider: pagerRider,
      },
      () => this.getRiderCallList()
    );
  };

  getList = () => {
    let pageSize = this.state.pagination.pageSize;
    let pageNum = this.state.pagination.current;
    let frIdx = this.state.selectedFr.idx;
    let dateRange = this.state.dateRange;
    let startDate = this.state.startDate;
    let endDate = moment(this.state.endDate).add(1, 'd').format('YYYY-MM-DD');
    httpGet(
      httpUrl.orderStats,
      [dateRange, frIdx, pageNum, pageSize, startDate, endDate],
      {}
    ).then((result) => {
      console.log('## nnbox result=' + JSON.stringify(result, null, 4));
      const pagination = { ...this.state.pagination };
      pagination.current = result.data.currentPage;
      pagination.total = result.data.totalCount;
      this.setState({
        list: result.data.calls,
        pagination,
      });
    });
  };

  getChartList = () => {
    let pageSize = 200;
    // let pageNum = this.state.pagination.current;
    let frIdx = this.state.selectedFr.idx;
    let dateRange = this.state.dateRange;
    let startDate = this.state.startDate;
    let endDate = moment(this.state.endDate).add(1, 'd').format('YYYY-MM-DD');
    httpGet(
      httpUrl.orderChartStats,
      [dateRange, frIdx, 1, pageSize, startDate, endDate],
      {}
    ).then((result) => {
      this.setState({
        chartList: result.data.calls,
      });
    });
  };

  getRiderCallList = () => {
    let pageSize = this.state.paginationRider.pageSize;
    let pageNum = this.state.paginationRider.current;
    let frIdx = this.state.selectedRider.idx;
    let dateRange = this.state.dateRangeRider;
    let startDate = this.state.startDateRider;
    let endDate = moment(this.state.endDateRider)
      .add(1, 'd')
      .format('YYYY-MM-DD');
    httpGet(
      httpUrl.orderRiderStats,
      [dateRange, frIdx, pageNum, pageSize, startDate, endDate],
      {}
    ).then((result) => {
      console.log('## nnbox result=' + JSON.stringify(result, null, 4));
      const paginationRider = { ...this.state.paginationRider };
      paginationRider.current = result.data.currentPage;
      paginationRider.total = result.data.totalCount;
      this.setState({
        listRider: result.data.calls,
        paginationRider,
      });
    });
  };

  getRiderCallChartList = () => {
    // let pageSize = this.state.paginationRider.pageSize;
    // let pageNum = this.state.paginationRider.current;
    let frIdx = this.state.selectedRider.idx;
    let dateRange = this.state.dateRangeRider;
    let startDate = this.state.startDateRider;
    let endDate = moment(this.state.endDateRider)
      .add(1, 'd')
      .format('YYYY-MM-DD');
    httpGet(
      httpUrl.orderRiderChartStats,
      [dateRange, frIdx, 1, 200, startDate, endDate],
      {}
    ).then((result) => {
      console.log('## nnbox result=' + JSON.stringify(result, null, 4));
      this.setState({
        chartListRider: result.data.calls,
      });
    });
  };

  onChangeDate = (dateString) => {
    this.setState(
      {
        startDate:
          dateString != null ? moment(dateString[0]).format('YYYY-MM-DD') : '',
        endDate:
          dateString != null ? moment(dateString[1]).format('YYYY-MM-DD') : '',
      },
      () => {
        this.getList();
        this.getChartList();
      }
    );
  };
  onChangeRiderDate = (dateString) => {
    this.setState(
      {
        startDateRider:
          dateString != null ? moment(dateString[0]).format('YYYY-MM-DD') : '',
        endDateRider:
          dateString != null ? moment(dateString[1]).format('YYYY-MM-DD') : '',
      },
      () => {
        this.getRiderCallList();
        this.getRiderCallChartList();
      }
    );
  };

  // 가맹점조회 dialog
  openSearchFranchiseModal = () => {
    this.setState({ searchFranchiseOpen: true });
  };
  closeSearchFranchiseModal = () => {
    this.setState(
      {
        searchFranchiseOpen: false,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
        this.getChartList();
      }
    );
  };

  // 기사조회 dialog
  openSearchRiderModal = () => {
    this.setState({ searchRiderOpen: true });
  };
  closeSearchRiderModal = () => {
    this.setState(
      {
        searchRiderOpen: false,
        paginationRider: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getRiderCallList();
        this.getRiderCallChartList();
      }
    );
  };

  render() {
    const columns = [
      {
        title: '날짜',
        dataIndex: 'createDate',
        className: 'table-column-center',
        width: '45%',
      },
      {
        title: '전체',
        dataIndex: 'totalCount',
        className: 'table-column-center',
        // width: "50px"
      },
      {
        title: '완료',
        dataIndex: 'completeCount',
        className: 'table-column-center',
        // width: "50px"
      },
      {
        title: '취소',
        dataIndex: 'cancelCount',
        className: 'table-column-center',
        // width: "50px"
      },
    ];

    const columnsRider = [
      {
        title: '날짜',
        dataIndex: 'createDate',
        className: 'table-column-center',
        width: '45%',
      },
      {
        title: '건수',
        dataIndex: 'count',
        className: 'table-column-center',
        // width: "50px"
      },
      {
        title: '금액',
        dataIndex: 'totalPrice',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
        // width: "50px"
      },
    ];

    const config = {
      data: this.state.chartList,
      xField: 'createDate',
      yField: 'count',
      seriesField: 'category',
    };

    const configRider = {
      data: [this.state.chartListRider, this.state.chartListRider],
      xField: 'createDate',
      yField: ['count', 'totalPrice'],
      meta: {
        count: {
          alias: '건수',
        },
        totalPrice: {
          alias: '총 배달비',
        },
      },
    };

    return (
      <div>
        <div className="selectLayout">
          <Radio.Group
            value={this.state.statCategory}
            onChange={(e) => this.setState({ statCategory: e.target.value })}
            style={{ marginLeft: 20, marginTop: 20 }}>
            {Object.keys(STAT_CATEGORY).map((key) => {
              return <Radio value={key}>{STAT_CATEGORY[key]}</Radio>;
            })}
          </Radio.Group>
        </div>
        {this.state.statCategory === 'TOTAL' && (
          <>
            <div className="orderStats">
              {this.state.searchFranchiseOpen && (
                <SearchFranchiseDialog
                  close={this.closeSearchFranchiseModal}
                  callback={(data) =>
                    this.setState({
                      selectedFr: data,
                    })
                  }
                />
              )}
              <div>
                <Input
                  placeholder="가맹점명 입력"
                  className="stats-input"
                  required
                  value={this.state.selectedFr.frName}
                  onClick={this.openSearchFranchiseModal}></Input>
                <Button onClick={this.openSearchFranchiseModal}>조회</Button>
                <Button
                  onClick={() =>
                    this.setState(
                      {
                        selectedFr: '',
                        pagination: {
                          current: 1,
                          pageSize: 10,
                        },
                      },
                      () => {
                        this.getList();
                        this.getChartList();
                      }
                    )
                  }
                  style={{ marginLeft: 6 }}>
                  전체
                </Button>
              </div>
              <div>
                <Radio.Group
                  defaultValue={1}
                  onChange={this.onChange}
                  checked={this.state.dateRange}
                  className="stats-radio">
                  <Radio value={1}>일별</Radio>
                  <Radio value={2}>주별</Radio>
                  <Radio value={3}>월별</Radio>
                </Radio.Group>

                <RangePicker
                  placeholder={['시작일', '종료일']}
                  onChange={this.onChangeDate}
                  className="stats-date"
                  locale={locale}
                  format={'YYYY-MM-DD'}
                  value={[
                    moment(this.state.startDate, 'YYYY-MM-DD'),
                    moment(this.state.endDate, 'YYYY-MM-DD'),
                  ]}
                />
              </div>
            </div>
            <Table
              className="stats-tables"
              dataSource={this.state.list}
              columns={columns}
              pagination={{
                ...this.state.pagination,
                position: ['bottomCenter'],
              }}
              onChange={this.handleTableChange}
              style={{ padding: 10 }}
            />
            <div className="stats-graph">
              <div className="stats-line">
                <Line {...config} />
              </div>
            </div>
            <div className="orderStats">
              {this.state.searchRiderOpen && (
                <SearchRiderDialog
                  close={this.closeSearchRiderModal}
                  callback={(data) =>
                    this.setState({
                      selectedRider: data,
                    })
                  }
                  isSelectBox={true}
                />
              )}
              <div>
                <Input
                  placeholder="기사명 입력"
                  className="stats-input"
                  required
                  value={this.state.selectedRider.riderName}
                  onClick={this.openSearchRiderModal}></Input>
                <Button onClick={this.openSearchRiderModal}>조회</Button>
                <Button
                  onClick={() =>
                    this.setState(
                      {
                        selectedRider: '',
                        paginationRider: {
                          current: 1,
                          pageSize: 10,
                        },
                      },
                      () => {
                        this.getRiderCallList();
                        this.getRiderCallChartList();
                      }
                    )
                  }
                  style={{ marginLeft: 6 }}>
                  전체
                </Button>
              </div>
              <div className="stats-date-box">
                <Radio.Group
                  defaultValue={1}
                  onChange={this.onChangeRider}
                  checked={this.state.dateRangeRider}
                  className="stats-radio">
                  <Radio value={1}>일별</Radio>
                  <Radio value={2}>주별</Radio>
                  <Radio value={3}>월별</Radio>
                </Radio.Group>

                <RangePicker
                  placeholder={['시작일', '종료일']}
                  onChange={this.onChangeRiderDate}
                  className="stats-date"
                  locale={locale}
                  format={'YYYY-MM-DD'}
                  value={[
                    moment(this.state.startDateRider, 'YYYY-MM-DD'),
                    moment(this.state.endDateRider, 'YYYY-MM-DD'),
                  ]}
                />
              </div>
            </div>
            <Table
              className="stats-tables"
              dataSource={this.state.listRider}
              columns={columnsRider}
              pagination={{
                ...this.state.paginationRider,
                position: ['bottomCenter'],
              }}
              onChange={this.handleRiderTableChange}
              style={{ padding: 10 }}
            />
            <div className="stats-graph">
              <div className="stats-line">
                <DualAxes {...configRider} />
              </div>
            </div>
          </>
        )}
        {this.state.statCategory === 'FRANCHISE' && <FranchiseStat />}
        {this.state.statCategory === 'RIDER' && <RiderStat />}
      </div>
    );
  }
}

export default orderStats;
