import {
  BankOutlined,
  CopyOutlined,
  PhoneOutlined,
  PieChartOutlined,
  SettingOutlined,
  SkinOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import React from 'react';
import NcashIcon from '../../img/header/ncash.png';

// 접수현황 - 상태
const deliveryStatusCode = ['', '접수', '배차', '픽업', '완료', '취소'];

// 접수현황 - 준비시간
const preparationStatus = ['준비중', '완료'];

// 접수현황 - 결재방식
const paymentMethod = ['', '카드', '현금', '선결'];

// 접수현황 - 결제 상태
const paymentStatus = ['', '요청', '완료', '취소', '에러', '삭제'];

const orderStatusDefs = [
  {
    value: 1,
    label: '접수',
    history: false,
  },
  {
    value: 2,
    label: '배차',
    history: false,
  },
  {
    value: 3,
    label: '픽업',
    history: false,
  },
  {
    value: 4,
    label: '완료',
    history: true,
  },
  {
    value: 5,
    label: '취소',
    history: true,
  },
];

const paymentMethodDefs = [
  {
    value: 1,
    label: '카드',
  },
  {
    value: 2,
    label: '현금',
  },
  {
    value: 3,
    label: '선결',
  },
];

const sortOrderCode = [
  {
    value: 1,
    label: '픽업시간순',
  },
  {
    value: 2,
    label: '거리순',
  },
  {
    value: 3,
    label: '주문시간순',
  },
];

const getOrderStatusValues = (history) => {
  let values = [];
  orderStatusDefs.forEach((x) => {
    if (history === undefined || x.history === history) {
      values.push(x.value);
    }
  });
  return values;
};
const getPaymentMethodValues = () => {
  let values = [];
  paymentMethodDefs.forEach((x) => {
    values.push(x.value);
  });
  return values;
};

// 접수현황, 가맹점관리 - 카드상태
const cardStatus = ['요청', '등록완료'];

// 접수현황 - 상태 변경 가능한 경우
// key : 기존 값 / value : 변경 가능한 값
const modifyType = {
  1: [2, 5],
  2: [1, 3, 5],
  3: [1, 2, 4, 5],
  4: [3],
  5: [1],
};

// 접수현황 - 상태 값에 따라 테이블 색 변경
const rowColorName = ['', 'table-red', 'table-blue', 'table-orange', 'table-lightyellow', 'table-gray'];

// 가맹점, 기사관리 - 상태
const statusString = {
  1: '사용',
  2: '중지',
  3: '탈퇴',
};

// 가맹점, 기사관리 - 상태 선택
const tableStatusString = {
  0: '전체',
  1: '사용',
  2: '중지',
  3: '탈퇴',
};

// 가맹점 접속 상태 구분
const franIsConnectedString = {
  0: '전체',
  1: '미접속',
  2: '접속중',
};

//일차감 구분
const kindString = [
  '전체',
  '렌트비(냠냠바이크)',
  '대출상환',
  '산재보험비',
  '수리비(냠냠바이크)',
  '경기바이크',
  '대여금 회수',
  '대출상환(아우라)',
  '렌트비(냠냠바이크경기)',
  '수리비(냠냠바이크경기)',
  '렌트비 회수',
  '수리비 회수',
  '단체상해보험',
  '포스구매',
  '기사수수료',
  '라이더그룹(일차감)',
  '대출상환(대양)',
  '과태료 회수',
  '냠냠몰비품구입',
  '가맹비',
  '기타',
  '음식보험 회수',
  '선충전 회수',
  '라이더간 회수',
];

const agencyBatchWorkKind = {
  0: '전체',
  11: '수리비 회수',
  10: '렌트비 회수',
  17: '과태료 회수',
  6: '대여금 회수',
  22: '선충전 회수',
  21: '음식보험 회수',
  19: '가맹비 회수',
  20: '기타',
  23: '라이더간 회수',
};

const agencyBatchWorkForRider = {
  11: '수리비 회수',
  10: '렌트비 회수',
  17: '과태료 회수',
  6: '대여금 회수',
  21: '음식보험 회수',
  20: '기타',
  23: '라이더간 회수',
};

const agencyBatchWorkForFr = {
  22: '선충전 회수',
  19: '가맹비 회수',
  20: '기타',
};

// const agencyBatchWorkKind = [
//   '전체',
//   '렌트비(냠냠바이크)',
//   '',
//   '',
//   '수리비(냠냠바이크)',
//   '',
//   '',
//   '',
//   '',
//   '',
//   '',
//   '',
//   '단체상해보험',
//   '',
//   '',
//   '',
//   '대출상환(대양)',
//   '바이크과태료',
//   '냠냠몰비품구입',
//   // 기존의 가맹비는 가맹비대로 걷고 일차감으로 걷는 가맹비가 따로 있다고 함.
//   '가맹비',
//   '기타',
// ];

//코인잔액 카테고리
const coinCategory = {
  NONE: '전체',
  BUY_PRODUCT: '몰 구매',
  BUY_PRODUCT_CANCEL: '몰 구매 취소',
  DELIVERY_PRICE_SEND: '배송비 전송',
  DELIVERY_PRICE_RECEIVE: '배송비 지급',
  DELIVERY_PRICE_RECEIVE_RESTORE: '배송비 지급 반환',
  NCASH_SEND: '예치금 전송',
  NCASH_SEND_BY_ADMIN: '관리자 예치금 추가/차감',
  NCASH_CHARGE_PROC: '충전',
  NCASH_SEND_BY_BIKE_REPAIR: '바이크 수리비',
  RIDER_ASSIGN_CANCEL: '배차 취소(페널티 차감)',
  WITHDRAW_REQ: '출금 요청',
  INCENTIVE_PAYED: '인센티브 지급',
  BATCH_WORK_NCASH: '일차감',
  BATCH_WORK_NCASH_MONTHLY: '월차감',
  CASH_ORDER_PRICE_SEND: '가맹점 전송',
  CASH_ORDER_PRICE_SEND_RESTORE: '가맹점 전송 복구',
  BATCH_WORK_NCASH_OF_RIDER_BIKE_RENT_FEE: '라이더 바이크 렌트비',
  HOURLY_INSURANCE: '시간제 보험료',

  // DELIVERY_PRICE_SEND_RESTORE: "배송비 보내기 복원",
  // NCASH_CHARGE_REQ:"충전 요청",
  // WITHDRAW_PROC: "출금 처리",
  // DUES_PAY: "가맹비 지급",
  // DUES_PAY_CANCEL: '가맹비 지급 취소',
};

// 가맹점 - 출금설정
const withdrawString = {
  true: '출금 가능',
  false: '출금 금지',
};

// 가맹점 - 가맹점 등록시 가맹비설정
const registFrfreeDues = {
  0: '미적용',
  1: '1개월',
  2: '2개월',
  3: '3개월',
  // 99: "무제한",
};

// 가맹점 - 가맹비예외 승인 신청

const freeDuesAutoPassive = {
  0: '자동',
  1: '수동',
};

const freeDuesApprovalStatus = {
  0: '승인대기',
  1: '승인완료',
  2: '승인반려',
};

const freeDuesString = {
  0: '미적용',
  1: '1개월',
  2: '2개월',
  3: '3개월',
  4: '4개월',
  5: '5개월',
  6: '6개월',
  7: '7개월',
  8: '8개월',
  9: '9개월',
  10: '10개월',
  11: '11개월',
  12: '12개월',
  99: '무제한',
};

// 유저 그룹
const userGroupString = {
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
  5: 'E',
};

// 수수료 방식
const feeManner = {
  1: '정량',
  2: '정률',
};

const importantNotice = {
  false: 'table-white',
  true: 'table-blue',
};

const orderCnt = {
  99: '전체',
  0: '0개',
  1: '1개',
  2: '2개',
  3: '3개',
  4: '4개',
  5: '5개 이상',
};

const blockString = {
  0: '처리대기',
  1: '차단중',
  2: '차단해제',
  3: '차단거부',
  4: '요청해제',
};

const blockStatusString = {
  2: '승인처리',
  3: '승인거부',
};

const frRiderString = {
  1: '기사',
  2: '가맹점',
};

const toggleCode = [0, 1];

const enabledString = {
  false: 'OFF',
  true: 'ON',
};

const enabledCode = [false, true];

const staffString = {
  1: '근무',
  2: '중지',
  3: '퇴사',
};

const riderGroupString = ['', 'A', 'B', 'C', 'D', 'E'];

const riderStatusCode = ['전체', '근무', '휴식', '퇴근'];

const riderLevelText = [
  '',
  '라이더',
  '팀장',
  '본부장',
  '부본부장',
  '수석본부장',
  '부지점장',
  '지점장',
  '부센터장',
  '센터장',
];

// const riderLevelText = ["", "라이더", "소속팀장", "본부장", "지점장", "센터장"];

const pgUseRate = {
  100: '미사용',
  0: '사용',
};

const arriveReqTime = {
  5: '5분',
  10: '10분',
  15: '15분',
  20: '20분',
  25: '25분',
  30: '30분',
  35: '35분',
  40: '40분',
  45: '45분',
  50: '50분',
  55: '55분',
  60: '60분',
  65: '65분',
  70: '70분',
  1005: '후5분',
  1010: '후10분',
  1015: '후15분',
  1020: '후20분',
  1025: '후25분',
};

const delayReqTime = {
  0: '지연해제',
  5: '지연 5분',
  10: '지연 10분',
  15: '지연 15분',
  20: '지연 20분',
  25: '지연 25분',
  30: '지연 30분',
  35: '지연 35분',
  40: '지연 40분',
  45: '지연 45분',
  50: '지연 50분',
  55: '지연 55분',
  60: '지연 60분',
  65: '지연 65분',
  70: '지연 70분',
  1005: '후5분',
  1010: '후10분',
  1015: '후15분',
  1020: '후20분',
  1025: '후25분',
};

const packAmount = {
  1: '1개',
  2: '2개',
  3: '3개',
  4: '4개',
  5: '5개이상',
};

const surchargeType = ['지점 전체', '지정 그룹'];
const surchargeTypeAgency = ['지사 전체', '지정 그룹'];

const addType = ['', '아파트', '오피스텔', '특정건물'];

const bikeType = ['렌트', '지입', '자전거'];

const riderType = ['전업 기사', '프리 기사'];

// 바이크 조회 모달
const searchBike = {
  0: '전체',
  1: '번호',
  2: '모델명',
  3: '제조사',
};

const deliveryPriceFeeType = ['정률', '정액', '일차감'];

// 상품관리 - 상태
const buyStatusString = {
  0: '',
  1: '수령대기',
  2: '수령완료',
  3: '환불요청',
  4: '환불완료',
};

// 공지사항 카테고리
const noticeCategoryType = {
  1: '전체',
  2: '라이더',
  3: '가맹점',
};

const chattingCategoryType = {
  1: '전체',
  2: '라이더',
  3: '가맹점',
};

const chattingSubCategoryType = {
  1: '전체',
  2: '그룹전송',
};

const bankCode = {
  경남은행: '039',
  광주은행: '034',
  국민은행: '004',
  기업은행: '003',
  농협: '011',
  단위농협: '012',
  대구은행: '031',
  부산은행: '032',
  산림조합: '064',
  산업은행: '002',
  상호저죽: '050',
  새마을금고: '045',
  수협: '007',
  신한은행: '088',
  신협: '048',
  우리은행: '020',
  우체국: '071',
  전북은행: '037',
  제주은행: '035',
  KEB하나은행: '081',
  한국시티은행: '027',
  SC제일은행: '023',
  K뱅크: '089',
  카카오뱅크: '090',
};

const items = ['헬멧', '조끼', '배달통', '보냉', '우의', '피자가방', '여름티', '토시', '바람막이', '카드리더기'];

// const deliveryZone ={
//   0: "감정동",
//   1: "걸포동",
//   2: "고촌읍",
//   3: "구래동",
//   4: "대곶면",
//   5: "마산동",
//   6: "북변동",
//   7: "사우동",
//   8: "양촌읍",
//   9: "운양동",
//   10: "월곶면",
//   11: "장기동",
//   12: "통진읍",
//   13: "풍무동",
//   14: "하성면",
// };

const deliveryZone = [
  {
    code: 0,
    text: '감정동',
    toggle: false,
    coords: [],
  },
  {
    code: 1,
    text: '걸포동',
    toggle: false,
  },
  {
    code: 2,
    text: '고촌읍',
    toggle: false,
  },
  {
    code: 3,
    text: '구래동',
    toggle: false,
  },
  {
    code: 4,
    text: '대곶면',
    toggle: false,
  },
  {
    code: 5,
    text: '마산동',
    toggle: false,
  },
  {
    code: 6,
    text: '북변동',
    toggle: false,
  },

  {
    code: 7,
    text: '사우동',
    toggle: false,
  },

  {
    code: 8,
    text: '양촌읍',
    toggle: false,
  },

  {
    code: 9,
    text: '운양동',
    toggle: false,
  },

  {
    code: 10,
    text: '월곶면',
    toggle: false,
  },
];
const contarctType = {
  1: '기존 사업자',
  0: '신규 사업자',
};

const phoneAgency = {
  0: 'SKT',
  1: 'KT',
  2: 'LG U+',
  3: 'SKT(알뜰폰)',
  4: 'KT(알뜰폰)',
  5: 'LG U+(알뜰폰)',
};

const apiResponseResult = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};

const dosiguCode = [
  '경기도',
  '경기',
  '고양시',
  '김포시',
  '덕양구',
  '일산동구',
  '일산서구',
  '서울특별시',
  '서울시',
  '서울',
];

const extListCode = ['푸드테크', '바나나포스', '배달천재', '포스피드', '배달특급'];

const dosiguCodeReplace = (addrData) => {
  if (!addrData) return '';
  dosiguCode.forEach((data) => {
    addrData = addrData.replace(data, '');
  });
  return addrData.trim();
};

const frOrderAgencyCode = {
  배달천재: 'dgCode',
  바나나포스: 'bpCode',
  포스피드: 'psCode',
  푸드테크: 'ftCode',
  KFC: 'kfc',
  CJ: 'cjo',
  아이노포스: 'ain',
  지니포스: 'gen',
  스파이더: 'spd',
  // 배달의민족: 'bmn',

  // 05-25 운영배포
  코나아이: 'kon',
  // 05-26 운영배포
  롯데리아: 'lte',
  spc: 'spc',
  먹깨비: 'mkb',
  오케이포스: 'okp',
  '본아이에프(본죽)': 'bif',
  //  비욘드: 'byd',
  // '비욘드(수기)': 'bydbt',

  콜신저: 'csg',
  마이벨: 'bel',
  솔비포스: 'sol',
  선택안함: '',
};

const frOrderAgencyPosList = {
  배달천재: '',
  바나나포스: '',
  포스피드: '',
  푸드테크: '푸드테크, 메이트포스',
  KFC: '',
  CJ: '',
  아이노포스: '',
  지니포스: '',
  스파이더:
    'OKPOS, POSBANK, 메타포스, SMARTRO, 에어포스, FUSEPOS, 대진포스, 피엔씨월드, 센시콘, 팔리다포스, 배달특급, 땡겨요, 솔비포스',

  // 05-25 운영배포
  // 코나아이: "kon",
  // 05-26 운영배포
  // 롯데리아: "lte",
  // 5월중 운영배포예정
  // 블루비: "spc",

  선택안함: '',
};

const voiceUpdate = [
  {
    value: '1',
    label: '접수',
  },
  {
    value: '2',
    label: '배차',
  },
  {
    value: '3',
    label: '픽업',
  },
  {
    value: '4',
    label: '취소',
  },
];

const batchCategory = ['', '일차감', '월차감'];

const dwServiceTypeCode = {
  KWANGWON: 1,
  PAYSTORY: 2,
};

const mapPaystoryVacntResultCd = {
  ALREADY_MAPPED: '이미 가상계좌가 발급된 계정입니다.',
  INSUFFICIENT_VACNT: '발급가능한 가상계좌가 없습니다. 관리자에게 문의하세요.',
  USER_NOT_FOUND: '잘못된 유저정보입니다.',
  USER_TYPE_ERROR: '잘못된 유저타입입니다.',
  USER_UPDATE_ERROR: '계정 정보 갱신에 실패했습니다. 관리자에게 문의하세요.',
  PV_UPDATE_ERROR: '가상계좌 정보 갱신에 실패했습니다. 관리자에게 문의하세요.',
  VACNT_LIMIT_SETTING_ERROR: '가상계좌 한도 설정에 실패했습니다. 관리자에게 문의하세요.',
  FR_NAME_EXTRA_NOT_VALID: '실가맹점명이 잘못되었습니다. 실가맹점 인증 후 가상계좌를 발급해주세요.',
  SET_DEPOSITOR_ERROR: '가상계좌 예금주 설정에 실패했습니다. 관리자에게 문의하세요.',
  SUCCESS: '가상계좌 발급에 성공했습니다.',
};

const riderPaperNoCode = {
  INVALID: -1,
  CONSIGNMENT_DELIVERY: 18, // 18 위수탁 계약서
  INFO_PROTECTION: 19, // 19 개인정보 보호 서약서
  PERSONAL_INFO: 20, // 20 개인정보제공동의서
  SERIOUS_ACCIDENT: 21, // 21 중대재해서약서
  DRIVERS_LICENSE: 26, // 26 운전면허증

  RENTAL_AGREEMENT: 22, // 22 렌트확약서(렌트만)
  RENTAL_LEASE_CONTRACT: 23, // 23 바이크임대계약서(렌트만)
  RENTAL_SAFE_CERTIFICATE: 27, // 27 안전보건공단수료증(렌트만)

  OWNED_PROMISE_VEHICLE: 24, // 24 지입 확인서(지입만)
  OWNED_BIKE_NUMBER: 25, // 25 지입바이크 차량번호(지입만)
  OWNED_INSURANCE_POLICY: 28, // 28 지입기사보험증서(지입만)
};

const rentOwnedPaperCodeList = [
  [],
  [18, 19, 20, 21, 26, 22, 23, 27],
  [18, 19, 20, 21, 26, 24, 25, 27, 28],
  [18, 19, 20, 21, 24, 27],
];

const riderPaperNoCodeToText = {
  18: '위수탁 계약서',
  19: '개인정보 보호 서약서',
  20: '개인정보제공동의서',
  21: '중대재해서약서',
  26: '운전면허증',

  // 렌트
  22: '렌트 계약서',
  23: '렌트 개인정보 제공 동의서',
  27: '안전보건공단수료증',

  // 지입
  24: '지입기사 확약서',
  25: '지입바이크 차량번호',
  28: '지입기사보험증서',
};

const riderPaperStatusCode = {
  NO_SUBMIT: 1, // 미제출
  SUBMIT: 2, // 제출
  APPROVED: 3, // 승인
  REJECTED: 4, // 반려
};

const riderPaperStatusToResultText = ['', '미제출', '제출', '승인', '반려'];

const riderPaperUploadErrorCode = {
  RIDER_PAPER_UPLOAD_FAILED: '업로드실패',
  RIDER_PAPER_UPLOAD_FAILED_INVALID_PAPER_NO: '잘못된 서류 유형입니다.',
  RIDER_PAPER_UPLOAD_FAILED_INVALID_FILE: '잘못된 파일입니다.',
  RIDER_PAPER_UPLOAD_FAILED_INVALID_RENT_PAPER: '잘못된 렌트 형식 서류입니다.',
  RIDER_PAPER_UPLOAD_FAILED_INVALID_OWNED_PAPER: '잘못된 지입 형식 서류입니다.',
  RIDER_PAPER_UPLOAD_FAILED_INVALID_RENT_OWNED_CODE: '잘못된 렌트/지입 코드입니다.',
  RIDER_PAPER_UPLOAD_FAILED_INVALID_ETAG: '잘못된 파일입니다.',
  RIDER_PAPER_UPLOAD_DUPLICATE_ETAG: '중복된 파일입니다.',
  RIDER_PAPER_UPLOAD_FAILED_INSERT: '업로드 파일 데이터 베이스 오류입니다.',
};

const bikeRentOwnedCode = {
  INVALID: -1,
  RENT: 1,
  OWNED: 2,
};

const HttpResponseStatusCode = {
  OK: 200,
  BAD_REQUEST: 400,
  INTERVAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
};

const riderApprovedCode = {
  INVALID: -1,
  NOT_APPROVED: 1,
  APPROVED: 2,
};

const frDuesType = ['자동', '수동'];

// 가입/탈퇴 통계 타입
const joinExitStaticsType = {
  1: '총원',
  2: '신규',
  3: '탈퇴',
  4: '중지',
  5: '복귀',
  6: '사고',
};

const redShowSetting = [
  {
    value: false,
    label: 'OFF',
  },
  {
    value: true,
    label: 'ON',
  },
];

const addrUpdateEnabled = [
  {
    value: 0,
    label: 'OFF',
  },
  {
    value: 1,
    label: 'ON',
  },
];

const distShowSetting = [
  {
    value: 0,
    label: 'OFF',
  },
  {
    value: 1,
    label: 'ON',
  },
];

const accidentInsuranceString = {
  0: '전체',
  1: '가입요청',
  2: '해지요청',
};

const categoryStatus = {
  NONE: '전체',
  BATCH_WORK_NCASH: '일차감',
  BATCH_WORK_NCASH_MONTHLY: '월차감',
  BUY_PRODUCT: '몰 상품 구매',
  BUY_PRODUCT_CANCEL: '몰 상품 구매 취소',
  CASH_ORDER_PRICE_SEND: '현금결제분 전송',
  CASH_ORDER_PRICE_SEND_RESTORE: '현금결제분 전송 복구',
  CASH_ORDER_PRICE_RECEIVE: '현금결제분 수령',
  CASH_ORDER_PRICE_RECEIVE_RESTORE: '현금결제분 수령 복구',
  DELIVERY_PRICE_RECEIVE: '배송비 받기',
  DELIVERY_PRICE_SEND: '배송비 보내기',
  DELIVERY_PRICE_SEND_RESTORE: '배송비 보내기 복원',
  DELIVERY_PRICE_RECEIVE_RESTORE: '배송비 받기 복원',
  DUES_PAY: '가맹비 지급',
  DUES_PAY_RESTORE: '가맹비 반환',
  NCASH_CHARGE_REQ: '충전',
  NCASH_CHARGE_PROC: '충전 처리',
  NCASH_SEND: '예치금 전송',
  NCASH_SEND_BY_ADMIN: '관리자 예치금 추가/차감',
  WITHDRAW_PROC: '출금 처리',
  WITHDRAW_REQ: '출금 요청',
  INCENTIVE_PAYED: '인센티브 지급',
  RIDER_ASSIGN_CANCEL: '배취 페널티',
  RIDER_LEVEL_UP_GIVE_NCASH: '레벨업 축하 지원금',
  RIDER_LEVEL_UP_GIVE_NCASH_SEND: '냠냠박스 예치금 차감(레벨업 축하 지원금)',
  RIDER_LEVEL_UP_GIVE_NCASH_TAX: '냠냠박스 예치금 세금 추가(레벨업 축하 지원금)',
  BATCH_WORK_NCASH_OF_RIDER_BIKE_RENT_FEE: '라이더 바이크 렌트비',
  AGENCY_FEE: '프로그램 사용료',
  AGENCY_FEE_RESTORE: '프로그램 사용료 복원',
  DELIVERY_PRICE_PENALTY: '페널티 처리(페널티콜 취소)',
  DELIVERY_SUBSIDY_SEND: '배달지원금',
  DELIVERY_SUBSIDY_RECEIVE: '배달지원금',
  DELIVERY_SUBSIDY_SEND_RESTORE: '배달지원금 복원',
  DELIVERY_SUBSIDY_RECEIVE_RESTORE: '배달지원금 복원',
};

const adminSendKindString = [
  '기타',
  '선충전 지급',
  '음식배상',
  '코인이관',
  '프로모션 지급',
  '산재보험비',
  '대출상환',
  '대여금 지급',
  '대출상환(아우라)',
  '수리비(냠냠바이크)',
  '수리비(냠냠바이크경기)',
  '수리비 납부',
  '렌트비(냠냠바이크)',
  '렌트비(냠냠바이크경기)',
  '렌트비 회수',
  '경기바이크',
  '단체상해보험',
  '포스구매',
  '냠냠몰구매',
  '기사수수료',
  '라이더그룹(일차감)',
  '선충전(B2B)',
  '대출상환(대양)',
  '이벤트 지급',
  '과태료 납부',
];

// const adminSendKindStringForAgencyService = ['기타', '', '', '예치금이관', '프로모션'];
const adminSendKindStringForAgencyService = {
  11: '수리비 납부',
  14: '렌트비 납부',
  24: '과태료 납부',
  7: '대여금 지급',
  0: '기타 비용',
};
const adminSendKindStringForAgencyServiceFranchise = {
  1: '선충전 지급',
  4: '프로모션 지급',
  2: '음식배상',
  0: '기타 비용',
};
// const adminSendKindStringForAgencyServiceFranchise = ['기타', '선충전', '음식배상', '', '프로모션'];

const searchType = ['라이더', '가맹점'];

const mainMenus = [
  {
    idx: 1,
    name: '접수현황',
    icon: <CopyOutlined />,
    url: '/order/OrderMain',
  },
  {
    idx: 2,
    name: '가입/탈퇴 통계',
    icon: <PieChartOutlined />,
    url: '/joinexit/JoinExitStats',
  },
  {
    idx: 3,
    name: '주문통계',
    icon: <PieChartOutlined />,
    url: '/order/OrderStats',
  },

  {
    idx: 4,
    name: '가맹점관리',
    icon: <PhoneOutlined />,
    url: '/franchise/FranchiseMain',
    className: 'desk',
  },
  {
    idx: 5,
    name: '기사관리',
    icon: <TeamOutlined />,
    url: '/rider/RiderMain',
    className: 'desk',
  },
  {
    idx: 6,
    name: '냠냠몰',
    icon: <SkinOutlined />,
    url: '/mall/MallMain',
    className: 'desk',
  },
  {
    idx: 7,
    name: '예치금관리',
    icon: <img src={NcashIcon} style={{ width: 18, marginBottom: 5 }} />,
    url: '/ncash',
    className: 'desk',
  },
  {
    idx: 9,
    name: '계정관리',
    icon: <TeamOutlined />,
    url: '/account/AccountMain',
    className: 'desk',
  },
  {
    idx: 12,
    name: '세금신고',
    icon: <BankOutlined />,
    url: '/ncash/AgencyTaxReturn',
    className: 'desk',
  },
  {
    idx: 10,
    name: '환경설정',
    icon: <SettingOutlined />,
    url: '/setting/SettingMain',
    className: 'desk',
  },
];

const statusCode = {
  0: '전체',
  1: '사용',
  2: '중지',
  3: '탈퇴',
};

const userTypeString = {
  0: '전체',
  1: '라이더',
  2: '가맹점',
};

const paystoryTransferResultMsg = {
  BANK_ACCOUNT_NOT_EXIST: '출금계좌 정보가 존재하지 않습니다.',
  NOT_ENOUGH_NCASH: '예치금 잔액이 부족합니다.',
  NOT_ENOUGH_CONNECT_CASH: 'pg잔액이 부족합니다. 관리자에게 문의하세요.',
  FUND_REMIT_ERR: '자금이체 요청 오류입니다. 관리자에게 문의하세요.',
  FUND_REMIT_AMT_ERR: '자금이체 요청금액 오류입니다. 관리자에게 문의하세요.',
  FUND_REMIT_ACNT_ERR: '자금이체 요청계좌 오류입니다, 관리자에게 문의하세요.',
  FUND_REMIT_RST_ERR: '자금이체 결과조회 오류입니다. 관리자에게 문의하세요.',

};

const category2FrStatus = {
  ALL: '전체',
  DUES: '가맹비',
  ETC: '기타',
};

const category2RiderStatus = {
  ALL: '전체',
  RIDER_FEE: '기사수수료',
  NCASH_WITHHOLDING_TAX: '원천징수 3.3%',
  RECOVER_LOAN: '대출상환(대양)',
  ACCIDENT_INSURANCE: '상해보험',
  BIKE_RENT: '바이크렌트비',
  BIKE_REPAIR_COST: '바이크수리비',
  BIKE_ROUTINE_INSPECTION: '바이크정기점검',
  RIDER_OWNED_BIKE_REPAIR_COST: '지입차수리비',
  BIKE_FINE: '바이크과태료',
  ETC: '기타',
};

const category2AllStatus = {
  ALL: '전체',
  DUES: '가맹비',
  RIDER_FEE: '기사수수료',
  NCASH_WITHHOLDING_TAX: '원천징수 3.3%',
  RECOVER_LOAN: '대출상환(대양)',
  ACCIDENT_INSURANCE: '상해보험',
  BIKE_RENT: '바이크렌트비',
  BIKE_REPAIR_COST: '바이크수리비',
  BIKE_ROUTINE_INSPECTION: '바이크정기점검',
  RIDER_OWNED_BIKE_REPAIR_COST: '지입차수리비',
  BIKE_FINE: '바이크과태료',
  ETC: '기타',
  CREATE_MISU: '미수금 발생',
};

const DistType = ['지점설정', '직선', '내비'];

const vanFileUploadErrorCode = {
  VAN_FILE_UPLOAD_DUPLICATE_USER: '등록된 이미지가 있습니다.',
  VAN_FILE_UPLOAD_DUPLICATE_USER_IN_VAN_CREATE_REQ: '등록된 요청이 있습니다.',
  VAN_FILE_UPLOAD_FAILED_INVAILID_ETAG: '잘못된 파일입니다.',
  VAN_FILE_UPLOAD_DUPLICATE_ETAG: '중복된 파일입니다.',
  VAN_FILE_UPLOAD_FAILED_INSERT: '업로드 파일 데이터 베이스 오류입니다.',
};

const foreignerCountryCode = {
  0: '미상',
  100: '한국',
  101: '아프가니스탄',
  104: '바레인',
  105: '방글라데시',
  106: '부탄',
  107: '브루나이',
  108: '미얀마',
  109: '명령인도양섬',
  110: '캄보디아',
  111: '스리랑카',
  112: '중국',
  113: '타이완',
  114: '키프로스',
  118: '북한',
  120: '홍콩',
  121: '홍콩거주난민',
  122: '한국계중국인',
  124: '인도',
  125: '인도네시아',
  126: '이란',
  127: '이라크',
  128: '이스라엘',
  130: '일본',
  131: '요르단',
  133: '카자흐스탄',
  134: '키르기스스탄',
  135: '쿠웨이트',
  138: '라오스',
  139: '레바논',
  142: '마카오',
  143: '말레이시아',
  144: '몰디브',
  145: '몽골',
  146: '마요트',
  148: '네팔',
  150: '오만',
  153: '파키스탄',
  154: '팔레스타인',
  155: '필리틴',
  156: '티모르',
  159: '카타르',
  162: '사우디아라비아',
  163: '시킴왕국',
  164: '싱가포르',
  165: '시리아',
  169: '타지키스탄',
  170: '타이완',
  171: '터키',
  172: '투르크메니스탄',
  180: '아랍에미리트연합',
  181: '우즈베키스탄',
  185: '베트남',
  191: '예멘공화국',
  192: '예멘인민민주공화국',
  200: '앵귈라',
  201: '앤티가바부다',
  202: '아르헨티나',
  203: '아루바',
  205: '바하마',
  206: '바베이도스',
  207: '벨리즈',
  208: '볼리비아',
  209: '브라질',
  210: '버뮤다',
  211: '부베트',
  212: '케이맨제도',
  213: '캐나다',
  214: '칠레',
  215: '콜롬비아',
  216: '코스타리카',
  217: '쿠바',
  220: '도미니카연방',
  221: '도미니카공화국',
  223: '이스터제도',
  224: '에콰도르',
  225: '엘살바도로',
  226: '포클랜드',
  227: '불령가이아나',
  229: '그레나다',
  230: '과들루프',
  231: '과테말라',
  232: '가이아나',
  235: '아이티',
  236: '온두라스',
  240: '자메이카',
  247: '마르티니크',
  248: '멕시코',
  249: '몬서래트',
  251: '네덜란드령앤틸리스',
  252: '니카라과',
  255: '파나마',
  256: '파라과이',
  257: '페루',
  258: '푸에르토리코',
  260: '남조지아남샌드위치군도',
  261: '상피에르이클롱',
  262: '세인트크리스토퍼네비스',
  263: '세인트루시아',
  264: '세인트빈센트그레나딘',
  265: '수리남',
  268: '트리니다드토바고',
  269: '터크스케이커스',
  274: '우루과이',
  275: '미국',
  276: '미국인근섬',
  280: '베네수엘라',
  281: '미령버진아일랜드',
  282: '영령버진아일랜드',
  301: '알바니아',
  302: '안도라',
  303: '오스트리아',
  304: '아르메니아',
  305: '아제르바이잔',
  306: '벨기에',
  307: '불가리아',
  308: '벨로루시',
  309: '보스니아-헤르체고비나',
  310: '체코',
  311: '페로섬',
  312: '에스토니아',
  313: '덴마크',
  314: '영국보호민',
  315: '영국속국민',
  316: '영국',
  317: '영국속령지시민',
  318: '영국외지민',
  319: '영국외지시민',
  320: '핀란드',
  321: '프랑스',
  322: '한국계러시아인',
  323: '그루지아',
  324: '독일',
  325: '동독',
  326: '그리스',
  327: '지브롤터',
  328: '그린란드',
  329: '헝가리',
  333: '아이슬란드',
  334: '아일랜드',
  335: '이탈리아',
  337: '코소보',
  339: '라트비아',
  340: '리히텐슈타인',
  341: '룩셈부르크',
  342: '리투아니아',
  343: '마케도니아',
  344: '몰타',
  345: '모나코',
  346: '몰도바',
  347: '몬테네그로',
  350: '네덜란드',
  352: '노르웨이',
  360: '폴란드',
  361: '포르투갈',
  365: '루마니아',
  366: '러시아(연방)',
  367: '세르비아',
  368: '슬로바크',
  370: '슬로베니아',
  371: '산마리노',
  372: '스페인',
  373: '스웨덴',
  374: '스위스',
  375: '스발바르',
  378: '우크라이나',
  380: '독립국가연합',
  390: '바티칸',
  391: '크로아티아',
  395: '유고슬라비아',
  396: '세르비아몬테네그로',
  404: '오스트레일리아',
  411: '캐롤라인군도',
  412: '쿡아일랜드',
  413: '크리스마스',
  414: '코코스',
  418: '피지',
  419: '불령폴리네시아',
  420: '불령남태평양섬',
  423: '괌',
  425: '허드맥도날드',
  429: '키리바시',
  434: '북마리아나군도',
  435: '미크로네시아',
  436: '마라아나군도',
  437: '마살군도',
  438: '미드웨이',
  441: '나우루',
  443: '뉴칼레도니아',
  446: '뉴질랜드',
  447: '나우에',
  448: '노퍽',
  451: '팔라우',
  452: '파푸아뉴기니',
  454: '핏캐른',
  461: '사모아',
  462: '미령사모아',
  463: '솔로몬군도',
  464: '호주령솔로몬군도',
  473: '통가',
  474: '토켈라우',
  475: '투발루',
  485: '비누아투',
  490: '웨이크아일랜드',
  491: '윌리스푸투나',
  502: '알제리',
  503: '앙골라',
  506: '보츠와나',
  507: '부룬디',
  510: '카메룬',
  511: '카나리아군도',
  512: '카보베르데',
  513: '중앙아프리카공화국',
  514: '차드',
  515: '코모로',
  516: '콩고',
  517: '콩고민주공화국',
  520: '베냉',
  521: '지부티',
  525: '이집트',
  526: '적도기니',
  527: '에티오피아',
  528: '에리트레아',
  530: '가봉',
  531: '감비아',
  532: '가나',
  533: '기니',
  534: '기니비사우',
  537: '코트디부아르',
  540: '케냐',
  542: '레소토',
  543: '라이베리아',
  544: '리비아',
  550: '마다가스카르',
  551: '말라위',
  552: '말리',
  553: '모리타니',
  554: '모리셔스',
  555: '모로코',
  556: '모잠비크',
  560: '나미비아',
  561: '니제르',
  562: '나이지리아',
  564: '레위니옹',
  565: '짐바브웨',
  566: '르완다',
  571: '상투메프린시페',
  572: '세네갈',
  573: '세이셸',
  574: '시에라리온',
  575: '소말리아',
  576: '남아프리카공화국',
  577: '세인트헬레나',
  578: '수단',
  579: '스와칠란드',
  583: '탄자니아',
  584: '토고',
  585: '튀니지',
  588: '우간다',
  589: '부르키나파소',
  591: '서사하라',
  594: '자이르',
  595: '밤비아',
  600: '무국적',
  620: '남극대륙',
  666: '공해',
  702: '이중국적',
  703: '한국(재외국민)',
  704: '난민',
  900: '국제연합',
  901: 'UN전문기구 또는 산하기구의 종사자',
  911: '안티구아',
  996: '난민(난민의 지위에 대한 협약에서 정의된 난민 외 난민 중 국적불명자)',
  997: '난민(난만의 지위에 대한 협약에 정의된 난민 중 국적불명자)',
  999: '기타(국적불명자)',
};

const foreignerStatusCode = {
  '0-0': '체류자격없음',
  'A-1': '외교',
  'A-2': '공무',
  'A-3-1': '미군현역',
  'A-3-2': '미군군속',
  'A-3-99': '기타협정',
  'B-1': '사증면제',
  'B-2-1': '일반무사증',
  'B-2-2': '제주무사증',
  'C-1': '일시취재',
  'C-2-1': '단기상용',
  'C-2-2': '우대기업',
  'C-2-91': 'FTA상용',
  'C-3': '단기종합',
  'C-3-1': '단기일반',
  'C-3-2': '단체관광등',
  'C-3-3': '의료관광',
  'C-3-4': '일반상용',
  'C-3-5': '협정단기상용',
  'C-3-6': '단기상용',
  'C-3-7': '도착관광',
  'C-3-8': '동포방문',
  'C-3-9': '일반관광',
  'C-3-M': '의료관광',
  'C-4': '단기취업',
  'D-1': '문화예술',
  'D-10': '구직',
  'D-10-1': '구직활동',
  'D-10-2': '기술창업활동',
  'D-2': '유학',
  'D-2-1': '전문학사',
  'D-2-2': '학사유학',
  'D-2-3': '석사유학',
  'D-2-4': '박사유학',
  'D-2-5': '연구유학',
  'D-2-6': '교환학생',
  'D-2-F': '교환학생',
  'D-2-S': '시간취업',
  'D-3-1': '해투기술연수',
  'D-3-B': '중기협',
  'D-3-C': '수산협',
  'D-3-D': '각부처',
  'D-3-E': '건설협',
  'D-3-F': '농협',
  'D-3-11': '해외직접',
  'D-3-12': '기술수출',
  'D-3-13': '플랜트수출',
  'D-4': '일반연수',
  'D-4-1': '대학부설어학원연수',
  'D-4-2': '기타기관연수',
  'D-4-3': '초중고생',
  'D-4-4': '동포연수',
  'D-4-5': '한식조리연수',
  'D-4-6': '사설기관연수',
  'D-4-7': '외국어연수',
  'D-5': '취재',
  'D-6': '종교',
  'D-7': '상사주재',
  'D-7-1': '외국기업',
  'D-7-2': '내국기업',
  'D-7-91': 'FTA전근',
  'D-7-92': 'FTA계약',
  'D-8': '기업투자',
  'D-8-1': '법인에투자',
  'D-8-2': '벤처투자',
  'D-8-3': '개인기업투자',
  'D-8-4': '기술창업',
  'D-8-91': 'FTA전근',
  'D-9': '무역경영',
  'D-9-1': '무역고유거래',
  'D-9-2': '수출설비',
  'D-9-3': '선박설비',
  'D-9-4': '경영영리사업',
  'E-1': '교수',
  'E-10-1': '내항선원',
  'E-10-2': '어선원',
  'E-10-3': '순항선원',
  'E-2-1': '일반회화강사',
  'E-2-2': '학교보조교사',
  'E-2-91': 'FTA영어',
  'E-3': '연구',
  'E-4': '기술지도',
  'E-5': '전문직업',
  'E-6': '예술흥행',
  'E-6-1': '예술연예',
  'E-6-2': '호텔유흥',
  'E-6-3': '운동',
  'E-7-1': '특정활동',
  'E-7-2': '의료코디',
  'E-7-3': '해삼양식',
  'E-7-91': 'FTA독립',
  'E-9-1': '제조업',
  'E-9-2': '건설업',
  'E-9-3': '농업',
  'E-9-4': '어업',
  'E-9-5': '서비스업',
  'E-9-6': '재료수집',
  'E-9-7': '관광호텔',
  'E-9-8': '축산업',
  'E-9-A': '음식업',
  'E-9-B': '청소업',
  'E-9-C': '간병가사',
  'E-9-D': '건설업',
  'E-9-E': '자차수리',
  'E-9-F': '제조업',
  'E-9-G': '농축산업',
  'E-9-H': '연근해업',
  'E-9-I': '욕탕업',
  'E-9-J': '재료수집',
  'E-9-K': '냉장냉동',
  'E-9-L': '비전문취업',
  'E-9-95': '과거추천연수',
  'E-9-96': '과거연수취업',
  'E-9-97': '과거특례고용',
  'E-9-98': '과거합법조치',
  'F-1': '방문동거',
  'F-1-1': '방문동거',
  'F-1-2': '가사보조',
  'F-1-3': '외교동거',
  'F-1-5': '결혼이민가족',
  'F-1-6': '결혼가사정리',
  'F-1-7': '국적신청',
  'F-1-8': '합법출생자녀',
  'F-1-9': '동포배우자등',
  'F-1-10': '동포고령',
  'F-1-11': '방문취업자녀',
  'F-1-12': '거주배우자',
  'F-1-13': '유학생부모',
  'F-1-14': '입양외국인',
  'F-1-21': '외교가사보조',
  'F-1-22': '고액가사보조',
  'F-1-23': '첨단가사보조',
  'F-1-24': '전문가사보조',
  'F-1-71': '국적신청가족',
  'F-1-72': '영주신청가족',
  'F-1-99': '기타동거',
  'F-2': '거주',
  'F-2-1': '국민배우자',
  'F-2-2': '국민자녀',
  'F-2-3': '영주자가족',
  'F-2-4': '난민',
  'F-2-5': '고액투자',
  'F-2-6': '숙련기능',
  'F-2-7': '점수우수인력',
  'F-2-8': '부동산투자',
  'F-2-9': '영주상실',
  'F-2-10': '자녀양육',
  'F-2-11': '공무임용',
  'F-2-12': '공익사업투자',
  'F-2-13': '공익은퇴가족',
  'F-2-14': '은퇴이민투자',
  'F-2-71': '점수가족',
  'F-2-81': '부동가족',
  'F-2-99': '기타장기',
  'F-3-1': '동반',
  'F-3-91': 'FTA동반',
  'F-4-1': '기여동포',
  'F-4-2': '일반동포',
  'F-4-11': '재외동포본인',
  'F-4-12': '재외동포직계가족',
  'F-4-13': 'DE계열6개월이상체류자',
  'F-4-14': '대학졸업자',
  'F-4-15': 'OECD영주자',
  'F-4-16': '법인대표등',
  'F-4-17': '10만불기업가',
  'F-4-18': '다국적기업',
  'F-4-19': '동포단체대표',
  'F-4-20': '공무원등',
  'F-4-21': '교원',
  'F-4-22': '개인사업가',
  'F-4-23': '빈번출입자',
  'F-4-24': '제조등근속자',
  'F-4-25': '60세이상자',
  'F-4-26': '수교전입국자',
  'F-4-27': '자격증소지자',
  'F-4-99': '재외동포기타',
  'F-5': '영주',
  'F-5-1': '장기체류',
  'F-5-2': '국민배우자',
  'F-5-3': '국민자녀',
  'F-5-4': '영주가족',
  'F-5-5': '고액투자',
  'F-5-6': '재외동포2년',
  'F-5-7': '동포국적요건',
  'F-5-8': '재한화교',
  'F-5-9': '첨단박사',
  'F-5-10': '첨단학사',
  'F-5-11': '특정능력',
  'F-5-12': '특별공로',
  'F-5-13': '연금수혜',
  'F-5-14': '방문취업4년',
  'F-5-15': '국내박사',
  'F-5-16': '점수제',
  'F-5-17': '부동산투자',
  'F-5-18': '점수가족',
  'F-5-19': '부동가족',
  'F-5-20': '영주출생',
  'F-5-21': '공익사업투자',
  'F-5-22': '공익은퇴가족',
  'F-5-23': '은퇴이민투자',
  'F-5-24': '법인창업영주',
  'F-5-25': '고액투자조건부영주',
  'F-6-1': '국민배우자',
  'F-6-2': '자녀양육',
  'F-6-3': '혼인단절',
  'G-1': '기타',
  'G-1-1': '산재보상',
  'G-1-2': '질병사고',
  'G-1-3': '소송진행',
  'G-1-4': '체임중재',
  'G-1-5': '난민신청',
  'G-1-6': '난민인허',
  'G-1-7': '가족사망',
  'G-1-9': '임신출산',
  'G-1-M': '의료관광',
  'G-1-10': '치료요양',
  'G-1-11': '성매매피해자',
  'G-1-99': '기타',
  'H-1': '관광취업',
  'H-2-1': '연고방취',
  'H-2-2': '유학방취',
  'H-2-3': '자진방취',
  'H-2-4': '연수방취',
  'H-2-5': '추첨방취',
  'H-2-6': '변경방취',
  'H-2-7': '만기방취',
  'H-2-99': '기타방취',
  'T-1-1': '관광상륙',
};
const monthCode = ['01월', '02월', '03월', '04월', '05월', '06월', '07월', '08월', '09월', '10월', '11월', '12월'];

const selectCategory = [
  { label: '전체', category: '', kind: '' },
  { label: '배달 수수료', category: 'DELIVERY_PRICE_RECEIVE', kind: '' },
  { label: '배달 지원금', category: 'DELIVERY_SUBSIDY_SEND', kind: '' },
  { label: '콜수수료', category: 'CALL_FEE', kind: '' },
  { label: '렌트비 회수', category: 'BATCH_WORK_NCASH', kind: 10 },
  { label: '과태료 회수', category: 'BATCH_WORK_NCASH', kind: 17 },
  { label: '수리비 회수', category: 'BATCH_WORK_NCASH', kind: 11 },
  { label: '대여금 회수', category: 'BATCH_WORK_NCASH', kind: 6 },
  { label: '선충전 회수', category: 'BATCH_WORK_NCASH', kind: 22 },
  { label: '음식보험 회수', category: 'BATCH_WORK_NCASH', kind: 21 },
  { label: '가맹비 회수', category: 'BATCH_WORK_NCASH', kind: 19 },
  { label: '기타', category: 'BATCH_WORK_NCASH', kind: 20 },
  { label: '렌트비 납부', category: 'NCASH_SEND_BY_ADMIN', kind: 14 },
  { label: '과태료 납부', category: 'NCASH_SEND_BY_ADMIN', kind: 24 },
  { label: '수리비 납부', category: 'NCASH_SEND_BY_ADMIN', kind: 11 },
  { label: '프로모션 지급', category: 'NCASH_SEND_BY_ADMIN', kind: 4 },
  { label: '대여금 지급', category: 'NCASH_SEND_BY_ADMIN', kind: 7 },
  { label: '선충전 지급', category: 'NCASH_SEND_BY_ADMIN', kind: 1 },
  { label: '음식배상', category: 'NCASH_SEND_BY_ADMIN', kind: 2 },
  { label: '기타 비용', category: 'NCASH_SEND_BY_ADMIN', kind: 0 },
];
const selectCategoryForBranchForRider = [
  { label: '전체', category: 'All', kind: '' },
  { label: '렌트비 회수', category: 'BATCH_WORK_NCASH', kind: 10 },
  { label: '과태료 회수', category: 'BATCH_WORK_NCASH', kind: 17 },
  { label: '수리비 회수', category: 'BATCH_WORK_NCASH', kind: 11 },
  { label: '대여금 회수', category: 'BATCH_WORK_NCASH', kind: 6 },
  { label: '음식보험 회수', category: 'BATCH_WORK_NCASH', kind: 21 },
  { label: '기타', category: 'BATCH_WORK_NCASH', kind: 20 },
  { label: '렌트비 납부', category: 'NCASH_SEND_BY_ADMIN', kind: 14 },
  { label: '과태료 납부', category: 'NCASH_SEND_BY_ADMIN', kind: 24 },
  { label: '수리비 납부', category: 'NCASH_SEND_BY_ADMIN', kind: 11 },
  { label: '대여금 지급', category: 'NCASH_SEND_BY_ADMIN', kind: 7 },
  { label: '기타 비용', category: 'NCASH_SEND_BY_ADMIN', kind: 0 },
];
const selectCategoryForBranchForFr = [
  { label: '전체', category: 'All', kind: '' },
  { label: '선충전 회수', category: 'BATCH_WORK_NCASH', kind: 22 },
  { label: '가맹비 회수', category: 'BATCH_WORK_NCASH', kind: 19 },
  { label: '기타', category: 'BATCH_WORK_NCASH', kind: 20 },
  { label: '프로모션 지급', category: 'NCASH_SEND_BY_ADMIN', kind: 4 },
  { label: '선충전 지급', category: 'NCASH_SEND_BY_ADMIN', kind: 1 },
  { label: '음식배상', category: 'NCASH_SEND_BY_ADMIN', kind: 2 },
  { label: '기타 비용', category: 'NCASH_SEND_BY_ADMIN', kind: 0 },
];

const assignRiderFailResult = {
  ORDER_NOT_EXISTS: '존재하지 않은 주문입니다.',
  ALREADY_ASSIGNED: '선택한 주문 중 이미 배차된 주문이 있습니다.',
  ALREADY_ASSIGNED_SKIP_TRUE: '선택한 주문 중 이미 배차된 주문이 있습니다.',
  NCASH_MINUS: '잔액이 부족합니다.',
  ASSIGN_LIMIT_EXCEEDED: '배차 목록이 가득 찼습니다.',
  NOT_ADMIN: '관리자만 추천배차할 수 있습니다.',
  SAME_RIDER: '동일한 라이더가 배정되어 있습니다.',
  COMPLETED_ORDER: '완료상태의 주문 입니다.',
  CANCELED_ORDER: '취소상태의 주문 입니다.',
  ORDER_STATUS_IS_NOT_1: '접수상태의 주문만 배차 가능합니다.',
  ASSIGNED_TO_ADMIN: '다른 관리자 장바구니에 추가된 주문입니다.',
  RIDER_NOT_APPROVED: '서류 승인이 필요한 라이더 입니다.',
  WAITING_ORDER: '대기상태의 주문입니다.',
  RIDER_NAME_NOT_VERIFIED: '실명인증이 되어있지 않은 라이더입니다.',
  EXCEPTION: '예외 발생',
};

export {
  DistType,
  HttpResponseStatusCode,
  accidentInsuranceString,
  addType,
  addrUpdateEnabled,
  adminSendKindString,
  adminSendKindStringForAgencyService,
  adminSendKindStringForAgencyServiceFranchise,
  agencyBatchWorkKind,
  apiResponseResult,
  arriveReqTime,
  bankCode,
  batchCategory,
  bikeRentOwnedCode,
  bikeType,
  blockStatusString,
  blockString,
  buyStatusString,
  cardStatus,
  category2AllStatus,
  category2FrStatus,
  category2RiderStatus,
  categoryStatus,
  chattingCategoryType,
  chattingSubCategoryType,
  coinCategory,
  contarctType,
  delayReqTime,
  deliveryPriceFeeType,
  deliveryStatusCode,
  deliveryZone,
  distShowSetting,
  dosiguCode,
  dosiguCodeReplace,
  dwServiceTypeCode,
  enabledCode,
  enabledString,
  extListCode,
  feeManner,
  frDuesType,
  frOrderAgencyCode,
  frOrderAgencyPosList,
  frRiderString,
  franIsConnectedString,
  freeDuesApprovalStatus,
  freeDuesAutoPassive,
  freeDuesString,
  getOrderStatusValues,
  getPaymentMethodValues,
  importantNotice,
  items,
  joinExitStaticsType,
  kindString,
  mainMenus,
  mapPaystoryVacntResultCd,
  modifyType,
  noticeCategoryType,
  orderCnt,
  orderStatusDefs,
  packAmount,
  paymentMethod,
  paymentMethodDefs,
  paymentStatus,
  paystoryTransferResultMsg,
  pgUseRate,
  phoneAgency,
  preparationStatus,
  redShowSetting,
  registFrfreeDues,
  rentOwnedPaperCodeList,
  riderApprovedCode,
  riderGroupString,
  riderLevelText,
  riderPaperNoCode,
  riderPaperNoCodeToText,
  riderPaperStatusCode,
  riderPaperStatusToResultText,
  riderPaperUploadErrorCode,
  riderStatusCode,
  riderType,
  rowColorName,
  searchBike,
  searchType,
  sortOrderCode,
  staffString,
  statusCode,
  statusString,
  surchargeType,
  surchargeTypeAgency,
  tableStatusString,
  toggleCode,
  userGroupString,
  userTypeString,
  voiceUpdate,
  withdrawString,
  vanFileUploadErrorCode,
  foreignerCountryCode,
  foreignerStatusCode,
  monthCode,
  agencyBatchWorkForRider,
  agencyBatchWorkForFr,
  selectCategory,
  selectCategoryForBranchForRider,
  selectCategoryForBranchForFr,
  assignRiderFailResult,
};
