const createSortParam = (sortParam) => {
  if (sortParam.column === undefined || sortParam.direction === undefined) {
    return 'idx desc';
  } else {
    const sqlDirection = sortParam.direction.replaceAll('end', '');
    return `${sortParam.column} ${sqlDirection}`;
  }
};

export { createSortParam };
